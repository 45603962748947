<div class="card border-top-primary border-top-2 rounded-top-0"
  style="border-top-color: #2196F3 !important; overflow: auto;">
  <div class="card-header bg-light header-elements-sm-inline">
    <h6 class="card-title">Status History</h6>
    <div class="header-elements">
    </div>
  </div>
  <div class="table-responsive"
    *ngIf="iilhService.iilhList | async; let model">
    <div class="table-responsive"
      *ngIf="model.length === 0">
      <div class="alert alert-primary ml-3 mr-3 mt-3"
        role="alert">
        <i class="fad fa-check"></i>&nbsp;&nbsp;There is currently no <strong>Status History.</strong>
      </div>
    </div>
    <kendo-grid *ngIf="model.length > 0"
      [kendoGridBinding]="model"
      filterable="false"
      [sortable]="true"
      [resizable]="true"
      [pageSize]="kGridParams.pageSize"
      [skip]="kGridParams.skip"
      (pageChange)="pageChange($event)"
      [pageable]="{
                    buttonCount: kGridParams.buttonCount,
                    info: kGridParams.info,
                    type: kGridParams.type,
                    pageSizes: kGridParams.pageSizes,
                    previousNext: kGridParams.previousNext
                  }">
      <kendo-grid-column field="changedOn"
        title="Date"
        [width]="70">
        <ng-template kendoGridCellTemplate
          let-dataItem
          let-rowIndex="rowIndex">
          {{dataItem.changedOn | date: 'shortDate'}}
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column field="itemInstanceLocation"
        title="Status">
      </kendo-grid-column>
      <kendo-grid-column field="jobNumber"
        title="Job#"
        [width]="150">
      </kendo-grid-column>
      <kendo-grid-column field="changedByUserName"
        title="User"
        [width]="250">
      </kendo-grid-column>
    </kendo-grid>
  </div>
</div>
