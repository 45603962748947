import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { BillingCodes } from 'src/enums/billing-codes.enum';
import { JobTypes } from 'src/enums/job-types';
import { AppStateService } from 'src/services/app-state.service';
import { UIService } from 'src/services/ui.service';
import { WorkLogModel } from '../../../../models/work-log.model';
import { JobService } from '../../../../services/job.service';
import { WorkLogService } from '../../../../services/work-log.service';
import { AddEditWorkLogModalComponent } from '../add-edit-work-log-modal/add-edit-work-log-modal.component';

@Component({
  selector: 'app-work-log',
  templateUrl: './work-log.component.html',
  styleUrls: ['./work-log.component.css']
})

export class WorkLogComponent implements OnInit, OnDestroy {
  @Input() logs: WorkLogModel[];
  @Input() jobId: number;
  @Input() dayId: number;
  @Input() jobTypeId: number;
  @Input() jobOwnerId: number;
  @Input() dayDate: Date;

  public bsModalRef: BsModalRef;
  public JobTypes = JobTypes;
  public BillingCodes = BillingCodes;

  private isTabletSub: Subscription;
  public isTablet: boolean = false;

  private deleteSub: Subscription;

  constructor(
    public uiService: UIService,
    public jobService: JobService,
    private modalService: BsModalService,
    public workLogService: WorkLogService,
    public appStateService: AppStateService
  ) { };

  ngOnDestroy(): void {
    this.isTabletSub?.unsubscribe();

    this.deleteSub?.unsubscribe();
  }

  ngOnInit(): void {
    this.determineIsTablet();
  }

  public determineIsTablet(): void {
    this.isTabletSub = this.appStateService.isTablet$
      .subscribe((isTablet: boolean) => {
        this.isTablet = isTablet;
      });
  }

  public openWorkLogModal(wlId: number): void {
    let lastTime: string | Date = null;

    if (wlId === 0 && this.logs.length > 0) {
      let getLastTime = this.logs.reduce((a, b): WorkLogModel => {
        return a.workEnd > b.workEnd ? a : b;
      });
      lastTime = getLastTime ? getLastTime.workEnd : null;
    }

    const initialState = {
      id: wlId,
      dayId: this.dayId,
      jobOwnerId: this.jobOwnerId,
      jobId: this.jobId,
      dayDate: this.dayDate,
      jobTypeId: this.jobTypeId,
      lastTime: lastTime ? new Date(lastTime) : null
    };

    this.bsModalRef = this.modalService.show(AddEditWorkLogModalComponent, { initialState, backdrop: 'static' });
  }

  public deleteWorkLog(log: WorkLogModel): void {
    log.jobId = this.jobId;

    this.deleteSub = this.workLogService.deleteWorkLog(log)
      .subscribe((x: boolean) => {
        if (x) {
          this.uiService.showSuccess('Work Log Deleted', 'Success');
        } else {
          this.uiService.showError('Work Log Not Deleted', 'Error');
        }
      });
  }

}
