import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { JobSubStatuses } from 'src/enums/job-sub-statuses';
import { JobTypes } from 'src/enums/job-types';
import { JobSafetyAnalysisHealthModel } from 'src/models/job-safety-analysis-health.model';
import { AppStateService } from 'src/services/app-state.service';
import { JobService } from 'src/services/job.service';
import { TimezoneService } from 'src/services/timezone.service';
import { UIService } from 'src/services/ui.service';
import { UserService } from 'src/services/user.service';
import { JobStatuses } from '../../../../enums/job-statuses';

@Component({
  selector: 'app-jsa-tab-heading',
  templateUrl: './jsa-tab-heading.component.html',
  styleUrls: ['./jsa-tab-heading.component.css']
})

export class JsaTabHeadingComponent implements OnInit, OnDestroy {
  @Input() jsaHealth: JobSafetyAnalysisHealthModel;
  public handDisplay: boolean = false;
  public JobStatuses = JobStatuses;
  public JobSubStatuses = JobSubStatuses;
  public JobTypes = JobTypes;

  private isTabletSub: Subscription;
  public isTablet: boolean = false;

  constructor(
    public uiService: UIService,
    public userService: UserService,
    public jobService: JobService,
    public appStateService: AppStateService,
    public timeZoneService: TimezoneService
  ) { };

  ngOnDestroy(): void {
    this.isTabletSub?.unsubscribe();
  }

  ngOnInit(): void {
    this.determineIsTablet();
  }

  public determineIsTablet(): void {
    this.isTabletSub = this.appStateService.isTablet$
      .subscribe((isTablet: boolean) => {
        this.isTablet = isTablet;
      });
  }

}
