<div class="modal-header"
  ngxModalDraggable>
  <h4 class="modal-title pull-left"
    *ngIf="model">{{isNew ? 'Add' : 'Edit'}}&nbsp;{{typeString}} Item</h4>
  <button type="button"
    class="close pull-right"
    aria-label="Close"
    (click)="bsModalRef.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body"
  *ngIf="model">
  <div class="form-group row"
    *ngIf="type===JobFishingItemModalTypes.Serialized">
    <label for="title"
      class="col-sm-2 text-right control-label col-form-label">Item</label>
    <div class="col-sm-10 form-group mb-0"
      *ngIf="iiService.itemInstanceList | async; let item">
      <ng-select [items]="item"
        bindLabel="dropdownDisplay"
        bindValue="itemInstanceId"
        [ngModel]="model.itemInstanceId"
        style="padding:0px;"
        name="items"
        [disabled]="!isNew"
        (change)="onChangeItemInstance($event)"
        required>
      </ng-select>
    </div>
  </div>
  <div class="form-group row"
    *ngIf="type===JobFishingItemModalTypes.Inventory">
    <label for="title"
      class="col-sm-2 text-right control-label col-form-label">Item</label>
    <div class="col-sm-10 form-group mb-0"
      *ngIf="itemService.itemDropdownList | async; let item">
      <ng-select [items]="item"
        bindLabel="dropdownDisplay"
        bindValue="itemId"
        [(ngModel)]="model.itemId"
        (change)="onChangeOfItem($event)"
        style="padding:0px;"
        name="items"
        [disabled]="!isNew"
        required>
      </ng-select>
    </div>
  </div>
  <div class="form-group row"
    *ngIf="type===JobFishingItemModalTypes.Inventory || type===JobFishingItemModalTypes.Miscellaneous">
    <label for="title"
      class="col-sm-2 text-right control-label col-form-label">Billing Code</label>
    <div class="col-sm-10 form-group mb-0">
      <div class="input-group">
        <ng-select [items]="billingCodes"
          bindLabel="dropDownDisplay"
          bindValue="billingCodeId"
          [ngModel]="model.billingCodeId"
          name="billingCodes"
          class="col-md-9"
          style="padding:0px;"
          (change)="onChangeOfBillingCode($event)"
          [disabled]="billingCodes.length === 0||isRebuildItem"
          required>
        </ng-select>
        <div class="input-group-append col-md-3"
          style="padding: 0;width:100%">
          <span class="input-group-text"
            style="padding: 0px;text-align:center;"><button (click)="openBillingCodeModal(0)"
              style="display: inline-flex;padding: 4px 10px;width:114px"
              class="btn btn-sm btn-default"
              [disabled]="isRebuildItem||(isInventoryItem&&!this.model.itemId)"><i class="fa fa-plus"
                style="position: relative; top: 3px; margin-right: 5px;"></i>&nbsp;New</button></span>
        </div>
      </div>
    </div>
  </div>
  <div class="form-group row"
    *ngIf="type===JobFishingItemModalTypes.Inventory && jobTypeId===JobTypes.FishingToolRental">
    <label for="title"
      class="col-sm-2 text-right control-label col-form-label">Total</label>
    <div class="col-sm-4 form-group mb-0">
      <div class="input-group input-group-sm">
        <div class="input-group-prepend">
          <span class="input-group-text">$</span>
        </div>
        <input type="text"
          class="form-control form-control-sm"
          id="total"
          [ngModel]="total"
          name="total"
          readonly>
      </div>
    </div>
    <label for="title"
      class="col-sm-3 text-right control-label col-form-label"
      *ngIf="model.returnToInvAfterFishing===true">Remove From Inventory</label>
    <div class="col-sm-3 form-group mb-0"
      *ngIf="model.returnToInvAfterFishing===true">
      <ui-switch [(ngModel)]="model.removeFromInventory"
        name="removeFromInventory"></ui-switch>
    </div>
  </div>
  <div class="form-group row"
    *ngIf="type===JobFishingItemModalTypes.Inventory && currentInventory !== null && currentInventory.length > 0">
    <label for="title"
      class="col-sm-2 text-right control-label col-form-label"></label>
    <div class="col-sm-10 form-group mb-0">
      <div class="row pl-2">
        <div class="alert alert-danger p-1 mb-1"
          role="alert"
          *ngIf="itemIsLoaded && (currentInventory === null || currentInventory.length === 0)">
          <i class="fas fa-check-circle"></i>&nbsp;&nbsp;Current Inventory:
          <strong>{{currentInventory!==null ? currentInventory : 0}}</strong>
        </div>
        <ng-container *ngIf="itemIsLoaded && currentInventory !== null && currentInventory.length > 0">
          <div class="alert p-1 mb-1 mr-2"
            [ngClass]="{'alert-success':ci.inventoryCount !==null && ci.inventoryCount > 0, 'alert-danger': ci.inventoryCount === null || ci.inventoryCount <= 0 }"
            role="alert"
            *ngFor="let ci of currentInventory">
            <i class="fas fa-check-circle"></i>&nbsp;&nbsp;{{ci.name}} Inventory:
            <strong>{{ci.inventoryCount !==null ? ci.inventoryCount : 0}}</strong>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
  <div class="form-group row"
    *ngIf="type===JobFishingItemModalTypes.ThirdParty">
    <label for="title"
      class="col-sm-2 text-right control-label col-form-label">Item</label>
    <div class="col-sm-10 form-group mb-0"
      *ngIf="itemService.rentalItemList | async; let item">
      <ng-select [items]="item"
        bindLabel="dropdownDisplay"
        bindValue="itemId"
        [(ngModel)]="model.itemId"
        (change)="onChangeOfThirdParty($event)"
        style="padding:0px;"
        name="items"
        [disabled]="!isNew"
        required>
      </ng-select>
    </div>
  </div>
  <div class="form-group row"
    *ngIf="type===JobFishingItemModalTypes.Serialized && showQuantity || jobTypeId === JobTypes.FishingToolRental">
    <label for="title"
      class="col-sm-2 text-right control-label col-form-label"
      *ngIf="(type===1 && showQuantity) || (type === 2 && jobTypeId === JobTypes.FishingToolRental)">Qty</label>
    <div class="col-sm-4 form-group mb-0"
      *ngIf="(type===JobFishingItemModalTypes.Serialized && showQuantity) || (type === JobFishingItemModalTypes.Inventory && jobTypeId === JobTypes.FishingToolRental)">
      <div class="input-group">
        <input type="number"
          class="form-control form-control-sm"
          id="qty"
          [(ngModel)]="model.qty"
          name="qty"
          (change)="calculateInventoryTotal()"
          required>
        <div class="input-group-append"
          style="height:32px;">
          <span
            class="input-group-text">{{model.quantityUom === 'Run' && jobTypeId === JobTypes.FishingToolRental ? 'Well' : model.quantityUom}}</span>
        </div>
      </div>
    </div>
    <label for="title"
      class="col-sm-2 text-right control-label col-form-label"
      *ngIf="jobTypeId === JobTypes.FishingToolRental && type !== JobFishingItemModalTypes.Inventory && model.quantityUom !== 'Run'">Days</label>
    <div class="col-sm-4 form-group mb-0"
      *ngIf="jobTypeId === JobTypes.FishingToolRental && type !== JobFishingItemModalTypes.Inventory && model.quantityUom !== 'Run'">
      <div class="input-group">
        <input type="number"
          class="form-control form-control-sm"
          id="qty"
          [(ngModel)]="model.numberOfDays"
          name="numberOfDays"
          required>
        <div class="input-group-append"
          style="height:32px;">
          <span class="input-group-text">Days</span>
        </div>
      </div>
    </div>
  </div>
  <div class="form-group row"
    *ngIf="!isNew && type===1">
    <label for="title"
      class="col-sm-2 text-right control-label col-form-label">Sold</label>
    <div class="col-sm-1 form-group mb-0">
      <ui-switch [ngModel]="model.sold"
        name="sold"
        (change)="onChangeOfSold($event)"></ui-switch>
    </div>
    <label for="title"
      class="col-sm-2 text-right control-label col-form-label"
      *ngIf="model.sold===true">Sold Qty</label>
    <div class="col-sm-2 form-group mb-0"
      *ngIf="model.sold===true">
      <input type="number"
        class="form-control form-control-sm"
        id="soldQty"
        [(ngModel)]="model.soldQty"
        name="soldQty"
        required>
    </div>
    <label for="title"
      class="col-sm-2 text-right control-label col-form-label"
      *ngIf="model.sold===true">Price</label>
    <div class="col-sm-3 form-group mb-0"
      *ngIf="model.sold===true">
      <div class="input-group">
        <div class="input-group-prepend"
          style="height: 32px;">
          <span class="input-group-text">$</span>
        </div>
        <input type="number"
          class="form-control form-control-sm"
          id="price"
          [(ngModel)]="model.price"
          name="price"
          required>
      </div>
    </div>
  </div>
  <div class="form-group row"
    *ngIf="true">
    <label for="title"
      class="col-sm-2 text-right control-label col-form-label">Do Not Invoice</label>
    <div class="col-sm-1 form-group mb-0">
      <ui-switch [ngModel]="model.doNotCharge"
        name="doNotCharge"
        (change)="onChangeOfDoNotCharge($event)"></ui-switch>
    </div>
    <div class="col-sm-9 form-group mb-0"
      *ngIf="model.doNotCharge">
      <div class="alert alert-warning mb-0 mt-1 p-1"
        role="alert"
        style="height: 32px;">
        <i class="fas fa-exclamation-triangle"></i>&nbsp;&nbsp;This item will no longer show on the invoice!
      </div>
    </div>
  </div>
  <div class="form-group row"
    *ngIf="showAdditionalDaysOnly || showChargeAllMinimumDays">
    <label for="title"
      class="col-sm-2 text-right control-label col-form-label"
      *ngIf="showChargeAllMinimumDays">Charge All Minimum Days</label>
    <div class="col-sm-4 form-group mb-0"
      *ngIf="showChargeAllMinimumDays">
      <ui-switch [ngModel]="model.chargeMinimumDays"
        name="sold"
        (change)="onChangeOfChargeMinimumDays($event)"
        [disabled]="model && model.additionalRateOnly"></ui-switch>
    </div>
    <label for="title"
      class="col-sm-2 text-right control-label col-form-label"
      *ngIf="showAdditionalDaysOnly">Additional Rate Only</label>
    <div class="col-sm-4 form-group mb-0"
      *ngIf="showAdditionalDaysOnly">
      <ui-switch [ngModel]="model.additionalRateOnly"
        name="sold"
        (change)="onChangeOfAdditionalRateOnly($event)"></ui-switch>
    </div>
  </div>
  <div class="alert alert-secondary mb-1 p-0"
    role="alert"
    *ngIf="poLines.length > 0">
    <table class="table">
      <thead class="thead-dark">
        <tr>
          <td colspan="3">
            <strong>Most Recent Unit Prices</strong>
          </td>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let l of poLines">
          <td style="text-align: right;width:15%;">{{l.poDate | date:'shortDate'}}</td>
          <td>{{l.vendor}}</td>
          <td style="text-align: right;width:25%;"><strong>{{l.price | currency}}</strong></td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
<div class="modal-footer"
  *ngIf="model">
  <button type="button"
    class="btn btn-primary"
    (click)="onSave()"
    [disabled]="disableSave ||
                      !((jobTypeId === JobTypes.FishingToolRental && model.numberOfDays !== null && typeString !=='Inventory') || (jobTypeId === JobTypes.FishingToolRental && model.qty && typeString ==='Inventory')|| jobTypeId !== JobTypes.FishingToolRental) ||
                      !(typeString !== 'Serialized' ||
                      ((typeString === 'Serialized' && showQuantity && model.qty!==null) || (typeString === 'Serialized' && !showQuantity && model.qty===null))) ||!((model.itemInstanceId||model.itemId)&&(model.sold===false||model.sold===true&&model.price&&model.soldQty)&&(type!==2 || (type===2&&model.billingCodeId!==undefined&&model.billingCodeId!==null)))">Save</button>
  <button type="button"
    class="btn btn-default"
    (click)="bsModalRef.hide()">Cancel</button>
</div>
