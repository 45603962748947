<div class="modal-header"
  ngxModalDraggable>
  <h4 class="modal-title pull-left">Reorder Invoice</h4>
  <button type="button"
    class="close pull-right"
    aria-label="Close"
    (click)="bsModalRef.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<app-table-skeleton [numberToIterate]="14"
  *ngIf="!rows"></app-table-skeleton>
<div class="modal-body"
  *ngIf="rows">
  <ag-grid-angular style="width: 100%; height: 100%"
    class="ag-theme-alpine"
    [columnDefs]="columnDefs"
    [defaultColDef]="defaultColDef"
    [rowData]="rows"
    [rowSelection]="'multiple'"
    [rowDragManaged]="true"
    [suppressMoveWhenRowDragging]="false"
    [animateRows]="true"
    (gridReady)="onGridReady($event)"></ag-grid-angular>
</div>
<div class="modal-footer"
  *ngIf="true">
  <button type="button"
    class="btn btn-primary"
    (click)="onSave()"
    [disabled]="disableSave">Save</button>
  <button type="button"
    class="btn btn-default"
    (click)="bsModalRef.hide()">Cancel</button>
</div>
