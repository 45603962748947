import { JobItemInstanceDayModel } from './job-item-instance-day.model';

export class JobItemInstanceModel {
  jobItemInstanceId: number;
  jobId: number;
  jobNumber: number | null;
  itemInstanceId: number | null;
  itemId: number | null;
  billingCodeId: number | null;
  qty: number | null;
  sold: boolean;
  soldQty: number | null;
  price: number | null;
  releasedFromJob: boolean;
  returnToInvAfterFishing: boolean;
  removeFromInventory: boolean;
  chargeMinimumDays: boolean;
  additionalRateOnly: boolean;
  doNotCharge: boolean;
  numberOfDays: number;
  serialNumber: string;
  outerDiameter: number | null;
  innerDiameter: number | null;
  length: number | null;
  originalCost: number | null;
  description: string;
  isActive: boolean | null;
  isThirdPartyRental: boolean | null;
  sortOrder: number;
  fishingToolRentalGroupId: number;

  quantityUom: string;
  fullGtNumber: string;
  dayIds: number[];
  jobIteminstanceDays: JobItemInstanceDayModel[];
  disableTrash: boolean = false;
  variableQty: boolean = false;
  perRun: boolean = false;
  bhaNumber: number | null;
  onBha: boolean | null;
  type: number | null;
  dropdownDisplay: string;
  disabled: boolean = false;
}
