<div class="page-header">
  <div class="page-header-content header-elements-md-inline">
    <div class="page-title d-flex">
      <a [routerLink]="['/worksheet-types/list']"
        class="breadcrumb-link-color">
        <h4><i class="icon-arrow-left52 mr-2"></i> <span class="font-weight-semibold">Worksheet Type</span> - Details
        </h4>
      </a>
      <button type="button"
        class="btn btn-outline-dark btn-sm ml-3 pl-1 pr-1 pt-0 pb-0 no-border"
        [title]="!showDetails ? 'Show Details' : 'Hide Details'"
        (click)="showDetails = !showDetails">
        <span *ngIf="!showDetails">
          <i class="fa-regular fa-chevrons-right"></i>&nbsp;Show </span>
        <span *ngIf="showDetails"><i class="fa-regular fa-chevrons-left"></i>&nbsp;Hide
        </span>Details</button>
    </div>
    <div class="header-elements d-none py-0 mb-3 mb-md-0">
      <div class="breadcrumb">
        <a href="index.html"
          class="breadcrumb-item"><i class="icon-home2 mr-2"></i> Worksheet Type</a>
        <span class="breadcrumb-item active">Detail</span>
      </div>
    </div>
  </div>
</div>
<div class="page-content pt-0"
  *ngIf="worksheetTypeService.worksheetType | async; let model">
  <div class="content-wrapper">
    <div class="content">
      <div class="row">
        <div class="col-lg-3"
          *ngIf="showDetails">
          <div class="card">
            <div class="card-header bg-light header-elements-inline"
              [ngClass]="{'pl-2 pr-2':isTablet}">
              <h6 class="card-title">{{model.name}}</h6>
              <div class="header-elements">
                <button class="btn btn-light btn-sm mr-1"
                  [swal]="{ title: 'Create Copy?', text: model.name, showCancelButton: true, confirmButtonText: 'Copy', confirmButtonColor: '#1B6EC2' }"
                  (confirm)="createCopy();"><i class="far fa-copy"></i><span *ngIf="!isTablet"> Create
                    Copy</span></button>
                <button class="btn btn-light btn-sm"
                  (click)="openWorksheetTypeModal(model.worksheetTypeId)"><i class="fa fa-edit"></i><span
                    *ngIf="!isTablet"> Edit</span></button>
              </div>
            </div>
            <div class="card-body"
              [ngClass]="{'pl-2 pr-2':isTablet}">
              <dl class="row">
                <dt class="col-sm-4 text-right"
                  [ngClass]="{'pr-0':isTablet}">Name</dt>
                <dd class="col-sm-8">{{model.name}}</dd>
                <dt class="col-sm-4 text-right"
                  [ngClass]="{'pr-0':isTablet}"
                  *ngIf="model.billingCodeName">Billing Code</dt>
                <dd class="col-sm-8"
                  *ngIf="model.billingCodeName">{{model.billingCodeName}}</dd>
                <dt class="col-sm-4 text-right"
                  [ngClass]="{'pr-0':isTablet}"
                  *ngIf="model.worksheetCategoryName">Worksheet Category</dt>
                <dd class="col-sm-8"
                  *ngIf="model.worksheetCategoryName">{{model.worksheetCategoryName}}</dd>
                <dt class="col-sm-4 text-right"
                  [ngClass]="{'pr-0':isTablet}">Order</dt>
                <dd class="col-sm-8">{{model.sortOrder}}</dd>
                <dt class="col-sm-4 text-right"
                  [ngClass]="{'pr-0':isTablet}">Show IsCustomerProperty</dt>
                <dd class="col-sm-8">{{model.showIsCustomerProperty ? 'Yes' : 'No'}}</dd>
                <dt class="col-sm-4 text-right"
                  [ngClass]="{'pr-0':isTablet}">Is Itemized</dt>
                <dd class="col-sm-8">{{model.isItemized ? 'Yes' : 'No'}}</dd>
                <dt class="col-sm-4 text-right"
                  [ngClass]="{'pr-0':isTablet}">Rebuild/New</dt>
                <dd class="col-sm-8">
                  <span class="badge badge-primary"
                    *ngIf="model.isRebuild">REBUILD</span>
                  <span class="badge badge-info"
                    *ngIf="!model.isRebuild">NEW</span>
                </dd>
                <dt class="col-sm-4 text-right"
                  [ngClass]="{'pr-0':isTablet}">Is Packer</dt>
                <dd class="col-sm-8">
                  <span class="badge badge-success"
                    *ngIf="model.isPacker">YES</span>
                  <span class="badge badge-danger"
                    *ngIf="!model.isPacker">NO</span>
                </dd>
                <dt class="col-sm-4 text-right"
                  [ngClass]="{'pr-0':isTablet}"
                  *ngIf="model.itemId">Item</dt>
                <dd class="col-sm-8"
                  *ngIf="model.itemId"><a [routerLink]="['/items/details', model.itemId]"
                    target="_blank">{{model.itemDescription}}</a>
                </dd>
              </dl>
            </div>
          </div>
        </div>
        <div [ngClass]="{'col-lg-3': showDetails, 'col-lg-5': !showDetails}">
          <div class="card border-top-primary border-top-2 rounded-top-0"
            style="border-top-color: #2196F3 !important;">
            <div class="card-header bg-light header-elements-sm-inline">
              <h6 class="card-title">Worksheet Row Types</h6>
              <div class="header-elements">
                <button class="btn btn-light btn-sm"
                  (click)="openWorksheetRowTypeModal(0)">
                  <i class="fa fa-plus"></i> New<span *ngIf="!isTablet"> Row Type</span>
                </button>
              </div>
            </div>
            <div class="table-responsive">
              <kendo-grid [kendoGridBinding]="model.worksheetRowTypes"
                filterable="false"
                [sortable]="true"
                [resizable]="true"
                [rowClass]="rowCallback"
                [selectable]="selectableSettings"
                (selectedKeysChange)="selectedKeysChange($event)"
                [kendoGridSelectBy]="'worksheetRowTypeId'">
                <kendo-grid-checkbox-column [width]="60"></kendo-grid-checkbox-column>
                <kendo-grid-column field="one"
                  title=""
                  [width]="60"
                  filterable="false">
                  <ng-template kendoGridCellTemplate
                    let-dataItem
                    let-rowIndex="rowIndex">
                    <button class="btn btn-xs btn-light"
                      (click)="openWorksheetRowTypeModal(dataItem.worksheetRowTypeId)"><i
                        class="fa fa-pencil"></i></button>
                  </ng-template>
                </kendo-grid-column>
                <kendo-grid-column field="sortOrder"
                  title="Order"
                  [width]="60">
                </kendo-grid-column>
                <kendo-grid-column field="name"
                  title="Name"
                  [width]="200">
                  <ng-template kendoGridCellTemplate
                    let-dataItem
                    let-rowIndex="rowIndex">
                    {{dataItem.name}}<span class="text-primary"
                      *ngIf="dataItem.itemized"
                      title="Itemized">&nbsp;&nbsp;<i class="fas fa-info"></i></span>
                  </ng-template>
                </kendo-grid-column>
                <kendo-grid-column field="one"
                  title=""
                  [width]="60"
                  filterable="false">
                  <ng-template kendoGridCellTemplate
                    let-dataItem
                    let-rowIndex="rowIndex">
                    <button class="btn btn-xs btn-light"
                      [swal]="{ title: 'Delete Row Type?', text: dataItem.name, showCancelButton: true, confirmButtonText: 'Delete', confirmButtonColor: '#DC3545' }"
                      (confirm)="deleteWorksheetRowType(dataItem)"><i class="fa fa-trash"></i></button>
                  </ng-template>
                </kendo-grid-column>
              </kendo-grid>
            </div>
          </div>
        </div>
        <div [ngClass]="{'col-lg-6': showDetails, 'col-lg-7': !showDetails}">
          <div class="card border-top-primary border-top-2 rounded-top-0"
            style="border-top-color: #2196F3 !important;">
            <div class="card-header bg-light header-elements-sm-inline">
              <h6 class="card-title">Worksheet Row Item Types</h6>
              <div class="header-elements">
                <button class="btn btn-light btn-sm"
                  (click)="openWorksheetRowItemTypeModal(0, -1)"
                  [disabled]="selectedIds.length === 0">
                  <i class="fa fa-plus"></i> New<span *ngIf="!isTablet"> Row Item Type</span>
                </button>
              </div>
            </div>
            <div class="table-responsive">
              <kendo-grid [kendoGridBinding]="itemTypes"
                filterable="false"
                [sortable]="true"
                [resizable]="true">
                <kendo-grid-column field="one"
                  title=""
                  [width]="60"
                  filterable="false">
                  <ng-template kendoGridCellTemplate
                    let-dataItem
                    let-rowIndex="rowIndex">
                    <button class="btn btn-xs btn-light"
                      (click)="openWorksheetRowItemTypeModal(dataItem.worksheetRowItemTypeId, dataItem.worksheetRowTypeId)"><i
                        class="fa fa-pencil"></i></button>
                  </ng-template>
                </kendo-grid-column>
                <kendo-grid-column field="name"
                  title="Name">
                  <ng-template kendoGridCellTemplate
                    let-dataItem
                    let-rowIndex="rowIndex">
                    {{dataItem.name}}
                    <span class="text-primary"
                      *ngIf="dataItem.chargeItemizedOption"
                      title="Charge">&nbsp;&nbsp;<i class="fas fa-dollar-sign"></i></span>
                    <span class="text-primary"
                      *ngIf="dataItem.isNote"
                      title="Note">&nbsp;&nbsp;<i class="fal fa-sticky-note"></i></span>
                  </ng-template>
                </kendo-grid-column>
                <kendo-grid-column field="sortOrder"
                  title="Order"
                  [width]="60">
                </kendo-grid-column>
                <kendo-grid-column field="billingCodeName"
                  title="Billing Code">
                </kendo-grid-column>
                <kendo-grid-column field="itemDescription"
                  title="Item">
                </kendo-grid-column>
                <kendo-grid-column field="zeroCharge"
                  title="No Charge"
                  [width]="80">
                  <ng-template kendoGridCellTemplate
                    let-dataItem
                    let-rowIndex="rowIndex">
                    <span *ngIf="dataItem.zeroCharge"><i class="fas fa-check"></i></span>
                  </ng-template>
                </kendo-grid-column>
                <kendo-grid-column field="isOption"
                  title="Option"
                  [width]="60">
                  <ng-template kendoGridCellTemplate
                    let-dataItem
                    let-rowIndex="rowIndex">
                    <span *ngIf="dataItem.isOption"><i class="fas fa-check"></i></span>
                  </ng-template>
                </kendo-grid-column>
                <kendo-grid-column field="one"
                  title=""
                  [width]="60"
                  filterable="false">
                  <ng-template kendoGridCellTemplate
                    let-dataItem
                    let-rowIndex="rowIndex">
                    <button class="btn btn-xs btn-light"
                      [swal]="{ title: 'Delete Row Item Type?', text: dataItem.name, showCancelButton: true, confirmButtonText: 'Delete', confirmButtonColor: '#DC3545' }"
                      (confirm)="deleteWorksheetRowItemType(dataItem)">
                      <i class="fa fa-trash"></i>
                    </button>
                  </ng-template>
                </kendo-grid-column>
              </kendo-grid>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div *ngIf="!worksheetTypeService.worksheetType.value"
  class="page-content pt-0">
  <div class="content-wrapper">
    <div class="content">
      <div class="row">
        <div class="col-lg-3">
          <div class="card">
            <div class="card-header bg-light header-elements-inline">
              <h6 class="card-title">&nbsp;</h6>
              <div class="header-elements">
                <button class="btn btn-light btn-sm mr-1"
                  [disabled]="true"><i class="far fa-copy"></i><span *ngIf="!isTablet"> Create
                    Copy</span></button>
                <button class="btn btn-light btn-sm"
                  [disabled]="true"><i class="fa fa-edit"></i><span *ngIf="!isTablet"> Edit</span></button>
              </div>
            </div>
            <div class="card-body">
              <app-list-skeleton [numberToIterate]="5"></app-list-skeleton>
            </div>
          </div>
        </div>
        <div [ngClass]="{'col-lg-3': showDetails, 'col-lg-5': !showDetails}">
          <div class="card border-top-primary border-top-2 rounded-top-0"
            style="border-top-color: #2196F3 !important;">
            <div class="card-header bg-light header-elements-inline"
              style="height: 70px;">
              <h6 class="card-title">Worksheet Row Types</h6>
              <button class="btn btn-light btn-sm"
                disabled>
                <i class="fa fa-plus"></i> New<span *ngIf="!isTablet"> Row Type</span>
              </button>
            </div>
            <div class="card-body">
              <app-table-skeleton [numberToIterate]="11"></app-table-skeleton>
            </div>
          </div>
        </div>
        <div [ngClass]="{'col-lg-6': showDetails, 'col-lg-7': !showDetails}">
          <div class="card border-top-primary border-top-2 rounded-top-0"
            style="border-top-color: #2196F3 !important;">
            <div class="card-header bg-light header-elements-sm-inline">
              <h6 class="card-title">Worksheet Row Item Types</h6>
              <div class="header-elements">
                <button class="btn btn-light btn-sm"
                  [disabled]="true">
                  <i class="fa fa-plus"></i> New<span *ngIf="!isTablet"> Row Item Type</span>
                </button>
              </div>
            </div>
            <div class="card-body">
              <app-table-skeleton [numberToIterate]="0"></app-table-skeleton>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
