import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { PaymentTermModel } from '../models/payment-term.model';
import { tap } from 'rxjs/operators';

@Injectable()
export class PaymentTermService {
  constructor(
    private http: HttpClient,
    @Inject('BASE_URL') private baseUrl: string
  ) { }

  private apiUrl = 'api/PaymentTerm';  // URL to web api

  private paymentTermListSubject = new BehaviorSubject<PaymentTermModel[]>(null);
  public _paymentTermList$ = this.paymentTermListSubject.asObservable();
  private isPaymentTermCallInProgress = false;


  public get paymentTermList$(): Observable<PaymentTermModel[]> {
    if (!this.paymentTermListSubject.getValue() && !this.isPaymentTermCallInProgress) {
      this.isPaymentTermCallInProgress = true;
      this.getPaymentTerms$(false).subscribe(() => this.isPaymentTermCallInProgress = false);
    }

    return this._paymentTermList$;
  }

  private getPaymentTerms$(showInactive: boolean): Observable<PaymentTermModel[]> {
    return this.http.get<Array<PaymentTermModel>>(this.baseUrl + this.apiUrl + '/GetPaymentTerms/' + showInactive)
      .pipe(
        tap((terms: PaymentTermModel[]) => {
          this.paymentTermListSubject.next(terms);
        })
      );
  }
}
