import { Component, OnDestroy, OnInit } from '@angular/core';
import { RowClassArgs } from '@progress/kendo-angular-grid';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { SuperAdminEditConfirmModalComponent } from 'src/app/shared/super-admin-edit-confirm-modal/super-admin-edit-confirm-modal.component';
import { JobSubStatusService } from 'src/services/job-sub-status.service';
import { KGridService } from 'src/services/k-grid.service';
import { UIService } from 'src/services/ui.service';
import { UserService } from 'src/services/user.service';
import { AddEditJobSubStatusModalComponent } from '../add-edit-job-sub-status-modal/add-edit-job-sub-status-modal.component';

@Component({
  selector: 'app-job-sub-status-list',
  templateUrl: './list.component.html',
  styleUrls: ['list.component.css']
})

export class JobSubStatusListComponent implements OnInit, OnDestroy {
  public showInactive: boolean = false;
  public allowEditing: boolean = false;
  private deleteSub: Subscription;
  public bsModalRef: BsModalRef;
  private saveSubscriptions = new Subscription();

  constructor(
    private modalService: BsModalService,
    public kGridService: KGridService,
    public jobSubStatusService: JobSubStatusService,
    public uiService: UIService,
    public userService: UserService
  ) { };

  public ngOnDestroy(): void {
    this.kGridService.resetKGridParams();

    this.deleteSub?.unsubscribe();

    this.saveSubscriptions?.unsubscribe();
  }

  ngOnInit(): void {
    this.jobSubStatusService.getJobSubStatuses(this.showInactive);
  }

  public onChangeShowInactive($event): void {
    this.showInactive = $event;

    this.jobSubStatusService.getJobSubStatuses(this.showInactive);
  }

  public openJobSubStatusModal(subStatusId: number): void {
    const initialState = {
      id: subStatusId,
      showInactive: this.showInactive
    };
    this.bsModalRef = this.modalService.show(AddEditJobSubStatusModalComponent, { initialState, backdrop: 'static' });
  }

  public onChangeOfAllowEditing($event: boolean): void {
    this.allowEditing = $event;

    if ($event !== undefined) {
      if ($event === true) {
        this.bsModalRef = this.modalService.show(SuperAdminEditConfirmModalComponent, { backdrop: 'static' });

        this.saveSubscriptions.add(this.bsModalRef.content.onClose
          .subscribe((confirm: boolean) => {
            if (confirm !== undefined) {
              this.allowEditing = confirm;
            }
          }));
      }
    }
  }

  public deleteItemType(id: number): void {
    this.deleteSub = this.jobSubStatusService.deleteJobSubStatus(id, this.showInactive)
      .subscribe((x: boolean) => {
        if (x) {
          this.uiService.showSuccess('Success', 'Job Sub Status Deleted');
        } else {
          this.uiService.showError('Error', 'Job Sub Status Not Deleted');
        }
      });
  }

  public rowCallback(context: RowClassArgs): any {
    return {
      inactive: context.dataItem.isActive === false
    };
  }

}
