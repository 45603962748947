<table class="table mb-0 thead-border-top-0 table-nowrap text-center table-borderless">
  <tbody>
    <tr *ngFor="let i of iteratorArray(numberToIterate).fill(1)">
      <td class="p-0">
        <ngx-skeleton-loader count="1"
          [animation]="'pulse'"
          [theme]="{ 'width': '100%', 'height': '40px', 'border': 'none'}"></ngx-skeleton-loader>
      </td>
    </tr>
  </tbody>
</table>
