import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { PageChangeEvent } from '@progress/kendo-angular-grid';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { AddEditAddressModalComponent } from 'src/app/addresses/add-edit-address-modal/add-edit-address-modal.component';
import { AddressTypes } from 'src/enums/address-types.enum';
import { AddressModel } from 'src/models/address.model';
import { CustomerModel } from 'src/models/customer.model';
import { AddressService } from 'src/services/address.service';
import { CustomerService } from 'src/services/customer.service';
import { KGridService } from 'src/services/k-grid.service';
import { UIService } from 'src/services/ui.service';
import { VendorService } from 'src/services/vendor.service';

@Component({
  selector: 'app-address-list',
  templateUrl: './address-list.component.html',
})

export class AddressListComponent implements OnInit, OnDestroy {
  @Input() type: number;

  public customerSub: Subscription;
  public deleteSub: Subscription;

  constructor(
    private modalService: BsModalService,
    public addressService: AddressService,
    public customerService: CustomerService,
    public vendorService: VendorService,
    public kGridService: KGridService,
    public uiService: UIService,
  ) { };

  public showInactive: boolean = false;
  public entityId: number = -1;
  public billingAddressId: number = -1;
  public bsModalRef: BsModalRef;

  ngOnDestroy(): void {
    this.customerSub?.unsubscribe();

    this.deleteSub?.unsubscribe();
  }

  ngOnInit() {
    if (this.type === AddressTypes.Customer) {
      this.subscribeToCustomer();
    } else if (this.type === AddressTypes.Vendor) {

    }
  }

  public subscribeToCustomer(): void {
    this.customerSub = this.customerService.customer
      .subscribe((x: CustomerModel) => {
        this.entityId = x.customerId;
        this.billingAddressId = x.billingAddressId !== undefined && x.billingAddressId !== null ? x.billingAddressId : -1;
      });
  }

  public openAddressModal(id: number): void {
    const initialState = {
      id: id,
      type: this.type,
      entityId: this.entityId,
      showInactive: this.showInactive
    };
    this.bsModalRef = this.modalService.show(AddEditAddressModalComponent, { initialState, class: 'modal-lg', backdrop: 'static' });
  }

  public onChangeShowInactive($event: boolean): void {
    this.showInactive = $event;
    this.addressService.refreshAddressList(this.type, this.entityId, this.showInactive);
  }

  public deleteAddress(model: AddressModel): void {
    this.deleteSub = this.addressService.deleteAddress(model, this.showInactive)
      .subscribe((x: boolean) => {
        if (x) {
          this.uiService.showSuccess('Success', 'Address Deleted');
        } else {
          this.uiService.showError('Error', 'Address Not Deleted');
        }
      });
  }

  public kGridParams: any =
    {
      buttonCount: 5,
      info: true,
      type: '\'numeric\' | \'input\' = \'numeric\'',
      pageSizes: true,
      previousNext: true,
      pageSize: 100,
      skip: 0
    };

  public pageChange({ skip, take }: PageChangeEvent): void {
    this.kGridParams.skip = skip;
    this.kGridParams.pageSize = take;
  }

}
