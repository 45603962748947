<!--<h1>PO Component</h1>-->
<div class="card rounded-top-0">
  <div class="card-header bg-light header-elements-sm-inline pb-1 pt-1">
    <h6 class="card-title">PO's</h6>
    <div class="header-elements">
      <button class="btn btn-light btn-sm mr-1"
        (click)="generateReport()"
        [disabled]="disable || purchaseOrders.length === 0">
        <i class="fal fa-file-excel"></i> Excel
      </button>
      <button class="btn btn-light btn-sm"
        (click)="openLinkPoModal()"
        [disabled]="disable">
        <i class="fal fa-link"></i> Link PO
      </button>
    </div>
  </div>
  <div class="table-responsive">
    <div class="table-responsive"
      *ngIf="purchaseOrders.length === 0">
      <div class="alert alert-primary ml-3 mr-3 mt-3"
        role="alert">
        <i class="fad fa-link"></i>&nbsp;&nbsp;There are no linked <strong>Purchase Orders.</strong>
      </div>
    </div>
    <table width="100%"
      *ngIf="purchaseOrders.length > 0"
      class="table table-striped">
      <thead>
        <tr>
          <th>PO#</th>
          <th>Vendor</th>
          <th>PO Total</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <ng-container *ngFor="let po of purchaseOrders">
          <tr>
            <td><a [routerLink]="['/purchase-orders/details', po.poid]">{{po.ponumberDisplay}}</a></td>
            <td>{{po.vendorName}}</td>
            <td>{{po.total | currency}}</td>
            <td>
              <button class="btn btn-danger btn-sm"
                title="Unlink PO"
                [swal]="{ title: 'Unlink PO#'+ po.ponumber + '?', text: po.vendorName + ': $' + po.total, showCancelButton: true, confirmButtonText: 'Delete', confirmButtonColor: '#DC3545' }"
                (confirm)="unlinkPo(po.poid)"
                [disabled]="disable">
                <i class="fal fa-unlink"></i>
              </button>
            </td>
          </tr>
        </ng-container>
      </tbody>
      <tfoot>
        <tr>
          <td colspan="2">
            <strong>TOTAL:</strong>
          </td>
          <td>
            <strong>{{total | currency}}</strong>
          </td>
          <td>
          </td>
        </tr>
      </tfoot>
    </table>
  </div>
</div>
