<div class="page-header">
  <div class="page-header-content header-elements-md-inline">
    <div class="page-title d-flex">
      <a [routerLink]="['/home']"
        class="breadcrumb-link-color">
        <h4><i class="icon-arrow-left52 mr-2"></i> <span class="font-weight-semibold">Part Family/Group Master</span> -
          Manage</h4>
      </a>
    </div>
    <div class="header-elements d-none py-0 mb-3 mb-md-0">
      <div class="breadcrumb">
        <a href="index.html"
          class="breadcrumb-item"><i class="icon-home2 mr-2"></i> Part Family/Group Master</a>
        <span class="breadcrumb-item active">List</span>
      </div>
    </div>
  </div>
</div>
<div class="page-content pt-0">
  <div class="content-wrapper">
    <div class="content">
      <div class="row">
        <div class="col-lg-6">
          <div class="card border-top-primary border-top-2 rounded-top-0"
            style="border-top-color: #2196F3 !important;">
            <div class="card-header bg-light header-elements-sm-inline">
              <h6 class="card-title">Part Families</h6>
              <div class="header-elements">
                <button class="btn btn-light btn-sm"
                  (click)="openPartFamilyModal(0)">
                  <i class="fa fa-plus"></i> New Part Family
                </button>
              </div>
            </div>
            <app-table-skeleton [numberToIterate]="6"
              *ngIf="!partFamilyService.partFamilyListIsLoaded"></app-table-skeleton>
            <ng-container *ngIf="partFamilyService.partFamilyListIsLoaded">
              <div class="table-responsive"
                *ngIf="partFamilyService.partFamilyList | async; let model">
                <kendo-grid [kendoGridBinding]="model"
                  filterable="false"
                  [sortable]="true"
                  [resizable]="true"
                  [selectable]="selectableSettings"
                  (selectedKeysChange)="selectedKeysChange($event)"
                  [kendoGridSelectBy]="'partFamilyId'">
                  <kendo-grid-checkbox-column [width]="60"></kendo-grid-checkbox-column>
                  <kendo-grid-column field="one"
                    title=""
                    [width]="60"
                    filterable="false">
                    <ng-template kendoGridCellTemplate
                      let-dataItem
                      let-rowIndex="rowIndex">
                      <button class="btn btn-xs btn-light"
                        (click)="openPartFamilyModal(dataItem.partFamilyId)"><i class="fa fa-edit"></i></button>
                    </ng-template>
                  </kendo-grid-column>
                  <kendo-grid-column field="fishingTools"
                    title=""
                    [width]="35">
                    <ng-template kendoGridCellTemplate
                      let-dataItem
                      let-rowIndex="rowIndex">
                      <span *ngIf="dataItem.fishingTools"
                        class="text-primary"><i class="fas fa-fish"></i></span>
                    </ng-template>
                  </kendo-grid-column>
                  <kendo-grid-column field="name"
                    title="Name">
                  </kendo-grid-column>
                  <kendo-grid-column field="prefix"
                    title="Prefix">
                  </kendo-grid-column>
                  <kendo-grid-column field="partNumberRangeStart"
                    title="Range Start">
                  </kendo-grid-column>
                  <kendo-grid-column field="one"
                    title=""
                    [width]="60"
                    filterable="false">
                    <ng-template kendoGridCellTemplate
                      let-dataItem
                      let-rowIndex="rowIndex">
                      <button class="btn btn-xs btn-light"
                        [swal]="{ title: 'Delete Part Family?', text: dataItem.name + ' - ' + dataItem.prefix, showCancelButton: true, confirmButtonText: 'Delete', confirmButtonColor: '#DC3545' }"
                        (confirm)="deletePartFamily(dataItem.partFamilyId)"
                        [disabled]="dataItem.isActive === false || (dataItem.partGroups && dataItem.partGroups.length > 0)">
                        <i class="fa fa-trash"></i>
                      </button>
                    </ng-template>
                  </kendo-grid-column>
                </kendo-grid>
              </div>
            </ng-container>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="card border-top-primary border-top-2 rounded-top-0"
            style="border-top-color: #2196F3 !important;">
            <div class="card-header bg-light header-elements-sm-inline">
              <h6 class="card-title">Part Groups</h6>
              <div class="header-elements">
                <button class="btn btn-light btn-sm"
                  (click)="openPartGroupModal(0, selectedIds[0], selectedIsFishingTool)"
                  [disabled]="selectedIds.length === 0">
                  <i class="fa fa-plus"></i> New Part Group
                </button>
              </div>
            </div>
            <div class="table-responsive">
              <kendo-grid [kendoGridBinding]="partGroups"
                filterable="false"
                [sortable]="true"
                [resizable]="true">
                <kendo-grid-column field="one"
                  title=""
                  [width]="60"
                  filterable="false">
                  <ng-template kendoGridCellTemplate
                    let-dataItem
                    let-rowIndex="rowIndex">
                    <button class="btn btn-xs btn-light"
                      (click)="openPartGroupModal(dataItem.partGroupId, dataItem.partFamilyId, selectedIsFishingTool)"><i
                        class="fa fa-pencil"></i></button>
                  </ng-template>
                </kendo-grid-column>
                <kendo-grid-column field="name"
                  title="Name">
                </kendo-grid-column>
                <kendo-grid-column field="gtPrefix"
                  title="GT Prefix"
                  [width]="100">
                  <ng-template kendoGridCellTemplate
                    let-dataItem
                    let-rowIndex="rowIndex">
                    <span *ngIf="dataItem.gtPrefix">{{dataItem.gtPrefix}}</span>
                    <span *ngIf="!dataItem.gtPrefix"
                      class="text-muted">n/a</span>
                  </ng-template>
                </kendo-grid-column>
                <kendo-grid-column field="one"
                  title=""
                  [width]="60"
                  filterable="false">
                  <ng-template kendoGridCellTemplate
                    let-dataItem
                    let-rowIndex="rowIndex">
                    <button class="btn btn-xs btn-light"
                      [swal]="{ title: 'Delete Part Group?', text: dataItem.name, showCancelButton: true, confirmButtonText: 'Delete', confirmButtonColor: '#DC3545' }"
                      (confirm)="deletePartGroup(dataItem.partGroupId)"
                      [disabled]="dataItem.hasActiveItems">
                      <i class="fa fa-trash"></i>
                    </button>
                  </ng-template>
                </kendo-grid-column>
              </kendo-grid>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
