<div class="card border-top-primary border-top-2 rounded-top-0"
  style="border-top-color: #2196F3 !important;"
  *ngIf="model">
  <div class="card-header bg-light header-elements-inline">
    <h6 class="card-title">Job Safety Analysis
      <span *ngIf="!model.jsaIsComplete || !model.jsaIsComplete.jsaIsComplete"
        class="text-danger pointer">
        <ng-template #popoverTemplate>
          <div>
            <ul class="pl-3 mb-0">
              <li *ngFor="let e of model.jsaIsComplete.errors">{{e}}</li>
            </ul>
          </div>
        </ng-template>
        &nbsp;&nbsp;<i class="fa-solid fa-do-not-enter"
          type="button"
          [popover]="popoverTemplate"
          popoverTitle="JSA - {{jsaIndex}}: Incomplete"
          placement="auto"
          [outsideClick]="true"
          container="body"></i>
      </span>
      <span *ngIf="model.jsaIsComplete && model.jsaIsComplete.jsaIsComplete"
        class="text-success"
        title="JSA Complete">
        &nbsp;&nbsp;<i class="fa-solid fa-circle-check"></i>
      </span>
    </h6>
    <div class="header-elements">
      <div class="switch-container mr-2 d-inline-block"
        *ngIf="jobSubStatusId !== JobSubStatuses.In_Progress && !jobStatusIsTerminal">
        <span class="switch-label">Enable Edit </span>
        <ui-switch [ngModel]="editJsa"
          (change)="enableEditJsa($event)"></ui-switch>
      </div>
      <button class="btn btn-danger btn-sm"
        [swal]="{ title: 'Delete JSA-' + jsaIndex + '?' , text: 'This job must have a JSA for each 7-day period!', showCancelButton: true, confirmButtonText: 'Delete', confirmButtonColor: '#DC3545' }"
        style="float: right"
        (confirm)="deleteJsa()"
        [disabled]="jobStatusIsTerminal || (jobSubStatusId !== JobSubStatuses.In_Progress && !editJsa)"><i
          class="fa fa-trash"></i></button>
    </div>
  </div>
  <div class="card-body"
    [ngClass]="{'pl-2 pr-2':isTablet}">
    <form #heroForm="ngForm">
      <div class="form-group row">
        <label for="title"
          class="text-right control-label col-form-label"
          [ngClass]="{ 'col-md-1': !isTablet, 'col-md-2 ' : isTablet }">Employee</label>
        <div class="col-md-4 form-group mb-0"
          [ngClass]="{ 'col-md-5': !isTablet }"
          *ngIf="userService.handList | async; let hands">
          <ng-select [items]="hands"
            bindLabel="displayName"
            bindValue="id"
            [searchable]="true"
            placeholder="Select Employee"
            [(ngModel)]="model.userId"
            [clearable]="false"
            [disabled]="jobStatusIsTerminal || (jobSubStatusId !== JobSubStatuses.In_Progress && !editJsa)"
            (change)="saveJsa($event)"
            name="employee"
            required>
          </ng-select>
        </div>
        <label for="title"
          class="text-right control-label col-form-label"
          [ngClass]="{ 'col-md-1': !isTablet, 'col-md-2 ' : isTablet }">Date</label>
        <div class="col-md-4 form-group mb-0"
          [ngClass]="{ 'col-md-5': !isTablet }">
          <input type="text"
            placeholder="mm/dd/yyyy"
            class="form-control"
            [(ngModel)]="model.date"
            name="date"
            (bsValueChange)="saveJsa($event)"
            [disabled]="jobStatusIsTerminal || (jobSubStatusId !== JobSubStatuses.In_Progress && !editJsa)"
            required
            bsDatepicker>
        </div>
      </div>
      <div class="form-group row">
        <label for="title"
          class="text-right control-label col-form-label"
          [ngClass]="{ 'col-md-1': !isTablet, 'col-md-2 ' : isTablet }">Location Name</label>
        <div class="col-md-4 form-group mb-0"
          [ngClass]="{ 'col-md-5': !isTablet }">
          <input type="text"
            class="form-control form-control-sm"
            id="description"
            [value]="wellRig"
            name="description"
            readonly
            required>
        </div>
        <label for="title"
          class="text-right control-label col-form-label"
          [ngClass]="{ 'col-md-1': !isTablet, 'col-md-2 ' : isTablet }">Muster Area</label>
        <div class="col-md-4 form-group mb-0"
          [ngClass]="{ 'col-md-5': !isTablet }">
          <textarea type="text"
            class="form-control form-control-sm"
            [(ngModel)]="model.musterArea"
            id="musterArea"
            name="musterArea"
            (blur)="saveJsa($event)"
            (keyup.enter)="blurThis($event)"
            [disabled]="jobStatusIsTerminal || (jobSubStatusId !== JobSubStatuses.In_Progress && !editJsa)"
            required></textarea>
        </div>
      </div>
      <div class="form-group row">
        <label for="title"
          class="text-right control-label col-form-label"
          [ngClass]="{ 'col-md-1': !isTablet, 'col-md-2 ' : isTablet }">Job Description</label>
        <div class="col-md-10 form-group mb-0"
          [ngClass]="{ 'col-md-11': !isTablet }">
          <textarea type="text"
            class="form-control form-control-sm"
            [(ngModel)]="model.jobDescription"
            id="jobDescription"
            name="jobDescription"
            (blur)="saveJsa($event)"
            (keyup.enter)="blurThis($event)"
            [disabled]="jobStatusIsTerminal || (jobSubStatusId !== JobSubStatuses.In_Progress && !editJsa)"
            required></textarea>
        </div>
      </div>
      <hr />
      <h6>Safety Equipment Required</h6>
      <div class="table-responsive mt-3">
        <table class="table table-bordered fixed-table">
          <tbody>
            <tr>
              <td class="pt-1 pb-1">Hard Hat</td>
              <td class="safety-button-tbl-width pt-1 pb-1">
                <button class="btn btn-sm safety-button-width"
                  [ngClass]="{'btn-success': model.hardHat, 'btn-outline-danger': !model.hardHat}"
                  (click)="model.hardHat = !model.hardHat; saveJsa($event)"
                  [disabled]="jobStatusIsTerminal || (jobSubStatusId !== JobSubStatuses.In_Progress && !editJsa)">
                  <span *ngIf="!model.hardHat">
                    <i class="fas fa-times"></i>
                  </span>
                  <span *ngIf="model.hardHat">
                    <i class="fas fa-check"></i>
                  </span>
                </button>
              </td>
              <td class="pt-1 pb-1">Lockout/Tagout Kit</td>
              <td class="safety-button-tbl-width pt-1 pb-1">
                <button class="btn btn-sm safety-button-width"
                  [ngClass]="{'btn-success': model.lockoutTagoutKit, 'btn-outline-danger': !model.lockoutTagoutKit}"
                  (click)="model.lockoutTagoutKit = !model.lockoutTagoutKit; saveJsa($event)"
                  [disabled]="jobStatusIsTerminal || (jobSubStatusId !== JobSubStatuses.In_Progress && !editJsa)">
                  <span *ngIf="!model.lockoutTagoutKit">
                    <i class="fas fa-times"></i>
                  </span>
                  <span *ngIf="model.lockoutTagoutKit">
                    <i class="fas fa-check"></i>
                  </span>
                </button>
              </td>
            </tr>
            <tr>
              <td class="pt-1 pb-1">Safety Shoes</td>
              <td class="safety-button-tbl-width pt-1 pb-1">
                <button class="btn btn-sm safety-button-width"
                  [ngClass]="{'btn-success': model.safetyShoes, 'btn-outline-danger': !model.safetyShoes}"
                  (click)="model.safetyShoes = !model.safetyShoes; saveJsa($event)"
                  [disabled]="jobStatusIsTerminal || (jobSubStatusId !== JobSubStatuses.In_Progress && !editJsa)">
                  <span *ngIf="!model.safetyShoes">
                    <i class="fas fa-times"></i>
                  </span>
                  <span *ngIf="model.safetyShoes">
                    <i class="fas fa-check"></i>
                  </span>
                </button>
              </td>
              <td class="pt-1 pb-1">Wheel Chocks</td>
              <td class="safety-button-tbl-width pt-1 pb-1">
                <button class="btn btn-sm safety-button-width"
                  [ngClass]="{'btn-success': model.wheelChocks, 'btn-outline-danger': !model.wheelChocks}"
                  (click)="model.wheelChocks = !model.wheelChocks; saveJsa($event)"
                  [disabled]="jobStatusIsTerminal || (jobSubStatusId !== JobSubStatuses.In_Progress && !editJsa)">
                  <span *ngIf="!model.wheelChocks">
                    <i class="fas fa-times"></i>
                  </span>
                  <span *ngIf="model.wheelChocks">
                    <i class="fas fa-check"></i>
                  </span>
                </button>
              </td>
            </tr>
            <tr>
              <td class="pt-1 pb-1">Safety Glasses</td>
              <td class="safety-button-tbl-width pt-1 pb-1">
                <button class="btn btn-sm safety-button-width"
                  [ngClass]="{'btn-success': model.safetyGlasses, 'btn-outline-danger': !model.safetyGlasses}"
                  (click)="model.safetyGlasses = !model.safetyGlasses; saveJsa($event)"
                  [disabled]="jobStatusIsTerminal || (jobSubStatusId !== JobSubStatuses.In_Progress && !editJsa)">
                  <span *ngIf="!model.safetyGlasses">
                    <i class="fas fa-times"></i>
                  </span>
                  <span *ngIf="model.safetyGlasses">
                    <i class="fas fa-check"></i>
                  </span>
                </button>
              </td>
              <td class="pt-1 pb-1">First Aid Kit</td>
              <td class="safety-button-tbl-width pt-1 pb-1">
                <button class="btn btn-sm safety-button-width"
                  [ngClass]="{'btn-success': model.firstAidKit, 'btn-outline-danger': !model.firstAidKit}"
                  (click)="model.firstAidKit = !model.firstAidKit; saveJsa($event)"
                  [disabled]="jobStatusIsTerminal || (jobSubStatusId !== JobSubStatuses.In_Progress && !editJsa)">
                  <span *ngIf="!model.firstAidKit">
                    <i class="fas fa-times"></i>
                  </span>
                  <span *ngIf="model.firstAidKit">
                    <i class="fas fa-check"></i>
                  </span>
                </button>
              </td>
            </tr>
            <tr>
              <td class="pt-1 pb-1">FR Clothing</td>
              <td class="safety-button-tbl-width pt-1 pb-1">
                <button class="btn btn-sm safety-button-width"
                  [ngClass]="{'btn-success': model.frClothing, 'btn-outline-danger': !model.frClothing}"
                  (click)="model.frClothing = !model.frClothing; saveJsa($event)"
                  [disabled]="jobStatusIsTerminal || (jobSubStatusId !== JobSubStatuses.In_Progress && !editJsa)">
                  <span *ngIf="!model.frClothing">
                    <i class="fas fa-times"></i>
                  </span>
                  <span *ngIf="model.frClothing">
                    <i class="fas fa-check"></i>
                  </span>
                </button>
              </td>
              <td class="pt-1 pb-1">Light Plant</td>
              <td class="safety-button-tbl-width pt-1 pb-1">
                <button class="btn btn-sm safety-button-width"
                  [ngClass]="{'btn-success': model.lightPlant, 'btn-outline-danger': !model.lightPlant}"
                  (click)="model.lightPlant = !model.lightPlant; saveJsa($event)"
                  [disabled]="jobStatusIsTerminal || (jobSubStatusId !== JobSubStatuses.In_Progress && !editJsa)">
                  <span *ngIf="!model.lightPlant">
                    <i class="fas fa-times"></i>
                  </span>
                  <span *ngIf="model.lightPlant">
                    <i class="fas fa-check"></i>
                  </span>
                </button>
              </td>
            </tr>
            <tr>
              <td class="pt-1 pb-1">Air Monitor</td>
              <td class="safety-button-tbl-width pt-1 pb-1">
                <button class="btn btn-sm safety-button-width"
                  [ngClass]="{'btn-success': model.airMonitor, 'btn-outline-danger': !model.airMonitor}"
                  (click)="model.airMonitor = !model.airMonitor; saveJsa($event)"
                  [disabled]="jobStatusIsTerminal || (jobSubStatusId !== JobSubStatuses.In_Progress && !editJsa)">
                  <span *ngIf="!model.airMonitor">
                    <i class="fas fa-times"></i>
                  </span>
                  <span *ngIf="model.airMonitor">
                    <i class="fas fa-check"></i>
                  </span>
                </button>
              </td>
              <td class="pt-1 pb-1">Reflective Vest</td>
              <td class="safety-button-tbl-width pt-1 pb-1">
                <button class="btn btn-sm safety-button-width"
                  [ngClass]="{'btn-success': model.reflectiveVest, 'btn-outline-danger': !model.reflectiveVest}"
                  (click)="model.reflectiveVest = !model.reflectiveVest; saveJsa($event)"
                  [disabled]="jobStatusIsTerminal || (jobSubStatusId !== JobSubStatuses.In_Progress && !editJsa)">
                  <span *ngIf="!model.reflectiveVest">
                    <i class="fas fa-times"></i>
                  </span>
                  <span *ngIf="model.reflectiveVest">
                    <i class="fas fa-check"></i>
                  </span>
                </button>
              </td>
            </tr>
            <tr>
              <td class="pt-1 pb-1">Hearing Protection</td>
              <td class="safety-button-tbl-width pt-1 pb-1">
                <button class="btn btn-sm safety-button-width"
                  [ngClass]="{'btn-success': model.hearingProtection, 'btn-outline-danger': !model.hearingProtection}"
                  (click)="model.hearingProtection = !model.hearingProtection; saveJsa($event)"
                  [disabled]="jobStatusIsTerminal || (jobSubStatusId !== JobSubStatuses.In_Progress && !editJsa)">
                  <span *ngIf="!model.hearingProtection">
                    <i class="fas fa-times"></i>
                  </span>
                  <span *ngIf="model.hearingProtection">
                    <i class="fas fa-check"></i>
                  </span>
                </button>
              </td>
              <td class="pt-1 pb-1">Head Lamp</td>
              <td class="safety-button-tbl-width pt-1 pb-1">
                <button class="btn btn-sm safety-button-width"
                  [ngClass]="{'btn-success': model.headLamp, 'btn-outline-danger': !model.headLamp}"
                  (click)="model.headLamp = !model.headLamp; saveJsa($event)"
                  [disabled]="jobStatusIsTerminal || (jobSubStatusId !== JobSubStatuses.In_Progress && !editJsa)">
                  <span *ngIf="!model.headLamp">
                    <i class="fas fa-times"></i>
                  </span>
                  <span *ngIf="model.headLamp">
                    <i class="fas fa-check"></i>
                  </span>
                </button>
              </td>
            </tr>
            <tr>
              <td class="pt-1 pb-1">Face Shield</td>
              <td class="safety-button-tbl-width pt-1 pb-1">
                <button class="btn btn-sm safety-button-width"
                  [ngClass]="{'btn-success': model.faceShield, 'btn-outline-danger': !model.faceShield}"
                  (click)="model.faceShield = !model.faceShield; saveJsa($event)"
                  [disabled]="jobStatusIsTerminal || (jobSubStatusId !== JobSubStatuses.In_Progress && !editJsa)">
                  <span *ngIf="!model.faceShield">
                    <i class="fas fa-times"></i>
                  </span>
                  <span *ngIf="model.faceShield">
                    <i class="fas fa-check"></i>
                  </span>
                </button>
              </td>
              <td class="pt-1 pb-1">Permits Filled Out</td>
              <td class="safety-button-tbl-width pt-1 pb-1">
                <button class="btn btn-sm safety-button-width"
                  [ngClass]="{'btn-success': model.permitsFilledOut, 'btn-outline-danger': !model.permitsFilledOut}"
                  (click)="model.permitsFilledOut = !model.permitsFilledOut; saveJsa($event)"
                  [disabled]="jobStatusIsTerminal || (jobSubStatusId !== JobSubStatuses.In_Progress && !editJsa)">
                  <span *ngIf="!model.permitsFilledOut">
                    <i class="fas fa-times"></i>
                  </span>
                  <span *ngIf="model.permitsFilledOut">
                    <i class="fas fa-check"></i>
                  </span>
                </button>
              </td>
            </tr>
            <tr>
              <td class="pt-1 pb-1">Safety Harness</td>
              <td class="safety-button-tbl-width pt-1 pb-1">
                <button class="btn btn-sm safety-button-width"
                  [ngClass]="{'btn-success': model.safetyHarness, 'btn-outline-danger': !model.safetyHarness}"
                  (click)="model.safetyHarness = !model.safetyHarness; saveJsa($event)"
                  [disabled]="jobStatusIsTerminal || (jobSubStatusId !== JobSubStatuses.In_Progress && !editJsa)">
                  <span *ngIf="!model.safetyHarness">
                    <i class="fas fa-times"></i>
                  </span>
                  <span *ngIf="model.safetyHarness">
                    <i class="fas fa-check"></i>
                  </span>
                </button>
              </td>
              <td class="pt-1 pb-1">Other</td>
              <td class="safety-button-tbl-width pt-1 pb-1">
                <button class="btn btn-sm safety-button-width"
                  [ngClass]="{'btn-success': model.otherSafetyEquipment, 'btn-outline-danger': !model.otherSafetyEquipment}"
                  (click)="model.otherSafetyEquipment = !model.otherSafetyEquipment; saveJsa($event)"
                  [disabled]="jobStatusIsTerminal || (jobSubStatusId !== JobSubStatuses.In_Progress && !editJsa)">
                  <span *ngIf="!model.otherSafetyEquipment">
                    <i class="fas fa-times"></i>
                  </span>
                  <span *ngIf="model.otherSafetyEquipment">
                    <i class="fas fa-check"></i>
                  </span>
                </button>
              </td>
            </tr>
            <tr *ngIf="model.otherSafetyEquipment">
              <td>Other Description</td>
              <td colspan="3">
                <textarea type="text"
                  class="form-control form-control-sm"
                  [(ngModel)]="model.otherSafetyEquipmentDescription"
                  id="otherEquipDescription"
                  name="otherEquipDescription"
                  (blur)="saveJsa($event)"
                  (keyup.enter)="blurThis($event)"
                  [disabled]="jobStatusIsTerminal || (jobSubStatusId !== JobSubStatuses.In_Progress && !editJsa)"></textarea>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <hr />
      <h6>Working Conditions (Yes/No)</h6>
      <div class="table-responsive my-3">
        <table class="table table-bordered fixed-table">
          <tbody>
            <tr>
              <td class="pt-1 pb-1">Adequate Lighting</td>
              <td class="safety-button-tbl-width pt-1 pb-1">
                <button class="btn btn-sm safety-button-width"
                  [ngClass]="{'btn-success': model.adequateLighting, 'btn-outline-danger': !model.adequateLighting}"
                  (click)="model.adequateLighting = !model.adequateLighting; saveJsa($event)"
                  [disabled]="jobStatusIsTerminal || (jobSubStatusId !== JobSubStatuses.In_Progress && !editJsa)">
                  <span *ngIf="!model.adequateLighting">
                    <i class="fas fa-times"></i>
                  </span>
                  <span *ngIf="model.adequateLighting">
                    <i class="fas fa-check"></i>
                  </span>
                </button>
              </td>
              <td class="pt-1 pb-1">Confined Space Entry</td>
              <td class="safety-button-tbl-width pt-1 pb-1">
                <button class="btn btn-sm safety-button-width"
                  [ngClass]="{'btn-success': model.confinedSpaceEntry, 'btn-outline-danger': !model.confinedSpaceEntry}"
                  (click)="model.confinedSpaceEntry = !model.confinedSpaceEntry; saveJsa($event)"
                  [disabled]="jobStatusIsTerminal || (jobSubStatusId !== JobSubStatuses.In_Progress && !editJsa)">
                  <span *ngIf="!model.confinedSpaceEntry">
                    <i class="fas fa-times"></i>
                  </span>
                  <span *ngIf="model.confinedSpaceEntry">
                    <i class="fas fa-check"></i>
                  </span>
                </button>
              </td>
            </tr>
            <tr>
              <td class="pt-1 pb-1">Extreme Heat/Cold</td>
              <td class="safety-button-tbl-width pt-1 pb-1">
                <button class="btn btn-sm safety-button-width"
                  [ngClass]="{'btn-success': model.extremeHeatCold, 'btn-outline-danger': !model.extremeHeatCold}"
                  (click)="model.extremeHeatCold = !model.extremeHeatCold; saveJsa($event)"
                  [disabled]="jobStatusIsTerminal || (jobSubStatusId !== JobSubStatuses.In_Progress && !editJsa)">
                  <span *ngIf="!model.extremeHeatCold">
                    <i class="fas fa-times"></i>
                  </span>
                  <span *ngIf="model.extremeHeatCold">
                    <i class="fas fa-check"></i>
                  </span>
                </button>
              </td>
              <td class="pt-1 pb-1">Ice/Snow/Deep Mud</td>
              <td class="safety-button-tbl-width pt-1 pb-1">
                <button class="btn btn-sm safety-button-width"
                  [ngClass]="{'btn-success': model.iceSnowDeepMud, 'btn-outline-danger': !model.iceSnowDeepMud}"
                  (click)="model.iceSnowDeepMud = !model.iceSnowDeepMud; saveJsa($event)"
                  [disabled]="jobStatusIsTerminal || (jobSubStatusId !== JobSubStatuses.In_Progress && !editJsa)">
                  <span *ngIf="!model.iceSnowDeepMud">
                    <i class="fas fa-times"></i>
                  </span>
                  <span *ngIf="model.iceSnowDeepMud">
                    <i class="fas fa-check"></i>
                  </span>
                </button>
              </td>
            </tr>
            <tr>
              <td class="pt-1 pb-1">H2S/LEL/Low O2/High O2</td>
              <td class="safety-button-tbl-width pt-1 pb-1">
                <button class="btn btn-sm safety-button-width"
                  [ngClass]="{'btn-success': model.h2sLelLowO2HighO2, 'btn-outline-danger': !model.h2sLelLowO2HighO2}"
                  (click)="model.h2sLelLowO2HighO2 = !model.h2sLelLowO2HighO2; saveJsa($event)"
                  [disabled]="jobStatusIsTerminal || (jobSubStatusId !== JobSubStatuses.In_Progress && !editJsa)">
                  <span *ngIf="!model.h2sLelLowO2HighO2">
                    <i class="fas fa-times"></i>
                  </span>
                  <span *ngIf="model.h2sLelLowO2HighO2">
                    <i class="fas fa-check"></i>
                  </span>
                </button>
              </td>
              <td class="pt-1 pb-1">Other</td>
              <td class="safety-button-tbl-width pt-1 pb-1">
                <button class="btn btn-sm safety-button-width"
                  [ngClass]="{'btn-success': model.otherWorkingConditions, 'btn-outline-danger': !model.otherWorkingConditions}"
                  (click)="model.otherWorkingConditions = !model.otherWorkingConditions; saveJsa($event)"
                  [disabled]="jobStatusIsTerminal || (jobSubStatusId !== JobSubStatuses.In_Progress && !editJsa)">
                  <span *ngIf="!model.otherWorkingConditions">
                    <i class="fas fa-times"></i>
                  </span>
                  <span *ngIf="model.otherWorkingConditions">
                    <i class="fas fa-check"></i>
                  </span>
                </button>
              </td>
            </tr>
            <tr *ngIf="model.otherWorkingConditions">
              <td>Other Description</td>
              <td colspan="3">
                <textarea type="text"
                  class="form-control form-control-sm"
                  [(ngModel)]="model.otherWorkingConditionsDescription"
                  id="otherConditionDescription"
                  name="description"
                  (blur)="saveJsa($event)"
                  (keyup.enter)="blurThis($event)"
                  [disabled]="jobStatusIsTerminal || (jobSubStatusId !== JobSubStatuses.In_Progress && !editJsa)"></textarea>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="table-responsive mt-4 mb-4">
        <table class="table table-bordered">
          <thead>
            <tr class="table-active">
              <th scope="col"
                class="pt-0 pb-0">Basic Job Steps</th>
              <th scope="col"
                class="pt-0 pb-0">Potential Risks/Hazards</th>
              <th scope="col"
                class="pt-0 pb-0">Actions Taken to Reduce Risk/Hazard</th>
              <th class="pt-1 pb-1">
                <button class="btn btn-primary btn-sm"
                  title="Add Signature Row"
                  (click)="addJobStepRow()"
                  [disabled]="jobStatusIsTerminal || (jobSubStatusId !== JobSubStatuses.In_Progress && !editJsa)">
                  <i class="fa fa-plus"></i>
                </button>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let r of model.jobSafetyAnalysisJobSteps;let i = index;">
              <td>
                <textarea type="text"
                  class="form-control form-control-sm"
                  [(ngModel)]="r.basicJobSteps"
                  id="jobStep_{{i}}"
                  name="jobStep_{{i}}"
                  [disabled]="jobStatusIsTerminal || (jobSubStatusId !== JobSubStatuses.In_Progress && !editJsa)"
                  (blur)="saveJobStepRow(r)"
                  (keyup.enter)="blurThis($event)"
                  required></textarea>
              </td>
              <td>
                <textarea type="text"
                  class="form-control form-control-sm"
                  [(ngModel)]="r.potentialRisksHazards"
                  id="risk_{{i}}"
                  name="risk_{{i}}"
                  [disabled]="jobStatusIsTerminal || (jobSubStatusId !== JobSubStatuses.In_Progress && !editJsa)"
                  (blur)="saveJobStepRow(r)"
                  (keyup.enter)="blurThis($event)"
                  required></textarea>
              </td>
              <td>
                <textarea type="text"
                  class="form-control form-control-sm"
                  [(ngModel)]="r.actionsTakenToReduce"
                  id="mitigate_{{i}}"
                  name="mitigate_{{i}}"
                  [disabled]="jobStatusIsTerminal || (jobSubStatusId !== JobSubStatuses.In_Progress && !editJsa)"
                  (blur)="saveJobStepRow(r)"
                  (keyup.enter)="blurThis($event)"
                  required></textarea>
              </td>
              <td class="pr-2 pl-2 tlb-dlt-width">
                <button class="btn btn-xs btn-light"
                  [swal]="{ title: 'Delete Job Step?', text: 'This cannot be undone!', showCancelButton: true, confirmButtonText: 'Delete', confirmButtonColor: '#DC3545' }"
                  (confirm)="deleteJobStepRow(r.jobSafetyAnalysisJobStepId)"
                  [disabled]="jobStatusIsTerminal || (model.jobSafetyAnalysisJobSteps && model.jobSafetyAnalysisJobSteps.length === 1) || (jobSubStatusId !== JobSubStatuses.In_Progress && !editJsa)"
                  *ngIf="r.jobSafetyAnalysisJobStepId">
                  <i class="fa fa-trash"></i>
                </button>
                <button class="btn btn-xs btn-light"
                  *ngIf="!r.jobSafetyAnalysisJobStepId"
                  (click)="deleteJobStepRow(r.jobSafetyAnalysisJobStepId)"
                  [disabled]="jobStatusIsTerminal || (model.jobSafetyAnalysisJobSteps && model.jobSafetyAnalysisJobSteps.length === 1) || (jobSubStatusId !== JobSubStatuses.In_Progress && !editJsa)">
                  <i class="fa fa-trash"></i>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="form-group row mt-3">
        <div class="col-md-12 mb-2"
          *ngIf="(!jobStatusIsTerminal
            && jobSubStatusId === JobSubStatuses.In_Progress) || editJsa">
          <div class="form-group row">
            <div class="col-md-3">
              <button (click)="getJsaWeather()"
                class="btn btn-sm btn-primary float-right"
                *ngIf="!model.temperature && !model.weatherId && !model.windDirectionId && !model.windSpeedId">Get Local
                Weather</button>
              <button (confirm)="getJsaWeather()"
                [swal]="{ title: 'Get Local Weather?', text: 'Any weather data entered onto this JSA will be replaced!', showCancelButton: true, confirmButtonText: 'Get', confirmButtonColor: '#1B6EC2' }"
                class="btn btn-sm btn-primary float-right"
                *ngIf="model.temperature || model.weatherId || model.windDirectionId || model.windSpeedId">Get Local
                Weather</button>
            </div>
            <div class="col-md-9">
              <div class="alert alert-secondary"
                role="alert"
                *ngIf="!currentWeather">
                <i class="fas fa-do-not-enter"></i>&nbsp;&nbsp;No Local Weather Data
              </div>
              <div class="alert alert-info"
                role="alert"
                *ngIf="currentWeather">
                <h5 class="mb-0">Local Weather for <strong>{{currentWeather.name}}, {{currentWeather.region}}</strong>
                </h5>
                (Last Updated <strong>{{currentWeather.lastUpdated}})</strong>
                <br />
                <br />
                <div class="row">
                  <div class="col-6">
                    <strong>Wind Speed:</strong> {{currentWeather.wind_mph}} mph
                    <br />
                    <strong>Wind Direction:</strong> {{currentWeather.wind_dir}}
                  </div>
                  <div class="col-6">
                    <strong>Temperature:</strong> {{currentWeather.temperatureRounded}}°F
                    <br />
                    <strong>Weather:</strong> {{currentWeather.condition}}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <label for="title"
          class="control-label col-form-label text-right pl-0"
          [ngClass]="{ 'col-md-2' : !isTablet, 'col-md-3' : isTablet }">Wind Speed/Direction</label>
        <div class="col-md-2 form-group mb-0"
          *ngIf="windSpeeds">
          <ng-select [items]="windSpeeds"
            bindLabel="name"
            bindValue="jobSafetyAnalysisWindSpeedId"
            [searchable]="true"
            placeholder="Speed"
            [(ngModel)]="model.windSpeedId"
            (change)="saveJsa($event)"
            (clear)="saveJsa($event, true)"
            name="windSpeedId"
            [disabled]="jobStatusIsTerminal || (jobSubStatusId !== JobSubStatuses.In_Progress && !editJsa)">
          </ng-select>
        </div>
        <div class="col-md-2 form-group mb-0"
          *ngIf="windDirections">
          <ng-select [items]="windDirections"
            bindLabel="name"
            bindValue="jobSafetyAnalysisWindDirectionId"
            [searchable]="true"
            placeholder="Direction"
            [(ngModel)]="model.windDirectionId"
            (change)="saveJsa($event)"
            (clear)="saveJsa($event, true)"
            name="windDirectionId"
            [disabled]="jobStatusIsTerminal || (jobSubStatusId !== JobSubStatuses.In_Progress && !editJsa)">
          </ng-select>
        </div>
        <label for="title"
          class="col-md-1 control-label col-form-label text-right pr-0 pl-0">Weather</label>
        <div class="form-group mb-0 pl-3"
          [ngClass]="{ 'col-md-5' : !isTablet, 'col-md-4' : isTablet }"
          *ngIf="weatherTypes">
          <ng-select [items]="weatherTypes"
            bindLabel="name"
            bindValue="jobSafetyAnalysisWeatherId"
            [searchable]="true"
            placeholder="Weather"
            [(ngModel)]="model.weatherId"
            (change)="saveJsa($event)"
            (clear)="saveJsa($event, true)"
            name="weatherId"
            [disabled]="jobStatusIsTerminal || (jobSubStatusId !== JobSubStatuses.In_Progress && !editJsa)">
          </ng-select>
        </div>
      </div>
      <div class="form-group row mt-2">
        <label for="title"
          class="control-label col-form-label text-right pl-0"
          [ngClass]="{ 'col-md-2' : !isTablet, 'col-md-3' : isTablet }">Temperature&nbsp;&nbsp;
          <span class="text-primary pointer">
            <ng-template #popoverTemplateInv>
              <p>Please enter a positive or negative whole number indicating the temperature in Fahrenheit.</p>
              <p>Decimals will be rounded off to the nearest whole number.</p>
              <p>Temperatures can range from -70°F to 140°F.</p>
            </ng-template>
            <i class="fas fa-info-circle"
              type="button"
              [popover]="popoverTemplateInv"
              popoverTitle="Temperature"
              placement="auto"
              [outsideClick]="true"
              container="body"></i>
          </span>
        </label>
        <div class="col-md-2 form-group mb-0">
          <div class="input-group-append input-group-sm">
            <input type="number"
              step="1"
              class="form-control form-control-sm"
              id="taxRate"
              [(ngModel)]="model.temperature"
              (blur)="onBlurOfTemp(); saveJsa($event)"
              (keyup.enter)="blurThis($event)"
              name="taxRate"
              [disabled]="jobStatusIsTerminal || (jobSubStatusId !== JobSubStatuses.In_Progress && !editJsa)">
            <div class="input-group-append">
              <span class="input-group-text">
                °F
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="form-group row">
        <label for="title"
          class="text-right control-label col-form-label"
          [ngClass]="{ 'col-md-2' : !isTablet, 'col-md-3' : isTablet }">Other Contractors On Site</label>
        <div class="col-md-9 form-group mb-0"
          [ngClass]="{ 'col-md-10' : !isTablet, 'col-md-9' : isTablet }"
          *ngIf="contractorTypes">
          <ng-select bindLabel="name"
            bindValue="contractorTypeId"
            [items]="contractorTypes"
            [multiple]="true"
            [closeOnSelect]="false"
            [(ngModel)]="model.contractorTypeIds"
            [searchable]="false"
            name="contractors"
            (change)="onChangeOfContractor(); saveJsa($event)"
            [disabled]="jobStatusIsTerminal || (jobSubStatusId !== JobSubStatuses.In_Progress && !editJsa)">
          </ng-select>
        </div>
      </div>
      <div class="form-group row"
        *ngIf="showOtherContractors">
        <label for="title"
          class="text-right control-label col-form-label"
          [ngClass]="{ 'col-md-2' : !isTablet, 'col-md-3' : isTablet }">Other Contractor Description</label>
        <div class="col-md-8 form-group mb-0"
          [ngClass]="{ 'col-md-10' : !isTablet, 'col-md-9' : isTablet }">
          <textarea type="text"
            class="form-control form-control-sm"
            [(ngModel)]="model.otherContractorDescription"
            id="otherContractorDescription"
            name="otherContractorDescription"
            (blur)="saveJsa($event)"
            (keyup.enter)="blurThis($event)"
            [disabled]="jobStatusIsTerminal || (jobSubStatusId !== JobSubStatuses.In_Progress && !editJsa)"></textarea>
        </div>
      </div>
      <div class="form-group row">
        <label for="title"
          class="text-right control-label col-form-label"
          [ngClass]="{ 'col-md-3' : !isTablet, 'col-md-4' : isTablet }">Company Names (i.e. Vendors on Site)</label>
        <div class="col-md-8 form-group mb-0"
          [ngClass]="{ 'col-md-9' : !isTablet, 'col-md-8' : isTablet }">
          <textarea type="text"
            class="form-control form-control-sm"
            [(ngModel)]="model.companyNames"
            id="companyNames"
            name="companyNames"
            (blur)="saveJsa($event)"
            (keyup.enter)="blurThis($event)"
            [disabled]="jobStatusIsTerminal || (jobSubStatusId !== JobSubStatuses.In_Progress && !editJsa)"></textarea>
        </div>
      </div>
      <hr />
      <h6 class="d-inline-block">Certification</h6>
      <p><strong>I certify that I have done the PPE assessment above & reviewed the JSA with all crew members.</strong>
      </p>
      <div class="form-group row">
        <label for="title"
          class="control-label col-form-label pr-0"
          [ngClass]="{ 'col-md-2': !isTablet, 'col-md-3 ' : isTablet }">Assessor's Signature</label>
        <div class="col-md-6 form-group mb-0 pl-0">
          <input type="text"
            class="form-control form-control-sm"
            [(ngModel)]="model.assessorSignature"
            id="assessorSignature"
            name="assessorSignature"
            (blur)="saveJsa($event)"
            (keyup.enter)="blurThis($event)"
            [disabled]="jobStatusIsTerminal || (jobSubStatusId !== JobSubStatuses.In_Progress && !editJsa)"
            required>
        </div>
      </div>
      <div class="table-responsive mt-1">
        <table class="table table-bordered">
          <thead>
            <tr class="table-active">
              <th scope="col"
                colspan="2"
                class="pt-0 pb-0">Crew Signatures</th>
              <th class="tlb-dlt-width pt-1 pb-1">
                <button class="btn btn-primary btn-sm"
                  title="Add Signature Row"
                  (click)="addSignatureRow()"
                  [disabled]="jobStatusIsTerminal || (jobSubStatusId !== JobSubStatuses.In_Progress && !editJsa)">
                  <i class="fa fa-plus"></i>
                </button>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let r of model.jobSafetyAnalysisCrewSignatures;let i = index;">
              <td>
                Name
              </td>
              <td>
                <input type="text"
                  class="form-control form-control-sm"
                  [(ngModel)]="r.name"
                  id="crewSignature_{{i}}"
                  name="crewSignature_{{i}}"
                  (blur)="saveSignatureRow(r)"
                  (keyup.enter)="blurThis($event)"
                  [disabled]="jobStatusIsTerminal || (jobSubStatusId !== JobSubStatuses.In_Progress && !editJsa)"
                  required>
              </td>
              <td class="pr-2 pl-2 tlb-dlt-width">
                <button class="btn btn-xs btn-light"
                  *ngIf="r.jobSafetyAnalysisCrewSignatureId"
                  [swal]="{ title: 'Delete Crew Signature?', text: 'This cannot be undone!', showCancelButton: true, confirmButtonText: 'Delete', confirmButtonColor: '#DC3545' }"
                  (confirm)="deleteSignatureRow(r.jobSafetyAnalysisCrewSignatureId)"
                  [disabled]="jobStatusIsTerminal || (model.jobSafetyAnalysisCrewSignatures && model.jobSafetyAnalysisCrewSignatures.length === 1) || (jobSubStatusId !== JobSubStatuses.In_Progress && !editJsa)">
                  <i class="fa fa-trash"></i>
                </button>
                <button class="btn btn-xs btn-light"
                  *ngIf="!r.jobSafetyAnalysisCrewSignatureId"
                  (click)="deleteSignatureRow(r.jobSafetyAnalysisCrewSignatureId)"
                  [disabled]="jobStatusIsTerminal || (model.jobSafetyAnalysisCrewSignatures && model.jobSafetyAnalysisCrewSignatures.length === 1) || (jobSubStatusId !== JobSubStatuses.In_Progress && !editJsa)">
                  <i class="fa fa-trash"></i>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <hr />
      <h6>Emergency Evacuation Strategy</h6>
      <p><strong>Discuss any and all specific concerns that would prevent safe evacuation.</strong></p>
      <ol>
        <li>
          Turn equipment off
        </li>
        <li>
          Gather at muster point
        </li>
        <li>
          Sign back of JSA (check personnel in)
        </li>
        <li>
          Report to client representative & supervisor IMMEDIATELY (911 if necessary)
        </li>
      </ol>
    </form>
  </div>
</div>
