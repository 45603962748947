import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { PurchaseOrderTypeModel } from '../models/purchase-order-type.model';
import { tap } from 'rxjs/operators';

@Injectable()
export class PurchaseOrderTypeService {
  constructor(
    private http: HttpClient,
    @Inject('BASE_URL') private baseUrl: string
  ) { }

  private apiUrl = 'api/PurchaseOrderType';  // URL to web api

  private poTypeListSubject = new BehaviorSubject<PurchaseOrderTypeModel[]>(null);
  public _poTypeList$ = this.poTypeListSubject.asObservable();
  private isPoTypeCallInProgress = false;

  public get poTypeList$(): Observable<PurchaseOrderTypeModel[]> {
    if (!this.poTypeListSubject.getValue() && !this.isPoTypeCallInProgress) {
      this.isPoTypeCallInProgress = true;
      this.getPurchaseOrderTypes$(false).subscribe(() => this.isPoTypeCallInProgress = false);
    }

    return this._poTypeList$;
  }

  private getPurchaseOrderTypes$(showInactive: boolean): Observable<PurchaseOrderTypeModel[]> {
    return this.http.get<Array<PurchaseOrderTypeModel>>(this.baseUrl + this.apiUrl + '/GetPurchaseOrderTypes/' + showInactive)
      .pipe(
        tap((x: PurchaseOrderTypeModel[]) => {
          this.poTypeListSubject.next(x);
        })
      );
  }

}
