export class WorkLogModel {
  workLogId: number;
  dayId: number;
  workStart: Date | string | null;
  workEnd: Date | string | null;
  description: string;
  employeeFullName: string;
  employeeId: number | null;
  billingCodeId: number | null;
  billingCodeName: string;
  mileage: number | null;
  isActive: boolean | null;

  jobId: number | null;
  hours: number | null;
}
