<div class="page-header">
  <div class="page-header-content header-elements-md-inline">
    <div class="page-title d-flex">
      <a [routerLink]="['/purchase-orders/list']"
        class="breadcrumb-link-color">
        <h4><i class="icon-arrow-left52 mr-2"></i> <span class="font-weight-semibold">Purchase Order</span> - Details
        </h4>
      </a>
      <button type="button"
        class="btn btn-outline-dark btn-sm ml-3 pl-1 pr-1 pt-0 pb-0 no-border"
        [title]="!showDetails ? 'Show Details' : 'Hide Details'"
        (click)="showDetails = !showDetails">
        <span *ngIf="!showDetails">
          <i class="fa-regular fa-chevrons-right"></i>&nbsp;Show </span>
        <span *ngIf="showDetails"><i class="fa-regular fa-chevrons-left"></i>&nbsp;Hide
        </span>Details</button>
    </div>
    <div class="header-elements d-none py-0 mb-3 mb-md-0">
      <div class="breadcrumb">
        <a href="index.html"
          class="breadcrumb-item"><i class="icon-home2 mr-2"></i> Purchase Order</a>
        <span class="breadcrumb-item active">Detail</span>
      </div>
    </div>
  </div>
</div>
<div class="page-content pt-0"
  *ngIf="poService.purchaseOrder | async; let model">
  <div class="content-wrapper">
    <div class="content">
      <div class="row">
        <div class="col-md-3"
          *ngIf="showDetails">
          <div class="card">
            <div class="card-header bg-light header-elements-inline"
              [ngClass]="{'pr-2 pl-2':isTablet}">
              <h6 class="card-title">PO#: {{model.ponumberDisplay}} - <span *ngIf="model.isActive">
                  <strong>{{model.total | currency}}</strong></span><span *ngIf="!model.isActive"
                  class="text-danger"><strong>&nbsp;DELETED</strong></span></h6>
              <div class="header-elements">
                <button class="btn btn-light btn-sm"
                  (click)="openPoModal(model.poid)"
                  [disabled]="poService.purchaseOrder.value && (poService.purchaseOrder.value.derivedStatusId === PurchaseOrderStatuses.Received || !poService.purchaseOrder.value.isActive)"><i
                    class="fa fa-edit"></i><span *ngIf="!isTablet"> Edit</span></button>
              </div>
            </div>
            <div class="card-body"
              [ngClass]="{'pr-2 pl-2':isTablet}">
              <div id="collapseBasic"
                [collapse]="isCollapsed">
                <dl class="row">
                  <dt class="col-sm-4 text-right">Vendor</dt>
                  <dd class="col-sm-8"
                    [ngClass]="{'pl-0':isTablet}">{{model.vendorName}}</dd>
                  <dt class="col-sm-4 text-right"
                    *ngIf="model.vendorContact">Contact</dt>
                  <dd class="col-sm-8"
                    [ngClass]="{'pl-0':isTablet}"
                    *ngIf="model.vendorContact">{{model.vendorContact}}</dd>
                  <dt class="col-sm-4 text-right"
                    *ngIf="model.vendorPhone">Phone</dt>
                  <dd class="col-sm-8"
                    [ngClass]="{'pl-0':isTablet}"
                    *ngIf="model.vendorPhone">{{model.vendorPhone}}</dd>
                  <dt class="col-sm-4 text-right"
                    *ngIf="model.vendorEmail">Email</dt>
                  <dd class="col-sm-8"
                    [ngClass]="{'pl-0':isTablet}"
                    *ngIf="model.vendorEmail">{{model.vendorEmail}}</dd>
                  <dt class="col-sm-4 text-right"><span *ngIf="!isTablet">Payment </span>Terms</dt>
                  <dd class="col-sm-8"
                    [ngClass]="{'pl-0':isTablet}">
                    <span *ngIf="model.vendorPaymentTerms">{{model.vendorPaymentTerms}}</span>
                    <span *ngIf="!model.vendorPaymentTerms"
                      class="text-muted">n/a</span>
                  </dd>
                  <dt class="col-sm-4 text-right">Description</dt>
                  <dd class="col-sm-8"
                    [ngClass]="{'pl-0':isTablet}">{{model.description}}</dd>
                  <dt class="col-sm-4 text-right">PO Date</dt>
                  <dd class="col-sm-8"
                    [ngClass]="{'pl-0':isTablet}">{{model.podate| date}}</dd>
                  <dt class="col-sm-4 text-right">Ship Method</dt>
                  <dd class="col-sm-8"
                    [ngClass]="{'pl-0':isTablet}"><span *ngIf="model.shipMethod">{{model.shipMethod}}</span><span
                      *ngIf="!model.shipMethod"
                      class="text-muted">n/a</span></dd>
                  <dt class="col-sm-4 text-right">Delivery Date</dt>
                  <dd class="col-sm-8"
                    [ngClass]="{'pl-0':isTablet}">{{model.deliveryDate | date}}</dd>
                  <dt class="col-sm-4 text-right">Status</dt>
                  <dd class="col-sm-8"
                    [ngClass]="{'pl-0':isTablet}">{{model.derivedStatusName}}</dd>
                  <dt class="col-sm-4 text-right">Created By</dt>
                  <dd class="col-sm-8"
                    [ngClass]="{'pl-0':isTablet}">{{model.createdByName}}</dd>
                  <dt class="col-sm-4 text-right"
                    *ngIf="model.shopName">Shop</dt>
                  <dd class="col-sm-8"
                    [ngClass]="{'pl-0':isTablet}"
                    *ngIf="model.shopName">{{model.shopName}}</dd>
                  <dt class="col-sm-4 text-right"
                    *ngIf="model.accountName">Account</dt>
                  <dd class="col-sm-8"
                    [ngClass]="{'pl-0':isTablet}"
                    *ngIf="model.accountName">{{model.accountName}}</dd>
                  <dt class="col-sm-4 text-right">Notes</dt>
                  <dd class="col-sm-8"
                    [ngClass]="{'pl-0':isTablet}">{{model.ponotes}}</dd>
                  <dt class="col-sm-4 text-right"
                    *ngIf="model.jobId">Invoice</dt>
                  <dd class="col-sm-8"
                    [ngClass]="{'pl-0':isTablet}"
                    *ngIf="model.jobId">
                    <span *ngIf="model.jobTypeId === JobTypes.Fishing"><a
                        [routerLink]="['/jobs/fishing/details', model.jobId]">{{model.jobDisplay}}</a></span>
                    <span *ngIf="model.jobTypeId === JobTypes.FishingToolRental"><a
                        [routerLink]="['/jobs/fishing-tool-rental/details', model.jobId]">{{model.jobDisplay}}</a></span>
                    <span *ngIf="model.jobTypeId === JobTypes.Sales"><a
                        [routerLink]="['/jobs/sales/details', model.jobId]">{{model.jobDisplay}}</a></span>
                    <span *ngIf="model.jobTypeId === JobTypes.Packer"><a
                        [routerLink]="['/jobs/packer/details', model.jobId]">{{model.jobDisplay}}</a></span>
                  </dd>
                </dl>
                <br>
              </div>
              <button (click)="reportService.getPurchaseOrderPDF(model.poid)"
                class="btn btn-light btn-sm btn-block"><i class="fal fa-file-pdf"></i> Print</button>
              <!-- PO Status Buttons -->
              <button type="button"
                class="btn btn-primary btn-block"
                *ngIf="poService.purchaseOrder.value && poService.purchaseOrder.value.derivedStatusId === PurchaseOrderStatuses.Draft"
                (click)="batchStatusChange(PurchaseOrderStatuses.PendingApproval)"
                [disabled]="poService.purchaseOrder.value.poLines === null || poService.purchaseOrder.value.poLines.length === 0">
                <span
                  *ngIf="userService.user.value  && poService.purchaseOrder.value.total > userService.user.value.poLimit"><i
                    class="fad fa-check"></i>&nbsp;&nbsp;Submit For Approval</span>
                <span
                  *ngIf="userService.user.value && poService.purchaseOrder.value.total <= userService.user.value.poLimit"><i
                    class="fad fa-check"></i>&nbsp;&nbsp;Issue</span>
              </button>
              <button type="button"
                class="btn btn-primary btn-block"
                *ngIf="userService.user.value  && poService.purchaseOrder.value.total > userService.user.value.poLimit && poService.purchaseOrder.value && poService.purchaseOrder.value.derivedStatusId === PurchaseOrderStatuses.PendingApproval"
                disabled>
                <i class="fas fa-pause-circle"></i>&nbsp;&nbsp;Pending Approval
              </button>
              <button type="button"
                class="btn btn-primary btn-block"
                *ngIf="userService.user.value && poService.purchaseOrder.value.total <= userService.user.value.poLimit &&poService.purchaseOrder.value && poService.purchaseOrder.value.derivedStatusId === PurchaseOrderStatuses.PendingApproval"
                (click)="batchStatusChange(PurchaseOrderStatuses.Issued)">
                <i class="fad fa-check"></i>&nbsp;&nbsp;Issue
              </button>
              <button type="button"
                class="btn btn-danger btn-block"
                *ngIf="userService.user.value && poService.purchaseOrder.value.total <= userService.user.value.poLimit &&poService.purchaseOrder.value && poService.purchaseOrder.value.derivedStatusId === PurchaseOrderStatuses.PendingApproval"
                (click)="batchStatusChange(PurchaseOrderStatuses.Draft)">
                <i class="fas fa-times-circle"></i>&nbsp;&nbsp;Reject
              </button>
              <button type="button"
                class="btn btn-primary btn-block"
                *ngIf="poService.purchaseOrder.value && (poService.purchaseOrder.value.derivedStatusId === PurchaseOrderStatuses.Issued || poService.purchaseOrder.value.derivedStatusId === PurchaseOrderStatuses.Receiving)"
                (click)="batchStatusChange(PurchaseOrderStatuses.Received)">
                <i class="fad fa-check"></i>&nbsp;&nbsp;Received
              </button>
              <button type="button"
                class="btn btn-primary btn-block"
                *ngIf="poService.purchaseOrder.value && poService.purchaseOrder.value.derivedStatusId === PurchaseOrderStatuses.Received"
                disabled>
                <i class="fad fa-check"></i>&nbsp;&nbsp;Received
              </button>
            </div>
          </div>
        </div>
        <div [ngClass]="{'col-md-9': showDetails, 'col-md-12': !showDetails}">
          <div class="card border-top-primary border-top-2 rounded-top-0"
            style="border-top-color: #2196F3 !important;">
            <div class="card-header bg-light header-elements-sm-inline">
              <h6 class="card-title">Purchase Orders Lines</h6>
              <div class="header-elements">
                <div class="btn-group"
                  dropdown
                  *ngIf="model.isActive && (model.derivedStatusName === 'Draft' || (managePo && model.derivedStatusName !== 'Received' && model.derivedStatusName !== 'Pending Approval'))">
                  <button class="btn btn-light btn-sm dropdown-toggle"
                    dropdownToggle
                    id="button-basic"
                    aria-controls="dropdown-basic">
                    <i class="fa fa-plus"></i> Add Line
                    <span class="caret"></span>
                  </button>
                  <ul id="dropdown-basic"
                    *dropdownMenu
                    class="dropdown-menu dropdown-menu-right"
                    role="menu"
                    aria-labelledby="button-basic">
                    <li role="menuitem"><span class="dropdown-item"
                        (click)="openPoLineModal(0, true)"><i class="fal fa-inventory"></i> Inventory</span></li>
                    <li role="menuitem"><span class="dropdown-item"
                        (click)="openPoLineModal(0, false)"><i class="fal fa-box"></i> Miscellaneous</span></li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="table-responsive">
              <kendo-grid [kendoGridBinding]="model.poLines"
                filterable="false"
                [sortable]="true"
                [resizable]="true"
                style="min-width: 1200px;">
                <kendo-grid-column field="one"
                  title=""
                  [width]="60"
                  filterable="false">
                  <ng-template kendoGridCellTemplate
                    let-dataItem
                    let-rowIndex="rowIndex">
                    <button class="btn btn-xs btn-light"
                      (click)="openPoLineModal(dataItem.polineId)"><i class="fa fa-pencil"></i></button>
                  </ng-template>
                </kendo-grid-column>
                <kendo-grid-column field="description"
                  title="Description">
                  <ng-template kendoGridCellTemplate
                    let-dataItem
                    let-rowIndex="rowIndex">
                    <ng-template #popoverTemplateMaintenance>
                      <p class="mb-1 link"> <a
                          [routerLink]="['/item-instances/details', dataItem.maintenanceEventItemInstanceId]"
                          target="_blank"
                          class="link">{{dataItem.maintenanceEventDisplay}}</a></p>
                    </ng-template>
                    {{dataItem.description}}<span *ngIf="dataItem.maintenanceEventItemInstanceId"
                      class="float-right"><i class="fas fa-hammer text-primary"
                        type="button"
                        [popover]="popoverTemplateMaintenance"
                        popoverTitle="Maintenance Log"
                        placement="auto"
                        [outsideClick]="true"
                        container="body"></i></span>
                  </ng-template>
                </kendo-grid-column>
                <kendo-grid-column field="partFamilyName"
                  title="Part Family"
                  [width]="105">
                </kendo-grid-column>
                <kendo-grid-column field="partGroupName"
                  title="Part Group"
                  [width]="100">
                </kendo-grid-column>
                <kendo-grid-column field="quantity"
                  title="Quantity"
                  [width]="70"
                  filterable="false">
                </kendo-grid-column>
                <kendo-grid-column field="price"
                  title="Unit Price"
                  [width]="110">
                  <ng-template kendoGridCellTemplate
                    let-dataItem
                    let-rowIndex="rowIndex">
                    {{dataItem.price | currency}}
                  </ng-template>
                </kendo-grid-column>
                <kendo-grid-column field="lineTotal"
                  title="Line Total"
                  [width]="110">
                  <ng-template kendoGridCellTemplate
                    let-dataItem
                    let-rowIndex="rowIndex">
                    {{dataItem.lineTotal | currency}}
                  </ng-template>
                </kendo-grid-column>
                <kendo-grid-column field="newOrUsedStatusName"
                  title="New/Used"
                  [width]="90">
                  <ng-template kendoGridCellTemplate
                    let-dataItem
                    let-rowIndex="rowIndex">
                    <span *ngIf="dataItem.newOrUsedStatusName">{{dataItem.newOrUsedStatusName}}</span>
                    <span *ngIf="!dataItem.newOrUsedStatusName"
                      class="text-muted">n/a</span>
                  </ng-template>
                </kendo-grid-column>
                <kendo-grid-column field="potypeName"
                  title="Segment"
                  [width]="95">
                </kendo-grid-column>
                <kendo-grid-column field="postatusName"
                  title="PO Status"
                  [width]="130">
                </kendo-grid-column>
                <kendo-grid-column field="one"
                  title=""
                  [width]="60"
                  filterable="false">
                  <ng-template kendoGridCellTemplate
                    let-dataItem
                    let-rowIndex="rowIndex">
                    <button class="btn btn-xs btn-light"
                      [disabled]="(!managePo && dataItem.postatusId !== PurchaseOrderStatuses.Draft) || (managePo && (dataItem.postatusId === PurchaseOrderStatuses.PendingApproval || dataItem.postatusId === PurchaseOrderStatuses.Received))"
                      [swal]="{ title: 'Delete PO Line?', text: dataItem.description + ': $' + dataItem.lineTotal, showCancelButton: true, confirmButtonText: 'Delete', confirmButtonColor: '#DC3545' }"
                      (confirm)="deletePoLine(dataItem.polineId)"><i class="fa fa-trash"></i></button>
                  </ng-template>
                </kendo-grid-column>
              </kendo-grid>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div *ngIf="!poService.purchaseOrder.value"
  class="page-content pt-0">
  <div class="content-wrapper">
    <div class="content">
      <div class="row">
        <div class="col-lg-3">
          <div class="card">
            <div class="card-header bg-light header-elements-inline">
              <h6 class="card-title">PO#</h6>
              <div class="header-elements">
                <button class="btn btn-light btn-sm"
                  [disabled]="true"><i class="fa fa-edit"></i> Edit</button>
              </div>
            </div>
            <div class="card-body">
              <app-list-skeleton [numberToIterate]="9"></app-list-skeleton>
            </div>
          </div>
        </div>
        <div class="col-lg-9">
          <div class="card border-top-primary border-top-2 rounded-top-0"
            style="border-top-color: #2196F3 !important;">
            <div class="card-header bg-light header-elements-inline"
              style="height: 70px;">
              <h6 class="card-title">Purchase Orders Lines</h6>
            </div>
            <div class="card-body">
              <app-table-skeleton [numberToIterate]="3"></app-table-skeleton>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
