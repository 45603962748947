<div class="modal-header"
  *ngIf="model"
  ngxModalDraggable>
  <h4 class="modal-title pull-left">{{isNew ? 'Add' : 'Edit'}}&nbsp;Part Group</h4>
  <button type="button"
    class="close pull-right"
    aria-label="Close"
    (click)="bsModalRef.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body"
  *ngIf="model">
  <div class="form-group row">
    <label for="title"
      class="col-sm-2 text-right control-label col-form-label">Name</label>
    <div class="col-sm-10 form-group mb-0">
      <input type="text"
        class="form-control form-control-sm"
        id="name"
        [(ngModel)]="model.name"
        name="name"
        required>
    </div>
  </div>
  <div class="form-group row">
    <label for="title"
      class="col-sm-2 text-right control-label col-form-label">Part Family</label>
    <div class="col-sm-10 form-group mb-0">
      <ng-select [items]="partFamilies"
        bindLabel="name"
        bindValue="partFamilyId"
        [(ngModel)]="model.partFamilyId"
        name="partFamily"
        appendTo='body'
        required>
      </ng-select>
    </div>
  </div>
  <div class="form-group row"
    *ngIf="fishingTools">
    <label for="title"
      class="col-sm-2 text-right control-label col-form-label">GT Prefix</label>
    <div class="col-sm-10 form-group mb-0">
      <input type="text"
        class="form-control form-control-sm border-danger"
        [ngClass]="{'border-danger' : gtIsInUse, 'border-success': !gtIsInUse}"
        id="gtPrefix"
        [(ngModel)]="model.gtPrefix"
        name="gtPrefix"
        (blur)="checkIfGtIsInUse($event)"
        required>
      <p class="text-danger mb-0"
        *ngIf="gtIsInUse"><i class="fas fa-exclamation-triangle"></i>&nbsp;<strong>Fishing Tools must have a GT Number
          that is not already in use!</strong></p>
    </div>
  </div>
</div>
<div class="modal-footer"
  *ngIf="model">
  <button type="button"
    class="btn btn-primary"
    (click)="onSave()"
    [disabled]="disableSave || !((model.name&&model.partFamilyId!==-1)&&(!fishingTools||(fishingTools&&!gtIsInUse)))">Save</button>
  <button type="button"
    class="btn btn-default"
    (click)="bsModalRef.hide()">Cancel</button>
</div>
