<div class="modal-header"
  ngxModalDraggable>
  <h4 class="modal-title pull-left"
    *ngIf="model">{{isNew ? 'Add' : 'Edit'}}&nbsp;Invoice Item</h4>
  <button type="button"
    class="close pull-right"
    aria-label="Close"
    (click)="bsModalRef.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body"
  *ngIf="model">
  <div class="form-group row"
    *ngIf="isInventoryItem">
    <label for="title"
      class="col-sm-2 text-right control-label col-form-label">Item</label>
    <div class="col-sm-10 form-group mb-0"
      *ngIf="itemService.itemDropdownList | async; let item">
      <div class="input-group">
        <ng-select [items]="item"
          bindLabel="dropdownDisplay"
          bindValue="itemId"
          [(ngModel)]="model.itemId"
          class="col-md-9"
          style="padding:0px;"
          (change)="onChangeOfItem($event)"
          [disabled]="isRebuildItem  || model.isPackerWorkLog"
          name="items"
          required>
        </ng-select>
        <div class="input-group-append col-md-3"
          style="padding: 0;width:100%">
          <span class="input-group-text"
            style="padding: 0px;"><button (click)="openItemModal(0)"
              style="display: inline-flex;padding: 4px 10px;width:100%"
              class="btn btn-sm btn-default"
              [disabled]="isRebuildItem  || model.isPackerWorkLog"><i class="fa fa-plus"
                style="position: relative; top: 3px; margin-right: 5px;"></i>&nbsp;New</button></span>
        </div>
      </div>
    </div>
  </div>
  <div class="form-group row">
    <label for="title"
      class="col-sm-2 text-right control-label col-form-label">Billing Code</label>
    <div class="col-sm-10 form-group mb-0">
      <div class="input-group">
        <ng-select [items]="billingCodes"
          bindLabel="dropDownDisplay"
          bindValue="billingCodeId"
          [ngModel]="model.billingCodeId"
          name="billingCodes"
          class="col-md-9"
          style="padding:0px;"
          (change)="onChangeOfBillingCode($event)"
          [disabled]="billingCodes.length === 0 || isRebuildItem || model.isPackerWorkLog"
          required>
        </ng-select>
        <div class="input-group-append col-md-3"
          style="padding: 0;width:100%">
          <span class="input-group-text"
            style="padding: 0px;"><button (click)="openBillingCodeModal(0)"
              style="display: inline-flex;padding: 4px 10px;width:100%"
              class="btn btn-sm btn-default"
              [disabled]="isRebuildItem || model.isPackerWorkLog ||(isInventoryItem&&!this.model.itemId)"><i
                class="fa fa-plus"
                style="position: relative; top: 3px; margin-right: 5px;"></i>&nbsp;New</button></span>
        </div>
      </div>
    </div>
  </div>
  <div class="form-group row">
    <label for="title"
      class="col-sm-2 text-right control-label col-form-label">Description</label>
    <div class="col-sm-10 form-group mb-0">
      <input type="text"
        class="form-control form-control-sm"
        id="description"
        [(ngModel)]="model.description"
        name="description"
        required
        [readonly]="isRebuildItem || model.isPackerWorkLog">
    </div>
  </div>
  <div class="form-group row">
    <label for="title"
      class="col-sm-2 text-right control-label col-form-label">Quantity</label>
    <div class="col-sm-4 form-group mb-0"
      *ngIf="!isEditable">
      <input type="number"
        step="1"
        class="form-control form-control-sm"
        id="quantity"
        [(ngModel)]="model.quantity"
        name="quantity"
        (input)="calculateTotal()"
        required
        [readonly]="isRebuildItem || model.isPackerWorkLog">
    </div>
    <div class="col-sm-4 form-group mb-0"
      *ngIf="isEditable">
      <div class="input-group">
        <input type="number"
          class="form-control form-control-sm"
          id="quantity"
          [(ngModel)]="model.quantity"
          name="quantity"
          required
          [readonly]="!editItem&&!inEditMode"
          (input)="calculateTotal()">
        <div class="input-group-append"
          style="height: 32px;">
          <span class="input-group-text">
            <input type="checkbox"
              (change)="enableQtyEdit()"
              [checked]="editItem"
              [disabled]="editItem||inEditMode||!isFishingRental&&!model.billingCodeId">&nbsp;&nbsp;<span
              [ngClass]="{'text-muted': !editItem}">Edit</span>
          </span>
        </div>
      </div>
      <div class="alert alert-warning"
        role="alert"
        *ngIf="showWarning"
        style="padding: 3px;margin-top: 5px;margin-bottom: 0px;">
        <i class="fas fa-exclamation-triangle"></i>&nbsp;&nbsp;Editing the <strong>Quantity</strong> will prevent
        changes on the Operations Breakdown from reflecting on the invoice.
      </div>
      <div class="alert alert-warning"
        role="alert"
        *ngIf="editItem && !showWarning"
        style="padding: 3px;margin-top: 5px;margin-bottom: 0px;">
        <i class="fas fa-exclamation-triangle"></i>&nbsp;&nbsp;Qty has been edited.
      </div>
    </div>
    <ng-container *ngIf="userCanSeePrices">
      <label for="title"
        class="col-sm-2 text-right control-label col-form-label">Price</label>
      <div class="col-sm-4 form-group mb-0">
        <input type="number"
          step=".01"
          class="form-control form-control-sm"
          id="price"
          [(ngModel)]="model.price"
          name="price"
          (input)="calculateTotal()"
          required>
      </div>
    </ng-container>
  </div>
  <div class="form-group row">
    <ng-container *ngIf="userCanSeePrices">
      <label for="title"
        class="col-sm-2 text-right control-label col-form-label">Total</label>
      <div class="col-sm-4 form-group mb-0">
        <div class="input-group input-group-sm">
          <div class="input-group-prepend">
            <span class="input-group-text">$</span>
          </div>
          <input type="text"
            class="form-control form-control-sm"
            id="total"
            [ngModel]="total"
            name="total"
            readonly>
        </div>
      </div>
    </ng-container>
    <label for="title"
      class="col-sm-2 text-right control-label col-form-label">For Resale</label>
    <div class="col-sm-4 form-group mb-0">
      <ui-switch [(ngModel)]="model.isForResale"
        name="isForResale"></ui-switch>
    </div>
  </div>
  <div class="form-group row"
    *ngIf="itemIsLoaded && isInventoryItem">
    <label for="title"
      class="col-sm-2 text-right control-label col-form-label"></label>
    <div class="col-sm-10 form-group mb-0">
      <div class="row pl-2">
        <div class="alert alert-danger p-1 mb-1"
          role="alert"
          *ngIf="itemIsLoaded && isInventoryItem && (currentInventory === null || currentInventory.length === 0)">
          <i class="fas fa-check-circle"></i>&nbsp;&nbsp;Current Inventory:
          <strong>{{currentInventory!==null ? currentInventory : 0}}</strong>
        </div>
        <ng-container
          *ngIf="itemIsLoaded && isInventoryItem && currentInventory !== null && currentInventory.length > 0">
          <div class="alert p-1 mb-1 mr-2"
            [ngClass]="{'alert-success':ci.inventoryCount !==null && ci.inventoryCount > 0, 'alert-danger': ci.inventoryCount === null || ci.inventoryCount <= 0 }"
            role="alert"
            *ngFor="let ci of currentInventory">
            <i class="fas fa-check-circle"></i>&nbsp;&nbsp;{{ci.name}} Inventory:
            <strong>{{ci.inventoryCount !==null ? ci.inventoryCount : 0}}</strong>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
  <div class="modal-footer"
    *ngIf="model">
    <button type="button"
      class="btn btn-primary"
      (click)="onSave()"
      [disabled]="disableSave || !(model.quantity&&model.billingCodeId&&(!isInventoryItem || isInventoryItem && model.itemId))">Save</button>
    <button type="button"
      class="btn btn-default"
      (click)="bsModalRef.hide()">Cancel</button>
  </div>
