import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { JobSafetyAnalysisWindSpeedModel } from 'src/models/job-safety-analysis-wind-speed.model';

@Injectable()
export class JobSafetyAnalysisWindSpeedService {
  constructor(
    private http: HttpClient,
    @Inject('BASE_URL') private baseUrl: string
  ) { }

  private apiUrl = 'api/JobSafetyAnalysisWindSpeed';  // URL to web api

  private windSpeedListSubject = new BehaviorSubject<JobSafetyAnalysisWindSpeedModel[]>(null);
  public _windSpeedList$ = this.windSpeedListSubject.asObservable();
  private isWindSpeedCallInProgress = false;

  public get windSpeedList$(): Observable<JobSafetyAnalysisWindSpeedModel[]> {
    if (!this.windSpeedListSubject.getValue() && !this.isWindSpeedCallInProgress) {
      this.isWindSpeedCallInProgress = true;
      this.getWindSpeeds$(false).subscribe(() => this.isWindSpeedCallInProgress = false);
    }

    return this._windSpeedList$;
  }

  private getWindSpeeds$(showInactive: boolean): Observable<JobSafetyAnalysisWindSpeedModel[]> {
    return this.http.get<Array<JobSafetyAnalysisWindSpeedModel>>(this.baseUrl + this.apiUrl + '/GetWindSpeeds/' + showInactive)
      .pipe(
        tap((speeds: JobSafetyAnalysisWindSpeedModel[]) => {
          this.windSpeedListSubject.next(speeds);
        })
      );
  }
}
