import { BehaviorSubject, Observable, Observer } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ItemInstanceLocationHistoryService } from './item-instance-location-history.service';
import { ItemInstanceLocations } from '../enums/item-instance-locations';
import { ItemInstanceModel } from 'src/models/item-instance.model';
import { JobService } from './job.service';
import { SerializedLocationFilterParamsModel } from 'src/models/RequestParams/serialized-location-filter-params.model';
import { SerializedLocationModel } from 'src/models/serialized-location.model';
import { tap } from 'rxjs/operators';
import { Shops } from 'src/enums/shops';
import { UserService } from './user.service';

@Injectable()
export class ItemInstanceService {
  constructor(
    private http: HttpClient,
    @Inject('BASE_URL') private baseUrl: string,
    public iilhService: ItemInstanceLocationHistoryService,
    private userService: UserService,
    public jobService: JobService) { }

  public itemInstanceList: BehaviorSubject<Array<ItemInstanceModel>> = new BehaviorSubject(null);
  public itemInstanceByIdList: BehaviorSubject<Array<ItemInstanceModel>> = new BehaviorSubject(null);
  public itemInstance: BehaviorSubject<ItemInstanceModel> = new BehaviorSubject(null);
  public ItemInstanceLocations = ItemInstanceLocations;
  public serializedLocationFilterParams: SerializedLocationFilterParamsModel = new SerializedLocationFilterParamsModel();
  public serializedLocationList: BehaviorSubject<Array<SerializedLocationModel>> = new BehaviorSubject(null);
  public locationsAreLoading: boolean = false;
  public Shops = Shops;
  public itemInstanceRepairDropdownList: BehaviorSubject<ItemInstanceModel[]> = new BehaviorSubject<ItemInstanceModel[]>(null);

  private apiUrl = 'api/ItemInstance';  // URL to web api

  public getItemInstances(
    showScrap: boolean = false,
    showInactive: boolean = false,
    showInactiveItems: boolean = false): void {
    this.http.get<ItemInstanceModel[]>(this.baseUrl + this.apiUrl + '/GetItemInstances/' + showScrap + '/' + showInactive + '/' + showInactiveItems)
      .pipe(tap((x: ItemInstanceModel[]) => {
        x.forEach((y: ItemInstanceModel) => {
          y.disabled = y.itemInstanceLocationId === this.ItemInstanceLocations.Scrap || y.itemInstanceLocationId === this.ItemInstanceLocations.OnJob;
          y.dropdownDisplay = ` ${y.fullGtNumber !== undefined && y.fullGtNumber !== null ? y.fullGtNumber : 'n/a'}  - ${y.description}`;
          y.dropdownDisplay += y.itemInstanceLocationId === this.ItemInstanceLocations.OnJob ? ` (On Job #${y.jobNumber})` : '';
        });
      }))
      .subscribe((x: ItemInstanceModel[]) => {
        this.itemInstanceList.next(x);
      });
  }

  public getItemInstancesRepairDropdown(itemId: number = null): void {
    let query = '';

    if (itemId !== null) {
      query = `?itemId=${itemId}`;
    }

    this.http.get<ItemInstanceModel[]>(
      this.baseUrl + this.apiUrl + '/GetItemInstancesRepairDropdown/' + false + '/' + false + '/' + false + query)
      .pipe(tap((x: ItemInstanceModel[]) => {
        x.forEach((y: ItemInstanceModel) => {
          y.dropdownDisplay = `${y.fullGtNumber !== undefined && y.fullGtNumber !== null ? y.fullGtNumber : 'n/a'} - ${y.description}`;
        });
      }))
      .subscribe((x: ItemInstanceModel[]) => {
        this.itemInstanceRepairDropdownList.next(x);
      });
  }

  public getItemInstancesByItemId(
    itemId: number,
    showScrap: boolean = false,
    showInactive: boolean = false): void {
    this.http.get<ItemInstanceModel[]>(this.baseUrl + this.apiUrl + '/GetItemInstancesByItemId/' + itemId + '/' + showScrap + '/' + showInactive)
      .subscribe((x: ItemInstanceModel[]) => {
        this.itemInstanceByIdList.next(x);
      });
  }

  public getItemInstanceById(itemInstanceId: number): Observable<ItemInstanceModel> {
    return this.http.get<ItemInstanceModel>(this.baseUrl + this.apiUrl + '/GetItemInstanceById/' + itemInstanceId)
      .pipe(tap((y: ItemInstanceModel) => {
        y.dropdownDisplay = ` ${y.fullGtNumber !== undefined && y.fullGtNumber !== null ? y.fullGtNumber : 'n/a'}  - ${y.description}`;
        y.dropdownDisplay += y.itemInstanceLocationId === this.ItemInstanceLocations.OnJob ? ` (On Job #${y.jobNumber})` : '';
      }));
  }

  public refreshItemInstance(itemInstanceId: number): void {
    this.getItemInstanceById(itemInstanceId)
      .subscribe((x: ItemInstanceModel) => {
        this.itemInstance.next(x);
      });
  }

  public addEditItemInstance(
    dto: ItemInstanceModel,
    showInactive: boolean,
    refreshItemInstances: boolean): Observable<boolean> {
    return new Observable((observer: Observer<boolean>) => {
      this.http.post<boolean>(this.baseUrl + this.apiUrl + '/AddEditItemInstance', this.cleanModel(dto))
        .subscribe((x: boolean) => {
          if (x === true) {
            if (refreshItemInstances === true) {
              this.getItemInstancesByItemId(dto.itemId, showInactive);
            } else {
              this.itemInstance.next(dto);
              this.iilhService.getLocationHistoryByItemInstanceId(dto.itemInstanceId);
            }
          }
          observer.next(x);
        });
    });
  }

  public deleteItemInstance(
    id: number,
    itemId: number,
    showScrap: boolean = false,
    showInactive: boolean = false): Observable<boolean> {
    return new Observable((observer: Observer<boolean>) => {
      this.http.get<boolean>(this.baseUrl + this.apiUrl + '/DeleteItemInstance/' + id)
        .subscribe((x: boolean) => {
          if (x) {
            this.getItemInstancesByItemId(itemId, showScrap, showInactive);
          }
          observer.next(x);
        });
    });
  }

  public closeOutItemInstances(jobId: number): Observable<boolean> {
    return new Observable((observer: Observer<boolean>) => {
      this.http.get<boolean>(this.baseUrl + this.apiUrl + '/CloseOutItemInstances/' + jobId)
        .subscribe((x: boolean) => {
          if (x === true) {
            this.jobService.refreshJob(jobId);
          }
          observer.next(x);
        });
    });
  }

  public getSerializedLocationList(
    onJobOnly: boolean,
    clearParams: boolean = false,
    showSkeleton: boolean = false): void {
    this.cleanSerializedLocationFilterModel();
    if (showSkeleton) {
      this.locationsAreLoading = true;
    }

    this.http.post<Array<SerializedLocationModel>>(this.baseUrl + this.apiUrl + '/GetSerializedLocations/' + onJobOnly,
      clearParams === true ? new SerializedLocationFilterParamsModel() : this.serializedLocationFilterParams)
      .subscribe((x: SerializedLocationModel[]) => {
        this.serializedLocationList.next(x);
        this.locationsAreLoading = false;
      });
  }

  private cleanSerializedLocationFilterModel(): void {
    this.cleanModel(this.serializedLocationFilterParams);
  }

  public clearFilterParams(onJobOnly: boolean): void {
    Object.keys(this.serializedLocationFilterParams).forEach((index: string) => {
      if (index === 'itemIds') {
        this.serializedLocationFilterParams.itemIds = [];
      } else {
        this.serializedLocationFilterParams[index] = null;
      }
    });


    this.getSerializedLocationList(onJobOnly);
  }

  public generateNewItemInstanceModel(
    itemId: number,
    poid: number = null,
    partGroupId: number = null,
    serialNumber: string = null,
    purchasePoLineId: number = null,
    cost: number = null): ItemInstanceModel {
    const user = this.userService.user.value;

    let model: ItemInstanceModel = new ItemInstanceModel();
    model.itemInstanceId = 0;
    model.itemId = itemId;
    model.poid = poid;
    model.itemInstanceLocationId = this.ItemInstanceLocations.Warehouse;
    model.itemInstanceLocation = null;
    model.jobId = null;
    model.jobNumber = null;
    model.isActive = true;
    model.partGroupId = partGroupId;
    model.shopId = user != null ? user.defaultShopId : this.Shops.Williston;
    model.serialNumber = serialNumber;
    model.purchasePoLineId = purchasePoLineId;
    model.originalCost = cost;

    return model;
  }

  public getSingleNewSerialNumber(partGroupId: number, itemId: number): Observable<string> {
    return this.http.get<string>(this.baseUrl + this.apiUrl + '/GetSingleNewSerialNumber/' + partGroupId + '/' + itemId);
  }

  public getMultipleNewSerialNumbers(partGroupId: number, itemId: number, count: number): Observable<string[]> {
    return this.http.get<string[]>(this.baseUrl + this.apiUrl + '/GetMultipleNewSerialNumbers/' + partGroupId + '/' + itemId + '/' + count);
  }

  public getNumberOfActiveFilterFields(): number {
    let count: number = 0;

    Object.values(this.serializedLocationFilterParams).forEach((value: any) => {
      count = value !== null && (!Array.isArray(value) || (Array.isArray(value) && value.length > 0)) ? count += 1 : count;
    });

    return count;
  }

  private cleanModel(model: any): any {
    Object.keys(model).forEach((index: string) => {
      if (typeof model[index] === 'string') {
        model[index] = model[index]
          && model[index].trim() !== '' ?
          model[index].trim()
          : null;
      }
    });

    return model;
  }

}
