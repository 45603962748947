import { BehaviorSubject, Observable, Observer, tap } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ItemService } from './item.service';
import { UserService } from './user.service';
import { InventoryCountModel } from 'src/models/inventory-count.model';
import { InventoryCountStatuses } from 'src/enums/inventory-count-statuses';
import { Router } from '@angular/router';
import { TimezoneService } from './timezone.service';

@Injectable()
export class InventoryCountService {
  constructor(private http: HttpClient,
    @Inject('BASE_URL') private baseUrl: string,
    public itemService: ItemService,
    public userService: UserService,
    public timezoneService: TimezoneService,
    private router: Router
  ) { }

  public inventoryCountList: BehaviorSubject<Array<InventoryCountModel>> = new BehaviorSubject(null);
  public inventoryCount: BehaviorSubject<InventoryCountModel> = new BehaviorSubject(null);
  public countListIsLoading: boolean = false;
  public countIsLoading: boolean = false;

  private apiUrl = 'api/InventoryCount';  // URL to web api

  public createInventoryCount(
    dto: InventoryCountModel): Observable<boolean> {
    return new Observable((observer: Observer<boolean>) => {
      this.http.post<number>(this.baseUrl + this.apiUrl + '/CreateInventoryCount', this.cleanInventoryCountModel(dto))
        .subscribe((x: number) => {
          if (x !== -1) {
            this.router.navigateByUrl(`/inventory-counts/details/${x}`);
          }
          observer.next(x !== -1);
        });
    });
  }

  public deleteInventoryCount(
    id: number,
    showInactive: boolean): Observable<boolean> {
    return new Observable((observer: Observer<boolean>) => {
      this.http.get<boolean>(this.baseUrl + this.apiUrl + '/DeleteInventoryCount/' + id)
        .subscribe((x: boolean) => {
          if (x === true) {
            this.getInventoryCounts(showInactive, true);
          }
          observer.next(x);
        });
    });
  }

  public editInventoryCount(
    dto: InventoryCountModel): Observable<boolean> {
    dto.date = this.timezoneService.correctOffset(new Date(dto.date));

    return new Observable((observer: Observer<boolean>) => {
      this.http.post<number>(this.baseUrl + this.apiUrl + '/EditInventoryCount', this.cleanInventoryCountModel(dto))
        .subscribe((x: number) => {
          if (x !== -1) {
            this.refreshCount(dto.inventoryCountId, false);
          }
          observer.next(x !== -1);
        });
    });
  }

  public finishInventoryCount(id: number): Observable<boolean> {
    return new Observable((observer: Observer<boolean>) => {
      this.http.get<boolean>(this.baseUrl + this.apiUrl + '/FinishInventoryCount/' + id)
        .subscribe((x: boolean) => {
          if (x === true) {
            this.refreshCount(id, false);
          }
          observer.next(x);
        });
    });
  }

  public getCountById(id: number): Observable<InventoryCountModel> {
    return this.http.get<InventoryCountModel>(this.baseUrl + this.apiUrl + '/GetInventoryCountById/' + id)
      .pipe(tap((x: InventoryCountModel) => {
        x.date = x.date !== undefined && x.date !== null ? new Date(x.date) : null;
      }));
  }

  public refreshCount(
    id: number,
    clear: boolean = false) {
    if (clear) {
      this.inventoryCount.next(null);
    }

    this.countIsLoading = true;
    this.getCountById(id)
      .subscribe((x: InventoryCountModel) => {
        this.inventoryCount.next(x);
        this.countIsLoading = false;
      });
  }

  public getInventoryCounts(
    showInactive: boolean,
    showSkeleton: boolean = false): void {

    if (showSkeleton) {
      this.countIsLoading = true;
    }

    this.http.get<Array<InventoryCountModel>>(this.baseUrl + this.apiUrl + '/GetInventoryCounts/' + showInactive)
      .subscribe((x: InventoryCountModel[]) => {
        this.inventoryCountList.next(x);
        this.countIsLoading = false;
      });
  }

  public generateNewInventoryCount(): InventoryCountModel {
    let model = new InventoryCountModel();

    model.date = new Date();
    model.inventoryCountStatusId = InventoryCountStatuses.InProgress;

    return model;
  }

  private cleanInventoryCountModel(model: InventoryCountModel): InventoryCountModel {
    Object.keys(model).forEach((index: string) => {
      if (typeof model[index] === 'string') {
        model[index] = model[index]
          && model[index].trim() !== '' ?
          model[index].trim()
          : null;
      }
    });

    return model;
  }

}
