<button type="button"
  class="btn btn-primary btn-block mt-1 mb-1"
  *ngIf="jobService.job.value !== null && jobService.job.value.jobStatusId === closeId"
  disabled>
  <i class="fad fa-check"></i>&nbsp;&nbsp;Invoice Finalized
</button>
<button type="button"
  class="btn btn-danger btn-block mt-1 mb-1"
  *ngIf="jobService.job.value !== null && jobService.job.value.jobStatusId === cancellationId"
  disabled>
  <i class="fad fa-times-circle"></i>&nbsp;&nbsp;Invoice Canceled
</button>
<div class="btn-group btn-block mt-1 mb-1"
  dropdown
  placement="bottom right"
  *ngIf="jobService.job.value !== null && (jobService.job.value.jobStatusId !== cancellationId && jobService.job.value.jobStatusId !== closeId)">
  <button type="button"
    class="btn btn-primary btn-block"
    *ngIf="jobService.job.value && jobService.job.value.jobStatusId === JobStatuses.Quote_Sales"
    [swal]="{ title: 'Convert Quote to Sales Invoice?', text: 'Job#:' + job.jobNumber + ' - ' + job.customerName, showCancelButton: true, confirmButtonText: 'Convert', confirmButtonColor: '#1B6EC2' }"
    (confirm)="changeJobStatus(openId);"
    [disabled]="enableReorder">
    <i class="fad fa-check"></i>&nbsp;&nbsp;Open Invoice
  </button>
  <button type="button"
    class="btn btn-primary btn-block"
    *ngIf="jobService.job.value && jobService.job.value.jobStatusId === openId"
    [swal]="{ title: 'Finalize Job?', text: 'Job#:' + job.jobNumber + ' - ' + job.customerName, showCancelButton: true, confirmButtonText: 'Finalize', confirmButtonColor: '#1B6EC2' }"
    (confirm)="changeJobStatus(closeId);"
    [disabled]="enableReorder || !canApproveInvoices">
    <i class="fad fa-check"></i>&nbsp;&nbsp;Finalize Invoice
  </button>
  <button *ngIf="canApproveInvoices"
    id="button-split"
    type="button"
    dropdownToggle
    class="btn btn-xs dropdown-toggle dropdown-toggle-split"
    [class.btn-light]="false"
    [class.btn-primary]="true"
    aria-controls="dropdown-split">
    <span class="caret"></span>
    <span class="sr-only">Other Options</span>
  </button>
  <ul *dropdownMenu
    class="dropdown-menu btn-block"
    role="menu"
    aria-labelledby="button-split">
    <li role="menuitem">
      <a class="dropdown-item"
        *ngIf="!isFishing
          && jobService.job.value 
          && jobService.job.value.jobSubStatusId === JobSubStatuses.Sent_for_Signature"
        [swal]="{ title: 'BACK TO Ready for Signature?', text: 'Job#:' + job.jobNumber + ' - ' + job.customerName, showCancelButton: true, confirmButtonText: 'Back', confirmButtonColor: '#1B6EC2' }"
        (confirm)="changeJobSubStatus(JobSubStatuses.Ready_for_Signature);"
        [disabled]="enableReorder">
        <i class="fas fa-undo"></i> BACK (Ready for Signature)
      </a>
    </li>
    <li role="menuitem">
      <a class="dropdown-item"
        *ngIf="jobService.job.value && jobService.job.value.jobStatusId !== JobStatuses.Quote_Sales"
        [swal]="{ title: 'Convert Sales Invoice to Quote?', text: 'Job#:' + job.jobNumber + ' - ' + job.customerName, showCancelButton: true, confirmButtonText: 'Convert', confirmButtonColor: '#1B6EC2' }"
        (confirm)="changeJobStatus(JobStatuses.Quote_Sales);"
        [disabled]="enableReorder">
        <i class="fal fa-quote-right"></i> Quote Invoice
      </a>
    </li>
    <li role="menuitem"><a class="dropdown-item"
        (click)="changeJobStatus(openId)"
        *ngIf="jobService.job.value && jobService.job.value.jobStatusId !== openId && jobService.job.value.jobStatusId !== JobStatuses.Quote_Sales"><i
          class="fas fa-tasks"></i> Open Invoice</a></li>
    <li role="menuitem"><a class="dropdown-item"
        (click)="changeJobStatus(closeId)"
        *ngIf="jobService.job.value && jobService.job.value.jobStatusId !== closeId && jobService.job.value.jobStatusId !== openId && jobService.job.value.jobStatusId !== JobStatuses.Quote_Sales"><i
          class="fad fa-check"></i> Finalize Invoice</a></li>
    <li role="menuitem"><a class="dropdown-item"
        (click)="openCancelJobConfirmModal(job, cancellationId)"
        *ngIf="jobService.job.value && jobService.job.value.jobStatusId !== cancellationId && jobService.job.value.jobStatusId !== closeId"><i
          class="fad fa-times-circle"></i> Cancel Invoice</a></li>
  </ul>
</div>
