<table class="table mb-0 thead-border-top-0 table-nowrap text-center">
  <tbody>
    <tr *ngFor="let i of iteratorArray(numberToIterate).fill(1)">
      <td>
        <ngx-skeleton-loader count="1"
          [animation]="'pulse'"
          [theme]="{ 'width': '50px', 'height': '20px', 'border': 'none'}"></ngx-skeleton-loader>
      </td>
      <td>
        <ngx-skeleton-loader count="1"
          [animation]="'pulse'"
          [theme]="{ 'width': '100px', 'height': '20px', 'border': 'none'}"></ngx-skeleton-loader>
      </td>
      <td>
        <ngx-skeleton-loader count="1"
          [animation]="'pulse'"
          [theme]="{ 'width': '50px', 'height': '20px', 'border': 'none'}"></ngx-skeleton-loader>
      </td>
      <td>
        <ngx-skeleton-loader count="1"
          [animation]="'pulse'"
          [theme]="{ 'width': '100px', 'height': '20px', 'border': 'none'}"></ngx-skeleton-loader>
      </td>
      <td>
        <ngx-skeleton-loader count="1"
          [animation]="'pulse'"
          [theme]="{ 'width': '50px', 'height': '20px', 'border': 'none'}"></ngx-skeleton-loader>
      </td>
    </tr>
  </tbody>
</table>
