import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { JobSubStatuses } from 'src/enums/job-sub-statuses';
import { JobTypes } from 'src/enums/job-types';
import { AppStateService } from 'src/services/app-state.service';
import { JobService } from 'src/services/job.service';
import { TimezoneService } from 'src/services/timezone.service';
import { UIService } from 'src/services/ui.service';
import { UserService } from 'src/services/user.service';
import { JobStatuses } from '../../../../enums/job-statuses';
import { JobModel } from '../../../../models/job.model';

@Component({
  selector: 'app-job-info',
  templateUrl: './job-info.component.html',
  styleUrls: ['./job-info.component.css']
})

export class JobInfoComponent implements OnInit, OnDestroy {
  @Input() job: JobModel;
  public handDisplay: boolean = false;
  public JobStatuses = JobStatuses;
  public JobSubStatuses = JobSubStatuses;
  public JobTypes = JobTypes;
  public invoiceDate: Date = null;

  private isTabletSub: Subscription;
  public isTablet: boolean = false;

  private saveSub: Subscription;

  constructor(
    public uiService: UIService,
    public userService: UserService,
    public jobService: JobService,
    public appStateService: AppStateService,
    public timeZoneService: TimezoneService
  ) { };

  public ngOnDestroy(): void {
    this.isTabletSub?.unsubscribe();

    this.saveSub?.unsubscribe();
  }

  ngOnInit(): void {
    this.invoiceDate = this.job.invoiceDate ? new Date(this.job.invoiceDate) : null;
    this.handDisplay = this.job.jobTypeId === this.JobTypes.Fishing || this.job.jobTypeId === this.JobTypes.Packer;
    this.determineIsTablet();
  }

  public determineIsTablet(): void {
    this.isTabletSub = this.appStateService.isTablet$
      .subscribe((isTablet: boolean) => {
        this.isTablet = isTablet;
      });
  }

  public saveInvoiceDateChange(
    $event: Date,
    job: JobModel): void {
    let cache: Date = this.invoiceDate;

    let needToSave: boolean = false;

    let eventYear: number = null;
    let eventMonth: number = null;
    let eventDay: number = null;

    let invoiceDateYear: number = null;
    let invoiceDateMonth: number = null;
    let invoiceDateDay: number = null;

    if ($event !== undefined) {
      eventYear = $event.getFullYear();
      eventMonth = $event.getMonth();
      eventDay = $event.getDate();

      if (this.invoiceDate !== null) {
        invoiceDateYear = this.invoiceDate.getFullYear();
        invoiceDateMonth = this.invoiceDate.getMonth();
        invoiceDateDay = this.invoiceDate.getDate();

        needToSave = eventYear !== invoiceDateYear || eventMonth !== invoiceDateMonth || eventDay !== invoiceDateDay;

        if (needToSave) {
          this.invoiceDate = $event;
          this.job.invoiceDate = $event;
        }
      }
    }
    if (needToSave) {
      this.saveSub = this.jobService.addEditJob(job, false, false)
        .subscribe((x: boolean) => {
          if (x) {
            this.uiService.showSuccess('Invoice Date Changed', 'Success');
          } else {
            this.invoiceDate = cache;
            this.uiService.showError('Invoice Date Not Saved', 'Error');
          }
        });
    }
  }

}
