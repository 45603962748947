<ng-container *ngIf="worksheetService.worksheetList | async; let model">
  <div class="card border-top-primary border-top-2 rounded-top-0"
    style="border-top-color: #2196F3 !important;"
    *ngIf="model && model.length > 0">
    <div class="card-header bg-light header-elements-sm-inline">
      <h6 class="card-title"><span *ngIf="!isPackerInvoice"> Rebuild</span><span *ngIf="isPackerInvoice"> Packer</span>
        Worksheets</h6>
      <div class="header-elements">
      </div>
    </div>
    <div id="accordion-group"
      class="card-group-control card-group-control-right">
      <div class="card mb-0 rounded-bottom-0"
        *ngFor="let ws of model"
        style="margin:10px">
        <ng-container>
          <div style="cursor:pointer;"
            data-toggle="collapse"
            class="card-header bg-light"
            (click)="ws.isCollapsed = !ws.isCollapsed"
            [attr.aria-expanded]="!ws.isCollapsed"
            aria-controls="collapseBasic">
            <h6 class="card-title">
              <span class="text-default"
                [class.collapsed]="ws.isCollapsed"><strong><span
                    *ngIf="isPackerInvoice || (!isPackerInvoice && !isTablet)">Rebuild
                  </span>#{{ws.worksheetId}}:</strong>
                {{ws.description}}</span>
            </h6>
          </div>
          <div id="accordion-item-group1"
            class="collapse"
            data-parent="#accordion-group"
            [collapse]="ws.isCollapsed">
            <div class="card-body">
              <button class="btn btn-xs float-right btn-danger"
                style="margin-left: 5px;"
                (click)="deleteWorksheet(ws)"
                [disabled]="(ws.dayIds && ws.dayIds.length > 0) || (jobService.job.value !== null && jobService.job.value.jobStatusIsTerminal === true)"
                title="Edit">
                <i class="fa fa-trash"></i><span *ngIf="isPackerInvoice">&nbsp;Delete</span>
              </button>
              <button class="btn btn-xs float-right btn-primary"
                style="margin-left: 5px;"
                (click)="removeWorksheetFromJob(ws)"
                [disabled]="(ws.dayIds && ws.dayIds.length > 0) || (jobService.job.value !== null && jobService.job.value.jobStatusIsTerminal === true)"
                title="Remove From Job">
                <i class="fa fa-redo"></i><span *ngIf="isPackerInvoice">&nbsp;Remove From Job</span>
              </button>
              <button class="btn btn-info btn-xs float-right"
                style="margin-left: 5px;"
                (click)="openSnapshotModal(ws.worksheetId)"
                title="Snapshots">
                <i class="fad fa-camera"></i><span *ngIf="isPackerInvoice">&nbsp;Snapshots</span>
              </button>
              <button class="btn btn-light btn-xs float-right"
                (click)="openWorksheetModal(ws.worksheetId)"
                title="Edit">
                <i class="fa fa-edit"></i><span *ngIf="isPackerInvoice">&nbsp;Edit</span>
              </button>
              <span style="margin-top: 5px; margin-bottom: 20px; display: inline-block;">
                <strong><span
                    *ngIf="isPackerInvoice || (!isPackerInvoice && !isTablet)">Labor&nbsp;</span>Hours:</strong>
                {{ws.laborHours}}
              </span>
              <table width="100%">
                <thead>
                  <tr>
                    <th *ngIf="isPackerInvoice || (!isPackerInvoice && !isTablet)"></th>
                    <th>Item</th>
                    <th>
                      <span *ngIf="isPackerInvoice || (!isPackerInvoice && !isTablet)">Quantity</span>
                      <span *ngIf="!isPackerInvoice && isTablet">Qty</span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <ng-container *ngFor="let row of ws.worksheetRowsCombined">
                    <tr *ngIf="row.quantity > 0">
                      <td *ngIf="isPackerInvoice || (!isPackerInvoice && !isTablet)"><i class="icon-gear"
                          style="color: gray;"></i></td>
                      <td>{{row.selectedItemName}}</td>
                      <td>{{row.quantity}}</td>
                    </tr>
                  </ng-container>
                </tbody>
              </table>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
    <div class="table-responsive">
      <ul *ngFor="let ws of model">
      </ul>
    </div>
  </div>
</ng-container>
