import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Observer } from 'rxjs';
import { ItemInstanceLocationModel } from 'src/models/item-instance-location.model';

@Injectable()
export class ItemInstanceLocationService {
  constructor(
    private http: HttpClient,
    @Inject('BASE_URL') private baseUrl: string
  ) { }

  public iilList: BehaviorSubject<Array<ItemInstanceLocationModel>> = new BehaviorSubject(null);

  private apiUrl = 'api/ItemInstanceLocation';  // URL to web api

  public getItemInstanceLocations(
    showInactive: boolean,
    clear: boolean = false): void {
    if (!this.iilList.value || clear) {
      this.http.get<Array<ItemInstanceLocationModel>>(this.baseUrl + this.apiUrl + '/GetItemInstanceLocations/' + showInactive)
        .subscribe((x: ItemInstanceLocationModel[]) => {
          this.iilList.next(x);
        });
    }
  }

  public getItemInstanceLocationById(id: number): Observable<ItemInstanceLocationModel> {
    return this.http.get<ItemInstanceLocationModel>(this.baseUrl + this.apiUrl + '/GetItemInstanceLocationById/' + id);
  }

  public addEditItemInstanceLocation(
    dto: ItemInstanceLocationModel,
    showInactive: boolean): Observable<boolean> {
    dto = this.cleanItemInstanceLocationModel(dto);
    return new Observable((observer: Observer<boolean>) => {
      this.http.post<boolean>(this.baseUrl + this.apiUrl + '/AddEditItemInstanceLocation', dto)
        .subscribe((x: boolean) => {
          if (x === true) {
            this.getItemInstanceLocations(showInactive, true);
          }
          observer.next(x);
        });
    });
  }

  public deleteItemInstanceLocation(
    id: number,
    showInactive: boolean): Observable<boolean> {
    return new Observable((observer: Observer<boolean>) => {
      this.http.get<boolean>(this.baseUrl + this.apiUrl + '/DeleteItemInstanceLocation/' + id)
        .subscribe((x: boolean) => {
          if (x === true) {
            this.getItemInstanceLocations(showInactive, true);
          }
          observer.next(x);
        });
    });
  }

  public generateNewItemInstanceLocationModel(): ItemInstanceLocationModel {
    let model: ItemInstanceLocationModel = new ItemInstanceLocationModel();

    model.sortOrder = this.getLastOrderNumber();
    model.isActive = true;

    return model;
  }

  private getLastOrderNumber(): number {
    let last: number = this.iilList.value !== null ? Math.max.apply(Math, this.iilList.value.map((x: ItemInstanceLocationModel) => {
      return x.sortOrder;
    })) : null;

    return last !== null ? last + 1 : 0;
  }

  private cleanItemInstanceLocationModel(model: ItemInstanceLocationModel): ItemInstanceLocationModel {
    model.name = model.name
      && model.name.trim() !== '' ?
      model.name.trim()
      : null;

    return model;
  }

}
