import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { CustomerService } from 'src/services/customer.service';
import { KGridService } from 'src/services/k-grid.service';
import { UIService } from 'src/services/ui.service';
import { WellService } from 'src/services/well.service';
import { JobPurchaseOrderLinkModel } from '../../../../models/job-purchase-order-link.model';
import { PurchaseOrderModel } from '../../../../models/purchase-order.model';
import { ReportModel } from '../../../../models/report.model';
import { JobService } from '../../../../services/job.service';
import { ReportService } from '../../../../services/report.service';
import { UserService } from '../../../../services/user.service';
import { LinkPoToJobModalComponent } from '../link-po-to-job-modal/link-po-to-job-modal.component';

@Component({
  selector: 'app-job-po-display',
  templateUrl: './job-po-display.component.html',
})

export class JobPoDisplayComponent implements OnInit, OnChanges, OnDestroy {
  @Input() purchaseOrders: PurchaseOrderModel[] = [];
  @Input() disable: boolean = false;
  @Input() jobId: number = null;
  public total: number = 0;
  public report: ReportModel = null;

  private reportSub: Subscription;
  private generateReportSub: Subscription;
  private unlinkPoSub: Subscription;

  constructor(
    private modalService: BsModalService,
    public wellService: WellService,
    public customerService: CustomerService,
    public jobService: JobService,
    public reportService: ReportService,
    public kGridService: KGridService,
    public userService: UserService,
    public uiService: UIService,
  ) { };

  public showInactive: boolean = false;
  public entityId: number = -1;
  public bsModalRef: BsModalRef;

  ngOnDestroy(): void {
    this.reportSub?.unsubscribe();

    this.generateReportSub?.unsubscribe();

    this.unlinkPoSub?.unsubscribe();
  }

  ngOnInit(): void {

  }

  ngOnChanges(changes: SimpleChanges): void {
    this.calculateTotal();
    this.checkForReport();
  }

  public calculateTotal(): void {
    let temp: number = 0;

    this.purchaseOrders.forEach((x: PurchaseOrderModel) => {
      temp += x.total;
    });

    this.total = temp;
  }

  public checkForReport(): void {
    if (this.purchaseOrders.length > 0 && this.report == null) {
      this.reportSub = this.reportService.getReport(3, true)
        .subscribe((x: ReportModel) => {
          this.report = x;
        });
    }
  }

  public generateReport(): void {
    if (this.report !== null && this.jobId !== null) {
      this.report.reportParams.jobId = this.jobId;

      this.generateReportSub = this.reportService.generateReport(this.report)
        .subscribe((x: boolean) => {
          if (x) {
            this.uiService.showSuccess('Report Generated', 'Success');
          } else {
            this.uiService.showError('Report Not Generated', 'Error');
          }
        });
    }
  }

  public openLinkPoModal(): void {
    let jobId: number = this.jobService.job.value !== null ? this.jobService.job.value.jobId : null;

    const initialState = {
      jobId: jobId,
      selectedPos: this.purchaseOrders
    };
    this.bsModalRef = this.modalService.show(LinkPoToJobModalComponent, { initialState, backdrop: 'static' });
  }

  public unlinkPo(poid: number): void {
    let jobId: number = this.jobService.job.value !== null ? this.jobService.job.value.jobId : null;
    if (jobId !== null && poid !== undefined) {
      let dto: JobPurchaseOrderLinkModel = new JobPurchaseOrderLinkModel();
      dto.jobId = jobId;
      dto.poIds = [poid];

      this.unlinkPoSub = this.jobService.unLinkPoFromJob(dto)
        .subscribe((x: boolean) => {
          if (x === true) {
            this.uiService.showSuccess('PO Unlinked', 'Success');
          } else {
            this.uiService.showError('PO NOT Unlinked', 'Error');
          }
        });
    }
  }

}
