import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { StateModel } from '../models/state.model';
import { tap } from 'rxjs/operators';

@Injectable()
export class StateService {
  constructor(
    private http: HttpClient,
    @Inject('BASE_URL') private baseUrl: string
  ) { }

  private apiUrl = 'api/State';  // URL to web api

  private stateListSubject = new BehaviorSubject<StateModel[]>(null);
  public _stateList$ = this.stateListSubject.asObservable();
  private isStateCallInProgress = false;

  public get stateList$(): Observable<StateModel[]> {
    if (!this.stateListSubject.getValue() && !this.isStateCallInProgress) {
      this.isStateCallInProgress = true;
      this.getStates$(false).subscribe(() => this.isStateCallInProgress = false);
    }

    return this._stateList$;
  }

  private getStates$(showInactive: boolean): Observable<StateModel[]> {
    return this.http.get<Array<StateModel>>(this.baseUrl + this.apiUrl + '/GetStates/' + showInactive)
      .pipe(
        tap((states: StateModel[]) => {
          this.stateListSubject.next(states);
        })
      );
  }

}
