import { BehaviorSubject, Observable, Observer } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ShopModel } from '../models/shop.model';
import { tap } from 'rxjs/operators';

@Injectable()
export class ShopService {
  constructor(
    private http: HttpClient,
    @Inject('BASE_URL') private baseUrl: string
  ) { }

  private apiUrl = 'api/Shop';  // URL to web api

  private shopListSubject = new BehaviorSubject<ShopModel[]>(null);
  public _shopList$ = this.shopListSubject.asObservable();
  private isShopCallInProgress = false;
  public shopList: BehaviorSubject<ShopModel[]> = new BehaviorSubject(null);

  public get shopList$(): Observable<ShopModel[]> {
    if (!this.shopListSubject.getValue() && !this.isShopCallInProgress) {
      this.isShopCallInProgress = true;
      this.getShops$(false).subscribe(() => this.isShopCallInProgress = false);
    }

    return this._shopList$;
  }

  public addEditShop(dto: ShopModel): Observable<boolean> {
    dto = this.cleanShopModel(dto);

    return new Observable((observer: Observer<boolean>) => {
      this.http.post<boolean>(this.baseUrl + this.apiUrl + '/AddEditShop', dto)
        .subscribe((x: boolean) => {
          if (x === true) {
            this.getShops();
          }
          observer.next(x);
        });
    });
  }

  public deleteShop(id: number): Observable<boolean> {
    return new Observable((observer: Observer<boolean>) => {
      this.http.get<boolean>(this.baseUrl + this.apiUrl + '/DeleteShop/' + id)
        .subscribe((x: boolean) => {
          if (x === true) {
            this.getShops();
          }
          observer.next(x);
        });
    });
  }

  public getShopById(id: number): Observable<ShopModel> {
    return this.http.get<ShopModel>(this.baseUrl + this.apiUrl + '/GetShopById/' + id);
  }

  public getShops(showInactive: boolean = false): void {
    this.http.get<ShopModel[]>(this.baseUrl + this.apiUrl + '/GetShops/' + showInactive)
      .subscribe((x: ShopModel[]) => {
        this.shopList.next(x);
      });
  }

  public generateNewShopModel(): ShopModel {
    let model: ShopModel = new ShopModel();

    model.isActive = true;
    model.sortOrder = this.getLastOrderNumber();

    return model;
  }

  private getLastOrderNumber(): number {
    const last: number = this.shopList.value !== null ? Math.max.apply(Math, this.shopList.value.map((x: ShopModel) => {
      return x.sortOrder;
    })) : null;

    return last !== null ? last + 1 : 0;
  }

  private getShops$(showInactive: boolean): Observable<ShopModel[]> {
    return this.http.get<Array<ShopModel>>(this.baseUrl + this.apiUrl + '/GetShops/' + showInactive)
      .pipe(
        tap((shops: ShopModel[]) => {
          this.shopListSubject.next(shops);
        })
      );
  }

  private cleanShopModel(model: ShopModel): ShopModel {
    Object.keys(model).forEach((index: string) => {
      if (typeof model[index] === 'string') {
        model[index] = model[index]
          && model[index].trim() !== '' ?
          model[index].trim()
          : null;
      }
    });

    return model;
  }

}
