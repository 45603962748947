import { BehaviorSubject, Observable, Observer } from 'rxjs';
import { BillingCodeFilterParamsModel } from 'src/models/RequestParams/billing-code-params.model';
import { BillingCodeModel } from 'src/models/billing-code.model';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { UserService } from './user.service';

@Injectable()
export class BillingCodeService {
  constructor(
    private http: HttpClient,
    private userService: UserService,
    @Inject('BASE_URL') private baseUrl: string
  ) { }

  public billingCodeList: BehaviorSubject<Array<BillingCodeModel>> = new BehaviorSubject(null);
  public mileageBCList: BehaviorSubject<Array<BillingCodeModel>> = new BehaviorSubject(null);
  public pkrTlSupBCList: BehaviorSubject<Array<BillingCodeModel>> = new BehaviorSubject(null);
  public billingCodeCache: BehaviorSubject<BillingCodeModel> = new BehaviorSubject(null);
  public billingCodeFilterParams: BillingCodeFilterParamsModel = new BillingCodeFilterParamsModel();
  public billingCodesAreLoading = false;

  private apiUrl = 'api/BillingCode';  // URL to web api

  public addEditBillingCode(
    dto: BillingCodeModel,
    showInactive: boolean = false,
    cacheBillingCode: boolean = false,
    showUnselectable: boolean = false,
    getByItem: boolean = false,
    keepFilter: boolean = false): Observable<boolean> {
    return new Observable((observer: Observer<boolean>) => {
      this.http.post<number>(this.baseUrl + this.apiUrl + '/AddEditBillingCode', this.cleanBillingCodeModel(dto))
        .subscribe((x: number) => {

          if (x !== -1 && !getByItem) {
            this.getBillingCodes(showInactive, true, showUnselectable, keepFilter === true ? !keepFilter : !showUnselectable);
          } else {
            this.refreshBillingCodesByItemId(dto.itemId);
          }

          if (cacheBillingCode) {
            dto.billingCodeId = x;
            this.billingCodeCache.next(dto);
          } else {
            this.billingCodeCache.value !== null ? this.billingCodeCache.next(null) : null;
          }

          observer.next(x !== -1);
        });
    });
  }

  public deleteBillingCode(
    id: number,
    showInactive: boolean): Observable<boolean> {
    return new Observable((observer: Observer<boolean>) => {
      this.http.get<boolean>(this.baseUrl + this.apiUrl + '/DeleteBillingCode/' + id)
        .subscribe((x: boolean) => {
          if (x === true) {
            this.getBillingCodes(showInactive, true, false, false);
          }
          observer.next(x);
        });
    });
  }

  public getBillingCodeById(id: number): Observable<BillingCodeModel> {
    return this.http.get<BillingCodeModel>(this.baseUrl + this.apiUrl + '/GetBillingCodeById/' + id)
      .pipe(tap((x: BillingCodeModel) => {
        x.dropDownDisplay = `${x.name} ($${x.rate !== null && x.rate !== undefined ? x.rate.toFixed(2) : 0}/${x.uomName.toLowerCase()})`;
      }));
  }

  public getBillingCodes(
    showInactive: boolean,
    clear = false,
    showUnselectable: boolean = false,
    clearParams: boolean = false,
    showSkeletons: boolean = false): void {
    this.billingCodeFilterParams.name = this.billingCodeFilterParams.name !== null
      && this.billingCodeFilterParams.name !== undefined
      && this.billingCodeFilterParams.name.trim() !== '' ? this.billingCodeFilterParams.name : null;

    if (!this.billingCodeList.value || clear) {
      if (showSkeletons) {
        this.billingCodesAreLoading = true;
      }

      this.http.post<Array<BillingCodeModel>>(this.baseUrl + this.apiUrl + '/GetBillingCodes/' + showInactive + '/' + showUnselectable, clearParams ?
        new BillingCodeFilterParamsModel() : this.billingCodeFilterParams)
        .pipe(tap((x: Array<BillingCodeModel>) => {

          const canSeePrices = this.canSeePrices();

          x.forEach((bc: BillingCodeModel) => {
            bc.dropDownDisplay = `${bc.name}`;
            if (canSeePrices) {
              bc.dropDownDisplay += ` ($${bc.rate !== null && bc.rate !== undefined ? bc.rate.toFixed(2) : 0}/${bc.uomName.toLowerCase()})`;
            }
          });
        }))
        .subscribe((x: BillingCodeModel[]) => {
          this.billingCodeList.next(x);
          this.billingCodesAreLoading = false;
        });
    }
  }

  public getBillingCodesByItemId(
    itemId: number,
    showInactive: boolean = false): Observable<Array<BillingCodeModel>> {
    return this.http.get<Array<BillingCodeModel>>(this.baseUrl + this.apiUrl + '/GetBillingCodesByItemId/' + itemId + '/' + showInactive)
      .pipe(tap((x: Array<BillingCodeModel>) => {
        x.forEach((item: BillingCodeModel) => {
          item.dropDownDisplay = `${item.name} ($${item.rate.toFixed(2)}/${item.uomName.toLowerCase()})`;
        });
      }));
  }

  public refreshBillingCodesByItemId(
    itemId: number,
    showInactive: boolean = false): void {
    this.getBillingCodesByItemId(itemId)
      .subscribe((x: BillingCodeModel[]) => {
        this.billingCodeList.next(x);
      });
  }

  public getMileageBillingCodes(): void {
    this.http.get<Array<BillingCodeModel>>(this.baseUrl + this.apiUrl + '/GetMileageBillingCodes')
      .pipe(tap((x: Array<BillingCodeModel>) => {
        const canSeePrices = this.canSeePrices();

        x.forEach((item: BillingCodeModel) => {
          item.dropDownDisplay = `${item.name}`;

          if (canSeePrices) {
            item.dropDownDisplay += ` ($${item.rate.toFixed(2)}/${item.uomName.toLowerCase()})`;
          }
        });
      }))
      .subscribe((x: BillingCodeModel[]) => {
        this.mileageBCList.next(x);
      });
  }

  public getPackerToolSupervisorBillingCodes(): void {
    this.http.get<Array<BillingCodeModel>>(this.baseUrl + this.apiUrl + '/GetPackerToolSupervisorBillingCodes')
      .pipe(tap((x: Array<BillingCodeModel>) => {
        const canSeePrices = this.canSeePrices();

        x.forEach((item: BillingCodeModel) => {
          item.dropDownDisplay = `${item.name}`;

          if (canSeePrices) {
            item.dropDownDisplay += ` ($${item.rate.toFixed(2)}/${item.uomName.toLowerCase()})`;
          }
        });
      }))
      .subscribe((x: BillingCodeModel[]) => {
        this.pkrTlSupBCList.next(x);
      });
  }

  public refreshBillingCodes(showInactive: boolean) {
    this.clearFilterParams(showInactive);
  }

  public clearFilterParams(showInactive: boolean) {
    Object.keys(this.billingCodeFilterParams).forEach((index) => {
      this.billingCodeFilterParams[index] = null;
    });

    this.getBillingCodes(showInactive, true);
  }

  public cleanBillingCodeModel(model: BillingCodeModel): BillingCodeModel {
    Object.keys(model).forEach((index: string) => {
      if (typeof model[index] === 'string') {
        model[index] = model[index]
          && model[index].trim() !== '' ?
          model[index].trim()
          : null;
      }
    });

    return model;
  }

  public generateNewBillingCodeModel(): BillingCodeModel {
    let model: BillingCodeModel = new BillingCodeModel();
    model.isTaxable = true;
    model.isSelectable = true;
    model.isActive = true;
    model.quantity = 1;
    model.rate = null;

    return model;
  }

  private canSeePrices(): boolean {
    return this.userService.user.value != null
      && this.userService.user.value.activeRoles != null ?
      this.userService.user.value.activeRoles.includes('General')
      : false;
  }

  public getNumberOfActiveFilterFields(): number {
    let count: number = 0;

    Object.values(this.billingCodeFilterParams).forEach((value: any) => {
      count = value ? count += 1 : count;
    });

    return count;
  }

}
