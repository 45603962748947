export class ItemInstanceModel {
  itemInstanceId: number;
  itemId: number;
  serialNumber: string;
  poid: number | null;
  itemInstanceLocationId: number | null;
  itemInstanceLocation: string;
  description: string;
  outerDiameter: number | null;
  innerDiameter: number | null;
  length: number | null;
  originalCost: number | null;
  jobId: number | null;
  jobNumber: number | null;
  shopId: number;
  shopName: string;
  isActive: boolean | null;
  threadTypeId: number | null;
  threadTypeName: string;
  numberOfBlades: number | null;
  purchasePoLineId: number | null;
  purchasePoNumber: string;
  purchasePoId: number | null;
  hasMaintenanceLog: boolean;
  maintenanceDurationUomId: number | null;
  maintenanceDurationUomName: string;

  requiresQuantity: boolean | null;
  quantityUom: string;
  fullGtNumber: string;
  dropdownDisplay: string;
  disabled: boolean = false;
  showAdditionalDaysOnly: boolean = false;
  showChargeAllMinimumDays: boolean = false;
  allowDelete: boolean = false;
  partGroupId: number | null;
}
