import { BehaviorSubject, Observable, Observer } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { InventoryHistoryModel } from 'src/models/inventory-history.model';
import { InventoryTransferModel } from '../models/inventory-transfer.model';
import { ItemService } from './item.service';
import { Shops } from '../enums/shops';
import { tap } from 'rxjs/operators';
import { UserModel } from '../models/user.model';
import { UserService } from './user.service';

@Injectable()
export class InventoryHistoryService {
  constructor(private http: HttpClient,
    @Inject('BASE_URL') private baseUrl: string,
    public itemService: ItemService,
    public userService: UserService
  ) { }

  public inventoryHistoryList: BehaviorSubject<Array<InventoryHistoryModel>> = new BehaviorSubject(null);
  public inventoryHistoryShopList: BehaviorSubject<InventoryHistoryModel[][]> = new BehaviorSubject(null);
  public Shops = Shops;

  private apiUrl = 'api/InventoryHistory';  // URL to web api

  public getInventoryHistoryByItemId(id: number): void {
    this.http.get<Array<InventoryHistoryModel>>(this.baseUrl + this.apiUrl + '/GetInventoryHistoryByItemId/' + id)
      .pipe(tap((x: Array<InventoryHistoryModel>) => {
        x.forEach((x: InventoryHistoryModel) => {
          x.changedOn = x.changedOn !== undefined && x.changedOn !== null ? new Date(x.changedOn) : null;

          if (x.shopInventoryChange !== undefined && x.shopInventoryChange !== null) {
            let invPositive: string = Math.sign(x.shopInventoryChange) === 1 ? '+' : '';
            x.shopInventoryChangeDisplay = `${invPositive}${x.shopInventoryChange.toString()}`;
          }

          if (x.shopReserveChange !== undefined && x.shopReserveChange !== null) {
            let resPositive: string = Math.sign(x.shopReserveChange) === 1 ? '+' : '';
            x.shopReserveChangeDisplay = `${resPositive}${x.shopReserveChange.toString()}`;
          }
        });
      }))
      .subscribe((x: InventoryHistoryModel[]) => {
        this.inventoryHistoryList.next(x);
      });
  }

  public getInventoryHistoryPerShopByItemId(id: number): Observable<boolean> {
    return new Observable((observer: Observer<boolean>) => {
      this.http.get<InventoryHistoryModel[][]>(this.baseUrl + this.apiUrl + '/GetInventoryHistoryPerShopByItemId/' + id)
        .pipe(tap((x: InventoryHistoryModel[][]) => {
          x.forEach((y: InventoryHistoryModel[]) => {
            y.forEach((z: InventoryHistoryModel) => {
              z.changedOn = z.changedOn !== undefined && z.changedOn !== null ? new Date(z.changedOn) : null;

              if (z.shopInventoryChange !== undefined && z.shopInventoryChange !== null) {
                let invPositive: string = Math.sign(z.shopInventoryChange) === 1 ? '+' : '';
                z.shopInventoryChangeDisplay = `${invPositive}${z.shopInventoryChange.toString()}`;
              }

              if (z.shopReserveChange !== undefined && z.shopReserveChange !== null) {
                let resPositive: string = Math.sign(z.shopReserveChange) === 1 ? '+' : '';
                z.shopReserveChangeDisplay = `${resPositive}${z.shopReserveChange.toString()}`;
              }
            });
          });
        }))
        .subscribe((x: InventoryHistoryModel[][]) => {
          this.inventoryHistoryShopList.next(x);
          observer.next(true);
        });
    });
  }

  public manualInventoryAdjustment(dto: InventoryHistoryModel): Observable<boolean> {
    return new Observable((observer: Observer<boolean>) => {
      this.http.post<boolean>(this.baseUrl + this.apiUrl + '/ManualInventoryAdjustment', this.cleanNewInventoryHistoryModel(dto))
        .subscribe((x: boolean) => {
          if (x === true) {
            this.getInventoryHistoryPerShopByItemId(dto.itemId);
            this.itemService.refreshItem(dto.itemId);
          }
          observer.next(x);
        });
    });
  }

  public manualInventoryTransfer(dto: InventoryTransferModel): Observable<boolean> {
    return new Observable((observer: Observer<boolean>) => {
      // this.http.post<boolean>(this.baseUrl + this.apiUrl + '/ManualInventoryTransfer', dto)
      //   .subscribe(x => {
      //     if (x === true) {
      //       this.getInventoryHistoryByItemId(dto.itemId);
      //       this.itemService.refreshItem(dto.itemId);
      //     }
      //     observer.next(x);
      //   });
    });
  }

  public generateNewInventoryHistoryModel(
    itemId: number,
    shopId: number): InventoryHistoryModel {
    let model: InventoryHistoryModel = new InventoryHistoryModel();
    model.itemId = itemId;
    model.shopId = shopId;

    return model;
  }

  public generateNewInventoryTransferModel(
    itemId: number,
    shopId: number): InventoryTransferModel {
    const user: UserModel = this.userService.user.value;

    let model: InventoryTransferModel = new InventoryTransferModel();
    model.itemId = itemId;

    if (user) {
      model.fromShopId = shopId;
      model.toShopId = model.fromShopId ? (model.fromShopId === this.Shops.Williston ? this.Shops.Dickinson : this.Shops.Williston) : null;
    } else {
      model.fromShopId = null;
      model.toShopId = null;
    }

    return model;
  }

  private cleanNewInventoryHistoryModel(model: InventoryHistoryModel): InventoryHistoryModel {
    Object.keys(model).forEach((index: string) => {
      if (typeof model[index] === 'string') {
        model[index] = model[index]
          && model[index].trim() !== '' ?
          model[index].trim()
          : null;
      }
    });

    return model;
  }
  // public zeroOutDicksonInventory(): Observable<boolean> {
  //   return this.http.get<boolean>(this.baseUrl + this.apiUrl + '/ZeroOutDicksonInventory');
  // }

}
