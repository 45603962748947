import { BehaviorSubject, Observable, Observer } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { PurchaseOrderStatusModel } from '../models/purchase-order-status.model';
import { tap } from 'rxjs/operators';

@Injectable()
export class PurchaseOrderStatusService {
  constructor(
    private http: HttpClient,
    @Inject('BASE_URL') private baseUrl: string
  ) { }

  public statusList: BehaviorSubject<Array<PurchaseOrderStatusModel>> = new BehaviorSubject(null);
  public cancel: BehaviorSubject<PurchaseOrderStatusModel> = new BehaviorSubject(null);

  private apiUrl = 'api/PurchaseOrderStatus';  // URL to web api

  public addEditPurchaseOrderStatus(dto: PurchaseOrderStatusModel, showInactive: boolean): Observable<boolean> {
    dto = this.cleanPurchaseOrderStatusModel(dto);

    return new Observable((observer: Observer<boolean>) => {
      this.http.post<boolean>(this.baseUrl + this.apiUrl + '/AddEditPurchaseOrderStatus', dto)
        .subscribe((x: boolean) => {
          if (x === true) {
            this.getPurchaseOrderStatuses(showInactive, true, false, true);
          }
          observer.next(x);
        });
    });
  }

  public deletePurchaseOrderStatus(id: number, showInactive: boolean): Observable<boolean> {
    return new Observable((observer: Observer<boolean>) => {
      this.http.get<boolean>(this.baseUrl + this.apiUrl + '/DeletePurchaseOrderStatus/' + id)
        .subscribe((x: boolean) => {
          if (x === true) {
            this.getPurchaseOrderStatuses(showInactive, true, false, true);
          }
          observer.next(x);
        });
    });
  }

  public getPurchaseOrderStatusById(id: number): Observable<PurchaseOrderStatusModel> {
    return this.http.get<PurchaseOrderStatusModel>(this.baseUrl + this.apiUrl + '/GetPurchaseOrderStatusById/' + id);
  }

  public getPurchaseOrderStatuses(
    showInactive: boolean,
    clear: boolean = false,
    setDisable: boolean = true,
    showCanceled = false): void {
    if (!this.statusList.value || clear || setDisable) {
      this.statusList.next(null);

      this.http.get<Array<PurchaseOrderStatusModel>>(this.baseUrl + this.apiUrl + '/GetPurchaseOrderStatuses/' + showInactive)
        .pipe(tap((x: Array<PurchaseOrderStatusModel>) => {
          let index: number = x.findIndex((y: PurchaseOrderStatusModel) => {
            return y.name === 'Canceled';
          });

          let c = x.slice(index)[0];
          this.cancel.next(c);

          if (setDisable) {
            x.forEach((sta: PurchaseOrderStatusModel) => sta.disabled = sta.readOnly);
          }

          if (!showCanceled) {
            if (index !== null && index !== undefined) {
              x.splice(index, 1)[0];
            }
          }
        }))
        .subscribe((x: PurchaseOrderStatusModel[]) => {
          this.statusList.next(x);
        });
    }
  }

  public generatePurchaseOrderStatusModel(): PurchaseOrderStatusModel {
    let model: PurchaseOrderStatusModel = new PurchaseOrderStatusModel();

    model.isActive = true;
    model.statusOrder = this.getLastOrderNumber();
    model.readOnly = false;
    model.showInDropDown = true;

    return model;
  }

  private getLastOrderNumber(): number {
    const last: number = this.statusList.value !== null ? Math.max.apply(Math, this.statusList.value.map((x: PurchaseOrderStatusModel) => {
      return x.statusOrder;
    })) : null;

    return last !== null ? last + 1 : 0;
  }


  private cleanPurchaseOrderStatusModel(model: PurchaseOrderStatusModel): PurchaseOrderStatusModel {
    Object.keys(model).forEach((index: string) => {
      if (typeof model[index] === 'string') {
        model[index] = model[index]
          && model[index].trim() !== '' ?
          model[index].trim()
          : null;
      }
    });

    return model;
  }

}
