import { WorksheetRowItemTypeModel } from './worksheet-row-item-type.model';

export class WorksheetRowTypeModel {
  worksheetRowTypeId: number;
  worksheetTypeId: number | null;
  worksheetTypeName: string;
  sortOrder: number | null;
  name: string;
  itemized: boolean;
  isNewBuildBillingCode: boolean;
  isActive: boolean;

  worksheetRowItemTypes: WorksheetRowItemTypeModel[];
}
