<div class="page-header">
  <div class="page-header-content header-elements-md-inline">
    <div class="page-title d-flex">
      <a [routerLink]="[returnUrl]"
        class="breadcrumb-link-color"
        *ngIf="userService.user.value && userService.user.value.activeRoles && userService.user.value.activeRoles.includes('General')">
        <h4><i class="icon-arrow-left52 mr-2"></i> <span class="font-weight-semibold">Fishing Tool Rental Invoice</span>
          - Details</h4>
      </a>
      <button type="button"
        class="btn btn-outline-dark btn-sm ml-3 pl-1 pr-1 pt-0 pb-0 no-border"
        [title]="!showDetails ? 'Show Details' : 'Hide Details'"
        (click)="showDetails = !showDetails">
        <span *ngIf="!showDetails">
          <i class="fa-regular fa-chevrons-right"></i>&nbsp;Show </span>
        <span *ngIf="showDetails"><i class="fa-regular fa-chevrons-left"></i>&nbsp;Hide
        </span>Details</button>
    </div>
    <div class="header-elements d-none py-0 mb-3 mb-md-0">
      <div class="breadcrumb">
        <a href="index.html"
          class="breadcrumb-item"><i class="icon-home2 mr-2"></i> Invoice</a>
        <span class="breadcrumb-item active">Detail</span>
      </div>
    </div>
  </div>
</div>
<div class="page-content pt-0"
  *ngIf="jobService.job | async; let j">
  <div class="content-wrapper">
    <div class="content">
      <div class="row">
        <div class="col-lg-3"
          *ngIf="showDetails">
          <!-- LEFT HAND JOB SUMMARY CARD -->
          <div class="card mb-0">
            <div class="card-header bg-light header-elements-inline">
              <h6 class="card-title">Invoice#: {{j.jobNumber}}</h6>
              <div class="header-elements">
                <button class="btn btn-success btn-sm mr-1 btn-light"
                  *ngIf="!j.notReadyToInvoice"
                  title="Ready To Invoice"
                  (click)="toggleReadyToInvoice(j.jobId)"
                  [disabled]="enableReorder || enableReorderToolList || (jobService.job.value !== null && jobService.job.value.jobStatusIsTerminal === true) || !(userService.user.value && userService.user.value.activeRoles && userService.user.value.activeRoles.includes('General'))">
                  <span><i class="far fa-check-circle"></i></span><span
                    *ngIf="!isTablet">&nbsp;&nbsp;Ready</span></button>
                <button class="btn btn-success btn-sm mr-1 btn-danger"
                  *ngIf="j.notReadyToInvoice"
                  title="Not Ready To Invoice"
                  (click)="toggleReadyToInvoice(j.jobId)"
                  [disabled]="enableReorder || enableReorderToolList || (jobService.job.value !== null && jobService.job.value.jobStatusIsTerminal === true) || !(userService.user.value && userService.user.value.activeRoles && userService.user.value.activeRoles.includes('General'))"><i
                    class="fa-regular fa-circle-xmark"></i><span *ngIf="!isTablet">&nbsp;&nbsp;Not Ready</span></button>
                <button class="btn btn-light btn-sm"
                  (click)="openJobFishingModal(j.jobId)"
                  [disabled]="enableReorder || enableReorderToolList || jobService.job.value !== null && jobService.job.value.jobStatusIsTerminal === true"><i
                    class="fa fa-edit"></i><span *ngIf="!isTablet"> Edit</span></button>
              </div>
            </div>
            <div class="card-body"
              [ngClass]="{'pr-2 pl-2':isTablet}">
              <div id="collapseBasic"
                [collapse]="isCollapsed">
                <app-job-info [job]="j"></app-job-info>
                <br>
              </div>
              <ng-container
                *ngIf="userService.user.value && userService.user.value.activeRoles && userService.user.value.activeRoles.includes('General')">
                <div class="row">
                  <div class="col-md-12">
                    <button class="btn btn-light btn-sm btn-block"
                      (click)="reportService.getAllFishingInvoicePDF(j.jobId)"
                      [disabled]="enableReorder || enableReorderToolList || (!j.invoices ||j.invoices.length === 0 || (j.invoices.length > 0 && (j.invoices[0].invoiceItems !== undefined && j.invoices[0].invoiceItems.length === 0)))"><i
                        class="fal fa-file-pdf"></i> Print Invoice</button>
                    <app-sub-status-button [job]="j"
                      [enableReorder]="enableReorder"
                      *ngIf="jobService.job.value !== null 
                          && (
                                (jobService.job.value.jobStatusId === JobStatuses.Open_FishingToolRental 
                                  && (jobService.job.value.jobSubStatusId === JobSubStatuses.In_Progress))
                                ||
                                (jobService.job.value.jobStatusId === JobStatuses.EditInvoice_FishingToolRental && (jobService.job.value.jobSubStatusId === JobSubStatuses.Pending_Approval || jobService.job.value.jobSubStatusId === JobSubStatuses.Ready_for_Signature))
                              )">
                    </app-sub-status-button>
                    <app-status-button-fishing [job]="j"
                      [enableReorder]="enableReorder"
                      *ngIf="jobService.job.value !== null
                          && (
                                (jobService.job.value.jobStatusId === JobStatuses.Open_FishingToolRental 
                                  && jobService.job.value.jobSubStatusId === JobSubStatuses.Pending_Approval)
                                ||
                                (jobService.job.value.jobStatusId === JobStatuses.EditInvoice_FishingToolRental
                                  && jobService.job.value.jobSubStatusId === JobSubStatuses.Sent_for_Signature)
                                ||
                                (jobService.job.value.jobStatusId === JobStatuses.Closed_FishingToolRental)
                                ||
                                (jobService.job.value.jobStatusId === JobStatuses.Cancelled_FishingToolRental)
                              )">
                    </app-status-button-fishing>
                    <button class="btn btn-outline-dark btn-sm btn-block"
                      (click)="openCopyJobModal()"><i class="fal fa-clone"></i>&nbsp;Copy Job</button>
                  </div>
                </div>
              </ng-container>
            </div>
          </div>
          <!-- END LEFT HAND JOB SUMMARY CARD -->
          <!-- POSSIBLE BHA LIST LIKE WORKSHEET LIST?? -->
          <ng-container
            *ngIf="userService.user.value && userService.user.value.activeRoles && userService.user.value.activeRoles.includes('General')">
            <app-job-po-display [purchaseOrders]="j.purchaseOrders"
              [disable]="j.jobStatusId !== JobStatuses.Open_FishingToolRental"
              [jobId]="jobId"></app-job-po-display>
          </ng-container>
        </div>
        <div [ngClass]="{'col-lg-9': showDetails, 'col-lg-12': !showDetails}">
          <tabset [justified]="true"
            class="compact-nav">
            <!-- FISHING TOOL TAB -->
            <tab heading="Tool Rental List"
              id="tab1"
              [disabled]="enableReorder || enableReorderToolList">
              <div class="d-flex justify-content-between mb-1">
                <button class="btn btn-primary"
                  [disabled]="disableReleaseAll || enableReorder || enableReorderToolList"
                  [swal]="{ title: 'Release All Serialized Items?', showCancelButton: true, confirmButtonText: 'Release', confirmButtonColor: '#007bff' }"
                  (confirm)="releaseAllFromJob()">
                  <i class="far fa-check-square"></i>&nbsp;&nbsp;Release All
                </button>
                <div>
                  <button class="btn  btn-sm btn-primary mr-2"
                    *ngIf="isTablet"
                    [disabled]="tools.length <= 1"
                    (click)="openReorderToolsModal()"><i class="fas fa-arrows-v"></i>&nbsp;&nbsp;Re-sort</button>
                  <div class="switch-container mr-2 d-inline-block"
                    *ngIf="!enableReorderToolList && !isTablet">
                    <span class="switch-label">Enable Re-sort </span>
                    <ui-switch [ngModel]="enableReorderToolList"
                      (change)="switchEnableReorder($event)"></ui-switch>
                  </div>
                  <button class="btn btn-sm btn-primary mr-2 d-inline-block"
                    *ngIf="enableReorderToolList"
                    (click)="switchEnableReorder(false)">Save Sort Order</button>
                  <div class="btn-group d-inline-block"
                    dropdown>
                    <button class="btn btn-light btn-sm dropdown-toggle"
                      *ngIf="j.invoiceHasBeenSplit || enableReorder || enableReorderToolList || jobService.job.value !== null && jobService.job.value.jobStatusIsTerminal === true"
                      disabled>
                      <i class="fa fa-plus"></i> Add
                      <span class="caret"></span>
                    </button>
                    <button class="btn btn-light btn-sm dropdown-toggle"
                      dropdownToggle
                      id="button-basic"
                      aria-controls="dropdown-basic"
                      *ngIf="!j.invoiceHasBeenSplit && !enableReorder && !enableReorderToolList && !(jobService.job.value !== null && jobService.job.value.jobStatusIsTerminal === true)">
                      <i class="fa fa-plus"></i> Add
                      <span class="caret"></span>
                    </button>
                    <ul id="dropdown-basic"
                      *dropdownMenu
                      class="dropdown-menu dropdown-menu-right"
                      role="menu"
                      aria-labelledby="button-basic">
                      <li role="menuitem"><span class="dropdown-item"
                          (click)="openJobFishingItemInstanceModal(0, j.jobId, j.jobTypeId, JobFishingItemModalTypes.Serialized)"><i
                            class="fal fa-barcode"></i>Serialized Item</span></li>
                      <li role="menuitem"><span class="dropdown-item"
                          (click)="openJobFishingItemInstanceModal(0, j.jobId, j.jobTypeId, JobFishingItemModalTypes.Inventory)"><i
                            class="fal fa-inventory"></i>Inventory Item</span></li>
                      <li role="menuitem"><span class="dropdown-item"
                          (click)="openJobFishingItemInstanceModal(0, j.jobId, j.jobTypeId, JobFishingItemModalTypes.ThirdParty)"><i
                            class="fal fa-hand-holding-box"></i>Third Party Item</span></li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="table-responsive">
                <div class="alert alert-primary mb-0 mt-2"
                  role="alert"
                  *ngIf="j.jobItemInstances.length === 0">
                  No Tools added for Job#: {{j.jobNumber}}. Hit the <strong><i class="fas fa-plus"></i> Add</strong>
                  button to select a new Tool.
                </div>
                <div class="mt-2"
                  *ngIf="j.jobItemInstances.length > 0">
                  <kendo-grid [data]="j.jobItemInstances"
                    [style.maxHeight.px]="620"
                    [rowClass]="rowCallback_2"
                    [sortable]="true"
                    (dataStateChange)="dataStateChange($event)">
                    <kendo-grid-column field="move"
                      title=""
                      [width]="40"
                      filterable="false"
                      *ngIf="enableReorderToolList">
                      <ng-template kendoGridCellTemplate
                        let-dataItem
                        let-rowIndex="rowIndex">
                        <span class="pl-1 text-danger"><i class="fas fa-arrows-alt-v"></i></span>
                      </ng-template>
                    </kendo-grid-column>
                    <kendo-grid-column field="one"
                      title=""
                      [width]="55">
                      <ng-template kendoGridCellTemplate
                        let-dataItem
                        let-rowIndex="rowIndex">
                        <button class="btn btn-xs btn-default"
                          (click)="openJobFishingItemInstanceModal(dataItem.jobItemInstanceId, j.jobId, j.jobTypeId, dataItem.type)"
                          [disabled]="enableReorder || enableReorderToolList || j.invoiceHasBeenSplit || jobService.job.value !== null && jobService.job.value.jobStatusIsTerminal === true"><i
                            class="fa fa-pencil"></i></button>
                      </ng-template>
                    </kendo-grid-column>
                    <kendo-grid-column field="two"
                      title="Release"
                      [width]="65"
                      [style]="{'text-align': 'center'}"
                      [headerStyle]="{'text-align': 'center'}">
                      <ng-template kendoGridCellTemplate
                        let-dataItem
                        let-rowIndex="rowIndex">
                        <input type="checkbox"
                          [checked]="dataItem.releasedFromJob === true"
                          (click)="toggleReleaseFromJob(dataItem.jobItemInstanceId)"
                          [disabled]="enableReorder || enableReorderToolList || dataItem.sold===true||j.invoiceHasBeenSplit || jobService.job.value !== null && jobService.job.value.jobStatusIsTerminal === true"
                          *ngIf="dataItem.itemInstanceId !== null">
                      </ng-template>
                    </kendo-grid-column>
                    <kendo-grid-column field="Invoice"
                      title="Invoice"
                      [width]="60"
                      [style]="{'text-align': 'center'}"
                      [headerStyle]="{'text-align': 'center'}">
                      <ng-template kendoGridCellTemplate
                        let-dataItem
                        let-rowIndex="rowIndex">
                        <span *ngIf="!dataItem.doNotCharge"
                          title="Invoice"><i class="fas fa-dollar-sign"></i></span>
                      </ng-template>
                    </kendo-grid-column>
                    <kendo-grid-column field="two"
                      title="Scrap"
                      [width]="65"
                      [style]="{'text-align': 'center'}"
                      [headerStyle]="{'text-align': 'center'}">
                      <ng-template kendoGridCellTemplate
                        let-dataItem
                        let-rowIndex="rowIndex">
                        <span *ngIf="dataItem.sold===true || dataItem.removeFromInventory===true"
                          class="text-danger"><i class="fas fa-times"></i><span
                            *ngIf="dataItem.soldQty !== undefined && dataItem.soldQty !== null && dataItem.quantityUom !== undefined && dataItem.quantityUom !== null">&nbsp;&nbsp;({{dataItem.soldQty}}<span
                              *ngIf="dataItem.quantityUom!=='Each'&&dataItem.quantityUom!=='Run'&&dataItem.quantityUom!=='Day'">{{dataItem.quantityUom}}</span>)</span></span>
                      </ng-template>
                    </kendo-grid-column>
                    <kendo-grid-column field="three"
                      title="Type"
                      [width]="60"
                      [style]="{'text-align': 'center'}"
                      [headerStyle]="{'text-align': 'center'}">
                      <ng-template kendoGridCellTemplate
                        let-dataItem
                        let-rowIndex="rowIndex">
                        <span *ngIf="dataItem.type===1"
                          title="Serialized Item"><i class="far fa-barcode"></i></span>
                        <span *ngIf="dataItem.type===2"
                          title="Inventory Item"><i class="far fa-inventory"></i></span>
                        <span *ngIf="dataItem.type===3"
                          title="Third-Party Item"><i class="far fa-hand-holding-box"></i></span>
                      </ng-template>
                    </kendo-grid-column>
                    <kendo-grid-column field="four"
                      title="Serial #"
                      [width]="80"
                      [style]="{'text-align': 'center'}"
                      [headerStyle]="{'text-align': 'center'}">
                      <ng-template kendoGridCellTemplate
                        let-dataItem
                        let-rowIndex="rowIndex">
                        <span
                          *ngIf="dataItem.fullGtNumber !== undefined && dataItem.fullGtNumber !== null">{{dataItem.fullGtNumber}}</span><span
                          class="text-muted"
                          *ngIf="dataItem.fullGtNumber === undefined || dataItem.fullGtNumber === null">n/a</span>
                      </ng-template>
                    </kendo-grid-column>
                    <kendo-grid-column field="description"
                      title="Tools Used"
                      [style]="{'text-align': 'left'}"
                      [headerStyle]="{'text-align': 'left'}"
                      [width]="225">
                    </kendo-grid-column>
                    <kendo-grid-column field="one"
                      title="Qty"
                      [width]="140">
                      <ng-template kendoGridCellTemplate
                        let-dataItem
                        let-rowIndex="rowIndex">
                        <ng-container *ngIf="(dataItem.type===1&&dataItem.quantityUom!=='Well')||dataItem.type===3">
                          <div class="input-group-append input-group-sm">
                            <input type="number"
                              name="name"
                              [(ngModel)]="dataItem.numberOfDays"
                              class="form-control form-control-sm"
                              (blur)="saveJobItemInstance(dataItem)"
                              [readonly]="enableReorder || enableReorderToolList || jobService.job.value !== null && jobService.job.value.jobStatusIsTerminal === true" />
                            <div class="input-group-append">
                              <span class="input-group-text">
                                days
                              </span>
                            </div>
                          </div>
                        </ng-container>
                        <ng-container *ngIf="(dataItem.type===1&&dataItem.quantityUom==='Well')||dataItem.type===2">
                          <input type="number"
                            name="name"
                            [(ngModel)]="dataItem.qty"
                            class="form-control form-control-sm"
                            [readonly]="enableReorder || enableReorderToolList || jobService.job.value !== null && jobService.job.value.jobStatusIsTerminal === true"
                            (blur)="saveJobItemInstance(dataItem)" />
                        </ng-container>
                      </ng-template>
                    </kendo-grid-column>
                    <kendo-grid-column field="one"
                      title=""
                      [width]="50">
                      <ng-template kendoGridCellTemplate
                        let-dataItem
                        let-rowIndex="rowIndex">
                        <button class="btn btn-xs"
                          [swal]="{ title: 'Delete ' + dataItem.description + '?', text:dataItem.serialNumber, showCancelButton: true, confirmButtonText: 'Delete', confirmButtonColor: '#DC3545' }"
                          (confirm)="deleteJobItemInstance(dataItem)"
                          [disabled]="enableReorder || enableReorderToolList || j.invoiceHasBeenSplit || dataItem.dayIds.length > 0 || dataItem.onBha || jobService.job.value !== null && jobService.job.value.jobStatusIsTerminal === true">
                          <i class="fa fa-trash"></i>
                        </button>
                      </ng-template>
                    </kendo-grid-column>
                  </kendo-grid>
                </div>
              </div>
            </tab>
            <!-- END FISHING TOOL TAB -->
            <!--INVOICE -->
            <tab heading="Invoice"
              id="tab2"
              *ngIf="userService.user.value && userService.user.value.activeRoles && userService.user.value.activeRoles.includes('General')"
              [disabled]="enableReorder || enableReorderToolList">
              <div class="alert alert-primary mb-0"
                role="alert"
                *ngIf="!j.invoices || j.invoices.length === 0 || (j.invoices.length > 0 && (j.invoices[0].invoiceItems !== undefined && j.invoices[0].invoiceItems.length === 0))">
                No <strong>Invoice Items</strong> added for Job#: {{j.jobNumber}}.
              </div>
              <ng-container
                *ngIf="j.invoices && j.invoices.length > 0 && (j.invoices[0].invoiceItems === undefined || j.invoices[0].invoiceItems.length > 0)">
                <ng-container *ngFor="let inv of j.invoices;let i = index;">
                  <app-fishing-invoice-table [invoice]="inv"
                    [job]="j"
                    (enableReorderEvent)="handleEnableReorderEvent($event)"></app-fishing-invoice-table>
                </ng-container>
              </ng-container>
              <div class="d-flex justify-content-end"
                *ngIf="j.totalStamp">
                <div class="card border-top-primary border-top-2 rounded-top-0 col-lg-3 col-md-5 col-sm-6"
                  style="border-top-color: #2196F3 !important;">
                  <div class="card-body">
                    <table style="float:right;">
                      <tr>
                        <td style="font-weight: 500;">Sub Total:</td>
                        <td width="20px">&nbsp;</td>
                        <td>{{j.subTotalStamp | currency}}</td>
                      </tr>
                      <tr>
                        <td style="font-weight: 500;">Tax&nbsp;<small>({{j.salesTaxRateStamp | percent:'0.2'}})</small>:
                        </td>
                        <td width="20px">&nbsp;</td>
                        <td>{{j.salesTaxTotalStamp | currency}}</td>
                      </tr>
                      <tr>
                        <td style="font-weight: 500;">TOTAL:</td>
                        <td width="20px">&nbsp;</td>
                        <td><strong>{{j.totalStamp | currency}}</strong></td>
                      </tr>
                    </table>
                  </div>
                </div>
              </div>
            </tab>
            <!--END INVOICE -->
          </tabset>
        </div>
      </div>
    </div>
  </div>
</div>
<div *ngIf="!jobService.job.value"
  class="page-content pt-0">
  <div class="content-wrapper">
    <div class="content">
      <div class="row">
        <div class="col-lg-3">
          <div class="card">
            <div class="card-header bg-light header-elements-inline">
              <h6 class="card-title">Invoice</h6>
              <div class="header-elements">
                <button class="btn btn-light btn-sm"
                  [disabled]="true"><i class="fa fa-edit"></i> Edit</button>
              </div>
            </div>
            <div class="card-body">
              <app-list-skeleton [numberToIterate]="11"></app-list-skeleton>
            </div>
          </div>
        </div>
        <div class="col-lg-9">
          <tabset [justified]="true"
            class="compact-nav">
            <tab heading="Tool Rental List"
              id="tab1"
              [disabled]="true">
              <app-table-skeleton [numberToIterate]="5"></app-table-skeleton>
            </tab>
            <tab heading="Invoice"
              id="tab2"
              [disabled]="true">
            </tab>
          </tabset>
        </div>
      </div>
    </div>
  </div>
</div>
