import { Component, OnDestroy, OnInit } from '@angular/core';
import { RowClassArgs } from '@progress/kendo-angular-grid';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { KGridService } from 'src/services/k-grid.service';
import { UIService } from 'src/services/ui.service';
import { WorksheetCategoryService } from 'src/services/worksheet-category.service';
import { AddEditWorksheetCategoryModalComponent } from '../add-edit-worksheet-category-modal/add-edit-worksheet-category-modal.component';

@Component({
  selector: 'app-worksheet-categories-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.css']
})

export class WorksheetCategoryListComponent implements OnInit, OnDestroy {

  public showInactive: boolean = false;

  constructor(
    private modalService: BsModalService,
    public kGridService: KGridService,
    public worksheetCategoryService: WorksheetCategoryService,
    public uiService: UIService,
  ) { };

  public bsModalRef: BsModalRef;
  private deleteSub: Subscription;

  ngOnDestroy(): void {
    this.kGridService.resetKGridParams();

    this.deleteSub?.unsubscribe();
  }

  ngOnInit(): void {
    this.worksheetCategoryService.getWorksheetCategories(
      this.showInactive,
      true,
      true);
  }

  public openWorksheetCategoryModal(worksheetCategoryId: number): void {
    const initialState = {
      id: worksheetCategoryId,
      showInactive: this.showInactive
    };

    this.bsModalRef = this.modalService.show(AddEditWorksheetCategoryModalComponent, { initialState, backdrop: 'static' });
  }

  public deleteWorksheetCategory(id: number): void {
    this.deleteSub = this.worksheetCategoryService.deleteWorksheetCategory(
      id,
      this.showInactive)
      .subscribe((x: boolean) => {
        if (x) {
          this.uiService.showSuccess('Success', 'Item Deleted');
        } else {
          this.uiService.showError('Error', 'Item Not Deleted');
        }
      });
  }

  public onChangeShowInactive($event: boolean): void {
    this.showInactive = $event;
    this.worksheetCategoryService.getWorksheetCategories(this.showInactive, true);
  }

  public rowCallback(context: RowClassArgs): any {
    return {
      inactive: context.dataItem.isActive === false
    };
  }

}
