import { Component } from '@angular/core';

@Component({
  selector: 'app-layout-root',
  templateUrl: './app-layout.component.html'
})

export class AppLayoutComponent {
  title = 'app';
}
