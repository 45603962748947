import { Component, OnDestroy, OnInit } from '@angular/core';
import { ExcelExportData } from '@progress/kendo-angular-excel-export';
import { RowClassArgs } from '@progress/kendo-angular-grid';
import { process } from '@progress/kendo-data-query';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { ItemDropdownModel } from 'src/models/item-dropdown.model';
import { ItemInstanceModel } from 'src/models/item-instance.model';
import { MaintenanceTypeModel } from 'src/models/maintenance-type.model';
import { AppStateService } from 'src/services/app-state.service';
import { ItemInstanceService } from 'src/services/item-instance.service';
import { ItemService } from 'src/services/item.service';
import { KGridService } from 'src/services/k-grid.service';
import { MaintenanceEventService } from 'src/services/maintenance-event.service';
import { MaintenanceTypeService } from 'src/services/maintenance-type.service';
import { UIService } from 'src/services/ui.service';
import { UserService } from 'src/services/user.service';
import { VendorService } from 'src/services/vendor.service';
import { AddEditMaintenanceLogModalComponent } from '../add-edit-maintenance-log-modal/add-edit-maintenance-log-modal.component';

@Component({
  selector: 'app-maintenance-log-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.css']
})

export class MaintenanceLogListComponent implements OnInit, OnDestroy {
  public showInactive: boolean = false;
  public showFilter = true;
  public bsModalRef: BsModalRef;
  public numberOfActiveFilterFields: number = 0;
  public seePrices: boolean = false;
  private deleteSub: Subscription;
  private saveSubscriptions = new Subscription();

  private maintenanceTypeSub: Subscription;
  public maintenanceTypes: MaintenanceTypeModel[];

  private isTabletSub: Subscription;
  public isTablet: boolean = false;

  private userSub: Subscription;

  constructor(
    private modalService: BsModalService,
    public kGridService: KGridService,
    public maintenanceEventService: MaintenanceEventService,
    public maintenanceTypeService: MaintenanceTypeService,
    public itemInstanceService: ItemInstanceService,
    public itemService: ItemService,
    public appStateService: AppStateService,
    public vendorService: VendorService,
    public uiService: UIService,
    public userService: UserService
  ) {
    this.allData = this.allData.bind(this);
  };

  public ngOnDestroy(): void {
    this.kGridService.resetKGridParams();

    this.deleteSub?.unsubscribe();

    this.isTabletSub?.unsubscribe();

    this.maintenanceTypeSub?.unsubscribe();

    this.saveSubscriptions?.unsubscribe();

    this.userSub?.unsubscribe();
  }

  ngOnInit(): void {
    this.determineIsTablet();

    this.canSeePrices();

    this.maintenanceEventService.getMaintenanceEvents(
      this.showInactive,
      true,
      true);

    this.vendorService.getVendors(false, false, false, true);

    this.itemInstanceService.getItemInstancesRepairDropdown();

    this.itemService.getItemsWithRepairLogsDropdown();

    this.getMaintenanceTypes();

  }

  public determineIsTablet(): void {
    this.isTabletSub = this.appStateService.isTablet$
      .subscribe((isTablet: boolean) => {
        this.isTablet = isTablet;
      });
  }

  public rowCallback(context: RowClassArgs): any {
    return {
      inactive: context.dataItem.isActive === false
    };
  }

  public getMaintenanceTypes(): void {
    this.maintenanceTypeSub = this.maintenanceTypeService.maintenanceTypeList$
      .subscribe((x: MaintenanceTypeModel[]) => {
        this.maintenanceTypes = x;
      });
  }

  public onChangeSerialNumberFilter($event: ItemInstanceModel): void {
    if ($event !== undefined) {
      this.maintenanceEventService.maintenanceEventFilterParams.itemId = $event.itemId;

      this.itemService.getItemsWithRepairLogsDropdown($event.itemInstanceId);
    } else {
      this.maintenanceEventService.maintenanceEventFilterParams.itemId = null;

      this.itemService.getItemsWithRepairLogsDropdown();
      this.itemInstanceService.getItemInstancesRepairDropdown();
    }

  }

  public onChangeItemFilter($event: ItemDropdownModel): void {
    this.maintenanceEventService.maintenanceEventFilterParams.itemInstanceId = null;

    if ($event !== undefined) {
      this.maintenanceEventService.maintenanceEventFilterParams.itemId = $event.itemId;

      this.itemInstanceService.getItemInstancesRepairDropdown($event.itemId);
    } else {
      this.maintenanceEventService.maintenanceEventFilterParams.itemId = null;

      this.itemService.getItemsWithRepairLogsDropdown();
      this.itemInstanceService.getItemInstancesRepairDropdown();
    }
  }

  public getNumberOfActiveFilterFields() {
    if (!this.showFilter) {
      this.numberOfActiveFilterFields = this.maintenanceEventService.getNumberOfActiveFilterFields();
    } else {
      this.numberOfActiveFilterFields = 0;
    }
  }

  public openMaintenanceEventModal(maintenanceEventId: number): void {
    const initialState = {
      id: maintenanceEventId,
      showInactive: this.showInactive,
      seePrices: this.seePrices
    };
    this.bsModalRef = this.modalService.show(AddEditMaintenanceLogModalComponent, { initialState, backdrop: 'static' });
  }

  public deleteMaintenanceEvent(
    $event: any,
    id: number): void {
    this.deleteSub = this.maintenanceEventService.deleteMaintenanceEvent(id, this.showInactive)
      .subscribe((x: boolean) => {
        if (x) {
          this.uiService.showSuccess('Success', 'Maintenance Log Deleted');
        } else {
          this.uiService.showError('Error', 'Maintenance Log Not Deleted');
        }
      });
  }

  public onClearFilters(): void {
    this.itemInstanceService.getItemInstancesRepairDropdown();

    this.itemService.getItemsWithRepairLogsDropdown();
  }

  public onChangeShowInactive($event): void {
    this.showInactive = $event;

    this.maintenanceEventService.getMaintenanceEvents(
      this.showInactive,
      true,
      false);
  }

  private canSeePrices(): void {
    this.userSub = this.userService.user.subscribe((user) => {
      if (user) {
        this.seePrices = user.activeRoles != null ?
          user.activeRoles.includes('General')
          : false;
      }
    });
  }

  public allData(): ExcelExportData {
    let data = this.maintenanceEventService.maintenanceEventList.value;

    const result: ExcelExportData = {
      data: process(data, { group: null, sort: null }).data,
      group: null
    };

    return result;
  }

}
