import { BatchChangeParamsModel } from 'src/models/RequestParams/batch-change-params.model';
import { BehaviorSubject, Observable, Observer } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { NewOrUsedStatuses } from 'src/enums/new-or-used-statuses';
import { NewOrUsedStatusModel } from 'src/models/new-or-used-status.model';
import { PurchaseOrderLineModel } from '../models/purchase-order-line.model';
import { PurchaseOrderLineReceivedModel } from '../models/purchase-order-line-received.model';
import { PurchaseOrderService } from './purchase-order.service';
import { PurchaseOrderStatuses } from '../enums/purchase-order-statuses';
import { tap } from 'rxjs/operators';
import { TimezoneService } from './timezone.service';

@Injectable()
export class PurchaseOrderLineService {
  constructor(
    private http: HttpClient,
    @Inject('BASE_URL') private baseUrl: string,
    private poService: PurchaseOrderService,
    private timezoneService: TimezoneService) { }

  public PurchaseOrderStatuses = PurchaseOrderStatuses;
  public NewOrUsedStatuses = NewOrUsedStatuses;
  public newOrUsedStatusList: BehaviorSubject<Array<NewOrUsedStatusModel>> = new BehaviorSubject(null);

  private apiUrl = 'api/PurchaseOrderLine';  // URL to web api

  public getPoLineById(id: number): Observable<PurchaseOrderLineModel> {
    return this.http.get<PurchaseOrderLineModel>(this.baseUrl + this.apiUrl + '/GetPoLineById/' + id)
      .pipe(tap((x: PurchaseOrderLineModel) => {
        x.accountingDate = x.accountingDate !== null ? new Date(x.accountingDate) : null;
        x.receivedDate = x.receivedDate !== null ? new Date(x.receivedDate) : null;
        x.received.forEach((y: PurchaseOrderLineReceivedModel) => {
          y.receivedDate = y.receivedDate != null ? new Date(y.receivedDate) : null;
          y.recDateString = y.receivedDate != null ? this.timezoneService.formatDateAsString(y.receivedDate) : null;
        });
      }));
  }

  public getPoLinesByVendorIdForMaintenanceDropdown(
    vendorId: number,
    maintenanceEventId: number): Observable<PurchaseOrderLineModel[]> {
    let query = '?';

    if (maintenanceEventId) {
      query += `maintenanceEventId=${maintenanceEventId}`;
    }

    return this.http.get<PurchaseOrderLineModel[]>(this.baseUrl + this.apiUrl + '/GetPoLinesByVendorIdForMaintenanceDropdown/' + vendorId + query)
      .pipe(tap((x: PurchaseOrderLineModel[]) => {
        x.forEach((y: PurchaseOrderLineModel) => {
          x.forEach((y: PurchaseOrderLineModel) => {
            y.dropDownDisplay = '';
            y.dropDownDisplay += y.poDate !== null ? `${new Date(y.poDate).toLocaleDateString()}` : '';
            y.dropDownDisplay += y.ponumberDisplay !== null ? ` - ${y.ponumberDisplay}` : '';
            y.dropDownDisplay += y.description !== null ? ` - ${y.description}` : '';
          });
        });
      }));
  }

  public addEditPoLine(dto: PurchaseOrderLineModel): Observable<boolean> {
    return new Observable((observer: Observer<boolean>) => {
      this.http.post<boolean>(this.baseUrl + this.apiUrl + '/AddEditPoLine', this.cleanPurchaseOrderLineModel(dto))
        .subscribe((x: boolean) => {
          if (x === true) {
            this.poService.refreshPurchaseOrder(dto.poid);
          };
          observer.next(x);
        });
    });
  }

  public deletePoLine(
    id: number,
    poid: number): Observable<boolean> {
    return new Observable((observer: Observer<boolean>) => {
      this.http.get<boolean>(this.baseUrl + this.apiUrl + '/DeletePoLine/' + id)
        .subscribe((x: boolean) => {
          if (x === true) {
            this.poService.refreshPurchaseOrder(poid);
          };
          observer.next(x);
        });
    });
  }

  public batchStatusChange(
    dto: BatchChangeParamsModel,
    poid: number): Observable<boolean> {
    return new Observable((observer: Observer<boolean>) => {
      this.http.post<boolean>(this.baseUrl + this.apiUrl + '/BatchStatusChange', dto)
        .subscribe((x: boolean) => {
          if (x) {
            this.poService.refreshPurchaseOrder(poid);
          };
          observer.next(x);
        });
    });
  }

  public getLastReceivedPoLinesByItem(
    itemId: number = null,
    itemInstanceId: number = null): Observable<Array<PurchaseOrderLineModel>> {
    let q: string = '?';
    q += itemId !== null ? `itemId=${itemId}` : '';
    q += itemInstanceId !== null ? `itemInstanceId=${itemInstanceId}` : '';

    return this.http.get<Array<PurchaseOrderLineModel>>(this.baseUrl + this.apiUrl + '/GetLastReceivedPoLinesByItem' + q);
  }

  public getNewOrUsedStatuses(showInactive: boolean): void {
    this.http.get<Array<NewOrUsedStatusModel>>(this.baseUrl + this.apiUrl + '/GetNewOrUsedStatuses/' + showInactive)
      .subscribe((x: NewOrUsedStatusModel[]) => {
        this.newOrUsedStatusList.next(x);
      });
  }

  // HELPER METHODS
  public generateTotalCostString(
    qty: number,
    price: number): string {
    if ((qty !== null && qty !== undefined) && (price !== null && price !== undefined)) {
      return (qty * price).toFixed(2);
    } else {
      return '0';
    }
  }


  public generateNewPoLineModel(
    poid: number,
    isInventoryItem: boolean): PurchaseOrderLineModel {
    let model = new PurchaseOrderLineModel();
    model.poid = poid;
    model.polineId = 0;
    model.postatusId = this.PurchaseOrderStatuses.Draft;
    model.accountingDate = null;
    model.isActive = true;
    model.received = [new PurchaseOrderLineReceivedModel()];
    model.newOrUsedStatusId = isInventoryItem ? this.NewOrUsedStatuses.New : null;

    return model;
  }

  private cleanPurchaseOrderLineModel(model: PurchaseOrderLineModel): PurchaseOrderLineModel {
    Object.keys(model).forEach((index: string) => {
      if (typeof model[index] === 'string') {
        model[index] = model[index]
          && model[index].trim() !== '' ?
          model[index].trim()
          : null;
      } else if (model[index] instanceof Date
        && !isNaN(model[index])) {
        model[index] = this.timezoneService.correctOffset(model[index]);
      }
    });

    model = this.cleanReceivedLineModels(model);

    return model;
  }

  private cleanReceivedLineModels(model: PurchaseOrderLineModel): PurchaseOrderLineModel {
    for (let i = model.received.length - 1; i >= 0; i--) {
      if (model.received[i].receivedDate === null
        && model.received[i].receivedPrice === null
        && model.received[i].receivedQuantity === null
        && model.received.length > 1) {
        model.received.splice(i);
      }
    }

    model.received.forEach((y: PurchaseOrderLineReceivedModel) => {
      y.receivedDate = this.timezoneService.correctOffset(y.receivedDate);
    });

    return model;
  }

}
