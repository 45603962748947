<div class="card rounded-top-0 mb-0">
  <div class="card-header bg-light header-elements-sm-inline pb-1 pt-1 pl-2">
    <h6 class="card-title">Vendor Items</h6>
    <div class="header-elements">
    </div>
  </div>
  <div class="card-body p-0"
    style="overflow: auto;">
    <div class="table-responsive"
      *ngIf="itemService.item | async; let model"
      style="min-width: 300px;">
      <div class="table-responsive"
        *ngIf="model.itemVendor && model.itemVendor.length === 0">
        <div class="alert alert-primary ml-3 mr-3 mt-3"
          role="alert">
          <i class="fad fa-check"></i>&nbsp;&nbsp;There are currently no <strong>Vendor Items.</strong>
        </div>
      </div>
      <kendo-grid *ngIf="model.itemVendor && model.itemVendor.length > 0"
        [kendoGridBinding]="model.itemVendor"
        filterable="false"
        [sortable]="true"
        [resizable]="true">
        <kendo-grid-column field="vendorName"
          title="Vendor"
          [width]="100">
        </kendo-grid-column>
        <kendo-grid-column field="partNumber"
          title="Part#"
          [width]="100">
        </kendo-grid-column>
        <kendo-grid-column field="description"
          title="Description"
          [width]="150">
        </kendo-grid-column>
        <kendo-grid-column field="cost"
          title="Cost"
          [width]="80">
          <ng-template kendoGridCellTemplate
            let-dataItem
            let-rowIndex="rowIndex">
            {{dataItem.cost | currency}}
          </ng-template>
        </kendo-grid-column>
      </kendo-grid>
    </div>
  </div>
</div>
