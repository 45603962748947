<div class="modal-header"
  ngxModalDraggable>
  <h4 class="modal-title pull-left">Rebuild<span *ngIf="model"><span
        *ngIf="!isNew">#&nbsp;{{model.worksheetId}}</span>&nbsp;Worksheet -
      {{model.description}}</span></h4>
  <button type="button"
    class="close pull-right"
    aria-label="Close"
    (click)="bsModalRef.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<app-table-skeleton [numberToIterate]="14"
  *ngIf="!model"></app-table-skeleton>
<div class="modal-body"
  *ngIf="model">
  <div class="row mb-3">
    <div [class.col-md-7]="model.hasImage && !isTablet"
      [class.col-md-8]="!model.hasImage && !isTablet"
      [class.offset-md-2]="!model.hasImage && !isTablet"
      [class.col-md-12]="isTablet">
      <div class="table-responsive">
        <table class="table table-condensed">
          <thead>
            <tr>
              <th>Section</th>
              <th>Item</th>
              <th width="80">Qty</th>
              <th *ngIf="isTablet"></th>
              <th *ngIf="model.showIsCustomerProperty">Cust Prop</th>
            </tr>
          </thead>
          <tbody>
            <ng-container *ngFor="let row of model.worksheetRowsCombined; let index =index; let isOdd=odd;">
              <tr [class.row-stripe]="!isOdd"
                *ngIf="row.hide !== true">
                <td>
                  {{row.name}}<span class="text-primary"
                    *ngIf="row.isNote"
                    title="Note">&nbsp;&nbsp;<i class="fal fa-sticky-note"></i></span>
                </td>
                <td>
                  <ng-select
                    [ngModel]="(row.worksheetRowItemsCombined | firstOrDefault: 'isSelected' : 1)?.worksheetRowItemTypeId"
                    name="items_{{row.worksheetRowTypeId}}"
                    (change)="changeSelection($event, row.worksheetRowTypeId)"
                    [clearable]="false"
                    [disabled]="jobStatusIsTerminal || row.worksheetRowItemsCombined.length <= 1"
                    [class.hide-arrow]="row.worksheetRowItemsCombined.length <= 1">
                    <ng-option *ngFor="let item of row.worksheetRowItemsCombined"
                      [value]="item.worksheetRowItemTypeId">{{item.name}}<span class="text-primary"
                        *ngIf="item.chargeItemizedOption"
                        title="charge">&nbsp;&nbsp;<i class="fas fa-dollar-sign"></i></span></ng-option>
                  </ng-select>
                </td>
                <td>
                  <input type="number"
                    style="width: 70px;"
                    class="form-control form-control-sm"
                    id="quantity_{{row.worksheetRowTypeId}}"
                    [(ngModel)]="row.quantity"
                    name="'quantity_{{row.worksheetRowTypeId}}"
                    [disabled]="jobStatusIsTerminal || row.isNewBuildBillingCode" />
                </td>
                <td *ngIf="isTablet"
                  class="pl-0">
                  <button class="btn btn-xs btn-light mr-1"
                    title="Increment"
                    (click)="handleIncrement(row)"
                    [disabled]="jobStatusIsTerminal || row.isNewBuildBillingCode"><i
                      class="fas fa-arrow-up"></i></button>
                  <button class="btn btn-xs btn-light"
                    title="Decrement"
                    (click)="handleDecrement(row)"
                    [disabled]="jobStatusIsTerminal || row.isNewBuildBillingCode"><i
                      class="fas fa-arrow-down"></i></button>
                </td>
                <td *ngIf="model.showIsCustomerProperty">
                  <ui-switch [(ngModel)]="row.isCustomerProperty"
                    [disabled]="jobStatusIsTerminal"></ui-switch>
                </td>
              </tr>
              <tr class="table-row-clear-top-border"
                [class.row-stripe]="!isOdd"
                *ngFor="let opt of row.worksheetRowOptionsCombined">
                <td></td>
                <td style="text-align: right;">{{opt.name}}</td>
                <td>
                  <ui-switch [(ngModel)]="opt.isSelected"
                    [disabled]="jobStatusIsTerminal"></ui-switch>
                </td>
                <td *ngIf="model.showIsCustomerProperty"></td>
              </tr>
            </ng-container>
          </tbody>
        </table>
        <div class="pl-2 mt-3">
          <div class="form-group row mb-2">
            <div class="col-sm-3 form-group mb-0">
              <label for="title">Labor Hours</label>
              <div class="input-group-append input-group-sm">
                <input type="number"
                  class="form-control form-control-sm"
                  id="laborHours"
                  [(ngModel)]="model.laborHours"
                  name="laborHours"
                  [disabled]="jobStatusIsTerminal"
                  required>
                <div class="input-group-append">
                  <span class="input-group-text">
                    hour(s)
                  </span>
                </div>
              </div>
              <span class="mt-1 text-danger d-inline-block"
                *ngIf="!(model.laborHours && model.laborHours > 0)"><strong>Labor Hours are required!</strong></span>
              <span class="mt-1 text-success d-inline-block"
                *ngIf="model.laborHours && model.laborHours > 0"><strong><i class="far fa-check-circle"></i>&nbsp;Labor
                  Hours</strong></span>
            </div>
            <div class="col-sm-4 form-group mb-0">
              <label for="title">Builder</label>
              <div class="col-sm-12 form-group mb-0 pl-0"
                *ngIf="employeeService.employeeList | async; let emp">
                <ng-select [items]="emp"
                  bindLabel="fullName"
                  bindValue="employeeId"
                  [(ngModel)]="model.employeeId"
                  name="employees"
                  style="width: 100%;"
                  appendTo="body"
                  required>
                </ng-select>
              </div>
              <span class="mt-1 text-danger d-inline-block"
                *ngIf="!model.employeeId"><strong>Builder is required!</strong></span>
              <span class="mt-1 text-success d-inline-block"
                *ngIf="model.employeeId"><strong><i class="far fa-check-circle"></i>&nbsp;Builder</strong></span>
            </div>
            <div class="col-sm-5 form-group mb-0">
              <label for="title">Customer</label>
              <div class="col-sm-12 form-group mb-0 pl-0"
                *ngIf="customerService.customerList | async; let cust">
                <ng-select [items]="cust"
                  bindLabel="name"
                  bindValue="customerId"
                  [(ngModel)]="model.customerId"
                  name="customers"
                  style="width: 100%;"
                  appendTo="body"
                  required>
                </ng-select>
              </div>
              <span class="mt-1 text-danger d-inline-block"
                *ngIf="!model.customerId"><strong>Customer is required!</strong></span>
              <span class="mt-1 text-success d-inline-block"
                *ngIf="model.customerId"><strong><i class="far fa-check-circle"></i>&nbsp;Customer</strong></span>
            </div>
          </div>
          <label for="title"
            class="control-label col-form-label">Notes</label>
          <textarea class="form-control form-control-sm"
            id="notes"
            [(ngModel)]="model.notes"
            name="notes"
            style="width: 100%;height: 80px;"
            [disabled]="jobStatusIsTerminal"></textarea>
        </div>
      </div>
    </div>
    <div class="col-md-5"
      *ngIf="!isTablet">
      <img src="/api/worksheetType/GetWorksheetTypeDetailsImage/{{model.worksheetTypeId}}/1" />
    </div>
  </div>
  <div class="modal-footer"
    *ngIf="true">
    <button type="button"
      class="btn btn-primary"
      (click)="onSave()"
      [disabled]="disableSave || jobStatusIsTerminal || !(model && model.employeeId && model.customerId && (model.laborHours && model.laborHours > 0))">Save</button>
    <button type="button"
      class="btn btn-default"
      (click)="bsModalRefChildModal.hide()">Cancel</button>
  </div>
