import { Component, OnDestroy, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { WorksheetCategoryModel } from 'src/models/worksheet-category.model';
import { WorksheetCombinedModel } from 'src/models/worksheet-combined.model';
import { WorksheetModel } from 'src/models/worksheet.model';
import { UIService } from 'src/services/ui.service';
import { WorksheetCategoryService } from 'src/services/worksheet-category.service';
import { WorksheetService } from 'src/services/worksheet.service';

@Component({
  selector: 'app-add-built-packer-modal',
  templateUrl: './add-built-packer-modal.component.html',
  styleUrls: ['./add-built-packer-modal.component.css']
})

export class AddBuiltPackerModalComponent implements OnInit, OnDestroy {
  public jobId: number = -1;
  public customerId: number = null;
  public worksheetCategoryId: number = null;
  public worksheetId: number = null;
  public disableSave = false;
  public isLoading: boolean = false;

  private modelSub: Subscription;
  public model: WorksheetModel[];

  private chosenWsSub: Subscription;
  public chosenWs: WorksheetCombinedModel = null;

  private saveSub: Subscription;

  constructor(
    public bsModalRef: BsModalRef,
    public worksheetService: WorksheetService,
    public worksheetCategoryService: WorksheetCategoryService,
    public uiService: UIService

  ) { };

  ngOnDestroy(): void {
    this.modelSub?.unsubscribe();

    this.chosenWsSub?.unsubscribe();

    this.saveSub?.unsubscribe();
  }

  ngOnInit(): void {
    this.getModel();
    this.worksheetCategoryService.getWorksheetCategories(false);
  }

  public getModel(): void {
    this.modelSub = this.worksheetService.getPackerList(
      false,
      true,
      this.customerId,
      this.worksheetCategoryId)
      .subscribe((x: WorksheetModel[]) => {
        this.model = x;
      });
  }

  public onChangeOfWorksheetCategoryType($event: WorksheetCategoryModel) {
    if ($event !== undefined) {
      this.worksheetCategoryId = $event === null ? null : $event.worksheetCategoryId;

      this.chosenWs = null;
      this.worksheetId = null;

      this.getModel();
    }
  }

  public getWorksheetModel(worksheetId: number) {
    this.chosenWs = null;

    if (worksheetId !== null) {
      this.isLoading = true;

      this.chosenWsSub = this.worksheetService.getWorksheetCombinedById(worksheetId)
        .subscribe((x: WorksheetCombinedModel) => {
          this.isLoading = false;
          this.chosenWs = x;
        });
    } else {
      this.isLoading = false;
    }
  }

  public onSave(): void {
    this.uiService.showToastSaving();

    this.disableSave = true;

    this.chosenWs.jobId = this.jobId;

    this.saveSub = this.worksheetService.editWorksheetCombined(
      this.chosenWs,
      false)
      .subscribe((x: boolean) => {
        if (x) {
          this.bsModalRef.hide();
          this.uiService.killAllToastMessages();
          this.uiService.showSuccess('Packer Added', 'Success');
        } else {
          this.disableSave = false;
          this.uiService.killAllToastMessages();
          this.uiService.showError('Packer Not Added', 'Error');
        }
      });
  }
}
