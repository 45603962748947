import { Component, OnDestroy, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { ShopModel } from 'src/models/shop.model';
import { WarehouseModel } from 'src/models/warehouse.model';
import { ItemService } from 'src/services/item.service';
import { ShopService } from 'src/services/shop.service';
import { UIService } from 'src/services/ui.service';
import { WarehouseService } from 'src/services/warehouse.service';

@Component({
  selector: 'app-add-edit-warehouse-modal',
  templateUrl: './add-edit-warehouse-modal.component.html',
  styleUrls: ['./add-edit-warehouse-modal.component.css']
})

export class AddEditWarehouseModalComponent implements OnInit, OnDestroy {
  public isNew: boolean = false;
  public id: number = -1;
  public model: WarehouseModel;
  public shops: ShopModel[];

  private shopsSub: Subscription;
  private modelSub: Subscription;
  private saveSub: Subscription;

  constructor(
    public bsModalRef: BsModalRef,
    public whService: WarehouseService,
    public uiService: UIService,
    public shopService: ShopService,
    public itemService: ItemService

  ) { };

  ngOnDestroy(): void {
    this.shopsSub?.unsubscribe();

    this.modelSub?.unsubscribe();

    this.saveSub?.unsubscribe();
  }

  ngOnInit(): void {
    this.isNew = this.id === 0;

    this.getShops();

    if (this.isNew) {
      this.model = this.whService.generateNewWarehouseModel();
    } else {
      this.getModel();
    }
  }

  public getModel(): void {
    this.modelSub = this.whService.getWarehouseById(this.id)
      .subscribe((x: WarehouseModel) => {
        this.model = x;
      });
  }

  public getShops(): void {
    this.shopsSub = this.shopService.shopList$
      .subscribe((shops: ShopModel[]) => {
        if (shops) {
          this.shops = shops;
        }
      });
  }

  public onSave(): void {
    this.saveSub = this.whService.addEditWarehouse(this.model)
      .subscribe((x: boolean) => {
        if (x) {
          this.bsModalRef.hide();
          this.uiService.showSuccess('Warehouse Saved', 'Success');
        } else {
          this.uiService.showError('Warehouse Saved', 'Error');
        }
      });
  }

}
