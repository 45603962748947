export class BhalineModel {

  constructor(bhaId:number, rowNumber: number, isActive: boolean = true){
    this.bhaId = bhaId;
    this.rowNumber = rowNumber;
    this.isActive = isActive;
    this.length = null;
  }

  bhaLineId: number;
  bhaId: number;
  jobItemInstanceId: number | null;
  rowNumber: number | null;
  outerDiameter: number | null;
  description: string;
  serialNumber: string;
  innerDiameter: number | null;
  fnOd: number | null;
  fnLength: number | null;
  length: number | null;
  notes: string;
  isActive: boolean | null;

  isItemInstance: boolean;
  accumLength: number = 0;
}
