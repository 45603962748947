import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { JobSubStatuses } from 'src/enums/job-sub-statuses';
import { JobTypes } from 'src/enums/job-types';
import { AppStateService } from 'src/services/app-state.service';
import { InvoiceItemService } from 'src/services/invoice-item.service';
import { JobService } from 'src/services/job.service';
import { TimezoneService } from 'src/services/timezone.service';
import { UIService } from 'src/services/ui.service';
import { UserService } from 'src/services/user.service';
import { JobStatuses } from '../../../../enums/job-statuses';
import { JobModel } from '../../../../models/job.model';
import { CancelJobConfirmComponent } from '../cancel-job-confirm-modal/cancel-job-confirm-modal.component';

@Component({
  selector: 'app-status-button-fishing',
  templateUrl: './status-button-fishing.component.html',
  styleUrls: ['./status-button-fishing.component.css']
})

export class StatusButtonFishingComponent implements OnInit, OnDestroy {
  @Input() job: JobModel;
  @Input() enableReorder: boolean = false;
  public JobStatuses = JobStatuses;
  public JobSubStatuses = JobSubStatuses;
  public JobTypes = JobTypes;
  public bsModalRef: BsModalRef;
  public cancellationId: number = null;
  public closeId: number = null;
  public openId: number = null;
  public editId: number = null;
  public canApproveInvoices: boolean = false;

  private isTabletSub: Subscription;
  public isTablet: boolean = false;

  private changeJobStatusSub: Subscription;
  private changeJobSubStatusSub: Subscription;
  private splitInvoiceSub: Subscription;

  constructor(
    public uiService: UIService,
    public userService: UserService,
    public jobService: JobService,
    public appStateService: AppStateService,
    public timeZoneService: TimezoneService,
    public invoiceItemService: InvoiceItemService,
    private modalService: BsModalService,
  ) { };

  ngOnDestroy(): void {
    this.isTabletSub?.unsubscribe();

    this.splitInvoiceSub?.unsubscribe();
  }

  ngOnInit(): void {
    this.determineIsTablet();
    this.determineIfUserCanApprove();
    this.setCancellationId();
    this.setCloseId();
    this.setOpenId();
    this.setEditInvoiceId();
  }

  public determineIfUserCanApprove(): void {
    this.canApproveInvoices = this.userService.user.value ?
      this.userService.user.value.activeRoles.includes('Approve Invoices')
      && (this.userService.user.value.invoiceApprovalLimit === null
        || (this.userService.user.value.invoiceApprovalLimit !== null
          && this.userService.user.value.invoiceApprovalLimit > this.job.totalStamp))
      : false;
  }

  public determineIsTablet(): void {
    this.isTabletSub = this.appStateService.isTablet$
      .subscribe((isTablet: boolean) => {
        this.isTablet = isTablet;
      });
  }

  public setCancellationId(): void {
    this.cancellationId = this.jobService.setCancellationId(this.job.jobTypeId);
  }

  public setCloseId(): void {
    this.closeId = this.jobService.setCloseId(this.job.jobTypeId);
  }

  public setOpenId(): void {
    this.openId = this.jobService.setOpenId(this.job.jobTypeId);
  }

  public setEditInvoiceId(): void {
    this.editId = this.jobService.setEditInvoiceId(this.job.jobTypeId);
  }

  public changeJobSubStatus(subStatusId: number): void {
    if (this.jobService.job.value !== null) {
      this.jobService.job.value.jobSubStatusId = subStatusId;

      this.changeJobSubStatusSub = this.jobService.addEditJob(this.jobService.job.value, false, true)
        .subscribe((x: boolean) => {
          // This is here because the jobSubStatusSub can not be unsubscribed from ngOnDestroy.
          this.changeJobSubStatusSub?.unsubscribe();

          if (x === true) {
            this.uiService.showSuccess('Job Status Changed', 'Success');
          } else {
            this.uiService.showError('Job Status Not Changed', 'Error');
          }
        });
    } else {
      this.uiService.showError('Job Status Not Changed', 'Error');
    }
  }

  public changeJobStatus(
    statusId: number,
    split: boolean = false): void {
    if (this.jobService.job.value !== null) {
      this.jobService.job.value.jobStatusId = statusId;

      this.changeJobStatusSub = this.jobService.addEditJob(this.jobService.job.value, false, true)
        .subscribe((x: boolean) => {
          // This is here because the changeJobStatusSub can not be unsubscribed from ngOnDestroy.
          this.changeJobStatusSub?.unsubscribe();

          if (x === true) {
            if (!split) {
              this.uiService.showSuccess('Job Status Changed', 'Success');
            } else {
              this.uiService.showSuccess('Invoice Split & Job Status Changed', 'Success');
            }
          }
        });
    } else {
      this.uiService.showError('Job Status Not Changed', 'Error');
    }
  }

  public openCancelJobConfirmModal(
    job: JobModel,
    cancellationStatusId: number): void {
    const initialState = {
      statusId: cancellationStatusId,
      model: job
    };

    this.bsModalRef = this.modalService.show(CancelJobConfirmComponent, { initialState, backdrop: 'static' });
  }

  public splitInvoice(): void {
    let jobId = this.jobService.job != null ? this.jobService.job.value.jobId : null;

    if (jobId !== null) {
      this.splitInvoiceSub = this.invoiceItemService.generateFishingJobInvoice(jobId, true)
        .subscribe((x: boolean) => {
          if (x === true) {
            this.changeJobStatus(this.JobStatuses.EditInvoice_Fishing, true); // SPLIT
          } else {
            this.uiService.showError('Invoice NOT Split', 'Error');
          }
        });
    }
  }

}
