<div style="text-align:center"
  *ngIf="!combinedStatusHistory">
  <span style="font-size: 50px">
    <i class="fad fa-spinner fa-spin"></i>
  </span>
</div>
<table class="table table-striped"
  *ngIf="combinedStatusHistory">
  <tbody>
    <tr>
      <th class="p-1">Status</th>
      <th class="p-1">User</th>
      <th class="p-1">Date</th>
    </tr>
    <tr *ngFor="let h of combinedStatusHistory">
      <td class="p-1">{{h.statusName}}</td>
      <td class="p-1">{{h.userName}}</td>
      <td class="p-1">{{h.effectiveDate | date:'shortDate'}}</td>
    </tr>
  </tbody>
</table>
