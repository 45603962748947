import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { BatchChangeParamsModel } from 'src/models/RequestParams/batch-change-params.model';
import { PurchaseOrderModel } from 'src/models/purchase-order.model';
import { AppStateService } from 'src/services/app-state.service';
import { PurchaseOrderLineService } from 'src/services/purchase-order-line.service';
import { PurchaseOrderService } from 'src/services/purchase-order.service';
import { ReportService } from 'src/services/report.service';
import { UIService } from 'src/services/ui.service';
import { UserService } from 'src/services/user.service';
import { JobTypes } from '../../../enums/job-types';
import { PurchaseOrderStatuses } from '../../../enums/purchase-order-statuses';
import { AddEditPoLineModalComponent } from '../add-edit-po-line-modal/add-edit-po-line-modal.component';
import { AddEditPoModalComponent } from '../add-edit-po-modal/add-edit-po-modal.component';
import { UserModel } from 'src/models/user.model';

@Component({
  selector: 'app-purchase-orders-details',
  templateUrl: './details.component.html',
  styleUrls: ['details.component.css']
})

export class PurchaseOrderDetailsComponent implements OnInit, OnDestroy {
  public cachedPo: PurchaseOrderModel = null;
  public poId: number = 1;
  public managePo: boolean = false;
  public showDetails = true;
  public JobTypes = JobTypes;
  public PurchaseOrderStatuses = PurchaseOrderStatuses;
  public infoIsCollapsed = false;

  private isTabletSub: Subscription;
  public isTablet: boolean = false;

  private openInventoryLineSub: Subscription;
  private openMiscLineSub: Subscription;
  private routeParamsSub: Subscription;
  private userSub: Subscription;
  private batchStatusChangeSub: Subscription;
  private deleteSub: Subscription;

  constructor(
    private modalService: BsModalService,
    private activatedRoute: ActivatedRoute,
    public poService: PurchaseOrderService,
    public lineService: PurchaseOrderLineService,
    public uiService: UIService,
    public userService: UserService,
    public appStateService: AppStateService,
    public reportService: ReportService
  ) { };

  public bsModalRef: BsModalRef;

  ngOnDestroy(): void {
    this.batchStatusChangeSub?.unsubscribe();

    this.deleteSub?.unsubscribe();

    this.isTabletSub?.unsubscribe();

    this.openInventoryLineSub?.unsubscribe();

    this.openMiscLineSub?.unsubscribe();

    this.routeParamsSub?.unsubscribe();

    this.userSub?.unsubscribe();
  }

  ngOnInit(): void {
    this.subscribeToRouteParams();
    this.subscribeToOpenLines();
    this.subscribeToUser();

    this.determineIsTablet();
  }

  public determineIsTablet(): void {
    this.isTabletSub = this.appStateService.isTablet$
      .subscribe((isTablet: boolean) => {
        this.isTablet = isTablet;
      });
  }

  public subscribeToRouteParams(): void {
    this.routeParamsSub = this.activatedRoute.params
      .subscribe((x: Params) => {
        this.poId = parseInt(this.activatedRoute.snapshot.params['id']);
        this.poService.refreshPurchaseOrder(this.poId);

        this.userService.refreshUser();
      });
  }

  public subscribeToUser(): void {
    this.userSub = this.userService.user
      .subscribe((x: UserModel) => {
        if (x !== null) {
          this.managePo = x.activeRoles.includes('Manage PO');
        }
      });
  }

  public subscribeToOpenLines(): void {
    this.openInventoryLineSub = this.poService.openInventoryLine
      .subscribe((x: [boolean, PurchaseOrderModel]) => {
        if (x[0] === true && this.poService.purchaseOrder.value !== null) {
          this.cachedPo = x[1];

          this.openPoLineModal(
            0,
            true,
            true);

          this.poService.openInventoryLine.next([false, null]);
        }
      });

    this.openMiscLineSub = this.poService.openMiscLine
      .subscribe((x: [boolean, PurchaseOrderModel]) => {
        if (x[0] === true && this.poService.purchaseOrder.value !== null) {
          this.cachedPo = x[1];
          this.openPoLineModal(0, false, true);
          this.poService.openMiscLine.next([false, null]);
        }
      });

  }

  public openPoModal(poid: number): void {
    const initialState = {
      id: poid
    };

    this.bsModalRef = this.modalService.show(AddEditPoModalComponent, { initialState, backdrop: 'static' });
  }

  public openPoLineModal(
    lineId: number,
    isInventoryItem: boolean = null,
    useCachedPo: boolean = false): void {
    let poDisplay = 'PO: ';

    if (!useCachedPo && (this.poService.purchaseOrder != null || this.poService.purchaseOrder !== undefined)) {
      poDisplay += `${this.poService.purchaseOrder.value.ponumber} - ${this.poService.purchaseOrder.value.vendorName}`;
    } else if (useCachedPo && this.cachedPo !== undefined && this.cachedPo !== null) {
      poDisplay += `${this.cachedPo.ponumber} - ${this.cachedPo.vendorName}`;
    }

    const initialState = {
      poid: !useCachedPo ? this.poService.purchaseOrder.value.poid : this.cachedPo.poid,
      poDisplay: poDisplay,
      id: lineId,
      isInventoryItem: isInventoryItem,
      vendorId: !useCachedPo ?
        (this.poService.purchaseOrder !== null && this.poService.purchaseOrder !== undefined ? this.poService.purchaseOrder.value.vendorId : -1)
        : this.cachedPo.vendorId
    };

    this.bsModalRef = this.modalService.show(AddEditPoLineModalComponent, { initialState, class: 'modal-lg', backdrop: 'static' });
  }

  public batchStatusChange(status: number): void {
    let dto: BatchChangeParamsModel = new BatchChangeParamsModel();
    dto.id = status;
    dto.batchIds = this.getLineIds();

    this.batchStatusChangeSub = this.lineService.batchStatusChange(dto, this.poService.purchaseOrder.value.poid)
      .subscribe((x: boolean) => {
        if (x) {
          this.uiService.showSuccess('Success', 'PO Status Updated');
        } else {
          this.uiService.showError('Error', 'PO Status Not Updated');
        }
      });
  }

  public getLineIds(): Array<number> {
    let lineIds: Array<number> = [];
    this.poService.purchaseOrder.value.poLines.forEach(line => {
      lineIds.push(line.polineId);
    });
    return lineIds;
  }

  public deletePoLine(id: number) {
    this.deleteSub = this.lineService.deletePoLine(id, this.poService.purchaseOrder.value.poid)
      .subscribe((x: boolean) => {
        if (x) {
          this.uiService.showSuccess('Success', 'PO Line Deleted');
        } else {
          this.uiService.showError('Error', 'PO Line Not Deleted');
        }
      });
  }

}
