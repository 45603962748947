import { Component, OnDestroy, OnInit } from '@angular/core';
import { RowClassArgs } from '@progress/kendo-angular-grid';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { JobTypes } from 'src/enums/job-types';
import { JobSubStatusModel } from 'src/models/job-sub-status.model';
import { AppStateService } from 'src/services/app-state.service';
import { JobSubStatusService } from 'src/services/job-sub-status.service';
import { JobService } from 'src/services/job.service';
import { PurchaseOrderService } from 'src/services/purchase-order.service';
import { UserService } from 'src/services/user.service';
import { UIService } from '../../services/ui.service';
import { AddEditJobSalesModalComponent } from '../jobs/shared/add-edit-job-sales-modal/add-edit-job-sales-modal.component';
import { AddEditPoModalComponent } from '../purchase-orders/add-edit-po-modal/add-edit-po-modal.component';
import { ChooseWorksheetModalComponent } from '../worksheets/choose-worksheet-modal/choose-worksheet-modal.component';
import { UserModel } from 'src/models/user.model';
import { InventoryHistoryService } from 'src/services/inventory-history.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})

export class HomeComponent implements OnInit, OnDestroy {
  private jobSubStatusSub: Subscription;
  public jobSubStatuses: JobSubStatusModel[];

  private isTabletSub: Subscription;
  public isTablet: boolean = false;

  private poSub: Subscription;
  private userSub: Subscription;

  constructor(
    private modalService: BsModalService,
    public userService: UserService,
    public poService: PurchaseOrderService,
    public jobService: JobService,
    public appStateService: AppStateService,
    public uiService: UIService,
    public subStatusService: JobSubStatusService,
    public invHistService: InventoryHistoryService
  ) {
  };

  public bsModalRef: BsModalRef;
  public JobTypes = JobTypes;
  public showNotReadyToInvoice = false;
  public isHand: boolean = false;
  public showFilter = true;
  public numberOfActiveFilterFields: number = 0;
  public openPosAreLoaded: boolean = false;

  ngOnDestroy(): void {
    this.jobSubStatusSub?.unsubscribe();

    this.isTabletSub?.unsubscribe();

    this.poSub?.unsubscribe();

    this.userSub?.unsubscribe();
  }

  ngOnInit(): void {
    this.subToUser();

    this.getOpenPurchaseOrders();

    this.poService.getPendingPurchaseOrders();

    this.getJobSubStatuses();

    this.determineIsTablet();
  }

  public determineIsTablet(): void {
    this.isTabletSub = this.appStateService.isTablet$
      .subscribe((isTablet: boolean) => {
        this.isTablet = isTablet;
      });
  }

  public getJobSubStatuses(): void {
    this.jobSubStatusSub = this.subStatusService.jobSubStatusList$
      .subscribe((jobSubStatuses: JobSubStatusModel[]) => {
        if (jobSubStatuses) {
          this.jobSubStatuses = jobSubStatuses;
        }
      });
  }

  public getOpenPurchaseOrders(): void {
    this.poSub = this.poService.getOpenPurchaseOrders()
      .subscribe((x: boolean) => {
        this.openPosAreLoaded = true;
      });
  }

  public createPoModal(): void {
    const initialState = {
      id: 0,
    };
    this.bsModalRef = this.modalService.show(AddEditPoModalComponent, { initialState, backdrop: 'static' });
  }

  public subToUser(): void {
    this.userSub = this.userService.user
      .subscribe((x: UserModel) => {
        if (x !== null) {
          this.isHand = this.userService.user.value
            && this.userService.user.value.activeRoles ?
            (this.userService.user.value.activeRoles.includes('Packer Hand')
              || this.userService.user.value.activeRoles.includes('Fishing Hand')
              || !this.userService.user.value.activeRoles.includes('General'))
            : false;

          this.showNotReadyToInvoice = this.isHand ? true : this.showNotReadyToInvoice;

          if (this.userService.openJobUserSubStatuses) {
            this.jobService.getOpenJobs(this.showNotReadyToInvoice, this.userService.openJobUserSubStatuses, true);
          }
        }
      });
  }

  public onChangeNotReadyToInvoice($event: boolean): void {
    this.showNotReadyToInvoice = $event;
    this.jobService.getOpenJobs(this.showNotReadyToInvoice);
  }

  public onChangeOfSubStatusMulti(): void {
    this.jobService.getOpenJobs(this.showNotReadyToInvoice);
  }

  public openJobModal(
    jobId: number,
    jobTypeId: number,
    isQuote: boolean = false): void {
    const initialState = {
      id: jobId,
      jobTypeId: jobTypeId,
      isQuote: isQuote
    };

    this.bsModalRef = this.modalService.show(AddEditJobSalesModalComponent, { initialState, class: 'modal-lg modal-xl', backdrop: 'static' });
  }

  public openChooseWorksheetModal(): void {
    const modalSize = this.isTablet ? 'modal-xl' : 'modal-lg';

    const initialState = {
      jobId: null,
      packersOnly: true
    };
    this.bsModalRef = this.modalService.show(ChooseWorksheetModalComponent, { initialState, class: modalSize, backdrop: 'static' });
  }

  public rowCallback(context: RowClassArgs): any {
    return {
      notReady: context.dataItem.notReadyToInvoice
    };
  }

  public rowCallbackClear(context: RowClassArgs): any {

  }

  public getNumberOfActiveFilterFields(): void {
    if (!this.showFilter) {
      this.numberOfActiveFilterFields = this.jobService.getNumberOfActiveFilterFields();
    } else {
      this.numberOfActiveFilterFields = 0;
    }
  }

  // PLEASE LEAVE THIS HERE FOR PRICE CHANGES
  // public recalculateJobTotals(): void {
  //   this.jobService.recalculateJobTotals()
  //     .pipe(takeUntil(this.ngUnsubscribe))
  //     .subscribe((x: boolean) => {
  //       if (x) {
  //         this.uiService.showSuccess('Jobs Recalculated', 'Success');
  //       } else {
  //         this.uiService.showError('Jobs NOT Recalculated', 'Error');
  //       }
  //     });
  // }

  // public zeroOutDicksonInventory(): void {
  //   this.invHistService.zeroOutDicksonInventory()
  //     .pipe(takeUntil(this.ngUnsubscribe))
  //     .subscribe((x: boolean) => {
  //       if (x) {
  //         this.uiService.showSuccess('Dickson Inventory Zeroed Out', 'Success');
  //       } else {
  //         this.uiService.showError('Dickson Inventory NOT Zeroed Out', 'Error');
  //       }
  //     });
  // }

}
