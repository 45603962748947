import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { InvoiceItemTypes } from '../enums/invoice-item-types';
import { JobSalesItemModel } from 'src/models/job-sales-item.model';
import { JobService } from './job.service';
import { Observable, Observer } from 'rxjs';

@Injectable()
export class InvoiceItemService {
  constructor(
    private http: HttpClient,
    @Inject('BASE_URL') private baseUrl: string,
    private jobService: JobService
  ) { }

  public InvoiceItemTypes = InvoiceItemTypes;

  private apiUrl = 'api/InvoiceItem';  // URL to web api

  public addEditJobSalesItem(dto: JobSalesItemModel): Observable<boolean> {
    return new Observable((observer: Observer<boolean>) => {
      this.http.post<boolean>(this.baseUrl + this.apiUrl + '/AddEditJobSalesItem', dto)
        .subscribe((x: boolean) => {
          if (x === true) {
            this.jobService.refreshJob(dto.jobId);
          }
          observer.next(x);
        });
    });
  }

  public getJobSalesItemByInvoiceItemId(id: number): Observable<JobSalesItemModel> {
    return this.http.get<JobSalesItemModel>(this.baseUrl + this.apiUrl + '/GetJobSalesItemByInvoiceItemId/' + id);
  }

  public deleteJobSalesItemByInvoiceItemId(dto: JobSalesItemModel): Observable<boolean> {
    return new Observable((observer: Observer<boolean>) => {
      this.http.get<boolean>(this.baseUrl + this.apiUrl + '/DeleteJobSalesItemByInvoiceItemId/' + dto.invoiceItemId)
        .subscribe((x: boolean) => {
          if (x === true) {
            this.jobService.refreshJob(dto.jobId);
          }
          observer.next(x);
        });
    });
  }

  public generateFishingJobInvoice(
    jobId: number,
    splitInvoice: boolean): Observable<boolean> {
    return new Observable((observer: Observer<boolean>) => {
      this.http.get<boolean>(this.baseUrl + this.apiUrl + '/GenerateFishingJobInvoice/' + jobId + '/' + splitInvoice)
        .subscribe((x: boolean) => {
          if (x === true) {
            this.jobService.refreshJob(jobId);
          }
          observer.next(x);
        });
    });
  }

  public generateFishingToolRentalInvoice(
    jobId: number,
    splitInvoice: boolean): Observable<boolean> {
    return new Observable((observer: Observer<boolean>) => {
      this.http.get<boolean>(this.baseUrl + this.apiUrl + '/GenerateFishingToolRentalInvoice/' + jobId + '/' + splitInvoice)
        .subscribe((x: boolean) => {
          if (x === true) {
            this.jobService.refreshJob(jobId);
          }
          observer.next(x);
        });
    });
  }

  public deleteFishingInvoiceItem(dto: JobSalesItemModel): Observable<boolean> {
    return new Observable((observer: Observer<boolean>) => {
      this.http.get<boolean>(this.baseUrl + this.apiUrl + '/DeleteFishingInvoiceItem/' + dto.invoiceItemId)
        .subscribe((x: boolean) => {
          if (x === true) {
            this.jobService.refreshJob(dto.jobId);
          }
          observer.next(x);
        });
    });
  }

  public createFishingInvoiceLines(jobId: number): Observable<boolean> {
    return this.http.get<boolean>(this.baseUrl + this.apiUrl + '/CreateFishingInvoiceLines/' + jobId);
  }

  public addEditFishingInvoiceLine(dto: JobSalesItemModel): Observable<boolean> {
    return new Observable((observer: Observer<boolean>) => {
      this.http.post<boolean>(this.baseUrl + this.apiUrl + '/AddEditFishingInvoiceLine', dto)
        .subscribe((x: boolean) => {
          if (x === true) {
            this.jobService.refreshJob(dto.jobId);
          }
          observer.next(x);
        });
    });
  }

  public generateNewInvoiceNote(
    jobId: number,
    invoiceId: number = null): JobSalesItemModel {
    let model: JobSalesItemModel = new JobSalesItemModel();
    model.invoiceItemId = 0;
    model.invoiceId = invoiceId;
    model.invoiceItemTypeId = this.InvoiceItemTypes.Note;
    model.isForResale = false;
    model.isActive = true;
    model.jobId = jobId;
    model.quantity = 0;
    model.price = 0;

    return model;
  }

}
