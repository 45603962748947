import { BehaviorSubject, Observable } from 'rxjs';
import { CountyModel } from '../models/county.model';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';

@Injectable()
export class CountyService {
  constructor(
    private http: HttpClient,
    @Inject('BASE_URL') private baseUrl: string
  ) { }

  private apiUrl = 'api/County';  // URL to web api

  private countyListSubject = new BehaviorSubject<CountyModel[]>(null);
  public _countyList$ = this.countyListSubject.asObservable();
  private isCountyCallInProgress = false;

  public get countyList$(): Observable<CountyModel[]> {
    if (!this.countyListSubject.getValue() && !this.isCountyCallInProgress) {
      this.isCountyCallInProgress = true;
      this.getCounties$(false).subscribe(() => this.isCountyCallInProgress = false);
    }

    return this._countyList$;
  }

  private getCounties$(showInactive: boolean): Observable<CountyModel[]> {
    return this.http.get<Array<CountyModel>>(this.baseUrl + this.apiUrl + '/GetCounties/' + showInactive)
      .pipe(
        tap((counties: CountyModel[]) => {
          this.countyListSubject.next(counties);
        })
      );
  }

  public getCountiesForAreaDropdown(showInactive: boolean, customerId: number, customerAreaId: number | null = null): Observable<CountyModel[]> {
    const queryStr: string = customerAreaId ? `?customerAreaId=${customerAreaId}` : '';

    return this.http.get<Array<CountyModel>>(this.baseUrl + this.apiUrl + '/GetCountiesForAreaDropdown/' + showInactive + '/' + customerId + queryStr);
  }

}
