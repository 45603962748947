import { Component, OnDestroy, OnInit } from '@angular/core';
import { RowClassArgs } from '@progress/kendo-angular-grid';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { UnitOfMeasureModel } from 'src/models/unit-of-measure.model';
import { AppStateService } from 'src/services/app-state.service';
import { BillingCodeService } from 'src/services/billing-code.service';
import { CustomerService } from 'src/services/customer.service';
import { ItemService } from 'src/services/item.service';
import { KGridService } from 'src/services/k-grid.service';
import { PartFamilyService } from 'src/services/part-family.service';
import { PartGroupService } from 'src/services/part-group.service';
import { UIService } from 'src/services/ui.service';
import { UnitOfMeasureService } from 'src/services/unit-of-measure.service';
import { AddEditBillingCodeModalComponent } from '../add-edit-billing-code-modal/add-edit-billing-code-modal.component';
import { PartFamilyModel } from 'src/models/part-family.model';

@Component({
  selector: 'app-billing-codes-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.css']
})

export class BillingCodeListComponent implements OnInit, OnDestroy {
  private unitsOfMeasureSub: Subscription;
  public unitsOfMeasure: UnitOfMeasureModel[];

  private isTabletSub: Subscription;
  public isTablet: boolean = false;

  private deleteSub: Subscription;

  public showInactive: boolean = false;
  public showFilter = true;
  public numberOfActiveFilterFields: number = 0;

  constructor(
    private modalService: BsModalService,
    public itemService: ItemService,
    public kGridService: KGridService,
    public customerService: CustomerService,
    public uomService: UnitOfMeasureService,
    public billingCodeService: BillingCodeService,
    public partGroupService: PartGroupService,
    public partFamilyService: PartFamilyService,
    public appStateService: AppStateService,
    public uiService: UIService
  ) { };

  public bsModalRef: BsModalRef;

  ngOnDestroy(): void {
    this.kGridService.resetKGridParams();

    this.deleteSub?.unsubscribe();

    this.unitsOfMeasureSub?.unsubscribe();

    this.isTabletSub?.unsubscribe();
  }

  ngOnInit() {
    this.determineIsTablet();

    this.billingCodeService.getBillingCodes(
      this.showInactive,
      true,
      true,
      false,
      true);

    this.itemService.getItemsDropdown(
      false,
      true,
      true,
      false);

    this.customerService.getCustomers(false);

    this.getUnitsOfMeasure();

    this.partFamilyService.refreshPartFamilyList(
      false,
      true,
      false);

    this.partGroupService.partGroupList.next(null);

    if (this.billingCodeService.billingCodeFilterParams.partFamilyId !== null
      && this.billingCodeService.billingCodeFilterParams.partFamilyId !== undefined) {
      this.partGroupService.getPartGroupsByFamilyId(
        this.itemService.itemFilterParams.partFamilyId,
        false,
        true);
    }
  }

  public getUnitsOfMeasure(): void {
    this.unitsOfMeasureSub = this.uomService.uomList$
      .subscribe((unitsOfMeasure: UnitOfMeasureModel[]) => {
        if (unitsOfMeasure) {
          this.unitsOfMeasure = unitsOfMeasure;
        }
      });
  }

  public determineIsTablet(): void {
    this.isTabletSub = this.appStateService.isTablet$
      .subscribe((isTablet: boolean) => {
        this.isTablet = isTablet;
      });
  }

  public openBillingCodeModal(
    billingCodeId: number,
    isInventoryItem: boolean,
    launchedFromList: boolean = false): void {
    const initialState = {
      billingCodeId: billingCodeId,
      showInactive: this.showInactive,
      isInventoryItem: isInventoryItem,
      launchedFromList: launchedFromList,
      keepFilter: true
    };

    this.bsModalRef = this.modalService.show(AddEditBillingCodeModalComponent, { initialState, backdrop: 'static' });
  }

  public deleteBillingCode(id: number): void {
    this.deleteSub = this.billingCodeService.deleteBillingCode(id, this.showInactive)
      .subscribe((x: boolean) => {
        if (x) {
          this.uiService.showSuccess('Success', 'Billing Code Deleted');
        } else {
          this.uiService.showError('Error', 'Billing Code Not Deleted');
        }
      });
  }

  public onChangePartFamily($event: PartFamilyModel): void {
    this.billingCodeService.billingCodeFilterParams.partGroupId = null;

    if ($event !== undefined && $event !== null) {
      this.partGroupService.getPartGroupsByFamilyId($event.partFamilyId, false, true);
    } else {
      this.partGroupService.partGroupList.next(null);
    }
  }

  public onChangeShowInactive($event): void {
    this.showInactive = $event;
    this.billingCodeService.getBillingCodes(this.showInactive, true, false);
  }

  public getNumberOfActiveFilterFields(): void {
    if (!this.showFilter) {
      this.numberOfActiveFilterFields = this.itemService.getNumberOfActiveFilterFields();
    } else {
      this.numberOfActiveFilterFields = 0;
    }
  }

  public rowCallback(context: RowClassArgs): any {
    return {
      inactive: !context.dataItem.isActive
    };
  }

}
