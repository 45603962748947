<div class="modal-header"
  ngxModalDraggable>
  <h4 class="modal-title pull-left">{{isNew ? 'Add' : 'Edit'}}&nbsp;Employee</h4>
  <button type="button"
    class="close pull-right"
    aria-label="Close"
    (click)="bsModalRef.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<form (ngSubmit)="onSave()"
  #heroForm="ngForm">
  <div class="modal-body"
    *ngIf="model">
    <div class="form-group row">
      <label for="title"
        class="col-sm-2 text-right control-label col-form-label">First Name</label>
      <div class="col-sm-10 form-group mb-0">
        <input type="text"
          class="form-control form-control-sm"
          id="firstName"
          [(ngModel)]="model.firstName"
          name="firstName"
          required>
      </div>
    </div>
    <div class="form-group row">
      <label for="title"
        class="col-sm-2 text-right control-label col-form-label">Last Name</label>
      <div class="col-sm-10 form-group mb-0">
        <input type="text"
          class="form-control form-control-sm"
          id="lastName"
          [(ngModel)]="model.lastName"
          name="lastName"
          required>
      </div>
    </div>
    <div class="form-group row">
      <label for="title"
        class="col-sm-2 text-right control-label col-form-label">Email</label>
      <div class="col-sm-10 form-group mb-0">
        <input type="text"
          class="form-control form-control-sm"
          id="email"
          [(ngModel)]="model.email"
          name="email"
          required>
      </div>
    </div>
  </div>
</form>
<div class="modal-footer"
  *ngIf="model">
  <button type="button"
    class="btn btn-primary"
    (click)="onSave()"
    [disabled]="!(model.firstName&&model.lastName&&model.email)">Save</button>
  <button type="button"
    class="btn btn-default"
    (click)="bsModalRef.hide()">Cancel</button>
</div>
