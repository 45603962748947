export class AddressModel {
  addressId: number;
  streetAddressLine1: string;
  streetAddressLine2: string;
  city: string;
  countyId: number | null;
  countyName: string;
  stateId: number | null;
  stateName: string;
  zip: string;
  taxRate: number;
  isActive: boolean;
  customerId: number;
  vendorId: number;

  addressString: string;
}
