<div class="btn-group btn-block"
  dropdown
  placement="bottom right"
  *ngIf="jobService.job.value !== null && (jobService.job.value.jobStatusId !== cancellationId && jobService.job.value.jobStatusId !== closeId)">
  <button type="button"
    *ngIf="jobService.job.value !== null && (jobService.job.value.jobStatusId !== cancellationId && jobService.job.value.jobStatusId === openId && jobService.job.value.jobSubStatusId === JobSubStatuses.In_Progress)"
    class="btn btn-primary btn-block mt-1 mb-1"
    [swal]="{ title: 'Submit for Approval?', text: 'Job#:' + job.jobNumber + ' - ' + job.customerName, showCancelButton: true, confirmButtonText: 'Submit', confirmButtonColor: '#1B6EC2' }"
    (confirm)="changeJobSubStatus(JobSubStatuses.Pending_Approval);"
    [disabled]="enableReorder || !allowSubmitForSignature"><i class="fas fa-share"></i>&nbsp;&nbsp;Submit for
    Approval</button>
  <button type="button"
    *ngIf="!isFishing && jobService.job.value !== null && (jobService.job.value.jobStatusId !== cancellationId && jobService.job.value.jobStatusId === openId && jobService.job.value.jobSubStatusId === JobSubStatuses.Pending_Approval)"
    class="btn btn-primary btn-block mt-1 mb-1"
    [swal]="{ title: 'Ready for Signature?', text: 'Job#:' + job.jobNumber + ' - ' + job.customerName, showCancelButton: true, confirmButtonText: 'Ready', confirmButtonColor: '#1B6EC2' }"
    (confirm)="changeJobSubStatus(JobSubStatuses.Ready_for_Signature);"
    [disabled]="enableReorder || !canApproveInvoices"><i class="fas fa-check"></i>&nbsp;&nbsp;Ready for
    Signature</button>
  <button type="button"
    *ngIf="!isFishing && jobService.job.value !== null && (jobService.job.value.jobStatusId !== cancellationId && jobService.job.value.jobStatusId === openId && jobService.job.value.jobSubStatusId === JobSubStatuses.Ready_for_Signature)"
    class="btn btn-primary btn-block mt-1 mb-1"
    [swal]="{ title: 'Sent for Signature?', text: 'Job#:' + job.jobNumber + ' - ' + job.customerName, showCancelButton: true, confirmButtonText: 'Sent', confirmButtonColor: '#1B6EC2' }"
    (confirm)="changeJobSubStatus(JobSubStatuses.Sent_for_Signature);"
    [disabled]="enableReorder || !canApproveInvoices"><i class="fas fa-paper-plane"></i>&nbsp;&nbsp;Sent for
    Signature</button>
  <button type="button"
    *ngIf="isFishing && jobService.job.value !== null && (jobService.job.value.jobStatusId !== cancellationId && jobService.job.value.jobStatusId === editId && jobService.job.value.jobSubStatusId === JobSubStatuses.Pending_Approval)"
    class="btn btn-primary btn-block mt-1 mb-1"
    [swal]="{ title: 'Ready for Signature?', text: 'Job#:' + job.jobNumber + ' - ' + job.customerName, showCancelButton: true, confirmButtonText: 'Ready', confirmButtonColor: '#1B6EC2' }"
    (confirm)="changeJobSubStatus(JobSubStatuses.Ready_for_Signature);"
    [disabled]="enableReorder || !canApproveInvoices"><i class="fas fa-check"></i>&nbsp;&nbsp;Ready for
    Signature</button>
  <button type="button"
    *ngIf="isFishing && jobService.job.value !== null && (jobService.job.value.jobStatusId !== cancellationId && jobService.job.value.jobStatusId === editId && jobService.job.value.jobSubStatusId === JobSubStatuses.Ready_for_Signature)"
    class="btn btn-primary btn-block mt-1 mb-1"
    [swal]="{ title: 'Sent for Signature?', text: 'Job#:' + job.jobNumber + ' - ' + job.customerName, showCancelButton: true, confirmButtonText: 'Sent', confirmButtonColor: '#1B6EC2' }"
    (confirm)="changeJobSubStatus(JobSubStatuses.Sent_for_Signature);"
    [disabled]="enableReorder || !canApproveInvoices"><i class="fas fa-paper-plane"></i>&nbsp;&nbsp;Sent for
    Signature</button>
  <button *ngIf="canApproveInvoices"
    id="button-split"
    type="button"
    dropdownToggle
    class="btn btn-xs dropdown-toggle dropdown-toggle-split mt-1 mb-1"
    [class.btn-light]="false"
    [class.btn-primary]="true"
    aria-controls="dropdown-split"
    [disabled]="enableReorder">
    <span class="caret"></span>
    <span class="sr-only">Other Options</span>
  </button>
  <ul *dropdownMenu
    class="dropdown-menu btn-block"
    role="menu"
    aria-labelledby="button-split">
    <li role="menuitem">
      <a class="dropdown-item"
        *ngIf="!isFishing 
          && jobService.job.value 
          && jobService.job.value.jobSubStatusId === JobSubStatuses.Pending_Approval"
        [swal]="{ title: 'BACK TO In Progress?', text: 'Job#:' + job.jobNumber + ' - ' + job.customerName, showCancelButton: true, confirmButtonText: 'Back', confirmButtonColor: '#1B6EC2' }"
        (confirm)="changeJobSubStatus(JobSubStatuses.In_Progress);"
        [disabled]="enableReorder">
        <i class="fas fa-undo"></i> BACK (In Progress)
      </a>
    </li>
    <li role="menuitem">
      <a class="dropdown-item"
        *ngIf="jobService.job.value 
          && jobService.job.value.jobSubStatusId === JobSubStatuses.Ready_for_Signature"
        [swal]="{ title: 'BACK TO Pending Approval?', text: 'Job#:' + job.jobNumber + ' - ' + job.customerName, showCancelButton: true, confirmButtonText: 'Back', confirmButtonColor: '#1B6EC2' }"
        (confirm)="changeJobSubStatus(JobSubStatuses.Pending_Approval);"
        [disabled]="enableReorder">
        <i class="fas fa-undo"></i> BACK (Pending Approval)
      </a>
    </li>
    <li role="menuitem">
      <a class="dropdown-item"
        *ngIf="jobService.job.value && jobService.job.value.jobTypeId === JobTypes.Sales && jobService.job.value.jobStatusId !== JobStatuses.Quote_Sales"
        [swal]="{ title: 'Convert Sales Invoice to Quote?', text: 'Job#:' + job.jobNumber + ' - ' + job.customerName, showCancelButton: true, confirmButtonText: 'Convert', confirmButtonColor: '#1B6EC2' }"
        (confirm)="changeJobStatus(JobStatuses.Quote_Sales);"
        [disabled]="enableReorder">
        <i class="fal fa-quote-right"></i> Quote Invoice
      </a>
    </li>
    <li role="menuitem"><a class="dropdown-item"
        (click)="openCancelJobConfirmModal(job, cancellationId)"
        *ngIf="jobService.job.value && jobService.job.value.jobStatusId !== cancellationId && jobService.job.value.jobStatusId !== closeId"><i
          class="fad fa-times-circle"></i> Cancel Invoice</a></li>
  </ul>
</div>
