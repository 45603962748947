export enum JobStatuses {
  Open_Sales = 1,
  Closed_Sales = 2,
  Cancelled_Sales = 3,
  Open_Fishing = 4,
  Closed_Fishing = 5,
  Cancelled_Fishing = 6,
  Quote_Sales = 7,
  SplitInvoice = 8,
  Open_FishingToolRental = 9,
  Closed_FishingToolRental = 10,
  Cancelled_FishingToolRental = 11,
  EditInvoice_Fishing = 12,
  EditInvoice_FishingToolRental = 13,
  Open_Packer = 14,
  EditInvoice_Packer = 15,
  Closed_Packer = 16,
  Cancelled_Packer = 17
}
