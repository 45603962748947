import { BehaviorSubject, Observable, Observer } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { JobSubStatusModel } from 'src/models/job-sub-status.model';
import { tap } from 'rxjs/operators';

@Injectable()
export class JobSubStatusService {
  constructor(
    private http: HttpClient,
    @Inject('BASE_URL') private baseUrl: string
  ) { }

  private apiUrl = 'api/JobSubStatus';  // URL to web api

  private jobSubStatusListSubject = new BehaviorSubject<JobSubStatusModel[]>(null);
  public _jobSubStatusList$ = this.jobSubStatusListSubject.asObservable();
  private isJobSubStatusCallInProgress = false;
  public jobSubStatusList: BehaviorSubject<JobSubStatusModel[]> = new BehaviorSubject(null);

  public get jobSubStatusList$(): Observable<JobSubStatusModel[]> {
    if (!this.jobSubStatusListSubject.getValue() && !this.isJobSubStatusCallInProgress) {
      this.isJobSubStatusCallInProgress = true;
      this.getJobSubStatuses$(false).subscribe(() => this.isJobSubStatusCallInProgress = false);
    }

    return this._jobSubStatusList$;
  }

  public addEditJobSubStatus(dto: JobSubStatusModel, showInactive: boolean = false): Observable<boolean> {
    dto = this.cleanJobSubStatusModel(dto);

    return new Observable((observer: Observer<boolean>) => {
      this.http.post<boolean>(this.baseUrl + this.apiUrl + '/AddEditJobSubStatus', dto)
        .subscribe((x: boolean) => {
          if (x === true) {
            this.getJobSubStatuses(showInactive);
          }
          observer.next(x);
        });
    });
  }

  public deleteJobSubStatus(id: number, showInactive: boolean = false): Observable<boolean> {
    return new Observable((observer: Observer<boolean>) => {
      this.http.get<boolean>(this.baseUrl + this.apiUrl + '/DeleteJobSubStatus/' + id)
        .subscribe((x: boolean) => {
          if (x === true) {
            this.getJobSubStatuses(showInactive);
          }
          observer.next(x);
        });
    });
  }

  public getJobSubStatusById(id: number): Observable<JobSubStatusModel> {
    return this.http.get<JobSubStatusModel>(this.baseUrl + this.apiUrl + '/GetJobSubStatusById/' + id);
  }

  public getJobSubStatuses(showInactive: boolean = false): void {
    this.http.get<JobSubStatusModel[]>(this.baseUrl + this.apiUrl + '/GetJobSubStatuses/' + showInactive)
      .subscribe((x: JobSubStatusModel[]) => {
        this.jobSubStatusList.next(x);
      });
  }

  private getJobSubStatuses$(showInactive: boolean): Observable<JobSubStatusModel[]> {
    return this.http.get<Array<JobSubStatusModel>>(this.baseUrl + this.apiUrl + '/GetJobSubStatuses/' + showInactive)
      .pipe(tap((x: JobSubStatusModel[]) => {
        this.jobSubStatusListSubject.next(x);
      })
      );
  }

  public generateJobSubStatusModel(): JobSubStatusModel {
    let model: JobSubStatusModel = new JobSubStatusModel();

    model.isActive = true;
    model.isTerminal = false;
    model.sortOrder = this.getLastOrderNumber();

    return model;
  }

  private cleanJobSubStatusModel(model: JobSubStatusModel): JobSubStatusModel {
    Object.keys(model).forEach((index: string) => {
      if (typeof model[index] === 'string') {
        model[index] = model[index]
          && model[index].trim() !== '' ?
          model[index].trim()
          : null;
      }
    });

    return model;
  }

  private getLastOrderNumber(): number {
    const last: number = this.jobSubStatusList.value !== null ? Math.max.apply(Math, this.jobSubStatusList.value.map((x: JobSubStatusModel) => {
      return x.sortOrder;
    })) : null;

    return last !== null ? last + 1 : 0;
  }

}
