<div class="modal-header"
  ngxModalDraggable>
  <h4 class="modal-title pull-left"
    *ngIf="model">{{isNew ? 'Add' : 'Edit'}}&nbsp;Note</h4>
  <button type="button"
    class="close pull-right"
    aria-label="Close"
    (click)="bsModalRef.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body"
  *ngIf="model">
  <div class="form-group row">
    <label for="title"
      class="col-sm-2 text-right control-label col-form-label">Note</label>
    <div class="col-sm-10 form-group mb-0">
      <textarea class="form-control form-control-sm"
        id="description"
        [(ngModel)]="model.description"
        name="description"
        required></textarea>
    </div>
  </div>
  <div class="form-group row"
    *ngIf="invoiceHasBeenSplit">
    <label for="title"
      class="col-sm-2 text-right control-label col-form-label">Invoice</label>
    <div class="col-sm-10 form-group mb-0"
      *ngIf="invoiceService.invoiceList | async; let inv">
      <ng-select [items]="inv"
        bindLabel="dropdownDisplay"
        bindValue="invoiceId"
        [(ngModel)]="model.invoiceId"
        style="padding:0px;"
        name="items"
        [clearable]="false"
        required>
      </ng-select>
    </div>
  </div>
  <div class="modal-footer"
    *ngIf="model">
    <button type="button"
      class="btn btn-primary"
      (click)="onSave()"
      [disabled]="disableSave 
        || !(model.description)">Save</button>
    <button type="button"
      class="btn btn-default"
      (click)="bsModalRef.hide()">Cancel</button>
  </div>
