import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { MaintenanceTypeModel } from 'src/models/maintenance-type.model';

@Injectable()
export class MaintenanceTypeService {
  constructor(
    private http: HttpClient,
    @Inject('BASE_URL') private baseUrl: string
  ) { }

  private apiUrl = 'api/MaintenanceType';  // URL to web api

  private maintenanceTypeListSubject = new BehaviorSubject<MaintenanceTypeModel[]>(null);
  public _maintenanceTypeList$ = this.maintenanceTypeListSubject.asObservable();
  private isMaintenanceTypeCallInProgress = false;

  public get maintenanceTypeList$(): Observable<MaintenanceTypeModel[]> {
    if (!this.maintenanceTypeListSubject.getValue() && !this.isMaintenanceTypeCallInProgress) {
      this.isMaintenanceTypeCallInProgress = true;
      this.geMaintenanceTypes$(false).subscribe(() => this.isMaintenanceTypeCallInProgress = false);
    }

    return this._maintenanceTypeList$;
  }

  private geMaintenanceTypes$(showInactive: boolean): Observable<MaintenanceTypeModel[]> {
    return this.http.get<Array<MaintenanceTypeModel>>(this.baseUrl + this.apiUrl + '/GetMaintenanceTypes/' + showInactive)
      .pipe(
        tap((types: MaintenanceTypeModel[]) => {
          this.maintenanceTypeListSubject.next(types);
        })
      );
  }

}
