<div class="modal-header"
  ngxModalDraggable>
  <h4 class="modal-title pull-left">{{isNew ? 'Add' : 'Edit'}}&nbsp;Worksheet Type</h4>
  <button type="button"
    class="close pull-right"
    aria-label="Close"
    (click)="bsModalRef.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<form (ngSubmit)="onSave()"
  #heroForm="ngForm">
  <div class="modal-body"
    *ngIf="model">
    <div class="form-group row">
      <label for="title"
        class="col-sm-2 text-right control-label col-form-label">Name</label>
      <div class="col-sm-10 form-group mb-0">
        <input type="text"
          class="form-control form-control-sm"
          id="name"
          [(ngModel)]="model.name"
          name="name"
          required>
      </div>
    </div>
    <div class="form-group row"
      *ngIf="billingCodeService.billingCodeList | async; let bc">
      <label for="title"
        class="col-sm-2 text-right control-label col-form-label">Billing Code</label>
      <div class="col-sm-10 form-group mb-0">
        <div class="input-group">
          <ng-select [items]="bc"
            bindLabel="dropDownDisplay"
            bindValue="billingCodeId"
            [ngModel]="model.billingCodeId"
            name="billingCodes"
            class="col-md-9"
            style="padding:0px;"
            (change)="onChangeBillingCode($event)">
          </ng-select>
          <div class="input-group-append col-md-3"
            style="padding: 0;width:100%">
            <span class="input-group-text"
              style="padding: 0px;"><button id="new-bc"
                (click)="openBillingCodeModal(0, $event)"
                style="display: inline-flex;padding: 4px 10px;width:100%"
                class="btn btn-sm btn-default"
                [disabled]="isRebuildItem||(isInventoryItem&&!this.model.itemId)"><i class="fa fa-plus"
                  style="position: relative; top: 3px; margin-right: 5px;"></i>&nbsp;New</button></span>
          </div>
        </div>
      </div>
    </div>
    <div class="form-group row"
      *ngIf="worksheetCategoryService.worksheetCategoriesList | async; let cat">
      <label for="title"
        class="col-sm-2 text-right control-label col-form-label">Worksheet Category</label>
      <div class="col-sm-10 form-group mb-0">
        <div class="input-group">
          <ng-select [items]="cat"
            bindLabel="name"
            bindValue="worksheetCategoryId"
            [ngModel]="model.worksheetCategoryId"
            name="worksheetCategories"
            class="col-md-9"
            style="padding:0px;"
            [clearable]="false"
            (change)="onChangeWorksheetCategory($event)">
          </ng-select>
          <div class="input-group-append col-md-3"
            style="padding: 0;width:100%">
            <span class="input-group-text"
              style="padding: 0px;"><button id="new-bc"
                (click)="openWorksheetCategoryModal(0, $event)"
                style="display: inline-flex;padding: 4px 10px;width:100%"
                class="btn btn-sm btn-default"><i class="fa fa-plus"
                  style="position: relative; top: 3px; margin-right: 5px;"></i>&nbsp;New</button></span>
          </div>
        </div>
      </div>
    </div>
    <div class="form-group row">
      <label for="title"
        class="col-sm-2 text-right control-label col-form-label">Order</label>
      <div class="col-sm-10 form-group mb-0">
        <input type="number"
          class="form-control form-control-sm"
          id="sort"
          [(ngModel)]="model.sortOrder"
          name="sort"
          required>
      </div>
    </div>
    <div class="form-group row">
      <label for="title"
        class="col-sm-2 text-right control-label col-form-label">Is Itemized</label>
      <div class="col-sm-2 form-group mb-0">
        <ui-switch [(ngModel)]="model.isItemized"
          name="isItemized"></ui-switch>
      </div>
      <label for="title"
        class="col-sm-4 text-right control-label col-form-label">Show IsCustomerProperty</label>
      <div class="col-sm-4 form-group mb-0">
        <ui-switch [(ngModel)]="model.showIsCustomerProperty"
          name="showIsCustomerProperty"></ui-switch>
      </div>
    </div>
    <div class="form-group row">
      <label for="title"
        class="col-sm-2 text-right control-label col-form-label">Is Rebuild</label>
      <div class="col-sm-2 form-group mb-0">
        <ui-switch [(ngModel)]="model.isRebuild"
          name="isRebuild"></ui-switch>
      </div>
      <label for="title"
        class="col-sm-4 text-right control-label col-form-label">Is Packer</label>
      <div class="col-sm-4 form-group mb-0">
        <ui-switch [(ngModel)]="model.isPacker"
          name="isPacker"></ui-switch>
      </div>
    </div>
  </div>
</form>
<div class="modal-footer"
  *ngIf="model">
  <button type="button"
    class="btn btn-primary"
    (click)="onSave()"
    [disabled]="disableSave || !(model.name&&model.sortOrder)">Save</button>
  <button type="button"
    class="btn btn-default"
    (click)="bsModalRef.hide()">Cancel</button>
</div>
