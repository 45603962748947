import { Injectable } from '@angular/core';

@Injectable()
export class TimezoneService {
  constructor() { }

  public correctOffset(dateTime: Date): Date {
    if (dateTime) {
      return new Date(dateTime.getFullYear(),
        dateTime.getMonth(),
        dateTime.getDate(),
        dateTime.getHours(),
        dateTime.getMinutes() - dateTime.getTimezoneOffset());
    } else {
      return null;
    }
  }

  public formatDateAsString(date: Date): string {
    var m_names = new Array('January', 'February', 'March',
      'April', 'May', 'June', 'July', 'August', 'September',
      'October', 'November', 'December');

    var d = new Date(date);
    var curr_date = d.getDate();
    var curr_month = d.getMonth();
    var curr_year = d.getFullYear();

    return m_names[curr_month] + ' ' + curr_date + ', ' + curr_year;
  }


  public removeSeconds(dateTime: Date): Date {
    if (dateTime) {
      return new Date(
        dateTime.getFullYear(),
        dateTime.getMonth(),
        dateTime.getDate(),
        dateTime.getHours(),
        dateTime.getMinutes(),
        0,
        0);
    } else {
      return null;
    }
  }

  public spliceTimeOntoDate(
    date: Date,
    time: Date): Date {
    return new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
      time.getHours(),
      time.getMinutes(),
      time.getSeconds()
    );
  }

  public generateCurrentTimeToClosest15MinInterval(): Date {
    const dateToRound = new Date();
    const hours = dateToRound.getHours();
    const minutes = dateToRound.getMinutes();

    const roundedMinutes = (((minutes + 7.5) / 15 | 0) * 15) % 60;
    const roundedHours = ((((minutes / 105) + .5) | 0) + hours) % 24;

    return new Date(
      dateToRound.getFullYear(),
      dateToRound.getMonth(),
      dateToRound.getDay(),
      roundedHours,
      roundedMinutes,
      0,
      0);
  }

  public removeTimeFromDate(date: string | Date): Date {
    if (date instanceof Date) {
      return new Date(
        date.getFullYear(),
        date.getMonth(),
        date.getDate(),
        0,
        0,
        0);
    } else {
      null;
    }
  }

  public getFirstDayOfCurrentMonth(date: string | Date): Date {
    if (date instanceof Date) {
      return new Date(
        date.getFullYear(),
        date.getMonth(),
        1,
        0,
        0,
        0);
    } else {
      null;
    }
  }

  public getFirstDayOfPreviousMonth(date: string | Date): Date {
    if (date instanceof Date) {
      if (date.getMonth() === 0) {
        return (new Date(
          date.getFullYear() - 1,
          11,
          1,
          0,
          0,
          0));
      } else {
        return (new Date(
          date.getFullYear(),
          date.getMonth() - 1,
          1,
          0,
          0,
          0));
      }
    }
  }
}
