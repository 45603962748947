<div class="page-header">
  <div class="page-header-content header-elements-md-inline">
    <div class="page-title d-flex">
      <a [routerLink]="['/home']"
        class="breadcrumb-link-color">
        <h4><i class="icon-arrow-left52 mr-2"></i> <span class="font-weight-semibold">User Master</span> - Manage</h4>
      </a>
    </div>
    <div class="header-elements d-none py-0 mb-3 mb-md-0">
      <div class="breadcrumb">
        <a href="index.html"
          class="breadcrumb-item"><i class="icon-home2 mr-2"></i> User Master</a>
        <span class="breadcrumb-item active">List</span>
      </div>
    </div>
  </div>
</div>
<div class="page-content pt-0">
  <div class="content-wrapper">
    <div class="content">
      <div class="row">
        <div class="col-md-12">
          <div class="card border-top-primary border-top-2 rounded-top-0"
            style="border-top-color: #2196F3 !important;">
            <div class="card-header bg-light header-elements-sm-inline">
              <h6 class="card-title">Users</h6>
              <div class="header-elements">
                <div class="switch-container pt-2 mr-2">
                  <span class="switch-label">Show Inactive </span>
                  <ui-switch [ngModel]="showInactive"
                    (change)="onChangeShowInactive($event)"></ui-switch>
                </div>
                <button class="btn btn-light btn-sm mr-2"
                  (click)="openRoleMapModel()">
                  <i class="far fa-map"></i> Role Map
                </button>
                <button class="btn btn-light btn-sm"
                  (click)="openUserModal(0)">
                  <i class="fa fa-plus"></i> New User
                </button>
              </div>
            </div>
            <app-table-skeleton *ngIf="userService.usersAreLoading"></app-table-skeleton>
            <ng-container *ngIf="!userService.usersAreLoading">
              <div class="table-responsive"
                *ngIf="userService.userList | async; let model">
                <kendo-grid [kendoGridBinding]="model"
                  [style.maxHeight.px]="640"
                  filterable="menu"
                  [sortable]="true"
                  [resizable]="true"
                  [rowClass]="rowCallback"
                  [pageSize]="kGridService.kGridParams.pageSize"
                  [skip]="kGridService.kGridParams.skip"
                  (pageChange)="kGridService.pageChange($event)"
                  [pageable]="{
                              buttonCount: kGridService.kGridParams.buttonCount,
                              info: kGridService.kGridParams.info,
                              type: kGridService.kGridParams.type,
                              pageSizes: kGridService.kGridParams.pageSizes,
                              previousNext: kGridService.kGridParams.previousNext
                            }">
                  <kendo-grid-column field="one"
                    title=""
                    [width]="60"
                    filterable="false">
                    <ng-template kendoGridCellTemplate
                      let-dataItem
                      let-rowIndex="rowIndex">
                      <button class="btn btn-xs btn-light"
                        (click)="openUserModal(dataItem.id)"><i class="fa fa-edit"></i></button>
                    </ng-template>
                  </kendo-grid-column>
                  <kendo-grid-column field="userName"
                    title="Username"
                    [width]="200">
                  </kendo-grid-column>
                  <kendo-grid-column field="displayName"
                    title="Full Name"
                    [width]="150">
                  </kendo-grid-column>
                  <kendo-grid-column field="email"
                    title="Email"
                    [width]="240">
                  </kendo-grid-column>
                  <kendo-grid-column field="phoneNumber"
                    title="Phone"
                    [width]="130">
                  </kendo-grid-column>
                  <kendo-grid-column field="poLimit"
                    title="PO Limit"
                    filterable="false"
                    [width]="120">
                    <ng-template kendoGridCellTemplate
                      let-dataItem
                      let-rowIndex="rowIndex">
                      {{dataItem.poLimit | currency}}
                    </ng-template>
                  </kendo-grid-column>
                  <kendo-grid-column field="invoiceApprovalLimit"
                    title="Invoice Approval Limit"
                    filterable="false"
                    [width]="120">
                    <ng-template kendoGridCellTemplate
                      let-dataItem
                      let-rowIndex="rowIndex">
                      <span *ngIf="dataItem.canApproveInvoices 
                        && dataItem.invoiceApprovalLimit">{{dataItem.invoiceApprovalLimit |
                        currency}}</span>
                      <span *ngIf="dataItem.canApproveInvoices 
                        && !dataItem.invoiceApprovalLimit">Unlimited</span>
                      <span *ngIf="!dataItem.canApproveInvoices"
                        class="text-secondary">n/a</span>
                    </ng-template>
                  </kendo-grid-column>
                  <kendo-grid-column field="defaultShopName"
                    title="Default Shop"
                    [width]="110">
                  </kendo-grid-column>
                  <kendo-grid-column field="isActive"
                    title="Active"
                    [width]="70"
                    filterable="false">
                    <ng-template kendoGridCellTemplate
                      let-dataItem
                      let-rowIndex="rowIndex">
                      <span [ngClass]="{ 'text-danger' : !dataItem.isActive }">{{dataItem.isActive ? 'Yes' :
                        'No'}}</span>
                    </ng-template>
                  </kendo-grid-column>
                  <kendo-grid-column field="one"
                    title=""
                    [width]="60"
                    filterable="false">
                    <ng-template kendoGridCellTemplate
                      let-dataItem
                      let-rowIndex="rowIndex">
                      <button class="btn btn-xs btn-light"
                        [swal]="{ title: 'Deactivate User?', text: dataItem.userName + ' (' + dataItem.displayName +')', showCancelButton: true, confirmButtonText: 'Deactivate', confirmButtonColor: '#DC3545' }"
                        (confirm)="toggleUserActive(dataItem.id)"
                        *ngIf="dataItem.isActive === true">
                        <i class="fa fa-trash"></i>
                      </button>
                      <button class="btn btn-xs btn-light"
                        [swal]="{ title: 'Reactivate User?', text: dataItem.userName + ' (' + dataItem.displayName +')', showCancelButton: true, confirmButtonText: 'Reactivate', confirmButtonColor: '#2196F3' }"
                        (confirm)="toggleUserActive(dataItem.id)"
                        *ngIf="dataItem.isActive === false">
                        <i class="fas fa-plus"></i>
                      </button>
                    </ng-template>
                  </kendo-grid-column>
                </kendo-grid>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
