<div class="modal-header"
  ngxModalDraggable>
  <h4 class="modal-title pull-left"
    *ngIf="model">{{isNew ? 'Add ' : 'Edit '}}Unit Of Measure</h4>
  <button type="button"
    class="close pull-right"
    aria-label="Close"
    (click)="bsModalRef.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body"
  *ngIf="model">
  <div class="form-group row">
    <label for="title"
      class="col-sm-2 text-right control-label col-form-label">Name</label>
    <div class="col-sm-10 form-group mb-0">
      <input type="text"
        class="form-control form-control-sm"
        id="name"
        [(ngModel)]="model.name"
        name="name"
        required>
    </div>
  </div>
</div>
<div class="modal-footer"
  *ngIf="model">
  <button type="button"
    class="btn btn-primary"
    (click)="onSave()"
    [disabled]="!(model.name)">Save</button>
  <button type="button"
    class="btn btn-default"
    (click)="bsModalRef.hide()">Cancel</button>
</div>
