import { AccountModel } from '../models/account.model';
import { BehaviorSubject, Observable, tap } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

@Injectable()
export class AccountService {
  constructor(
    private http: HttpClient,
    @Inject('BASE_URL') private baseUrl: string
  ) { }

  private apiUrl = 'api/Account';  // URL to web api

  private accountListSubject = new BehaviorSubject<AccountModel[]>(null);
  private _accountList$ = this.accountListSubject.asObservable();
  private isAccountCallInProgress = false;

  public get accountList$(): Observable<AccountModel[]> {
    if (!this.accountListSubject.getValue() && !this.isAccountCallInProgress) {
      this.isAccountCallInProgress = true;
      this.getAccounts$().subscribe(() => this.isAccountCallInProgress = false);
    }

    return this._accountList$;
  }

  private getAccounts$(): Observable<AccountModel[]> {
    return this.http.get<Array<AccountModel>>(this.baseUrl + this.apiUrl + '/GetAccounts')
      .pipe(
        tap((accounts: AccountModel[]) => {
          this.accountListSubject.next(accounts);
        })
      );
  }

}
