import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Observer } from 'rxjs';
import { AddressModel } from 'src/models/address.model';
import { VendorModel } from '../models/vendor.model';
import { AddressService } from './address.service';

@Injectable()
export class VendorService {
  constructor(
    private http: HttpClient,
    @Inject('BASE_URL') private baseUrl: string,
    private addressService: AddressService
  ) { }

  public vendorList: BehaviorSubject<Array<VendorModel>> = new BehaviorSubject(null);
  public vendorCache: BehaviorSubject<VendorModel> = new BehaviorSubject(null);
  public vendorListIsLoading: boolean = false;

  private apiUrl = 'api/Vendor';  // URL to web api

  public addEditVendor(
    dto: VendorModel,
    showInactive: boolean = false,
    cacheVendor: boolean = false): Observable<boolean> {
    dto.vendorAddresses.forEach((a: AddressModel) => {
      a = this.addressService.cleanAddressModel(a);
    });

    return new Observable((observer: Observer<boolean>) => {
      this.http.post<number>(this.baseUrl + this.apiUrl + '/AddEditVendor', this.cleanVendorModel(dto))
        .subscribe((x: number) => {
          if (x !== -1) {
            this.getVendors(showInactive, true);
          }
          if (cacheVendor) {
            dto.vendorId = x;
            this.vendorCache.next(dto);
          } else {
            this.vendorCache.value !== null ? this.vendorCache.next(null) : null;
          }
          observer.next(x !== -1);
        });
    });
  }

  public deleteVendor(
    id: number,
    showInactive: boolean): Observable<boolean> {
    return new Observable((observer: Observer<boolean>) => {
      this.http.get<boolean>(this.baseUrl + this.apiUrl + '/DeleteVendor/' + id)
        .subscribe((x: boolean) => {
          if (x === true) {
            this.getVendors(showInactive, true);
          }
          observer.next(x);
        });
    });
  }

  public getVendors(
    showInactive: boolean,
    clear: boolean = false,
    showSkeleton: boolean = false,
    addInHouse: boolean = false): void {
    if (!this.vendorList.value || clear) {
      if (showSkeleton) {
        this.vendorListIsLoading = true;
      }

      this.http.get<Array<VendorModel>>(this.baseUrl + this.apiUrl + '/GetVendors/' + showInactive)
        .subscribe((x: VendorModel[]) => {

          if (addInHouse) {
            let inHouseVendor: VendorModel = new VendorModel();
            inHouseVendor.vendorId = -1;
            inHouseVendor.name = 'Grizzly Shop Repair';
            x.unshift(inHouseVendor);
          }

          this.vendorList.next(x);
          this.vendorListIsLoading = false;
        });
    }
  }

  public getVendorById(id: number): Observable<VendorModel> {
    return this.http.get<VendorModel>(this.baseUrl + this.apiUrl + '/GetVendorById/' + id);
  }

  public generateNewVendorModel(): VendorModel {
    let model: VendorModel = new VendorModel();
    model.isActive = true;

    return model;
  }

  private cleanVendorModel(model: VendorModel): VendorModel {
    model.name = model.name
      && model.name.trim() !== '' ?
      model.name.trim()
      : null;

    return model;
  }

}
