import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { AppStateService } from 'src/services/app-state.service';
import { BillingCodeService } from 'src/services/billing-code.service';
import { InventoryHistoryService } from 'src/services/inventory-history.service';
import { ItemInstanceLocationHistoryService } from 'src/services/item-instance-location-history.service';
import { ItemInstanceService } from 'src/services/item-instance.service';
import { ItemService } from 'src/services/item.service';
import { UIService } from 'src/services/ui.service';
import { AddEditItemInstanceModalComponent } from '../add-edit-item-instance-modal/add-edit-item-instance-modal.component';
import { ItemInstanceModel } from 'src/models/item-instance.model';
import { TabDirective } from 'ngx-bootstrap/tabs';

@Component({
  selector: 'app-item-instance-details',
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.css']
})

export class ItemInstanceDetailsComponent implements OnInit, OnDestroy {
  constructor(
    private modalService: BsModalService,
    private activatedRoute: ActivatedRoute,
    public itemService: ItemService,
    public uiService: UIService,
    public inventoryHistoryService: InventoryHistoryService,
    public billingCodeService: BillingCodeService,
    public itemInstanceService: ItemInstanceService,
    public iilhService: ItemInstanceLocationHistoryService,
    public appStateService: AppStateService,

  ) { };

  public bsModalRef: BsModalRef;
  public itemInstanceId: number = -1;
  public itemId: number = -1;
  public partGroupId: number = null;
  public infoIsCollapsed = false;
  public showDetails = true;
  public activeTabId: string = null;
  public hasMaintenanceLog: boolean = false;
  public showStatusHistory: boolean = false;

  private tabletSub: Subscription;
  public isTablet: boolean = false;

  private routeSub: Subscription;
  private iiSub: Subscription;

  ngOnDestroy(): void {
    this.iiSub?.unsubscribe();

    this.tabletSub?.unsubscribe();

    this.routeSub?.unsubscribe();
  }

  ngOnInit(): void {
    this.subscribeToRouteParams();

    this.determineIsTablet();
  }

  public determineIsTablet(): void {
    this.tabletSub = this.appStateService.isTablet$
      .subscribe((isTablet: boolean) => {
        this.isTablet = isTablet;
      });
  }

  public subscribeToRouteParams(): void {
    this.itemInstanceService.itemInstance.next(null);

    this.routeSub = this.activatedRoute.params
      .subscribe((x: Params) => {
        this.itemInstanceId = parseInt(this.activatedRoute.snapshot.params['id']);
        this.subscribeToInstance();
        this.iilhService.getLocationHistoryByItemInstanceId(this.itemInstanceId);
        this.itemInstanceService.refreshItemInstance(this.itemInstanceId);
      });
  }

  public subscribeToInstance(): void {
    this.iiSub = this.itemInstanceService.itemInstance
      .subscribe((x: ItemInstanceModel) => {
        if (x) {
          this.itemId = x.itemId;
          this.partGroupId = x.partGroupId;
          this.hasMaintenanceLog = x.hasMaintenanceLog;
          this.activeTabId = !x.hasMaintenanceLog ? 'STA' : 'MAI';
          this.showStatusHistory = true;
        }
      });
  }

  public tabSelect($event: TabDirective): void {
    if ($event !== undefined) {
      this.activeTabId = $event.id;
    }
  }

  public openAddEditItemInstanceModal(
    iiId: number,
    itemId: number): void {
    const initialState = {
      id: iiId,
      itemId: itemId,
      partGroupId: this.partGroupId
    };

    this.bsModalRef = this.modalService.show(AddEditItemInstanceModalComponent, { initialState, class: 'modal-lg', backdrop: 'static' });
  }

}
