<div class="page-header">
  <div class="page-header-content header-elements-md-inline">
    <div class="page-title d-flex">
      <a [routerLink]="['/home']"
        class="breadcrumb-link-color">
        <h4><i class="icon-arrow-left52 mr-2"></i> <span class="font-weight-semibold">Billing Code Master</span> -
          Manage</h4>
      </a>
      <button type="button"
        class="btn btn-outline-dark btn-sm ml-3 pl-1 pr-1 pt-0 pb-0 no-border"
        [title]="!showFilter ? 'Show Filter' : 'Hide Filter'"
        (click)="showFilter = !showFilter;getNumberOfActiveFilterFields();">
        <span *ngIf="!showFilter">
          <i class="fa-regular fa-chevrons-right"></i>&nbsp;Show </span>
        <span *ngIf="showFilter"><i class="fa-regular fa-chevrons-left"></i>&nbsp;Hide
        </span>Filter<span class="text-warning"
          *ngIf="!showFilter && numberOfActiveFilterFields > 0"><strong>&nbsp;&nbsp;({{numberOfActiveFilterFields}}
            active
            criteria)</strong></span></button>
    </div>
    <div class="header-elements d-none py-0 mb-3 mb-md-0">
      <div class="breadcrumb">
        <a href="index.html"
          class="breadcrumb-item"><i class="icon-home2 mr-2"></i> Billing Code Master</a>
        <span class="breadcrumb-item active">List</span>
      </div>
    </div>
  </div>
</div>
<div class="page-content pt-0">
  <div class="content-wrapper">
    <div class="content">
      <div class="row">
        <div class="col-lg-3"
          *ngIf="showFilter">
          <div class="card">
            <div class="card-header bg-light header-elements-inline"
              [ngClass]="{'pr-2 pl-2':isTablet}">
              <h6 class="card-title">Filter Criteria</h6>
              <div class="switch-container pt-2">
                <span class="switch-label">Show Inactive </span>
                <ui-switch [ngModel]="showInactive"
                  (change)="onChangeShowInactive($event)"></ui-switch>
              </div>
            </div>
            <div class="card-body"
              [ngClass]="{'pr-2 pl-2':isTablet}">
              <div class="form-group row">
                <label class="col-form-label col-lg-4 col-md-2">Name</label>
                <div class="col-lg-8 col-md-4"
                  [ngClass]="{'pl-0':isTablet}">
                  <input type="text"
                    class="form-control"
                    [(ngModel)]="billingCodeService.billingCodeFilterParams.name"
                    name="name"
                    (keyup.enter)="billingCodeService.getBillingCodes(showInactive, true);kGridService.pageChange({ skip: 0, take: kGridService.kGridParams.pageSize })">
                </div>
                <label class="col-form-label col-lg-4 col-md-2 mt-lg-3 mt-sm-0">Customer</label>
                <div class="col-lg-8 col-md-4 mt-lg-3"
                  [ngClass]="{'pl-0':isTablet}"
                  *ngIf="customerService.customerList | async; let cust">
                  <ng-select [items]="cust"
                    bindLabel="name"
                    bindValue="customerId"
                    [clearable]="false"
                    [(ngModel)]="billingCodeService.billingCodeFilterParams.customerId"
                    name="customers">
                  </ng-select>
                </div>
                <div *ngIf="!customerService.customerList.value"
                  class="col-lg-8 col-md-4 mt-lg-3"
                  [ngClass]="{'pl-0':isTablet}">
                  <ng-select name="customers"
                    [readonly]="true">
                  </ng-select>
                </div>
              </div>
              <div class="form-group row">
                <label class="col-form-label col-lg-4 col-md-2">UOM</label>
                <div class="col-lg-8 col-md-4"
                  [ngClass]="{'pl-0':isTablet}"
                  *ngIf="unitsOfMeasure">
                  <ng-select [items]="unitsOfMeasure"
                    bindLabel="name"
                    bindValue="uomId"
                    [clearable]="false"
                    [(ngModel)]="billingCodeService.billingCodeFilterParams.uomId"
                    name="uom">
                  </ng-select>
                </div>
                <div *ngIf="!unitsOfMeasure"
                  class="col-lg-8 col-md-4"
                  [ngClass]="{'pl-0':isTablet}">
                  <ng-select name="uom"
                    [readonly]="true">
                  </ng-select>
                </div>
                <label class="col-form-label col-lg-4 col-md-2 mt-lg-3 mt-sm-0">Item</label>
                <div class="col-lg-8 col-md-4 mt-lg-3"
                  [ngClass]="{'pl-0':isTablet}"
                  *ngIf="itemService.itemDropdownList | async; let item">
                  <ng-select [items]="item"
                    bindLabel="dropdownDisplay"
                    bindValue="itemId"
                    [(ngModel)]="billingCodeService.billingCodeFilterParams.itemId"
                    name="items"
                    [clearable]="false"
                    appendTo='body'>
                  </ng-select>
                </div>
                <div *ngIf="!itemService.itemDropdownList.value"
                  class="col-lg-8 col-md-4 mt-lg-3"
                  [ngClass]="{'pl-0':isTablet}">
                  <ng-select name="items"
                    [readonly]="true">
                  </ng-select>
                </div>
              </div>
              <div class="form-group row">
                <label class="col-form-label col-lg-4 col-md-2">Part Family</label>
                <div class="col-lg-8 col-md-4"
                  [ngClass]="{'pl-0':isTablet}"
                  *ngIf="partFamilyService.partFamilyList | async; let pf">
                  <ng-select [items]="pf"
                    bindLabel="name"
                    bindValue="partFamilyId"
                    [(ngModel)]="billingCodeService.billingCodeFilterParams.partFamilyId"
                    (change)="onChangePartFamily($event)"
                    name="partFamily"
                    appendTo='body'>
                  </ng-select>
                </div>
                <div *ngIf="!partFamilyService.partFamilyList.value"
                  class="col-lg-8 col-md-4"
                  [ngClass]="{'pl-0':isTablet}">
                  <ng-select name="partFamily"
                    [readonly]="true">
                  </ng-select>
                </div>
                <label class="col-form-label col-lg-4 col-md-2 mt-lg-3 mt-sm-0">Part Group</label>
                <div class="col-lg-8 col-md-4 mt-lg-3"
                  [ngClass]="{'pl-0':isTablet}"
                  *ngIf="partGroupService.partGroupList | async; let pg">
                  <ng-select [items]="pg"
                    bindLabel="name"
                    bindValue="partGroupId"
                    [(ngModel)]="billingCodeService.billingCodeFilterParams.partGroupId"
                    name="partGroup"
                    appendTo='body'>
                  </ng-select>
                </div>
                <div class="col-lg-8 col-md-4 mt-lg-3"
                  [ngClass]="{'pl-0':isTablet}"
                  *ngIf="(partGroupService.partGroupList | async)===null; let pg">
                  <input type="text"
                    class="form-control"
                    readonly>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-6">
                  <button type="submit"
                    class="btn btn-block btn-primary"
                    (click)="billingCodeService.getBillingCodes(showInactive, true, true, false);kGridService.pageChange({ skip: 0, take: kGridService.kGridParams.pageSize })"><i
                      class="fa fa-search mr-2"></i>Search</button>
                </div>
                <div class="col-sm-6">
                  <button type="submit"
                    class="btn btn-block btn-light"
                    (click)="billingCodeService.clearFilterParams(showInactive);partGroupService.partGroupList.next(null);kGridService.pageChange({ skip: 0, take: kGridService.kGridParams.pageSize })"><i
                      class="far fa-times-circle"></i>&nbsp;Clear</button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div [ngClass]="{'col-lg-9': showFilter, 'col-lg-12': !showFilter}">
          <div class="card border-top-primary border-top-2 rounded-top-0"
            style="border-top-color: #2196F3 !important;">
            <div class="card-header bg-light header-elements-sm-inline">
              <h6 class="card-title">Billing Codes</h6>
              <div class="header-elements">
                <button class="btn btn-light btn-sm"
                  (click)="openBillingCodeModal(0, false, true)">
                  <i class="fa fa-plus"></i> New Billing Code
                </button>
              </div>
            </div>
            <app-table-skeleton *ngIf="billingCodeService.billingCodesAreLoading"></app-table-skeleton>
            <ng-container *ngIf="!billingCodeService.billingCodesAreLoading">
              <div class="table-responsive"
                *ngIf="billingCodeService.billingCodeList | async; let model">
                <kendo-grid [ngClass]="{'kendo-min-width':!isTablet}"
                  [kendoGridBinding]="model"
                  [style.maxHeight.px]="640"
                  filterable="false"
                  [rowClass]="rowCallback"
                  [sortable]="true"
                  [resizable]="true"
                  [pageSize]="kGridService.kGridParams.pageSize"
                  [skip]="kGridService.kGridParams.skip"
                  (pageChange)="kGridService.pageChange($event)"
                  [pageable]="{
                                        buttonCount: kGridService.kGridParams.buttonCount,
                                        info: kGridService.kGridParams.info,
                                        type: kGridService.kGridParams.type,
                                        pageSizes: kGridService.kGridParams.pageSizes,
                                        previousNext: kGridService.kGridParams.previousNext
                                      }">
                  <kendo-grid-column field="one"
                    title=""
                    width="60"
                    filterable="false">
                    <ng-template kendoGridCellTemplate
                      let-dataItem
                      let-rowIndex="rowIndex">
                      <button class="btn btn-xs btn-light"
                        (click)="openBillingCodeModal(dataItem.billingCodeId, dataItem.itemId !== null)"><i
                          class="fa fa-edit"></i></button>
                    </ng-template>
                  </kendo-grid-column>
                  <kendo-grid-column field="name"
                    title="Name"
                    width="300">
                    <ng-template kendoGridCellTemplate
                      let-dataItem
                      let-rowIndex="rowIndex">
                      <ng-template #popoverTemplateBc>
                        <div class="pl-2 pr-2">
                          <div *ngFor="let ws of dataItem.worksheets;let i = index"
                            class="row">
                            <div class="col-xs-12">
                              <a [routerLink]="['/worksheet-types/details', ws.worksheetTypeId]"
                                target="_blank">{{ws.name}}</a>
                            </div>
                          </div>
                        </div>
                      </ng-template>
                      <div class="d-flex justify-content-between">
                        <span>{{dataItem.name}} <span *ngIf="!dataItem.isActive"
                            class="text-danger"
                            title="Inactive">&nbsp;<i class="fas fa-times-circle"></i></span></span>
                        <span *ngIf="dataItem.worksheets && dataItem.worksheets.length > 0"
                          class="text-primary pointer">
                          &nbsp;&nbsp;
                          <i class="fal fa-file-alt"
                            type="button"
                            [popover]="popoverTemplateBc"
                            popoverTitle="Active Worksheets"
                            placement="auto"
                            [outsideClick]="true"
                            container="body">
                          </i>
                        </span>
                      </div>
                    </ng-template>
                  </kendo-grid-column>
                  <kendo-grid-column field="uomName"
                    title="UOM"
                    width="70">
                  </kendo-grid-column>
                  <kendo-grid-column field="quantity"
                    title="Qty"
                    width="50">
                  </kendo-grid-column>
                  <kendo-grid-column field="rate"
                    title="Rate"
                    width="90">
                    <ng-template kendoGridCellTemplate
                      let-dataItem
                      let-rowIndex="rowIndex">
                      {{dataItem.rate | currency}}
                    </ng-template>
                  </kendo-grid-column>
                  <kendo-grid-column field="itemPartNumber"
                    title="Item"
                    width="80">
                    <ng-template kendoGridCellTemplate
                      let-dataItem
                      let-rowIndex="rowIndex">
                      <span *ngIf="dataItem.itemId"
                        [ngClass]="{'link-color' : dataItem.itemIsActive}">
                        <a [routerLink]="['/items/details', dataItem.itemId]"
                          target="_blank"
                          *ngIf="dataItem.itemIsActive"
                          [title]="dataItem.itemDescription">{{dataItem.itemPartNumber}}</a>
                        <span *ngIf="!dataItem.itemIsActive">{{dataItem.itemPartNumber}}</span>
                      </span>
                      <span *ngIf="!dataItem.itemId"
                        class="text-muted">n/a</span>
                    </ng-template>
                  </kendo-grid-column>
                  <kendo-grid-column field="customerName"
                    title="Customer"
                    width="170">
                  </kendo-grid-column>
                  <kendo-grid-column field="isTaxable"
                    title="Taxable"
                    width="70">
                    <ng-template kendoGridCellTemplate
                      let-dataItem
                      let-rowIndex="rowIndex">
                      <i class="fal fa-file-invoice-dollar"
                        title="Taxable Item"
                        *ngIf="dataItem.isTaxable"></i>
                    </ng-template>
                  </kendo-grid-column>
                  <kendo-grid-column field="additionalDayRental"
                    title="Add'l Day"
                    [width]="70">
                    <ng-template kendoGridCellTemplate
                      let-dataItem
                      let-rowIndex="rowIndex">
                      <i class="fas fa-check"
                        title="Additional Day Rental"
                        *ngIf="dataItem.additionalDayRental"></i>
                    </ng-template>
                  </kendo-grid-column>
                  <kendo-grid-column field="one"
                    title=""
                    width="60"
                    filterable="false">
                    <ng-template kendoGridCellTemplate
                      let-dataItem
                      let-rowIndex="rowIndex">
                      <button class="btn btn-xs btn-light"
                        [swal]="{ title: 'Delete Billing Code?', text: dataItem.name, showCancelButton: true, confirmButtonText: 'Delete', confirmButtonColor: '#DC3545' }"
                        (confirm)="deleteBillingCode(dataItem.billingCodeId)"
                        [disabled]="dataItem.isActive === false"><i class="fa fa-trash"></i></button>
                    </ng-template>
                  </kendo-grid-column>
                </kendo-grid>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
