import { Component, OnDestroy, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { ContactTypes } from 'src/enums/contact-types.enum';
import { ContactModel } from 'src/models/contact.model';
import { ContactService } from 'src/services/contact.service';
import { CountyService } from 'src/services/county.service';
import { StateService } from 'src/services/state.service';
import { UIService } from 'src/services/ui.service';

@Component({
  selector: 'app-add-edit-contact-modal',
  templateUrl: './add-edit-contact-modal.component.html',
  styleUrls: ['add-edit-contact-modal.component.css']
})

export class AddEditContactModalComponent implements OnInit, OnDestroy {
  public isNew: boolean = false;
  public id: number = -1;
  public type: number;
  public entityId: number = -1;
  public showInactive: boolean = false;
  public cacheContact: boolean = false;
  public disableSave: boolean = false;

  public saveSub: Subscription;
  public modelSub: Subscription;
  public model: ContactModel;

  constructor(
    public bsModalRef: BsModalRef,
    public contactService: ContactService,
    public countyService: CountyService,
    public stateService: StateService,
    public uiService: UIService
  ) { };

  ngOnDestroy(): void {
    this.modelSub?.unsubscribe();

    this.saveSub?.unsubscribe();
  }

  ngOnInit() {
    this.isNew = this.id === 0;

    if (this.isNew) {
      this.model = this.contactService.generateNewContactModel(
        this.type === ContactTypes.Vendor ? this.entityId : null,
        this.type === ContactTypes.Customer ? this.entityId : null);
    } else {
      this.getModel();
    }
  }

  public getModel(): void {
    this.modelSub = this.contactService.getContactById(this.id)
      .subscribe((x: ContactModel) => {
        this.model = x;
      });
  }

  public onSave(): void {
    this.disableSave = true;

    this.saveSub = this.contactService.addEditContact(
      this.model,
      this.showInactive,
      this.cacheContact)
      .subscribe((x: boolean) => {
        if (x) {
          this.bsModalRef.hide();

          this.uiService.showSuccess('Contact Saved', 'Success');
        } else {
          this.uiService.showError('Contact Not Saved', 'Error');

          this.disableSave = false;
        }
      });
  }

}
