import { Component, OnDestroy, OnInit } from '@angular/core';
import { RowClassArgs } from '@progress/kendo-angular-grid';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { InventoryCountStatusModel } from 'src/models/inventory-count-status.model';
import { InventoryCountStatusService } from 'src/services/inventory-count-status.service';
import { InventoryCountService } from 'src/services/inventory-count.service';
import { KGridService } from 'src/services/k-grid.service';
import { UIService } from 'src/services/ui.service';
import { AddEditInventoryCountModalComponent } from '../add-edit-inventory-count-modal/add-edit-inventory-count-modal.component';
import { InventoryCountStatuses } from 'src/enums/inventory-count-statuses';

@Component({
  selector: 'app-inventory-counts-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.css']
})

export class InventoryCountListComponent implements OnInit, OnDestroy {

  public showInactive: boolean = false;

  constructor(
    private modalService: BsModalService,
    public kGridService: KGridService,
    public statusService: InventoryCountStatusService,
    public countService: InventoryCountService,
    public uiService: UIService,
  ) { };

  public bsModalRef: BsModalRef;

  private deleteSub: Subscription;
  private statusSub: Subscription;
  public statuses: InventoryCountStatusModel[];
  public InventoryCountStatuses = InventoryCountStatuses;


  ngOnDestroy() {
    this.kGridService.resetKGridParams();

    this.deleteSub?.unsubscribe();

    this.statusSub?.unsubscribe();
  }

  ngOnInit(): void {
    this.countService.getInventoryCounts(this.showInactive, true);
  }


  public openInventoryCountModal(inventoryCountId: number): void {
    const initialState = {
      id: inventoryCountId,
      showInactive: this.showInactive
    };

    this.bsModalRef = this.modalService.show(AddEditInventoryCountModalComponent, { initialState, backdrop: 'static' });
  }

  public deleteCount(id: number): void {
    this.deleteSub = this.countService.deleteInventoryCount(id, this.showInactive)
      .subscribe((x: boolean) => {
        if (x) {
          this.uiService.showSuccess('Success', 'Count Deleted');
        } else {
          this.uiService.showError('Error', 'Count Not Deleted');
        }
      });
  }

  public onChangeShowInactive($event: boolean): void {
    this.showInactive = $event;
    this.countService.getInventoryCounts(this.showInactive, true);
  }

  public rowCallback(context: RowClassArgs): any {
    return {
      inactive: context.dataItem.isActive === false
    };
  }

}
