import { PartGroupModel } from './part-group.model';

export class PartFamilyModel {

  constructor(partFamilyId: number, partNumberRangeStart: string, fishingTools: boolean,isActive: boolean = true) {
    this.partFamilyId = partFamilyId;
    this.partNumberRangeStart = partNumberRangeStart;
    this.fishingTools = fishingTools;
    this.isActive = isActive;
  }

  partFamilyId: number;
  name: string;
  prefix: string;
  partNumberRangeStart: string;
  fishingTools: boolean;
  isActive: boolean;

  partGroups: PartGroupModel[] = [];
}
