import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable()
export class AppStateService {
  private isTabletSubject = new BehaviorSubject<boolean>(false);
  public isTablet$ = this.isTabletSubject.asObservable();

  constructor() { }

  public updateIsTablet(isTablet: boolean): void {
    this.isTabletSubject.next(isTablet);
  }

}
