<div class="modal-header"
  ngxModalDraggable>
  <h4 class="modal-title pull-left"
    *ngIf="model">Adjust Inventory - {{itemService.item.value.partNumber}}</h4>
  <button type="button"
    class="close pull-right"
    aria-label="Close"
    (click)="bsModalRef.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body"
  *ngIf="model">
  <div class="form-group row">
    <label for="title"
      class="col-sm-2 text-right control-label col-form-label">Shop</label>
    <div class="col-sm-10 form-group mb-0"
      *ngIf="shops">
      <ng-select [items]="shops"
        bindLabel="name"
        bindValue="shopId"
        [(ngModel)]="model.shopId"
        [clearable]="false"
        (change)="onChangeOfShop($event.shopId)"
        name="shops"
        required>
      </ng-select>
    </div>
  </div>
  <div class="form-group row">
    <label for="title"
      class="col-sm-2 text-right control-label col-form-label">Change</label>
    <div class="col-sm-10 form-group mb-0">
      <input type="number"
        class="form-control form-control-sm"
        id="inventoryChange"
        [(ngModel)]="model.inventoryChange"
        name="inventoryChange"
        (change)="calculateInventoryOnHand()"
        required>
    </div>
  </div>
  <div class="form-group row">
    <label for="title"
      class="col-sm-2 text-right control-label col-form-label">Reason</label>
    <div class="col-sm-10 form-group mb-0">
      <textarea type="number"
        class="form-control form-control-sm"
        id="adjustmentDescription"
        [(ngModel)]="model.adjustmentDescription"
        name="adjustmentDescription"
        required></textarea>
    </div>
  </div>
  <div class="form-group row mb-0"
    *ngIf="model.shopId">
    <label for="title"
      class="col-sm-2 text-right control-label col-form-label"></label>
    <div class="col-sm-5 form-group mb-0">
      <div class="alert alert-success p-1 mb-1"
        role="alert"
        *ngIf="inventoryDisplay > 0 && inventoryDisplay !==null">
        <i class="fas fa-check-circle"></i>&nbsp;&nbsp;Current Inventory:
        <strong>{{inventoryDisplay!==null ? inventoryDisplay : 0}}</strong>
      </div>
      <div class="alert alert-danger p-1 mb-1"
        role="alert"
        *ngIf="inventoryDisplay <= 0 && inventoryDisplay !==null">
        <i class="fas fa-exclamation-circle"></i>&nbsp;&nbsp;Current Inventory:
        <strong>{{inventoryDisplay!==null ? inventoryDisplay : 0}}</strong>
      </div>
    </div>
    <div class="col-sm-5 form-group mb-0">
      <div class="alert alert-success p-1 mb-1"
        role="alert"
        *ngIf="model.inventoryOnHandAfterChange > 0 && model.inventoryOnHandAfterChange !==null">
        <i class="fas fa-check-circle"></i>&nbsp;&nbsp;Inventory After Change:
        <strong>{{model.inventoryOnHandAfterChange!==null ? model.inventoryOnHandAfterChange : 0}}</strong>
      </div>
      <div class="alert alert-danger p-1 mb-1"
        role="alert"
        *ngIf="model.inventoryOnHandAfterChange <= 0 && model.inventoryOnHandAfterChange !==null">
        <i class="fas fa-exclamation-circle"></i>&nbsp;&nbsp;Inventory After Change:
        <strong>{{model.inventoryOnHandAfterChange!==null ? model.inventoryOnHandAfterChange : 0}}</strong>
      </div>
    </div>
  </div>
  <div class="form-group row"
    *ngIf="!model.shopId">
    <label for="title"
      class="col-sm-2 text-right control-label col-form-label"></label>
    <div class="col-sm-10 form-group mb-0">
      <div class="row pl-2">
        <div class="alert alert-danger p-1 mb-1"
          role="alert"
          *ngIf="itemIsLoaded && (currentInventory === null || currentInventory.length === 0)">
          <i class="fas fa-check-circle"></i>&nbsp;&nbsp;Current Inventory:
          <strong>{{currentInventory!==null ? currentInventory : 0}}</strong>
        </div>
        <ng-container *ngIf="itemIsLoaded && currentInventory !== null && currentInventory.length > 0">
          <div class="alert p-1 mb-1 mr-2"
            [ngClass]="{'alert-success':ci.inventoryCount !==null && ci.inventoryCount > 0, 'alert-danger': ci.inventoryCount === null || ci.inventoryCount <= 0 }"
            role="alert"
            *ngFor="let ci of currentInventory">
            <i class="fas fa-check-circle"></i>&nbsp;&nbsp;{{ci.name}} Inventory:
            <strong>{{ci.inventoryCount !==null ? ci.inventoryCount : 0}}</strong>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>
<div class="modal-footer"
  *ngIf="model">
  <button type="button"
    class="btn btn-primary"
    (click)="onSave()"
    [disabled]="disableSave 
      || !(model.inventoryChange 
        && model.adjustmentDescription 
        && model.shopId)">Save</button>
  <button type="button"
    class="btn btn-default"
    (click)="bsModalRef.hide()">Cancel</button>
</div>
