import { Component, OnDestroy, OnInit } from '@angular/core';
import { RowClassArgs } from '@progress/kendo-angular-grid';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { EmployeeService } from 'src/services/employee.service';
import { KGridService } from 'src/services/k-grid.service';
import { UIService } from 'src/services/ui.service';
import { AddEditEmployeeModalComponent } from '../add-edit-employee-modal/add-edit-employee-modal.component';

@Component({
  selector: 'app-employees-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.css']
})

export class EmployeeListComponent implements OnInit, OnDestroy {
  public showInactive: boolean = false;
  private deleteSub: Subscription;

  constructor(
    private modalService: BsModalService,
    public kGridService: KGridService,
    public employeeService: EmployeeService,
    public uiService: UIService,
  ) { };

  public bsModalRef: BsModalRef;

  ngOnDestroy(): void {
    this.kGridService.resetKGridParams();

    this.deleteSub?.unsubscribe();
  }

  ngOnInit(): void {
    this.employeeService.getEmployees(
      this.showInactive,
      true);
  }

  public openEmployeeModal(employeeId: number): void {
    const initialState = {
      id: employeeId,
      showInactive: this.showInactive
    };

    this.bsModalRef = this.modalService.show(AddEditEmployeeModalComponent, { initialState, backdrop: 'static' });
  }

  public deleteEmployee(id: number): void {
    this.deleteSub = this.employeeService.deleteEmployee(id, this.showInactive)
      .subscribe((x: boolean) => {
        if (x) {
          this.uiService.showSuccess('Success', 'Employee Deleted');
        } else {
          this.uiService.showError('Error', 'Employee Not Deleted');
        }
      });
  }

  public onChangeShowInactive($event: boolean): void {
    this.showInactive = $event;
    this.employeeService.getEmployees(this.showInactive, true);
  }

  public rowCallback(context: RowClassArgs): any {
    return {
      inactive: context.dataItem.isActive === false
    };
  }

}
