import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { RowClassArgs, SelectableSettings } from '@progress/kendo-angular-grid';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { WorksheetRowItemTypeModel } from 'src/models/worksheet-row-item-type.model';
import { WorksheetRowTypeModel } from 'src/models/worksheet-row-type.model';
import { AppStateService } from 'src/services/app-state.service';
import { UIService } from 'src/services/ui.service';
import { WorksheetRowItemTypeService } from 'src/services/worksheet-row-item-type.service';
import { WorksheetRowTypeService } from 'src/services/worksheet-row-type.service';
import { WorksheetTypeService } from 'src/services/worksheet-type.service';
import { AddEditWorksheetRowItemTypeModalComponent } from '../add-edit-worksheet-row-item-type-modal/add-edit-worksheet-row-item-type-modal.component';
import { AddEditWorksheetRowTypeModalComponent } from '../add-edit-worksheet-row-type-modal/add-edit-worksheet-row-type-modal.component';
import { AddEditWorksheetTypeModalComponent } from '../add-edit-worksheet-type-modal/add-edit-worksheet-type-modal.component';
import { WorksheetTypeModel } from 'src/models/worksheet-type.model';

@Component({
  selector: 'app-worksheet-types-details',
  templateUrl: './details.component.html',
  styleUrls: ['details.component.css']
})

export class WorksheetTypeDetailsComponent implements OnInit, OnDestroy {

  public worksheetTypeId: number = 1;
  public selectableSettings: SelectableSettings;
  public selectedIds: number[] = [];
  public itemTypes: WorksheetRowItemTypeModel[] = [];
  public selectedRowName: string = '';
  public showDetails = true;
  public bsModalRef: BsModalRef;

  private isTabletSub: Subscription;
  public isTablet: boolean = false;

  private routeParamsSub: Subscription;
  private worksheetTypeSub: Subscription;
  private deleteWrtSub: Subscription;
  private deleteWritSub: Subscription;
  private copySub: Subscription;

  constructor(
    private modalService: BsModalService,
    private activatedRoute: ActivatedRoute,
    public uiService: UIService,
    public worksheetTypeService: WorksheetTypeService,
    public worksheetRowTypeService: WorksheetRowTypeService,
    public appStateService: AppStateService,
    public worksheetRowItemTypeService: WorksheetRowItemTypeService,
  ) { };

  ngOnDestroy(): void {
    this.copySub?.unsubscribe();

    this.deleteWrtSub?.unsubscribe();

    this.deleteWritSub?.unsubscribe();

    this.isTabletSub?.unsubscribe();

    this.routeParamsSub?.unsubscribe();

    this.worksheetTypeSub?.unsubscribe();
  }

  ngOnInit(): void {
    this.determineIsTablet();

    this.subscribeToRouteParams();

    this.subscribeToWorksheetChanges();

    this.setSelectableSettings();
  }

  public determineIsTablet(): void {
    this.isTabletSub = this.appStateService.isTablet$
      .subscribe((isTablet: boolean) => {
        this.isTablet = isTablet;
      });
  }

  public rowCallback(context: RowClassArgs): any {
    return {
      newBuildBillingCode: context !== undefined
        && context.dataItem != undefined
        && context.dataItem.isNewBuildBillingCode === true
    };
  }

  public subscribeToRouteParams(): void {
    this.routeParamsSub = this.activatedRoute.params
      .subscribe((x: Params) => {
        this.worksheetTypeId = parseInt(this.activatedRoute.snapshot.params['id']);
        this.worksheetTypeService.refreshWorksheetType(this.worksheetTypeId);
        this.selectedIds = [];
        this.itemTypes = [];
      });
  }

  // SELECTABLE KENDO STUFF//
  public setSelectableSettings(): void {
    this.selectableSettings = {
      checkboxOnly: false,
      mode: 'single'
    };
  }

  public selectedKeysChange($event: number[]): void {
    this.selectedIds = $event;
    this.populateItemTypeKendo();
  }

  public subscribeToWorksheetChanges(): void {
    this.worksheetTypeSub = this.worksheetTypeService.worksheetType
      .subscribe((x: WorksheetTypeModel) => {
        if (x !== null) {
          this.populateItemTypeKendo();
        }
      });
  }

  public populateItemTypeKendo(): void {
    if (this.selectedIds.length == 0) {
      this.itemTypes = [];
    } else {
      let row: WorksheetRowTypeModel[] = this.worksheetTypeService.worksheetType.value !== null
        && this.worksheetTypeService.worksheetType.value.worksheetRowTypes !== null ?
        this.worksheetTypeService.worksheetType.value.worksheetRowTypes.filter((x: WorksheetRowTypeModel) => {
          return x.worksheetRowTypeId === this.selectedIds[0];
        }) : null;

      this.itemTypes = row !== null
        && row.length > 0
        && row[0].worksheetRowItemTypes !== null
        && row[0].worksheetRowItemTypes.length > 0 ? row[0].worksheetRowItemTypes : [];

      this.selectedRowName = row !== null && row.length > 0 ? row[0].name : '';
    }
  }
  // END SELECTABLE KENDO STUFF//
  // MODAL STUFF//
  public openWorksheetTypeModal(worksheetTypeId: number): void {
    const initialState = {
      id: worksheetTypeId
    };
    this.bsModalRef = this.modalService.show(AddEditWorksheetTypeModalComponent, { initialState, backdrop: 'static' });
  }

  public openWorksheetRowTypeModal(worksheetRowTypeId: number): void {
    const initialState = {
      id: worksheetRowTypeId,
      isItemized: this.worksheetTypeService.worksheetType.value !== null ? this.worksheetTypeService.worksheetType.value.isItemized : true,
      isRebuild: this.worksheetTypeService.worksheetType.value !== null ? this.worksheetTypeService.worksheetType.value.isRebuild : true,
      worksheetTypeName: this.worksheetTypeService.worksheetType.value !== null ? this.worksheetTypeService.worksheetType.value.name : '',
      worksheetTypeId: this.worksheetTypeService.worksheetType.value !== null ? this.worksheetTypeService.worksheetType.value.worksheetTypeId : -1,
      newRowSortValue: this.getNewRowSortNumber()
    };
    this.bsModalRef = this.modalService.show(AddEditWorksheetRowTypeModalComponent, { initialState, backdrop: 'static' });
  }

  public openWorksheetRowItemTypeModal(
    worksheetRowItemTypeId: number,
    worksheetRowTypeId: number): void {
    if (worksheetRowTypeId === -1) {
      worksheetRowTypeId = this.selectedIds[0];
    }

    let isItemized: boolean;

    let rowType: WorksheetRowTypeModel[] = this.worksheetTypeService.worksheetType.value !== null
      && this.worksheetTypeService.worksheetType.value.worksheetRowTypes ?
      this.worksheetTypeService.worksheetType.value.worksheetRowTypes.filter((x: WorksheetRowTypeModel) => {
        return x.worksheetRowTypeId == worksheetRowTypeId;
      }) : [];

    if (rowType.length > 0) {
      isItemized = rowType[0].itemized;
    }

    const initialState = {
      id: worksheetRowItemTypeId,
      isItemized: isItemized !== undefined ? isItemized : false,
      worksheetTypeName: this.worksheetTypeService.worksheetType.value !== null ? this.worksheetTypeService.worksheetType.value.name : '',
      worksheetRowTypeName: this.selectedRowName,
      worksheetRowTypeId: worksheetRowTypeId,
      worksheetTypeId: this.worksheetTypeService.worksheetType.value !== null ? this.worksheetTypeService.worksheetType.value.worksheetTypeId : -1,
      newRowItemSortValue: this.getNewRowItemSortNumber()
    };

    this.bsModalRef = this.modalService.show(AddEditWorksheetRowItemTypeModalComponent, { initialState, backdrop: 'static' });
  }
  // END MODAL STUFF//
  public getNewRowSortNumber(): number {
    let num =
      this.worksheetTypeService.worksheetType.value !== null &&
        this.worksheetTypeService.worksheetType.value.worksheetRowTypes &&
        this.worksheetTypeService.worksheetType.value.worksheetRowTypes.length > 0 ?
        Math.max.apply(Math, this.worksheetTypeService.worksheetType.value.worksheetRowTypes.map((x: WorksheetRowTypeModel) => {
          return x.sortOrder;
        })) : 0;

    return num + 1;
  }

  public getNewRowItemSortNumber(): number {
    let row: WorksheetRowTypeModel[] =
      this.selectedIds.length > 0 &&
        this.worksheetTypeService.worksheetType.value !== null &&
        this.worksheetTypeService.worksheetType.value.worksheetRowTypes &&
        this.worksheetTypeService.worksheetType.value.worksheetRowTypes.length > 0 ?
        this.worksheetTypeService.worksheetType.value.worksheetRowTypes.filter((x: WorksheetRowTypeModel) => {
          return x.worksheetRowTypeId == this.selectedIds[0];
        }) : [];

    let num =
      row.length > 0 &&
        row[0].worksheetRowItemTypes !== null &&
        row[0].worksheetRowItemTypes.length > 0 ?
        Math.max.apply(Math, row[0].worksheetRowItemTypes.map((x: WorksheetRowItemTypeModel) => {
          return x.sortOrder;
        })) : 0;

    return num + 1;
  }

  public deleteWorksheetRowType(dto: WorksheetRowTypeModel): void {
    this.deleteWrtSub = this.worksheetRowTypeService.deleteWorksheetRowType(
      dto,
      true)
      .subscribe((x: boolean) => {
        if (x) {
          this.uiService.showSuccess('Success', 'Worksheet Row Type Deleted');
        } else {
          this.uiService.showError('Error', 'Worksheet Row Type Not Deleted');
        }
      });
  }

  public deleteWorksheetRowItemType(dto: WorksheetRowItemTypeModel): void {
    this.deleteWritSub = this.worksheetRowItemTypeService.deleteWorksheetRowItemType(
      dto,
      true)
      .subscribe((x: boolean) => {
        if (x) {
          this.uiService.showSuccess('Success', 'Worksheet Row Item Type Deleted');
        } else {
          this.uiService.showError('Error', 'Worksheet Row Item Type Not Deleted');
        }
      });
  }

  public createCopy(): void {
    let id = this.worksheetTypeService.worksheetType.value.worksheetTypeId;
    this.copySub = this.worksheetTypeService.createCopyOfWorksheetType(id)
      .subscribe((x: boolean) => {
        if (x) {
          this.uiService.showSuccess('Success', 'Worksheet Type Copied');
        } else {
          this.uiService.showError('Error', 'Worksheet Type Not Copied');
        }
      });
  }

}
