import { Component, OnDestroy, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { ItemInstanceLocationModel } from 'src/models/item-instance-location.model';
import { ItemInstanceLocationService } from 'src/services/item-instance-location.service';
import { ItemInstanceService } from 'src/services/item-instance.service';
import { ItemService } from 'src/services/item.service';
import { UIService } from 'src/services/ui.service';

@Component({
  selector: 'app-add-edit-item-instance-location-modal',
  templateUrl: './add-edit-item-instance-location-modal.component.html',
  styleUrls: ['./add-edit-item-instance-location-modal.component.css']
})

export class AddEditItemInstanceLocationModalComponent implements OnInit, OnDestroy {
  public isNew: boolean = false;
  public id: number = -1;
  public model: ItemInstanceLocationModel;

  private modelSub: Subscription;
  private saveSub: Subscription;

  constructor(
    public bsModalRef: BsModalRef,
    public itemInstanceService: ItemInstanceService,
    public iilService: ItemInstanceLocationService,
    public uiService: UIService,
    public itemService: ItemService

  ) { };

  ngOnDestroy(): void {
    this.modelSub?.unsubscribe();

    this.saveSub?.unsubscribe();
  }

  ngOnInit(): void {
    this.isNew = this.id === 0;

    if (this.isNew) {
      this.model = this.iilService.generateNewItemInstanceLocationModel();
    } else {
      this.getModel();
    }
  }

  public getModel(): void {
    this.modelSub = this.iilService.getItemInstanceLocationById(this.id)
      .subscribe((x: ItemInstanceLocationModel) => {
        this.model = x;
      });
  }

  public onSave(): void {
    this.saveSub = this.iilService.addEditItemInstanceLocation(this.model, false)
      .subscribe((x: boolean) => {
        if (x) {
          this.bsModalRef.hide();
          this.uiService.showSuccess('Location Saved', 'Success');
        } else {
          this.uiService.showError('Location Saved', 'Error');
        }
      });
  }

}
