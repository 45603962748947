// This is NOT a full list of Billing Codes.  This list is Billing Codes that, for better or worse, are hard-coded within the app.
export enum BillingCodes {
  PackerToolSupervisor = 624,
  FullBodyInspection = 1476,
  ConnectionInspection = 1477,
  ToolSupervisor12hours = 1478,
  MileageWithTrailer = 1479,
  Mileage = 1480,
  RodInspection = 1541,
  PackerToolSupervisorHalfDay = 1710
}
