import { Component, OnDestroy, OnInit } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { AddEditItemModalComponent } from 'src/app/items/add-edit-item-modal/add-edit-item-modal.component';
import { AddEditStorageLocationModalComponent } from 'src/app/storage-location/add-edit-storage-location-modal/add-edit-storage-location-modal.component';
import { ItemVendorModel } from 'src/models/item-vendor.model';
import { PurchaseOrderLineModel } from 'src/models/purchase-order-line.model';
import { PurchaseOrderStatusModel } from 'src/models/purchase-order-status.model';
import { PurchaseOrderTypeModel } from 'src/models/purchase-order-type.model';
import { ItemService } from 'src/services/item.service';
import { PurchaseOrderLineService } from 'src/services/purchase-order-line.service';
import { PurchaseOrderStatusService } from 'src/services/purchase-order-status.service';
import { PurchaseOrderTypeService } from 'src/services/purchase-order-type.service';
import { PurchaseOrderService } from 'src/services/purchase-order.service';
import { StorageLocationService } from 'src/services/storage-location.service';
import { UIService } from 'src/services/ui.service';
import { PurchaseOrderStatuses } from '../../../enums/purchase-order-statuses';
import { PurchaseOrderLineReceivedModel } from '../../../models/purchase-order-line-received.model';
import { ShopItemModel } from '../../../models/shop-item.model';
import { TimezoneService } from '../../../services/timezone.service';
import { UserService } from '../../../services/user.service';
import { ItemModel } from 'src/models/item.model';
import { StorageLocationModel } from 'src/models/storage-location.model';
import { UserModel } from 'src/models/user.model';
import { ItemDropdownModel } from 'src/models/item-dropdown.model';
import { ItemInstanceService } from 'src/services/item-instance.service';
import { ItemInstanceModel } from 'src/models/item-instance.model';
import { MaintenanceEventService } from 'src/services/maintenance-event.service';

type InnerSnArray = [ItemInstanceModel, boolean];
type ParentSnArray = InnerSnArray[];

@Component({
  selector: 'app-add-edit-po-line-modal',
  templateUrl: './add-edit-po-line-modal.component.html',
  styleUrls: ['./add-edit-po-line-modal.component.css']
})

export class AddEditPoLineModalComponent implements OnInit, OnDestroy {
  private poTypesSub: Subscription;
  public poTypes: PurchaseOrderTypeModel[];

  private modelSub: Subscription;
  private inventorySub: Subscription;
  private itemSub: Subscription;
  private itemListSub: Subscription;
  private storageLocationSub: Subscription;
  private userSub: Subscription;
  private saveSub: Subscription;
  private cancelSub: Subscription;
  private statusSub: Subscription;
  private snSub: Subscription;

  public isNew: boolean = false;
  public isInventoryItem: boolean = null;
  public id: number = -1;
  public poid: number = -1;
  public poDisplay: string = '';
  public vendorId: number = -1;
  public model: PurchaseOrderLineModel;
  public lineTotal: string = '0';
  public isInitialLoad: boolean = false;
  public managePo: boolean = false;
  public itemIsLoaded: boolean = false;

  public disableStatus: boolean = true;
  public hideCancel: boolean = false;
  public disableTopFields: boolean = false;
  public currentInventory: ShopItemModel[] = [];
  public PurchaseOrderStatuses = PurchaseOrderStatuses;
  public disableSave: boolean = false;
  public showAllItems: boolean = false;

  public item: ItemDropdownModel;

  public serNum: ParentSnArray = [];
  public serNumSelected: boolean = true;
  public allIiMeasurementsComplete: boolean = true;

  constructor(
    public bsModalRef: BsModalRef,
    public bsModalRefChildModal: BsModalRef,
    public itemService: ItemService,
    public itemInstanceService: ItemInstanceService,
    public poService: PurchaseOrderService,
    public lineService: PurchaseOrderLineService,
    public statusService: PurchaseOrderStatusService,
    public typeService: PurchaseOrderTypeService,
    public locService: StorageLocationService,
    public maintenanceEventService: MaintenanceEventService,
    private modalService: BsModalService,
    public userService: UserService,
    public uiService: UIService,
    public timeZoneService: TimezoneService
  ) { };

  ngOnDestroy() {
    this.itemService.itemCache.next(null);

    this.cancelSub?.unsubscribe();

    this.inventorySub?.unsubscribe();

    this.itemSub?.unsubscribe();

    this.itemListSub?.unsubscribe();

    this.poTypesSub.unsubscribe();

    this.modelSub?.unsubscribe();

    this.saveSub?.unsubscribe();

    this.snSub?.unsubscribe();

    this.statusSub?.unsubscribe();

    this.storageLocationSub?.unsubscribe();

    this.userSub?.unsubscribe();
  }

  ngOnInit(): void {
    this.isInitialLoad = true;

    this.statusService.getPurchaseOrderStatuses(
      false,
      true,
      true);

    this.getPoTypes();

    this.lineService.getNewOrUsedStatuses(false);

    this.locService.getStorageLocations(
      false,
      true);

    this.subscribeToItemCache();

    this.subscribeToStorageLocationCache();

    this.subscribeToUser();

    this.isNew = this.id === 0;

    if (this.isNew) {
      this.hideCancel = true;

      this.model = this.lineService.generateNewPoLineModel(
        this.poid,
        this.isInventoryItem);

      if (this.isInventoryItem) {
        this.itemService.itemCache.next(null);

        this.itemService.getItemsDropdown(
          false,
          false,
          true,
          false);
      } else {
        this.maintenanceEventService.getMaintenanceEventsByVendorId(
          this.vendorId,
          false,
          true);
      }
    } else {
      this.getModel();
    }
  }

  public getPoTypes(): void {
    this.poTypesSub = this.typeService.poTypeList$
      .subscribe((poTypes: PurchaseOrderTypeModel[]) => {
        if (poTypes) {
          this.poTypes = poTypes;
        }
      });
  }

  public getModel(): void {
    this.modelSub = this.lineService.getPoLineById(this.id)
      .subscribe((x: PurchaseOrderLineModel) => {
        if ((x.quantity !== null || x.quantity) && (x.price !== null || x.price !== undefined)) {
          this.lineTotal = this.lineService.generateTotalCostString(x.quantity, x.price);
        }

        this.isInventoryItem = x.itemId !== null && x.itemId !== undefined;

        if (this.isInventoryItem) {
          this.itemService.getItemsDropdown(
            false,
            false,
            true,
            false);
        } else {
          this.maintenanceEventService.getMaintenanceEventsByVendorId(
            this.vendorId,
            false,
            true);
        }

        this.statusSelectDisable(x.postatusId);

        this.setDisableTopFields(x);

        this.model = x;

        if (this.model.itemId !== null) {
          this.subscribeToItemList();
        }

        if (this.model.itemId !== null
          && (this.model.postatusId === this.PurchaseOrderStatuses.Draft
            || this.model.postatusId === this.PurchaseOrderStatuses.PendingApproval)) {
          this.getCurrentInventory();
        }
      });
  }

  public addNewReceivedLine(): void {
    this.model.received.push(new PurchaseOrderLineReceivedModel());
  }

  public deleteReceivedLine(index: number): void {
    this.model.received.splice(index, 1);
  }

  public changeDateString(
    $event: Date,
    rec: PurchaseOrderLineReceivedModel): void {
    rec.recDateString = $event !== undefined && $event !== null ? this.timeZoneService.formatDateAsString($event) : null;
  }

  public getCurrentInventory(): void {
    if (this.model.itemId !== null) {
      this.inventorySub = this.itemService.getItemById(this.model.itemId)
        .subscribe((x: ItemModel) => {
          this.itemIsLoaded = true;

          this.currentInventory = x !== null
            && x.shopItems !== undefined
            && x.shopItems !== null
            && x.shopItems.length > 0 ?
            x.shopItems
            : [];
        });
    }
  }

  public subscribeToItemCache(): void {
    this.itemService.itemCache.next(null);

    this.itemSub = this.itemService.itemCache
      .subscribe((x: ItemModel) => {
        if (x !== null && x !== undefined) {
          this.model.itemId = x.itemId;
        }
      });
  }

  public subscribeToStorageLocationCache(): void {
    this.locService.storageLocationList.next(null);

    this.storageLocationSub = this.locService.locationCache
      .subscribe((x: StorageLocationModel) => {
        if (x !== null && x !== undefined) {
          this.model.storageLocationId = x.storageLocationId;
        }
      });
  }

  public subscribeToUser(): void {
    this.userSub = this.userService.user
      .subscribe((x: UserModel) => {
        if (x !== null) {
          this.managePo = x.activeRoles.includes('Manage PO');
          this.setDisableTopFields(this.model);
        }
      });
  }

  public subscribeToItemList(): void {
    this.itemListSub = this.itemService.itemDropdownList
      .subscribe((items: ItemDropdownModel[]) => {
        if (items !== null && this.isInitialLoad) {
          this.itemService.checkForDeactivatedItem(this.model.itemId);
          this.isInitialLoad = false;
          this.loadItem(items);
        }
      });
  }

  public onChangeOfQtyOrPrice(): void {
    if ((this.model.quantity !== null || this.model.quantity) && (this.model.price !== null || this.model.price !== undefined)) {
      this.lineTotal = this.lineService.generateTotalCostString(
        this.model.quantity,
        this.model.price);
    } else {
      this.lineTotal = '';
    }
  }

  public updateDescriptionAndPrice($event: ItemDropdownModel): void {
    if ($event !== undefined) {
      let temp: ItemVendorModel[] = null;
      if ($event.itemVendor !== undefined && $event.itemVendor !== null && $event.itemVendor.length > 0) {
        temp = $event.itemVendor.filter((x: any) => {
          return x.vendorId === this.vendorId;
        });
      }

      if ($event.dropdownDisplay !== undefined && $event.cost !== undefined) {
        this.model.description = $event.dropdownDisplay !== undefined ? $event.dropdownDisplay : '';

        this.model.price = $event.cost !== undefined ? $event.cost : null;
      } else if (temp !== null && temp.length === 1) {
        let partNumberExists: boolean = temp[0].partNumber !== null
          && temp[0].partNumber !== undefined
          && temp[0].partNumber.trim() !== '';

        let descriptionExists: boolean = temp[0].description !== null
          && temp[0].description !== undefined
          && temp[0].description.trim() !== '';

        let priceExists: boolean = temp[0].cost !== undefined
          && temp[0].cost !== null;

        this.model.description = '';
        this.model.description += partNumberExists ? temp[0].partNumber : '';
        this.model.description += partNumberExists && descriptionExists ? ' (' : '';
        this.model.description += descriptionExists ? temp[0].description : '';
        this.model.description += partNumberExists && descriptionExists ? ')' : '';

        this.model.price = priceExists ? temp[0].cost : null;
      }
    }

    this.onChangeOfQtyOrPrice();
    this.getCurrentInventory();
    this.loadItem(this.itemService.itemDropdownList.value);
  }

  public cancel(): void {
    if (this.statusService.cancel !== null) {
      this.model.postatusId = this.statusService.cancel.value.postatusId;

      this.onSave();
    }
  }

  public onSave(): void {
    this.disableSave = true;

    this.model.itemInstances = this.filterItemInstances();

    this.saveSub = this.lineService.addEditPoLine(this.model)
      .subscribe((x: boolean) => {
        if (x) {
          this.bsModalRef.hide();
          this.uiService.showSuccess('PO Line Saved', 'Success');
        } else {
          this.disableSave = false;
          this.uiService.showError('PO Line Not Saved', 'Error');
        }
      });
  }

  public openItemModal(itemId: number): void {
    const initialState = {
      id: itemId,
      showInactive: false,
      launchedFromModal: true
    };

    this.bsModalRefChildModal = this.modalService.show(AddEditItemModalComponent, { initialState, class: 'modal-lg', backdrop: 'static' });
  }

  public openStorageLocationModal(storageLocationId: number): void {
    const initialState = {
      id: storageLocationId,
      showInactive: false,
      launchedFromPoLineModal: true
    };

    this.bsModalRefChildModal = this.modalService.show(AddEditStorageLocationModalComponent, { initialState, backdrop: 'static' });
  }

  public setDisableTopFields(model: PurchaseOrderLineModel): void {
    if (model !== undefined && model !== null) {
      if (this.managePo) {
        this.disableTopFields = model.postatusName === 'Received';
      } else {
        this.disableTopFields = model.postatusName !== 'Draft';
      }
    }
  }

  public handleStatusChange($event: PurchaseOrderStatusModel): void {
    this.setHideCancel($event);
  }

  public onChangeOfReceivedQty($event: Event, receivedPrice: number): void {
    if ($event && this.checkIfSerialized()) {
      const value: string = ($event.target as HTMLInputElement).value;
      const valueInt: number = parseInt(value, 10);

      if (!isNaN(valueInt) || valueInt > 0) {
        this.snSub = this.itemInstanceService.getMultipleNewSerialNumbers(this.item.partGroupId, this.item.itemId, valueInt)
          .subscribe((x: string[]) => {
            if (x) {
              x.forEach((sn: string) => {
                const exists: boolean = this.serNum.some((x: InnerSnArray) => {
                  return x[0].serialNumber === sn;
                });

                if (!exists) {
                  const ii: ItemInstanceModel = this.itemInstanceService.generateNewItemInstanceModel(
                    this.item.itemId,
                    null,
                    this.item.partGroupId,
                    sn,
                    this.model.polineId,
                    receivedPrice ? receivedPrice : this.model.price
                  );

                  this.serNum.push([ii, true]);
                }
              });

              for (let i = this.serNum.length - 1; i >= 0; i--) {
                const exists: boolean = x.some((sn: string) => {
                  return sn === this.serNum[i][0].serialNumber;
                });

                if (!exists) {
                  this.serNum.splice(i, 1);
                }
              }

              this.setAllIiMeasurementsComplete($event);
            }
          });
      } else {
        this.serNum = [];
      }
    }
  }

  public onChangeOfReceivedPrice($event: Event, receivedPrice: number) {
    if ($event) {
      this.serNum.forEach((x: InnerSnArray) => {
        x[0].originalCost = receivedPrice ? receivedPrice : this.model.price;
      });
    }
  }

  public setAllIiMeasurementsComplete($event: Event): void {
    this.allIiMeasurementsComplete = this.serNum.every((x: InnerSnArray) => {
      return !x[1]
        || (x[1]
          && ((x[0].innerDiameter || x[0].innerDiameter === 0)
            && (x[0].outerDiameter || x[0].outerDiameter === 0)
            && (x[0].length || x[0].length === 0)));
    });
  }

  public toggleAllSerialNumbers($event): void {
    if ($event) {
      const anyChecked: boolean = this.serNum.some((x: InnerSnArray) => {
        return x[1] === true;
      });

      if (anyChecked) {
        this.serNum.forEach((x: InnerSnArray) => {
          x[1] = false;
        });

        this.serNumSelected = anyChecked;
      } else {
        this.serNum.forEach((x: InnerSnArray) => {
          x[1] = true;

          this.serNumSelected = !anyChecked;
        });
      }

      this.setSerNumSelected($event);
    }
  }

  public setSerNumSelected($event: Event) {
    this.serNumSelected = this.serNum.some((x: InnerSnArray) => {
      return x[1] === true;
    });

    this.setAllIiMeasurementsComplete($event);
  }


  public setHideCancel($event: PurchaseOrderStatusModel): void {
    this.hideCancel = this.isNew || this.statusService.cancel.value.postatusId === $event.postatusId;
  }

  public checkIfSerialized(): boolean {
    return this.item && this.item.itemTypeId === 2;
  }

  public statusSelectDisable(id: number): void {
    this.statusSub = this.statusService.statusList
      .subscribe((x: PurchaseOrderStatusModel[]) => {
        if (x !== null) {
          let status: Array<PurchaseOrderStatusModel> = x.filter((x: PurchaseOrderStatusModel) => x.postatusId === id);
          if (status.length > 0) {
            this.disableStatus = status[0].readOnly;
          } else {
            this.cancelSub = this.statusService.cancel
              .subscribe(y => {
                if (y) {
                  this.statusService.statusList.value.push(y);
                  this.hideCancel = true;
                  this.disableStatus = false;
                }
              });
          }
        }
      });
  }

  private loadItem(items: ItemDropdownModel[]): void {
    let item: ItemDropdownModel[] = items.filter((x: ItemDropdownModel) => x.itemId === this.model.itemId);

    if (item && item.length > 0) {
      this.item = item[0];
    }
  }

  private filterItemInstances(): ItemInstanceModel[] {
    let sns: ItemInstanceModel[] = [];

    this.serNum.forEach((sn: InnerSnArray) => {
      if (sn[1] === true) {
        sns.push(sn[0]);
      }
    });

    return sns;
  }
}
