<div class="modal-header"
  ngxModalDraggable>
  <h4 class="modal-title pull-left"
    *ngIf="model">{{isNew ? 'Add' : 'Edit'}}&nbsp;Work Log</h4>
  <div class="ml-3">
    <ui-switch name="timeFormat"
      (change)="toggleTimeFormat($event)"
      checkedLabel="12-hr"
      uncheckedLabel="24-hr"
      color="white"
      size="small"></ui-switch>
  </div>
  <button type="button"
    class="close pull-right"
    aria-label="Close"
    (click)="bsModalRef.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body"
  *ngIf="model">
  <div class="form-group row mb-2">
    <label for="title"
      class="col-sm-2 text-right control-label col-form-label">Start</label>
    <div class="col-sm-4 form-group mb-0">
      <kendo-timepicker style="width: 100%;"
        [(ngModel)]="model.workStart"
        [format]="timeFormat"
        name="start"
        [steps]="steps"
        required>
      </kendo-timepicker>
    </div>
    <label for="title"
      class="col-sm-2 text-right control-label col-form-label">
      End
    </label>
    <div class="col-sm-4 form-group mb-0">
      <kendo-timepicker style="width: 100%;"
        [(ngModel)]="model.workEnd"
        [format]="timeFormat"
        name="start"
        [steps]="steps"
        required>
      </kendo-timepicker>
    </div>
    <div class="col-sm-12"
      *ngIf="model.workEnd<=model.workStart">
      <div class="row">
        <div class="col-sm-2">
        </div>
        <div class="col-sm-10">
          <p class="text-danger mb-0 mt-2"><i class="fas fa-exclamation-triangle"></i>&nbsp;End time must be later than
            Start time.</p>
        </div>
      </div>
    </div>
    <div class="col-sm-12"
      *ngIf="model.workEnd>model.workStart">
      <div class="row">
        <div class="col-sm-2">
        </div>
        <div class="col-sm-10">
          <p class="text-success mb-0 mt-2"><i class="fas fa-check"></i>&nbsp;Nice!</p>
        </div>
      </div>
    </div>
  </div>
  <div class="form-group row">
    <label for="title"
      class="col-sm-2 text-right control-label col-form-label">Supervisor</label>
    <div class="col-sm-10 form-group mb-0"
      *ngIf="employeeService.employeeList | async; let emp">
      <ng-select [items]="emp"
        bindLabel="fullName"
        bindValue="employeeId"
        [(ngModel)]="model.employeeId"
        [clearable]="false"
        name="employee"
        required>
      </ng-select>
    </div>
  </div>
  <div class="form-group row">
    <label for="title"
      class="col-sm-2 text-right control-label col-form-label">Mileage</label>
    <div class="col-sm-2 form-group mb-0">
      <ui-switch [ngModel]="isMileage"
        name="isMileage"
        (change)="onChangeOfMileage($event)"></ui-switch>
    </div>
    <label for="title"
      class="col-sm-3 text-right control-label col-form-label"
      *ngIf="isMileage">Miles</label>
    <div class="col-sm-5 form-group mb-0"
      *ngIf="isMileage">
      <input type="number"
        class="form-control form-control-sm"
        id="mileage"
        (blur)="createMileageDescription()"
        [(ngModel)]="model.mileage"
        name="mileage"
        required>
    </div>
  </div>
  <div class="form-group row"
    *ngIf="isMileage">
    <label for="title"
      class="col-sm-2 text-right control-label col-form-label">Billing Code</label>
    <div class="col-sm-10 form-group mb-0"
      *ngIf="bcService.mileageBCList | async; let bc">
      <ng-select [items]="bc"
        bindLabel="dropDownDisplay"
        bindValue="billingCodeId"
        [(ngModel)]="model.billingCodeId"
        name="billingCode"
        [clearable]="false"
        (change)="createMileageDescription()"
        required>
      </ng-select>
    </div>
  </div>
  <div class="form-group row"
    *ngIf="!isMileage && jobTypeId === JobTypes.Packer">
    <label for="title"
      class="col-sm-2 text-right control-label col-form-label">Billing Code</label>
    <div class="col-sm-10 form-group mb-0"
      *ngIf="bcService.pkrTlSupBCList | async; let bc">
      <ng-select [items]="bc"
        bindLabel="dropDownDisplay"
        bindValue="billingCodeId"
        [(ngModel)]="model.billingCodeId"
        name="billingCode"
        [clearable]="false"
        required>
      </ng-select>
    </div>
  </div>
  <div class="form-group row">
    <label for="title"
      class="col-sm-2 text-right control-label col-form-label">Description</label>
    <div class="col-sm-10 form-group mb-0">
      <textarea class="form-control"
        [(ngModel)]="model.description"
        required
        [readonly]="isMileage"></textarea>
    </div>
  </div>
</div>
<div class="modal-footer"
  *ngIf="model">
  <button type="button"
    class="btn btn-primary"
    (click)="onSave()"
    [disabled]="disableSave
      || !(model.description
        &&model.workEnd
        &&model.workStart
        &&model.employeeId
        &&(model.workEnd>model.workStart)
           &&(jobTypeId === JobTypes.Fishing && (!isMileage || (isMileage&&model.billingCodeId&&model.mileage))
              || (jobTypeId === JobTypes.Packer && model.billingCodeId && (!isMileage || (isMileage&&model.mileage))))
           )">Save</button>
  <button type="button"
    class="btn btn-default"
    (click)="bsModalRef.hide()">Cancel</button>
</div>
