import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable, Observer } from 'rxjs';
import { map } from 'rxjs/operators';
import { BhalineModel } from 'src/models/bha-line.model';
import { JobService } from './job.service';

@Injectable()
export class BhaLineService {
  constructor(
    private http: HttpClient,
    @Inject('BASE_URL') private baseUrl: string,
    private jobService: JobService
  ) { }

  private apiUrl = 'api/BhaLine';  // URL to web api

  public addEditBhaLine(
    dto: BhalineModel,
    jobId: number = null): Observable<boolean> {
    return new Observable((observer: Observer<boolean>) => {
      this.http.post<boolean>(this.baseUrl + this.apiUrl + '/AddEditBhaLine', this.cleanBhaLineModel(dto))
        .subscribe((x: boolean) => {
          if (x === true && jobId !== null) {
            this.jobService.refreshJob(jobId);
          }
          observer.next(x);
        });
    });
  }

  public deleteBhaLine(
    id: number,
    jobId: number = null): Observable<boolean> {
    return new Observable((observer: Observer<boolean>) => {
      this.http.get<boolean>(this.baseUrl + this.apiUrl + '/DeleteBhaLine/' + id)
        .subscribe((x: boolean) => {
          if (x === true && jobId !== null) {
            this.jobService.refreshJob(jobId);
          }
          observer.next(x);
        });
    });
  }

  public getBhaLineById(id: number): Observable<BhalineModel> {
    return this.http.get<BhalineModel>(this.baseUrl + this.apiUrl + '/GetBhaLineById/' + id);
  }

  public generateNewBhaLineModel(bhaId: number): Observable<BhalineModel> {
    return this.http.get<number>(this.baseUrl + this.apiUrl + '/GetLastBhaLineNumberByBhaId/' + bhaId)
      .pipe(
        map((x: number) => {
          return new BhalineModel(bhaId, x + 1);
        })
      );
  }

  private cleanBhaLineModel(model: BhalineModel): BhalineModel {
    Object.keys(model).forEach((index: string) => {
      if (typeof model[index] === 'string') {
        model[index] = model[index]
          && model[index].trim() !== '' ?
          model[index].trim()
          : null;
      }
    });

    return model;
  }

}
