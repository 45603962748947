import { BehaviorSubject, Observable, Observer } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { SearchDataModel } from 'src/models/search-data.model';
import { SearchService } from './search.service';
import { Shops } from '../enums/shops';
import { tap } from 'rxjs/operators';
import { UserFilterParamsModel } from '../models/RequestParams/user-filter-params.model';
import { UserModel } from '../models/user.model';
import { RoleWithUsersModel } from 'src/models/role-with-users';

@Injectable()
export class UserService {
  constructor(
    private http: HttpClient,
    @Inject('BASE_URL') private baseUrl: string,
    private searchService: SearchService
  ) { }

  public userList: BehaviorSubject<Array<UserModel>> = new BehaviorSubject(null);
  public handList: BehaviorSubject<Array<UserModel>> = new BehaviorSubject(null);
  public user: BehaviorSubject<UserModel> = new BehaviorSubject(null);
  public roleList: BehaviorSubject<Array<string>> = new BehaviorSubject(null);
  public rollsWithUsersList: BehaviorSubject<Array<RoleWithUsersModel>> = new BehaviorSubject(null);

  public openJobUserSubStatuses: number[] = null;
  public userFilterParams: UserFilterParamsModel = new UserFilterParamsModel();
  public passwordFormatRegex = new RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$^+=!*()@%&]).{8,}$/);
  public Shops = Shops;
  public initialLoad = true;
  public usersAreLoading: boolean = false;

  private apiUrl = 'api/User';  // URL to web api

  public refreshUser(clear: boolean = false): void {
    if (this.user.value === null || clear) {
      this.getUserInfo()
        .subscribe((x: UserModel) => {
          if (x !== null) {
            this.openJobUserSubStatuses = this.initialLoad ? x.jobSubStatusIds : this.openJobUserSubStatuses;
            this.initialLoad = false;
          }
          this.user.next(x);
        });
    }
  }

  public getUserInfo(): Observable<UserModel> {
    return this.http.get<UserModel>(this.baseUrl + this.apiUrl + '/GetUserInfo');
  }

  public getUserInfoById(id: number): Observable<UserModel> {
    return this.http.get<UserModel>(this.baseUrl + this.apiUrl + '/GetUserInfoById/' + id);
  }

  public getUsers(
    clear: boolean = false,
    showSkeleton: boolean = false): void {
    if (!this.userList.value || clear) {
      if (showSkeleton) {
        this.usersAreLoading = true;
      }

      this.http.post<Array<UserModel>>(this.baseUrl + this.apiUrl + '/GetAllUsers', this.userFilterParams)
        .pipe(tap((x: Array<UserModel>) => {
          x.forEach((u: UserModel) => {
            u.displayName = u.lastName !== null && u.lastName !== undefined && u.lastName.trim() !== '' ? u.lastName : '';
            u.displayName += u.firstName !== null && u.firstName !== undefined && u.firstName.trim() !== '' ? ', ' + u.firstName : '';

            u.roleMapDisplayName = u.firstName && u.lastName ? u.firstName + ' ' + u.lastName + ' - (' + u.userName + ')' : u.userName;
          });
        }))
        .subscribe((x: UserModel[]) => {
          this.userList.next(x);
          this.usersAreLoading = false;
        });
    }
  }

  public getHands(
    jobTypeId: number,
    userId: number = null): void {
    let qry = userId !== null ? '?userId=' + userId : '';

    this.http.get<Array<UserModel>>(this.baseUrl + this.apiUrl + '/GetHands/' + jobTypeId + qry)
      .pipe(tap((x: Array<UserModel>) => {
        x.forEach((u: UserModel) => {
          u.displayName = u.lastName !== null && u.lastName !== undefined && u.lastName.trim() !== '' ? u.lastName : '';
          u.displayName += u.firstName !== null && u.firstName !== undefined && u.firstName.trim() !== '' ? ', ' + u.firstName : '';
        });
      }))
      .subscribe((x: UserModel[]) => {
        this.handList.next(x);
      });
  }

  public getRolesForDropdown(clear: boolean = false): void {
    if (!this.roleList.value || clear) {
      this.http.get<Array<string>>(this.baseUrl + this.apiUrl + '/GetRolesForDropdown')
        .subscribe((x: string[]) => {
          this.roleList.next(x);
        });
    }
  }

  public toggleUserIsActive(id: number): Observable<boolean> {
    return new Observable((observer: Observer<boolean>) => {
      this.http.get<boolean>(this.baseUrl + this.apiUrl + '/ToggleUserIsActive/' + id)
        .subscribe((x: boolean) => {
          if (x !== null) {
            this.getUsers(true);
          }
          observer.next(x);
        });
    });
  }

  public addSearchResult(
    result: SearchDataModel,
    userId: number): void {
    this.http.post<void>(this.baseUrl + this.apiUrl + '/AddSearchResult/' + userId, result)
      .subscribe((x: void) => {

      });
  }

  public getRecentSearchesByUserId(id: number): void {
    this.http.get<SearchDataModel[]>(this.baseUrl + this.apiUrl + '/GetRecentSearchesByUserId/' + id)
      .subscribe((x: SearchDataModel[]) => {
        this.searchService.next(x);
      });
  }

  public getRolesWithUsers(): void {
    this.http.get<Array<RoleWithUsersModel>>(this.baseUrl + this.apiUrl + '/GetRolesWithUsers')
      .subscribe((x: Array<RoleWithUsersModel>) => {
        this.rollsWithUsersList.next(x);
      });
  }

  public addEditUser(dto: UserModel): Observable<boolean> {
    dto = this.cleanUserModel(dto);

    return new Observable((observer: Observer<boolean>) => {
      this.http.post<number>(this.baseUrl + this.apiUrl + '/AddEditUser', dto)
        .subscribe((x: number) => {
          if (x !== -1) {
            this.getUsers(true);
            if (this.user.value.id === dto.id) {
              this.refreshUser(true);
            }
          }
          observer.next(x !== -1);
        });
    });
  }

  public clearFilterParams(): void {
    this.userFilterParams.showInactive = false;
    this.userFilterParams.roles.length = 0;

    this.getUsers(true);
  }

  public generateNewUserModel(): UserModel {
    let model: UserModel = new UserModel();
    model.poLimit = 0;
    model.defaultShopId = this.Shops.Williston;
    model.twoFactorEnabled = false;
    model.isActive = true;

    return model;
  }

  public verifyPassword(password: string): boolean {
    return this.passwordFormatRegex.test(password);
  }

  private cleanUserModel(model: UserModel): UserModel {
    Object.keys(model).forEach((index: string) => {
      if (typeof model[index] === 'string') {
        if (index !== 'recentSearches'
          && index !== 'password') {
          model[index] = model[index]
            && model[index].trim() !== '' ?
            model[index].trim()
            : null;
        }
      }
    });

    return model;
  }

}
