import { BehaviorSubject, Observable, Observer } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { UnitOfMeasureModel } from '../models/unit-of-measure.model';

@Injectable()
export class UnitOfMeasureService {
  constructor(
    private http: HttpClient,
    @Inject('BASE_URL') private baseUrl: string
  ) { }

  private apiUrl = 'api/UnitOfMeasure';  // URL to web api

  private uomListSubject = new BehaviorSubject<UnitOfMeasureModel[]>(null);
  public _uomList$ = this.uomListSubject.asObservable();
  private isUomCallInProgress = false;
  public uomList: BehaviorSubject<UnitOfMeasureModel[]> = new BehaviorSubject(null);

  public get uomList$(): Observable<UnitOfMeasureModel[]> {
    if (!this.uomListSubject.getValue() && !this.isUomCallInProgress) {
      this.isUomCallInProgress = true;
      this.getUnitsOfMeasure$(false).subscribe(() => this.isUomCallInProgress = false);
    }

    return this._uomList$;
  }

  public addEditUnitOfMeasure(dto: UnitOfMeasureModel): Observable<boolean> {
    dto = this.cleanUomModel(dto);

    return new Observable((observer: Observer<boolean>) => {
      this.http.post<boolean>(this.baseUrl + this.apiUrl + '/AddEditUnitOfMeasure', dto)
        .subscribe((x: boolean) => {
          if (x === true) {
            this.getUnitsOfMeasure();
          }
          observer.next(x);
        });
    });
  }

  public deleteUnitOfMeasure(id: number): Observable<boolean> {
    return new Observable((observer: Observer<boolean>) => {
      this.http.get<boolean>(this.baseUrl + this.apiUrl + '/DeleteUnitOfMeasure/' + id)
        .subscribe((x: boolean) => {
          if (x === true) {
            this.getUnitsOfMeasure();
          }
          observer.next(x);
        });
    });
  }

  public getUnitsOfMeasure(showInactive: boolean = false): void {
    this.http.get<UnitOfMeasureModel[]>(this.baseUrl + this.apiUrl + '/GetUnitsOfMeasure/' + showInactive)
      .subscribe((x: UnitOfMeasureModel[]) => {
        this.uomList.next(x);
      });
  }

  public getUnitOfMeasureById(id: number): Observable<UnitOfMeasureModel> {
    return this.http.get<UnitOfMeasureModel>(this.baseUrl + this.apiUrl + '/GetUnitOfMeasureById/' + id);
  }

  public generateUomModel(): UnitOfMeasureModel {
    let model: UnitOfMeasureModel = new UnitOfMeasureModel();

    model.isActive = true;

    return model;
  }

  public generateNewUomModel(): UnitOfMeasureModel {
    let model: UnitOfMeasureModel = new UnitOfMeasureModel();

    model.isActive = true;

    return model;
  }

  private getUnitsOfMeasure$(showInactive: boolean): Observable<UnitOfMeasureModel[]> {
    return this.http.get<Array<UnitOfMeasureModel>>(this.baseUrl + this.apiUrl + '/GetUnitsOfMeasure/' + showInactive)
      .pipe(
        tap((units: UnitOfMeasureModel[]) => {
          this.uomListSubject.next(units);
        })
      );
  }

  private cleanUomModel(model: UnitOfMeasureModel): UnitOfMeasureModel {
    Object.keys(model).forEach((index: string) => {
      if (typeof model[index] === 'string') {
        model[index] = model[index]
          && model[index].trim() !== '' ?
          model[index].trim()
          : null;
      }
    });

    return model;
  }

}
