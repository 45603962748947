import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ItemService } from './item.service';
import { UserService } from './user.service';
import { InventoryCountItemModel } from 'src/models/inventory-count-item.model';
import { InventoryItemCountSearchParamsModel } from 'src/models/RequestParams/inventory-item-count-search-params.model';
import { InventoryCountSummaryModel } from 'src/models/inventory-count-summary.model';

@Injectable()
export class InventoryCountItemService {
  constructor(private http: HttpClient,
    @Inject('BASE_URL') private baseUrl: string,
    public itemService: ItemService,
    public userService: UserService
  ) { }

  public inventoryCountItemList: BehaviorSubject<Array<InventoryCountItemModel>> = new BehaviorSubject(null);
  // eslint-disable-next-line max-len
  public differenceCounts: BehaviorSubject<InventoryCountSummaryModel> = new BehaviorSubject(null);
  public filterDto: InventoryItemCountSearchParamsModel = new InventoryItemCountSearchParamsModel();
  public setToFirstPage: BehaviorSubject<boolean> = new BehaviorSubject(null);
  public countIsLoading: boolean = false;

  private apiUrl = 'api/InventoryCountItem';  // URL to web api
  private countApiUrl = 'api/InventoryCount';  // URL to web api

  public getInventoryCountItems(
    inventoryCountId: number,
    showSkeleton: boolean = false): void {

    if (showSkeleton) {
      this.countIsLoading = true;
    }

    const url = this.baseUrl + this.apiUrl + '/GetInventoryCountItems/' + inventoryCountId;

    this.http.post<Array<InventoryCountItemModel>>(url, this.filterDto)
      .subscribe((x: InventoryCountItemModel[]) => {
        this.inventoryCountItemList.next(x);

        this.countIsLoading = false;

        this.setToFirstPage.next(true);

        this.updateInventoryValues(inventoryCountId);
      });
  }

  public updateInventoryValues(inventoryCountId: number): void {
    const url = this.baseUrl + this.countApiUrl + '/GetInventorySummaryData/' + inventoryCountId;

    this.http.get<InventoryCountSummaryModel>(url)
      .subscribe((summary: InventoryCountSummaryModel) => {
        this.differenceCounts.next(summary);
      });
  }

  public updateItemCount(dto: InventoryCountItemModel): Observable<boolean> {
    return this.http.post<boolean>(this.baseUrl + this.apiUrl + '/UpdateItemCount', dto);
  }

  public clearFilterParams(showInactive: boolean, inventoryCountId: number): void {
    Object.keys(this.filterDto).forEach((index: string) => {
      if (index === 'countedOnly' || index === 'uncountedOnly' || index === 'negativeOnly') {
        this.filterDto[index] = false;
      } else if (index === 'excludeZeroCount') {
        this.filterDto[index] = true;
      } else {
        this.filterDto[index] = null;
      }
    });

    this.getInventoryCountItems(inventoryCountId);
  }

  public getNumberOfActiveFilterFields(): number {
    let count: number = 0;

    Object.values(this.filterDto).forEach((value: any) => {

      count = value !== null && value !== false && (!Array.isArray(value) || (Array.isArray(value) && value.length > 0)) ? count += 1 : count;
    });

    return count;
  }

}
