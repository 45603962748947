import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable, Observer } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { BhaModel } from 'src/models/bha.model';
import { JobService } from './job.service';
import { TimezoneService } from './timezone.service';

@Injectable()
export class BhaService {
  constructor(
    private http: HttpClient,
    @Inject('BASE_URL') private baseUrl: string,
    private jobService: JobService,
    private timeZoneService: TimezoneService
  ) { }

  private apiUrl = 'api/Bha';  // URL to web api

  public addEditBha(
    dto: BhaModel,
    refreshJob: boolean = true): Observable<boolean> {
    return new Observable((observer: Observer<boolean>) => {
      this.http.post<boolean>(this.baseUrl + this.apiUrl + '/AddEditBha', this.cleanBhaModel(dto))
        .subscribe((x: boolean) => {
          if (x === true) {
            if (refreshJob === true) {
              this.jobService.refreshJob(dto.jobId);
            }
          }
          observer.next(x);
        });
    });
  }

  public getBhaById(id: number): Observable<BhaModel> {
    return this.http.get<BhaModel>(this.baseUrl + this.apiUrl + '/GetBhaById/' + id)
      .pipe(tap((x: BhaModel) => {
        x.date = x.date !== undefined
          && x.date !== null ?
          new Date(x.date)
          : null;
      }));
  }

  public deleteBha(
    dto: BhaModel,
    refreshJob: boolean = true): Observable<boolean> {
    return new Observable((observer: Observer<boolean>) => {
      this.http.get<number>(this.baseUrl + this.apiUrl + '/DeleteBha/' + dto.bhaId)
        .subscribe((x: number) => {
          if (x !== -1) {
            if (refreshJob === true) {
              this.jobService.refreshJob(dto.jobId);
            }
          }
          observer.next(x !== -1);
        });
    });
  }

  public generateNewBhaModel(jobId: number): Observable<BhaModel> {
    return this.http.get<number>(this.baseUrl + this.apiUrl + '/GetLastBhaNumberByJobId/' + jobId)
      .pipe(
        map((x: number) => {
          return new BhaModel(0, x + 1, new Date(new Date().setHours(0, 0, 0, 0)), jobId);
        })
      );
  }

  public toggleBhaDay(
    dayId: number,
    bhaId: number,
    jobId: number): Observable<boolean> {
    return new Observable((observer: Observer<boolean>) => {
      this.http.get<boolean>(this.baseUrl + this.apiUrl + '/ToggleBhaDay/' + dayId + '/' + bhaId)
        .subscribe((x: boolean) => {
          if (x === true) {
            this.jobService.refreshJob(jobId, true); // if check fails refresh with accurate data
          }
          observer.next(x);
        });
    });
  }

  private cleanBhaModel(model: BhaModel): BhaModel {
    Object.keys(model).forEach((index: string) => {
      if (typeof model[index] === 'string') {
        model[index] = model[index]
          && model[index].trim() !== '' ?
          model[index].trim()
          : null;
      } else if (model[index] instanceof Date
        && !isNaN(model[index])) {
        model[index] = this.timeZoneService.correctOffset(model[index]);
      }
    });

    return model;
  }

}
