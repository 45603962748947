<div class="modal-header"
  ngxModalDraggable>
  <h4 class="modal-title pull-left">{{isNew ? 'Add' : 'Edit'}}&nbsp;Worksheet Row Item Type</h4>
  <button type="button"
    class="close pull-right"
    aria-label="Close"
    (click)="bsModalRef.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<form (ngSubmit)="onSave()"
  #heroForm="ngForm">
  <div class="modal-body"
    *ngIf="model">
    <div class="form-group row">
      <label for="title"
        class="col-sm-2 text-right control-label col-form-label">Worksheet</label>
      <div class="col-sm-10 form-group mb-0">
        <input type="text"
          class="form-control form-control-sm"
          id="worksheetTypeName"
          [(ngModel)]="model.worksheetTypeName"
          name="worksheetTypeName"
          readonly>
      </div>
    </div>
    <div class="form-group row">
      <label for="title"
        class="col-sm-2 text-right control-label col-form-label">Row</label>
      <div class="col-sm-10 form-group mb-0">
        <input type="text"
          class="form-control form-control-sm"
          id="worksheetRowTypeName"
          [(ngModel)]="model.worksheetRowTypeName"
          name="worksheetRowTypeName"
          readonly>
      </div>
    </div>
    <div class="form-group row">
      <label for="title"
        class="col-sm-2 text-right control-label col-form-label">Name</label>
      <div class="col-sm-10 form-group mb-0">
        <input type="text"
          class="form-control form-control-sm"
          id="name"
          [(ngModel)]="model.name"
          name="name"
          required>
      </div>
    </div>
    <div class="form-group row">
      <label for="title"
        class="col-sm-2 text-right control-label col-form-label">Order</label>
      <div class="col-sm-10 form-group mb-0">
        <input type="number"
          class="form-control form-control-sm"
          id="order"
          [(ngModel)]="model.sortOrder"
          name="sortOrder"
          required>
      </div>
    </div>
    <div class="form-group row">
      <label for="title"
        class="col-sm-2 text-right control-label col-form-label">Item</label>
      <div class="col-sm-10 form-group mb-0"
        *ngIf="itemService.itemDropdownList | async; let item">
        <div class="input-group">
          <ng-select [items]="item"
            bindLabel="dropdownDisplay"
            bindValue="itemId"
            [(ngModel)]="model.itemId"
            class="col-md-9"
            style="padding:0px;"
            (change)="onChangeOfItem($event)"
            [disabled]="model.isNote"
            name="items">
          </ng-select>
          <div class="input-group-append col-md-3"
            style="padding: 0;width:100%">
            <span class="input-group-text"
              style="padding: 0px;"><button (click)="openItemModal(0, $event)"
                style="display: inline-flex;padding: 4px 10px;width:100%"
                class="btn btn-sm btn-default"><i class="fa fa-plus"
                  style="position: relative; top: 3px; margin-right: 5px;"></i>&nbsp;New</button></span>
          </div>
        </div>
      </div>
    </div>
    <div class="form-group row">
      <label for="title"
        class="col-sm-2 text-right control-label col-form-label">Billing Code</label>
      <div class="col-sm-10 form-group mb-0">
        <div class="input-group">
          <ng-select [items]="billingCodes"
            bindLabel="dropDownDisplay"
            bindValue="billingCodeId"
            [ngModel]="model.billingCodeId"
            name="billingCodes"
            class="col-md-9"
            style="padding:0px;"
            (change)="onChangeOfBillingCode($event)"
            [disabled]="billingCodes.length === 0 || model.isNote">
          </ng-select>
          <div class="input-group-append col-md-3"
            style="padding: 0;width:100%">
            <span class="input-group-text"
              style="padding: 0px;"><button id="new-bc"
                (click)="openBillingCodeModal(0, $event)"
                style="display: inline-flex;padding: 4px 10px;width:100%"
                class="btn btn-sm btn-default"><i class="fa fa-plus"
                  style="position: relative; top: 3px; margin-right: 5px;"></i>&nbsp;New</button></span>
          </div>
        </div>
      </div>
    </div>
    <div class="form-group row">
      <label for="title"
        class="col-sm-2 text-right control-label col-form-label">No Charge</label>
      <div class="col-sm-2 form-group mb-0">
        <ui-switch [(ngModel)]="model.zeroCharge"
          name="zeroCharge"
          [disabled]="model.isNote"></ui-switch>
      </div>
      <label for="title"
        class="col-sm-2 text-right control-label col-form-label">Is Note</label>
      <div class="col-sm-2 form-group mb-0">
        <ui-switch [ngModel]="model.isNote"
          name="isNote"
          (change)="onChangeOfIsNote($event)"></ui-switch>
      </div>
    </div>
    <div class="form-group row">
      <label for="title"
        class="col-sm-2 text-right control-label col-form-label">Is Option</label>
      <div class="col-sm-2 form-group mb-0">
        <ui-switch [(ngModel)]="model.isOption"
          name="isOption"
          [disabled]="model.isNote"></ui-switch>
      </div>
      <ng-container>
        <label for="title"
          class="col-sm-4 text-right control-label col-form-label">Charge Itemized Option</label>
        <div class="col-sm-2 form-group mb-0">
          <ui-switch [ngModel]="model.chargeItemizedOption"
            name="chargeItemizedOption"
            (change)="onChangeOfChargeItemizedOption($event)"
            [disabled]="model.isNote"></ui-switch>
        </div>
      </ng-container>
    </div>
    <div class="form-group row"
      *ngIf="model && model.chargeItemizedOption">
      <label for="title"
        class="col-sm-2 text-right control-label col-form-label">Option Billing Code</label>
      <div class="col-sm-10 form-group mb-0">
        <div class="input-group">
          <ng-select [items]="billingCodes"
            bindLabel="dropDownDisplay"
            bindValue="billingCodeId"
            [(ngModel)]="model.optionBillingCodeId"
            name="optionBillingCodes"
            class="col-md-12"
            style="padding:0px;"
            [disabled]="billingCodes.length === 0">
          </ng-select>
        </div>
      </div>
    </div>
  </div>
</form>
<div class="modal-footer"
  *ngIf="model">
  <button type="button"
    class="btn btn-primary"
    (click)="onSave()"
    [disabled]="disableSave || !(model.name && model.sortOrder)">Save</button>
  <button type="button"
    class="btn btn-default"
    (click)="bsModalRef.hide()">Cancel</button>
</div>
