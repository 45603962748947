import { WorkLogModel } from './work-log.model';

export class DayModel {
  constructor(
    dayId:number, 
    dayNumber:number, 
    date: Date, 
    jobId: number, 
    cumulativeWellCost: number, 
    dailyWellCost: number = 0, 
    isActive: boolean = true, 
    dateUsed: boolean = false) {
    this.dayId = dayId;
    this.dayNumber = dayNumber;
    this.date = date;
    this.jobId = jobId;
    this.isActive = isActive;
    this.cumulativeWellCost = cumulativeWellCost;
    this.dailyWellCost = dailyWellCost;
    this.dateUsed = dateUsed;
  }

  dayId: number;
  dayNumber: number;
  date: Date | string;
  jobId: number;
  progressOfWork: string;
  dailyWellCost: number | null;
  cumulativeWellCost: number | null;
  isActive: boolean | null;

  jobItemInstanceIds: number[];
  workLogs: WorkLogModel[];
  bhasUsed: boolean | null;
  parsedDate: Date;
  dateUsed: boolean = false;
}
