<div class="modal-header"
  ngxModalDraggable>
  <h4 class="modal-title pull-left"
    *ngIf="model">{{isNew ? 'Add' : 'Edit'}}&nbsp;PO<span *ngIf="!isNew">:&nbsp;{{model.ponumberDisplay}}</span></h4>
  <button type="button"
    class="close pull-right"
    aria-label="Close"
    (click)="bsModalRef.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body"
  *ngIf="model">
  <div class="form-group row">
    <label for="title"
      class="col-sm-2 text-right control-label col-form-label">Vendor</label>
    <div class="col-sm-10 form-group mb-0"
      *ngIf="vendorService.vendorList | async; let vend">
      <div class="input-group">
        <ng-select [items]="vend"
          bindLabel="name"
          bindValue="vendorId"
          [ngModel]="model.vendorId"
          (change)="updateVendorName($event)"
          name="vendors"
          class="col-md-9"
          style="padding:0px;"
          required>
        </ng-select>
        <div class="input-group-append col-md-3"
          style="padding:0;width:100%;">
          <span class="input-group-text"
            style="padding: 0px;"><button (click)="openVendorModal(0)"
              style="display: inline-flex;padding: 4px 10px;width:100%"
              class="btn btn-sm btn-default"><i class="fa fa-plus"
                style="position: relative; top: 3px; margin-right: 5px;"></i>&nbsp;New Vendor</button></span>
        </div>
      </div>
    </div>
  </div>
  <div class="form-group row">
    <label for="title"
      class="col-sm-2 text-right control-label col-form-label">Description</label>
    <div class="col-sm-10 form-group mb-0">
      <textarea type="text"
        class="form-control form-control-sm"
        id="description"
        [(ngModel)]="model.description"
        name="description"></textarea>
    </div>
  </div>
  <div class="form-group row">
    <label for="title"
      class="col-sm-2 text-right control-label col-form-label">PO Date</label>
    <div class="col-sm-10 form-group mb-0">
      <input type="text"
        placeholder="mm/dd/yyyy"
        class="form-control"
        [(ngModel)]="model.podate"
        required
        bsDatepicker>
    </div>
  </div>
  <div class="form-group row">
    <label for="title"
      class="col-sm-2 text-right control-label col-form-label">Ship Method</label>
    <div class="col-sm-10 form-group mb-0">
      <input type="text"
        class="form-control form-control-sm"
        id="shipMethod"
        [(ngModel)]="model.shipMethod"
        name="shipMethod">
    </div>
  </div>
  <div class="form-group row">
    <label for="title"
      class="col-sm-2 text-right control-label col-form-label">Delivery Date</label>
    <div class="col-sm-10 form-group mb-0">
      <input type="text"
        placeholder="mm/dd/yyyy"
        class="form-control"
        [(ngModel)]="model.deliveryDate"
        bsDatepicker>
    </div>
  </div>
  <div class="form-group row">
    <label for="title"
      class="col-sm-2 text-right control-label col-form-label">Shop</label>
    <div class="col-sm-10 form-group mb-0"
      *ngIf="shops">
      <ng-select [items]="shops"
        bindLabel="name"
        bindValue="shopId"
        [ngModel]="model.shopId"
        [clearable]="false"
        (change)="onShopChange($event)"
        name="shops">
      </ng-select>
    </div>
  </div>
  <div class="form-group row">
    <label for="title"
      class="col-sm-2 text-right control-label col-form-label">Account</label>
    <div class="col-sm-10 form-group mb-0"
      *ngIf="accounts">
      <ng-select [items]="accounts"
        bindLabel="name"
        bindValue="accountId"
        [ngModel]="model.accountId"
        [clearable]="false"
        (change)="onAccountChange($event)"
        name="accounts">
      </ng-select>
    </div>
  </div>
  <div class="form-group row">
    <label for="title"
      class="col-sm-2 text-right control-label col-form-label">Notes</label>
    <div class="col-sm-10 form-group mb-0">
      <textarea type="text"
        class="form-control form-control-sm"
        id="ponotes"
        [(ngModel)]="model.ponotes"
        name="ponotes"></textarea>
    </div>
  </div>
  <div class="form-group row">
    <label for="title"
      class="col-sm-2 text-right control-label col-form-label">Job</label>
    <div class="col-sm-10 form-group mb-0">
      <div class="input-group">
        <ng-select [items]="jobs"
          bindLabel="dropdownDisplay"
          bindValue="jobId"
          [ngModel]="model.jobId"
          (change)="onJobChange($event)"
          name="jobs"
          style="padding:0px;width:100%">
        </ng-select>
      </div>
    </div>
  </div>
</div>
<div class="modal-footer"
  *ngIf="model">
  <div class="btn-group"
    dropdown>
    <button class="btn btn-primary dropdown-toggle"
      dropdownToggle
      id="button-basic"
      aria-controls="dropdown-basic"
      *ngIf="isNew&&model.podate&&model.vendorId">
      Save & Add Line
      <span class="caret"></span>
    </button>
    <ul id="dropdown-basic"
      *dropdownMenu
      class="dropdown-menu dropdown-menu-right"
      role="menu"
      aria-labelledby="button-basic">
      <li role="menuitem"><span class="dropdown-item"
          (click)="openInventoryLine=true;openMiscLine=false;onSave();"><i class="fal fa-inventory"></i>
          Inventory</span></li>
      <li role="menuitem"><span class="dropdown-item"
          (click)="openInventoryLine=false;openMiscLine=true;onSave();"><i class="fal fa-box"></i> Miscellaneous</span>
      </li>
    </ul>
  </div>
  <button type="button"
    class="btn btn-primary dropdown-toggle"
    *ngIf="isNew&&!(model.podate&&model.vendorId)"
    disabled>Save & Add Line&nbsp;<span class="caret"></span></button>
  <button type="button"
    class="btn btn-primary"
    (click)="openInventoryLine=false;openMiscLine=false;onSave();"
    [disabled]="disableSave || !(model.podate&&model.vendorId)">Save</button>
  <button type="button"
    class="btn btn-default"
    (click)="bsModalRef.hide()">Cancel</button>
</div>
