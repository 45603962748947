import { Component, OnDestroy, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { ThreadTypeModel } from 'src/models/thread-type.model';
import { ThreadTypeService } from 'src/services/thread-type.service';
import { UIService } from 'src/services/ui.service';

@Component({
  selector: 'app-add-edit-thread-type-modal',
  templateUrl: './add-edit-thread-type-modal.component.html',
  styleUrls: ['./add-edit-thread-type-modal.component.css']
})

export class AddEditThreadTypeModalComponent implements OnInit, OnDestroy {
  public isNew: boolean = false;
  public id: number = -1;
  public model: ThreadTypeModel;
  public showInactive: boolean = false;

  private modelSub: Subscription;
  private saveSub: Subscription;

  constructor(
    public bsModalRef: BsModalRef,
    public uiService: UIService,
    public threadTypeService: ThreadTypeService

  ) { };

  ngOnDestroy(): void {
    this.modelSub?.unsubscribe();

    this.saveSub?.unsubscribe();
  }

  ngOnInit(): void {
    this.isNew = this.id === 0;

    if (this.isNew) {
      this.model = this.threadTypeService.generateThreadTypeModel();
    } else {
      this.getModel();
    }
  }

  public getModel(): void {
    this.modelSub = this.threadTypeService.getThreadTypeById(this.id)
      .subscribe((x: ThreadTypeModel) => {
        this.model = x;
      });
  }

  public onSave(): void {
    this.saveSub = this.threadTypeService.addEditThreadType(this.model, this.showInactive)
      .subscribe((x: boolean) => {
        if (x) {
          this.bsModalRef.hide();
          this.uiService.showSuccess('Thread Type Saved', 'Success');
        } else {
          this.uiService.showError('Thread Type Saved', 'Error');
        }
      });
  }

}
