<div class="page-header">
  <div class="page-header-content header-elements-md-inline">
    <div class="page-title d-flex">
      <a [routerLink]="[returnUrl]"
        class="breadcrumb-link-color"
        *ngIf="userService.user.value && userService.user.value.activeRoles && userService.user.value.activeRoles.includes('General')">
        <h4><i class="icon-arrow-left52 mr-2"></i> <span class="font-weight-semibold">Packer Invoice</span> - Details
        </h4>
      </a>
      <a [routerLink]="['/']"
        class="breadcrumb-link-color"
        *ngIf="userService.user.value && userService.user.value.activeRoles && userService.user.value.activeRoles.includes('Packer Hand')">
        <h4><i class="icon-arrow-left52 mr-2"></i> <span class="font-weight-semibold">Packer Invoice</span> - Details
        </h4>
      </a>
      <button type="button"
        class="btn btn-outline-dark btn-sm ml-3 pl-1 pr-1 pt-0 pb-0 no-border"
        [title]="!showDetails ? 'Show Details' : 'Hide Details'"
        (click)="showDetails = !showDetails">
        <span *ngIf="!showDetails">
          <i class="fa-regular fa-chevrons-right"></i>&nbsp;Show </span>
        <span *ngIf="showDetails"><i class="fa-regular fa-chevrons-left"></i>&nbsp;Hide
        </span>Details</button>
    </div>
    <div class="header-elements d-none py-0 mb-3 mb-md-0">
      <div class="breadcrumb">
        <a href="index.html"
          class="breadcrumb-item"><i class="icon-home2 mr-2"></i> Invoice</a>
        <span class="breadcrumb-item active">Detail</span>
      </div>
    </div>
  </div>
</div>
<div class="page-content pt-0"
  *ngIf="jobService.job | async; let j">
  <div class="content-wrapper">
    <div class="content">
      <div class="row">
        <div class="col-lg-3"
          *ngIf="showDetails">
          <!-- LEFT HAND JOB SUMMARY CARD -->
          <div class="card">
            <div class="card-header bg-light header-elements-inline">
              <h6 class="card-title">Invoice#: {{j.jobNumber}}</h6>
              <div class="header-elements">
                <button class="btn btn-light btn-sm"
                  (click)="openJobModal(j.jobId)"
                  [disabled]="enableReorder || jobService.job.value !== null && jobService.job.value.jobStatusIsTerminal === true"><i
                    class="fa fa-edit"></i> Edit</button>
              </div>
            </div>
            <div class="card-body"
              [ngClass]="{'pr-2 pl-2':isTablet}">
              <div id="collapseBasic"
                [collapse]="isCollapsed">
                <app-job-info [job]="j"></app-job-info>
                <br />
              </div>
              <ng-container
                *ngIf="userService.user.value && userService.user.value.activeRoles && !userService.user.value.activeRoles.includes('General')">
                <div class="row">
                  <div class="col-md-12">
                    <app-sub-status-button-hands [job]="j"
                      [enableReorder]="enableReorder">
                    </app-sub-status-button-hands>
                  </div>
                </div>
              </ng-container>
              <ng-container
                *ngIf="userService.user.value && userService.user.value.activeRoles && userService.user.value.activeRoles.includes('General')">
                <div class="row">
                  <div class="col-md-12">
                    <button (click)="reportService.getPackerInvoicePDF(j.jobId)"
                      class="btn btn-light btn-sm btn-block"><i class="fal fa-file-pdf"></i> Print Invoice</button>
                    <app-sub-status-button [job]="j"
                      [enableReorder]="enableReorder"
                      *ngIf="jobService.job.value !== null && jobService.job.value.jobStatusId === JobStatuses.Open_Packer && (jobService.job.value.jobSubStatusId === JobSubStatuses.In_Progress || jobService.job.value.jobSubStatusId === JobSubStatuses.Pending_Approval || jobService.job.value.jobSubStatusId === JobSubStatuses.Ready_for_Signature)">
                    </app-sub-status-button>
                    <app-status-button-sales [job]="j"
                      [enableReorder]="enableReorder"
                      *ngIf="jobService.job.value !== null && (jobService.job.value.jobStatusId !== JobStatuses.Open_Packer|| (jobService.job.value.jobStatusId === JobStatuses.Open_Packer && jobService.job.value.jobSubStatusId === JobSubStatuses.Sent_for_Signature))">
                    </app-status-button-sales>
                  </div>
                </div>
              </ng-container>
            </div>
          </div>
          <!-- END LEFT HAND JOB SUMMARY CARD -->
          <ng-container
            *ngIf="userService.user.value && userService.user.value.activeRoles && userService.user.value.activeRoles.includes('General')">
            <app-job-po-display [purchaseOrders]="j.purchaseOrders"
              [disable]="j.jobStatusId !== 14"
              [jobId]="jobId"></app-job-po-display>
          </ng-container>
        </div>
        <div [ngClass]="{'col-lg-9': showDetails, 'col-lg-12': !showDetails}">
          <tabset [justified]="true"
            class="compact-nav">
            <!-- DAILY PACKER REPORT TAB -->
            <tab heading="Daily Packer Report"
              id="tab1">
              <div style="background-color: white;">
                <div class="d-flex flex-row-reverse mb-3"
                  *ngIf="j.days.length === 0">
                  <button class="btn btn-light btn-sm"
                    (click)="openJobFishingDayModal(0)"
                    [disabled]="j.invoiceHasBeenSplit || jobService.job.value !== null && jobService.job.value.jobStatusIsTerminal === true"><i
                      class="fas fa-plus"></i> New Day</button>
                </div>
                <div class="alert alert-primary mb-0"
                  role="alert"
                  *ngIf="j.days.length === 0">
                  No <strong>Days</strong> added for Job#: {{j.jobNumber}}. Hit the <strong><i class="fas fa-plus"></i>
                    Add</strong> button to create a new Day.
                </div>
                <button style="float: right;"
                  class="btn btn-light"
                  (click)="openJobFishingDayModal(0)"
                  *ngIf="j.days.length > 0"
                  [disabled]="j.invoiceHasBeenSplit || jobService.job.value !== null && jobService.job.value.jobStatusIsTerminal === true"><i
                    class="fas fa-plus"></i> New Day</button>
                <tabset type="pills"
                  class="prevent-tabset-default"
                  *ngIf="j.days.length > 0">
                  <tab heading="{{day.date | date:'shortDate'}}"
                    id="dayTab_{{i}}"
                    *ngFor="let day of j.days; let i = index;"
                    (selectTab)="tabSelect($event)"
                    [active]="'dayTab_' + i === activeTabId">
                    <div class="row">
                      <div class="col-sm-4"
                        [ngClass]="{'col-md-5':isTablet}">
                        <div class="card">
                          <div class="card-header bg-light header-elements-inline">
                            <h6 class="card-title">Summary Card</h6>
                            <div class="header-elements">
                              <button class="btn btn-danger btn-sm"
                                style="float: right"
                                [swal]="{ title: 'Delete Day ' + day.dayNumber +'?', text:formatDateAsString(day.date), showCancelButton: true, confirmButtonText: 'Delete', confirmButtonColor: '#DC3545' }"
                                (confirm)="deleteDay(day)"
                                [title]="'Delete Day ' + day.dayNumber"
                                [disabled]="j.invoiceHasBeenSplit || day.bhasUsed || day.workLogs.length > 0 || jobService.job.value !== null && jobService.job.value.jobStatusIsTerminal === true"><i
                                  class="fa fa-trash"></i></button>
                            </div>
                          </div>
                          <div class="card-body"
                            [ngClass]="{'pl-2 pr-2':isTablet}">
                            <dl class="row">
                              <dt class="col-sm-5">Date:</dt>
                              <dd class="col-sm-6">
                                <input type="text"
                                  placeholder="mm/dd/yyyy"
                                  class="form-control pl-0"
                                  style="height: 20px; border: 0;"
                                  [ngModel]="day.date"
                                  [bsConfig]="{ dateInputFormat: 'MMMM Do YYYY' }"
                                  (bsValueChange)="saveJobDateChange($event, day)"
                                  [datesDisabled]="currentJobDates"
                                  bsDatepicker>
                              </dd>
                              <dt class="col-sm-5">Daily Well Cost:</dt>
                              <dd class="col-sm-6">{{day.dailyWellCost | currency}}</dd>
                              <dt class="col-sm-5">Cumulative Well Cost:</dt>
                              <dd class="col-sm-6">{{day.cumulativeWellCost | currency}}<span class="text-primary"
                                  title="This number reflects the Cumulative Well Cost PRIOR to the invoice being edited."
                                  *ngIf="jobService.job.value !== null && jobService.job.value.jobStatusId !== JobStatuses.Open_Packer">&nbsp;&nbsp;<i
                                    class="fas fa-info-circle"></i></span><span class="text-primary"
                                  title="This number reflects the Cumulative Well Cost PRIOR to the Tool Supervisor Quantity being edited."
                                  *ngIf="jobService.job.value !== null && jobService.job.value.toolSupervisorQtyHasBeenEdited && jobService.job.value.jobStatusId === JobStatuses.Open_Packer">&nbsp;&nbsp;<i
                                    class="fas fa-info-circle"></i></span></dd>
                              <dt class="col-sm-12">Progress of Work</dt>
                              <dd class="col-sm-12">
                                <textarea name="pow"
                                  id="pow"
                                  class="form-control"
                                  [(ngModel)]="day.progressOfWork"
                                  (blur)="saveDay(day)"
                                  *ngIf="jobService.job.value !== null && jobService.job.value.jobStatusIsTerminal === false"></textarea>
                                <textarea name="pow"
                                  id="pow"
                                  class="form-control"
                                  [(ngModel)]="day.progressOfWork"
                                  *ngIf="jobService.job.value !== null && jobService.job.value.jobStatusIsTerminal === true"
                                  readonly></textarea>
                              </dd>
                            </dl>
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-8"
                        [ngClass]="{'col-md-7':isTablet}">
                        <div class="card">
                          <div class="card-header bg-light header-elements-inline">
                            <h6 class="card-title">Packers</h6>
                          </div>
                          <div class="card-body"
                            [ngClass]="{'pl-2 pr-2':isTablet}">
                            <app-table-skeleton [numberToIterate]="2"
                              *ngIf="worksheetService.worksheetList.value === null"></app-table-skeleton>
                            <div class="table-responsive table-condensed"
                              *ngIf="worksheetService.worksheetList | async; let ws">
                              <div class="alert alert-primary mb-0"
                                role="alert"
                                *ngIf="ws.length === 0">
                                No <strong>Packers</strong> added for Job#: {{j.jobNumber}}.
                              </div>
                              <table style="min-width:400px;width: 100%;"
                                class="table table-striped"
                                *ngIf="ws.length > 0">
                                <tbody>
                                  <tr *ngFor="let w of ws">
                                    <td style="width: 24px;">
                                      <input type="checkbox"
                                        [checked]="w.dayIds.includes(day.dayId)"
                                        (click)="toggleWorksheetDay(day.dayId, w.worksheetId)"
                                        [disabled]="jobService.job.value !== null && jobService.job.value.jobStatusIsTerminal === true">
                                    </td>
                                    <td style="width: 150px;">Rebuild #{{w.worksheetId}}</td>
                                    <td>{{w.description}}</td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <app-work-log *ngIf="j"
                      [logs]="day.workLogs"
                      [jobId]="jobId"
                      [dayId]="day.dayId"
                      [jobOwnerId]="j.jobOwnerId"
                      [dayDate]="day.date"
                      [jobTypeId]="j.jobTypeId">
                    </app-work-log>
                  </tab>
                </tabset>
              </div>
            </tab>
            <!-- END DAILY PACKER REPORT TAB -->
            <!-- JSA TAB -->
            <tab id="tab4">
              <app-jsa-tab-heading [jsaHealth]="jsaHealth"></app-jsa-tab-heading>
              <app-job-safety-analysis-tabset [jobId]="j.jobId"
                [jobDate]="j.jobDate"
                [jobStatusIsTerminal]="j.jobStatusIsTerminal"
                [jobNumber]="j.jobNumber"
                [jobTypeId]="j.jobTypeId"
                [wellRig]="j.well_Rig"
                [lastJobDay]="j.lastJobDay !== null ? j.lastJobDay : j.jobDate"
                [jobSubStatusId]="j.jobSubStatusId"></app-job-safety-analysis-tabset>
            </tab>
            <!-- END JSA TAB -->
            <!-- DAILY PACKER WORKSHEETS TAB -->
            <tab heading="Packer Worksheets"
              id="tab2">
              <div class="d-flex flex-row-reverse mb-3">
                <div class="btn-group d-inline-block"
                  dropdown>
                  <button class="btn btn-light btn-sm dropdown-toggle"
                    *ngIf="jobService.job.value !== null && jobService.job.value.jobStatusIsTerminal === true"
                    disabled>
                    <i class="fa fa-plus"></i> Add
                    <span class="caret"></span>
                  </button>
                  <button class="btn btn-light btn-sm dropdown-toggle"
                    dropdownToggle
                    id="button-basic"
                    aria-controls="dropdown-basic"
                    *ngIf="!(jobService.job.value !== null && jobService.job.value.jobStatusIsTerminal === true)">
                    <i class="fa fa-plus"></i> Add
                    <span class="caret"></span>
                  </button>
                  <ul id="dropdown-basic"
                    *dropdownMenu
                    class="dropdown-menu dropdown-menu-right"
                    role="menu"
                    aria-labelledby="button-basic">
                    <li role="menuitem"><span class="dropdown-item"
                        (click)="openBuiltPackerModal(j.jobId)"><i class="fal fa-user-helmet-safety"></i>Add Built
                        Packer</span></li>
                    <li role="menuitem"><span class="dropdown-item"
                        (click)="openChooseWorksheetModal()"><i class="fal fa-screwdriver"></i>Add New Packer</span>
                    </li>
                  </ul>
                </div>
              </div>
              <app-list-skeleton [numberToIterate]="2"
                *ngIf="worksheetService.worksheetList.value === null"></app-list-skeleton>
              <ng-container *ngIf="worksheetService.worksheetList | async; let ws">
                <div class="alert alert-primary mb-0"
                  role="alert"
                  *ngIf="ws.length === 0">
                  No <strong>Packers</strong> added for Job#: {{j.jobNumber}}. Hit the <strong><i
                      class="fas fa-plus"></i>
                    Add</strong> button to create a new Packer.
                </div>
              </ng-container>
              <app-worksheets-sm-list></app-worksheets-sm-list>
            </tab>
            <!-- END DAILY PACKER WORKSHEETS TAB -->
            <!--INVOICE TAB-->
            <tab heading="Invoice"
              id="tab3"
              [disabled]="enableReorder">
              <app-sales-invoice-table [job]="j"
                (enableReorderEvent)="handleEnableReorderEvent($event)"></app-sales-invoice-table>
              <div class="d-flex justify-content-end"
                *ngIf="j.totalStamp">
                <div class="card border-top-primary border-top-2 rounded-top-0 col-lg-3 col-md-5 col-sm-6"
                  style="border-top-color: #2196F3 !important;">
                  <div class="card-body">
                    <table style="float:right;">
                      <tr>
                        <td style="font-weight: 500;">Sub Total:</td>
                        <td width="20px">&nbsp;</td>
                        <td>{{j.subTotalStamp | currency}}</td>
                      </tr>
                      <tr>
                        <td style="font-weight: 500;">Tax&nbsp;<small>({{j.salesTaxRateStamp | percent:'0.2'}})</small>:
                        </td>
                        <td width="20px">&nbsp;</td>
                        <td>{{j.salesTaxTotalStamp | currency}}</td>
                      </tr>
                      <tr>
                        <td style="font-weight: 500;">TOTAL:</td>
                        <td width="20px">&nbsp;</td>
                        <td><strong>{{j.totalStamp | currency}}</strong></td>
                      </tr>
                    </table>
                  </div>
                </div>
              </div>
            </tab>
            <!--END INVOICE TAB -->
          </tabset>
        </div>
      </div>
    </div>
  </div>
</div>
<div *ngIf="!jobService.job.value"
  class="page-content pt-0">
  <div class="content-wrapper">
    <div class="content">
      <div class="row">
        <div class="col-lg-3">
          <div class="card">
            <div class="card-header bg-light header-elements-inline">
              <h6 class="card-title">Invoice</h6>
              <div class="header-elements">
                <button class="btn btn-light btn-sm"
                  [disabled]="true"><i class="fa fa-edit"></i> Edit</button>
              </div>
            </div>
            <div class="card-body">
              <app-list-skeleton [numberToIterate]="11"></app-list-skeleton>
            </div>
          </div>
        </div>
        <div class="col-lg-9">
          <tabset [justified]="true"
            class="compact-nav">
            <tab heading="Daily Packer Report"
              id="tab1"
              [disabled]="true">
              <div class="d-flex flex-row-reverse mb-3">
                <button class="btn btn-light"
                  [disabled]="true"><i class="fas fa-plus"></i> New Day</button>
              </div>
              <div class="row">
                <div class="col-sm-4"
                  [ngClass]="{'col-md-5':isTablet}">
                  <div class="card">
                    <div class="card-header bg-light header-elements-inline">
                      <h6 class="card-title">Summary Card</h6>
                      <div class="header-elements">
                        <button class="btn btn-danger btn-sm"
                          style="float: right"
                          [disabled]="true"><i class="fa fa-trash"></i></button>
                      </div>
                    </div>
                    <div class="card-body"
                      [ngClass]="{'pl-2 pr-2':isTablet}">
                      <app-list-skeleton [numberToIterate]="4"></app-list-skeleton>
                    </div>
                  </div>
                </div>
                <div class="col-sm-8"
                  [ngClass]="{'col-md-7':isTablet}">
                  <div class="card">
                    <div class="card-header bg-light header-elements-inline">
                      <h6 class="card-title">Packers</h6>
                    </div>
                    <div class="card-body"
                      [ngClass]="{'pl-2 pr-2':isTablet}">
                      <app-table-skeleton [numberToIterate]="2"></app-table-skeleton>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card">
                <div class="card-header bg-light header-elements-inline">
                  <h6 class="card-title">Operations Breakdown</h6>
                  <div class="header-elements">
                    <button class="btn btn-light btn-sm"
                      [disabled]="true"><i class="fas fa-plus"></i> Add</button>
                  </div>
                </div>
                <div class="card-body"
                  [ngClass]="{'pl-2 pr-2':isTablet}">
                  <div class="table-responsive">
                    <app-table-skeleton [numberToIterate]="2"></app-table-skeleton>
                  </div>
                </div>
              </div>
            </tab>
            <tab heading="JSA"
              id="tab4"
              [disabled]="true">
            </tab>
            <tab heading="Packer Worksheets"
              id="tab2"
              [disabled]="true">
            </tab>
            <tab heading="Invoice"
              id="tab3"
              [disabled]="true">
            </tab>
          </tabset>
        </div>
      </div>
    </div>
  </div>
</div>
