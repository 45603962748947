import { Component, Input, OnInit } from '@angular/core';
import { PageChangeEvent } from '@progress/kendo-angular-grid';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { BillingCodeService } from 'src/services/billing-code.service';
import { ItemService } from 'src/services/item.service';
import { KGridService } from 'src/services/k-grid.service';
import { UIService } from 'src/services/ui.service';
import { AddEditBillingCodeModalComponent } from '../add-edit-billing-code-modal/add-edit-billing-code-modal.component';

@Component({
  selector: 'app-billing-code-sm-list',
  templateUrl: './billing-code-sm-list.component.html',
})

export class BillingCodeSmallListComponent implements OnInit {
  constructor(
    private modalService: BsModalService,
    public billingCodeService: BillingCodeService,
    public itemService: ItemService,
    public kGridService: KGridService,
    public uiService: UIService,
  ) { };

  @Input() showAdditionalDayColumn: boolean;

  public bsModalRef: BsModalRef;

  ngOnInit() {

  }

  public openBillingCodeModal(billingCodeId: number): void {
    const initialState = {
      billingCodeId: billingCodeId,
      showInactive: false,
      isInventoryItem: true,
      launchedFromList: false,
      itemId: this.itemService.item.value.itemId,
      getByItem: true
    };
    this.bsModalRef = this.modalService.show(AddEditBillingCodeModalComponent, { initialState, backdrop: 'static' });
  }

  public kGridParams: any =
    {
      buttonCount: 5,
      info: true,
      type: '\'numeric\' | \'input\' = \'numeric\'',
      pageSizes: true,
      previousNext: true,
      pageSize: 10,
      skip: 0
    };

  public pageChange({ skip, take }: PageChangeEvent): void {
    this.kGridParams.skip = skip;
    this.kGridParams.pageSize = take;
  }

}
