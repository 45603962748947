import { Component, OnDestroy, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject, Subscription } from 'rxjs';
import { ShopModel } from 'src/models/shop.model';
import { UIService } from 'src/services/ui.service';

@Component({
  selector: 'app-super-admin-edit-confirm-modal',
  templateUrl: './super-admin-edit-confirm-modal.component.html',
  styleUrls: ['./super-admin-edit-confirm-modal.component.css']
})

export class SuperAdminEditConfirmModalComponent implements OnInit, OnDestroy {
  public isNew: boolean = false;
  public id: number = -1;
  public model: ShopModel;

  private modelSub: Subscription;
  private saveSub: Subscription;

  public onClose: Subject<boolean>;

  constructor(
    public bsModalRef: BsModalRef,
    public uiService: UIService,

  ) { };

  ngOnDestroy(): void {
    this.modelSub?.unsubscribe();

    this.saveSub?.unsubscribe();
  }

  ngOnInit(): void {
    this.onClose = new Subject();
  }

  public onConfirm(): void {
    this.onClose.next(true);
    this.bsModalRef.hide();
  }

  public onCancel(): void {
    this.onClose.next(false);
    this.bsModalRef.hide();
  }

}
