<div class="modal-header"
  ngxModalDraggable>
  <h4 class="modal-title pull-left">{{isNew ? 'Add' : 'Edit'}}&nbsp;PO Line</h4>
  <button type="button"
    class="close pull-right"
    aria-label="Close"
    (click)="bsModalRef.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<ng-container
  *ngIf="{ itemList: itemService.itemDropdownList | async, po: poService.purchaseOrder | async } as observables">
  <ng-container *ngIf="model && poTypes">
    <div class="modal-body"
      *ngIf="{ isNotPOVendorItem: ((model.itemId > 0) && ((observables.itemList | firstOrDefault: 'itemId' : model.itemId)?.vendorId !== observables.po.vendorId)) } as variables ">
      <div class="form-group row">
        <label for="title"
          class="col-sm-2 text-right control-label col-form-label">Segment</label>
        <div class="col-sm-4 form-group mb-0">
          <ng-select [items]="poTypes"
            bindLabel="name"
            bindValue="potypeId"
            [(ngModel)]="model.potypeId"
            [disabled]="disableTopFields"
            name="types"
            required>
          </ng-select>
        </div>
        <label for="title"
          class="col-sm-2 text-right control-label col-form-label">PO Line Status</label>
        <div class="col-sm-4 form-group mb-0"
          *ngIf="statusService.statusList | async; let stat">
          <ng-select [items]="stat"
            bindLabel="name"
            bindValue="postatusId"
            [(ngModel)]="model.postatusId"
            name="statuses"
            (change)="handleStatusChange($event)"
            [clearable]="false"
            [disabled]="disableStatus"
            required>
          </ng-select>
        </div>
      </div>
      <div class="form-group row"
        *ngIf="isInventoryItem && observables.itemList && observables.po">
        <label for="title"
          class="col-sm-2 text-right control-label col-form-label">Item Description</label>
        <div class="input-group col-md-10"
          style="height: 32px">
          <ng-select
            [items]="observables.itemList | filter: 'allVendorIds' : ( showAllItems || variables.isNotPOVendorItem ? null : observables.po.vendorId)"
            bindLabel="dropdownDisplay"
            bindValue="itemId"
            [(ngModel)]="model.itemId"
            (change)="updateDescriptionAndPrice($event)"
            name="items"
            [notFoundText]="variables.isNotPOVendorItem === false ? 'No items found for this Vendor - try selecting Show All' : 'No items found'"
            class="col-sm-8"
            style="padding:0px;"
            [disabled]="disableTopFields"
            required>
          </ng-select>
          <div class="input-group-append col-md-4"
            style="padding: 0; background-color: #fafafa; height: 32px;">
            <span class="input-group-text col-md-6"
              style="padding: 0px; padding-left: 10px;"
              title="Select 'Show All' to see all Items"><input type="checkbox"
                id="itemShowAll"
                [(ngModel)]="variables.isNotPOVendorItem" />&nbsp;<label for="itemShowAll"
                style="position: relative; top: 5px; left: 2px;">Show All</label></span>
            <span class="input-group-text col-md-6"
              style="padding: 0px;"><button (click)="openItemModal(0)"
                style="display: inline-flex;padding: 4px 10px;width:100%"
                class="btn btn-sm btn-default"
                [disabled]="disableTopFields"><i class="fa fa-plus"
                  style="position: relative; top: 3px; margin-right: 5px;"></i>&nbsp;New Item</button></span>
          </div>
        </div>
      </div>
      <div class="form-group row"
        *ngIf="model.itemId">
        <label for="title"
          class="col-sm-2 text-right control-label col-form-label">Part Group</label>
        <div class="col-sm-2 form-group mb-0"
          *ngIf="statusService.statusList | async; let stat">
          <input type="text"
            class="form-control form-control-sm"
            id="partGroup"
            [ngModel]="(observables.itemList | firstOrDefault: 'itemId' : model.itemId)?.partGroupName"
            name="poDisplay"
            readonly>
        </div>
        <label for="title"
          class="col-sm-2 text-right control-label col-form-label">Part Family</label>
        <div class="col-sm-2 form-group mb-0">
          <input type="text"
            class="form-control form-control-sm"
            id="partFamily"
            [ngModel]="(observables.itemList | firstOrDefault: 'itemId' : model.itemId)?.partFamilyName"
            name="poDisplay"
            readonly>
        </div>
        <label for="title"
          class="col-sm-2 text-right control-label col-form-label">New/Used</label>
        <div class="col-sm-2 form-group mb-0"
          *ngIf="lineService.newOrUsedStatusList | async; let stat">
          <ng-select [items]="stat"
            bindLabel="name"
            bindValue="newOrUsedStatusId"
            [(ngModel)]="model.newOrUsedStatusId"
            name="newOrUsedStatuses"
            [disabled]="disableTopFields">
          </ng-select>
        </div>
      </div>
      <div class="form-group row">
        <label for="title"
          class="col-sm-2 text-right control-label col-form-label"><span
            *ngIf="isInventoryItem && observables.itemList">Edited </span>Description</label>
        <div class="col-sm-10 form-group mb-0">
          <textarea name="description"
            id="description"
            class="form-control"
            [(ngModel)]="model.description"
            [disabled]="disableTopFields"></textarea>
        </div>
      </div>
      <div class="form-group row">
        <label for="title"
          class="col-sm-2 text-right control-label col-form-label">Qty</label>
        <div class="col-sm-2 form-group mb-0">
          <div class="input-group-append input-group-sm">
            <input type="number"
              class="form-control form-control-sm"
              (blur)="onChangeOfQtyOrPrice()"
              id="quantity"
              min="0"
              [(ngModel)]="model.quantity"
              name="quantity"
              required
              [disabled]="disableTopFields">
            <div class="input-group-append"
              *ngIf="model.itemId">
              <span class="input-group-text">
                {{ (observables.itemList | firstOrDefault: 'itemId' : model.itemId)?.uomName }}
              </span>
            </div>
          </div>
        </div>
        <label for="title"
          class="col-sm-2 text-right control-label col-form-label">Unit Price</label>
        <div class="col-sm-2 form-group mb-0">
          <div class="input-group input-group-sm">
            <div class="input-group-prepend">
              <span class="input-group-text">$</span>
            </div>
            <input type="number"
              class="form-control form-control-sm"
              id="quantity"
              (blur)="onChangeOfQtyOrPrice()"
              [(ngModel)]="model.price"
              name="price"
              required
              [disabled]="disableTopFields">
          </div>
        </div>
        <label for="title"
          class="col-sm-2 text-right control-label col-form-label">Line Total</label>
        <div class="col-sm-2 form-group mb-0">
          <div class="input-group input-group-sm">
            <div class="input-group-prepend">
              <span class="input-group-text">$</span>
            </div>
            <input type="text"
              class="form-control form-control-sm"
              id="lineTotal"
              [ngModel]="lineTotal"
              name="lineTotal"
              readonly>
          </div>
        </div>
      </div>
      <div *ngIf="!isInventoryItem"
        class="form-group row">
        <label for="title"
          class="col-sm-2 text-right control-label col-form-label">Maintenance Log</label>
        <div class="col-sm-6 form-group mb-0"
          *ngIf="maintenanceEventService.maintenanceEventByVendorList | async; let m">
          <ng-select [items]="m"
            bindLabel="maintenanceEventDropDisplay"
            bindValue="maintenanceEventId"
            [(ngModel)]="model.maintenanceEventId"
            name="maintenanceEvent"
            [disabled]="disableTopFields">
          </ng-select>
        </div>
      </div>
      <div class="form-group row mb-0"
        *ngIf="currentInventory && currentInventory.length > 0">
        <label for="title"
          class="col-sm-2 text-right control-label col-form-label"></label>
        <div class="col-sm-10 form-group mb-0">
          <div class="row">
            <div class="alert alert-danger p-1 mb-1"
              role="alert"
              *ngIf="itemIsLoaded && model && ((model.postatusId === PurchaseOrderStatuses.Draft || model.postatusId === PurchaseOrderStatuses.PendingApproval) && model.itemId !== null && model.itemId !== undefined) && isInventoryItem && (currentInventory === null || currentInventory.length === 0)">
              <i class="fas fa-check-circle"></i>&nbsp;&nbsp;Current Inventory:
              <strong>{{currentInventory!==null ? currentInventory : 0}}</strong>
            </div>
            <ng-container
              *ngIf="itemIsLoaded && model && ((model.postatusId === PurchaseOrderStatuses.Draft || model.postatusId === PurchaseOrderStatuses.PendingApproval) && model.itemId !== null && model.itemId !== undefined) && isInventoryItem && currentInventory !== null && currentInventory.length > 0">
              <div class="alert p-1 mb-1 mr-2"
                [ngClass]="{'alert-success':ci.inventoryCount !==null && ci.inventoryCount > 0, 'alert-danger': ci.inventoryCount === null || ci.inventoryCount <= 0 }"
                role="alert"
                *ngFor="let ci of currentInventory">
                <i class="fas fa-check-circle"></i>&nbsp;&nbsp;{{ci.name}} Inventory:
                <strong>{{ci.inventoryCount !==null ? ci.inventoryCount : 0}}</strong>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
      <div class="alert alert-secondary w-50"
        role="alert"
        *ngIf="model && model.itemInstanceSerialNumbers && model.itemInstanceSerialNumbers.length > 0">
        The following <strong>Serial Number<span *ngIf="model.itemInstanceSerialNumbers.length > 1">s</span></strong>
        <span *ngIf="model.itemInstanceSerialNumbers.length > 1"> have</span><span
          *ngIf="model.itemInstanceSerialNumbers.length === 1"> has</span> been created:
        <ul class="pl-3 mb-0">
          <li *ngFor="let sn of model.itemInstanceSerialNumbers">
            <strong>{{sn}}</strong>
          </li>
        </ul>
      </div>
      <ng-container
        *ngIf="model.postatusId === PurchaseOrderStatuses.Issued || model.postatusId === PurchaseOrderStatuses.Receiving || model.postatusId === PurchaseOrderStatuses.Received">
        <fieldset *ngIf="model.received.length > 0">
          <legend class="text-uppercase font-size-sm font-weight-bold">
            Receiving Data
            <button class="btn btn-sm btn-primary float-right"
              style="padding: 0 7px"
              (click)="addNewReceivedLine()"
              [disabled]="model.postatusId !== PurchaseOrderStatuses.Receiving"><i class="far fa-plus"></i></button>
          </legend>
          <div class="form-group row"
            *ngFor="let rec of model.received; let i = index">
            <label for="title"
              class="col-sm-1 text-right control-label col-form-label">Date</label>
            <div class="col-sm-2 form-group mb-0">
              <input type="text"
                placeholder="mm/dd/yyyy"
                class="form-control"
                [(ngModel)]="rec.receivedDate"
                (bsValueChange)="changeDateString($event, rec)"
                [disabled]="model.postatusId !== PurchaseOrderStatuses.Receiving && model.postatusId !== PurchaseOrderStatuses.Received"
                bsDatepicker>
            </div>
            <label for="title"
              class="col-sm-2 text-right control-label col-form-label">Received Quantity</label>
            <div class="col-sm-2 form-group mb-0">
              <input type="number"
                step="1"
                class="form-control"
                [(ngModel)]="rec.receivedQuantity"
                (change)="onChangeOfReceivedQty($event, rec.receivedPrice)"
                name="receivedQuantity"
                [readonly]="(rec.polineReceivedId && model.itemInstanceSerialNumbers.length > 0) || (model.postatusId !== PurchaseOrderStatuses.Receiving && model.postatusId !== PurchaseOrderStatuses.Received)">
            </div>
            <label for="title"
              class="col-sm-2 text-right control-label col-form-label">Received Price</label>
            <div class="col-sm-2 form-group mb-0">
              <div class="input-group input-group-sm">
                <div class="input-group-prepend">
                  <span class="input-group-text">$</span>
                </div>
                <input type="number"
                  class="form-control form-control-sm"
                  id="receivedPrice"
                  [(ngModel)]="rec.receivedPrice"
                  name="receivedPrice"
                  (change)="onChangeOfReceivedPrice($event, rec.receivedPrice)"
                  [readonly]="model.postatusId !== PurchaseOrderStatuses.Receiving && model.postatusId !== PurchaseOrderStatuses.Received">
              </div>
            </div>
            <div class="col-sm-1 form-group mb-0">
              <button class="btn btn-sm btn-default"
                [disabled]="model.postatusId === PurchaseOrderStatuses.Received || i === 0"
                [swal]="{ title: 'Delete Received Line?', text: rec.recDateString !== null ? rec.recDateString : null, showCancelButton: true, confirmButtonText: 'Delete', confirmButtonColor: '#DC3545' }"
                (confirm)="deleteReceivedLine(i)">
                <i class="fa fa-trash"></i>
              </button>
            </div>
          </div>
          <div class="alert alert-success w-75"
            role="alert"
            *ngIf="serNum.length > 0"
            [ngClass]="{ 'alert-dark': !serNumSelected }">
            <span *ngIf="serNumSelected">The following <strong>Serial Number<span
                  *ngIf="serNum.length > 1">s</span></strong> will be
              created:</span>
            <span *ngIf="!serNumSelected"><span class="text-danger"><i
                  class="fa-solid fa-circle-exclamation"></i></span>&nbsp;&nbsp;No <strong>Serial Numbers</strong> will
              be created.</span>
            <br />
            <table class="mt-1">
              <thead>
                <tr>
                  <td class="pr-1">
                    <input type="checkbox"
                      class="mt-1"
                      (change)="toggleAllSerialNumbers($event)"
                      [ngModel]="serNumSelected" />
                  </td>
                  <td class="pl-1 pt-1 pb-1 td-width">
                    <span>
                      <strong>
                        <span *ngIf="serNumSelected">DES</span><span *ngIf="!serNumSelected">S</span>ELECT
                        ALL
                      </strong>
                    </span>
                  </td>
                  <td colspan="3">
                  </td>
                </tr>
                <tr>
                  <td></td>
                  <td class="pl-1 pt-1 pb-1 td-width">
                    <u>
                      <span>
                        <strong>
                          Serial Number
                        </strong>
                      </span>
                    </u>
                  </td>
                  <td class="td-width center">
                    <u>
                      <span>
                        <strong>ID</strong>
                      </span>
                    </u>
                  </td>
                  <td class="td-width center">
                    <u>
                      <span>
                        <strong>OD</strong>
                      </span>
                    </u>
                  </td>
                  <td class="td-width center">
                    <u>
                      <span>
                        <strong>Length</strong>
                      </span>
                    </u>
                  </td>
                  <td class="td-width center">
                    <u>
                      <span>
                        <strong>Cost</strong>
                      </span>
                    </u>
                  </td>
                </tr>
              </thead>
              <tr *ngFor="let sn of serNum">
                <td class="pr-1">
                  <input type="checkbox"
                    class="mt-1"
                    [(ngModel)]="sn[1]"
                    [disabled]="model.postatusId !== PurchaseOrderStatuses.Receiving && model.postatusId !== PurchaseOrderStatuses.Received"
                    (change)="setSerNumSelected($event)">
                </td>
                <td [ngClass]="{ 'bg-secondary' : !sn[1] }"
                  class="pl-1 pt-1 pb-1">
                  <strong><span [ngClass]="{ 'strike' : !sn[1] }">{{sn[0].serialNumber}}</span></strong>
                </td>
                <td class="pl-1 pr-1 td-width">
                  <input type="number"
                    class="form-control"
                    [(ngModel)]="sn[0].innerDiameter"
                    [disabled]="!sn[1]"
                    (change)="setAllIiMeasurementsComplete($event)"
                    required>
                </td>
                <td class="pl-1 pr-1 td-width">
                  <input type="number"
                    class="form-control"
                    [(ngModel)]="sn[0].outerDiameter"
                    [disabled]="!sn[1]"
                    (change)="setAllIiMeasurementsComplete($event)"
                    required>
                </td>
                <td class="pl-1 pr-1 td-width">
                  <input type="number"
                    class="form-control"
                    [(ngModel)]="sn[0].length"
                    [disabled]="!sn[1]"
                    (change)="setAllIiMeasurementsComplete($event)"
                    required>
                </td>
                <td class="pl-1 pr-1 td-width">
                  <input type="number"
                    class="form-control"
                    [(ngModel)]="sn[0].originalCost"
                    [disabled]="!sn[1]"
                    (change)="setAllIiMeasurementsComplete($event)"
                    required>
                </td>
              </tr>
            </table>
          </div>
        </fieldset>
        <fieldset>
          <legend class="text-uppercase font-size-sm font-weight-bold">Accounting Use</legend>
          <div class="form-group row">
            <label for="title"
              class="col-sm-2 text-right control-label col-form-label">Invoice Date</label>
            <div class="col-sm-2 form-group mb-0">
              <input type="text"
                placeholder="mm/dd/yyyy"
                class="form-control"
                [(ngModel)]="model.accountingDate"
                bsDatepicker>
            </div>
            <label for="title"
              class="col-sm-2 text-right control-label col-form-label">Vendor Inv #</label>
            <div class="col-sm-2 form-group mb-0">
              <input type="text"
                class="form-control form-control-sm"
                id="vendorInvoiceNumber"
                [(ngModel)]="model.vendorInvoiceNumber"
                name="vendorInvoiceNumber">
            </div>
            <label for="title"
              class="col-sm-2 text-right control-label col-form-label">GL Account #</label>
            <div class="col-sm-2 form-group mb-0">
              <input type="text"
                class="form-control form-control-sm"
                disabled
                id="glAccountNumber"
                [ngModel]="(observables.typeList | firstOrDefault: 'potypeId' : model.potypeId)?.glAccount"
                name="glAccountNumber">
            </div>
          </div>
        </fieldset>
        <!-- <fieldset>
          <legend class="text-uppercase font-size-sm font-weight-bold">Outside Processing</legend>
          <div class="form-group row">
            <label for="title"
              class="col-sm-2 text-right control-label col-form-label">Storage Location</label>
            <div class="col-sm-4 form-group mb-0"
              *ngIf="locService.storageLocationList | async; let loc">
              <div class="input-group">
                <ng-select [items]="loc"
                  bindLabel="name"
                  bindValue="storageLocationId"
                  [(ngModel)]="model.storageLocationId"
                  class="col-md-9"
                  style="padding:0px;"
                  name="types">
                </ng-select>
                <div class="input-group-append col-md-3"
                  style="padding: 0;width:100%">
                  <span class="input-group-text"
                    style="padding: 0px;"><button (click)="openStorageLocationModal(0)"
                      style="display: inline-flex;padding: 4px 10px;width:100%"
                      class="btn btn-sm btn-default"
                      [disabled]="disableTopFields"><i class="fa fa-plus"
                        style="position: relative; top: 3px; margin-right: 5px;"></i>&nbsp;New</button></span>
                </div>
              </div>
            </div>
          </div>
        </fieldset> -->
      </ng-container>
    </div>
  </ng-container>
</ng-container>
<div class="modal-footer"
  *ngIf="model">
  <button type="button"
    class="btn btn-danger mr-auto"
    (click)="cancel(model.polineId)"
    *ngIf="!hideCancel"
    [disabled]="disableTopFields">Cancel Item</button>
  <button type="button"
    class="btn btn-primary"
    (click)="onSave()"
    [disabled]="disableSave || !allIiMeasurementsComplete || !(model.potypeId&&model.quantity&&model.price&&model.postatusId&&(!isInventoryItem||isInventoryItem&&model.itemId))">Save</button>
  <button type="button"
    class="btn btn-default"
    (click)="bsModalRef.hide()">Cancel</button>
</div>
