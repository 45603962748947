import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { JobSubStatuses } from 'src/enums/job-sub-statuses';
import { JobTypes } from 'src/enums/job-types';
import { JobSafetyAnalysisHealthModel } from 'src/models/job-safety-analysis-health.model';
import { AppStateService } from 'src/services/app-state.service';
import { JobSafetyAnalysisService } from 'src/services/job-safety-analysis.service';
import { JobService } from 'src/services/job.service';
import { TimezoneService } from 'src/services/timezone.service';
import { UIService } from 'src/services/ui.service';
import { UserService } from 'src/services/user.service';
import { JobStatuses } from '../../../../enums/job-statuses';
import { JobModel } from '../../../../models/job.model';
import { CancelJobConfirmComponent } from '../cancel-job-confirm-modal/cancel-job-confirm-modal.component';

@Component({
  selector: 'app-sub-status-button',
  templateUrl: './sub-status-button.component.html',
  styleUrls: ['./sub-status-button.component.css']
})

export class SubStatusButtonComponent implements OnInit, OnDestroy {
  @Input() job: JobModel;
  @Input() enableReorder: boolean = false;
  public JobStatuses = JobStatuses;
  public JobSubStatuses = JobSubStatuses;
  public JobTypes = JobTypes;
  public bsModalRef: BsModalRef;
  public cancellationId: number = null;
  public closeId: number = null;
  public openId: number = null;
  public editId: number = null;
  public isFishing: boolean = false;
  public canApproveInvoices: boolean = false;
  public allowSubmitForSignature: boolean = true;

  private isTabletSub: Subscription;
  public isTablet: boolean = false;

  private jsasAreCompleteSub: Subscription;
  private jobStatusSub: Subscription;
  private jobSubStatusSub: Subscription;

  constructor(
    public uiService: UIService,
    public userService: UserService,
    public jobService: JobService,
    public appStateService: AppStateService,
    public timeZoneService: TimezoneService,
    public jobSafetyAnalysisService: JobSafetyAnalysisService,
    private modalService: BsModalService,
  ) { };

  ngOnDestroy(): void {
    this.isTabletSub?.unsubscribe();

    this.jsasAreCompleteSub?.unsubscribe();

    this.jobSubStatusSub?.unsubscribe();
  }

  ngOnInit(): void {
    this.determineIsTablet();
    this.determineIsFishing();
    this.determineIfUserCanApprove();
    this.setCancellationId();
    this.setCloseId();
    this.setOpenId();
    this.setEditInvoiceId();

    if (this.job.jobTypeId === this.JobTypes.Fishing
      || this.job.jobTypeId === this.JobTypes.Packer) {
      this.subscribeToJsasAreComplete();
    }
  }

  public determineIfUserCanApprove(): void {
    this.canApproveInvoices = this.userService.user.value ?
      this.userService.user.value.activeRoles.includes('Approve Invoices')
      && (this.userService.user.value.invoiceApprovalLimit === null
        || (this.userService.user.value.invoiceApprovalLimit !== null
          && this.userService.user.value.invoiceApprovalLimit > this.job.totalStamp))
      : false;
  }

  public determineIsTablet(): void {
    this.isTabletSub = this.appStateService.isTablet$
      .subscribe((isTablet: boolean) => {
        this.isTablet = isTablet;
      });
  }

  public determineIsFishing(): void {
    this.isFishing = this.job.jobTypeId === this.JobTypes.Fishing || this.job.jobTypeId === this.JobTypes.FishingToolRental;
  }

  public setCancellationId(): void {
    this.cancellationId = this.jobService.setCancellationId(this.job.jobTypeId);
  }

  public setCloseId(): void {
    this.closeId = this.jobService.setCloseId(this.job.jobTypeId);
  }

  public setOpenId(): void {
    this.openId = this.jobService.setOpenId(this.job.jobTypeId);
  }

  public setEditInvoiceId(): void {
    this.editId = this.jobService.setEditInvoiceId(this.job.jobTypeId);
  }

  public subscribeToJsasAreComplete(): void {
    this.jsasAreCompleteSub = this.jobSafetyAnalysisService.jsasAreComplete
      .subscribe((health: JobSafetyAnalysisHealthModel) => {
        if (health) {
          this.allowSubmitForSignature = health.jsasAreComplete;
        }
      });
  }

  public changeJobSubStatus(subStatusId: number): void {
    if (this.jobService.job.value !== null) {
      this.jobService.job.value.jobSubStatusId = subStatusId;

      this.jobSubStatusSub = this.jobService.addEditJob(this.jobService.job.value, false, true)
        .subscribe((x: boolean) => {
          // This is here because the jobSubStatusSub can not be unsubscribed from ngOnDestroy.
          this.jobSubStatusSub?.unsubscribe();

          if (x === true) {
            this.uiService.showSuccess('Job Status Changed', 'Success');
          } else {
            this.uiService.showError('Job Status Not Changed', 'Error');
          }
        });
    } else {
      this.uiService.showError('Job Status Not Changed', 'Error');
    }
  }

  public changeJobStatus(statusId: number): void {
    if (this.jobService.job.value !== null) {
      this.jobService.job.value.jobStatusId = statusId;

      this.jobStatusSub = this.jobService.addEditJob(this.jobService.job.value, false, true)
        .subscribe((x: boolean) => {
          // This is here because the jobStatusSub can not be unsubscribed from ngOnDestroy.
          this.jobStatusSub?.unsubscribe();

          if (x === true) {
            this.uiService.showSuccess('Job Status Changed', 'Success');
          } else {
            this.uiService.showError('Job Status Not Changed', 'Error');
          }
        });
    } else {
      this.uiService.showError('Job Status Not Changed', 'Error');
    }
  }

  public openCancelJobConfirmModal(
    job: JobModel,
    cancellationStatusId: number): void {
    const initialState = {
      statusId: cancellationStatusId,
      model: job
    };

    this.bsModalRef = this.modalService.show(CancelJobConfirmComponent, { initialState, backdrop: 'static' });
  }

}
