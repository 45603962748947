<ng-container *ngIf="inventoryHistoryService.inventoryHistoryShopList | async; let hist">
  <div class="table-responsive"
    *ngIf="hist.length === 0">
    <div class="alert alert-primary ml-3 mr-3 mt-3"
      role="alert">
      <i class="fad fa-check"></i>&nbsp;&nbsp;There is currently no <strong>Inventory History.</strong>
    </div>
  </div>
  <div class="card border-top-primary border-top-2 rounded-top-0"
    style="border-top-color: #2196F3 !important; overflow: auto;"
    *ngFor="let h of hist; let i = index">
    <div class="card-header bg-light header-elements-sm-inline">
      <h6 class="card-title">Inventory History - {{h[0].shopName}}</h6>
      <div class="header-elements">
        <button class="btn btn-light btn-sm"
          (click)="openInventoryAdjustModal(itemService.item.value.itemId, h[0].shopId)"><i class="fa fa-plus"></i>
          Adjust Inventory</button>
      </div>
    </div>
    <div class="table-responsive">
      <kendo-grid *ngIf="h.length > 0"
        [kendoGridBinding]="h"
        filterable="false"
        [sortable]="true"
        [resizable]="true"
        [pageSize]="kGridParams.pageSize"
        [skip]="kGridParams.skip"
        (pageChange)="pageChange($event)"
        [pageable]="{
                    buttonCount: kGridParams.buttonCount,
                    info: kGridParams.info,
                    type: kGridParams.type,
                    pageSizes: kGridParams.pageSizes,
                    previousNext: kGridParams.previousNext
                  }">
        <ng-template kendoGridToolbarTemplate>
          <button type="button"
            kendoGridExcelCommand
            icon="file-excel">Export to Excel</button>
        </ng-template>
        <kendo-grid-column field="one"
          title=""
          [width]="60"
          filterable="false">
          <ng-template kendoGridCellTemplate
            let-dataItem
            let-rowIndex="rowIndex">
            <a class="btn btn-xs btn-light"
              [routerLink]="['/jobs/sales/details', dataItem.frontEndJobId]"
              *ngIf="dataItem.frontEndJobId !== undefined && dataItem.frontEndJobId !== null"><i class="fa fa-search"
                title="Go To Job"></i></a>
            <a class="btn btn-xs btn-light"
              [routerLink]="['/purchase-orders/details', dataItem.poId]"
              *ngIf="dataItem.poId !== undefined && dataItem.poId !== null"
              title="Go To PO"><i class="fa fa-search"></i></a>
            <button class="btn btn-xs btn-light"
              *ngIf="dataItem.poId === null && dataItem.frontEndJobId === null"
              disabled
              title="Manual Inventory Adjustment"><i class="fa fa-search"></i></button>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="changedOn"
          title="Date"
          [width]="70">
          <ng-template kendoGridCellTemplate
            let-dataItem
            let-rowIndex="rowIndex">
            {{dataItem.changedOn | date: 'shortDate'}}
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="shopInventoryChangeDisplay"
          title="Inv Change"
          [width]="100">
        </kendo-grid-column>
        <kendo-grid-column field="shopInventoryOnHandAfterChange"
          title="Inv Balance"
          [width]="100">
        </kendo-grid-column>
        <kendo-grid-column field="shopReserveChangeDisplay"
          title="Res Change"
          [width]="100">
        </kendo-grid-column>
        <kendo-grid-column field="shopInventoryInReserveAfterChange"
          title="Res Balance"
          [width]="100">
        </kendo-grid-column>
        <kendo-grid-column field="inventoryHistoryActionTypeName"
          title="Action"
          [width]="190">
        </kendo-grid-column>
        <kendo-grid-column field="displayDescription"
          title="Description"
          *ngIf="!isTablet">
        </kendo-grid-column>
        <kendo-grid-column field="changedByUserName"
          title="User"
          [width]="120">
        </kendo-grid-column>
        <kendo-grid-excel fileName="Grizzly - Item Master.xlsx"
          [fetchData]="allData">
          <kendo-excelexport-column field="changedOn"
            title="Date">
          </kendo-excelexport-column>
          <kendo-excelexport-column field="shopName"
            title="Shop">
          </kendo-excelexport-column>
          <kendo-excelexport-column field="shopInventoryChangeDisplay"
            title="Inv Change">
          </kendo-excelexport-column>
          <kendo-excelexport-column field="shopInventoryOnHandAfterChange"
            title="Inv Balance">
          </kendo-excelexport-column>
          <kendo-excelexport-column field="shopReserveChangeDisplay"
            title="Res Change">
          </kendo-excelexport-column>
          <kendo-excelexport-column field="shopInventoryInReserveAfterChange"
            title="Res Balance">
          </kendo-excelexport-column>
          <kendo-excelexport-column field="inventoryHistoryActionTypeName"
            title="Action">
          </kendo-excelexport-column>
          <kendo-excelexport-column field="displayDescription"
            title="Description">
          </kendo-excelexport-column>
          <kendo-excelexport-column field="changedByUserName"
            title="User">
          </kendo-excelexport-column>
        </kendo-grid-excel>
      </kendo-grid>
    </div>
  </div>
</ng-container>
