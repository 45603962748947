<div class="modal-header"
  ngxModalDraggable>
  <h4 class="modal-title pull-left">Confirm Packer ID</h4>
  <button type="button"
    class="close pull-right"
    aria-label="Close"
    (click)="bsModalRef.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div class="center blue-background">
    <h2 class="mb-0 mt-2 inline-block">Packer ID:</h2>
    <br>
    <h1 class="mb-0 inline-block big-font"><u>{{worksheetId}}</u></h1>
    <br>
    <h2 class="inline-block text-danger"><i class="fas fa-exclamation-triangle"></i>&nbsp;Please make note of this
      ID!</h2>
  </div>
</div>
<div class="modal-footer">
  <button type="button"
    class="btn btn-default"
    (click)="bsModalRef.hide()">Close</button>
</div>
