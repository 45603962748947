export class PurchaseOrderStatusModel {
  postatusId: number;
  name: string;
  statusOrder: number;
  readOnly: boolean;
  showInDropDown: boolean;
  isActive: boolean;

  disabled: boolean = false;
}
