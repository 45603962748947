import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Observer } from 'rxjs';
import { AddressModel } from '../models/address.model';
import { AddressTypes } from 'src/enums/address-types.enum';

@Injectable()
export class AddressService {
  constructor(
    private http: HttpClient,
    @Inject('BASE_URL') private baseUrl: string
  ) { }

  public addressList: BehaviorSubject<Array<AddressModel>> = new BehaviorSubject(null);
  public addressCache: BehaviorSubject<AddressModel> = new BehaviorSubject(null);

  private apiUrl = 'api/Address';  // URL to web api

  public refreshAddressList(
    type: number,
    id: number,
    showInactive: boolean): void {
    if (type === AddressTypes.Customer) {
      this.getCustomerAddresses(id, showInactive)
        .subscribe((x: AddressModel[]) => {
          this.addressList.next(x);
        });
    }
  }

  public getCustomerAddresses(
    id: number,
    showInactive: boolean): Observable<Array<AddressModel>> {
    return this.http.get<Array<AddressModel>>(this.baseUrl + this.apiUrl + '/GetCustomerAddresses/' + id + '/' + showInactive);
  }

  public getAddressById(id: number): Observable<AddressModel> {
    return this.http.get<AddressModel>(this.baseUrl + this.apiUrl + '/GetAddressById/' + id);
  }

  public addEditAddress(
    dto: AddressModel,
    showInactive: boolean,
    cacheAddress: boolean): Observable<boolean> {
    return new Observable((observer: Observer<boolean>) => {
      this.http.post<number>(this.baseUrl + this.apiUrl + '/AddEditAddress', this.cleanAddressModel(dto))
        .subscribe((x: number) => {
          if (x !== -1) {
            if (cacheAddress) {
              dto.addressId = x;
              this.addressCache.next(dto);
            }
            if (dto.customerId !== null) {
              this.refreshAddressList(AddressTypes.Customer, dto.customerId, showInactive);
            } else if (dto.vendorId !== null) {
              this.refreshAddressList(AddressTypes.Vendor, dto.vendorId, showInactive);
            }
          }
          observer.next(x !== -1);
        });
    });
  }

  public deleteAddress(
    dto: AddressModel,
    showInactive: boolean): Observable<boolean> {
    return new Observable((observer: Observer<boolean>) => {
      this.http.get<boolean>(this.baseUrl + this.apiUrl + '/DeleteAddress/' + dto.addressId)
        .subscribe((x: boolean) => {
          if (x === true) {
            if (dto.customerId !== null) {
              this.refreshAddressList(AddressTypes.Customer, dto.customerId, showInactive);
            } else if (dto.vendorId !== null) {
              this.refreshAddressList(AddressTypes.Vendor, dto.vendorId, showInactive);
            }
          }
          observer.next(x);
        });
    });
  }

  public generateNewAddressModel(
    vendorId: number = null,
    customerId: number = null): AddressModel {
    let model: AddressModel = new AddressModel();
    model.vendorId = vendorId;
    model.customerId = customerId;
    model.isActive = true;

    return model;
  }

  public cleanAddressModel(model: AddressModel): AddressModel {
    Object.keys(model).forEach((index: string) => {
      if (typeof model[index] === 'string') {
        model[index] = model[index]
          && model[index].trim() !== '' ?
          model[index].trim()
          : null;
      }
    });

    return model;
  }

}
