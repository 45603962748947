import { AddressService } from './address.service';
import { BehaviorSubject, Observable, Observer } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { InvoiceItemReorderModel } from 'src/models/RequestParams/invoice-item-reorder.model';
import { JobCopyParamsModel } from '../models/RequestParams/job-copy-params.model';
import { JobFilterParamsModel } from '../models/RequestParams/job-filter-params.model';
import { JobModel } from '../models/job.model';
import { JobPurchaseOrderLinkModel } from '../models/job-purchase-order-link.model';
import { JobStatuses } from '../enums/job-statuses';
import { JobStatusHistoryCombinedModel } from 'src/models/job-status-history-combined.model';
import { JobStatusService } from './job-status.service';
import { JobTypes } from '../enums/job-types';
import { OpenInvoiceParamsModel } from 'src/models/RequestParams/open-invoice-params.model';
import { Router } from '@angular/router';
import { Shops } from '../enums/shops';
import { tap } from 'rxjs/operators';
import { TimezoneService } from './timezone.service';
import { UserService } from './user.service';
import { InvoiceModel } from 'src/models/invoice.model';
import { PurchaseOrderModel } from 'src/models/purchase-order.model';
import { JobItemInstanceModel } from 'src/models/job-item-instance.model';
import { DayModel } from 'src/models/day.model';
import { WorkLogModel } from 'src/models/work-log.model';
import { BhaModel } from 'src/models/bha.model';
import { BhalineModel } from 'src/models/bha-line.model';
import { JobDropdownModel } from 'src/models/job-dropdown.model';

@Injectable()
export class JobService {
  constructor(
    private http: HttpClient,
    @Inject('BASE_URL') private baseUrl: string,
    private addressService: AddressService,
    private timezoneService: TimezoneService,
    private jobStatusService: JobStatusService,
    private userService: UserService,
    private router: Router
  ) { }

  public jobFilterParams: JobFilterParamsModel = new JobFilterParamsModel();
  public jobList: BehaviorSubject<Array<JobModel>> = new BehaviorSubject(null);
  public openJobList: BehaviorSubject<Array<JobModel>> = new BehaviorSubject(null);
  public job: BehaviorSubject<JobModel> = new BehaviorSubject(null);
  public isLoading: boolean = false;
  public openJobsAreLoading: boolean = false;
  public JobTypes = JobTypes;
  public JobStatuses = JobStatuses;
  public Shops = Shops;
  public jobsAreLoaded: boolean = false;

  private apiUrl = 'api/Job';  // URL to web api

  public copyJob(
    params: JobCopyParamsModel,
    jobTypeId: number): Observable<boolean> {
    return new Observable((observer: Observer<boolean>) => {
      this.http.post<number>(this.baseUrl + this.apiUrl + '/CopyFishingJob', params)
        .subscribe((x: number) => {
          if (x !== -1) {
            if (jobTypeId === this.JobTypes.Fishing) {
              this.router.navigateByUrl(`/jobs/fishing/details/${x}`);
            } else if (jobTypeId === this.JobTypes.FishingToolRental) {
              this.router.navigateByUrl(`/jobs/fishing-tool-rental/details/${x}`);
            }
          }
          observer.next(x !== -1);
        });
    });
  }

  public getJobs(
    showCancelled: boolean,
    clear: boolean = false,
    showSkeleton: boolean = false): void {
    if (!this.jobList.value || clear) {
      if (showSkeleton) {
        this.jobsAreLoaded = false;
      }

      this.http.post<Array<JobModel>>(this.baseUrl + this.apiUrl + '/GetJobs/' + showCancelled, this.jobFilterParams)
        .pipe(tap((x: Array<JobModel>) => {
          x.forEach((job: JobModel) => {
            job.jobDate = job.jobDate !== undefined && job.jobDate !== null ? new Date(job.jobDate) : null;
          });
        }))
        .subscribe((x: JobModel[]) => {
          this.jobList.next(x);
          this.jobsAreLoaded = true;
        });
    }
  }

  public refreshJobs(
    showCancelled: boolean,
    clearFilterParams: boolean = false,
    showSkeleton: boolean = false) {
    if (!clearFilterParams) {
      this.getJobs(showCancelled, true, showSkeleton);
    } else {
      this.clearFilterParams(showCancelled);
    }
  }

  public getJobsForDropdown(showCancelled: boolean): Observable<JobModel[]> {
    return this.http.post<Array<JobModel>>(this.baseUrl + this.apiUrl + '/GetJobs/' + showCancelled, new JobFilterParamsModel())
      .pipe(tap((x: JobModel[]) => {
        x.forEach((job: JobModel) => {
          job.jobDate = job.jobDate !== undefined && job.jobDate !== null ? new Date(job.jobDate) : null;
          job.dropdownDisplay = `${job.jobNumber} ${job.customerName}/${job.wellName}`;
        });
      }));
  }

  public refreshJob(
    id: number,
    keepBhaDays: boolean = false,
    keepJiiDays: boolean = false): void {
    this.isLoading = true;
    this.getJobById(id)
      .subscribe((x: JobModel) => {
        if (keepBhaDays) {
          x.bhas = this.job.value != null ? this.job.value.bhas : x.bhas;
        }
        if (keepJiiDays) {
          x.jobItemInstances = this.job.value != null ? this.job.value.jobItemInstances : x.jobItemInstances;
        }
        if (x.invoices !== null && x.invoices !== undefined) {
          x.invoices.forEach((inv: InvoiceModel) => {
            let temp: Date[] = [];
            if (inv.invDay !== undefined && inv.invDay !== null) {
              inv.invDay.forEach((date: Date) => {
                temp.push(new Date(date));
              });
            }
            inv.invDay = temp;
          });
        }
        if (x.purchaseOrders !== null && x.purchaseOrders !== undefined) {
          x.purchaseOrders.forEach((po: PurchaseOrderModel) => {
            po.dropDownDisplay = `#${po.ponumberDisplay} - ${po.vendorName} - $${po.total}`;
          });
        }
        if (x.jobItemInstances !== undefined && x.jobItemInstances !== null) {
          x.jobItemInstances.forEach((jii: JobItemInstanceModel) => {
            jii.description += !keepJiiDays
              && jii.qty !== undefined
              && jii.qty !== null
              && jii.quantityUom !== undefined
              && jii.quantityUom !== null ?
              ` - ${jii.qty}${jii.quantityUom === 'Run' ? ' ' : ''}${jii.quantityUom === 'Ft' || jii.quantityUom === 'Run' ?
                jii.quantityUom + (jii.quantityUom === 'Run' && jii.qty > 1 ? 's' : '')
                : ''}`
              : '';

            if (jii.jobIteminstanceDays !== undefined
              && jii.jobIteminstanceDays !== null
              && jii.quantityUom !== null
              && jii.quantityUom.toLowerCase() !== 'run') {
              jii.disableTrash = Object.values(jii.jobIteminstanceDays).reduce((a, { qty }) => a + qty, 0) > 0;
            } else if (jii.quantityUom !== null && jii.quantityUom.toLowerCase() === 'run') {
              jii.disableTrash = jii.qty > 0;
            }
          });
        }
        if (x.days !== undefined && x.days !== null) {
          x.days.forEach((x: DayModel) => x.dateUsed = false);
        }

        this.job.next(x);
        this.isLoading = false;
      });
  }

  public getJobById(id: number): Observable<JobModel> {
    return this.http.get<JobModel>(this.baseUrl + this.apiUrl + '/GetJobById/' + id)
      .pipe(tap((x: JobModel) => {
        x.jobDate = x.jobDate !== undefined && x.jobDate !== null ? new Date(x.jobDate) : null;
        x.closeDate = x.closeDate !== undefined && x.closeDate !== null ? new Date(x.closeDate) : null;
        x.invoiceDate = x.invoiceDate !== undefined && x.invoiceDate !== null ? new Date(x.invoiceDate) : null;

        if (x.address) {
          x.address = this.addressService.cleanAddressModel(x.address);
        }

        if (x.days !== undefined && x.days !== null) {
          x.days.forEach((day: DayModel) => {
            day.date = day.date !== undefined && day.date !== null ? new Date(day.date) : null;
            day.workLogs.forEach((wl: WorkLogModel) => {
              wl.hours = Math.abs(new Date(wl.workEnd).getTime() - new Date(wl.workStart).getTime()) / 36e5;
            });
          });

          if (x.days.length > 0) {
            x.lastJobDay = x.days.reduce((maxDate, currentObj) => {
              const currentDate = new Date(currentObj.date);

              return currentDate > maxDate ? currentDate : maxDate;
            }, new Date(x.days[0]?.date));
          } else {
            x.lastJobDay = x.jobDate;
          }
        }

        if (x.bhas !== undefined && x.bhas !== null) {
          x.bhas.forEach((bha: BhaModel) => {
            let accum: number = 0;
            bha.bhalines.forEach((line: BhalineModel) => {
              accum = accum + line.length;
              line.accumLength = accum;
            });
            bha.length = accum;
          });
        }
      }));
  }

  public addEditJob(
    dto: JobModel,
    showInactive: boolean = false,
    refreshJobModel: boolean = false): Observable<boolean> {
    return new Observable((observer: Observer<boolean>) => {
      this.http.post<number>(this.baseUrl + this.apiUrl + '/AddEditJob', this.cleanJobModel(dto))
        .subscribe((x: number) => {
          if (x !== -1 && dto.jobId !== 0) {
            this.refreshJobs(showInactive);
            if (refreshJobModel) {
              this.refreshJob(x);
            }
          } else {
            // this will need to be different when we add more job types.
            if (dto.jobTypeId === this.JobTypes.Sales) {
              this.router.navigateByUrl(`/jobs/sales/details/${x}`);
            } else if (dto.jobTypeId === this.JobTypes.Fishing) {
              this.router.navigateByUrl(`/jobs/fishing/details/${x}`);
            } else if (dto.jobTypeId === this.JobTypes.FishingToolRental) {
              this.router.navigateByUrl(`/jobs/fishing-tool-rental/details/${x}`);
            } else if (dto.jobTypeId === this.JobTypes.Packer) {
              this.router.navigateByUrl(`/jobs/packer/details/${x}`);
            }
          }
          observer.next(x !== -1);
        });
    });
  }

  public linkPoToJob(dto: JobPurchaseOrderLinkModel): Observable<boolean> {
    return new Observable((observer: Observer<boolean>) => {
      this.http.post<number>(this.baseUrl + this.apiUrl + '/LinkPoToJob', dto)
        .subscribe((x: number) => {
          if (x !== -1 && dto.jobId !== 0) {
            this.refreshJob(x);
          }
          observer.next(x !== -1);
        });
    });
  }

  public unLinkPoFromJob(dto: JobPurchaseOrderLinkModel): Observable<boolean> {
    return new Observable((observer: Observer<boolean>) => {
      this.http.post<number>(this.baseUrl + this.apiUrl + '/UnLinkPoFromJob', dto)
        .subscribe((x: number) => {
          if (x !== -1 && dto.jobId !== 0) {
            this.refreshJob(x);
          }
          observer.next(x !== -1);
        });
    });
  }

  public getOpenJobs(
    showNotReadyToInvoice: boolean = false,
    jobSubStatusIds: number[] = null,
    clear: boolean = false): void {
    const params = new OpenInvoiceParamsModel();
    params.showNotReadyToInvoice = showNotReadyToInvoice;
    params.subStatusIds = jobSubStatusIds === null ? this.userService.openJobUserSubStatuses.map((x: number) => {
      return x;
    })
      : jobSubStatusIds;

    if (clear) {
      this.openJobsAreLoading = true;
    }

    this.http.post<Array<JobModel>>(this.baseUrl + this.apiUrl + '/GetOpenJobs', params)
      .pipe(tap((x: JobModel[]) => {
        x.forEach((job: JobModel) => {
          job.jobDate = job.jobDate !== undefined && job.jobDate !== null ? new Date(job.jobDate) : null;
        });
      }))
      .subscribe((x: JobModel[]) => {
        this.openJobList.next(x);
        this.openJobsAreLoading = false;
      });
  }

  public batchReorderJobInvoiceItems(dto: InvoiceItemReorderModel): Observable<boolean> {
    return new Observable((observer: Observer<boolean>) => {
      this.http.post<boolean>(this.baseUrl + this.apiUrl + '/BatchReorderJobInvoiceItems', dto)
        .subscribe((x: boolean) => {
          if (x === true) {
            this.refreshJob(dto.jobId);
          }
          observer.next(x);
        });
    });
  }

  public batchReorderFishingJobInvoiceItems(dto: InvoiceItemReorderModel): Observable<boolean> {
    return new Observable((observer: Observer<boolean>) => {
      this.http.post<boolean>(this.baseUrl + this.apiUrl + '/BatchReorderFishingJobInvoiceItems', dto)
        .subscribe((x: boolean) => {
          if (x === true) {
            this.refreshJob(dto.jobId);
          }
          observer.next(x);
        });
    });
  }

  public toggleReadyToInvoice(jobId: number): Observable<boolean> {
    return this.http.get<boolean>(this.baseUrl + this.apiUrl + '/ToggleReadyToInvoice/' + jobId);
  }

  public getCombinedJobStatusHistoryByJobId(jobId: number): Observable<JobStatusHistoryCombinedModel[]> {
    return this.http.get<JobStatusHistoryCombinedModel[]>(this.baseUrl + this.apiUrl + '/GetCombinedJobStatusHistoryByJobId/' + jobId);
  }

  public getJobsWithWorkLogsForDropdown(): Observable<JobDropdownModel[]> {
    return this.http.get<JobDropdownModel[]>(this.baseUrl + this.apiUrl + '/GetJobsWithWorkLogsForDropdown');
  }

  // PLEASE LEAVE THIS HERE FOR PRICE CHANGES
  // public recalculateJobTotals(): Observable<boolean> {
  //   return this.http.get<boolean>(this.baseUrl + this.apiUrl + '/RecalculateJobTotals');
  // }

  public clearFilterParams(showInactive: boolean) {
    Object.keys(this.jobFilterParams).forEach((index: string) => {
      this.jobFilterParams[index] = null;
    });

    this.jobStatusService.jobStatusList.next(null);

    this.getJobs(showInactive, true);
  }

  public generateNewJobModel(
    jobTypeId: number,
    isQuote: boolean = false): JobModel {
    const user = this.userService.user.value;

    let model: JobModel = new JobModel();
    model.jobId = 0;
    model.jobNumber = null;
    model.jobTypeId = jobTypeId;
    model.jobDate = new Date();
    model.isActive = true;
    model.jobStatusId = this.returnOpenJobStatus(jobTypeId, isQuote);
    model.countyId = null;
    model.shopId = user != null ? user.defaultShopId : this.Shops.Williston;
    model.notReadyToInvoice = false;
    model.jobHandUserIds = [];

    return model;
  }

  public getNumberOfActiveFilterFields(): number {
    let count: number = 0;

    Object.values(this.jobFilterParams).forEach((value: any) => {
      count = value ? count += 1 : count;
    });

    return count;
  }

  public setCancellationId(jobTypeId: number): number {
    if (jobTypeId === this.JobTypes.Sales) {
      return this.JobStatuses.Cancelled_Sales;
    } else if (jobTypeId === this.JobTypes.Fishing) {
      return this.JobStatuses.Cancelled_Fishing;
    } else if (jobTypeId === this.JobTypes.FishingToolRental) {
      return this.JobStatuses.Cancelled_FishingToolRental;
    } else if (jobTypeId === this.JobTypes.Packer) {
      return this.JobStatuses.Cancelled_Packer;
    }
  }

  public setCloseId(jobTypeId: number): number {
    if (jobTypeId === this.JobTypes.Sales) {
      return this.JobStatuses.Closed_Sales;
    } else if (jobTypeId === this.JobTypes.Fishing) {
      return this.JobStatuses.Closed_Fishing;
    } else if (jobTypeId === this.JobTypes.FishingToolRental) {
      return this.JobStatuses.Closed_FishingToolRental;
    } else if (jobTypeId === this.JobTypes.Packer) {
      return this.JobStatuses.Closed_Packer;
    }
  }

  public setOpenId(jobTypeId: number): number {
    if (jobTypeId === this.JobTypes.Sales) {
      return this.JobStatuses.Open_Sales;
    } else if (jobTypeId === this.JobTypes.Fishing) {
      return this.JobStatuses.Open_Fishing;
    } else if (jobTypeId === this.JobTypes.FishingToolRental) {
      return this.JobStatuses.Open_FishingToolRental;
    } else if (jobTypeId === this.JobTypes.Packer) {
      return this.JobStatuses.Open_Packer;
    }
  }

  public setEditInvoiceId(jobTypeId: number): number {
    if (jobTypeId === this.JobTypes.Fishing) {
      return this.JobStatuses.EditInvoice_Fishing;
    } else if (jobTypeId === this.JobTypes.FishingToolRental) {
      return this.JobStatuses.EditInvoice_FishingToolRental;
    }
  }

  private cleanJobModel(model: JobModel): JobModel {
    Object.keys(model).forEach((index: string) => {
      if (typeof model[index] === 'string') {
        model[index] = model[index]
          && model[index].trim() !== '' ?
          model[index].trim()
          : null;
      } else if (model[index] instanceof Date
        && !isNaN(model[index])) {
        model[index] = this.timezoneService.correctOffset(model[index]);
      }
    });

    return model;
  }

  private returnOpenJobStatus(
    jobTypeId: number,
    isQuote: boolean = false): number {
    if (isQuote) {
      return this.JobStatuses.Quote_Sales;
    } else {
      switch (jobTypeId) {
        case this.JobTypes.Sales:
          return this.JobStatuses.Open_Sales;
        case this.JobTypes.Fishing:
          return this.JobStatuses.Open_Fishing;
        case this.JobTypes.FishingToolRental:
          return this.JobStatuses.Open_FishingToolRental;
        case this.JobTypes.Packer:
          return this.JobStatuses.Open_Packer;
      }
    }
  }

}
