export class JobSalesItemModel {
  jobId: number = null;
  invoiceId: number | null = null;
  invoiceItemId: number | null = null;
  invoiceItemTypeId: number | null;
  itemId: number = null;
  billingCodeId: number = null;
  quantity: number = null;
  price: number = null;
  isForResale: boolean | null;
  isActive: boolean | null;
  description: string;

  lockQty: boolean;
  lockPrice: boolean;
  allowEditFishingItemQty: boolean;
}
