import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { BillingCodes } from 'src/enums/billing-codes.enum';
import { ContractorTypes } from 'src/enums/contractor-types';
import { JobSubStatuses } from 'src/enums/job-sub-statuses';
import { JobTypes } from 'src/enums/job-types';
import { ContractorTypeModel } from 'src/models/contractor-type.model';
import { JobSafetyAnalysisCrewSignatureModel } from 'src/models/job-safety-analysis-crew-signature.model';
import { JobSafetyAnalysisJobStepModel } from 'src/models/job-safety-analysis-job-step.model';
import { JobSafetyAnalysisWeatherModel } from 'src/models/job-safety-analysis-weather.model';
import { JobSafetyAnalysisWindDirectionModel } from 'src/models/job-safety-analysis-wind-direction.model';
import { JobSafetyAnalysisModel } from 'src/models/job-safety-analysis.model';
import { WeatherApiModel } from 'src/models/weather-api.model';
import { AppStateService } from 'src/services/app-state.service';
import { ContractorTypeService } from 'src/services/contractor-type.service';
import { JobSafetyAnalysisWeatherService } from 'src/services/job-safety-analysis-weather.service';
import { JobSafetyAnalysisWindDirectionService } from 'src/services/job-safety-analysis-wind-direction.service';
import { JobSafetyAnalysisWindSpeedService } from 'src/services/job-safety-analysis-wind-speed.service';
import { JobSafetyAnalysisService } from 'src/services/job-safety-analysis.service';
import { UIService } from 'src/services/ui.service';
import { UserService } from 'src/services/user.service';
import { JobService } from '../../../../services/job.service';
import { WorkLogService } from '../../../../services/work-log.service';

@Component({
  selector: 'app-job-safety-analysis',
  templateUrl: './job-safety-analysis.component.html',
  styleUrls: ['./job-safety-analysis.component.css']
})

export class JobSafetyAnalysisComponent implements OnInit, OnDestroy {
  @Input() jobId: number;
  @Input() jobDate: Date;
  @Input() lastJobDay: Date;
  @Input() jsaIndex: number;
  @Input() jobStatusIsTerminal: boolean;
  @Input() wellRig: string;
  @Input() jobSubStatusId: number;
  @Input() model: JobSafetyAnalysisModel;
  @Input() contractorTypes: ContractorTypeModel[];
  @Input() weatherTypes: JobSafetyAnalysisWeatherModel[];
  @Input() windDirections: JobSafetyAnalysisWindDirectionModel[];
  @Input() windSpeeds: JobSafetyAnalysisWindDirectionModel[];
  @Output() deleteJsaEvent = new EventEmitter<number>();
  @Output() editJsaEvent = new EventEmitter<boolean>();

  public bsModalRef: BsModalRef;
  public JobTypes = JobTypes;
  public BillingCodes = BillingCodes;
  public initialDateSave = true;
  public currentWeather: WeatherApiModel = null;
  public JobSubStatuses = JobSubStatuses;
  public showOtherContractors = false;
  public editJsa: boolean = false;

  public activeTabId: string = 'jsaTab_0';

  private isTabletSub: Subscription;
  public isTablet: boolean = false;

  private saveJobStepRowSub: Subscription;
  private deleteJobStepRowSub: Subscription;
  private saveSignatureRowSub: Subscription;
  private deleteSignatureRowSub: Subscription;
  private saveSub: Subscription;
  private weatherSub: Subscription;

  constructor(
    public uiService: UIService,
    public jobService: JobService,
    private modalService: BsModalService,
    public workLogService: WorkLogService,
    public appStateService: AppStateService,
    public userService: UserService,
    public contractorTypeService: ContractorTypeService,
    public jobSafetyAnalysisWeatherService: JobSafetyAnalysisWeatherService,
    public jobSafetyAnalysisWindDirectionService: JobSafetyAnalysisWindDirectionService,
    public jobSafetyAnalysisWindSpeedService: JobSafetyAnalysisWindSpeedService,
    public jobSafetyAnalysisService: JobSafetyAnalysisService
  ) { };

  ngOnDestroy(): void {
    this.isTabletSub?.unsubscribe();

    this.saveJobStepRowSub?.unsubscribe();

    this.deleteJobStepRowSub?.unsubscribe();

    this.saveSignatureRowSub?.unsubscribe();

    this.deleteSignatureRowSub?.unsubscribe();

    this.saveSub?.unsubscribe();

    this.weatherSub?.unsubscribe();
  }

  ngOnInit(): void {
    this.determineIsTablet();

    this.determineShowOtherContractors();
  }

  public determineIsTablet(): void {
    this.isTabletSub = this.appStateService.isTablet$
      .subscribe((isTablet: boolean) => {
        this.isTablet = isTablet;
      });
  }

  public determineShowOtherContractors(): void {
    this.showOtherContractors = this.model.contractorTypeIds.find((x: number) => x === ContractorTypes.Other) !== undefined;
  }

  public enableEditJsa($event: boolean): void {
    this.editJsa = $event;
    this.editJsaEvent.emit($event);
  }

  public onChangeOfContractor() {
    this.determineShowOtherContractors();
  }

  public addJobStepRow(): void {
    this.model.jobSafetyAnalysisJobSteps.push(this.jobSafetyAnalysisService.generateNewJobSafetyAnalysisJobStep(this.model.jobSafetyAnalysisId));

    this.jobSafetyAnalysisService.determineIfJsasAreComplete(
      this.jobId,
      this.jobDate,
      this.jobSubStatusId,
      this.lastJobDay);
  }

  public saveJobStepRow(dto: JobSafetyAnalysisJobStepModel): void {
    this.saveJobStepRowSub = this.jobSafetyAnalysisService.addEditJobSafetyAnalysisJobStep(dto)
      .subscribe((x: boolean) => {
        if (!x) {
          this.uiService.showError('Job Step NOT Saved', 'Error');
        } else {
          this.jobSafetyAnalysisService.determineIfJsasAreComplete(
            this.jobId,
            this.jobDate,
            this.jobSubStatusId,
            this.lastJobDay);
        }
      });
  }

  public deleteJobStepRow(id: number): void {
    this.deleteJobStepRowSub = this.jobSafetyAnalysisService.deleteJobSafetyAnalysisJobStep(id, this.model.jobSafetyAnalysisId)
      .subscribe((x: boolean) => {
        if (!x) {
          this.uiService.showError('Job Step NOT Deleted', 'Error');
        } else {
          this.uiService.showSuccess('Job Step Deleted', 'Success');

          this.jobSafetyAnalysisService.determineIfJsasAreComplete(
            this.jobId,
            this.jobDate,
            this.jobSubStatusId,
            this.lastJobDay);
        }
      });
  }

  public addSignatureRow(): void {
    this.model.jobSafetyAnalysisCrewSignatures
      .push(this.jobSafetyAnalysisService.generateNewJobSafetyAnalysisCrewSignatureModel(this.model.jobSafetyAnalysisId));

    this.jobSafetyAnalysisService.determineIfJsasAreComplete(
      this.jobId,
      this.jobDate,
      this.jobSubStatusId,
      this.lastJobDay);
  }

  public saveSignatureRow(dto: JobSafetyAnalysisCrewSignatureModel): void {
    this.saveSignatureRowSub = this.jobSafetyAnalysisService.addEditJobSafetyAnalysisCrewSignature(dto)
      .subscribe((x: boolean) => {
        if (!x) {
          this.uiService.showError('Crew Signature NOT Saved', 'Error');
        } else {
          this.jobSafetyAnalysisService.determineIfJsasAreComplete(
            this.jobId,
            this.jobDate,
            this.jobSubStatusId,
            this.lastJobDay);
        }
      });
  }

  public deleteSignatureRow(id: number): void {
    this.deleteSignatureRowSub = this.jobSafetyAnalysisService.deleteJobSafetyAnalysisCrewSignature(id, this.model.jobSafetyAnalysisId)
      .subscribe((x: boolean) => {
        if (!x) {
          this.uiService.showError('Signature NOT Deleted', 'Error');
        } else {
          this.uiService.showSuccess('Signature Deleted', 'Success');
          this.jobSafetyAnalysisService.determineIfJsasAreComplete(
            this.jobId,
            this.jobDate,
            this.jobSubStatusId,
            this.lastJobDay);
        }
      });
  }

  public onBlurOfTemp(): void {
    if (this.model.temperature !== null) {
      this.model.temperature = Math.round(this.model.temperature);
      this.model.temperature = this.model.temperature > 140 ? 140 : this.model.temperature;
      this.model.temperature = this.model.temperature < -70 ? -70 : this.model.temperature;
    }
  }

  public deleteJsa(): void {
    this.deleteJsaEvent.emit(this.model.jobSafetyAnalysisId);
  }

  public saveJsa($event: any): void {
    if (($event || (!$event && !this.initialDateSave)) && !this.initialDateSave) {
      if (!this.model.otherSafetyEquipment) {
        this.model.otherSafetyEquipmentDescription = null;
      }

      if (!this.model.otherWorkingConditions) {
        this.model.otherWorkingConditionsDescription = null;
      }

      if (!this.showOtherContractors) {
        this.model.otherContractorDescription = null;
      }

      this.saveSub = this.jobSafetyAnalysisService.addEditJobSafetyAnalysis(this.model)
        .subscribe((x: boolean) => {
          if (!x) {
            this.uiService.showError('JSA Not Saved', 'Error');
          } else {
            this.jobSafetyAnalysisService.determineIfJsasAreComplete(
              this.jobId,
              this.jobDate,
              this.jobSubStatusId,
              this.lastJobDay);
          }
        });
    }

    if (this.initialDateSave && $event instanceof Date) {
      this.initialDateSave = false;
    }
  }

  public blurThis($event: any): void {
    if ($event && $event.target) {
      $event.target.blur();
    }
  }

  public getJsaWeather(): void {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position: GeolocationPosition) => {
          this.weatherSub = this.jobSafetyAnalysisWeatherService.getJsaWeather(position)
            .subscribe((x: WeatherApiModel) => {
              if (x) {
                this.model.weatherId = x.weatherId;
                this.model.temperature = x.temperatureRounded;
                this.model.windSpeedId = x.windSpeedId;
                this.model.windDirectionId = x.windDirectionId;

                this.saveJsa('click');

                this.currentWeather = x;

                this.jobSafetyAnalysisService.determineIfJsasAreComplete(
                  this.jobId,
                  this.jobDate,
                  this.jobSubStatusId,
                  this.lastJobDay);
              }
            });
        },
        (error: any) => {
          console.log('Geolocation error:', error.message);
        }
      );
    } else {
      console.log('Geolocation is not supported by this browser.');
    }
  }

}
