<div class="modal-header"
  ngxModalDraggable>
  <h4 class="modal-title pull-left">Role Map</h4>
  <button type="button"
    class="close pull-right"
    aria-label="Close"
    (click)="bsModalRef.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div class="card">
    <div class="card-body">
      <div class="form-group row mb-0">
        <label for="title"
          class="col-sm-1 text-right control-label col-form-label">Roles</label>
        <div class="col-sm-5 form-group mb-0">
          <ng-select [items]="userService.roleList | async"
            [multiple]="true"
            [closeOnSelect]="false"
            [searchable]="false"
            placeholder="Select Roles"
            [(ngModel)]="selectedRoles"
            (change)="filterRoleMap()"
            name="activeRoles">
          </ng-select>
        </div>
        <label for="title"
          class="col-sm-1 text-right control-label col-form-label">Users</label>
        <div class="col-sm-5 form-group mb-0">
          <ng-select [items]="userService.userList | async"
            [searchable]="true"
            bindLabel="roleMapDisplayName"
            bindValue="id"
            placeholder="Select User"
            [(ngModel)]="selectedUserId"
            (change)="filterRoleMap()"
            name="activeRoles">
          </ng-select>
        </div>
      </div>
    </div>
  </div>
  <div class="row"
    *ngIf="filteredRoleMap">
    <div class="col-md-4"
      *ngFor="let r of filteredRoleMap">
      <div class="card">
        <div class="card-header bg-light header-elements-sm-inline pt-1 pb-1 pl-2">
          <h6 class="card-title">{{r.role}}</h6>
        </div>
        <div class="card-body pt-1 pb-1">
          <ul class="pl-1 mb-0">
            <li *ngFor="let u of r.userNames">
              <span [ngClass]="{ 'font-weight-bold' : u.id === user.id, 'highlight' : u.id === selectedUserId }"><span
                  *ngIf="u.firstName && u.lastName">{{u.firstName}} {{u.lastName}} - </span>({{u.userName}})</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="!filteredRoleMap"
    class="row">
    <div class="col-md-4"
      *ngFor="let r of numOfSkeletons">
      <app-list-skeleton [numberToIterate]="3"></app-list-skeleton>
    </div>
  </div>
</div>
<div class="modal-footer">
  <button type="button"
    class="btn btn-default"
    (click)="bsModalRef.hide()">Close</button>
</div>
