import { Component, OnDestroy, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { AddressTypes } from 'src/enums/address-types.enum';
import { CustomerModel } from 'src/models/customer.model';
import { PaymentTermModel } from 'src/models/payment-term.model';
import { AddressService } from 'src/services/address.service';
import { CustomerService } from 'src/services/customer.service';
import { PaymentTermService } from 'src/services/payment-term.service';
import { UIService } from 'src/services/ui.service';

@Component({
  selector: 'app-add-edit-customer-modal',
  templateUrl: './add-edit-customer-modal.component.html',
  styleUrls: ['./add-edit-customer-modal.component.css']
})

export class AddEditCustomerModalComponent implements OnInit, OnDestroy {
  private termsSub: Subscription;
  public terms: PaymentTermModel[];

  public modelSub: Subscription;
  public model: CustomerModel;

  private saveSub: Subscription;

  public isNew: boolean = false;
  public id: number = 0;
  public cacheCustomer: boolean = false;

  public disableSave = false;

  constructor(
    public bsModalRef: BsModalRef,
    public customerService: CustomerService,
    public uiService: UIService,
    public termService: PaymentTermService,
    public addressService: AddressService,

  ) { };

  ngOnDestroy(): void {
    this.termsSub?.unsubscribe();

    this.modelSub?.unsubscribe();

    this.saveSub?.unsubscribe();
  }

  ngOnInit(): void {
    this.isNew = this.id === 0;

    this.getTerms();

    this.addressService.refreshAddressList(
      AddressTypes.Customer,
      this.id,
      false);

    if (this.isNew) {
      this.model = this.customerService.generateNewCustomerModel();
    } else {
      this.getModel();
    }
  }

  public getTerms(): void {
    this.termsSub = this.termService.paymentTermList$
      .subscribe((terms: PaymentTermModel[]) => {
        if (terms) {
          this.terms = terms;
        }
      });
  }

  public getModel(): void {
    this.modelSub = this.customerService.getCustomerById(this.id)
      .subscribe((x: CustomerModel) => {
        this.model = x;
      });
  }

  public onSave(): void {
    this.disableSave = true;

    this.saveSub = this.customerService.addEditCustomer(
      this.model,
      this.cacheCustomer)
      .subscribe((x: boolean) => {
        if (x) {
          this.bsModalRef.hide();
          this.uiService.showSuccess('Customer Saved', 'Success');
        } else {
          this.disableSave = false;
          this.uiService.showError('Customer Not Saved', 'Error');
        }
      });
  }

}
