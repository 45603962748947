export class WorksheetRowItemTypeModel {
  worksheetRowItemTypeId: number;
  worksheetRowTypeId: number | null;
  worksheetRowTypeName: string;
  worksheetTypeId: number | null; 
  worksheetTypeName: string;
  itemId: number | null;
  itemDescription: string;
  billingCodeId: number | null;
  billingCodeName: string;
  optionBillingCodeName: string;
  optionBillingCodeId: number | null;
  sortOrder: number | null;
  name: string;
  isOption: boolean | null;
  chargeItemizedOption: boolean;
  zeroCharge: boolean;
  isNote: boolean;
  isActive: boolean | null;
}
