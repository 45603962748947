import { BehaviorSubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { WorksheetCombinedModel } from 'src/models/worksheet-combined.model';

@Injectable()
export class WorksheetSnapshotService {
  constructor(
    private http: HttpClient,
    @Inject('BASE_URL') private baseUrl: string,
    public router: Router) { }

  public snapshotList: BehaviorSubject<Array<WorksheetCombinedModel>> = new BehaviorSubject(null);

  private apiUrl = 'api/WorksheetSnapshot';  // URL to web api

  public getSnapshotsByWorksheetId(worksheetId: number): void {
    this.http.get<WorksheetCombinedModel[]>(this.baseUrl + this.apiUrl + '/GetSnapshotsByWorksheetId/' + worksheetId)
      .subscribe((x: WorksheetCombinedModel[]) => {
        this.snapshotList.next(x);
      });
  }

}
