import { Component, OnDestroy, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { InventoryHistoryModel } from 'src/models/inventory-history.model';
import { ShopModel } from 'src/models/shop.model';
import { InventoryHistoryService } from 'src/services/inventory-history.service';
import { ItemService } from 'src/services/item.service';
import { UIService } from 'src/services/ui.service';
import { ShopItemModel } from '../../../models/shop-item.model';
import { ShopService } from '../../../services/shop.service';
import { ItemModel } from 'src/models/item.model';

@Component({
  selector: 'app-manual-inventory-adjustment-modal',
  templateUrl: './manual-inventory-adjustment-modal.component.html',
  styleUrls: ['./manual-inventory-adjustment-modal.component.css']
})

export class ManualInventoryAdjustmentModalComponent implements OnInit, OnDestroy {
  private shopsSub: Subscription;
  public shops: ShopModel[];

  private inventorySub: Subscription;
  private saveSub: Subscription;

  public isNew: boolean = false;
  public itemId: number = -1;
  public shopId: number = -1;
  public model: InventoryHistoryModel;
  public currentInventory: ShopItemModel[] = [];
  public inventoryDisplay: number;
  public itemIsLoaded: boolean = false;
  public disableSave: boolean = false;

  constructor(
    public bsModalRef: BsModalRef,
    public inventoryHistoryService: InventoryHistoryService,
    public uiService: UIService,
    public itemService: ItemService,
    public shopService: ShopService,

  ) { };

  ngOnDestroy(): void {
    this.shopsSub?.unsubscribe();

    this.inventorySub?.unsubscribe();

    this.saveSub?.unsubscribe();
  }

  ngOnInit(): void {
    this.model = this.inventoryHistoryService.generateNewInventoryHistoryModel(
      this.itemId,
      this.shopId);

    this.getShops();

    this.getCurrentInventory();
  }

  public getShops(): void {
    this.shopsSub = this.shopService.shopList$
      .subscribe((shops: ShopModel[]) => {
        if (shops) {
          this.shops = shops;
        }
      });
  }

  public getCurrentInventory(): void {
    this.inventorySub = this.itemService.getItemById(this.itemId)
      .subscribe((x: ItemModel) => {
        this.currentInventory = x !== null && x.shopItems !== undefined && x.shopItems !== null ? x.shopItems : [];
        this.itemIsLoaded = true;
        this.onChangeOfShop(this.model.shopId);
      });
  }

  public calculateInventoryOnHand(): void {
    this.model.inventoryOnHandAfterChange = this.model.inventoryChange + this.inventoryDisplay;
  }

  public onChangeOfShop($event: number): void {
    if ($event !== undefined) {
      const si = this.currentInventory.filter((x: ShopItemModel) => x.shopId === $event);

      if (si.length > 0) {
        this.inventoryDisplay = si[0].inventoryCount;

        if (this.model.inventoryChange) {
          this.calculateInventoryOnHand();
        }
      }
    }
  }

  public onSave(): void {
    this.disableSave = true;

    this.saveSub = this.inventoryHistoryService.manualInventoryAdjustment(this.model)
      .subscribe((x: boolean) => {
        if (x) {
          this.bsModalRef.hide();

          this.uiService.showSuccess('Inventory Adjusted', 'Success');
        } else {
          this.uiService.showError('Inventory Not Adjusted', 'Error');

          this.disableSave = false;
        }
      });
  }

}
