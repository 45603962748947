import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Observer, tap } from 'rxjs';
import { MaintenanceEventFilterParamsModel } from 'src/models/RequestParams/maintenance-event-filter-params.model copy';
import { MaintenanceEventModel } from 'src/models/maintenance-event.model';
import { UserService } from './user.service';
import { MaintenanceTypes } from 'src/enums/maintenance-types';
import { TimezoneService } from './timezone.service';
import { PurchaseOrderLineModel } from 'src/models/purchase-order-line.model';

@Injectable()
export class MaintenanceEventService {
  constructor(
    private http: HttpClient,
    private userService: UserService,
    private timezoneService: TimezoneService,
    @Inject('BASE_URL') private baseUrl: string
  ) { }

  public maintenanceEventList: BehaviorSubject<Array<MaintenanceEventModel>> = new BehaviorSubject(null);
  public maintenanceEventByItemInstanceList: BehaviorSubject<Array<MaintenanceEventModel>> = new BehaviorSubject(null);
  public maintenanceEventByVendorList: BehaviorSubject<Array<MaintenanceEventModel>> = new BehaviorSubject(null);
  public maintenanceEventFilterParams: MaintenanceEventFilterParamsModel = new MaintenanceEventFilterParamsModel();
  public maintenanceEventsAreLoading = false;

  private apiUrl = 'api/MaintenanceEvent';  // URL to web api

  public addEditMaintenanceEvent(
    dto: MaintenanceEventModel,
    showInactive: boolean = false,
    refreshByItemInstance: boolean = false): Observable<boolean> {
    return new Observable((observer: Observer<boolean>) => {
      this.http.post<number>(this.baseUrl + this.apiUrl + '/AddEditMaintenanceEvent', this.cleanMaintenanceEventModel(dto))
        .subscribe((x: number) => {

          if (x !== -1 && !refreshByItemInstance) {
            this.getMaintenanceEvents(showInactive, true, false);
          } else {
            this.getMaintenanceEventsByItemInstanceId(dto.itemInstanceId, showInactive, true, false);
          }

          observer.next(x !== -1);
        });
    });
  }

  public deleteMaintenanceEvent(
    id: number,
    showInactive: boolean,
    refreshByItemInstance: boolean = false): Observable<boolean> {
    return new Observable((observer: Observer<boolean>) => {
      this.http.get<boolean>(this.baseUrl + this.apiUrl + '/DeleteMaintenanceEvent/' + id)
        .subscribe((x: boolean) => {
          if (x === true) {
            if (!refreshByItemInstance) {
              this.getMaintenanceEvents(showInactive, true, false);
            } else {
              this.getMaintenanceEventsByItemInstanceId(id, showInactive, true, false);
            }
          }
          observer.next(x);
        });
    });
  }

  public getMaintenanceEventById(id: number, canSeePrices: boolean): Observable<MaintenanceEventModel> {
    return this.http.get<MaintenanceEventModel>(this.baseUrl + this.apiUrl + '/GetMaintenanceEventById/' + id + '/' + canSeePrices)
      .pipe(tap((x: MaintenanceEventModel) => {
        x.maintenanceDate = x.maintenanceDate ? new Date(x.maintenanceDate) : null;
        x.purchaseOrderLines.forEach((y: PurchaseOrderLineModel) => {
          y.dropDownDisplay =
            `${new Date(y.poDate).toLocaleDateString()} - ${y.ponumberDisplay} -  ${y.description}`;
        });
      }));
  }

  public getMaintenanceEvents(
    showInactive: boolean,
    clear = false,
    showSkeletons: boolean = false): void {
    if (clear) {
      if (showSkeletons) {
        this.maintenanceEventsAreLoading = true;
      }

      const canSeePrices = this.canSeePrices();

      this.http.post<Array<MaintenanceEventModel>>(
        this.baseUrl + this.apiUrl + '/GetMaintenanceEvents/' + showInactive + '/' + canSeePrices, this.maintenanceEventFilterParams)
        .pipe(tap((x: MaintenanceEventModel[]) => {
          x.forEach((m: MaintenanceEventModel) => {
            m.maintenanceDate = m.maintenanceDate ? new Date(m.maintenanceDate) : null;

            m.purchaseOrderLines.forEach((y: PurchaseOrderLineModel) => {
              y.dropDownDisplay = '';
              y.dropDownDisplay += y.ponumberDisplay;
              y.dropDownDisplay += y.description ? ` - ${y.description}` : '';
            });
          });
        }))
        .subscribe((x: MaintenanceEventModel[]) => {
          this.maintenanceEventList.next(x);
          this.maintenanceEventsAreLoading = false;
        });
    }
  }

  public getMaintenanceEventsByItemInstanceId(
    itemInstanceId: number,
    showInactive: boolean,
    clear = false,
    showSkeletons: boolean = false): void {
    if (clear) {
      if (showSkeletons) {
        this.maintenanceEventsAreLoading = true;
      }

      const canSeePrices = this.canSeePrices();

      this.http.get<Array<MaintenanceEventModel>>(
        this.baseUrl + this.apiUrl + '/GetMaintenanceEventsByItemInstanceId/' + itemInstanceId + '/' + showInactive + '/' + canSeePrices)
        .pipe(tap((x: MaintenanceEventModel[]) => {
          x.forEach((m: MaintenanceEventModel) => {
            m.maintenanceDate = m.maintenanceDate ? new Date(m.maintenanceDate) : null;

            m.purchaseOrderLines.forEach((y: PurchaseOrderLineModel) => {
              y.dropDownDisplay = '';
              y.dropDownDisplay += y.ponumberDisplay;
              y.dropDownDisplay += y.description ? ` - ${y.description}` : '';
            });
          });
        }))
        .subscribe((x: MaintenanceEventModel[]) => {
          this.maintenanceEventByItemInstanceList.next(x);
          this.maintenanceEventsAreLoading = false;
        });
    }
  }

  public getMaintenanceEventsByVendorId(
    vendorId: number,
    showInactive: boolean,
    clear = false,
    showSkeletons: boolean = false): void {
    if (clear) {
      if (showSkeletons) {
        this.maintenanceEventsAreLoading = true;
      }

      const canSeePrices = this.canSeePrices();

      this.http.get<Array<MaintenanceEventModel>>(
        this.baseUrl + this.apiUrl + '/GetMaintenanceEventsByVendorId/' + vendorId + '/' + showInactive + '/' + canSeePrices)
        .pipe(tap((x: MaintenanceEventModel[]) => {
          x.forEach((m: MaintenanceEventModel) => {
            m.maintenanceDate = m.maintenanceDate ? new Date(m.maintenanceDate) : null;

            m.maintenanceEventDropDisplay = `${m.maintenanceDate.toLocaleDateString()} - ${m.maintenanceTypeName} ${m.itemDescription}-${m.serialNumber}`;
          });
        }))
        .subscribe((x: MaintenanceEventModel[]) => {
          this.maintenanceEventByVendorList.next(x);
          this.maintenanceEventsAreLoading = false;
        });
    }
  }

  public clearFilterParams(showInactive: boolean) {
    Object.keys(this.maintenanceEventFilterParams).forEach((index) => {
      this.maintenanceEventFilterParams[index] = null;
    });

    this.getMaintenanceEvents(showInactive, true);
  }

  public cleanMaintenanceEventModel(model: MaintenanceEventModel): MaintenanceEventModel {
    Object.keys(model).forEach((index: string) => {
      if (typeof model[index] === 'string') {
        model[index] = model[index]
          && model[index].trim() !== '' ?
          model[index].trim()
          : null;
      } else if (model[index] instanceof Date
        && !isNaN(model[index])) {
        model[index] = this.timezoneService.correctOffset(model[index]);
      }
    });

    return model;
  }

  public generateNewMaintenanceEventModel(itemInstanceId: number = null): MaintenanceEventModel {
    let model: MaintenanceEventModel = new MaintenanceEventModel();

    if (itemInstanceId) {
      const lastMaintenanceRecord = this.maintenanceEventByItemInstanceList.value[0];
      model.itemInstanceId = itemInstanceId;
      model.maintenanceTypeId = lastMaintenanceRecord ? lastMaintenanceRecord.maintenanceTypeId : null;
      model.vendorId = lastMaintenanceRecord ? lastMaintenanceRecord.vendorId : null;
      model.maintenanceDurationUom = lastMaintenanceRecord ? lastMaintenanceRecord.maintenanceDurationUom : null;
    } else {
      model.itemInstanceId = this.maintenanceEventFilterParams.itemInstanceId;
      model.maintenanceTypeId = this.maintenanceEventFilterParams.maintenanceTypeId ?? MaintenanceTypes.ChangeEngineOil;
      model.vendorId = this.maintenanceEventFilterParams.vendorId;
    }

    model.maintenanceDate = new Date();
    model.hrsMileage = 0;
    model.notes = null;
    model.isActive = true;
    model.inHouseRepair = !model.vendorId;

    return model;
  }

  private canSeePrices(): boolean {
    return this.userService.user.value != null
      && this.userService.user.value.activeRoles != null ?
      this.userService.user.value.activeRoles.includes('General')
      : false;
  }

  public getNumberOfActiveFilterFields(): number {
    let count: number = 0;

    Object.values(this.maintenanceEventFilterParams).forEach((value: any) => {
      count = value ? count += 1 : count;
    });

    return count;
  }

}
