<div class="modal-header"
  ngxModalDraggable>
  <h4 class="modal-title pull-left"
    *ngIf="model">{{isNew ? 'Add' : 'Edit'}}&nbsp;Day {{model.dayNumber}}</h4>
  <button type="button"
    class="close pull-right"
    aria-label="Close"
    (click)="bsModalRef.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body"
  *ngIf="model">
  <div class="form-group row">
    <label for="title"
      class="col-sm-2 text-right control-label col-form-label">Job Date</label>
    <div class="col-sm-10 form-group mb-0">
      <input type="text"
        placeholder="mm/dd/yyyy"
        class="form-control"
        [ngClass]="{'border-danger': dateIsInUse}"
        [(ngModel)]="model.date"
        (bsValueChange)="onChangeOfDate($event)"
        required
        bsDatepicker>
      <p class="text-danger mb-0 mt-1"
        *ngIf="dateIsInUse"><i class="fas fa-exclamation-triangle"></i>&nbsp;<strong>This date has already been
          created!</strong></p>
    </div>
  </div>
</div>
<div class="modal-footer"
  *ngIf="model">
  <button type="button"
    class="btn btn-primary"
    (click)="onSave()"
    [disabled]="disableSave 
      || !(model.date 
        && !dateIsInUse)">Save</button>
  <button type="button"
    class="btn btn-default"
    (click)="bsModalRef.hide()">Cancel</button>
</div>
