<div class="card">
  <div class="card-header bg-light header-elements-inline">
    <h6 class="card-title">Operations Breakdown</h6>
    <div class="header-elements">
      <button class="btn btn-light btn-sm"
        (click)="openWorkLogModal(0)"
        [disabled]="(jobService.job.value !== null && jobService.job.value.invoiceHasBeenSplit === true) || (jobService.job.value !== null && jobService.job.value.jobStatusIsTerminal === true)"><i
          class="fas fa-plus"></i> Add</button>
    </div>
  </div>
  <div class="card-body"
    [ngClass]="{'pl-2 pr-2':isTablet}">
    <div class="table-responsive">
      <div class="alert alert-primary"
        role="alert"
        *ngIf="logs.length === 0">
        No <strong>Work Logs</strong> added for {{dayDate | date:'longDate'}}. Hit the <strong><i
            class="fas fa-plus"></i> Add</strong> button to create a new Work Log.
      </div>
      <!-- KENDO GRID -->
      <kendo-grid *ngIf="logs.length > 0"
        [data]="logs"
        filterable="false"
        [sortable]="false"
        [resizable]="true">
        <kendo-grid-column field="one"
          title=""
          [width]="60"
          filterable="false">
          <ng-template kendoGridCellTemplate
            let-dataItem
            let-rowIndex="rowIndex">
            <button class="btn btn-xs btn-default"
              (click)="openWorkLogModal(dataItem.workLogId)"
              [disabled]="(jobService.job.value !== null && jobService.job.value.invoiceHasBeenSplit === true) || jobService.job.value !== null && jobService.job.value.jobStatusIsTerminal === true"><i
                class="fa fa-pencil"></i></button>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="hours"
          title="Hours"
          [width]="60"
          filterable="false">
          <ng-template kendoGridCellTemplate
            let-dataItem
            let-rowIndex="rowIndex">
            {{dataItem.hours | number:'1.2-2'}}
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="workStart"
          title="From"
          [width]="90"
          filterable="false">
          <ng-template kendoGridCellTemplate
            let-dataItem
            let-rowIndex="rowIndex">
            {{dataItem.workStart | date:'HH:mm'}}
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="workEnd"
          title="To"
          [width]="90"
          filterable="false">
          <ng-template kendoGridCellTemplate
            let-dataItem
            let-rowIndex="rowIndex">
            {{dataItem.workEnd | date:'HH:mm'}}
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="employeeFullName"
          title="Tool Supervisor"
          filterable="false"
          [width]="160">
        </kendo-grid-column>
        <kendo-grid-column [width]="60"
          filterable="false"
          *ngIf="jobTypeId === JobTypes.Packer">
          <ng-template kendoGridCellTemplate
            let-dataItem
            let-rowIndex="rowIndex">
            <span *ngIf="jobTypeId === JobTypes.Packer && dataItem.billingCodeId === BillingCodes.PackerToolSupervisor"
              class="text-muted"><span class="badge bg-success"
                title="FULL Day">FULL</span>&nbsp;</span>
            <span
              *ngIf="jobTypeId === JobTypes.Packer && dataItem.billingCodeId === BillingCodes.PackerToolSupervisorHalfDay"><span
                class="badge bg-secondary"
                title="Half Day">HALF</span>&nbsp;</span>
            <span
              *ngIf="jobTypeId === JobTypes.Packer && dataItem.billingCodeId !== BillingCodes.PackerToolSupervisorHalfDay && dataItem.billingCodeId !== BillingCodes.PackerToolSupervisor"
              class="text-muted">n/a</span>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="description"
          title="Description"
          filterable="false"
          [width]="200">
        </kendo-grid-column>
        <kendo-grid-column field="one"
          title=""
          [width]="60"
          filterable="false">
          <ng-template kendoGridCellTemplate
            let-dataItem
            let-rowIndex="rowIndex">
            <button class="btn btn-xs"
              [swal]="{ title: 'Delete Work Log?', text:dataItem.description, showCancelButton: true, confirmButtonText: 'Delete', confirmButtonColor: '#DC3545' }"
              (confirm)="deleteWorkLog(dataItem)"
              [disabled]="(jobService.job.value !== null && jobService.job.value.invoiceHasBeenSplit === true) || jobService.job.value !== null && jobService.job.value.jobStatusIsTerminal === true">
              <i class="fa fa-trash"></i>
            </button>
          </ng-template>
        </kendo-grid-column>
      </kendo-grid>
      <!--END KENDO GRID -->
    </div>
  </div>
</div>
