<div class="page-header">
  <div class="page-header-content header-elements-md-inline">
    <div class="page-title d-flex">
      <a [routerLink]="['/home']"
        class="breadcrumb-link-color">
        <h4><i class="icon-arrow-left52 mr-2"></i> <span class="font-weight-semibold">Invoices</span> - List</h4>
      </a>
      <button type="button"
        class="btn btn-outline-dark btn-sm ml-3 pl-1 pr-1 pt-0 pb-0 no-border"
        [title]="!showFilter ? 'Show Filter' : 'Hide Filter'"
        (click)="showFilter = !showFilter;getNumberOfActiveFilterFields();">
        <span *ngIf="!showFilter">
          <i class="fa-regular fa-chevrons-right"></i>&nbsp;Show </span>
        <span *ngIf="showFilter"><i class="fa-regular fa-chevrons-left"></i>&nbsp;Hide
        </span>Filter<span class="text-warning"
          *ngIf="!showFilter && numberOfActiveFilterFields > 0"><strong>&nbsp;&nbsp;({{numberOfActiveFilterFields}}
            active
            criteria)</strong></span></button>
    </div>
    <div class="header-elements d-none py-0 mb-3 mb-md-0">
      <div class="breadcrumb">
        <a href="index.html"
          class="breadcrumb-item"><i class="icon-home2 mr-2"></i> Invoices</a>
        <span class="breadcrumb-item active">List</span>
      </div>
    </div>
  </div>
</div>
<div class="page-content pt-0">
  <div class="content-wrapper">
    <div class="content">
      <div class="row">
        <div class="col-lg-3"
          *ngIf="showFilter">
          <div class="card">
            <div class="card-header bg-light header-elements-inline"
              [ngClass]="{'pr-2 pl-2':isTablet}">
              <h6 class="card-title">Filter Criteria</h6>
            </div>
            <div class="card-body"
              [ngClass]="{'pr-2 pl-2':isTablet}">
              <div class="form-group row">
                <label class="col-form-label col-lg-4 col-md-2">Invoice #</label>
                <div class="col-lg-8 col-md-4"
                  [ngClass]="{'pl-0':isTablet}">
                  <input type="number"
                    class="form-control"
                    [(ngModel)]="jobService.jobFilterParams.jobNumber"
                    name="jobNumber"
                    (keyup.enter)="jobService.getJobs(showCancelled, true);kGridService.pageChange({ skip: 0, take: kGridService.kGridParams.pageSize })">
                </div>
                <label class="col-form-label col-lg-4 col-md-2 mt-lg-3 mt-sm-0">Invoice Type</label>
                <div class="col-lg-8 col-md-4 mt-lg-3"
                  [ngClass]="{'pl-0':isTablet}"
                  *ngIf="jobTypes">
                  <ng-select [items]="jobTypes"
                    bindLabel="name"
                    bindValue="jobTypeId"
                    (change)="onChangeJobType($event)"
                    [clearable]="false"
                    [(ngModel)]="jobService.jobFilterParams.jobTypeId"
                    name="jobTypes">
                  </ng-select>
                </div>
                <div *ngIf="!jobTypes"
                  class="col-lg-8 col-md-4 mt-lg-3"
                  [ngClass]="{'pl-0':isTablet}">
                  <ng-select name="categories"
                    [readonly]="jobTypes">
                  </ng-select>
                </div>
              </div>
              <div class="form-group row">
                <label class="col-form-label col-lg-4 col-md-2">Shop</label>
                <div class="col-lg-8 col-md-4"
                  [ngClass]="{'pl-0':isTablet}"
                  *ngIf="shops">
                  <ng-select [items]="shops"
                    bindLabel="name"
                    bindValue="shopId"
                    [(ngModel)]="jobService.jobFilterParams.shopId"
                    name="shops"
                    appendTo='body'>
                  </ng-select>
                </div>
                <div *ngIf="!shops"
                  class="col-lg-8 col-md-4"
                  [ngClass]="{'pl-0':isTablet}">
                  <ng-select name="shops"
                    [readonly]="true">
                  </ng-select>
                </div>
                <label class="col-form-label col-lg-4 col-md-2 mt-lg-3 mt-sm-0">Customer</label>
                <div class="col-lg-8 col-md-4 mt-lg-3"
                  [ngClass]="{'pl-0':isTablet}"
                  *ngIf="customerService.customerList | async; let cust">
                  <ng-select [items]="cust"
                    bindLabel="name"
                    bindValue="customerId"
                    (change)="onCustomerChange($event)"
                    [(ngModel)]="jobService.jobFilterParams.customerId"
                    [clearable]="false"
                    name="customers">
                  </ng-select>
                </div>
                <div *ngIf="!customerService.customerList.value"
                  class="col-lg-8 col-md-4 mt-lg-3"
                  [ngClass]="{'pl-0':isTablet}">
                  <ng-select name="customers"
                    [readonly]="true">
                  </ng-select>
                </div>
              </div>
              <div class="form-group row">
                <label class="col-form-label col-lg-4 col-md-2">Well</label>
                <div class="col-lg-8 col-md-4"
                  [ngClass]="{'pl-0':isTablet}">
                  <ng-select [items]="localCustomerWells"
                    bindLabel="name"
                    bindValue="wellId"
                    [(ngModel)]="jobService.jobFilterParams.wellId"
                    style="padding:0px;"
                    name="wells"
                    [disabled]="localCustomerWells.length === 0">
                  </ng-select>
                </div>
                <label class="col-form-label col-lg-4 col-md-2 mt-lg-3 mt-sm-0">Ordered By</label>
                <div class="col-lg-8 col-md-4 mt-lg-3"
                  [ngClass]="{'pl-0':isTablet}">
                  <ng-select [items]="localCustomerContacts"
                    bindLabel="fullName"
                    bindValue="contactId"
                    [(ngModel)]="jobService.jobFilterParams.orderedById"
                    style="padding:0px;"
                    name="orderedBy"
                    [disabled]="localCustomerContacts.length === 0">
                  </ng-select>
                </div>
              </div>
              <div class="form-group row">
                <label class="col-form-label col-lg-4 col-md-2">Delivered By</label>
                <div class="col-lg-8 col-md-4"
                  [ngClass]="{'pl-0':isTablet}"
                  *ngIf="employeeService.employeeList | async; let emp">
                  <ng-select [items]="emp"
                    bindLabel="fullName"
                    bindValue="employeeId"
                    [(ngModel)]="jobService.jobFilterParams.deliveredById"
                    name="contacts">
                  </ng-select>
                </div>
                <div *ngIf="!employeeService.employeeList.value"
                  class="col-lg-8 col-md-4"
                  [ngClass]="{'pl-0':isTablet}">
                  <ng-select name="contacts"
                    [readonly]="true">
                  </ng-select>
                </div>
                <label class="col-form-label col-lg-4 col-md-2 mt-lg-3 mt-sm-0">Job Owner</label>
                <div class="col-lg-8 col-md-4 mt-lg-3"
                  [ngClass]="{'pl-0':isTablet}"
                  *ngIf="employeeService.employeeList | async; let emp">
                  <ng-select [items]="emp"
                    bindLabel="fullName"
                    bindValue="employeeId"
                    [(ngModel)]="jobService.jobFilterParams.jobOwnerId"
                    name="jobOwner">
                  </ng-select>
                </div>
                <div *ngIf="!employeeService.employeeList.value"
                  class="col-lg-8 col-md-4 mt-lg-3"
                  [ngClass]="{'pl-0':isTablet}">
                  <ng-select name="jobOwner"
                    [readonly]="true">
                  </ng-select>
                </div>
              </div>
              <div class="form-group row">
                <label class="col-form-label col-lg-4 col-md-2">Status</label>
                <div class="col-lg-8 col-md-4"
                  [ngClass]="{'pl-0':isTablet}"
                  *ngIf="jobStatusService.jobStatusList | async; let stat">
                  <ng-select [items]="stat"
                    bindLabel="name"
                    bindValue="jobStatusId"
                    [(ngModel)]="jobService.jobFilterParams.jobStatusId"
                    [clearable]="false"
                    name="jobStatus"
                    appendTo='body'>
                  </ng-select>
                </div>
                <div class="col-lg-8 col-md-4"
                  [ngClass]="{'pl-0':isTablet}"
                  *ngIf="(jobStatusService.jobStatusList | async)===null; let stat">
                  <input type="text"
                    class="form-control"
                    readonly>
                </div>
                <label class="col-form-label col-lg-4 col-md-2 mt-lg-3 mt-sm-0">Sub Status</label>
                <div class="col-lg-8 col-md-4 mt-lg-3"
                  [ngClass]="{'pl-0':isTablet}"
                  *ngIf="jobSubStatuses">
                  <ng-select [items]="jobSubStatuses"
                    bindLabel="name"
                    bindValue="jobSubStatusId"
                    [(ngModel)]="jobService.jobFilterParams.jobSubStatusId"
                    name="subStatuses"
                    appendTo='body'>
                  </ng-select>
                </div>
                <div *ngIf="!jobSubStatuses"
                  class="col-lg-8 col-md-4 mt-lg-3"
                  [ngClass]="{'pl-0':isTablet}">
                  <ng-select name="subStatuses"
                    [readonly]="true">
                  </ng-select>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-6">
                  <button type="submit"
                    class="btn btn-block btn-primary"
                    (click)="jobService.getJobs(showCancelled, true);kGridService.pageChange({ skip: 0, take: kGridService.kGridParams.pageSize })"><i
                      class="fa fa-search mr-2"></i>Search</button>
                </div>
                <div class="col-sm-6">
                  <button type="submit"
                    class="btn btn-block btn-light"
                    (click)="clearLocalArrays();jobService.clearFilterParams(showCancelled);kGridService.pageChange({ skip: 0, take: kGridService.kGridParams.pageSize })"><i
                      class="far fa-times-circle"></i>&nbsp;Clear</button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div [ngClass]="{'col-lg-9': showFilter, 'col-lg-12': !showFilter}">
          <div class="card border-top-primary border-top-2 rounded-top-0"
            style="border-top-color: #2196F3 !important;">
            <div class="card-header bg-light header-elements-sm-inline">
              <h6 class="card-title">Invoices</h6>
              <div class="header-elements">
                <div class="btn-group"
                  dropdown>
                  <div class="switch-container pt-2 mr-2">
                    <span class="switch-label">Show Cancelled </span>
                    <ui-switch [ngModel]="showCancelled"
                      (change)="onChangeShowCancelled($event)"></ui-switch>
                  </div>
                  <button class="btn btn-light btn-sm dropdown-toggle"
                    dropdownToggle
                    id="button-basic"
                    aria-controls="dropdown-basic">
                    <i class="fa fa-plus"></i> New Invoice
                    <span class="caret"></span>
                  </button>
                  <ul id="dropdown-basic"
                    *dropdownMenu
                    class="dropdown-menu dropdown-menu-right"
                    role="menu"
                    aria-labelledby="button-basic">
                    <li role="menuitem"><span class="dropdown-item"
                        (click)="openJobModal(0, JobTypes.Sales)"><i class="fal fa-truck-loading"></i> Sales
                        Invoice</span></li>
                    <li role="menuitem"><span class="dropdown-item"
                        (click)="openJobModal(0, JobTypes.Sales, true)"><i class="fal fa-quote-right"></i> Sales
                        Quote</span></li>
                    <li role="menuitem"><span class="dropdown-item"
                        (click)="openJobModal(0, JobTypes.Fishing)"><i class="fal fa-fish"></i> Fishing Invoice</span>
                    </li>
                    <li role="menuitem"><span class="dropdown-item"
                        (click)="openJobModal(0, JobTypes.FishingToolRental)"><i class="fal fa-tools"></i>Create Fishing
                        Tool Rental
                        Invoice</span></li>
                    <li role="menuitem"><span class="dropdown-item"
                        (click)="openJobModal(0, 5)"><i class="fal fa-screwdriver"></i>Create Packer
                        Invoice</span></li>
                  </ul>
                </div>
              </div>
            </div>
            <app-table-skeleton *ngIf="!jobService.jobsAreLoaded"></app-table-skeleton>
            <ng-container *ngIf="jobService.jobsAreLoaded">
              <div class="table-responsive"
                *ngIf="jobService.jobList | async; let model">
                <kendo-grid [kendoGridBinding]="model"
                  [style.maxHeight.px]="650"
                  filterable="menu"
                  [sortable]="true"
                  [resizable]="true"
                  [rowClass]="rowCallback"
                  style="min-width:1200px;"
                  [pageSize]="kGridService.kGridParams.pageSize"
                  [skip]="kGridService.kGridParams.skip"
                  (pageChange)="kGridService.pageChange($event)"
                  [pageable]="{
                              buttonCount: kGridService.kGridParams.buttonCount,
                              info: kGridService.kGridParams.info,
                              type: kGridService.kGridParams.type,
                              pageSizes: kGridService.kGridParams.pageSizes,
                              previousNext: kGridService.kGridParams.previousNext
                            }">
                  <kendo-grid-column field="one"
                    title=""
                    [width]="60"
                    filterable="false">
                    <ng-template kendoGridCellTemplate
                      let-dataItem
                      let-rowIndex="rowIndex">
                      <a *ngIf="dataItem.jobTypeId===JobTypes.Sales"
                        class="btn btn-xs btn-light"
                        [routerLink]="['/jobs/sales/details', dataItem.jobId]"><i class="fa fa-search"></i></a>
                      <a *ngIf="dataItem.jobTypeId===JobTypes.Fishing"
                        class="btn btn-xs btn-light"
                        [routerLink]="['/jobs/fishing/details', dataItem.jobId]"><i class="fa fa-search"></i></a>
                      <a *ngIf="dataItem.jobTypeId===JobTypes.FishingToolRental"
                        class="btn btn-xs btn-light"
                        [routerLink]="['/jobs/fishing-tool-rental/details', dataItem.jobId]"><i
                          class="fa fa-search"></i></a>
                      <a *ngIf="dataItem.jobTypeId===JobTypes.Packer"
                        class="btn btn-xs btn-light"
                        [routerLink]="['/jobs/packer/details', dataItem.jobId]"><i class="fa fa-search"></i></a>
                    </ng-template>
                  </kendo-grid-column>
                  <kendo-grid-column field="jobNumber"
                    title="Invoice #"
                    [width]="85"
                    filterable="false">
                  </kendo-grid-column>
                  <kendo-grid-column field="jobDate"
                    title="Date"
                    [width]="90"
                    filterable="false">
                    <ng-template kendoGridCellTemplate
                      let-dataItem
                      let-rowIndex="rowIndex">
                      {{dataItem.jobDate | date:'shortDate'}}
                    </ng-template>
                  </kendo-grid-column>
                  <kendo-grid-column field="jobTypeName"
                    title="Type"
                    [width]="110"
                    filterable="false">
                  </kendo-grid-column>
                  <kendo-grid-column field="jobStatusName"
                    title="Status"
                    [width]="80"
                    filterable="false">
                    <ng-template kendoGridCellTemplate
                      let-dataItem
                      let-rowIndex="rowIndex">
                      <span
                        [ngClass]="{ 'text-danger' : dataItem.jobStatusId === JobStatuses.Cancelled_Sales || dataItem.jobStatusId === JobStatuses.Cancelled_Fishing || dataItem.jobStatusId === JobStatuses.Cancelled_FishingToolRental ||  dataItem.jobStatusId === JobStatuses.Cancelled_Packer }">{{dataItem.jobStatusName}}</span>
                    </ng-template>
                  </kendo-grid-column>
                  <kendo-grid-column field="jobSubStatusName"
                    title="Sub Status"
                    [width]="100"
                    filterable="false">
                  </kendo-grid-column>
                  <kendo-grid-column field="wellName"
                    title="Well Site"
                    [width]="200">
                  </kendo-grid-column>
                  <kendo-grid-column field="customerName"
                    title="Customer"
                    filterable="false">
                  </kendo-grid-column>
                  <kendo-grid-column field="orderedByName"
                    title="Ordered By"
                    [width]="130">
                  </kendo-grid-column>
                  <kendo-grid-column field="countyName"
                    title="County"
                    [width]="100">
                  </kendo-grid-column>
                  <kendo-grid-column field="total"
                    title="Total"
                    [width]="120"
                    *ngIf="userService.user.value && userService.user.value.activeRoles && userService.user.value.activeRoles.includes('General')">
                    <ng-template kendoGridCellTemplate
                      let-dataItem
                      let-rowIndex="rowIndex">
                      <span *ngIf='dataItem.totalStamp !== null'>{{dataItem.totalStamp | currency}}</span>
                      <span *ngIf='dataItem.totalStamp === null'>{{0 | currency}}</span>
                    </ng-template>
                  </kendo-grid-column>
                </kendo-grid>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
