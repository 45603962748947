<div class="modal-header"
  ngxModalDraggable>
  <h4 class="modal-title pull-left">{{isNew ? 'Add' : 'Edit'}}&nbsp;Rig</h4>
  <button type="button"
    class="close pull-right"
    aria-label="Close"
    (click)="bsModalRef.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body"
  *ngIf="model">
  <div class="form-group row">
    <label for="title"
      class="col-sm-2 text-right control-label col-form-label">Name</label>
    <div class="col-sm-10 form-group mb-0">
      <input type="text"
        class="form-control form-control-sm"
        id="name"
        [(ngModel)]="model.name"
        name="name"
        required>
    </div>
  </div>
  <div class="form-group row">
    <label for="title"
      class="col-sm-2 text-right control-label col-form-label">County</label>
    <div class="col-sm-10 form-group mb-10"
      *ngIf="counties">
      <ng-select [items]="counties"
        bindLabel="name"
        bindValue="countyId"
        [(ngModel)]="model.countyId"
        name="county">
      </ng-select>
    </div>
  </div>
  <div class="form-group row">
    <label for="title"
      class="col-sm-2 text-right control-label col-form-label">Customer</label>
    <div class="col-sm-10 form-group mb-10"
      *ngIf="customerService.customerList | async; let customer">
      <ng-select [items]="customer"
        bindLabel="name"
        bindValue="customerId"
        [(ngModel)]="model.customerId"
        [disabled]="disableCustomerSelect"
        name="customer"
        required>
      </ng-select>
    </div>
  </div>
  <div class="form-group row">
    <label for="title"
      class="col-sm-2 text-right control-label col-form-label">Customer Notes</label>
    <div class="col-sm-10 form-group mb-0">
      <textarea type="text"
        class="form-control form-control-sm"
        id="customerNotes"
        [(ngModel)]="model.customerNotes"
        name="customerNotes"></textarea>
    </div>
  </div>
</div>
<div class="modal-footer"
  *ngIf="model">
  <button type="button"
    class="btn btn-primary"
    (click)="onSave()"
    [disabled]="disableSave || !(model.name&&model.customerId)">Save</button>
  <button type="button"
    class="btn btn-default"
    (click)="bsModalRef.hide()">Cancel</button>
</div>
