import { InventoryHistoryShopItemModel } from './inventory-history-shop-item.model';

export class InventoryHistoryModel {
  inventoryHistoryId: number;
  inventoryHistoryActionTypeId: number;
  inventoryHistoryActionTypeName: string;
  itemId: number;
  polineId: number;
  jobItemId: number;
  worksheetRowItemId: number;
  adjustmentDescription: string;
  inventoryChange: number;
  reserveChange: number;
  inventoryOnHandAfterChange: number;
  inventoryInReserveAfterChange: number;
  changedBy: number;
  changedByUserName: string;
  changedOn: Date;
  shopId: number;
  shopName: string;
  jobId: number | null;

  poId: number | null;
  frontEndJobId: number | null;
  displayDescription: string;
  inventoryChangeDisplay: string;
  reserveChangeDisplay: string;
  historyShopItems: InventoryHistoryShopItemModel[];

  totalInventoryOnHandAfterChange: number;
  totalInventoryInReserveAfterChange: number;

  shopInventoryChange: number;
  shopInventoryChangeDisplay: string;
  shopReserveChange: number;
  shopReserveChangeDisplay: string;
  shopInventoryOnHandAfterChange: number;
  shopInventoryInReserveAfterChange: number;
}



