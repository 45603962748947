<div class="modal-header"
  ngxModalDraggable>
  <h4 class="modal-title pull-left">Change Password</h4>
  <button type="button"
    class="close pull-right"
    aria-label="Close"
    (click)="bsModalRef.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<form (ngSubmit)="onSave()"
  #heroForm="ngForm">
  <div class="modal-body"
    *ngIf="model">
    <div class="form-group row">
      <label for="title"
        class="col-sm-2 text-right control-label col-form-label">Username</label>
      <div class="col-sm-10 form-group mb-0">
        <input type="text"
          class="form-control form-control-sm"
          id="userName"
          [(ngModel)]="model.userName"
          name="userName"
          readonly>
      </div>
    </div>
    <div class="form-group row mb-1">
      <label for="title"
        class="col-sm-2 text-right control-label col-form-label">Password</label>
      <div class="col-sm-10 form-group mb-0">
        <input type="password"
          class="form-control form-control-sm"
          id="password"
          [(ngModel)]="model.password"
          name="password"
          [ngClass]="{ 'border border-danger' : changePassword && !userService.verifyPassword(model.password) , 'border border-success' : changePassword && userService.verifyPassword(model.password) }">
      </div>
    </div>
    <div class="form-group row mb-2"
      *ngIf="!userService.verifyPassword(model.password)">
      <label for="title"
        class="col-sm-2 text-right control-label col-form-label"></label>
      <div class="col-sm-10 form-group mb-0">
        <div class="alert alert-danger"
          role="alert"
          style="margin-bottom: 0;padding: 4px;">
          <i class="fas fa-exclamation-triangle"></i>&nbsp;Password must contain:<br>
          <ul class="mb-0">
            <li>1 lower case character</li>
            <li>1 upper case character</li>
            <li>1 numerical character</li>
            <li>1 special character</li>
            <li>a minimum of <strong>8</strong> characters</li>
          </ul>
        </div>
      </div>
    </div>
    <div class="form-group row mb-2"
      *ngIf="userService.verifyPassword(model.password)">
      <label for="title"
        class="col-sm-2 text-right control-label col-form-label"></label>
      <div class="col-sm-10 form-group mb-0">
        <div class="alert alert-success"
          role="alert"
          style="margin-bottom: 0;padding: 4px;">
          <i class="fas fa-check-circle"></i>&nbsp;Password valid!
        </div>
      </div>
    </div>
    <div class="form-group row mb-1">
      <label for="title"
        class="col-sm-2 text-right control-label col-form-label">Confirm</label>
      <div class="col-sm-10 form-group mb-0">
        <input type="password"
          class="form-control form-control-sm"
          id="confirmPassword"
          [(ngModel)]="confirmPassword"
          name="confirmPassword"
          [ngClass]="{ 'border border-danger' : changePassword && (confirmPassword.length === 0 || (confirmPassword.length > 0 && model.password!==confirmPassword))  , 'border border-success' : changePassword && confirmPassword.length > 0 && model.password===confirmPassword }">
      </div>
    </div>
    <div class="form-group row mb-2"
      *ngIf="confirmPassword.length > 0 && model.password!==confirmPassword">
      <label for="title"
        class="col-sm-2 text-right control-label col-form-label"></label>
      <div class="col-sm-10 form-group mb-0">
        <div class="alert alert-danger"
          role="alert"
          style="margin-bottom: 0;padding: 4px;">
          <i class="fas fa-exclamation-triangle"></i>&nbsp;Passwords must match!
        </div>
      </div>
    </div>
    <div class="form-group row mb-2"
      *ngIf="confirmPassword.length > 0 && model.password===confirmPassword">
      <label for="title"
        class="col-sm-2 text-right control-label col-form-label"></label>
      <div class="col-sm-10 form-group mb-0">
        <div class="alert alert-success"
          role="alert"
          style="margin-bottom: 0;padding: 4px;">
          <i class="fas fa-check-circle"></i>&nbsp;Passwords match!
        </div>
      </div>
    </div>
  </div>
</form>
<div class="modal-footer"
  *ngIf="model">
  <button type="button"
    class="btn btn-primary"
    (click)="onSave()"
    [disabled]="disableSave || !(userService.verifyPassword(model.password)&&model.password===confirmPassword)">Save</button>
  <button type="button"
    class="btn btn-default"
    (click)="bsModalRef.hide()">Cancel</button>
</div>
