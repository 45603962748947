import { Component, OnDestroy, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { RoleWithUsersModel } from 'src/models/role-with-users';
import { UserModel } from 'src/models/user.model';
import { UserService } from 'src/services/user.service';

@Component({
  selector: 'app-role-map-modal',
  templateUrl: './role-map-modal.component.html',
  styleUrls: ['role-map-modal.component.css']
})

export class RoleMapModalComponent implements OnInit, OnDestroy {
  constructor(
    public bsModalRef: BsModalRef,
    public userService: UserService
  ) { };

  public numOfSkeletons: number[] = [];
  private userSub: Subscription;
  private roleMapSub: Subscription;
  public user: UserModel = null;
  public roleMap: RoleWithUsersModel[] = null;
  public filteredRoleMap: RoleWithUsersModel[] = null;
  public selectedRoles: Array<string> = [];
  public selectedUserId: number = null;

  ngOnDestroy(): void {
    this.userSub?.unsubscribe();

    this.roleMapSub?.unsubscribe();
  }

  ngOnInit(): void {
    this.numOfSkeletons = Array.from(Array(9), (x, i) => i);

    this.userService.getRolesForDropdown();

    this.subToUser();

    this.subToRoleMap();
  }

  public subToUser(): void {
    this.userSub = this.userService.user
      .subscribe((user: UserModel) => {
        if (user !== null) {
          this.user = user;

          this.userService.getRolesWithUsers();
        }
      });
  }

  public subToRoleMap(): void {
    this.roleMapSub = this.userService.rollsWithUsersList
      .subscribe((roleMap: RoleWithUsersModel[]) => {
        if (roleMap !== null) {
          this.roleMap = roleMap;

          this.filterRoleMap();
        }
      });
  }

  public filterRoleMap(): void {
    let interim: RoleWithUsersModel[] = this.roleMap;

    if (this.selectedRoles.length > 0) {
      interim = this.roleMap.filter((x: RoleWithUsersModel) => {
        return this.selectedRoles.includes(x.role);
      });
    }
    if (this.selectedUserId !== null) {
      interim = interim.filter((role: RoleWithUsersModel) => {
        return role.userNames.some(user => user.id === this.selectedUserId);
      });
    }

    this.filteredRoleMap = interim;
  }

}
