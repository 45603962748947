import { BehaviorSubject, Observable, Observer, tap } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ItemService } from './item.service';
import { UserService } from './user.service';
import { InventoryCountStatusModel } from 'src/models/inventory-count-status.model';

@Injectable()
export class InventoryCountStatusService {
  constructor(private http: HttpClient,
    @Inject('BASE_URL') private baseUrl: string,
    public itemService: ItemService,
    public userService: UserService
  ) { }

  private apiUrl = 'api/InventoryCountStatus';  // URL to web api

  private inventoryCountStatusSubject = new BehaviorSubject<InventoryCountStatusModel[]>(null);
  public _icsList$ = this.inventoryCountStatusSubject.asObservable();
  private icsCallInProgress = false;

  public get inventoryCountStatusList$(): Observable<InventoryCountStatusModel[]> {
    if (!this.inventoryCountStatusSubject.getValue() && !this.icsCallInProgress) {
      this.icsCallInProgress = true;
      this.getInventoryCountStatus$(false).subscribe(() => this.icsCallInProgress = false);
    }

    return this._icsList$;
  }

  private getInventoryCountStatus$(showInactive: boolean): Observable<InventoryCountStatusModel[]> {
    return this.http.get<Array<InventoryCountStatusModel>>(this.baseUrl + this.apiUrl + '/GetInventoryCountStatuses/' + showInactive)
      .pipe(
        tap((inventoryCountStatus: InventoryCountStatusModel[]) => {
          this.inventoryCountStatusSubject.next(inventoryCountStatus);
        })
      );
  }
}
