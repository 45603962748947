import { BhalineModel } from './bha-line.model';

export class BhaModel {

  constructor(bhaId: number, bhaNumber: number, date: Date, jobId: number, isActive: boolean = true) {
    this.bhaId = bhaId;
    this.bhaNumber = bhaNumber;
    this.date = date;
    this.jobId = jobId;
    this.isActive = isActive;
  }

  bhaId: number;
  bhaNumber: number;
  jobId: number;
  date: Date | string;
  description: string;
  operatorEmployeeId: number | null;
  operator: string;
  supervisorEmployeeId: number | null;
  supervisor: string;
  isActive: boolean | null;

  hasImage: boolean | null;
  bhalines: BhalineModel[];
  dayIds: number[];
  length: number | null;
}
