import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'firstOrDefault'
})
export class FirstOrDefaultPipe implements PipeTransform {

  transform(items: any[], idField: string, searchId: number): any {

    // return empty array if array is falsy
    if (!items) {
      return null;
    }

    // return the filtered array
    return items.filter(item => {
      if (item && item[idField]) {
        return item[idField] == searchId;
      }
      return false;
    })[0];
  }
}
