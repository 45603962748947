import { InventoryCountSubSummaryModel } from './inventory-count-sub-summary.model';

export class InventoryCountSummaryModel {
  inventoryCountId: number;
  employeeName: string;
  date: Date | string;
  status: string;
  notes: string;
  totalNegativeCostDifference: number = 0;
  totalPositiveCostDifference: number = 0;
  totalCostDifference: number = 0;
  totalInventoryValueInclNeg: number = 0;
  totalInventoryValueExclNeg: number = 0;

  partFamilySummaryList: InventoryCountSubSummaryModel[];
  fishingPartGroupSummaryList: InventoryCountSubSummaryModel[];
}
