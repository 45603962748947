import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, Observer } from 'rxjs';
import { tap } from 'rxjs/operators';
import { PackerFilterParamsModel } from 'src/models/RequestParams/packer-filter-params.model';
import { WorksheetCombinedModel } from 'src/models/worksheet-combined.model';
import { WorksheetSnapshotComparisonModel } from 'src/models/worksheet-snapshot-comparison.model';
import { WorksheetModel } from 'src/models/worksheet.model';
import { ItemService } from './item.service';
import { JobService } from './job.service';
import { WorksheetRowCombinedModel } from 'src/models/worksheet-row-combined.model';

@Injectable()
export class WorksheetService {
  constructor(
    private http: HttpClient,
    @Inject('BASE_URL') private baseUrl: string,
    private jobService: JobService,
    private itemService: ItemService,
    public router: Router
  ) { }

  public worksheetList: BehaviorSubject<Array<WorksheetCombinedModel>> = new BehaviorSubject(null);
  public worksheet: BehaviorSubject<WorksheetCombinedModel> = new BehaviorSubject(null);
  public packerList: BehaviorSubject<Array<WorksheetModel>> = new BehaviorSubject(null);
  public packerFilterParams: PackerFilterParamsModel = new PackerFilterParamsModel();
  public packerListIsLoading: boolean = false;

  private apiUrl = 'api/Worksheet';  // URL to web api

  public deleteWorksheet(
    dto: WorksheetModel,
    showInactive: boolean = false,
    showOnJob: boolean = false): Observable<boolean> {
    return new Observable((observer: Observer<boolean>) => {
      this.http.get<boolean>(this.baseUrl + this.apiUrl + '/DeleteWorksheet/' + dto.worksheetId)
        .subscribe((x: boolean) => {
          this.routeAfterWorksheet(dto.jobId, showInactive, showOnJob);
          observer.next(x);
        });
    });
  }

  public generateNewWorksheetCombined(
    worksheetTypeId: number,
    jobId: number | null,
    customerId: number | null): Observable<WorksheetCombinedModel> {
    let queryStr = '';

    if (jobId || customerId) {
      queryStr = '?';
    }

    if (jobId) {
      queryStr += `jobId=${jobId}`;
    }

    if (jobId && customerId) {
      queryStr += '&';
    }

    if (customerId) {
      queryStr += `customerId=${customerId}`;
    }

    return this.http.get<WorksheetCombinedModel>(this.baseUrl + this.apiUrl + '/GenerateNewWorksheetCombined/' + worksheetTypeId + queryStr);
  }

  public getWorksheetCombinedById(worksheetId: number): Observable<WorksheetCombinedModel> {
    return this.http.get<WorksheetCombinedModel>(this.baseUrl + this.apiUrl + '/GetWorksheetCombinedById/' + worksheetId);
  }

  public getWorksheetsCombinedByJobId(
    jobId: number,
    showInactive: boolean,
    clear: boolean = true): void {
    if (clear) {
      this.worksheetList.next(null);
    }
    this.http.get<Array<WorksheetCombinedModel>>(this.baseUrl + this.apiUrl + '/GetWorksheetsCombinedByJobId/' + jobId + '/' + showInactive)
      .pipe(tap((x: Array<WorksheetCombinedModel>) => {
        x.forEach((x: WorksheetCombinedModel) => {
          x.isCollapsed = true;
        });
      }))
      .subscribe((x: WorksheetCombinedModel[]) => {
        this.worksheetList.next(x);
      });
  }

  public refreshWorksheetCombined(id: number): void {
    this.getWorksheetCombinedById(id)
      .subscribe((x: WorksheetCombinedModel) => {
        this.worksheet.next(x);
      });
  }

  public addWorksheetCombined(
    dto: WorksheetCombinedModel,
    showInactive: boolean,
    showOnJob: boolean = false): Observable<number> {
    return new Observable((observer: Observer<number>) => {
      this.http.post<number>(this.baseUrl + this.apiUrl + '/AddWorksheetCombined', this.cleanWorksheetModel(dto))
        .subscribe((x: number) => {
          if (x !== -1) {
            this.routeAfterWorksheet(dto.jobId, showInactive, showOnJob);
          }
          observer.next(x);
        });
    });
  }

  public editWorksheetCombined(
    dto: WorksheetCombinedModel,
    showInactive: boolean,
    showOnJob: boolean = false): Observable<boolean> {
    return new Observable((observer: Observer<boolean>) => {
      this.http.post<number>(this.baseUrl + this.apiUrl + '/EditWorksheetCombined', this.cleanWorksheetModel(dto))
        .subscribe((x: number) => {
          if (x !== -1) {
            this.routeAfterWorksheet(dto.jobId, showInactive, showOnJob);
          }
          observer.next(x !== -1);
        });
    });
  }

  public toggleWorksheetDay(
    dayId: number,
    worksheetId: number,
    jobId: number): Observable<boolean> {
    return new Observable((observer: Observer<boolean>) => {
      this.http.get<boolean>(this.baseUrl + this.apiUrl + '/ToggleWorksheetDay/' + dayId + '/' + worksheetId)
        .subscribe((x: boolean) => {
          if (x !== true) {
            this.jobService.refreshJob(jobId, true); // if check fails refresh with accurate data
          }
          observer.next(x);
        });
    });
  }

  public getPackerList(
    showOnJob: boolean,
    bypassFilter: boolean,
    customerId: number = null,
    worksheetCategoryId: number = null,
    itemId: number = null
  ): Observable<WorksheetModel[]> {
    const query = itemId ? `?itemId=${itemId}` : '';

    this.cleanFilterStrings();

    let filter: PackerFilterParamsModel;

    if (bypassFilter) {
      filter = new PackerFilterParamsModel();

      if (customerId) {
        filter.customerId = customerId;
      }
      if (worksheetCategoryId) {
        filter.worksheetCategoryId = worksheetCategoryId;
      }
    } else {
      filter = this.packerFilterParams;
    }

    return this.http.post<Array<WorksheetModel>>((this.baseUrl + this.apiUrl + '/GetPackerList/' + showOnJob + query), filter)
      .pipe(tap((x: WorksheetModel[]) => {
        x.forEach((ws: WorksheetModel) => {
          ws.dropDownDisplay = `${ws.worksheetId.toString()} - ${ws.worksheetTypeName}`;
          ws.dropDownDisplay += ws.notes && ws.notes.trim().length > 0 ? ' - ' + ws.notes : '';

          ws.dropDownDisplay = this.limitCharacters(ws.dropDownDisplay, 80);
        });
      }));
  }

  public refreshPackerList(
    showOnJob: boolean,
    clear: boolean = true): void {
    if (clear) {
      this.packerList.next(null);
      this.packerListIsLoading = true;
    }
    this.getPackerList(showOnJob, false)
      .subscribe((x: WorksheetModel[]) => {
        if (x) {
          this.packerList.next(x);
          this.packerListIsLoading = false;
        }
      });
  }

  public removePackerFromJob(
    worksheetId: number,
    jobId: number): Observable<boolean> {
    return new Observable((observer: Observer<boolean>) => {
      this.http.get<boolean>(this.baseUrl + this.apiUrl + '/RemovePackerFromJob/' + worksheetId)
        .subscribe((x: boolean) => {
          if (x) {
            this.jobService.refreshJob(jobId);
            this.getWorksheetsCombinedByJobId(jobId, false);
          }
          observer.next(x);
        });
    });
  }

  public getCurrentWorksheetAndSnapshots(worksheetId: number): Observable<WorksheetSnapshotComparisonModel> {
    return this.http.get<WorksheetSnapshotComparisonModel>(this.baseUrl + this.apiUrl + '/GetCurrentWorksheetAndSnapshots/' + worksheetId);
  }

  public clearFilterParams(
    showInactive: boolean,
    refresh: boolean): void {
    Object.keys(this.packerFilterParams).forEach((index: string) => {
      this.packerFilterParams[index] = null;
    });

    if (refresh) {
      this.refreshPackerList(showInactive, false);
    }
  }

  public getNumberOfActiveFilterFields(): number {
    let count: number = 0;

    Object.values(this.packerFilterParams).forEach((value: any) => {
      count = value ? count += 1 : count;
    });

    return count;
  }

  private cleanWorksheetModel(model: WorksheetCombinedModel): WorksheetCombinedModel {
    model = this.cleanModel(model);

    model.worksheetRowsCombined.forEach((row: WorksheetRowCombinedModel) => {
      row.quantity = row.quantity != null ?
        row.quantity
        : 0;
    });

    return model;
  }

  private cleanFilterStrings(): void {
    this.packerFilterParams = this.cleanModel(this.packerFilterParams);;
  }

  private cleanModel(model: any) {
    Object.keys(model).forEach((index: string) => {
      if (typeof model[index] === 'string') {
        model[index] = model[index]
          && model[index].trim() !== '' ?
          model[index].trim()
          : null;
      }
    });

    return model;
  }

  private limitCharacters(
    str: string,
    length: number): string {
    if (str.length <= length) {
      return str;
    } else {
      return str.substring(0, length).trim() + '...';
    }
  }

  private routeAfterWorksheet(
    jobId = null,
    showInactive = null,
    showOnJob = null): void {
    if (jobId) {
      this.getWorksheetsCombinedByJobId(jobId, showInactive, false);
      this.jobService.refreshJob(jobId);
    } else if (!jobId && this.router.url.includes('packers/list')) {
      this.refreshPackerList(showOnJob, false);
    } else if (!jobId && this.router.url.includes('items/details')) {
      const id = this.router.url.split('/');
      this.itemService.refreshItem(parseInt(id[id.length - 1]));
    } else if (!jobId && !this.router.url.includes('packers/list') && !this.router.url.includes('items/details')) {
      this.router.navigateByUrl('packers/list');
    }
  }

}
