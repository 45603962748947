import { Component, OnDestroy, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { CountyModel } from 'src/models/county.model';
import { RigModel } from 'src/models/rig.model';
import { CountyService } from 'src/services/county.service';
import { CustomerService } from 'src/services/customer.service';
import { RigService } from 'src/services/rig.service';
import { UIService } from 'src/services/ui.service';

@Component({
  selector: 'app-add-edit-rig-modal',
  templateUrl: './add-edit-rig-modal.component.html',
  styleUrls: ['add-edit-rig-modal.component.css']
})

export class AddEditRigModalComponent implements OnInit, OnDestroy {
  private countiesSub: Subscription;
  public counties: CountyModel[];

  private modelSub: Subscription;
  private saveSub: Subscription;

  public isNew: boolean = false;
  public id: number = -1;
  public entityId = -1;
  public disableCustomerSelect: boolean = false;
  public getByCustomer: boolean = false;
  public cacheRig: boolean = false;
  public showInactive: boolean = false;
  public model: RigModel;
  public disableSave: boolean = false;

  constructor(
    public bsModalRef: BsModalRef,
    public uiService: UIService,
    public countyService: CountyService,
    public customerService: CustomerService,
    public rigService: RigService,
  ) { };

  ngOnDestroy(): void {
    this.countiesSub?.unsubscribe();

    this.modelSub?.unsubscribe();

    this.saveSub?.unsubscribe();
  }

  ngOnInit(): void {
    this.getCounties();

    this.customerService.getCustomers(false);

    this.showInactive = this.showInactive === true;

    this.disableCustomerSelect = this.entityId !== -1;

    this.isNew = this.id === 0;

    if (this.isNew) {
      this.model = this.rigService.generateNewRigModel();

      if (this.entityId !== -1) {
        this.model.customerId = this.entityId;
      }
    } else {
      this.getModel();
    }
  }

  public getCounties(): void {
    this.countiesSub = this.countyService.countyList$
      .subscribe((counties: CountyModel[]) => {
        if (counties) {
          this.counties = counties;
        }
      });
  }

  public getModel(): void {
    this.modelSub = this.rigService.getRigById(this.id)
      .subscribe((x: RigModel) => {
        this.model = x;
      });
  }

  public onSave(): void {
    this.disableSave = true;

    this.saveSub = this.rigService.addEditRig(
      this.model,
      this.showInactive,
      this.getByCustomer,
      this.cacheRig)
      .subscribe((x: boolean) => {
        if (x) {
          this.bsModalRef.hide();
          this.uiService.showSuccess('Rig Saved', 'Success');
        } else {
          this.disableSave = false;
          this.uiService.showError('Rig Not Saved', 'Error');
        }
      });
  }

}
