<div class="modal-header"
  ngxModalDraggable>
  <h4 class="modal-title pull-left">Choose Worksheet</h4>
  <div class="pull-right">
    <input type="text"
      *ngIf="!packersOnly"
      class="form-control form-control-sm pull-right"
      id="search"
      (keyup)="filterWorksheetTypes(searchTerm)"
      [(ngModel)]="searchTerm"
      name="search"
      placeholder="Search..."
      style="width: 250px;display: inline-block">
    <button type="button"
      class="close"
      aria-label="Close"
      (click)="bsModalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
</div>
<div class="modal-body ws-overflow">
  <div class="row">
    <div class="col-sm-4"
      *ngFor="let m of worksheetTypesFiltered">
      <div class="card border-top-primary border-top-2 rounded-top-0 pointer"
        style="border-top-color: #2196F3 !important;"
        (click)="selectWorksheetModal(0, m.worksheetTypeId)">
        <div class="card-header bg-light header-elements-sm-inline worksheet-card-padding">
          <h6 class="card-title">{{m.name}}</h6>
        </div>
        <div class="card-body worksheet-card-padding"
          style="min-height: 100px">
          <img style="max-width: 100%; height: auto;"
            src="/api/worksheetType/GetWorksheetTypeDetailsImage/{{m.worksheetTypeId}}/2" />
          <div *ngIf="!m.hasImage"
            style="text-align: center; padding-top: 10px; color: lightgray;">
            No Preview Image
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
