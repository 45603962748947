import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { PageChangeEvent } from '@progress/kendo-angular-grid';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { AddEditWellModalComponent } from 'src/app/wells/add-edit-well-modal/add-edit-well-modal.component';
import { AddressTypes } from 'src/enums/address-types.enum';
import { CustomerModel } from 'src/models/customer.model';
import { WellModel } from 'src/models/well.model';
import { CustomerService } from 'src/services/customer.service';
import { KGridService } from 'src/services/k-grid.service';
import { UIService } from 'src/services/ui.service';
import { WellService } from 'src/services/well.service';

@Component({
  selector: 'app-wells-sm-list',
  templateUrl: './wells-sm-list.component.html',
})

export class WellsSmallListComponent implements OnInit, OnDestroy {
  @Input() type: number;
  constructor(
    private modalService: BsModalService,
    public wellService: WellService,
    public customerService: CustomerService,
    public kGridService: KGridService,
    public uiService: UIService,
  ) { };

  public showInactive: boolean = false;
  public entityId: number = -1;
  public bsModalRef: BsModalRef;

  private customerSub: Subscription;
  private deleteSub: Subscription;

  ngOnDestroy(): void {
    this.customerSub?.unsubscribe();

    this.deleteSub?.unsubscribe();
  }

  ngOnInit(): void {
    if (this.type === AddressTypes.Customer) {
      this.subscribeToCustomer();
    }
  }

  public subscribeToCustomer(): void {
    this.customerSub = this.customerService.customer
      .subscribe((x: CustomerModel) => {
        this.entityId = x.customerId;
      });
  }

  public openWellModal(id: number): void {
    const initialState = {
      id: id,
      type: this.type,
      entityId: this.entityId,
      showInactive: this.showInactive,
      getByCustomer: true
    };

    this.bsModalRef = this.modalService.show(AddEditWellModalComponent, { initialState, backdrop: 'static' });
  }

  public onChangeShowInactive($event: boolean): void {
    this.showInactive = $event;
    this.wellService.getWellsByCustomerId(this.entityId, this.showInactive);
  }

  public deleteWell(dto: WellModel): void {
    this.deleteSub = this.wellService.deleteWell(
      dto,
      this.showInactive,
      true)
      .subscribe((x: boolean) => {
        if (x) {
          this.uiService.showSuccess('Success', 'Well Deleted');
        } else {
          this.uiService.showError('Error', 'Well Not Deleted');
        }
      });
  }

  public kGridParams: any =
    {
      buttonCount: 5,
      info: true,
      type: '\'numeric\' | \'input\' = \'numeric\'',
      pageSizes: true,
      previousNext: true,
      pageSize: 100,
      skip: 0
    };

  public pageChange({ skip, take }: PageChangeEvent): void {
    this.kGridParams.skip = skip;
    this.kGridParams.pageSize = take;
  }

}
