import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable, Observer } from 'rxjs';
import { WorksheetRowItemTypeModel } from 'src/models/worksheet-row-item-type.model';
import { WorksheetTypeService } from './worksheet-type.service';

@Injectable()
export class WorksheetRowItemTypeService {
  constructor(
    private http: HttpClient,
    @Inject('BASE_URL') private baseUrl: string,
    private worksheetTypeService: WorksheetTypeService
  ) { }

  private apiUrl = 'api/WorksheetRowItemType';  // URL to web api

  public getWorksheetRowItemTypeById(id: number): Observable<WorksheetRowItemTypeModel> {
    return this.http.get<WorksheetRowItemTypeModel>(this.baseUrl + this.apiUrl + '/GetWorksheetRowItemTypeById/' + id);
  }

  public addEditWorksheetRowItemType(
    dto: WorksheetRowItemTypeModel,
    refreshWorksheet: boolean = false): Observable<boolean> {
    return new Observable((observer: Observer<boolean>) => {
      this.http.post<number>(this.baseUrl + this.apiUrl + '/AddEditWorksheetRowItemType', this.cleanWorksheetRowItemTypeModel(dto))
        .subscribe((x: number) => {
          if (x !== -1) {
            if (refreshWorksheet) {
              this.worksheetTypeService.refreshWorksheetType(dto.worksheetTypeId);
            }
          }
          observer.next(x !== -1);
        });
    });
  }

  public deleteWorksheetRowItemType(
    dto: WorksheetRowItemTypeModel,
    refreshWorksheet: boolean = false): Observable<boolean> {
    return new Observable((observer: Observer<boolean>) => {
      this.http.get<boolean>(this.baseUrl + this.apiUrl + '/DeleteWorksheetRowItemType/' + dto.worksheetRowItemTypeId)
        .subscribe((x: boolean) => {
          if (x === true) {
            if (refreshWorksheet) {
              this.worksheetTypeService.refreshWorksheetType(dto.worksheetTypeId);
            }
          }
          observer.next(x);
        });
    });
  }

  public generateNewWorksheetRowItemTypeModel(worksheetRowTypeId: number): WorksheetRowItemTypeModel {
    let model: WorksheetRowItemTypeModel = new WorksheetRowItemTypeModel();
    model.worksheetRowItemTypeId = 0;
    model.worksheetRowTypeId = worksheetRowTypeId;
    model.isOption = false;
    model.chargeItemizedOption = false;
    model.isActive = true;

    return model;
  }

  private cleanWorksheetRowItemTypeModel(model: WorksheetRowItemTypeModel): WorksheetRowItemTypeModel {
    Object.keys(model).forEach((index: string) => {
      if (typeof model[index] === 'string') {
        model[index] = model[index]
          && model[index].trim() !== '' ?
          model[index].trim()
          : null;
      }
    });

    return model;
  }

}
