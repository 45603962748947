import { Component, OnDestroy, OnInit } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { AddEditBillingCodeModalComponent } from 'src/app/billing-codes/add-edit-billing-code-modal/add-edit-billing-code-modal.component';
import { AddEditWorksheetCategoryModalComponent }
  from 'src/app/worksheet-categories/add-edit-worksheet-category-modal/add-edit-worksheet-category-modal.component';
import { WorksheetCategoryModel } from 'src/models/worksheet-category.model';
import { WorksheetTypeModel } from 'src/models/worksheet-type.model';
import { BillingCodeService } from 'src/services/billing-code.service';
import { UIService } from 'src/services/ui.service';
import { WorksheetCategoryService } from 'src/services/worksheet-category.service';
import { WorksheetTypeService } from 'src/services/worksheet-type.service';
import { BillingCodeModel } from '../../../models/billing-code.model';

@Component({
  selector: 'app-add-edit-worksheet-type-modal',
  templateUrl: './add-edit-worksheet-type-modal.component.html',
  styleUrls: ['add-edit-worksheet-type-modal.component.css']
})

export class AddEditWorksheetTypeModalComponent implements OnInit, OnDestroy {
  public isNew: boolean = false;
  public id: number = -1;
  public showInactive: boolean = false;
  public launchedFromPoModal: boolean = false;
  public disableSave: boolean = false;

  private modelSub: Subscription;
  public model: WorksheetTypeModel;

  private bcSub: Subscription;
  private saveSub: Subscription;
  private wcCacheSub: Subscription;

  constructor(
    public bsModalRef: BsModalRef,
    public bsModalRefChildModal: BsModalRef,
    private modalService: BsModalService,
    public uiService: UIService,
    public worksheetTypeService: WorksheetTypeService,
    public billingCodeService: BillingCodeService,
    public worksheetCategoryService: WorksheetCategoryService
  ) { };

  ngOnDestroy(): void {
    this.bcSub?.unsubscribe();

    this.saveSub?.unsubscribe();

    this.wcCacheSub?.unsubscribe();

    this.modelSub?.unsubscribe();
  }

  ngOnInit(): void {
    this.billingCodeService.getBillingCodes(
      false,
      true,
      true,
      true);

    this.worksheetCategoryService.getWorksheetCategories(
      false,
      true);

    this.subscribeToBillingCodeCache();

    this.subscribeToWorksheetCategoryCache();

    this.isNew = this.id === 0;

    if (this.isNew) {
      this.model = this.worksheetTypeService.generateNewWorksheetTypeModel();
    } else {
      this.getModel();
    }
  }

  public getModel() {
    this.modelSub = this.worksheetTypeService.getWorksheetTypeById(this.id)
      .subscribe((x: WorksheetTypeModel) => {
        this.model = x;
      });
  }

  public openWorksheetCategoryModal(
    worksheetCategoryId: number,
    $event: any): void {
    $event.preventDefault();

    const initialState = {
      id: worksheetCategoryId,
      showInactive: this.showInactive,
      launchedFromModal: true

    };

    this.bsModalRefChildModal = this.modalService.show(AddEditWorksheetCategoryModalComponent, { initialState, backdrop: 'static' });
  }

  public openBillingCodeModal(
    billingCodeId: number,
    $event: any): void {
    $event.preventDefault();

    const initialState = {
      id: billingCodeId,
      showInactive: false,
      cacheBillingCode: true,
      isSelectable: false
    };

    this.bsModalRefChildModal = this.modalService.show(AddEditBillingCodeModalComponent, { initialState, backdrop: 'static' });
  }

  public onChangeBillingCode($event: BillingCodeModel): void {
    if ($event !== undefined) {
      this.model.billingCodeId = $event.billingCodeId;
      this.model.billingCodeName = $event.name;
    } else if ($event === undefined) {
      this.model.billingCodeId = null;
      this.model.billingCodeName = null;
    }
  }

  public onChangeWorksheetCategory($event: WorksheetCategoryModel): void {
    if ($event !== undefined) {
      this.model.worksheetCategoryId = $event.worksheetCategoryId;
      this.model.worksheetCategoryName = $event.name;
    } else if ($event === undefined) {
      this.model.worksheetCategoryId = null;
      this.model.worksheetCategoryName = null;
    }
  }

  public subscribeToBillingCodeCache(): void {
    this.billingCodeService.billingCodeCache.next(null);

    this.bcSub = this.billingCodeService.billingCodeCache
      .subscribe((x: BillingCodeModel) => {
        if (x !== null) {
          this.model.billingCodeId = x.billingCodeId;
        }
      });
  }

  public subscribeToWorksheetCategoryCache(): void {
    this.worksheetCategoryService.categoryCache.next(null);

    this.wcCacheSub = this.worksheetCategoryService.categoryCache
      .subscribe((x: WorksheetCategoryModel) => {
        if (x !== null) {
          this.model.worksheetCategoryId = x.worksheetCategoryId;
        }
      });
  }

  public onSave(): void {
    this.disableSave = true;
    this.saveSub = this.worksheetTypeService.addEditWorksheetType(
      this.model,
      this.showInactive)
      .subscribe((x: boolean) => {
        if (x) {
          this.bsModalRef.hide();
          this.uiService.showSuccess('Worksheet Type Saved', 'Success');
        } else {
          this.disableSave = false;
          this.uiService.showError('Worksheet Type Not Saved', 'Error');
        }
      });
  }

}
