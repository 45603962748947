import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Observer } from 'rxjs';
import { FileTypeModel } from 'src/models/file-type.model';

@Injectable()
export class FileTypeService {
  constructor(
    private http: HttpClient,
    @Inject('BASE_URL') private baseUrl: string
  ) { }

  private apiUrl = 'api/FileType';  // URL to web api

  public fileTypeList: BehaviorSubject<FileTypeModel[]> = new BehaviorSubject(null);

  public addEditFileType(dto: FileTypeModel, showInactive: boolean = false): Observable<boolean> {
    dto = this.cleanFileTypeModel(dto);

    return new Observable((observer: Observer<boolean>) => {
      this.http.post<boolean>(this.baseUrl + this.apiUrl + '/AddEditFileType', dto)
        .subscribe((x: boolean) => {
          if (x === true) {
            this.getFileTypes(showInactive);
          }
          observer.next(x);
        });
    });
  }

  public deleteFileType(id: number, showInactive: boolean = false): Observable<boolean> {
    return new Observable((observer: Observer<boolean>) => {
      this.http.get<boolean>(this.baseUrl + this.apiUrl + '/DeleteFileType/' + id)
        .subscribe((x: boolean) => {
          if (x === true) {
            this.getFileTypes(showInactive);
          }
          observer.next(x);
        });
    });
  }

  public getFileTypeById(id: number): Observable<FileTypeModel> {
    return this.http.get<FileTypeModel>(this.baseUrl + this.apiUrl + '/GetFileTypeById/' + id);
  }

  public getFileTypes(showInactive: boolean = false): void {
    this.http.get<FileTypeModel[]>(this.baseUrl + this.apiUrl + '/GetFileTypes/' + showInactive)
      .subscribe((x: FileTypeModel[]) => {
        this.fileTypeList.next(x);
      });
  }

  public generateFileTypeModel(): FileTypeModel {
    let model: FileTypeModel = new FileTypeModel();

    model.isActive = true;

    return model;
  }

  private cleanFileTypeModel(model: FileTypeModel): FileTypeModel {
    Object.keys(model).forEach((index: string) => {
      if (typeof model[index] === 'string') {
        model[index] = model[index]
          && model[index].trim() !== '' ?
          model[index].trim()
          : null;
      }
    });

    return model;
  }
}
