import { Component } from '@angular/core';
import { RouterEventService } from 'src/services/router-event.service';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent {
  constructor(private routerEventService: RouterEventService) { }

  title = 'app';
}
