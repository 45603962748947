import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ContractorTypeModel } from 'src/models/contractor-type.model';

@Injectable()
export class ContractorTypeService {
  constructor(
    private http: HttpClient,
    @Inject('BASE_URL') private baseUrl: string
  ) { }

  private apiUrl = 'api/ContractorType';  // URL to web api

  private contractorTypeListSubject = new BehaviorSubject<ContractorTypeModel[]>(null);
  public _contractorTypeList$ = this.contractorTypeListSubject.asObservable();
  private isContractorCallInProgress = false;

  public get contractorTypesList$(): Observable<ContractorTypeModel[]> {
    if (!this.contractorTypeListSubject.getValue() && !this.isContractorCallInProgress) {
      this.isContractorCallInProgress = true;
      this.getContractorTypes$(false).subscribe(() => this.isContractorCallInProgress = false);
    }

    return this._contractorTypeList$;
  }

  private getContractorTypes$(showInactive: boolean): Observable<ContractorTypeModel[]> {
    return this.http.get<Array<ContractorTypeModel>>(this.baseUrl + this.apiUrl + '/GetContractorTypes/' + showInactive)
      .pipe(
        tap((contractors: ContractorTypeModel[]) => {
          this.contractorTypeListSubject.next(contractors);
        })
      );
  }
}
