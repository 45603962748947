import { Component, OnDestroy, OnInit } from '@angular/core';
import { RowClassArgs } from '@progress/kendo-angular-grid';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { KGridService } from 'src/services/k-grid.service';
import { UIService } from 'src/services/ui.service';
import { UnitOfMeasureService } from 'src/services/unit-of-measure.service';
import { UserService } from 'src/services/user.service';
import { AddEditUomModalComponent } from '../add-edit-uom-modal/add-edit-uom-modal.component';
import { SuperAdminEditConfirmModalComponent } from 'src/app/shared/super-admin-edit-confirm-modal/super-admin-edit-confirm-modal.component';

@Component({
  selector: 'app-units-of-measure-list',
  templateUrl: './list.component.html',
  styleUrls: ['list.component.css']
})

export class UomListComponent implements OnInit, OnDestroy {
  public showInactive: boolean = false;
  public allowEditing: boolean = false;
  public bsModalRef: BsModalRef;
  private deleteSub: Subscription;
  private saveSubscriptions = new Subscription();

  constructor(
    private modalService: BsModalService,
    public kGridService: KGridService,
    public uomService: UnitOfMeasureService,
    public uiService: UIService,
    public userService: UserService
  ) { };

  public ngOnDestroy(): void {
    this.kGridService.resetKGridParams();

    this.deleteSub?.unsubscribe();

    this.saveSubscriptions?.unsubscribe();
  }

  ngOnInit(): void {
    this.uomService.getUnitsOfMeasure(this.showInactive);
  }

  public onChangeShowInactive($event): void {
    this.showInactive = $event;

    this.uomService.getUnitsOfMeasure(this.showInactive);
  }

  public openUomModal(itemInstanceLocationId: number): void {
    const initialState = {
      id: itemInstanceLocationId,
      showInactive: this.showInactive
    };
    this.bsModalRef = this.modalService.show(AddEditUomModalComponent, { initialState, backdrop: 'static' });
  }

  public onChangeOfAllowEditing($event: boolean): void {
    this.allowEditing = $event;

    if ($event !== undefined) {
      if ($event === true) {
        this.bsModalRef = this.modalService.show(SuperAdminEditConfirmModalComponent, { backdrop: 'static' });

        this.saveSubscriptions.add(this.bsModalRef.content.onClose
          .subscribe((confirm: boolean) => {
            if (confirm !== undefined) {
              this.allowEditing = confirm;
            }
          }));
      }
    }
  }

  public deleteUom(id: number): void {
    this.deleteSub = this.uomService.deleteUnitOfMeasure(id)
      .subscribe((x: boolean) => {
        if (x) {
          this.uiService.showSuccess('Success', 'UOM Deleted');
        } else {
          this.uiService.showError('Error', 'UOM Not Deleted');
        }
      });
  }

  public rowCallback(context: RowClassArgs): any {
    return {
      inactive: context.dataItem.isActive === false
    };
  }

}
