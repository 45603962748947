import { AddressModel } from './address.model';
import { BhaModel } from './bha.model';
import { DayModel } from './day.model';
import { InvoiceItemModel } from './invoice-item.model';
import { InvoiceModel } from './invoice.model';
import { JobItemInstanceModel } from './job-item-instance.model';
import { PurchaseOrderModel } from './purchase-order.model';

export class JobModel {
  jobId: number;
  customerId: number;
  customerName: string;
  addressId: number;
  address: AddressModel;
  jobNumber: string;
  jobDate: Date;
  afeNumber: string;
  ccNumber: string;
  orderedById: number | null;
  orderedByName: string;
  deliveredById: number | null;
  deliveredByName: string;
  deliveredByThirdParty: string;
  jobOwnerId: number | null;
  jobOwnerName: string;
  wellId: number | null;
  wellName: string;
  rigId: number | null;
  rigName: string;
  well_Rig: string;
  countyId: number | null;
  countyName: string;
  jobTypeId: number;
  jobTypeName: string;
  jobStatusId: number;
  jobStatusName: string;
  jobSubStatusId: number;
  jobSubStatusName: string;
  jobStatusIsTerminal: boolean | null;
  workDescription: string;
  salesTaxRateStamp: number | null;
  salesTaxTotalStamp: number | null;
  subTotalStamp: number | null;
  totalStamp: number | null;
  isActive: boolean | null;
  shopId: number;
  shopName: string;
  notReadyToInvoice: boolean;
  invoiceDate: Date | null;

  cancellationReasonId: number | null;
  otherReason: string;

  customerMax: number;
  showSplitInvoiceButton: boolean;
  invoiceHasBeenSplit: boolean;
  addrOrWellHasCounty: boolean;
  dropdownDisplay: string;

  total: number | null;
  invoices: InvoiceModel[];
  invoiceItems: InvoiceItemModel[];
  jobItemInstances: JobItemInstanceModel[];
  toolSupervisorQtyHasBeenEdited: boolean = false;
  jobHandUserIds: number[];
  jobHandNames: string[];

  days: DayModel[];
  bhas: BhaModel[];
  purchaseOrders: PurchaseOrderModel[];
  closeDate: Date | string | null;
  lastJobDay: Date;
}
