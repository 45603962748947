import { Component, OnDestroy, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { AddressModel } from 'src/models/address.model';
import { CountyModel } from 'src/models/county.model';
import { StateModel } from 'src/models/state.model';
import { AddressService } from 'src/services/address.service';
import { CountyService } from 'src/services/county.service';
import { StateService } from 'src/services/state.service';
import { UIService } from 'src/services/ui.service';
import { States } from '../../../enums/states';
import { AddressTypes } from 'src/enums/address-types.enum';

@Component({
  selector: 'app-add-edit-address-modal',
  templateUrl: './add-edit-address-modal.component.html',
  styleUrls: ['add-edit-address-modal.component.css']
})

export class AddEditAddressModalComponent implements OnInit, OnDestroy {
  private countiesSub: Subscription;
  public counties: CountyModel[];

  private statesSub: Subscription;
  public states: StateModel[];

  private modelSub: Subscription;
  public model: AddressModel;

  private saveSub: Subscription;

  public isNew: boolean = false;
  public id: number = -1;
  public type: number;
  public entityId: number = -1;
  public showInactive: boolean = false;
  public cacheAddress: boolean = false;
  public States = States;
  public disableSave: boolean = false;

  constructor(
    public bsModalRef: BsModalRef,
    public addressService: AddressService,
    public countyService: CountyService,
    public stateService: StateService,
    public uiService: UIService
  ) { };

  ngOnDestroy(): void {
    this.countiesSub?.unsubscribe();

    this.statesSub?.unsubscribe();

    this.modelSub?.unsubscribe();

    this.saveSub?.unsubscribe();
  }

  ngOnInit(): void {
    this.getCounties();

    this.getStates();

    this.showInactive = this.showInactive === true;
    this.isNew = this.id === 0;

    if (this.isNew) {
      this.model = this.addressService.generateNewAddressModel(
        this.type === AddressTypes.Vendor ? this.entityId : null,
        this.type === AddressTypes.Customer ? this.entityId : null);
    } else {
      this.getModel();
    }
  }

  public getStates(): void {
    this.statesSub = this.stateService.stateList$
      .subscribe((states: StateModel[]) => {
        if (states) {
          this.states = states;
        }
      });
  }

  public getCounties(): void {
    this.countiesSub = this.countyService.countyList$
      .subscribe((counties: CountyModel[]) => {
        if (counties) {
          this.counties = counties;
        }
      });
  }

  public getModel(): void {
    this.modelSub = this.addressService.getAddressById(this.id)
      .subscribe((address: AddressModel) => {
        this.model = address;
      });
  }

  public onSateChange(): void {
    this.model.countyId = null;
  }

  public onChangeOfCounty($event: CountyModel): void {
    this.model.countyId = $event !== undefined ? $event.countyId : null;
    this.model.countyName = $event !== undefined ? $event.name : null;
  }

  public onSave(): void {
    this.disableSave = true;

    this.saveSub = this.addressService.addEditAddress(this.model, this.showInactive, this.cacheAddress)
      .subscribe((x: boolean) => {
        if (x) {
          this.bsModalRef.hide();

          this.uiService.showSuccess('Address Saved', 'Success');
        } else {
          this.uiService.showError('Address Not Saved', 'Error');

          this.disableSave = false;
        }
      });
  }

}
