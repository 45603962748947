import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Observer } from 'rxjs';
import { WorksheetCategoryModel } from 'src/models/worksheet-category.model';

@Injectable()
export class WorksheetCategoryService {
  constructor(
    private http: HttpClient,
    @Inject('BASE_URL') private baseUrl: string
  ) { }

  public worksheetCategoriesList: BehaviorSubject<Array<WorksheetCategoryModel>> = new BehaviorSubject(null);
  public categoryCache: BehaviorSubject<WorksheetCategoryModel> = new BehaviorSubject(null);
  public categoryListIsLoading: boolean = false;

  private apiUrl = 'api/WorksheetCategory';  // URL to web api

  public addEditWorksheetCategory(
    dto: WorksheetCategoryModel,
    showInactive: boolean = false,
    cacheStorageLocation: boolean = false): Observable<boolean> {
    return new Observable((observer: Observer<boolean>) => {
      this.http.post<number>(this.baseUrl + this.apiUrl + '/AddEditWorksheetCategory', this.cleanWorksheetCategoryModel(dto))
        .subscribe((x: number) => {
          if (x !== -1) {
            this.getWorksheetCategories(showInactive, true);
          }
          if (cacheStorageLocation) {
            dto.worksheetCategoryId = x;
            this.categoryCache.next(dto);
          } else {
            this.categoryCache.value !== null ? this.categoryCache.next(null) : null;
          }
          observer.next(x !== -1);
        });
    });
  }

  public deleteWorksheetCategory(
    id: number,
    showInactive: boolean): Observable<boolean> {
    return new Observable((observer: Observer<boolean>) => {
      this.http.get<boolean>(this.baseUrl + this.apiUrl + '/DeleteWorksheetCategory/' + id)
        .subscribe((x: boolean) => {
          if (x === true) {
            this.getWorksheetCategories(showInactive, true);
          }
          observer.next(x);
        });
    });
  }

  public getWorksheetCategories(
    showInactive: boolean,
    clear: boolean = false,
    showSkeleton: boolean = false): void {
    if (showSkeleton) {
      this.categoryListIsLoading = true;
    }

    if (!this.worksheetCategoriesList.value || clear) {
      this.http.get<Array<WorksheetCategoryModel>>(this.baseUrl + this.apiUrl + '/GetWorksheetCategories/' + showInactive)
        .subscribe((x: WorksheetCategoryModel[]) => {
          this.worksheetCategoriesList.next(x);
          this.categoryListIsLoading = false;
        });
    }
  }

  public getWorksheetCategoryById(id: number): Observable<WorksheetCategoryModel> {
    return this.http.get<WorksheetCategoryModel>(this.baseUrl + this.apiUrl + '/GetWorksheetCategoryById/' + id);
  }

  public generateNewWorksheetCategory(): WorksheetCategoryModel {
    let model: WorksheetCategoryModel = new WorksheetCategoryModel();
    model.isActive = true;

    return model;
  }

  private cleanWorksheetCategoryModel(model: WorksheetCategoryModel): WorksheetCategoryModel {
    Object.keys(model).forEach((index: string) => {
      if (typeof model[index] === 'string') {
        model[index] = model[index]
          && model[index].trim() !== '' ?
          model[index].trim()
          : null;
      }
    });

    return model;
  }

}
