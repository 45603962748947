import { BehaviorSubject, Observable, Observer } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ItemService } from './item.service';

@Injectable()
export class FileService {
  constructor(
    private http: HttpClient,
    @Inject('BASE_URL') private baseUrl: string,
    private itemService: ItemService
  ) { }

  public refreshBhaDiagram: BehaviorSubject<boolean> = new BehaviorSubject(false);

  private apiUrl = 'api/File';  // URL to web api

  public uploadFile(
    fileList: FileList,
    typeId: number,
    itemId: number): Observable<boolean> {
    let query: string = '';

    if (itemId != undefined && itemId != null) {
      query = `?itemId=${itemId}`;
    }

    return new Observable((observer: Observer<boolean>) => {
      for (let i = 0; i < fileList.length; i++) {
        let file: File = fileList[i];
        let formData: FormData = new FormData();
        formData.append('uploadFile', file, file.name);

        this.http.post<boolean>(this.baseUrl + this.apiUrl + '/UploadFile/' + typeId + query, formData)
          .subscribe((x: boolean) => {
            if (x && itemId != undefined && itemId != null) {
              this.itemService.refreshItem(itemId);
            }
            observer.next(x);
          });
      }
    });
  }

  public removeBhaItemImage(
    typeId: number,
    itemId: number): Observable<boolean> {
    return new Observable((observer: Observer<boolean>) => {
      this.http.get<boolean>(this.baseUrl + this.apiUrl + '/RemoveBhaItemImage/' + typeId + '/' + itemId)
        .subscribe((x: boolean) => {
          if (x) {
            this.itemService.refreshItem(itemId);
          }
          observer.next(x);
        });
    });
  }

}
