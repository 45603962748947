import { Component, OnDestroy, OnInit } from '@angular/core';
import { RowClassArgs } from '@progress/kendo-angular-grid';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { AccountModel } from 'src/models/account.model';
import { PurchaseOrderModel } from 'src/models/purchase-order.model';
import { ShopModel } from 'src/models/shop.model';
import { AppStateService } from 'src/services/app-state.service';
import { KGridService } from 'src/services/k-grid.service';
import { PurchaseOrderStatusService } from 'src/services/purchase-order-status.service';
import { PurchaseOrderService } from 'src/services/purchase-order.service';
import { UIService } from 'src/services/ui.service';
import { VendorService } from 'src/services/vendor.service';
import { PurchaseOrderStatuses } from '../../../enums/purchase-order-statuses';
import { AccountService } from '../../../services/account.service';
import { ShopService } from '../../../services/shop.service';
import { AddEditPoModalComponent } from '../add-edit-po-modal/add-edit-po-modal.component';
import { ItemService } from 'src/services/item.service';

@Component({
  selector: 'app-purchase-orders-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.css']
})

export class PurchaseOrderListComponent implements OnInit, OnDestroy {
  private accountsSub: Subscription;
  public accounts: AccountModel[];

  private shopsSub: Subscription;
  public shops: ShopModel[];

  private deleteSub: Subscription;

  constructor(
    private modalService: BsModalService,
    public poService: PurchaseOrderService,
    public vendorService: VendorService,
    public accountService: AccountService,
    public shopService: ShopService,
    public statusService: PurchaseOrderStatusService,
    public kGridService: KGridService,
    public appStateService: AppStateService,
    public uiService: UIService,
    public itemService: ItemService
  ) { };

  public bsModalRef: BsModalRef;
  public showInactive: boolean = false;
  public PurchaseOrderStatuses = PurchaseOrderStatuses;
  public showFilter = true;
  public numberOfActiveFilterFields: number = 0;

  private isTabletSub: Subscription;
  public isTablet: boolean = false;

  ngOnDestroy(): void {
    this.accountsSub?.unsubscribe();

    this.deleteSub?.unsubscribe();

    this.shopsSub?.unsubscribe();

    this.isTabletSub?.unsubscribe();
  }

  ngOnInit(): void {
    this.determineIsTablet();

    this.poService.refreshPurchaseOrderList(
      false,
      true);

    this.vendorService.getVendors(false, true);

    this.statusService.getPurchaseOrderStatuses(false, true, false, true);

    this.itemService.getItemsDropdown(false, false, true, false);

    this.getAccounts();

    this.getShops();
  }

  public determineIsTablet(): void {
    this.isTabletSub = this.appStateService.isTablet$
      .subscribe((isTablet: boolean) => {
        this.isTablet = isTablet;
      });
  }

  public getAccounts(): void {
    this.accountsSub = this.accountService.accountList$
      .subscribe((accounts: AccountModel[]) => {
        if (accounts) {
          this.accounts = accounts;
        }
      });
  }

  public getShops(): void {
    this.shopsSub = this.shopService.shopList$
      .subscribe((shops: ShopModel[]) => {
        if (shops) {
          this.shops = shops;
        }
      });
  }

  public createPoModal(): void {
    const initialState = {
      id: 0,
    };

    this.bsModalRef = this.modalService.show(AddEditPoModalComponent, { initialState, backdrop: 'static' });
  }

  public onChangeShowInactive($event: boolean): void {
    this.showInactive = $event;
    this.poService.refreshPurchaseOrderList(this.showInactive);
  }

  public rowCallback(context: RowClassArgs): any {
    return {
      inactive: !context.dataItem.isActive
    };
  }

  public deletePo(dto: PurchaseOrderModel): void {
    this.deleteSub = this.poService.deletePurchaseOrder(dto, this.showInactive)
      .subscribe((x: boolean) => {
        if (x) {
          this.uiService.showSuccess('Success', 'PO Deleted');
        } else {
          this.uiService.showError('Error', 'PO Not Deleted');
        }
      });
  }

  public getNumberOfActiveFilterFields() {
    if (!this.showFilter) {
      this.numberOfActiveFilterFields = this.poService.getNumberOfActiveFilterFields();
    } else {
      this.numberOfActiveFilterFields = 0;
    }
  }

}
