import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { BillingCodes } from 'src/enums/billing-codes.enum';
import { JobSubStatuses } from 'src/enums/job-sub-statuses';
import { JobTypes } from 'src/enums/job-types';
import { ContractorTypeModel } from 'src/models/contractor-type.model';
import { JobSafetyAnalysisWeatherModel } from 'src/models/job-safety-analysis-weather.model';
import { JobSafetyAnalysisWindDirectionModel } from 'src/models/job-safety-analysis-wind-direction.model';
import { JobSafetyAnalysisWindSpeedModel } from 'src/models/job-safety-analysis-wind-speed.model';
import { AppStateService } from 'src/services/app-state.service';
import { ContractorTypeService } from 'src/services/contractor-type.service';
import { JobSafetyAnalysisWeatherService } from 'src/services/job-safety-analysis-weather.service';
import { JobSafetyAnalysisWindDirectionService } from 'src/services/job-safety-analysis-wind-direction.service';
import { JobSafetyAnalysisWindSpeedService } from 'src/services/job-safety-analysis-wind-speed.service';
import { JobSafetyAnalysisService } from 'src/services/job-safety-analysis.service';
import { ReportService } from 'src/services/report.service';
import { UIService } from 'src/services/ui.service';
import { UserService } from 'src/services/user.service';
import { JobService } from '../../../../services/job.service';
import { WorkLogService } from '../../../../services/work-log.service';

@Component({
  selector: 'app-job-safety-analysis-tabset',
  templateUrl: './job-safety-analysis-tabset.component.html',
  styleUrls: ['./job-safety-analysis-tabset.component.css']
})

export class JobSafetyAnalysisTabsetComponent implements OnInit, OnChanges, OnDestroy {
  private contractorTypesSub: Subscription;
  public contractorTypes: ContractorTypeModel[];

  private weatherTypesSub: Subscription;
  public weatherTypes: JobSafetyAnalysisWeatherModel[];

  private windDirectionSub: Subscription;
  public windDirections: JobSafetyAnalysisWindDirectionModel[];

  private windSpeedSub: Subscription;
  public windSpeeds: JobSafetyAnalysisWindSpeedModel[];

  private newJsaSub: Subscription;
  private deleteJsaSub: Subscription;

  @Input() jobId: number;
  @Input() jobDate: Date;
  @Input() lastJobDay: Date;
  @Input() jobNumber: number;
  @Input() jobSubStatusId: number;
  @Input() jobStatusIsTerminal: boolean;
  @Input() jobTypeId: number;
  @Input() wellRig: string;

  public bsModalRef: BsModalRef;
  public JobTypes = JobTypes;
  public JobSubStatuses = JobSubStatuses;
  public BillingCodes = BillingCodes;
  public jobIdCache: number = -1;
  public activeTabId: string = 'jsaTab_0';
  public editJsa: boolean = false;

  private isTabletSub: Subscription;
  public isTablet: boolean = false;

  constructor(
    public uiService: UIService,
    public jobService: JobService,
    private modalService: BsModalService,
    public workLogService: WorkLogService,
    public appStateService: AppStateService,
    public contractorTypeService: ContractorTypeService,
    public jobSafetyAnalysisWeatherService: JobSafetyAnalysisWeatherService,
    public jobSafetyAnalysisWindDirectionService: JobSafetyAnalysisWindDirectionService,
    public jobSafetyAnalysisWindSpeedService: JobSafetyAnalysisWindSpeedService,
    public jobSafetyAnalysisService: JobSafetyAnalysisService,
    public reportService: ReportService,
    public userService: UserService
  ) { };

  ngOnDestroy(): void {
    this.contractorTypesSub?.unsubscribe();

    this.isTabletSub?.unsubscribe();

    this.weatherTypesSub?.unsubscribe();

    this.windDirectionSub?.unsubscribe();

    this.windSpeedSub?.unsubscribe();

    this.newJsaSub?.unsubscribe();

    this.deleteJsaSub?.unsubscribe();
  }

  ngOnInit(): void {
    this.determineIsTablet();

    this.getContractorTypes();

    this.getWeatherTypes();

    this.getWindDirections();

    this.getWindSpeeds();

    this.userService.getHands(this.jobTypeId);
  }

  public determineIsTablet(): void {
    this.isTabletSub = this.appStateService.isTablet$
      .subscribe((isTablet: boolean) => {
        this.isTablet = isTablet;
      });
  }

  public getContractorTypes(): void {
    this.contractorTypesSub = this.contractorTypeService.contractorTypesList$
      .subscribe((contractorTypes: ContractorTypeModel[]) => {
        if (contractorTypes) {
          this.contractorTypes = contractorTypes;
        }
      });
  }

  public getWeatherTypes(): void {
    this.weatherTypesSub = this.jobSafetyAnalysisWeatherService.weatherTypesList$
      .subscribe((weatherTypes: JobSafetyAnalysisWeatherModel[]) => {
        if (weatherTypes) {
          this.weatherTypes = weatherTypes;
        }
      });
  }

  public getWindDirections(): void {
    this.windDirectionSub = this.jobSafetyAnalysisWindDirectionService.windDirectionList$
      .subscribe((windDirections: JobSafetyAnalysisWindDirectionModel[]) => {
        if (windDirections) {
          this.windDirections = windDirections;
        }
      });
  }

  public getWindSpeeds(): void {
    this.windSpeedSub = this.jobSafetyAnalysisWindSpeedService.windSpeedList$
      .subscribe((windSpeeds: JobSafetyAnalysisWindSpeedModel[]) => {
        if (windSpeeds) {
          this.windSpeeds = windSpeeds;
        }
      });
  }

  public handleJsaEdit($event: boolean): void {
    this.editJsa = $event;
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.jobSafetyAnalysisService.getJobSafetyAnalysisByJobId(
      this.jobId,
      this.jobDate,
      this.jobSubStatusId,
      this.lastJobDay);

    this.jobIdCache = this.jobId;
  }

  public tabSelect($event: any): void {
    if ($event !== undefined) {
      this.activeTabId = $event.id;
    }
  }

  public addNewJsa(): void {
    this.newJsaSub = this.jobSafetyAnalysisService.createNewJobSafetyAnalysis(
      this.jobId,
      this.jobDate,
      this.jobSubStatusId,
      this.lastJobDay)
      .subscribe((x: boolean) => {
        if (x) {
          this.uiService.showSuccess('JSA Created', 'Success');

          this.jobSafetyAnalysisService.determineIfJsasAreComplete(
            this.jobId,
            this.jobDate,
            this.jobSubStatusId,
            this.lastJobDay);

        } else {
          this.uiService.showError('JSA NOT Created', 'Error');
        }
      });
  }

  public deleteJsa(id: number): void {
    this.deleteJsaSub = this.jobSafetyAnalysisService.deleteJobSafetyAnalysis(id)
      .subscribe((x: boolean) => {
        if (!x) {
          this.uiService.showError('JSA NOT Deleted', 'Error');
        } else {
          this.jobSafetyAnalysisService.determineIfJsasAreComplete(
            this.jobId,
            this.jobDate,
            this.jobSubStatusId,
            this.lastJobDay);

          this.activeTabId = 'jsaTab_0';
        }
      });
  }

}
