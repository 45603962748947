import { Component, OnDestroy, OnInit } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { DayModel } from 'src/models/day.model';
import { DayService } from 'src/services/day.service';
import { UIService } from 'src/services/ui.service';
import { TimezoneService } from '../../../../services/timezone.service';

@Component({
  selector: 'app-add-edit-job-fishing-day-modal',
  templateUrl: './add-edit-job-fishing-day-modal.component.html',
  styleUrls: ['./add-edit-job-fishing-day-modal.component.css']
})

export class AddEditJobFishingDayModalComponent implements OnInit, OnDestroy {
  public isNew: boolean = false;
  public id: number = 0;
  public jobId: number = 0;
  public currentJobDates: (string | Date)[];
  public dateIsInUse: boolean = false;
  public disableSave: boolean = false;

  private modelSub: Subscription;
  public model: DayModel;

  private saveSub: Subscription;

  constructor(
    public bsModalRef: BsModalRef,
    public bsModalRefChildModal: BsModalRef,
    private modalService: BsModalService,
    public uiService: UIService,
    public dayService: DayService,
    public timeZoneService: TimezoneService,

  ) { };

  ngOnDestroy(): void {
    this.modelSub?.unsubscribe();

    this.saveSub?.unsubscribe();
  }

  ngOnInit(): void {
    this.isNew = this.id === 0;

    if (this.isNew) {
      this.generateNewDay();
    }
  }

  public generateNewDay(): void {
    this.modelSub = this.dayService.generateDayModel(this.jobId)
      .subscribe((x: DayModel) => {
        this.model = x;
      });
  }

  public onChangeOfDate($event: Date): void {
    if ($event !== undefined && $event !== null) {
      this.dateIsInUse = this.currentJobDates.findIndex((x: string | Date) => {
        return x.valueOf() === $event.valueOf();
      }) !== -1;
    } else {
      this.dateIsInUse = false;
    }
  }

  public onSave(): void {
    this.disableSave = true;

    this.saveSub = this.dayService.addEditDay(this.model)
      .subscribe((x: boolean) => {
        if (x) {
          this.uiService.showSuccess('Day Saved', 'Success');

          this.bsModalRef.hide();
        } else {
          this.uiService.showError('Day Not Saved', 'Error');

          this.disableSave = false;
        }
      });
  }

}
