import { Component, OnDestroy, OnInit } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { JobTypes } from 'src/enums/job-types';
import { BillingCodeModel } from 'src/models/billing-code.model';
import { WorkLogModel } from 'src/models/work-log.model';
import { BillingCodeService } from 'src/services/billing-code.service';
import { EmployeeService } from 'src/services/employee.service';
import { JobService } from 'src/services/job.service';
import { UIService } from 'src/services/ui.service';
import { WorkLogService } from 'src/services/work-log.service';
import { TimezoneService } from '../../../../services/timezone.service';

@Component({
  selector: 'app-add-edit-work-log-modal',
  templateUrl: './add-edit-work-log-modal.component.html',
  styleUrls: ['./add-edit-work-log-modal.component.css']
})

export class AddEditWorkLogModalComponent implements OnInit, OnDestroy {
  public isNew: boolean = false;
  public steps: any = { hour: 1, minute: 15 };
  public id: number = 0;
  public dayId: number = 0;
  public jobOwnerId: number = 0;
  public jobTypeId: number = 0;
  public dayDate: Date = new Date();
  public lastTime: Date = null;
  public isMileage: boolean = false;
  public disableSave = false;
  public timeFormat: string = 'HH:mm';
  public JobTypes = JobTypes;

  private modelSub: Subscription;
  public model: WorkLogModel;

  private saveSub: Subscription;

  constructor(
    public bsModalRef: BsModalRef,
    public bsModalRefChildModal: BsModalRef,
    private modalService: BsModalService,
    public employeeService: EmployeeService,
    public jobService: JobService,
    public uiService: UIService,
    public workLogService: WorkLogService,
    public bcService: BillingCodeService,
    public timeZoneService: TimezoneService
  ) { };

  ngOnDestroy(): void {
    this.modelSub?.unsubscribe();

    this.saveSub?.unsubscribe();
  }

  ngOnInit(): void {
    this.employeeService.getEmployees(
      false,
      true);

    this.bcService.getMileageBillingCodes();

    if (this.jobTypeId == JobTypes.Packer) {
      this.bcService.getPackerToolSupervisorBillingCodes();
    }

    this.isNew = this.id === 0;

    if (this.isNew) {
      this.model =
        this.workLogService.generateWorkLogModel(
          this.id,
          this.dayId,
          this.jobOwnerId,
          this.dayDate,
          this.lastTime,
          this.jobTypeId === this.JobTypes.Packer);
    } else {
      this.getModel();
    }
  }

  public createMileageDescription(): void {
    if (this.model.mileage !== undefined &&
      this.model.mileage !== null &&
      this.model.billingCodeId !== undefined &&
      this.model.billingCodeId !== null) {
      let bc: BillingCodeModel[] = this.bcService.mileageBCList.value !== null ?
        this.bcService.mileageBCList.value.filter((x: BillingCodeModel) => {
          return x.billingCodeId === this.model.billingCodeId;
        }) : null;
      if (bc.length > 0) {
        this.model.description = `${bc[0].dropDownDisplay} - (${this.model.mileage} miles)`;
      }
    } else {
      this.model.description = null;
    }
  }

  public onChangeOfMileage($event: boolean): void {
    this.model.description = null;
    this.model.mileage = null;
    this.model.billingCodeId = null;

    if ($event !== undefined) {
      this.isMileage = $event;
    }
  }

  public toggleTimeFormat($event: boolean) {
    if ($event) {
      this.timeFormat = 'hh:mm a';
    } else {
      this.timeFormat = 'HH:mm';
    }
  }

  public getModel(): void {
    this.modelSub = this.workLogService.getWorkLogById(this.id)
      .subscribe((x: WorkLogModel) => {
        if (x.mileage !== undefined && x.mileage !== null) {
          this.isMileage = true;
        }
        this.model = x;
      });
  }

  public onSave(): void {
    this.disableSave = true;

    this.model.mileage = this.isMileage === true ? this.model.mileage : null;

    this.model.billingCodeId =
      (this.isMileage === true && this.jobTypeId === this.JobTypes.Fishing)
        || (this.jobTypeId === this.JobTypes.Packer)
        ? this.model.billingCodeId
        : null;

    this.model.workStart = this.timeZoneService.spliceTimeOntoDate(
      this.dayDate,
      new Date(this.model.workStart));

    this.model.workEnd = this.timeZoneService.spliceTimeOntoDate(
      this.dayDate,
      new Date(this.model.workEnd));

    this.model.jobId = this.jobService.job.value !== null ? this.jobService.job.value.jobId : null;

    this.saveSub = this.workLogService.addEditWorkLog(
      this.model,
      true)
      .subscribe((x: boolean) => {
        if (x) {
          this.bsModalRef.hide();
          this.uiService.showSuccess('Work Log Saved', 'Success');
        } else {
          this.disableSave = false;
          this.uiService.showError('Work Log Not Saved', 'Error');
        }
      });
  }

}
