import { Injectable } from '@angular/core';
import { ItemVendorModel } from 'src/models/item-vendor.model';

@Injectable()
export class ItemVendorService {
  constructor() { }

  public generateNewItemVendorModel(itemId: number): ItemVendorModel {
    let model: ItemVendorModel = new ItemVendorModel();
    model.itemId = itemId;
    model.vendorId = null;
    model.description = null;
    model.partNumber = null;
    model.vendorName = null;
    model.isActive = true;

    return model;
  }

}
