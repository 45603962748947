import { BehaviorSubject, Observable, Observer } from 'rxjs';
import { FishingToolRentalGroups } from '../enums/fishing-tool-rental-groups.enum';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { InvoiceItemReorderModel } from '../models/RequestParams/invoice-item-reorder.model';
import { InvoiceService } from './invoice.service';
import { JobItemInstanceDayModel } from '../models/job-item-instance-day.model';
import { JobItemInstanceModel } from 'src/models/job-item-instance.model';
import { JobService } from './job.service';
import { JobTypes } from '../enums/job-types';
import { tap } from 'rxjs/operators';
import { InvoiceModel } from 'src/models/invoice.model';
import { JobModel } from 'src/models/job.model';
import { DayModel } from 'src/models/day.model';

@Injectable()
export class JobItemInstanceService {
  constructor(
    private http: HttpClient,
    @Inject('BASE_URL') private baseUrl: string,
    private jobService: JobService,
    private invoiceService: InvoiceService
  ) { }

  public fishingToolRentalGroups = FishingToolRentalGroups;
  public jiiByJobIdList: BehaviorSubject<Array<JobItemInstanceModel>> = new BehaviorSubject(null);
  public JobTypes = JobTypes;

  private apiUrl = 'api/JobItemInstance';  // URL to web api

  public getJobItemInstancesByJobId(jobId: number): void {
    this.http.get<JobItemInstanceModel[]>(this.baseUrl + this.apiUrl + '/GetJobItemInstancesByJobId/' + jobId)
      .pipe(tap((x: Array<JobItemInstanceModel>) => {
        x.forEach((y: JobItemInstanceModel) => {
          y.dropdownDisplay = y.fullGtNumber !== undefined && y.fullGtNumber !== null ? `${y.fullGtNumber} - ` : '';
          y.dropdownDisplay += `${y.description}`;
        });
      }))
      .subscribe((x: JobItemInstanceModel[]) => {
        this.jiiByJobIdList.next(x);
      });
  }

  public getJobItemInstanceById(id: number): Observable<JobItemInstanceModel> {
    return this.http.get<JobItemInstanceModel>(this.baseUrl + this.apiUrl + '/GetJobItemInstanceById/' + id);
  }

  public addEditJobItemInstance(dto: JobItemInstanceModel): Observable<boolean> {
    return new Observable((observer: Observer<boolean>) => {
      this.http.post<boolean>(this.baseUrl + this.apiUrl + '/AddEditJobItemInstance', dto)
        .subscribe((x: boolean) => {
          if (x === true) {
            this.jobService.refreshJob(dto.jobId);
          }
          observer.next(x);
        });
    });
  }

  public deleteJobItem(dto: JobItemInstanceModel): Observable<boolean> {
    return new Observable((observer: Observer<boolean>) => {
      this.http.get<boolean>(this.baseUrl + this.apiUrl + '/DeleteJobItemInstance/' + dto.jobItemInstanceId)
        .subscribe((x: boolean) => {
          if (x === true) {
            this.jobService.refreshJob(dto.jobId);
          }
          observer.next(x);
        });
    });
  }

  public toggleJobItemInstanceDay(
    dayId: number,
    jiiId: number,
    jobId: number): Observable<boolean> {
    return new Observable((observer: Observer<boolean>) => {
      this.http.get<boolean>(this.baseUrl + this.apiUrl + '/ToggleJobItemInstanceDay/' + dayId + '/' + jiiId)
        .subscribe((x: boolean) => {
          if (x === true) {
            this.refreshInvoicesOnly(jobId);
          }
          observer.next(x);
        });
    });
  }

  public addEditJobItemInstanceDay(
    dto: JobItemInstanceDayModel,
    jobId: number): Observable<boolean> {
    return new Observable((observer: Observer<boolean>) => {
      this.http.post<boolean>(this.baseUrl + this.apiUrl + '/AddEditJobItemInstanceDay', dto)
        .subscribe((x: boolean) => {
          if (x === true) {
            this.refreshInvoicesOnly(jobId);
          }
          observer.next(x);
        });
    });
  }

  public batchReorderToolList(
    dto: InvoiceItemReorderModel,
    keepJiiDays: boolean = true): Observable<boolean> {
    return new Observable((observer: Observer<boolean>) => {
      this.http.post<boolean>(this.baseUrl + this.apiUrl + '/BatchReorderToolList', dto)
        .subscribe((x: boolean) => {
          if (x === true) {
            this.jobService.refreshJob(dto.jobId, false, keepJiiDays);
          }
          observer.next(x);
        });
    });
  }

  public generateNewJobItemModel(
    jobId: number,
    jobTypeId: number = null,
    type: number = null): JobItemInstanceModel {
    let model: JobItemInstanceModel = new JobItemInstanceModel();
    model.sold = false;
    model.jobId = jobId;
    model.numberOfDays = jobTypeId === this.JobTypes.FishingToolRental ? 1 : null;
    model.releasedFromJob = false;
    model.isActive = true;

    model.additionalRateOnly = false; // db default
    model.chargeMinimumDays = true; // db default

    if (type === 1) {
      model.fishingToolRentalGroupId = this.fishingToolRentalGroups.FishingTools;
    } else if (type === 2) {
      model.fishingToolRentalGroupId = this.fishingToolRentalGroups.SaleItems;
    } else if (type === 3) {
      model.fishingToolRentalGroupId = this.fishingToolRentalGroups.ThirdPartyRental;
    } else {
      model.fishingToolRentalGroupId = this.fishingToolRentalGroups.Miscellaneous;
    }

    return model;
  }

  private refreshInvoicesOnly(jobId: number): void {
    let job = this.jobService.job.value;

    if (job != null) {

      this.invoiceService.getActiveInvoicesByJobId(jobId)
        .subscribe((x: InvoiceModel[]) => {
          job.invoices = x;
        });

      this.jobService.getJobById(jobId)
        .subscribe((x: JobModel) => {
          job.subTotalStamp = x.subTotalStamp;
          job.salesTaxTotalStamp = x.salesTaxTotalStamp;
          job.totalStamp = x.totalStamp;
          job.showSplitInvoiceButton = x.showSplitInvoiceButton;

          x.days.forEach((day: DayModel) => {
            let existingDay = job.days.filter(d => {
              return d.dayId === day.dayId;
            });

            if (existingDay.length === 1) {
              existingDay[0].dailyWellCost = day.dailyWellCost;
              existingDay[0].cumulativeWellCost = day.cumulativeWellCost;
            }
          });
        });
    }
  }

}
