<ng-template tabHeading>
  <span
    [ngClass]="{'text-danger font-weight-bold' : !jsaHealth ||(jsaHealth && !jsaHealth.jsasAreComplete)}">JSA</span>&nbsp;&nbsp;
  <span *ngIf="jsaHealth && jsaHealth.jsasAreComplete"
    class="text-success"
    title="JSA Complete">
    &nbsp;&nbsp;<i class="fa-solid fa-circle-check"></i>
  </span>
  <span *ngIf="!jsaHealth ||(jsaHealth && !jsaHealth.jsasAreComplete)"
    class="text-danger pointer">
    <ng-template #popoverTemplate>
      <div>
        <div *ngIf="jsaHealth !== null && (jsaHealth.expectedNumberOfJsas > jsaHealth.numberOfJsas)"
          class="mb-2">
          <span class="text-danger">This invoice requires <strong><u>{{jsaHealth.expectedNumberOfJsas}}</u></strong>
            JSA<span *ngIf="jsaHealth.expectedNumberOfJsas > 1">'s</span> but
            has <span *ngIf="jsaHealth.numberOfJsas !== 0">only</span>
            <span><strong><u>{{jsaHealth.numberOfJsas}}</u></strong></span> JSA.<span
              *ngIf="jsaHealth.numberOfJsas !== 1">'s</span></span>
        </div>
        <div *ngIf="jsaHealth !== null && (jsaHealth.expectedNumberOfJsas < jsaHealth.numberOfJsas)"
          class="mb-2">
          <span class="text-danger">This invoice requires only
            <strong><u>{{jsaHealth.expectedNumberOfJsas}}</u></strong>
            JSA<span *ngIf="jsaHealth.expectedNumberOfJsas > 1">'s</span> but
            has <span><strong><u>{{jsaHealth.numberOfJsas}}</u></strong></span> JSA<span
              *ngIf="jsaHealth.numberOfJsas > 1">'s</span>.
          </span>
        </div>
        <div *ngFor="let jsa of jsaHealth.individualJsaCompletion"
          class="mb-1">
          <div *ngIf="jsa.jsaIsComplete"
            class="text-success">JSA - {{jsa.jsaNumber}} is <strong>complete.</strong> <br></div>
          <span *ngIf="!jsa.jsaIsComplete"
            class="text-danger">JSA - {{jsa.jsaNumber}} is <strong>incomplete.</strong></span>
          <ul class="pl-3 mb-0"
            *ngIf="!jsa.jsaIsComplete">
            <li *ngFor="let e of jsa.errors">{{e}}</li>
          </ul>
        </div>
      </div>
    </ng-template>
    &nbsp;&nbsp;<i class="fa-solid fa-do-not-enter"
      type="button"
      [popover]="popoverTemplate"
      popoverTitle="JSA's Incomplete"
      placement="auto"
      [outsideClick]="true"
      container="body"></i></span>
</ng-template>
