export class DateDropdown {

  constructor(date: string, dateString: string) {
    this.date = date;
    this.dateString = dateString;
  }

  date: string;
  dateString: string;
}
