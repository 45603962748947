import { Component, OnDestroy, OnInit } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { AddEditVendorModalComponent } from 'src/app/vendors/add-edit-vendor-modal/add-edit-vendor-modal.component';
import { ItemInstanceModel } from 'src/models/item-instance.model';
import { MaintenanceEventModel } from 'src/models/maintenance-event.model';
import { MaintenanceEventPoLineLinkModel } from 'src/models/maintenance-po-line-link.model';
import { MaintenanceTypeModel } from 'src/models/maintenance-type.model';
import { PurchaseOrderLineModel } from 'src/models/purchase-order-line.model';
import { VendorModel } from 'src/models/vendor.model';
import { ItemInstanceService } from 'src/services/item-instance.service';
import { MaintenanceEventService } from 'src/services/maintenance-event.service';
import { MaintenanceTypeService } from 'src/services/maintenance-type.service';
import { PurchaseOrderLineService } from 'src/services/purchase-order-line.service';
import { UIService } from 'src/services/ui.service';
import { VendorService } from 'src/services/vendor.service';

@Component({
  selector: 'app-add-edit-maintenance-log-modal',
  templateUrl: './add-edit-maintenance-log-modal.component.html',
  styleUrls: ['./add-edit-maintenance-log-modal.component.css']
})

export class AddEditMaintenanceLogModalComponent implements OnInit, OnDestroy {
  public isNew: boolean = false;
  public id: number = -1;
  public seePrices: boolean = false;
  public showInactive: boolean = false;
  public model: MaintenanceEventModel;
  public itemInstanceId: number = -1;

  public lineList: PurchaseOrderLineModel[] = [];
  public selectedLines: PurchaseOrderLineModel[] = [];

  private maintenanceTypeSub: Subscription;
  public maintenanceTypes: MaintenanceTypeModel[];

  private modelSub: Subscription;
  private saveSub: Subscription;
  private cacheSub: Subscription;
  private poLineSub: Subscription;

  constructor(
    public bsModalRef: BsModalRef,
    public maintenanceEventService: MaintenanceEventService,
    public uiService: UIService,
    public maintenanceTypeService: MaintenanceTypeService,
    public vendorService: VendorService,
    public bsModalRefChildModal: BsModalRef,
    private modalService: BsModalService,
    private lineService: PurchaseOrderLineService,
    public itemInstanceService: ItemInstanceService,
  ) { };

  ngOnDestroy(): void {
    this.vendorService.getVendors(false, true, true, true);

    this.modelSub?.unsubscribe();

    this.maintenanceTypeSub?.unsubscribe();

    this.saveSub?.unsubscribe();

    this.cacheSub?.unsubscribe();

    this.poLineSub?.unsubscribe();
  }

  ngOnInit(): void {
    this.isNew = this.id === 0;

    if (this.isNew) {
      this.model = this.maintenanceEventService.generateNewMaintenanceEventModel(this.itemInstanceId !== -1 ? this.itemInstanceId : null);

      if (this.model.vendorId) {
        this.getPoLines();
      }

    } else {
      this.getModel();
    }

    this.itemInstanceService.getItemInstancesRepairDropdown();

    this.subscribeToVendorCache();

    this.getMaintenanceTypes();

    this.vendorService.getVendors(false, true, true);
  }

  public getModel(): void {
    this.modelSub = this.maintenanceEventService.getMaintenanceEventById(this.id, this.seePrices)
      .subscribe((x: MaintenanceEventModel) => {
        this.model = x;

        if (this.model.vendorId) {
          this.getPoLines();
        }

        this.selectedLines = this.model.purchaseOrderLines;
      });
  }

  public getPoLines(): void {
    this.poLineSub = this.lineService.getPoLinesByVendorIdForMaintenanceDropdown(
      this.model.vendorId,
      this.model.maintenanceEventId)
      .subscribe((x: PurchaseOrderLineModel[]) => {
        this.lineList = x;
      });
  }

  public openVendorModal(vendorId: number): void {
    const initialState = {
      id: vendorId,
      showInactive: false,
      launchedFromPoModal: true
    };

    this.bsModalRefChildModal = this.modalService.show(AddEditVendorModalComponent, { initialState, class: 'modal-lg', backdrop: 'static' });
  }

  public onChangeOfSerialNumber($event: ItemInstanceModel) {
    if ($event !== null && $event !== undefined) {
      this.model.maintenanceDurationUom = $event.maintenanceDurationUomName;
    } else {
      this.model.maintenanceDurationUom = null;
    }
  }

  public onChangeInHouseRepair($event: boolean) {
    if ($event !== null && $event !== undefined) {
      this.model.inHouseRepair = $event;

      if ($event === true) {
        this.model.vendorId = null;
      }
    }
  }

  public onChangeVendor($event: VendorModel) {
    this.lineList = [];
    this.selectedLines = [];

    if ($event !== null && $event !== undefined) {
      this.model.vendorId = $event.vendorId;
      this.getPoLines();
    }
  }

  public getMaintenanceTypes(): void {
    this.maintenanceTypeSub = this.maintenanceTypeService.maintenanceTypeList$
      .subscribe((x: MaintenanceTypeModel[]) => {
        this.maintenanceTypes = x;
      });
  }

  public subscribeToVendorCache(): void {
    this.vendorService.vendorCache.next(null);

    this.cacheSub = this.vendorService.vendorCache
      .subscribe((x: VendorModel) => {
        if (x !== null && x !== undefined) {
          this.model.vendorId = x.vendorId;

          this.getPoLines();
        }
      });
  }

  public onSave(): void {
    if (this.model.inHouseRepair) {
      this.model.vendorId = null;
    }

    let dto: MaintenanceEventPoLineLinkModel = new MaintenanceEventPoLineLinkModel();
    dto.maintenanceEventId = this.model.maintenanceEventId;
    dto.poLineIds = this.selectedLines.map((x: PurchaseOrderLineModel) => {
      return x.polineId;
    });

    this.model.maintenanceEventPoLineLinks = dto;

    this.saveSub = this.maintenanceEventService.addEditMaintenanceEvent(this.model, this.showInactive, this.itemInstanceId !== -1)
      .subscribe((x: boolean) => {
        if (x) {
          this.bsModalRef.hide();
          this.uiService.showSuccess('Log Saved', 'Success');
        } else {
          this.uiService.showError('Log Saved', 'Error');
        }
      });
  }

}
