import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ExcelExportData } from '@progress/kendo-angular-excel-export';
import { RowClassArgs } from '@progress/kendo-angular-grid';
import { process } from '@progress/kendo-data-query';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { MaintenanceTypeModel } from 'src/models/maintenance-type.model';
import { ItemInstanceService } from 'src/services/item-instance.service';
import { ItemService } from 'src/services/item.service';
import { KGridService } from 'src/services/k-grid.service';
import { MaintenanceEventService } from 'src/services/maintenance-event.service';
import { MaintenanceTypeService } from 'src/services/maintenance-type.service';
import { UIService } from 'src/services/ui.service';
import { UserService } from 'src/services/user.service';
import { VendorService } from 'src/services/vendor.service';
import { AddEditMaintenanceLogModalComponent } from '../add-edit-maintenance-log-modal/add-edit-maintenance-log-modal.component';

@Component({
  selector: 'app-maintenance-log-sm-list',
  templateUrl: './maintenance-log-sm-list.component.html',
  styleUrls: ['./maintenance-log-sm-list.component.css']
})

export class MaintenanceLogSmallListComponent implements OnInit, OnDestroy {
  @Input() itemInstanceId: number = -1;
  public showInactive: boolean = false;
  public showFilter = true;
  public bsModalRef: BsModalRef;
  public numberOfActiveFilterFields: number = 0;
  public seePrices: boolean = false;
  private deleteSub: Subscription;
  private saveSubscriptions = new Subscription();

  private maintenanceTypeSub: Subscription;
  public maintenanceTypes: MaintenanceTypeModel[];

  private userSub: Subscription;

  constructor(
    private modalService: BsModalService,
    public kGridService: KGridService,
    public maintenanceEventService: MaintenanceEventService,
    public maintenanceTypeService: MaintenanceTypeService,
    public itemInstanceService: ItemInstanceService,
    public itemService: ItemService,
    public vendorService: VendorService,
    public uiService: UIService,
    public userService: UserService
  ) {
    this.allData = this.allData.bind(this);
  };

  public ngOnDestroy(): void {
    this.kGridService.resetKGridParams();

    this.deleteSub?.unsubscribe();

    this.maintenanceTypeSub?.unsubscribe();

    this.saveSubscriptions?.unsubscribe();

    this.userSub?.unsubscribe();
  }

  ngOnInit(): void {
    this.canSeePrices();

    this.maintenanceEventService.getMaintenanceEventsByItemInstanceId(
      this.itemInstanceId,
      this.showInactive,
      true,
      true);
  }

  public rowCallback(context: RowClassArgs): any {
    return {
      inactive: context.dataItem.isActive === false
    };
  }

  public openMaintenanceEventModal(maintenanceEventId: number): void {
    const initialState = {
      id: maintenanceEventId,
      showInactive: this.showInactive,
      seePrices: this.seePrices,
      itemInstanceId: this.itemInstanceId
    };
    this.bsModalRef = this.modalService.show(AddEditMaintenanceLogModalComponent, { initialState, backdrop: 'static' });
  }

  public deleteMaintenanceEvent(
    $event: any,
    id: number): void {
    this.deleteSub = this.maintenanceEventService.deleteMaintenanceEvent(id, this.showInactive)
      .subscribe((x: boolean) => {
        if (x) {
          this.uiService.showSuccess('Success', 'Maintenance Log Deleted');
        } else {
          this.uiService.showError('Error', 'Maintenance Log Not Deleted');
        }
      });
  }

  public onChangeShowInactive($event): void {
    this.showInactive = $event;

    this.maintenanceEventService.getMaintenanceEvents(
      this.showInactive,
      true,
      false);
  }

  private canSeePrices(): void {
    this.userSub = this.userService.user.subscribe((user) => {
      if (user) {
        this.seePrices = user.activeRoles != null ?
          user.activeRoles.includes('General')
          : false;
      }
    });
  }

  public allData(): ExcelExportData {
    let data = this.maintenanceEventService.maintenanceEventByItemInstanceList.value;

    const result: ExcelExportData = {
      data: process(data, { group: null, sort: null }).data,
      group: null
    };

    return result;
  }

}
