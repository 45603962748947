import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Observer } from 'rxjs';
import { StorageLocationModel } from 'src/models/storage-location.model';

@Injectable()
export class StorageLocationService {
  constructor(
    private http: HttpClient,
    @Inject('BASE_URL') private baseUrl: string
  ) { }

  public storageLocationList: BehaviorSubject<Array<StorageLocationModel>> = new BehaviorSubject(null);
  public locationCache: BehaviorSubject<StorageLocationModel> = new BehaviorSubject(null);

  private apiUrl = 'api/StorageLocation';  // URL to web api

  public getStorageLocations(
    showInactive: boolean,
    clear: boolean = false): void {
    if (!this.storageLocationList.value || clear) {
      this.http.get<Array<StorageLocationModel>>(this.baseUrl + this.apiUrl + '/GetStorageLocations/' + showInactive)
        .subscribe((x: StorageLocationModel[]) => {
          this.storageLocationList.next(x);
        });
    }
  }

  public getStorageLocationById(id: number): Observable<StorageLocationModel> {
    return this.http.get<StorageLocationModel>(this.baseUrl + this.apiUrl + '/GetStorageLocationById/' + id);
  }

  public addEditStorageLocation(
    dto: StorageLocationModel,
    showInactive: boolean = false,
    cacheStorageLocation: boolean = false): Observable<boolean> {
    return new Observable((observer: Observer<boolean>) => {
      this.http.post<number>(this.baseUrl + this.apiUrl + '/AddEditStorageLocation', this.cleanStorageLocationModel(dto))
        .subscribe((x: number) => {
          if (x !== -1) {
            this.getStorageLocations(showInactive, true);
          }

          if (cacheStorageLocation) {
            dto.storageLocationId = x;

            this.locationCache.next(dto);
          } else {
            this.locationCache.value !== null ? this.locationCache.next(null) : null;
          }

          observer.next(x !== -1);
        });
    });
  }

  public deleteStorageLocation(
    id: number,
    showInactive: boolean): Observable<boolean> {
    return new Observable((observer: Observer<boolean>) => {
      this.http.get<boolean>(this.baseUrl + this.apiUrl + '/DeleteStorageLocation/' + id)
        .subscribe((x: boolean) => {
          if (x === true) {
            this.getStorageLocations(showInactive, true);
          }
          observer.next(x);
        });
    });
  }

  public generateNewStorageLocation(): StorageLocationModel {
    let model: StorageLocationModel = new StorageLocationModel();
    model.isActive = true;

    return model;
  }

  private cleanStorageLocationModel(model: StorageLocationModel): StorageLocationModel {
    model.name = model.name
      && model.name.trim() !== '' ?
      model.name.trim()
      : null;

    return model;
  }

}
