import { Component, OnDestroy, OnInit } from '@angular/core';
import { RowClassArgs } from '@progress/kendo-angular-grid';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { JobStatuses } from 'src/enums/job-statuses';
import { ContactModel } from 'src/models/contact.model';
import { WellModel } from 'src/models/well.model';
import { AppStateService } from 'src/services/app-state.service';
import { ContactService } from 'src/services/contact.service';
import { CustomerService } from 'src/services/customer.service';
import { EmployeeService } from 'src/services/employee.service';
import { JobStatusService } from 'src/services/job-status.service';
import { JobSubStatusService } from 'src/services/job-sub-status.service';
import { JobTypeService } from 'src/services/job-type.service';
import { JobService } from 'src/services/job.service';
import { KGridService } from 'src/services/k-grid.service';
import { UIService } from 'src/services/ui.service';
import { UserService } from 'src/services/user.service';
import { WellService } from 'src/services/well.service';
import { JobTypes } from '../../../enums/job-types';
import { ShopService } from '../../../services/shop.service';
import { AddEditJobSalesModalComponent } from '../shared/add-edit-job-sales-modal/add-edit-job-sales-modal.component';
import { Subscription } from 'rxjs';
import { ShopModel } from 'src/models/shop.model';
import { JobTypeModel } from 'src/models/job-type.model';
import { JobSubStatusModel } from 'src/models/job-sub-status.model';
import { CustomerModel } from 'src/models/customer.model';

@Component({
  selector: 'app-jobs-list',
  templateUrl: './list.component.html',
  styleUrls: ['list.component.css']
})

export class JobsListComponent implements OnInit, OnDestroy {
  private shopsSub: Subscription;
  public shops: ShopModel[];

  private jobTypesSub: Subscription;
  public jobTypes: JobTypeModel[];

  private jobSubStatusSub: Subscription;
  public jobSubStatuses: JobSubStatusModel[];

  private wellSub: Subscription;
  public localCustomerWells: WellModel[] = [];

  private contactsSub: Subscription;
  public localCustomerContacts: ContactModel[] = [];

  public showCancelled: boolean = false;
  public JobTypes = JobTypes;
  public JobStatuses = JobStatuses;
  public showFilter = true;
  public numberOfActiveFilterFields: number = 0;

  private isTabletSub: Subscription;
  public isTablet: boolean = false;

  constructor(
    private modalService: BsModalService,
    public jobTypeService: JobTypeService,
    public jobStatusService: JobStatusService,
    public jobSubStatusService: JobSubStatusService,
    public jobService: JobService,
    public customerService: CustomerService,
    public uiService: UIService,
    public kGridService: KGridService,
    public employeeService: EmployeeService,
    public wellService: WellService,
    public contactService: ContactService,
    public appStateService: AppStateService,
    public shopService: ShopService,
    public userService: UserService
  ) { };

  public bsModalRef: BsModalRef;

  ngOnDestroy(): void {
    this.contactsSub?.unsubscribe();

    this.shopsSub?.unsubscribe();

    this.jobTypesSub?.unsubscribe();

    this.jobSubStatusSub?.unsubscribe();

    this.isTabletSub?.unsubscribe();

    this.wellSub?.unsubscribe();
  }

  ngOnInit() {
    this.jobService.refreshJobs(
      this.showCancelled,
      false,
      true);

    this.getJobTypes();

    this.customerService.getCustomers(false);

    this.employeeService.getEmployees(false);

    this.getShops();

    this.getJobSubStatuses();

    this.determineIsTablet();

    if (this.jobService.jobFilterParams.jobTypeId !== null && this.jobService.jobFilterParams.jobTypeId !== undefined) {
      this.jobStatusService.getJobStatusesByJobTypeId(
        this.jobService.jobFilterParams.jobTypeId,
        false);
    }
  }

  public determineIsTablet(): void {
    this.isTabletSub = this.appStateService.isTablet$
      .subscribe((isTablet: boolean) => {
        this.isTablet = isTablet;
      });
  }

  public getShops(): void {
    this.shopsSub = this.shopService.shopList$
      .subscribe((shops: ShopModel[]) => {
        if (shops) {
          this.shops = shops;
        }
      });
  }

  public getJobTypes(): void {
    this.jobTypesSub = this.jobTypeService.jobTypeList$
      .subscribe((jobTypes: JobTypeModel[]) => {
        if (jobTypes) {
          this.jobTypes = jobTypes;
        }
      });
  }

  public getJobSubStatuses(): void {
    this.jobSubStatusSub = this.jobSubStatusService.jobSubStatusList$
      .subscribe((jobSubStatuses: JobSubStatusModel[]) => {
        if (jobSubStatuses) {
          this.jobSubStatuses = jobSubStatuses;
        }
      });
  }

  // Wells loaded locally to avoid clash between well list in service when job modal is instantiated
  public getWellsByCustomer(customerId: number): void {
    this.wellSub = this.wellService.getWellsByCustomerIdApiCall(customerId, false)
      .subscribe((x: WellModel[]) => {
        this.localCustomerWells = x;
      });
  }

  // Contacts loaded locally to avoid clash between contact list in service when job modal is instantiated
  public getContactsByCustomer(customerId: number): void {
    this.contactsSub = this.contactService.getCustomerContacts(customerId, false)
      .subscribe((x: ContactModel[]) => {
        this.localCustomerContacts = x;
      });
  }

  public onCustomerChange($event: CustomerModel): void {
    this.jobService.jobFilterParams.wellId = null;

    this.jobService.jobFilterParams.orderedById = null;
    if ($event !== undefined) {
      this.getWellsByCustomer($event.customerId);
      this.getContactsByCustomer($event.customerId);
    } else {
      this.clearLocalArrays();
    }
  }

  public clearLocalArrays(): void {
    this.localCustomerWells.length = 0;
    this.localCustomerContacts.length = 0;
  }

  public onChangeShowCancelled($event: boolean): void {
    this.showCancelled = $event;
    this.jobService.refreshJobs(this.showCancelled);
  }

  public onChangeJobType($event: JobTypeModel): void {
    this.jobService.jobFilterParams.jobStatusId = null;

    if ($event !== undefined && $event !== null) {
      this.jobStatusService.getJobStatusesByJobTypeId($event.jobTypeId, false);
    } else {
      this.jobStatusService.jobStatusList.next(null);
    }
  }

  public openJobModal(
    jobId: number,
    jobTypeId: number,
    isQuote: boolean = false): void {
    const initialState = {
      id: jobId,
      jobTypeId: jobTypeId,
      isQuote: isQuote
    };

    this.bsModalRef = this.modalService.show(AddEditJobSalesModalComponent, { initialState, class: 'modal-lg modal-xl', backdrop: 'static' });
  }

  public rowCallback(context: RowClassArgs): any {
    return {
      inactive: context.dataItem.jobStatusId === JobStatuses.Cancelled_Fishing
        || context.dataItem.jobStatusId === JobStatuses.Cancelled_FishingToolRental
        || context.dataItem.jobStatusId === JobStatuses.Cancelled_Packer
        || context.dataItem.jobStatusId === JobStatuses.Cancelled_Sales
    };
  }

  public getNumberOfActiveFilterFields() {
    if (!this.showFilter) {
      this.numberOfActiveFilterFields = this.jobService.getNumberOfActiveFilterFields();
    } else {
      this.numberOfActiveFilterFields = 0;
    }
  }

}
