<div class="page-header">
  <div class="page-header-content header-elements-md-inline">
    <div class="page-title d-flex">
      <a [routerLink]="[returnUrl]"
        class="breadcrumb-link-color">
        <h4><i class="icon-arrow-left52 mr-2"></i> <span class="font-weight-semibold">Sales Invoice</span> - Details
        </h4>
      </a>
      <button type="button"
        class="btn btn-outline-dark btn-sm ml-3 pl-1 pr-1 pt-0 pb-0 no-border"
        [title]="!showDetails ? 'Show Details' : 'Hide Details'"
        (click)="showDetails = !showDetails">
        <span *ngIf="!showDetails">
          <i class="fa-regular fa-chevrons-right"></i>&nbsp;Show </span>
        <span *ngIf="showDetails"><i class="fa-regular fa-chevrons-left"></i>&nbsp;Hide
        </span>Details</button>
    </div>
    <div class="header-elements d-none py-0 mb-3 mb-md-0">
      <div class="breadcrumb">
        <a href="index.html"
          class="breadcrumb-item"><i class="icon-home2 mr-2"></i> Invoice</a>
        <span class="breadcrumb-item active">Detail</span>
      </div>
    </div>
  </div>
</div>
<div class="page-content pt-0"
  *ngIf="jobService.job | async; let j">
  <div class="content-wrapper">
    <div class="content">
      <div class="row">
        <div class="col-lg-3"
          *ngIf="showDetails">
          <div class="card">
            <div class="card-header bg-light header-elements-inline">
              <h6 class="card-title">Invoice#: {{j.jobNumber}}</h6>
              <div class="header-elements">
                <button class="btn btn-light btn-sm"
                  (click)="openJobSalesModal(j.jobId)"
                  [disabled]="enableReorder || jobService.job.value !== null && jobService.job.value.jobStatusIsTerminal === true"><i
                    class="fa fa-edit"></i> Edit</button>
              </div>
            </div>
            <div class="card-body"
              [ngClass]="{'pr-2 pl-2':isTablet}">
              <div id="collapseBasic"
                [collapse]="isCollapsed">
                <app-job-info [job]="j"></app-job-info>
                <br />
              </div>
              <div class="row">
                <div class="col-md-12">
                  <button (click)="reportService.getSalesInvoicePDF(j.jobId)"
                    class="btn btn-light btn-sm btn-block"><i class="fal fa-file-pdf"></i> Print
                    {{jobService.job.value.jobStatusId === JobStatuses.Quote_Sales ? "Quote" : "Invoice"}}</button>
                  <app-sub-status-button [job]="j"
                    [enableReorder]="enableReorder"
                    *ngIf="jobService.job.value !== null 
                      && jobService.job.value.jobStatusId === JobStatuses.Open_Sales 
                      && (jobService.job.value.jobSubStatusId === JobSubStatuses.In_Progress 
                        || jobService.job.value.jobSubStatusId === JobSubStatuses.Pending_Approval
                        || jobService.job.value.jobSubStatusId === JobSubStatuses.Ready_for_Signature)">
                  </app-sub-status-button>
                  <app-status-button-sales [job]="j"
                    [enableReorder]="enableReorder"
                    *ngIf="jobService.job.value !== null 
                      && (jobService.job.value.jobStatusId !== JobStatuses.Open_Sales 
                        || (jobService.job.value.jobStatusId === JobStatuses.Open_Sales 
                          && jobService.job.value.jobSubStatusId === JobSubStatuses.Sent_for_Signature))">
                  </app-status-button-sales>
                </div>
              </div>
            </div>
          </div>
          <app-worksheets-sm-list></app-worksheets-sm-list>
          <ng-container>
            <app-job-po-display [purchaseOrders]="j.purchaseOrders"
              [disable]="j.jobStatusId !== JobStatuses.Open_Sales && j.jobStatusId !== JobStatuses.Quote_Sales"
              [jobId]="jobId"></app-job-po-display>
          </ng-container>
        </div>
        <div [ngClass]="{'col-lg-9': showDetails, 'col-lg-12': !showDetails}">
          <app-sales-invoice-table [job]="j"
            (enableReorderEvent)="handleEnableReorderEvent($event)">
          </app-sales-invoice-table>
          <div class="d-flex justify-content-end"
            *ngIf="j.totalStamp">
            <div class="card border-top-primary border-top-2 rounded-top-0 col-lg-3 col-md-5 col-sm-6"
              style="border-top-color: #2196F3 !important;">
              <div class="card-body">
                <table style="float:right;">
                  <tr>
                    <td style="font-weight: 500;">Sub Total:</td>
                    <td width="20px">&nbsp;</td>
                    <td>{{j.subTotalStamp | currency}}</td>
                  </tr>
                  <tr>
                    <td style="font-weight: 500;">Tax&nbsp;<small>({{j.salesTaxRateStamp | percent:'0.2'}})</small>:
                    </td>
                    <td width="20px">&nbsp;</td>
                    <td>{{j.salesTaxTotalStamp | currency}}</td>
                  </tr>
                  <tr>
                    <td style="font-weight: 500;">TOTAL:</td>
                    <td width="20px">&nbsp;</td>
                    <td><strong>{{j.totalStamp | currency}}</strong></td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div *ngIf="!jobService.job.value"
  class="page-content pt-0">
  <div class="content-wrapper">
    <div class="content">
      <div class="row">
        <div class="col-lg-3">
          <div class="card">
            <div class="card-header bg-light header-elements-inline">
              <h6 class="card-title">Invoice</h6>
              <div class="header-elements">
                <button class="btn btn-light btn-sm"
                  [disabled]="true"><i class="fa fa-edit"></i> Edit</button>
              </div>
            </div>
            <div class="card-body">
              <app-list-skeleton [numberToIterate]="12"></app-list-skeleton>
            </div>
          </div>
        </div>
        <div class="col-lg-9">
          <div class="card border-top-primary border-top-2 rounded-top-0"
            style="border-top-color: #2196F3 !important;">
            <div class="card-header bg-light header-elements-inline"
              style="height: 70px;">
              <h6 class="card-title">Sale Items</h6>
            </div>
            <div class="card-body">
              <app-table-skeleton [numberToIterate]="5"></app-table-skeleton>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
