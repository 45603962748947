<div class="modal-header"
  ngxModalDraggable>
  <h4 class="modal-title pull-left"
    *ngIf="model">Add Built Item</h4>
  <button type="button"
    class="close pull-right"
    aria-label="Close"
    (click)="bsModalRef.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div class="form-group row">
    <label for="title"
      class="col-sm-2 text-right control-label col-form-label">Category</label>
    <div class="col-sm-4 form-group mb-0"
      *ngIf="worksheetCategoryService.worksheetCategoriesList | async; let cats">
      <ng-select [items]="cats"
        bindLabel="name"
        bindValue="worksheetCategoryId"
        [ngModel]="worksheetCategoryId"
        (change)="onChangeOfWorksheetCategoryType($event)"
        (clear)="onChangeOfWorksheetCategoryType(null)"
        name="worksheetId">
      </ng-select>
    </div>
  </div>
  <div class="form-group row">
    <label for="title"
      class="col-sm-2 text-right control-label col-form-label">Packer</label>
    <div class="col-sm-10 form-group mb-0"
      *ngIf="model">
      <ng-select [items]="model"
        bindLabel="dropDownDisplay"
        bindValue="worksheetId"
        [(ngModel)]="worksheetId"
        (change)="getWorksheetModel(worksheetId)"
        name="worksheetId">
      </ng-select>
    </div>
  </div>
  <div class="form-group row"
    *ngIf="chosenWs && !isLoading">
    <div class="col-sm-2 text-right control-label col-form-label"></div>
    <div class="col-sm-10 form-group mb-0">
      <table width="100%">
        <thead>
          <tr>
            <th></th>
            <th>Item</th>
            <th>Quantity</th>
          </tr>
        </thead>
        <tbody>
          <ng-container *ngFor="let row of chosenWs.worksheetRowsCombined">
            <tr *ngIf="row.quantity > 0">
              <td><i class="icon-gear"
                  style="color: gray;"></i></td>
              <td>{{row.selectedItemName}}</td>
              <td>{{row.quantity}}</td>
            </tr>
          </ng-container>
        </tbody>
      </table>
    </div>
  </div>
  <div class="form-group row"
    *ngIf="!chosenWs && isLoading">
    <div class="col-sm-2 text-right control-label col-form-label"></div>
    <div class="col-sm-10 form-group mb-0">
      <app-table-skeleton [numberToIterate]="3"></app-table-skeleton>
    </div>
  </div>
</div>
<div class="modal-footer"
  *ngIf="model">
  <button type="button"
    class="btn btn-primary"
    (click)="onSave()"
    [disabled]="disableSave || worksheetId === null">Save</button>
  <button type="button"
    class="btn btn-default"
    (click)="bsModalRef.hide()">Cancel</button>
</div>
