import { ItemVendorModel } from './item-vendor.model';
import { ShopItemModel } from './shop-item.model';
import { WorksheetTypeModel } from './worksheet-type.model';
import { WorksheetModel } from './worksheet.model';

export class ItemModel {
  itemId: number;
  partNumber: string;
  description: string;
  vendorId: number;
  uomId: number;
  partGroupId: number;
  cost: number | null;
  minNumDays: number | null;
  numberOfConnections: number | null;
  bodyInspection: boolean;
  rodInspection: boolean;
  chargeForRunsAfterScrap: boolean;
  isActive: boolean;
  returnToInvAfterFishing: boolean;
  itemTypeId: number;

  totalInventoryCount: number;
  totalReserveCount: number;
  inShopSerializableCount: number | null;
  onJobSerializableCount: number | null;
  totalSerializableCount: number | null;
  shopItems: ShopItemModel[];
  inventoryPopover: string;
  reservePopover: string;
  inventoryInOpenPos: number | null;
  dropdownDisplay: string;
  vendorName: string;
  uomName: string;
  partGroupName: string;
  partFamilyPrefix: string;
  partFamilyId: number;
  partFamilyName: string;
  itemVendor: ItemVendorModel[];
  hasImage: boolean;
  worksheetTypeId: number | null;
  worksheetTypeName: string;
  lastDateSold: Date | string | null;
  amountSoldDefinedPeriod: number | null;


  disableSerTrackInvSwitch: boolean;
  showAdditionalDaysOnly: boolean = false;
  showChargeAllMinimumDays: boolean = false;
  worksheets: WorksheetTypeModel[];
  inStockBuilds: WorksheetModel[];
}
