<div class="page-header">
  <div class="page-header-content header-elements-md-inline">
    <div class="page-title d-flex">
      <a [routerLink]="['/home']"
        class="breadcrumb-link-color">
        <h4><i class="icon-arrow-left52 mr-2"></i> <span class="font-weight-semibold">Purchase Orders</span> - List</h4>
      </a>
      <button type="button"
        class="btn btn-outline-dark btn-sm ml-3 pl-1 pr-1 pt-0 pb-0 no-border"
        [title]="!showFilter ? 'Show Filter' : 'Hide Filter'"
        (click)="showFilter = !showFilter;getNumberOfActiveFilterFields();">
        <span *ngIf="!showFilter">
          <i class="fa-regular fa-chevrons-right"></i>&nbsp;Show </span>
        <span *ngIf="showFilter"><i class="fa-regular fa-chevrons-left"></i>&nbsp;Hide
        </span>Filter<span class="text-warning"
          *ngIf="!showFilter && numberOfActiveFilterFields > 0"><strong>&nbsp;&nbsp;({{numberOfActiveFilterFields}}
            active
            criteria)</strong></span></button>
    </div>
    <div class="header-elements d-none py-0 mb-3 mb-md-0">
      <div class="breadcrumb">
        <a href="index.html"
          class="breadcrumb-item"><i class="icon-home2 mr-2"></i> Purchase Order</a>
        <span class="breadcrumb-item active">List</span>
      </div>
    </div>
  </div>
</div>
<div class="page-content pt-0">
  <div class="content-wrapper">
    <div class="content">
      <div class="row">
        <div class="col-lg-3"
          *ngIf="showFilter">
          <div class="card">
            <div class="card-header bg-light header-elements-inline"
              [ngClass]="{'pr-2 pl-2':isTablet}">
              <h6 class="card-title">Filter Criteria</h6>
            </div>
            <div class="card-body"
              [ngClass]="{'pr-2 pl-2':isTablet}">
              <div class="form-group row">
                <label class="col-form-label col-lg-4 col-md-2">PO#</label>
                <div class="col-lg-8 col-md-4"
                  [ngClass]="{'pl-0':isTablet}">
                  <input type="text"
                    class="form-control"
                    [(ngModel)]="poService.poFilterParams.ponumber"
                    name="ponumberFilter"
                    (keyup.enter)="poService.refreshPurchaseOrderList(false);kGridService.pageChange({ skip: 0, take: kGridService.kGridParams.pageSize })">
                </div>
                <label class="col-form-label col-lg-4 col-md-2 mt-lg-3 mt-sm-0">Vendor</label>
                <div class="col-lg-8 col-md-4 mt-lg-3"
                  [ngClass]="{'pl-0':isTablet}"
                  *ngIf="vendorService.vendorList | async; let vend">
                  <ng-select [items]="vend"
                    bindLabel="name"
                    bindValue="vendorId"
                    [(ngModel)]="poService.poFilterParams.vendorId"
                    name="vendors"
                    appendTo='body'>
                  </ng-select>
                </div>
                <div *ngIf="!vendorService.vendorList.value"
                  class="col-lg-8 col-md-4 mt-lg-3"
                  [ngClass]="{'pl-0':isTablet}">
                  <ng-select name="vendors"
                    [readonly]="true">
                  </ng-select>
                </div>
              </div>
              <div class="form-group row">
                <label class="col-form-label col-lg-4 col-md-2">Shop</label>
                <div class="col-lg-8 col-md-4"
                  [ngClass]="{'pl-0':isTablet}"
                  *ngIf="shops">
                  <ng-select [items]="shops"
                    bindLabel="name"
                    bindValue="shopId"
                    [(ngModel)]="poService.poFilterParams.shopId"
                    name="shops"
                    appendTo='body'>
                  </ng-select>
                </div>
                <div *ngIf="!shops"
                  class="col-lg-8 col-md-4"
                  [ngClass]="{'pl-0':isTablet}">
                  <ng-select name="shops"
                    [readonly]="true">
                  </ng-select>
                </div>
                <label class="col-form-label col-lg-4 col-md-2 mt-lg-3 mt-sm-0">Account</label>
                <div class="col-lg-8 col-md-4 mt-lg-3"
                  [ngClass]="{'pl-0':isTablet}"
                  *ngIf="accounts">
                  <ng-select [items]="accounts"
                    bindLabel="name"
                    bindValue="accountId"
                    [(ngModel)]="poService.poFilterParams.accountId"
                    name="accounts"
                    appendTo='body'>
                  </ng-select>
                </div>
                <div *ngIf="!accounts"
                  class="col-lg-8 col-md-4 mt-lg-3"
                  [ngClass]="{'pl-0':isTablet}">
                  <ng-select name="accounts"
                    [readonly]="true">
                  </ng-select>
                </div>
              </div>
              <div class="form-group row">
                <label class="col-form-label col-lg-4 col-md-2">Status</label>
                <div class="col-lg-8 col-md-4"
                  [ngClass]="{'pl-0':isTablet}"
                  *ngIf="statusService.statusList | async; let stat">
                  <ng-select [items]="stat"
                    bindLabel="name"
                    bindValue="postatusId"
                    [(ngModel)]="poService.poFilterParams.derivedStatusId"
                    name="statuses"
                    appendTo='body'>
                  </ng-select>
                </div>
                <div *ngIf="!statusService.statusList.value"
                  class="col-lg-8 col-md-4"
                  [ngClass]="{'pl-0':isTablet}">
                  <ng-select name="statuses"
                    [readonly]="true">
                  </ng-select>
                </div>
                <label class="col-form-label col-lg-4 col-md-2 mt-lg-3 mt-sm-0">Description</label>
                <div class="col-lg-8 col-md-4 mt-lg-3"
                  [ngClass]="{'pl-0':isTablet}">
                  <input type="text"
                    class="form-control"
                    [(ngModel)]="poService.poFilterParams.description"
                    name="ponumberFilter"
                    (keyup.enter)="poService.refreshPurchaseOrderList(false);kGridService.pageChange({ skip: 0, take: kGridService.kGridParams.pageSize })">
                </div>
              </div>
              <div class="form-group row">
                <label class="col-form-label col-lg-4 col-md-2">Item</label>
                <div class="col-lg-8 col-md-4"
                  [ngClass]="{'pl-0':isTablet}"
                  *ngIf="itemService.itemDropdownList | async; let item">
                  <ng-select [items]="item"
                    bindLabel="dropdownDisplay"
                    bindValue="itemId"
                    [(ngModel)]="poService.poFilterParams.itemIds"
                    name="items"
                    [clearable]="false"
                    [multiple]="true"
                    appendTo='body'>
                  </ng-select>
                </div>
                <div *ngIf="!itemService.itemDropdownList.value"
                  class="col-lg-8 col-md-4"
                  [ngClass]="{'pl-0':isTablet}">
                  <ng-select name="items"
                    [readonly]="true">
                  </ng-select>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-6">
                  <button type="submit"
                    class="btn btn-block btn-primary"
                    (click)="poService.refreshPurchaseOrderList(false);kGridService.pageChange({ skip: 0, take: kGridService.kGridParams.pageSize })"><i
                      class="fa fa-search mr-2"></i>Search</button>
                </div>
                <div class="col-sm-6">
                  <button type="submit"
                    class="btn btn-block btn-light"
                    (click)="poService.clearFilterParams(false);kGridService.pageChange({ skip: 0, take: kGridService.kGridParams.pageSize })"><i
                      class="far fa-times-circle"></i>&nbsp;Clear</button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div [ngClass]="{'col-lg-9': showFilter, 'col-lg-12': !showFilter}">
          <div class="card border-top-primary border-top-2 rounded-top-0"
            style="border-top-color: #2196F3 !important;">
            <div class="card-header bg-light header-elements-sm-inline">
              <h6 class="card-title">Purchase Orders</h6>
              <div class="header-elements">
                <div class="switch-container pt-2 mr-2">
                  <span class="switch-label">Show Inactive </span>
                  <ui-switch [ngModel]="showInactive"
                    (change)="onChangeShowInactive($event)"></ui-switch>
                </div>
                <button class="btn btn-light btn-sm"
                  (click)="createPoModal()">
                  <i class="fa fa-plus"></i> New PO
                </button>
              </div>
            </div>
            <app-table-skeleton *ngIf="poService.poListIsLoading"></app-table-skeleton>
            <ng-container *ngIf="!poService.poListIsLoading">
              <div class="table-responsive"
                *ngIf="poService.purchaseOrderList | async; let model">
                <kendo-grid [kendoGridBinding]="model"
                  filterable="false"
                  [sortable]="true"
                  [resizable]="true"
                  style="min-width:1200px;"
                  [style.maxHeight.px]="650"
                  [rowClass]="rowCallback"
                  [pageSize]="kGridService.kGridParams.pageSize"
                  [skip]="kGridService.kGridParams.skip"
                  (pageChange)="kGridService.pageChange($event)"
                  [pageable]="{
                                        buttonCount: kGridService.kGridParams.buttonCount,
                                        info: kGridService.kGridParams.info,
                                        type: kGridService.kGridParams.type,
                                        pageSizes: kGridService.kGridParams.pageSizes,
                                        previousNext: kGridService.kGridParams.previousNext
                                      }">
                  <ng-template kendoGridToolbarTemplate>
                    <button type="button"
                      kendoGridExcelCommand
                      icon="file-excel">Export to Excel</button>
                  </ng-template>
                  <kendo-grid-column field="one"
                    title=""
                    [width]="60"
                    filterable="false">
                    <ng-template kendoGridCellTemplate
                      let-dataItem
                      let-rowIndex="rowIndex">
                      <a class="btn btn-xs btn-light"
                        [routerLink]="['/purchase-orders/details', dataItem.poid]"><i class="fa fa-search"></i></a>
                    </ng-template>
                  </kendo-grid-column>
                  <kendo-grid-column field="ponumberDisplay"
                    title="PO#"
                    [width]="70">
                  </kendo-grid-column>
                  <kendo-grid-column field="podate"
                    title="Date"
                    [width]="150">
                    <ng-template kendoGridCellTemplate
                      let-dataItem
                      let-rowIndex="rowIndex">
                      {{dataItem.podate | date:'longDate'}}
                    </ng-template>
                  </kendo-grid-column>
                  <kendo-grid-column field="shopName"
                    title="Shop"
                    [width]="100">
                  </kendo-grid-column>
                  <kendo-grid-column field="description"
                    title="Description">
                    <ng-template kendoGridCellTemplate
                      let-dataItem
                      let-rowIndex="rowIndex">
                      <span *ngIf="!dataItem.isActive"
                        class="text-danger "><strong>PO DELETED&nbsp;</strong></span>{{dataItem.description}}
                    </ng-template>
                  </kendo-grid-column>
                  <kendo-grid-column field="vendorName"
                    title="Vendor"
                    [width]="200">
                  </kendo-grid-column>
                  <kendo-grid-column field="total"
                    title="Total"
                    [width]="120">
                    <ng-template kendoGridCellTemplate
                      let-dataItem
                      let-rowIndex="rowIndex">
                      {{dataItem.total | currency}}
                    </ng-template>
                  </kendo-grid-column>
                  <kendo-grid-column field="derivedStatusName"
                    title="Status"
                    [width]="120">
                  </kendo-grid-column>
                  <kendo-grid-column field="createdByName"
                    title="Created By"
                    [width]="150">
                  </kendo-grid-column>
                  <kendo-grid-column field="one"
                    title=""
                    [width]="60"
                    filterable="false">
                    <ng-template kendoGridCellTemplate
                      let-dataItem
                      let-rowIndex="rowIndex">
                      <button class="btn btn-xs btn-light"
                        [swal]="{ title: 'Delete PO #' + dataItem.ponumber + '?', text: dataItem.vendorName + ' - $' + dataItem.total, showCancelButton: true, confirmButtonText: 'Delete', confirmButtonColor: '#DC3545' }"
                        (confirm)="deletePo(dataItem)"
                        [disabled]="dataItem.derivedStatusId!==PurchaseOrderStatuses.Draft || dataItem.isActive === false">
                        <i class="fa fa-trash"></i>
                      </button>
                    </ng-template>
                  </kendo-grid-column>
                  <kendo-grid-excel fileName="Grizzly - Purchase Orders.xlsx">
                    <kendo-excelexport-column field="ponumberDisplay"
                      title="PO#">
                    </kendo-excelexport-column>
                    <kendo-excelexport-column field="podate"
                      title="Date">
                    </kendo-excelexport-column>
                    <kendo-excelexport-column field="shopName"
                      title="Shop">
                    </kendo-excelexport-column>
                    <kendo-excelexport-column field="description"
                      title="Description">
                    </kendo-excelexport-column>
                    <kendo-excelexport-column field="vendorName"
                      title="Vendor">
                    </kendo-excelexport-column>
                    <kendo-excelexport-column field="description"
                      title="Description">
                    </kendo-excelexport-column>
                    <kendo-excelexport-column field="total"
                      title="Total">
                    </kendo-excelexport-column>
                    <kendo-excelexport-column field="derivedStatusName"
                      title="Status">
                    </kendo-excelexport-column>
                    <kendo-excelexport-column field="createdByName"
                      title="Created By">
                    </kendo-excelexport-column>
                    <kendo-excelexport-column field="isActive"
                      title="Active">
                    </kendo-excelexport-column>
                  </kendo-grid-excel>
                </kendo-grid>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
