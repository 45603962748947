import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Observer } from 'rxjs';
import { JobTypes } from 'src/enums/job-types';
import { CancellationReasonModel } from 'src/models/cancellation-reason';
import { JobStatusModel } from 'src/models/job-status.model';

@Injectable()
export class JobStatusService {
  constructor(
    private http: HttpClient,
    @Inject('BASE_URL') private baseUrl: string
  ) { }

  public jobStatusList: BehaviorSubject<Array<JobStatusModel>> = new BehaviorSubject(null);
  public fullJobStatusList: BehaviorSubject<Array<JobStatusModel>> = new BehaviorSubject(null);

  private apiUrl = 'api/JobStatus';  // URL to web api
  public JobTypes = JobTypes;

  public addEditJobStatus(dto: JobStatusModel, showInactive = false): Observable<boolean> {
    dto = this.cleanJobStatusModel(dto);

    return new Observable((observer: Observer<boolean>) => {
      this.http.post<boolean>(this.baseUrl + this.apiUrl + '/AddEditJobStatus', dto)
        .subscribe((x: boolean) => {
          if (x === true) {
            this.getJobStatuses(showInactive);
          }
          observer.next(x);
        });
    });
  }

  public deleteJobStatus(id: number, showInactive = false): Observable<boolean> {
    return new Observable((observer: Observer<boolean>) => {
      this.http.get<boolean>(this.baseUrl + this.apiUrl + '/DeleteJobStatus/' + id)
        .subscribe((x: boolean) => {
          if (x === true) {
            this.getJobStatuses(showInactive);
          }
          observer.next(x);
        });
    });
  }

  public getJobStatusById(id: number): Observable<JobStatusModel> {
    return this.http.get<JobStatusModel>(this.baseUrl + this.apiUrl + '/GetJobStatusById/' + id);
  }

  public getJobStatuses(showInactive: boolean = false): void {
    this.http.get<JobStatusModel[]>(this.baseUrl + this.apiUrl + '/GetJobStatuses/' + showInactive)
      .subscribe((x: JobStatusModel[]) => {
        this.fullJobStatusList.next(x);
      });
  }

  public getJobStatusesByJobTypeId(
    jobTypeId: number,
    showInactive: boolean): void {
    this.jobStatusList.next(null);
    this.http.get<Array<JobStatusModel>>(this.baseUrl + this.apiUrl + '/GetJobStatusesByJobTypeId/' + jobTypeId + '/' + showInactive)
      .subscribe((x: JobStatusModel[]) => {
        this.jobStatusList.next(x);
      });
  }

  public getCancellationReasons(): Observable<CancellationReasonModel[]> {
    return this.http.get<CancellationReasonModel[]>(this.baseUrl + this.apiUrl + '/GetCancellationReasons');
  }

  public generateJobStatusModel(): JobStatusModel {
    let model: JobStatusModel = new JobStatusModel();

    model.jobTypeId = this.JobTypes.Sales;
    model.statusOrder = this.getLastOrderNumber(this.JobTypes.Sales);
    model.isActive = true;
    model.isTerminal = false;

    return model;
  }

  public getLastOrderNumber(jobTypeId: number): number {
    const js = this.fullJobStatusList.value.filter(jt => jt.jobTypeId === jobTypeId);

    const last: number = js && js.length > 0 ? Math.max.apply(Math, js.map((x: JobStatusModel) => {
      return x.statusOrder;
    })) : null;

    return last !== null ? last + 1 : 0;
  }


  private cleanJobStatusModel(model: JobStatusModel): JobStatusModel {
    Object.keys(model).forEach((index: string) => {
      if (typeof model[index] === 'string') {
        model[index] = model[index]
          && model[index].trim() !== '' ?
          model[index].trim()
          : null;
      }
    });

    return model;
  }

}
