import { Component, OnDestroy, OnInit } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { AddEditBillingCodeModalComponent } from 'src/app/billing-codes/add-edit-billing-code-modal/add-edit-billing-code-modal.component';
import { AddEditItemModalComponent } from 'src/app/items/add-edit-item-modal/add-edit-item-modal.component';
import { BillingCodeModel } from 'src/models/billing-code.model';
import { ItemDropdownModel } from 'src/models/item-dropdown.model';
import { ItemModel } from 'src/models/item.model';
import { WorksheetRowItemTypeModel } from 'src/models/worksheet-row-item-type.model';
import { BillingCodeService } from 'src/services/billing-code.service';
import { ItemService } from 'src/services/item.service';
import { UIService } from 'src/services/ui.service';
import { WorksheetRowItemTypeService } from 'src/services/worksheet-row-item-type.service';

@Component({
  selector: 'app-add-edit-worksheet-row-item-type-modal',
  templateUrl: './add-edit-worksheet-row-item-type-modal.component.html',
  styleUrls: ['add-edit-worksheet-row-item-type-modal.component.css']
})

export class AddEditWorksheetRowItemTypeModalComponent implements OnInit, OnDestroy {
  public isNew: boolean = false;
  public id: number = -1;
  public isItemized = true;
  public worksheetRowTypeId: number = -1;
  public newRowItemSortValue: number = -1;
  public worksheetTypeId: number = -1;
  public worksheetRowTypeName: string = null;
  public worksheetTypeName: string = null;
  public showInactive: boolean = false;
  public isInventoryItem: boolean = false;
  public disableSave: boolean = false;

  private modelSub: Subscription;
  public model: WorksheetRowItemTypeModel;

  private bcListSub: Subscription;
  public billingCodes: Array<BillingCodeModel> = [];

  private billingCodeSub: Subscription;
  private itemSub: Subscription;
  private saveSub: Subscription;

  constructor(
    public bsModalRef: BsModalRef,
    public bsModalRefChildModal: BsModalRef,
    public uiService: UIService,
    public worksheetRowItemTypeService: WorksheetRowItemTypeService,
    public itemService: ItemService,
    public billingCodeService: BillingCodeService,
    private modalService: BsModalService,
  ) { };

  ngOnDestroy(): void {
    this.bcListSub?.unsubscribe();

    this.billingCodeSub?.unsubscribe();

    this.modelSub?.unsubscribe();

    this.itemSub?.unsubscribe();

    this.saveSub?.unsubscribe();
  }

  ngOnInit(): void {
    this.billingCodeService.getBillingCodes(
      false,
      true,
      false,
      true);

    this.itemService.getItemsDropdown(false);

    this.subscribeToBillingCodeCache();

    this.subscribeToItemCache();

    this.isNew = this.id === 0;

    if (this.isNew) {
      this.model = this.worksheetRowItemTypeService.generateNewWorksheetRowItemTypeModel(this.worksheetRowTypeId);
      this.model.worksheetTypeName = this.worksheetTypeName;
      this.model.worksheetTypeId = this.worksheetTypeId;
      this.model.worksheetRowTypeName = this.worksheetRowTypeName;
      this.model.sortOrder = this.newRowItemSortValue;

      this.subscribeToBillingCodes();
    } else {
      this.getModel();
    }
  }

  public getModel(): void {
    this.modelSub = this.worksheetRowItemTypeService.getWorksheetRowItemTypeById(this.id)
      .subscribe((x: WorksheetRowItemTypeModel) => {
        this.isInventoryItem = x.itemId !== null && x.itemId !== undefined;
        this.model = x;
        this.subscribeToBillingCodes();
      });
  }
  // ITEM AND BILLING CODE STUFF//
  // this subscribe exists to remove a race condition where there would not yet be values in billingCodeList when the modal renders
  public subscribeToBillingCodes(): void {
    this.bcListSub = this.billingCodeService.billingCodeList
      .subscribe((x: BillingCodeModel[]) => {
        if (x !== undefined && x !== null) {
          this.filterBillingCodes(this.model.itemId);
        }
      });
  }

  public subscribeToItemCache(): void {
    this.itemService.itemCache.next(null);

    this.itemSub = this.itemService.itemCache
      .subscribe((x: ItemModel) => {
        if (x !== null && x !== undefined) {
          this.model.itemId = x.itemId;
          this.isInventoryItem = true;
        }
      });
  }

  public subscribeToBillingCodeCache(): void {
    this.billingCodeService.billingCodeCache.next(null);

    this.billingCodeSub = this.billingCodeService.billingCodeCache
      .subscribe((x: BillingCodeModel) => {
        if (x !== null && x !== undefined) {
          this.model.billingCodeId = x.billingCodeId;
        }
      });
  }

  public onChangeOfItem($event: ItemDropdownModel): void {
    this.isInventoryItem = $event !== undefined;

    this.model.billingCodeId = null;

    this.billingCodes.length = 0;

    if ($event !== undefined) {
      this.filterBillingCodes($event.itemId);
    }
  }

  public onChangeOfChargeItemizedOption($event: boolean): void {
    if ($event !== undefined) {
      this.model.chargeItemizedOption = $event;
      if ($event === false) {
        this.model.optionBillingCodeId = null;
      }
    }
  }

  // only called on change of Item select menu
  public onChangeOfBillingCode($event: BillingCodeModel): void {
    if ($event !== undefined) {
      this.model.billingCodeId = $event.billingCodeId;
    } else {
      this.model.billingCodeId = null;
    }
  }

  public onChangeOfIsNote($event: boolean): void {
    if ($event !== undefined) {
      this.model.isNote = $event;
      if ($event === true) {
        this.model.zeroCharge = false;
        this.model.isOption = false;
        this.model.chargeItemizedOption = false;
        this.model.itemId = null;
        this.model.billingCodeId = null;
      }
    }
  }

  public filterBillingCodes(itemId: number): void {
    if (this.isInventoryItem) {
      this.billingCodes = this.billingCodeService.billingCodeList.value !== null ?
        (this.billingCodeService.billingCodeList.value.filter((x: BillingCodeModel) => {
          return x.itemId == itemId && x.itemId !== null && x.customerId === null;
        })) : [];

      let currentBillingCode: BillingCodeModel[] = this.billingCodes.filter((x: BillingCodeModel) => {
        return x.billingCodeId === this.model.billingCodeId;
      });
      // if the current billing code id exists in the list of filtered billing codes, the billing code is not changed.
      if (currentBillingCode.length === 0) {
        this.model.billingCodeId = this.billingCodes.length > 0 ? this.billingCodes[0].billingCodeId : null;
      }
    } else {
      // retrieve only billing codes without itemIds for misc items
      this.billingCodes = this.billingCodeService.billingCodeList.value !== null ?
        (this.billingCodeService.billingCodeList.value.filter((x: BillingCodeModel) => {
          return x.itemId === null;
        })) : [];
    }

    const find = this.billingCodes.filter((y: BillingCodeModel) => {
      return y.billingCodeId === this.model.billingCodeId;
    });

    if (find.length === 0 && this.model && this.model.billingCodeId !== undefined && this.model.billingCodeId !== null) {
      const retrieve = this.billingCodeService.billingCodeList.value != null ?
        this.billingCodeService.billingCodeList.value.filter((y: BillingCodeModel) => {
          return y.billingCodeId === this.model.billingCodeId;
        }) : [];
      this.billingCodes = [...retrieve, ...this.billingCodes];
    }
  }
  // END ITEM AND BILLING CODE STUFF//

  // MODAL STUFF//
  public openItemModal(
    itemId: number,
    $event: any): void {
    $event.preventDefault();

    const initialState = {
      id: itemId,
      showInactive: false,
      launchedFromModal: true,
      refreshDropdown: true
    };

    this.bsModalRefChildModal = this.modalService.show(AddEditItemModalComponent, { initialState, class: 'modal-lg', backdrop: 'static' });
  }

  public openBillingCodeModal(
    billingCodeId: number,
    $event: any): void {
    $event.preventDefault();

    const initialState = {
      id: billingCodeId,
      itemId: this.model.itemId,
      showInactive: false,
      launchedFromWorksheet: true,
      isInventoryItem: this.model.itemId !== undefined
        && this.model.itemId !== null
        && this.model.itemId !== 0
        && this.model.itemId !== -1,
      cacheBillingCode: true,
    };

    this.bsModalRefChildModal = this.modalService.show(AddEditBillingCodeModalComponent, { initialState, backdrop: 'static' });
  }
  // END MODAL STUFF//

  public onSave(): void {
    this.disableSave = true;

    this.saveSub = this.worksheetRowItemTypeService.addEditWorksheetRowItemType(
      this.model,
      true)
      .subscribe((x: boolean) => {
        if (x) {
          this.bsModalRef.hide();
          this.uiService.showSuccess('Worksheet Row Item Type Saved', 'Success');
        } else {
          this.disableSave = false;
          this.uiService.showError('Worksheet Row Item Type Not Saved', 'Error');
        }
      });
  }

}
