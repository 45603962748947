<div class="page-header">
  <div class="page-header-content header-elements-md-inline">
    <div class="page-title d-flex">
      <a [routerLink]="['/home']"
        class="breadcrumb-link-color">
        <h4><i class="icon-arrow-left52 mr-2"></i> <span class="font-weight-semibold">Customers</span> - List</h4>
      </a>
    </div>
    <div class="header-elements d-none py-0 mb-3 mb-md-0">
      <div class="breadcrumb">
        <a href="index.html"
          class="breadcrumb-item"><i class="icon-home2 mr-2"></i> Customer</a>
        <span class="breadcrumb-item active">List</span>
      </div>
    </div>
  </div>
</div>
<div class="page-content pt-0">
  <div class="content-wrapper">
    <div class="content">
      <div class="row">
        <div class="col-md-12">
          <div class="card border-top-primary border-top-2 rounded-top-0"
            style="border-top-color: #2196F3 !important;">
            <div class="card-header bg-light header-elements-sm-inline">
              <h6 class="card-title">Customers</h6>
              <div class="header-elements">
                <div class="switch-container pt-2 mr-2">
                  <span class="switch-label">Show Inactive </span>
                  <ui-switch [ngModel]="showInactive"
                    (change)="onChangeShowInactive($event)"></ui-switch>
                </div>
                <button class="btn btn-light btn-sm"
                  (click)="openCustomerModal(0)">
                  <i class="fa fa-plus"></i> New Customer
                </button>
              </div>
            </div>
            <app-table-skeleton *ngIf="customerService.customerListIsLoading"></app-table-skeleton>
            <ng-container *ngIf="!customerService.customerListIsLoading">
              <div class="table-responsive"
                *ngIf="customerService.customerList | async; let model">
                <kendo-grid [kendoGridBinding]="model"
                  filterable="menu"
                  [sortable]="true"
                  [resizable]="true"
                  [rowClass]="rowCallback"
                  [style.maxHeight.px]="650"
                  [ngClass]="{'kendo-min-width': !isTablet}">
                  <kendo-grid-column field="one"
                    title=""
                    [width]="60"
                    filterable="false">
                    <ng-template kendoGridCellTemplate
                      let-dataItem
                      let-rowIndex="rowIndex">
                      <a class="btn btn-xs btn-light"
                        [routerLink]="['/customers/details', dataItem.customerId]"><i class="fa fa-search"></i></a>
                    </ng-template>
                  </kendo-grid-column>
                  <kendo-grid-column field="name"
                    title="Customer">
                  </kendo-grid-column>
                  <kendo-grid-column field="abbreviatedName"
                    title="Abbreviated Name"
                    width="150">
                  </kendo-grid-column>
                  <kendo-grid-column field="paymentTermName"
                    title="Payment Terms"
                    width="150">
                  </kendo-grid-column>
                  <kendo-grid-column field="dailyInvoiceMax"
                    title="Daily Invoice Max."
                    [width]="170"
                    filterable="false">
                    <ng-template kendoGridCellTemplate
                      let-dataItem
                      let-rowIndex="rowIndex">
                      <span *ngIf="dataItem.dailyInvoiceMax">{{dataItem.dailyInvoiceMax | currency}}</span>
                      <span *ngIf="!dataItem.dailyInvoiceMax"
                        class="text-muted">n/a</span>
                    </ng-template>
                  </kendo-grid-column>
                  <kendo-grid-column field="isActive"
                    title="Active"
                    [width]="80"
                    filterable="false">
                    <ng-template kendoGridCellTemplate
                      let-dataItem
                      let-rowIndex="rowIndex">
                      <span [ngClass]="{ 'text-danger' : !dataItem.isActive }">{{dataItem.isActive ? 'Yes' :
                        'No'}}</span>
                    </ng-template>
                  </kendo-grid-column>
                  <kendo-grid-column field="one"
                    title=""
                    [width]="60"
                    filterable="false">
                    <ng-template kendoGridCellTemplate
                      let-dataItem
                      let-rowIndex="rowIndex">
                      <button class="btn btn-xs btn-light"
                        [swal]="{ title: 'Delete Customer?', text: dataItem.name, showCancelButton: true, confirmButtonText: 'Delete', confirmButtonColor: '#DC3545' }"
                        (confirm)="deleteCustomer(dataItem.customerId)"
                        [disabled]="dataItem.isActive === false">
                        <i class="fa fa-trash"></i>
                      </button>
                    </ng-template>
                  </kendo-grid-column>
                </kendo-grid>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
