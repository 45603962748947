import { Component, OnDestroy, OnInit } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { InventoryCountStatusModel } from 'src/models/inventory-count-status.model';
import { InventoryCountModel } from 'src/models/inventory-count.model';
import { PurchaseOrderLineModel } from 'src/models/purchase-order-line.model';
import { EmployeeService } from 'src/services/employee.service';
import { InventoryCountStatusService } from 'src/services/inventory-count-status.service';
import { InventoryCountService } from 'src/services/inventory-count.service';
import { UIService } from 'src/services/ui.service';

@Component({
  selector: 'app-add-edit-inventory-count-modal',
  templateUrl: './add-edit-inventory-count-modal.component.html',
  styleUrls: ['./add-edit-inventory-count-modal.component.css']
})

export class AddEditInventoryCountModalComponent implements OnInit, OnDestroy {
  public isNew: boolean = false;
  public id: number = -1;
  public seePrices: boolean = false;
  public showInactive: boolean = false;
  public model: InventoryCountModel;
  public itemInstanceId: number = -1;

  public lineList: PurchaseOrderLineModel[] = [];
  public selectedLines: PurchaseOrderLineModel[] = [];

  private statusSub: Subscription;
  public statuses: InventoryCountStatusModel[];

  private modelSub: Subscription;
  private saveSub: Subscription;

  constructor(
    public bsModalRef: BsModalRef,
    public countService: InventoryCountService,
    public countStatusService: InventoryCountStatusService,
    public uiService: UIService,
    public bsModalRefChildModal: BsModalRef,
    private modalService: BsModalService,
    public employeeService: EmployeeService,
  ) { };

  ngOnDestroy(): void {
    this.modelSub?.unsubscribe();

    this.statusSub?.unsubscribe();

    this.saveSub?.unsubscribe();
  }

  ngOnInit(): void {
    this.isNew = this.id === 0;

    this.getStatuses();

    this.employeeService.getEmployees(false);

    if (this.isNew) {
      this.model = this.countService.generateNewInventoryCount();
    } else {
      this.getModel();
    }
  }

  public getModel(): void {
    this.modelSub = this.countService.getCountById(this.id)
      .subscribe((x: InventoryCountModel) => {
        this.model = x;
      });
  }

  public getStatuses(): void {
    this.statusSub = this.countStatusService.inventoryCountStatusList$
      .subscribe((statuses: InventoryCountStatusModel[]) => {
        if (statuses) {
          this.statuses = statuses;
        }
      });
  }

  public onSave(): void {
    if (this.isNew) {
      this.saveSub = this.countService.createInventoryCount(this.model)
        .subscribe((x: boolean) => {
          if (x) {
            this.bsModalRef.hide();
            this.uiService.showSuccess('Count Saved', 'Success');
          } else {
            this.uiService.showError('Count Saved', 'Error');
          }
        });
    } else {
      this.saveSub = this.countService.editInventoryCount(this.model)
        .subscribe((x: boolean) => {
          if (x) {
            this.bsModalRef.hide();
            this.uiService.showSuccess('Count Saved', 'Success');
          } else {
            this.uiService.showError('Count Saved', 'Error');
          }
        });
    }
  }
}
