import { Component, OnDestroy, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { CancellationReasonModel } from 'src/models/cancellation-reason';
import { JobModel } from 'src/models/job.model';
import { JobStatusService } from 'src/services/job-status.service';
import { JobService } from 'src/services/job.service';
import { UIService } from 'src/services/ui.service';

@Component({
  selector: 'app-cancel-job-confirm-modal',
  templateUrl: './cancel-job-confirm-modal.component.html',
  styleUrls: ['./cancel-job-confirm-modal.component.css']
})

export class CancelJobConfirmComponent implements OnInit, OnDestroy {
  public deactivateSave: boolean = false;
  public model: JobModel;
  public showError: boolean = false;
  public showOtherBox: boolean = false;
  public statusCache: number = -1;
  public statusId: number = -1;

  public reasonsSub: Subscription;
  public reasons: CancellationReasonModel[] = [];

  public saveSub: Subscription;

  constructor(
    public bsModalRef: BsModalRef,
    public bsModalRefChildModal: BsModalRef,
    public jobStatusService: JobStatusService,
    public jobService: JobService,
    public uiService: UIService
  ) { };

  ngOnDestroy(): void {
    this.reasonsSub?.unsubscribe();

    this.saveSub?.unsubscribe();
  }

  ngOnInit(): void {
    this.statusCache = this.model.jobStatusId;

    this.getCancellationReasons();
  }

  public getCancellationReasons(): void {
    this.reasonsSub = this.jobStatusService.getCancellationReasons()
      .subscribe((reasons: CancellationReasonModel[]) => {
        this.reasons = reasons;
      });
  }

  public onChangeOfReason($event: CancellationReasonModel): void {
    if ($event !== undefined) {
      this.showError = false;
      this.model.cancellationReasonId = $event.cancellationReasonId;
      this.model.otherReason = null;
      this.showOtherBox = $event.requiresExplanation;
    }
  }

  public checkForOtherReason(): boolean {
    this.model.otherReason = this.model.otherReason && this.model.otherReason.trim() !== '' ? this.model.otherReason.trim() : null;

    return this.model.otherReason !== null;
  }

  public onSave(): void {
    this.deactivateSave = true;

    if (!this.showOtherBox || (this.showOtherBox && this.checkForOtherReason())) {
      this.model.jobStatusId = this.statusId;

      this.saveSub = this.jobService.addEditJob(
        this.model,
        false,
        true)
        .subscribe((x: boolean) => {
          if (x) {
            this.bsModalRef.hide();
            this.uiService.showSuccess('Job Canceled', 'Success');
          } else {
            this.deactivateSave = false;
            this.model.jobStatusId = this.statusCache;
            this.uiService.showError('Job Not Canceled', 'Error');
          }
        });
    } else {
      this.showError = true;
      this.deactivateSave = false;
    }
  }

  public onCancel(): void {
    this.model.cancellationReasonId = null;
    this.model.otherReason = null;

    this.bsModalRef.hide();
  }

}
