import { ItemInstanceModel } from './item-instance.model';
import { PurchaseOrderLineReceivedModel } from './purchase-order-line-received.model';

export class PurchaseOrderLineModel {
  polineId: number;
  poid: number;
  potypeId: number;
  potypeName: string;
  postatusId: number;
  postatusName: string;
  polineNumber: number;
  quantity: number;
  price: number;
  lineTotal: number;
  description: string;
  itemId: number;
  itemPartFamily: string;
  itemPartGroup: string;
  vendorInvoiceNumber: string;
  accountingDate: Date;
  isActive: boolean;
  receivedDate: Date;
  receivedQuantity: number | null;
  receivedPrice: number | null;
  newOrUsedStatusId: number | null;
  newOrUsedStatusName: string;
  storageLocationId: number | null;

  received: Array<PurchaseOrderLineReceivedModel>;
  itemInstances: ItemInstanceModel[];
  itemInstanceSerialNumbers: string[];
  poDate: Date;
  ponumberDisplay: string;
  vendor: string;
  maintenanceEventId: number | null;
  maintenanceEventItemInstanceId: number | null;
  maintenanceEventDisplay: string;
  dropDownDisplay: string;
}
