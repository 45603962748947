import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { JobService } from './job.service';
import { Observable, Observer } from 'rxjs';
import { tap } from 'rxjs/operators';
import { TimezoneService } from './timezone.service';
import { WorkLogModel } from 'src/models/work-log.model';
import { DayModel } from 'src/models/day.model';

@Injectable()
export class WorkLogService {
  constructor(
    private http: HttpClient,
    @Inject('BASE_URL') private baseUrl: string,
    private jobService: JobService,
    private timeZoneService: TimezoneService
  ) { }

  private apiUrl = 'api/WorkLog';  // URL to web api

  public getWorkLogsByDayId(
    dayId: number,
    showInactive: boolean): void {
    this.http.get<Array<WorkLogModel>>(this.baseUrl + this.apiUrl + '/GetWorkLogsByDayId/' + dayId + '/' + showInactive)
      .subscribe((x: WorkLogModel[]) => {

      });
  }

  public getWorkLogById(id: number): Observable<WorkLogModel> {
    return new Observable((observer: Observer<WorkLogModel>) => {
      this.http.get<WorkLogModel>(this.baseUrl + this.apiUrl + '/GetWorkLogById/' + id)
        .pipe(tap((x: WorkLogModel) => {
          x.workStart = x.workStart !== null && x.workStart !== undefined ? new Date(x.workStart) : null;
          x.workEnd = x.workEnd !== null && x.workEnd !== undefined ? new Date(x.workEnd) : null;
        }))
        .subscribe((x: WorkLogModel) => {
          observer.next(x);
        });
    });
  }

  public addEditWorkLog(
    dto: WorkLogModel,
    refreshJob: boolean = true): Observable<boolean> {
    return new Observable((observer: Observer<boolean>) => {
      this.http.post<number>(this.baseUrl + this.apiUrl + '/AddEditWorkLog', this.cleanWorkLogModel(dto))
        .subscribe((x: number) => {
          if (x !== -1) {
            if (refreshJob) {
              this.jobService.refreshJob(dto.jobId);
            }
          }
          observer.next(x !== -1);
        });
    });
  }

  public deleteWorkLog(
    dto: WorkLogModel,
    showInactive: boolean = false,
    refreshJob: boolean = true): Observable<boolean> {
    return new Observable((observer: Observer<boolean>) => {
      this.http.get<boolean>(this.baseUrl + this.apiUrl + '/DeleteWorkLog/' + dto.workLogId)
        .subscribe((x: boolean) => {
          if (x === true) {
            if (refreshJob) {
              this.jobService.refreshJob(dto.jobId);
            } else {
              this.getWorkLogsByDayId(dto.dayId, showInactive);
            }
          }
          observer.next(x);
        });
    });
  }

  private cleanWorkLogModel(model: WorkLogModel): WorkLogModel {
    model.employeeFullName = null;

    Object.keys(model).forEach((index: string) => {
      if (typeof model[index] === 'string') {
        model[index] = model[index]
          && model[index].trim() !== '' ?
          model[index].trim()
          : null;
      } else if (model[index] instanceof Date
        && !isNaN(model[index])) {
        model[index] = this.timeZoneService.correctOffset(model[index]);
      }
    });

    return model;
  }

  public generateWorkLogModel(
    id: number,
    dayId: number,
    jobOwnerId: number,
    jobDate: Date,
    lastTime: Date,
    isPacker: boolean): WorkLogModel {
    let model: WorkLogModel = new WorkLogModel();

    if (isPacker) {
      const dayLogs = this.jobService.job.value ? this.jobService.job.value.days.filter((x: DayModel) => x.dayId === dayId) : null;

      const nonMileageLogs = dayLogs != null
        && dayLogs.length > 0
        && dayLogs[0].workLogs != null
        && dayLogs[0].workLogs.length > 0
        ? dayLogs[0].workLogs.filter((x: WorkLogModel) => {
          return !x.mileage && x.employeeId === jobOwnerId;
        })
        : [];

      model.billingCodeId =
        nonMileageLogs.length > 0
          ? nonMileageLogs[0].billingCodeId
          : 624;
    }

    model.workLogId = id;
    model.dayId = dayId;
    model.workStart = lastTime ?
      this.timeZoneService.spliceTimeOntoDate(jobDate, lastTime)
      : this.timeZoneService.spliceTimeOntoDate(jobDate, this.timeZoneService.generateCurrentTimeToClosest15MinInterval());
    model.workEnd = lastTime ?
      this.timeZoneService.spliceTimeOntoDate(jobDate, lastTime)
      : this.timeZoneService.spliceTimeOntoDate(jobDate, this.timeZoneService.generateCurrentTimeToClosest15MinInterval());
    model.employeeId = jobOwnerId;
    model.isActive = true;

    return model;
  }

}
