export class PurchaseOrderLineReceivedModel {
  polineReceivedId: number = 0;
  polineId: number = 0;
  receivedDate: Date = null;
  receivedQuantity: number = null;
  receivedPrice: number = null;
  isActive: boolean = true;

  recDateString: string = null;
}
