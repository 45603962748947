import { WorksheetTypeModel } from './worksheet-type.model';

export class BillingCodeModel {       
  billingCodeId: number;
  itemId: number | null;
  itemDescription: string;
  itemPartNumber: string;
  itemIsActive: boolean;
  itemCost: number | null;
  partGroupId: number | null;
  partGroupName: string;
  partFamilyId: number | null;
  partFamilyName: string;
  customerId: number | null;
  customerName: string;
  name: string;
  uomId: number;
  uomName: string;
  quantity: number | null;
  rate: number | null;
  isTaxable: boolean | null;
  isSelectable: boolean | null;
  dayOneRental: boolean | null;
  additionalDayRental: boolean | null;
  isActive: boolean | null;

  worksheets: WorksheetTypeModel[];
  workSheetPopover: string;
  dropDownDisplay: string;
}
