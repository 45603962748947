export class ItemFilterParamsModel {
  partNumber: string = null;
  description: string = null;
  uomId: number = null;
  vendorId: number = null;
  partFamilyId: number = null;
  partGroupId: number = null;
  itemTypeId: number = null;
  lookbackInDays: number = 30;
}
