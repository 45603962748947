import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { State, process } from '@progress/kendo-data-query';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { WorksheetModalComponent } from 'src/app/worksheets/worksheet-modal/worksheet-modal.component';
import { JobSubStatuses } from 'src/enums/job-sub-statuses';
import { InvoiceItemModel } from 'src/models/invoice-item.model';
import { JobModel } from 'src/models/job.model';
import { AppStateService } from 'src/services/app-state.service';
import { InvoiceItemService } from 'src/services/invoice-item.service';
import { JobService } from 'src/services/job.service';
import { ReportService } from 'src/services/report.service';
import { RouterEventService } from 'src/services/router-event.service';
import { UIService } from 'src/services/ui.service';
import { WorksheetService } from 'src/services/worksheet.service';
import { InvoiceItemTypes } from '../../../../enums/invoice-item-types';
import { JobStatuses } from '../../../../enums/job-statuses';
import { JobTypes } from '../../../../enums/job-types';
import { UserService } from '../../../../services/user.service';
import { AddEditJobSalesModalComponent } from '../../shared/add-edit-job-sales-modal/add-edit-job-sales-modal.component';
import { CancelJobConfirmComponent } from '../../shared/cancel-job-confirm-modal/cancel-job-confirm-modal.component';

// //////////////FOR REORDER ROWS////////////////////////
const tableRow = node => node.tagName.toLowerCase() === 'tr';

const closest = (node, predicate) => {
  while (node && !predicate(node)) {
    node = node.parentNode;
  }

  return node;
};
// //////////////FOR REORDER ROWS////////////////////////

@Component({
  selector: 'app-jobs-sales-details',
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.css']
})

export class JobsSalesDetailsComponent implements OnInit, OnDestroy {
  constructor(
    private modalService: BsModalService,
    private activatedRoute: ActivatedRoute,
    public jobService: JobService,
    public uiService: UIService,
    public invoiceItemService: InvoiceItemService,
    public worksheetService: WorksheetService,
    public reportService: ReportService,
    public appStateService: AppStateService,
    public userService: UserService,
    private routerEventService: RouterEventService
  ) { };

  public model: JobModel;
  public bsModalRef: BsModalRef;
  public jobId: number = -1;
  public enableReorder: boolean = false;
  public editClosedInvoice: boolean = false;
  public infoIsCollapsed = false;
  public showDetails = true;
  public JobTypes = JobTypes;
  public JobStatuses = JobStatuses;
  public JobSubStatuses = JobSubStatuses;
  public InvoiceItemTypes = InvoiceItemTypes;
  public invoiceItems: InvoiceItemModel[] = [];
  public state: State = {};
  public gridData: any = process(this.invoiceItems, this.state);
  public returnUrl: string = '/';

  private isTabletSub: Subscription;
  public isTablet: boolean = false;

  private routeParamsSub: Subscription;
  private jobSub: Subscription;

  ngOnDestroy(): void {
    this.isTabletSub?.unsubscribe();

    this.routeParamsSub?.unsubscribe();

    this.jobSub?.unsubscribe();
  }

  ngOnInit(): void {
    this.returnUrl = this.routerEventService.getPreviousUrl();

    this.subscribeToRouteParams();

    this.determineIsTablet();
  }

  public determineIsTablet(): void {
    this.isTabletSub = this.appStateService.isTablet$
      .subscribe((isTablet: boolean) => {
        this.isTablet = isTablet;
      });
  }

  public subscribeToRouteParams(): void {
    this.jobService.job.next(null);

    this.routeParamsSub = this.activatedRoute.params
      .subscribe((x: Params) => {
        this.jobId = parseInt(this.activatedRoute.snapshot.params['id']);
        this.jobService.refreshJob(this.jobId);
        this.worksheetService.getWorksheetsCombinedByJobId(this.jobId, false);
        this.subscribeToJob();
      });
  }

  public openCancelJobConfirmModal(
    job: JobModel,
    cancellationStatusId: number): void {
    const initialState = {
      statusId: cancellationStatusId,
      model: job
    };

    this.bsModalRef = this.modalService.show(CancelJobConfirmComponent, { initialState, backdrop: 'static' });
  }

  public openJobSalesModal(jobId: number): void {
    const initialState = {
      id: jobId,
      jobTypeId: this.JobTypes.Sales
    };

    this.bsModalRef = this.modalService.show(AddEditJobSalesModalComponent, { initialState, class: 'modal-lg modal-xl', backdrop: 'static' });
  }

  public openWorksheetModal(worksheetId: number): void {
    const initialState = {
      id: worksheetId
    };

    this.bsModalRef = this.modalService.show(WorksheetModalComponent, { initialState, class: 'modal-xl', backdrop: 'static' });
  }

  public subscribeToJob(): void {
    this.jobSub = this.jobService.job
      .subscribe((x: JobModel) => {
        if (x !== null) {
          this.invoiceItems = x.invoiceItems;
          this.gridData = process(this.invoiceItems, this.state);
        }
      });
  }

  public handleEnableReorderEvent($event: boolean): void {
    this.enableReorder = $event;
  }

}
