<div class="page-header">
  <div class="page-header-content header-elements-md-inline">
    <div class="page-title d-flex">
      <a [routerLink]="['/home']"
        class="breadcrumb-link-color">
        <h4><i class="icon-arrow-left52 mr-2"></i> <span class="font-weight-semibold">Build Master</span> - Manage</h4>
      </a>
      <button type="button"
        class="btn btn-outline-dark btn-sm ml-3 pl-1 pr-1 pt-0 pb-0 no-border"
        [title]="!showFilter ? 'Show Filter' : 'Hide Filter'"
        (click)="showFilter = !showFilter;getNumberOfActiveFilterFields();">
        <span *ngIf="!showFilter">
          <i class="fa-regular fa-chevrons-down"></i>&nbsp;Show </span>
        <span *ngIf="showFilter"><i class="fa-regular fa-chevrons-up"></i>&nbsp;Hide
        </span>Filter<span class="text-warning"
          *ngIf="!showFilter && numberOfActiveFilterFields > 0"><strong>&nbsp;&nbsp;({{numberOfActiveFilterFields}}
            active
            criteria)</strong></span></button>
    </div>
    <div class="header-elements d-none py-0 mb-3 mb-md-0">
      <div class="breadcrumb">
        <a href="index.html"
          class="breadcrumb-item"><i class="icon-home2 mr-2"></i> Build Master</a>
        <span class="breadcrumb-item active">List</span>
      </div>
    </div>
  </div>
</div>
<div class="page-content pt-0">
  <div class="content-wrapper">
    <div class="content">
      <div class="row">
        <div class="col-md-12"
          *ngIf="showFilter">
          <div class="card">
            <div class="card-body p-2">
              <div class="row">
                <div class="col-md-3">
                  <div class="form-group mb-1">
                    <label class="col-form-label">Worksheet Id</label>
                    <div>
                      <input type="number"
                        class="form-control height-fix"
                        [(ngModel)]="worksheetService.packerFilterParams.worksheetId"
                        name="worksheetIdFilter"
                        (keyup.enter)="worksheetService.refreshPackerList(showOnJob, false);kGridService.pageChange({ skip: 0, take: kGridService.kGridParams.pageSize })"
                        (blur)="worksheetService.refreshPackerList(showOnJob, false);kGridService.pageChange({ skip: 0, take: kGridService.kGridParams.pageSize })">
                    </div>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="form-group mb-1">
                    <label class="col-form-label">Worksheet Type</label>
                    <div *ngIf="worksheetTypeService.worksheetTypeList | async; let types">
                      <ng-select [items]="types"
                        bindLabel="name"
                        bindValue="worksheetTypeId"
                        [(ngModel)]="worksheetService.packerFilterParams.worksheetTypeId"
                        (change)="worksheetService.refreshPackerList(showOnJob, false);kGridService.pageChange({ skip: 0, take: kGridService.kGridParams.pageSize })"
                        name="types">
                      </ng-select>
                    </div>
                    <div *ngIf="!worksheetTypeService.worksheetTypeList.value">
                      <ng-select name="types"
                        [readonly]="true">
                      </ng-select>
                    </div>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="form-group mb-1">
                    <label class="col-form-label">Worksheet Category</label>
                    <div *ngIf="worksheetCategoryService.worksheetCategoriesList | async; let cats">
                      <ng-select [items]="cats"
                        bindLabel="name"
                        bindValue="worksheetCategoryId"
                        [(ngModel)]="worksheetService.packerFilterParams.worksheetCategoryId"
                        (change)="worksheetService.refreshPackerList(showOnJob, false);kGridService.pageChange({ skip: 0, take: kGridService.kGridParams.pageSize })"
                        name="categories">
                      </ng-select>
                    </div>
                    <div *ngIf="!worksheetCategoryService.worksheetCategoriesList.value">
                      <ng-select name="categories"
                        [readonly]="true">
                      </ng-select>
                    </div>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="form-group mb-2">
                    <label class="col-form-label">Notes</label>
                    <div>
                      <input type="text"
                        class="form-control height-fix"
                        [(ngModel)]="worksheetService.packerFilterParams.notes"
                        name="worksheetIdNotes"
                        (keyup.enter)="worksheetService.refreshPackerList(showOnJob, false);kGridService.pageChange({ skip: 0, take: kGridService.kGridParams.pageSize })"
                        (blur)="worksheetService.refreshPackerList(showOnJob, false);kGridService.pageChange({ skip: 0, take: kGridService.kGridParams.pageSize })">
                    </div>
                  </div>
                </div>
              </div>
              <div class="d-flex justify-content-between row">
                <div class="col-md-9">
                  <div class="row">
                    <div class="col-md-4">
                      <div class="form-group mb-1">
                        <label class="col-form-label">Customer</label>
                        <div *ngIf="customerService.customerList | async; let cust">
                          <ng-select [items]="cust"
                            bindLabel="name"
                            bindValue="customerId"
                            [(ngModel)]="worksheetService.packerFilterParams.customerId"
                            (change)="worksheetService.refreshPackerList(showOnJob, false);kGridService.pageChange({ skip: 0, take: kGridService.kGridParams.pageSize })"
                            name="customers">
                          </ng-select>
                        </div>
                        <div *ngIf="!customerService.customerList.value">
                          <ng-select name="employees"
                            [readonly]="true">
                          </ng-select>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group mb-1">
                        <label class="col-form-label">Builder</label>
                        <div *ngIf="employeeService.employeeList | async; let emp">
                          <ng-select [items]="emp"
                            bindLabel="fullName"
                            bindValue="employeeId"
                            [(ngModel)]="worksheetService.packerFilterParams.employeeId"
                            (change)="worksheetService.refreshPackerList(showOnJob, false);kGridService.pageChange({ skip: 0, take: kGridService.kGridParams.pageSize })"
                            name="employees">
                          </ng-select>
                        </div>
                        <div *ngIf="!employeeService.employeeList.value">
                          <ng-select name="employees"
                            [readonly]="true">
                          </ng-select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <button type="submit"
                  class="btn btn-sm btn-light mr-2 clear-button-placement"
                  (click)="worksheetService.clearFilterParams(showOnJob, true);kGridService.pageChange({ skip: 0, take: kGridService.kGridParams.pageSize })"><i
                    class="far fa-times-circle"></i>&nbsp;Clear</button>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-12">
          <app-packer-table [showExtendedControls]="true"
            (updatedShowOnJobEvent)="handleOnJobChange($event)"></app-packer-table>
        </div>
      </div>
    </div>
  </div>
</div>
