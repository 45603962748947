<div class="page-content">
  <!-- Main content -->
  <div class="content-wrapper">
    <!-- Content area -->
    <div class="content d-flex justify-content-center align-items-center">
      <!-- Login form -->
      <form class="login-form"
        action="index.html">
        <div class="card mb-0">
          <div class="card-body">
            <div class="text-center mb-3">
              <img src="/images/grizzly tacklebox FINAL LOGO-01_resize.png"
                style="width: 250px;"
                alt="TackleBox" />
              <br /><br />
              <span class="d-block text-muted">Enter your credentials below</span>
            </div>
            <div *ngIf="errorMessage"
              class="alert alert-danger text-center"
              style="padding: 0.5rem;">{{errorMessage}}</div>
            <div class="form-group form-group-feedback form-group-feedback-left">
              <input type="text"
                class="form-control"
                placeholder="Username"
                [(ngModel)]="userModel.userName"
                name="username"
                autocomplete="off">
              <div class="form-control-feedback">
                <i class="icon-user text-muted"></i>
              </div>
            </div>
            <div class="form-group form-group-feedback form-group-feedback-left">
              <input type="password"
                class="form-control"
                placeholder="Password"
                [(ngModel)]="userModel.password"
                name="password"
                autocomplete="off">
              <div class="form-control-feedback">
                <i class="icon-lock2 text-muted"></i>
              </div>
            </div>
            <div class="form-group">
              <button type="submit"
                class="btn btn-primary btn-block"
                (click)="login()">Sign in <i class="icon-circle-right2 ml-2"></i></button>
            </div>
          </div>
        </div>
      </form>
      <!-- /login form -->
    </div>
    <!-- /content area -->
  </div>
  <!-- /main content -->
</div>
