<div class="page-header">
  <div class="page-header-content header-elements-md-inline">
    <div class="page-title d-flex">
      <a [routerLink]="['/customers/list']"
        class="breadcrumb-link-color">
        <h4><i class="icon-arrow-left52 mr-2"></i> <span class="font-weight-semibold">Customer</span> - Details</h4>
      </a>
      <button type="button"
        class="btn btn-outline-dark btn-sm ml-3 pl-1 pr-1 pt-0 pb-0 no-border"
        [title]="!showDetails ? 'Show Details' : 'Hide Details'"
        (click)="showDetails = !showDetails">
        <span *ngIf="!showDetails">
          <i class="fa-regular fa-chevrons-right"></i>&nbsp;Show </span>
        <span *ngIf="showDetails"><i class="fa-regular fa-chevrons-left"></i>&nbsp;Hide
        </span>Details</button>
    </div>
    <div class="header-elements d-none py-0 mb-3 mb-md-0">
      <div class="breadcrumb">
        <a href="index.html"
          class="breadcrumb-item"><i class="icon-home2 mr-2"></i> Customer</a>
        <span class="breadcrumb-item active">Detail</span>
      </div>
    </div>
  </div>
</div>
<div class="page-content pt-0"
  *ngIf="customerService.customer | async; let model">
  <div class="content-wrapper">
    <div class="content">
      <div class="row">
        <div class="col-md-3"
          *ngIf="showDetails">
          <div class="card">
            <div class="card-header bg-light header-elements-inline"
              [ngClass]="{'pr-2 pl-2':isTablet}">
              <h6 class="card-title">{{model.name}}</h6>
              <div class="header-elements">
                <button class="btn btn-light btn-sm"
                  (click)="openCustomerModal(model.customerId)"><i class="fa fa-edit"></i><span *ngIf="!isTablet">
                    Edit</span></button>
              </div>
            </div>
            <div class="card-body"
              [ngClass]="{'pr-2 pl-2':isTablet}">
              <dl class="row">
                <dt class="col-sm-4 text-right"
                  [ngClass]="{'pr-0': isTablet}">Customer</dt>
                <dd class="col-sm-8">{{model.name}}</dd>
                <dt class="col-sm-4 text-right"
                  [ngClass]="{'pr-0': isTablet}"
                  *ngIf="model.abbreviatedName">Abbreviated Name</dt>
                <dd class="col-sm-8"
                  *ngIf="model.abbreviatedName">{{model.abbreviatedName}}</dd>
                <dt class="col-sm-4 text-right"
                  [ngClass]="{'pr-0': isTablet}">Billing Address</dt>
                <dd class="col-sm-8">
                  <ul *ngIf="model.billingAddress"
                    class="address-ul">
                    <li *ngIf="model.billingAddress.streetAddressLine1">
                      {{model.billingAddress.streetAddressLine1}}
                    </li>
                    <li *ngIf="model.billingAddress.streetAddressLine2">
                      {{model.billingAddress.streetAddressLine2}}
                    </li>
                    <li>
                      {{model.billingAddress.city}}<span
                        *ngIf="model.billingAddress.stateCode !== null">,&nbsp;</span>{{model.billingAddress.stateCode}}<span
                        *ngIf="model.billingAddress.zip !== null">&nbsp;</span>{{model.billingAddress.zip}}
                    </li>
                  </ul>
                </dd>
                <dt class="col-sm-4 text-right"
                  [ngClass]="{'pr-0': isTablet}">Payment Terms</dt>
                <dd class="col-sm-8">{{model.paymentTermName}}</dd>
                <dt class="col-sm-4 text-right"
                  [ngClass]="{'pr-0': isTablet}"
                  *ngIf="model.dailyInvoiceMax">Daily Invoice Max.</dt>
                <dd class="col-sm-8"
                  *ngIf="model.dailyInvoiceMax">{{model.dailyInvoiceMax | currency}}</dd>
              </dl>
            </div>
          </div>
        </div>
        <div [ngClass]="{'col-md-9': showDetails, 'col-md-12': !showDetails}">
          <tabset class="compact-nav">
            <tab heading="Addresses">
              <app-address-list [type]="AddressTypes.Customer"></app-address-list>
            </tab>
            <tab heading="Contacts">
              <app-contact-list [type]="ContactTypes.Customer"></app-contact-list>
            </tab>
            <tab heading="Wells">
              <app-wells-sm-list [type]="AddressTypes.Customer"></app-wells-sm-list>
            </tab>
            <tab heading="Rigs">
              <app-rigs-sm-list [type]="AddressTypes.Customer"></app-rigs-sm-list>
            </tab>
            <tab heading="Areas">
              <app-customer-area-list [customerId]="customerId"></app-customer-area-list>
            </tab>
          </tabset>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
        </div>
        <div class="col-md-6">
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
        </div>
        <div class="col-md-6">
        </div>
      </div>
    </div>
  </div>
</div>
<div *ngIf="!customerService.customer.value"
  class="page-content pt-0">
  <div class="content-wrapper">
    <div class="content">
      <div class="row">
        <div class="col-lg-3">
          <div class="card">
            <div class="card-header bg-light header-elements-inline">
              <h6 class="card-title">Customer</h6>
              <div class="header-elements">
                <button class="btn btn-light btn-sm"
                  [disabled]="true"><i class="fa fa-edit"></i> Edit</button>
              </div>
            </div>
            <div class="card-body">
              <app-list-skeleton [numberToIterate]="3"></app-list-skeleton>
            </div>
          </div>
        </div>
        <div class="col-lg-9">
          <tabset class="compact-nav">
            <tab heading="Addresses"
              [disabled]="true">
              <div class="card border-top-primary border-top-2 rounded-top-0"
                style="border-top-color: #2196F3 !important;">
                <div class="card-header bg-light header-elements-inline"
                  style="height: 70px;">
                  <h6 class="card-title">Addresses</h6>
                </div>
                <div class="card-body">
                  <app-table-skeleton [numberToIterate]="3"></app-table-skeleton>
                </div>
              </div>
            </tab>
            <tab heading="Contacts"
              [disabled]="true">
            </tab>
            <tab heading="Wells"
              [disabled]="true">
            </tab>
            <tab heading="Rigs"
              [disabled]="true">
            </tab>
            <tab heading="Areas"
              [disabled]="true">
            </tab>
          </tabset>
        </div>
      </div>
    </div>
  </div>
</div>
