import { WorksheetRowTypeModel } from './worksheet-row-type.model';

export class WorksheetTypeModel {
  worksheetTypeId: number;
  name: string;
  sortOrder: number | null;
  billingCodeId: number | null;
  billingCodeName: string;
  isItemized: boolean | null;
  showIsCustomerProperty: boolean | null;
  isRebuild: boolean | null;
  isPacker: boolean;
  isActive: boolean | null;
  worksheetCategoryId: number;
  worksheetCategoryName: string;
  hasImage: boolean;
  itemId: number | null;
  itemDescription: string;

  worksheetRowTypes: WorksheetRowTypeModel[];
}
