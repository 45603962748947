import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ExcelExportData } from '@progress/kendo-angular-excel-export';
import { RowClassArgs } from '@progress/kendo-angular-grid';
import { process } from '@progress/kendo-data-query';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { SerializedLocationModel } from 'src/models/serialized-location.model';
import { AppStateService } from 'src/services/app-state.service';
import { ItemInstanceService } from 'src/services/item-instance.service';
import { ItemService } from 'src/services/item.service';
import { KGridService } from 'src/services/k-grid.service';
import { UIService } from 'src/services/ui.service';

@Component({
  selector: 'app-serialized-location-list',
  templateUrl: './list.component.html',
  styleUrls: ['list.component.css']
})

export class SerializedLocationListComponent implements OnInit, OnDestroy {
  public onJobOnly: boolean = false;
  public showFilter = true;
  public numberOfActiveFilterFields: number = 0;

  private isTabletSub: Subscription;
  public isTablet: boolean = false;

  constructor(
    private modalService: BsModalService,
    public itemService: ItemService,
    public itemInstanceService: ItemInstanceService,
    public kGridService: KGridService,
    public uiService: UIService,
    public router: Router,
    public activatedRoute: ActivatedRoute,
    public appStateService: AppStateService
  ) {
    this.allData = this.allData.bind(this);
  };

  public bsModalRef: BsModalRef;

  ngOnDestroy(): void {
    this.kGridService.resetKGridParams();

    this.isTabletSub?.unsubscribe();
  }

  ngOnInit(): void {
    this.itemInstanceService.getSerializedLocationList(
      this.onJobOnly,
      false,
      true);

    this.itemService.refreshSerializedItemList();

    this.determineIsTablet();
  }

  public determineIsTablet(): void {
    this.isTabletSub = this.appStateService.isTablet$
      .subscribe((isTablet: boolean) => {
        this.isTablet = isTablet;
      });
  }

  public onChangeOnJobOnly($event: boolean): void {
    this.onJobOnly = $event;

    this.itemInstanceService.getSerializedLocationList(
      this.onJobOnly,
      false,
      false);
  }

  public allData(): ExcelExportData {
    let all = this.itemInstanceService.serializedLocationList.value;

    const result: ExcelExportData = {
      data: process(all, { group: null, sort: null }).data,
      group: null
    };

    return result;
  }

  public goToJob(ii: SerializedLocationModel) {
    let link: string = `/jobs/${ii.jobType}/details/${ii.jobId}`;
    this.router.navigate([link], { relativeTo: this.activatedRoute.parent });
  }

  public rowCallback(context: RowClassArgs): any {
    return {
      onJob: context.dataItem.jobId,
    };
  }

  public getNumberOfActiveFilterFields() {
    if (!this.showFilter) {
      this.numberOfActiveFilterFields = this.itemInstanceService.getNumberOfActiveFilterFields();
    } else {
      this.numberOfActiveFilterFields = 0;
    }
  }

}
