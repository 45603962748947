<div class="card border-top-primary border-top-2 rounded-top-0"
  style="border-top-color: #2196F3 !important;">
  <div class="card-header bg-light header-elements-sm-inline">
    <h6 class="card-title">Customer Areas</h6>
    <div class="header-elements">
      <div class="switch-container pt-2 mr-2">
        <span class="switch-label">Show Inactive </span>
        <ui-switch [ngModel]="showInactive"
          (change)="onChangeShowInactive($event)"></ui-switch>
      </div>
      <button class="btn btn-light btn-sm"
        (click)="openCustomerAreaModal(0)">
        <i class="fa fa-plus"></i> New Customer Area
      </button>
    </div>
  </div>
  <div class="table-responsive"
    *ngIf="customerAreaService.customerAreaList | async; let model">
    <div class="table-responsive"
      *ngIf="model.length === 0">
      <div class="alert alert-primary ml-3 mr-3 mt-3"
        role="alert">
        <i class="fad fa-check"></i>&nbsp;&nbsp;There are currently no <strong>Customer Areas.</strong>
      </div>
    </div>
    <kendo-grid *ngIf="model.length > 0"
      [kendoGridBinding]="model"
      filterable="menu"
      [sortable]="true"
      [resizable]="true"
      [pageSize]="kGridParams.pageSize"
      [skip]="kGridParams.skip"
      (pageChange)="pageChange($event)"
      [pageable]="{
        buttonCount: kGridParams.buttonCount,
        info: kGridParams.info,
        type: kGridParams.type,
        pageSizes: kGridParams.pageSizes,
        previousNext: kGridParams.previousNext
      }">
      <kendo-grid-column field="one"
        title=""
        [width]="60"
        filterable="false">
        <ng-template kendoGridCellTemplate
          let-dataItem
          let-rowIndex="rowIndex">
          <button class="btn btn-xs btn-light"
            (click)="openCustomerAreaModal(dataItem.customerAreaId)"
            [disabled]="dataItem.isActive === false"><i class="fa fa-edit"></i></button>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column field="name"
        title="Area">
      </kendo-grid-column>
      <kendo-grid-column field="one"
        title="Contact"
        [width]="200">
        <ng-template kendoGridCellTemplate
          let-dataItem
          let-rowIndex="rowIndex">
          <span *ngIf="dataItem && dataItem.contact">{{ dataItem.contact.fullName }}</span>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column field="two"
        title="Phone"
        [width]="200">
        <ng-template kendoGridCellTemplate
          let-dataItem
          let-rowIndex="rowIndex">
          <span *ngIf="dataItem && dataItem.contact">{{ dataItem.contact.phone }}</span>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column field="three"
        title="Email"
        [width]="240">
        <ng-template kendoGridCellTemplate
          let-dataItem
          let-rowIndex="rowIndex">
          <span *ngIf="dataItem && dataItem.contact">{{ dataItem.contact.email }}</span>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column field="three"
        title="Notes"
        [width]="60">
        <ng-template kendoGridCellTemplate
          let-dataItem
          let-rowIndex="rowIndex">
          <span *ngIf="dataItem && dataItem.notes"
            class="pointer"><i class="far fa-sticky-note text-primary"
              type="button"
              [popover]="popoverTemplateNotes"
              popoverTitle="Notes"
              placement="auto"
              [outsideClick]="true"
              container="body"></i></span>
          <ng-template #popoverTemplateNotes>
            <div>
              {{dataItem.notes}}
            </div>
          </ng-template>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column field="isActive"
        title="Active"
        [width]="80"
        filterable="false">
        <ng-template kendoGridCellTemplate
          let-dataItem
          let-rowIndex="rowIndex">
          <span [ngClass]="{ 'text-danger' : !dataItem.isActive }">{{dataItem.isActive ? 'Yes' : 'No'}}</span>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column field="one"
        title=""
        [width]="60"
        filterable="false">
        <ng-template kendoGridCellTemplate
          let-dataItem
          let-rowIndex="rowIndex">
          <button class="btn btn-xs btn-light"
            [swal]="{ title: 'Delete Area?', text: dataItem.name, showCancelButton: true, confirmButtonText: 'Delete', confirmButtonColor: '#DC3545' }"
            (confirm)="deleteCustomerArea(dataItem)"
            [disabled]="dataItem.isActive === false">
            <i class="fa fa-trash"></i>
          </button>
        </ng-template>
      </kendo-grid-column>
      <div *kendoGridDetailTemplate="let dataItem">
        <kendo-grid *ngIf="dataItem.counties && dataItem.counties.length > 0"
          [kendoGridBinding]="dataItem.counties"
          filterable="false"
          [sortable]="false"
          [resizable]="false"
          [pageable]="false">
          <kendo-grid-column field="name"
            title="County">
          </kendo-grid-column>
        </kendo-grid>
      </div>
    </kendo-grid>
  </div>
</div>
