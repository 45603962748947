import { Component, NgZone, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { RowClassArgs } from '@progress/kendo-angular-grid';
import { State, process } from '@progress/kendo-data-query';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subscription, fromEvent } from 'rxjs';
import { take, tap } from 'rxjs/operators';
import { JobSubStatuses } from 'src/enums/job-sub-statuses';
import { JobItemInstanceModel } from 'src/models/job-item-instance.model';
import { JobModel } from 'src/models/job.model';
import { AppStateService } from 'src/services/app-state.service';
import { BhaLineService } from 'src/services/bha-line.service';
import { BhaService } from 'src/services/bha.service';
import { DayService } from 'src/services/day.service';
import { FileService } from 'src/services/file.service';
import { InvoiceItemService } from 'src/services/invoice-item.service';
import { JobItemInstanceService } from 'src/services/job-item-instance.service';
import { JobService } from 'src/services/job.service';
import { ReportService } from 'src/services/report.service';
import { RouterEventService } from 'src/services/router-event.service';
import { UIService } from 'src/services/ui.service';
import { WorkLogService } from 'src/services/work-log.service';
import { WorksheetService } from 'src/services/worksheet.service';
import { JobFishingItemModalTypes } from '../../../../enums/job-fishing-item-modal-types';
import { JobStatuses } from '../../../../enums/job-statuses';
import { JobTypes } from '../../../../enums/job-types';
import { InvoiceItemReorderModel } from '../../../../models/RequestParams/invoice-item-reorder.model';
import { ItemInstanceService } from '../../../../services/item-instance.service';
import { UserService } from '../../../../services/user.service';
import { AddEditJobFishingItemInstanceModalComponent }
  from '../../fishing/add-edit-job-fishing-item-instance-modal/add-edit-job-fishing-item-instance-modal.component';
import { AddEditJobFishingItemModalComponent } from '../../fishing/add-edit-job-fishing-item-modal/add-edit-job-fishing-item-modal.component';
import { AddEditJobSalesItemNoteModalComponent } from '../../sales/add-edit-job-sales-item-note-modal/add-edit-job-sales-item-note-modal.component';
import { AddEditJobSalesModalComponent } from '../../shared/add-edit-job-sales-modal/add-edit-job-sales-modal.component';
import { CancelJobConfirmComponent } from '../../shared/cancel-job-confirm-modal/cancel-job-confirm-modal.component';
import { CopyJobModalComponent } from '../../shared/copy-job-modal/copy-job-modal.component';
import { MobileReorderToolsModalComponent } from '../../shared/mobile-re-order-tools-modal/mobile-re-order-tools-modal.component';

// //////////////FOR REORDER ROWS////////////////////////
const tableRow = node => node.tagName.toLowerCase() === 'tr';

const closest = (node, predicate) => {
  while (node && !predicate(node)) {
    node = node.parentNode;
  }

  return node;
};
// //////////////FOR REORDER ROWS////////////////////////

@Component({
  selector: 'app-jobs-fishing-tool-rental-details',
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.css']
})

export class JobsFishingToolRentalDetailsComponent implements OnInit, OnDestroy {
  constructor(
    private modalService: BsModalService,
    private activatedRoute: ActivatedRoute,
    public jobService: JobService,
    public dayService: DayService,
    public uiService: UIService,
    public invoiceItemService: InvoiceItemService,
    public worksheetService: WorksheetService,
    public workLogService: WorkLogService,
    public jiiService: JobItemInstanceService,
    public iiService: ItemInstanceService,
    public bhaService: BhaService,
    public bhaLineService: BhaLineService,
    public fileService: FileService,
    public userService: UserService,
    public appStateService: AppStateService,
    private renderer: Renderer2,
    private zone: NgZone,
    public reportService: ReportService,
    private routerEventService: RouterEventService
  ) { };

  public model: JobModel;
  public bsModalRef: BsModalRef;
  public jobId: number = -1;
  public enableReorder: boolean = false;
  public enableReorderToolList: boolean = false;
  public showDatePicker: boolean = false;
  public activeTabId: string = 'dayTab_0';
  public activeBhaTabId: string = 'bhaTab_0';
  public timeStamp: string = new Date().toISOString();
  public disableReleaseAll: boolean = false;
  public showDetails = true;
  public tempDate: Date = new Date();
  public tools: JobItemInstanceModel[] = [];
  private currentSubscription: Subscription;
  public state: State = {};
  public toolsGridData: any = process(this.tools, this.state);
  public JobTypes = JobTypes;
  public JobStatuses = JobStatuses;
  public JobSubStatuses = JobSubStatuses;
  public JobFishingItemModalTypes = JobFishingItemModalTypes;
  public infoIsCollapsed = false;
  public returnUrl: string = '/';

  private isTabletSub: Subscription;
  public isTablet: boolean = false;

  private batchReorderSub: Subscription;
  private routeParamsSub: Subscription;
  private splitInvoiceSub: Subscription;
  private deleteJiiSub: Subscription;
  private releaseAllSub: Subscription;
  private releaseOneSub: Subscription;
  private jobStatusChangeSub: Subscription;
  private jobSub: Subscription;
  private saveJiiSub: Subscription;
  private readyToInvoiceSub: Subscription;

  ngOnDestroy(): void {
    this.fileService.refreshBhaDiagram.next(false);

    this.batchReorderSub?.unsubscribe();

    this.currentSubscription?.unsubscribe();

    this.deleteJiiSub?.unsubscribe();

    this.jobStatusChangeSub?.unsubscribe();

    this.jobSub?.unsubscribe();

    this.routeParamsSub?.unsubscribe();

    this.saveJiiSub?.unsubscribe();

    this.splitInvoiceSub?.unsubscribe();

    this.isTabletSub?.unsubscribe();

    this.readyToInvoiceSub?.unsubscribe();

    this.releaseAllSub?.unsubscribe();

    this.releaseOneSub?.unsubscribe();
  }

  ngOnInit(): void {
    this.returnUrl = this.routerEventService.getPreviousUrl();
    this.subscribeToRouteParams();
    this.determineIsTablet();
  }

  public determineIsTablet(): void {
    this.isTabletSub = this.appStateService.isTablet$
      .subscribe((isTablet: boolean) => {
        this.isTablet = isTablet;
      });
  }

  public subscribeToRouteParams(): void {
    this.jobService.job.next(null);

    this.routeParamsSub = this.activatedRoute.params
      .subscribe((x: Params) => {
        this.jobId = parseInt(this.activatedRoute.snapshot.params['id']);
        this.jobService.refreshJob(this.jobId);
        this.subscribeToJob();
      });
  }

  public splitInvoice(): void {
    let jobId = this.jobService.job != null ? this.jobService.job.value.jobId : null;

    if (jobId !== null) {
      this.splitInvoiceSub = this.invoiceItemService.generateFishingToolRentalInvoice(
        jobId,
        true)
        .subscribe((x: boolean) => {
          if (x === true) {
            this.changeJobStatus(13, true);
          } else {
            this.uiService.showError('Invoice NOT Split', 'Error');
          }
        });
    }
  }

  public openCancelJobConfirmModal(
    job: JobModel,
    cancellationStatusId: number): void {
    const initialState = {
      statusId: cancellationStatusId,
      model: job
    };

    this.bsModalRef = this.modalService.show(CancelJobConfirmComponent, { initialState, backdrop: 'static' });
  }

  public openCopyJobModal(): void {
    let job = this.jobService.job != null ? this.jobService.job.value : null;

    const initialState = {
      job: job
    };

    this.bsModalRef = this.modalService.show(CopyJobModalComponent, { initialState, backdrop: 'static' });
  }

  public openJobFishingModal(jobId: number): void {
    const initialState = {
      id: jobId,
      jobTypeId: this.JobTypes.FishingToolRental
    };

    this.bsModalRef = this.modalService.show(AddEditJobSalesModalComponent, { initialState, class: 'modal-lg modal-xl', backdrop: 'static' });
  }

  public formatDateAsString(date: Date): string {
    var m_names = new Array('January', 'February', 'March',
      'April', 'May', 'June', 'July', 'August', 'September',
      'October', 'November', 'December');

    var d = new Date(date);
    var curr_date = d.getDate();
    var curr_month = d.getMonth();
    var curr_year = d.getFullYear();

    return m_names[curr_month] + ' ' + curr_date + ', ' + curr_year;
  }

  public deleteJobItemInstance(dto: JobItemInstanceModel): void {
    this.deleteJiiSub = this.jiiService.deleteJobItem(dto)
      .subscribe((x: boolean) => {
        if (x) {
          this.uiService.showSuccess('Tool Deleted', 'Success');
        } else {
          this.uiService.showError('Tool Not Deleted', 'Error');
        }
      });
  }

  public openJobFishingItemModal(
    jobId: number,
    invoiceItemId: number,
    isFishingRental: boolean): void {
    const initialState = {
      jobId: jobId,
      invoiceItemId: invoiceItemId,
      isFishingRental: isFishingRental
    };

    this.bsModalRef = this.modalService.show(AddEditJobFishingItemModalComponent, { initialState, backdrop: 'static' });
  }

  public openJobFishingItemInstanceModal(
    jiiId: number,
    jobId: number,
    jobTypeId: number,
    type: number): void {
    const initialState = {
      id: jiiId,
      jobId: jobId,
      jobTypeId: jobTypeId,
      type: type
    };

    this.bsModalRef = this.modalService.show(AddEditJobFishingItemInstanceModalComponent, { initialState, backdrop: 'static' });
  }

  public openJobFishingItemNoteModal(
    jobId: number,
    invoiceItemId: number): void {
    const initialState = {
      jobId: jobId,
      invoiceItemId: invoiceItemId
    };

    this.bsModalRef = this.modalService.show(AddEditJobSalesItemNoteModalComponent, { initialState, backdrop: 'static' });
  }

  public toggleDatePicker(bool: boolean): void {
    this.showDatePicker = bool;
  }

  public openReorderToolsModal(): void {
    const initialState = {
      jobId: this.jobId,
      rows: this.tools
    };

    this.bsModalRef = this.modalService.show(MobileReorderToolsModalComponent, { initialState, class: 'modal-xl', backdrop: 'static' });
  }

  public releaseAllFromJob(): void {
    this.releaseAllSub = this.iiService.closeOutItemInstances(this.jobId)
      .subscribe((x: boolean) => {
        if (x === true) {
          this.uiService.showSuccess('Serialized Items Released', 'Success');
        } else {
          this.uiService.showError('Serialized Item NOT Released', 'Error');
        }
      });
  }

  public toggleReleaseFromJob(jiiId: number): void {
    let jii: JobItemInstanceModel[] = this.jobService.job.value.jobItemInstances != null ?
      this.jobService.job.value.jobItemInstances.filter((x: JobItemInstanceModel) => {
        return x.jobItemInstanceId == jiiId;
      }) : null;

    if (jii.length > 0) {
      jii[0].releasedFromJob = jii[0].releasedFromJob !== null && jii[0].releasedFromJob !== undefined ? !jii[0].releasedFromJob : false;

      this.releaseOneSub = this.jiiService.addEditJobItemInstance(jii[0])
        .subscribe((x: boolean) => {
          if (x === true) {
            if (jii[0].releasedFromJob === true) {
              this.uiService.showSuccess('Serialized Item Released', 'Success');
            } else {
              this.uiService.showSuccess('Serialized Item Returned', 'Success');
            }
          } else {
            if (jii[0].releasedFromJob === true) {
              this.uiService.showError('Serialized Item NOT Released', 'Error');
            } else {
              this.uiService.showError('Serialized Item NOT Returned', 'Error');
            }
          }
        });
    }

  }

  public changeJobStatus(
    statusId: number,
    split: boolean = false): void {
    if (this.jobService.job.value !== null) {
      this.jobService.job.value.jobStatusId = statusId;

      this.jobStatusChangeSub = this.jobService.addEditJob(this.jobService.job.value, false, true)
        .subscribe((x: boolean) => {
          if (x === true) {
            if (!split) {
              this.uiService.showSuccess('Job Status Changed', 'Success');
            } else {
              this.uiService.showSuccess('Invoice Split & Job Status Changed', 'Success');
            }
          } else {
            this.uiService.showError('Job Status Not Changed', 'Error');
          }
        });
    } else {
      this.uiService.showError('Job Status Not Changed', 'Error');
    }
  }

  public subscribeToJob(): void {
    this.jobSub = this.jobService.job
      .subscribe((x: JobModel) => {
        if (x !== null) {
          let serializedUnreleased = x.jobItemInstances.filter((x: JobItemInstanceModel) => {
            return x.itemInstanceId !== undefined
              && x.itemInstanceId !== null
              && x.releasedFromJob === false
              && x.sold === false;
          });

          this.disableReleaseAll = serializedUnreleased.length === 0;

          let getTools: JobItemInstanceModel[] = x.jobItemInstances !== undefined && x.jobItemInstances !== undefined ? x.jobItemInstances : [];

          this.tools = getTools;

          this.toolsGridData = process(getTools, this.state);
        }
      });
  }

  public rowCallback(context: RowClassArgs): any {
    const isWorksheet: boolean = (context.dataItem.worksheetId !== null && context.dataItem.worksheetId !== undefined)
      || (context.dataItem.parentInvoiceItemId !== null && context.dataItem.parentInvoiceItemId !== undefined);

    const hideWorksheetRow: boolean = context.dataItem.hideForReorder !== undefined
      && context.dataItem.hideForReorder !== null
      && context.dataItem.hideForReorder === true;

    return {
      worksheet: isWorksheet,
      dragging: context.dataItem.dragging,
      hideForReorder: hideWorksheetRow,
    };
  }

  public rowCallback_2(context: RowClassArgs): any {
    return {
      sold: context.dataItem.sold && context.dataItem.removeFromInventory,
      dragging: context.dataItem.dragging
    };
  }

  public setTimeStamp(): void {
    this.timeStamp = new Date().toISOString();
  }

  public saveJobItemInstance(jii: JobItemInstanceModel): void {
    if (jii.sold !== true) {
      jii.price = null;
    }

    this.saveJiiSub = this.jiiService.addEditJobItemInstance(jii)
      .subscribe((x: boolean) => {
        if (x) {
          this.uiService.showSuccess('Item Saved', 'Success');
        } else {
          this.uiService.showError('Item Not Saved', 'Error');
        }
      });
  }

  public toggleReadyToInvoice(jobId: number): void {
    if (this.jobService.job.value !== null) {
      this.jobService.job.value.notReadyToInvoice = !this.jobService.job.value.notReadyToInvoice;

      this.readyToInvoiceSub = this.jobService.toggleReadyToInvoice(jobId)
        .subscribe((x: boolean) => {
          if (x === true) {
            this.uiService.showSuccess('Ready To Invoice Status Changed', 'Success');
          } else {
            this.uiService.showError('Ready To Invoice Status Not Changed', 'Error');
          }
        });
    } else {
      this.uiService.showError('Job Status Not Changed', 'Error');
    }
  }

  public handleEnableReorderEvent($event: boolean): void {
    this.enableReorder = $event;
  }

  // /////////////REORDER ROWS//////////////////////
  public dataStateChange(state: State): void {
    this.state = state;
    this.toolsGridData = process(this.tools, this.state);
    this.currentSubscription.unsubscribe();
    this.zone.onStable.pipe(take(1))
      .subscribe(() => this.currentSubscription = this.handleDragAndDrop());
  }

  public switchEnableReorder($event: boolean): void {
    if ($event !== undefined) {
      this.enableReorderToolList = $event;

      if (this.enableReorderToolList === true) {
        // hide Rebuild detail lines
        this.toolsGridData.data.forEach((x: any) => {
          if (x.invoiceItemId === 0) {
            x.hideForReorder = true;
          }
        });

        this.currentSubscription = this.handleDragAndDrop();
      } else {
        // unhide of Rebuild detail lines happens in the batchReorder function
        this.currentSubscription.unsubscribe();
        this.batchReorder();
      }
    }
  }

  public batchReorder(): void {
    let batchReorder: number[][] = [];
    let counter: number = 0;
    for (let i = 0; i < this.toolsGridData.data.length; i++) {
      let temp = [];
      if (this.toolsGridData.data[i].jobItemInstanceId !== undefined
        && this.toolsGridData.data[i].jobItemInstanceId !== null
        && this.toolsGridData.data[i].jobItemInstanceId !== 0) {
        temp.push(this.toolsGridData.data[i].jobItemInstanceId);
        temp.push(counter);
        batchReorder.push(temp);
        counter++;
      }
    }

    let model = new InvoiceItemReorderModel();
    model.jobId = this.jobId;
    model.batchIds = batchReorder;

    this.batchReorderSub = this.jiiService.batchReorderToolList(model)
      .subscribe((x: boolean) => {
        if (x === true) {
          this.uiService.showSuccess('Invoice Reordered', 'Success');
        } else {
          this.uiService.showError('Invoice Not Reordered', 'Error');
        }
      });
  }

  private handleDragAndDrop(): Subscription {
    const sub = new Subscription(() => { });
    let draggedItemIndex;

    const tableRows = Array.from(document.querySelectorAll('.k-grid tr'));

    tableRows.forEach((item: Element) => {
      this.renderer.setAttribute(item, 'draggable', 'true');
      const dragStart = fromEvent<DragEvent>(item, 'dragstart');
      const dragOver = fromEvent(item, 'dragover');
      const dragEnd = fromEvent(item, 'dragend');

      sub.add(dragStart.pipe(
        tap(({ dataTransfer }) => {
          try {
            const dragImgEl = document.createElement('span');
            dragImgEl.setAttribute('style', 'position: absolute; display: block; top: 0; left: 0; width: 0; height: 0;');
            document.body.appendChild(dragImgEl);
            dataTransfer.setDragImage(dragImgEl, 0, 0);
          } catch (err) {
            // IE doesn't support setDragImage
          }
          try {
            // Firefox won't drag without setting data
            dataTransfer.setData('application/json', '');
          } catch (err) {
            // IE doesn't support MIME types in setData
          }
        })
      ).subscribe(({ target }) => {
        const row: HTMLTableRowElement = <HTMLTableRowElement>target;
        draggedItemIndex = row.rowIndex;
        const dataItem = this.toolsGridData.data[draggedItemIndex];
        dataItem.dragging = true;
      }));

      sub.add(dragOver.subscribe((e: any) => {
        e.preventDefault();
        const dataItem = this.toolsGridData.data.splice(draggedItemIndex, 1)[0];
        const dropIndex = closest(e.target, tableRow).rowIndex;
        const dropItem = this.toolsGridData.data[dropIndex];

        draggedItemIndex = dropIndex;
        this.zone.run(() =>
          this.toolsGridData.data.splice(dropIndex, 0, dataItem)
        );
      }));

      sub.add(dragEnd.subscribe((e: any) => {
        e.preventDefault();
        const dataItem = this.toolsGridData.data[draggedItemIndex];
        dataItem.dragging = false;
      }));
    });
    // console.log(this.gridData.data)
    return sub;
  }

}
