<div class="page-header">
  <div class="page-header-content header-elements-md-inline">
    <div class="page-title d-flex">
      <a [routerLink]="['/home']"
        class="breadcrumb-link-color">
        <h4><i class="icon-arrow-left52 mr-2"></i> <span class="font-weight-semibold">Maintenance Log Master</span> -
          Manage</h4>
      </a>
      <button type="button"
        class="btn btn-outline-dark btn-sm ml-3 pl-1 pr-1 pt-0 pb-0 no-border"
        [title]="!showFilter ? 'Show Filter' : 'Hide Filter'"
        (click)="showFilter = !showFilter;getNumberOfActiveFilterFields();">
        <span *ngIf="!showFilter">
          <i class="fa-regular fa-chevrons-down"></i>&nbsp;Show </span>
        <span *ngIf="showFilter"><i class="fa-regular fa-chevrons-up"></i>&nbsp;Hide
        </span>Filter<span class="text-warning"
          *ngIf="!showFilter && numberOfActiveFilterFields > 0"><strong>&nbsp;&nbsp;({{numberOfActiveFilterFields}}
            active
            criteria)</strong></span></button>
    </div>
    <div class="header-elements d-none py-0 mb-3 mb-md-0">
      <div class="breadcrumb">
        <a href="index.html"
          class="breadcrumb-item"><i class="icon-home2 mr-2"></i> Maintenance Log Master</a>
        <span class="breadcrumb-item active">List</span>
      </div>
    </div>
  </div>
</div>
<div class="page-content pt-0">
  <div class="content-wrapper">
    <div class="content">
      <div class="row">
        <div class="col-md-12"
          *ngIf="showFilter">
          <div class="card">
            <div class="card-body pb-2 pt-2">
              <div class="row">
                <div class="col-lg-3 col-md-6">
                  <div class="form-group mb-0">
                    <label class="col-form-label">Item</label>
                    <div>
                      <div *ngIf="itemService.itemWithMaintenanceLogsDropdown | async; let items">
                        <ng-select [items]="items"
                          bindLabel="dropdownDisplay"
                          bindValue="itemId"
                          placeholder="Select Item"
                          [(ngModel)]="maintenanceEventService.maintenanceEventFilterParams.itemId"
                          name="items"
                          appendTo="body"
                          (change)="onChangeItemFilter($event)"
                          (clear)="onChangeItemFilter()">
                        </ng-select>
                      </div>
                      <div *ngIf="!itemService.itemWithMaintenanceLogsDropdown">
                        <ng-select name="items"
                          placeholder="Select Serial Number">
                        </ng-select>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-3 col-md-6">
                  <div class="form-group mb-0">
                    <label class="col-form-label">Serial #</label>
                    <div>
                      <div *ngIf="itemInstanceService.itemInstanceRepairDropdownList | async; let itemInstances">
                        <ng-select [items]="itemInstances"
                          bindLabel="dropdownDisplay"
                          bindValue="itemInstanceId"
                          placeholder="Select Serial Number"
                          [(ngModel)]="maintenanceEventService.maintenanceEventFilterParams.itemInstanceId"
                          name="itemInstances"
                          appendTo="body"
                          (change)="onChangeSerialNumberFilter($event)"
                          (clear)="onChangeSerialNumberFilter()">
                        </ng-select>
                      </div>
                      <div *ngIf="!itemInstanceService.itemInstanceRepairDropdownList">
                        <ng-select name="itemInstances"
                          placeholder="Select Serial Number">
                        </ng-select>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-3 col-md-8">
                  <div class="form-group mb-0">
                    <label class="col-form-label">Vendor</label>
                    <div *ngIf="vendorService.vendorList | async; let vend">
                      <ng-select [items]="vend"
                        bindLabel="name"
                        bindValue="vendorId"
                        [searchable]="true"
                        placeholder="Select Vendor"
                        [(ngModel)]="maintenanceEventService.maintenanceEventFilterParams.vendorId"
                        name="vendorIds"
                        appendTo="body">
                      </ng-select>
                    </div>
                    <div *ngIf="!vendorService.vendorList.value">
                      <ng-select name="vendorIds"
                        placeholder="Select Vendor"
                        [readonly]="true">
                      </ng-select>
                    </div>
                  </div>
                </div>
                <div class="col-lg-3 col-md-8">
                  <div class="form-group mb-0">
                    <label class="col-form-label">Type</label>
                    <div *ngIf="maintenanceTypes">
                      <ng-select [items]="maintenanceTypes"
                        bindLabel="name"
                        bindValue="maintenanceTypeId"
                        [searchable]="true"
                        placeholder="Select Maintenance Type"
                        [(ngModel)]="maintenanceEventService.maintenanceEventFilterParams.maintenanceTypeId"
                        name="maintenanceTypeIds"
                        appendTo="body">
                      </ng-select>
                    </div>
                    <div *ngIf="!maintenanceTypes">
                      <ng-select name="maintenanceTypes"
                        placeholder="Select Maintenance Type">
                      </ng-select>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row d-flex flex-row-reverse pr-2 mt-lg-2">
                <button type="submit"
                  class="btn btn-sm btn-primary"
                  (click)="maintenanceEventService.getMaintenanceEvents(showInactive, true);kGridService.pageChange({ skip: 0, take: kGridService.kGridParams.pageSize })"><i
                    class="fa fa-search mr-2"></i>Search</button>
                <button type="submit"
                  class="btn btn-sm btn-light mr-1"
                  (click)="maintenanceEventService.clearFilterParams(showInactive);kGridService.pageChange({ skip: 0, take: kGridService.kGridParams.pageSize });onClearFilters()"><i
                    class="far fa-times-circle"></i>&nbsp;Clear</button>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-12">
          <div class="card border-top-primary border-top-2 rounded-top-0"
            style="border-top-color: #2196F3 !important;">
            <div class="card-header bg-light header-elements-sm-inline">
              <h6 class="card-title"> Maintenance Logs</h6>
              <div class="header-elements">
                <div class="switch-container pt-2 mr-2">
                  <span class="switch-label">Show Inactive </span>
                  <ui-switch [ngModel]="showInactive"
                    (change)="onChangeShowInactive($event)"></ui-switch>
                </div>
                <button class="btn btn-light btn-sm"
                  (click)="openMaintenanceEventModal(0)">
                  <i class="fa fa-plus"></i> New Maintenance Log
                </button>
              </div>
            </div>
            <app-table-skeleton *ngIf="maintenanceEventService.maintenanceEventsAreLoading"></app-table-skeleton>
            <ng-container *ngIf="!maintenanceEventService.maintenanceEventsAreLoading">
              <div class="table-responsive"
                *ngIf="maintenanceEventService.maintenanceEventList | async; let model">
                <kendo-grid style="min-width:1200px;"
                  [style.maxHeight.px]="640"
                  [kendoGridBinding]="model"
                  filterable="menu"
                  [sortable]="true"
                  [resizable]="true"
                  [rowClass]="rowCallback"
                  [pageSize]="kGridService.kGridParams.pageSize"
                  [skip]="kGridService.kGridParams.skip"
                  (pageChange)="kGridService.pageChange($event)"
                  [pageable]="{
                              buttonCount: kGridService.kGridParams.buttonCount,
                              info: kGridService.kGridParams.info,
                              type: kGridService.kGridParams.type,
                              pageSizes: kGridService.kGridParams.pageSizes,
                              previousNext: kGridService.kGridParams.previousNext
                            }">
                  <ng-template kendoGridToolbarTemplate>
                    <button type="button"
                      kendoGridExcelCommand
                      icon="file-excel">Export to Excel</button>
                  </ng-template>
                  <kendo-grid-column field="one"
                    title=""
                    [width]="60"
                    filterable="false">
                    <ng-template kendoGridCellTemplate
                      let-dataItem
                      let-rowIndex="rowIndex">
                      <button class="btn btn-xs btn-light"
                        (click)="openMaintenanceEventModal(dataItem.maintenanceEventId)"><i
                          class="fa fa-edit"></i></button>
                    </ng-template>
                  </kendo-grid-column>
                  <kendo-grid-column field="maintenanceDate"
                    title="Date"
                    [width]="80">
                    <ng-template kendoGridCellTemplate
                      let-dataItem
                      let-rowIndex="rowIndex">
                      {{dataItem.maintenanceDate | date: 'shortDate'}}
                    </ng-template>
                  </kendo-grid-column>
                  <kendo-grid-column field="vendorName"
                    title="Vendor"
                    [width]="180">
                    <ng-template kendoGridCellTemplate
                      let-dataItem
                      let-rowIndex="rowIndex">
                      <ng-template #popoverTemplatePo>
                        <div>
                          <p *ngFor="let l of dataItem.purchaseOrderLines"
                            class="mb-1 link"> <a [routerLink]="['/purchase-orders/details', l.poid]"
                              target="_blank"
                              class="link">{{l.dropDownDisplay}}</a></p>
                        </div>
                      </ng-template>
                      {{dataItem.vendorName}}<span *ngIf="dataItem.purchaseOrderLines?.length > 0 && seePrices"
                        class="float-right"><i class="fas fa-dollar-sign text-primary"
                          type="button"
                          [popover]="popoverTemplatePo"
                          popoverTitle="PO Lines"
                          placement="auto"
                          [outsideClick]="true"
                          container="body"></i></span>
                    </ng-template>
                  </kendo-grid-column>
                  <kendo-grid-column field="serialNumber"
                    title="Serial #"
                    [width]="110">
                    <ng-template kendoGridCellTemplate
                      let-dataItem
                      let-rowIndex="rowIndex">
                      <a [routerLink]="['/item-instances/details', dataItem.itemInstanceId]"
                        target="_blank"
                        class="link"
                        *ngIf="seePrices">{{dataItem.serialNumber}}</a>
                      <span *ngIf="!seePrices">{{dataItem.serialNumber}}</span>
                    </ng-template>
                  </kendo-grid-column>
                  <kendo-grid-column field="partNumber"
                    title="Part">
                    <ng-template kendoGridCellTemplate
                      let-dataItem
                      let-rowIndex="rowIndex">
                      <span *ngIf="dataItem.partNumber">{{dataItem.partNumber}}</span><span
                        *ngIf="dataItem.partNumber && dataItem.itemDescription"> -
                      </span><span>{{dataItem.itemDescription}}</span>
                    </ng-template>
                  </kendo-grid-column>
                  <kendo-grid-column field="maintenanceTypeName"
                    title="Maintenance Type"
                    [width]="180">
                  </kendo-grid-column>
                  <kendo-grid-column field="hrsMileage"
                    title="Hr/Mi"
                    [width]="70">
                  </kendo-grid-column>
                  <kendo-grid-column field="notes"
                    title="Notes"
                    [width]="80">
                    <ng-template kendoGridCellTemplate
                      let-dataItem
                      let-rowIndex="rowIndex">
                      <ng-template #popoverTemplateNotes>
                        <div>
                          {{dataItem.notes}}
                        </div>
                      </ng-template>
                      <span *ngIf="dataItem.notes"
                        class="pointer"><i class="far fa-sticky-note text-primary"
                          type="button"
                          [popover]="popoverTemplateNotes"
                          popoverTitle="Notes"
                          placement="auto"
                          [outsideClick]="true"
                          container="body"></i></span>
                      <span *ngIf="!dataItem.notes"
                        class="text-muted"><i class="far fa-sticky-note"></i></span>
                    </ng-template>
                  </kendo-grid-column>
                  <kendo-grid-column field="userName"
                    title="User"
                    [width]="200">
                  </kendo-grid-column>
                  <kendo-grid-column field="poTotal"
                    title="PO Total"
                    [width]="110"
                    *ngIf="seePrices">
                    <ng-template kendoGridCellTemplate
                      let-dataItem
                      let-rowIndex="rowIndex">
                      {{dataItem.poTotal | currency:'USD':true:'1.2-2'}}
                    </ng-template>
                  </kendo-grid-column>
                  <kendo-grid-column field="one"
                    title=""
                    [width]="60"
                    filterable="false">
                    <ng-template kendoGridCellTemplate
                      let-dataItem
                      let-rowIndex="rowIndex">
                      <button class="btn btn-xs btn-light"
                        [swal]="{ title: 'Delete Maintenance Event?', text: dataItem.serialNumber + ' - ' + dataItem.vendorName, showCancelButton: true, confirmButtonText: 'Delete', confirmButtonColor: '#DC3545' }"
                        (confirm)="deleteMaintenanceEvent($event, dataItem.maintenanceEventId)"
                        [disabled]="dataItem.isActive === false"><i class="fa fa-trash"></i></button>
                    </ng-template>
                  </kendo-grid-column>
                  <kendo-grid-excel fileName="Grizzly - Maintenance Log.xlsx"
                    [fetchData]="allData">
                    <kendo-excelexport-column field="maintenanceEventId"
                      title="Maintenance Log Id">
                    </kendo-excelexport-column>
                    <kendo-excelexport-column [width]="140"
                      field="maintenanceDate"
                      title="Date">
                    </kendo-excelexport-column>
                    <kendo-excelexport-column field="vendorName"
                      title="Vendor">
                    </kendo-excelexport-column>
                    <kendo-excelexport-column field="serialNumber"
                      title="Serial #">
                    </kendo-excelexport-column>
                    <kendo-excelexport-column field="partNumber"
                      title="Part Number">
                    </kendo-excelexport-column>
                    <kendo-excelexport-column field="itemDescription"
                      title="Item">
                    </kendo-excelexport-column>
                    <kendo-excelexport-column field="maintenanceTypeName"
                      title="Maintenance Type">
                    </kendo-excelexport-column>
                    <kendo-excelexport-column field="hrsMileage"
                      title="Hrs/Mi">
                    </kendo-excelexport-column>
                    <kendo-excelexport-column field="notes"
                      title="Notes">
                    </kendo-excelexport-column>
                    <kendo-excelexport-column field="userName"
                      title="User">
                    </kendo-excelexport-column>
                  </kendo-grid-excel>
                </kendo-grid>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
