import { Component, EventEmitter, Input, NgZone, OnDestroy, OnInit, Output, Renderer2 } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { RowClassArgs } from '@progress/kendo-angular-grid';
import { State, process } from '@progress/kendo-data-query';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subscription, fromEvent } from 'rxjs';
import { take, tap } from 'rxjs/operators';
import { AddBuiltPackerModalComponent } from 'src/app/packers/add-built-packer-modal/add-built-packer-modal.component';
import { InvoiceItemReorderModel } from 'src/models/RequestParams/invoice-item-reorder.model';
import { InvoiceItemModel } from 'src/models/invoice-item.model';
import { JobSalesItemModel } from 'src/models/job-sales-item.model';
import { JobModel } from 'src/models/job.model';
import { AppStateService } from 'src/services/app-state.service';
import { InvoiceItemService } from 'src/services/invoice-item.service';
import { JobService } from 'src/services/job.service';
import { ReportService } from 'src/services/report.service';
import { UIService } from 'src/services/ui.service';
import { WorksheetService } from 'src/services/worksheet.service';
import { InvoiceItemTypes } from '../../../../enums/invoice-item-types';
import { JobStatuses } from '../../../../enums/job-statuses';
import { JobTypes } from '../../../../enums/job-types';
import { UserService } from '../../../../services/user.service';
import { ChooseWorksheetModalComponent } from '../../../worksheets/choose-worksheet-modal/choose-worksheet-modal.component';
import { AddEditJobSalesItemModalComponent } from '../../sales/add-edit-job-sales-item-modal/add-edit-job-sales-item-modal.component';
import { AddEditJobSalesItemNoteModalComponent } from '../../sales/add-edit-job-sales-item-note-modal/add-edit-job-sales-item-note-modal.component';
import { MobileReorderInvoiceModalComponent } from '../mobile-re-order-invoice-modal/mobile-re-order-invoice-modal.component';

// //////////////FOR REORDER ROWS////////////////////////
const tableRow = node => node.tagName.toLowerCase() === 'tr';

const closest = (node, predicate) => {
  while (node && !predicate(node)) {
    node = node.parentNode;
  }

  return node;
};
// //////////////FOR REORDER ROWS////////////////////////

@Component({
  selector: 'app-sales-invoice-table',
  templateUrl: './sales-invoice-table.component.html',
  styleUrls: ['./sales-invoice-table.component.css']
})

export class SalesInvoiceTableComponent implements OnInit, OnDestroy {
  constructor(
    private modalService: BsModalService,
    private activatedRoute: ActivatedRoute,
    public jobService: JobService,
    public uiService: UIService,
    public invoiceItemService: InvoiceItemService,
    public worksheetService: WorksheetService,
    public appStateService: AppStateService,
    // //////////////FOR REORDER ROWS////////////////////////
    private renderer: Renderer2,
    private zone: NgZone,
    public reportService: ReportService,
    public userService: UserService,
  ) { };

  @Input() job: JobModel;
  @Output() enableReorderEvent = new EventEmitter<boolean>();

  public bsModalRef: BsModalRef;
  public lines: InvoiceItemModel[];
  public enableReorder: boolean = false;
  public editClosedInvoice: boolean = false;
  public JobTypes = JobTypes;
  public JobStatuses = JobStatuses;
  public InvoiceItemTypes = InvoiceItemTypes;
  public showPriceHeatmap: boolean = false;

  private isTabletSub: Subscription;
  public isTablet: boolean = false;

  private jobSub: Subscription;
  private reorderSub: Subscription;
  private deleteItemSub: Subscription;

  // //////////////FOR REORDER ROWS////////////////////////
  public invoiceItems: InvoiceItemModel[] = [];
  private currentSubscription: Subscription;
  public state: State = {};
  public gridData: any = process(this.invoiceItems, this.state);

  ngOnDestroy(): void {
    this.isTabletSub?.unsubscribe();

    this.deleteItemSub?.unsubscribe();

    this.jobSub?.unsubscribe();

    this.reorderSub?.unsubscribe();

    this.currentSubscription?.unsubscribe();
  }

  ngOnInit(): void {
    this.subscribeToJob();

    this.determineIsTablet();
  }

  public determineIsTablet(): void {
    this.isTabletSub = this.appStateService.isTablet$
      .subscribe((isTablet: boolean) => {
        this.isTablet = isTablet;
      });
  }

  public openJobSalesItemNoteModal(
    jobId: number,
    invoiceItemId: number): void {
    const initialState = {
      jobId: jobId,
      invoiceItemId: invoiceItemId
    };

    this.bsModalRef = this.modalService.show(AddEditJobSalesItemNoteModalComponent, { initialState, backdrop: 'static' });
  }

  public openJobSalesItemModal(
    jobId: number,
    invoiceItemId: number,
    isInventoryItem: boolean,
    isRebuildItem: boolean = false): void {
    const initialState = {
      jobId: jobId,
      invoiceItemId: invoiceItemId,
      isInventoryItem: isInventoryItem,
      isRebuildItem: isRebuildItem
    };

    this.bsModalRef = this.modalService.show(AddEditJobSalesItemModalComponent, { initialState, backdrop: 'static' });
  }

  public openReorderInvoiceModal(): void {
    const mainInvoiceItems: InvoiceItemModel[] = this.job.invoiceItems.filter((ii: InvoiceItemModel) => {
      return ii.invoiceItemId !== 0;
    });

    const initialState = {
      jobId: this.job.jobId,
      rows: mainInvoiceItems
    };
    this.bsModalRef = this.modalService.show(MobileReorderInvoiceModalComponent, { initialState, class: 'modal-xl', backdrop: 'static' });
  }

  public openChooseWorksheetModal(): void {
    const modalSize = this.isTablet ? 'modal-xl' : 'modal-lg';

    const initialState = {
      jobId: this.job.jobId,
      customerId: this.job.customerId,
      packersOnly: true
    };
    this.bsModalRef = this.modalService.show(ChooseWorksheetModalComponent, { initialState, class: modalSize, backdrop: 'static' });
  }

  public openBuiltPackerModal(jobId: number): void {
    const initialState = {
      customerId: this.job.customerId,
      jobId: jobId
    };
    this.bsModalRef = this.modalService.show(AddBuiltPackerModalComponent, { initialState, class: 'modal-lg', backdrop: 'static' });
  }

  public subscribeToJob(): void {
    this.jobSub = this.jobService.job
      .subscribe((x: JobModel) => {
        if (x !== null) {
          this.invoiceItems = x.invoiceItems;
          this.createPriceOrder(x.invoiceItems);
          this.gridData = process(this.invoiceItems, this.state);
        }
      });
  }

  public deleteJobSalesItem(dto: JobSalesItemModel): void {
    this.deleteItemSub = this.invoiceItemService.deleteJobSalesItemByInvoiceItemId(dto)
      .subscribe((x: boolean) => {
        if (x) {
          this.uiService.showSuccess('Success', 'Item Deleted');
        } else {
          this.uiService.showError('Error', 'Item Not Deleted');
        }
      });
  }

  public createPriceOrder(items: InvoiceItemModel[]): void {
    let sortable: InvoiceItemModel[] = [];

    items.forEach((ii: InvoiceItemModel) => {
      sortable.push(ii);
    });

    sortable.sort((a, b) => b.total - a.total);

    sortable.forEach((ii: InvoiceItemModel, index: number) => {
      ii.priceOrder = index + 1;
    });
  }

  public togglePriceHeatmap($event: any): void {
    if ($event !== undefined) {
      this.showPriceHeatmap = !this.showPriceHeatmap;

      this.gridData.data.forEach((x: any) => {
        x.showPriceHeatmap = this.showPriceHeatmap;
      });
    }
  }

  // ////////////////////////////////////////////////////////////

  public switchEnableReorder($event: boolean): void {
    if ($event !== undefined) {
      this.enableReorder = $event;
      this.enableReorderEvent.emit(this.enableReorder);

      if (this.enableReorder === true) {
        // hide Rebuild detail lines
        this.gridData.data.forEach((x: any) => {
          if (x.invoiceItemId === 0) {
            x.hideForReorder = true;
          }
        });

        this.currentSubscription = this.handleDragAndDrop();
      } else {
        // unhide of Rebuild detail lines happens in the batchReorder function
        this.currentSubscription.unsubscribe();
        this.batchReorder();
      }
    }
  }

  public batchReorder(): void {
    let batchReorder: number[][] = [];
    let counter: number = 0;
    for (let i = 0; i < this.gridData.data.length; i++) {
      let temp = [];
      if (this.gridData.data[i].invoiceItemId !== undefined && this.gridData.data[i].invoiceItemId !== null && this.gridData.data[i].invoiceItemId !== 0) {
        temp.push(this.gridData.data[i].invoiceItemId);
        temp.push(counter);
        batchReorder.push(temp);
        counter++;
      }
    }

    let model = new InvoiceItemReorderModel();
    model.jobId = this.job.jobId;
    model.batchIds = batchReorder;

    this.reorderSub = this.jobService.batchReorderJobInvoiceItems(model)
      .subscribe((x: boolean) => {
        if (x === true) {
          // un-hides 
          this.gridData.data.forEach((x: any) => {
            if (x.invoiceItemId === 0) {
              x.hideForReorder = undefined;
            }
          });

          this.uiService.showSuccess('Invoice Reordered', 'Success');
        } else {
          this.uiService.showError('Invoice Not Reordered', 'Error');
        }
      });
  }

  public rowCallback(context: RowClassArgs): any {
    const isWorksheet: boolean = (context.dataItem.worksheetId !== null && context.dataItem.worksheetId !== undefined)
      || (context.dataItem.parentInvoiceItemId !== null && context.dataItem.parentInvoiceItemId !== undefined);

    const hideWorksheetRow: boolean = context.dataItem.hideForReorder !== undefined
      && context.dataItem.hideForReorder !== null
      && context.dataItem.hideForReorder === true;

    return {
      worksheet: isWorksheet,
      dragging: context.dataItem.dragging,
      hideForReorder: hideWorksheetRow,
      one: context.dataItem.showPriceHeatmap && context.dataItem.priceOrder === 1,
      two: context.dataItem.showPriceHeatmap && context.dataItem.priceOrder === 2,
      three: context.dataItem.showPriceHeatmap && context.dataItem.priceOrder === 3
    };
  }

  public dataStateChange(state: State): void {
    this.state = state;
    this.gridData = process(this.invoiceItems, this.state);
    if (this.currentSubscription !== undefined) {
      this.currentSubscription.unsubscribe();
    }
    this.zone.onStable.pipe(take(1))
      .subscribe(() => this.currentSubscription = this.handleDragAndDrop());
  }

  private handleDragAndDrop(): Subscription {
    const sub = new Subscription(() => { });
    let draggedItemIndex;

    const tableRows = Array.from(document.querySelectorAll('.k-grid tr'));

    tableRows.forEach((item: Element) => {
      this.renderer.setAttribute(item, 'draggable', 'true');
      const dragStart = fromEvent<DragEvent>(item, 'dragstart');
      const dragOver = fromEvent(item, 'dragover');
      const dragEnd = fromEvent(item, 'dragend');

      sub.add(dragStart.pipe(
        tap(({ dataTransfer }) => {
          try {
            const dragImgEl = document.createElement('span');
            dragImgEl.setAttribute('style', 'position: absolute; display: block; top: 0; left: 0; width: 0; height: 0;');
            document.body.appendChild(dragImgEl);
            dataTransfer.setDragImage(dragImgEl, 0, 0);
          } catch (err) {
            // IE doesn't support setDragImage
          }
          try {
            // Firefox won't drag without setting data
            dataTransfer.setData('application/json', '');
          } catch (err) {
            // IE doesn't support MIME types in setData
          }
        })
      ).subscribe(({ target }) => {
        const row: HTMLTableRowElement = <HTMLTableRowElement>target;
        draggedItemIndex = row.rowIndex;
        const dataItem = this.gridData.data[draggedItemIndex];
        dataItem.dragging = true;
      }));

      sub.add(dragOver.subscribe((e: any) => {
        e.preventDefault();
        const dataItem = this.gridData.data.splice(draggedItemIndex, 1)[0];
        const dropIndex = closest(e.target, tableRow).rowIndex;
        const dropItem = this.gridData.data[dropIndex];

        draggedItemIndex = dropIndex;
        this.zone.run(() =>
          this.gridData.data.splice(dropIndex, 0, dataItem)
        );
      }));

      sub.add(dragEnd.subscribe((e: any) => {
        e.preventDefault();
        const dataItem = this.gridData.data[draggedItemIndex];
        dataItem.dragging = false;
      }));
    });
    return sub;
  }

}
