<!--<h1>NEW SALES INVOICE</h1>-->
<div class="card border-top-primary border-top-2 rounded-top-0"
  style="border-top-color: #2196F3 !important;">
  <div class="card-header bg-light header-elements-sm-inline">
    <h6 class="card-title">Sale Items</h6>
    <div class="header-elements">
      <div class="switch-container pt-2 mr-2"
        *ngIf="job.jobStatusId === JobStatuses.Closed_Sales && userService.user.value && userService.user.value.activeRoles && userService.user.value.activeRoles.includes('Manage Closed Invoices')">
        <span class="switch-label">Edit Override </span>
        <ui-switch [(ngModel)]="editClosedInvoice"></ui-switch>
      </div>
      <button class="btn  btn-sm btn-primary mr-2"
        *ngIf="isTablet"
        [disabled]="invoiceItems.length <= 1"
        (click)="openReorderInvoiceModal()"><i class="fas fa-arrows-v"></i>&nbsp;&nbsp;Re-sort</button>
      <div class="switch-container pt-2 mr-2"
        *ngIf="!enableReorder && !isTablet">
        <span class="switch-label">Enable Re-sort </span>
        <ui-switch [ngModel]="enableReorder"
          (change)="switchEnableReorder($event)"></ui-switch>
      </div>
      <button class="btn btn-sm btn-primary mr-2"
        *ngIf="enableReorder"
        (click)="switchEnableReorder(false)">Save Sort Order</button>
      <div class="btn-group"
        dropdown>
        <button class="btn btn-light btn-sm dropdown-toggle"
          *ngIf="!editClosedInvoice && (enableReorder || (jobService.job.value !== null && jobService.job.value.jobStatusIsTerminal === true))"
          disabled>
          <i class="fa fa-plus"></i> Add Line
          <span class="caret"></span>
        </button>
        <button class="btn btn-light btn-sm dropdown-toggle"
          dropdownToggle
          id="button-basic"
          aria-controls="dropdown-basic"
          *ngIf="editClosedInvoice || (!enableReorder && !(jobService.job.value !== null && jobService.job.value.jobStatusIsTerminal === true))">
          <i class="fa fa-plus"></i> Add Line
          <span class="caret"></span>
        </button>
        <ul id="dropdown-basic"
          *dropdownMenu
          class="dropdown-menu dropdown-menu-right"
          role="menu"
          aria-labelledby="button-basic">
          <li role="menuitem"><span class="dropdown-item"
              (click)="openJobSalesItemModal(job.jobId, 0, true)"><i class="fal fa-inventory"></i> Inventory</span></li>
          <li role="menuitem"><span class="dropdown-item"
              (click)="openJobSalesItemModal(job.jobId, 0, false)"><i class="fal fa-box"></i> Miscellaneous</span></li>
          <li role="menuitem"
            *ngIf="job && job.jobTypeId === JobTypes.Sales"><span class="dropdown-item"
              (click)="openBuiltPackerModal(job.jobId)"><i class="fal fa-user-helmet-safety"></i>Add Built
              Item</span></li>
          <li role="menuitem"
            *ngIf="job && job.jobTypeId === JobTypes.Sales"><span class="dropdown-item"
              (click)="openChooseWorksheetModal()"><i class="fal fa-tools"></i> Rebuild</span></li>
          <li role="menuitem"><span class="dropdown-item"
              (click)="openJobSalesItemNoteModal(job.jobId, 0)"><i class="fal fa-sticky-note"></i> Note</span></li>
        </ul>
        <button class="btn btn-sm btn-light ml-2"
          [disabled]="lines && lines.length === 0"
          (click)="togglePriceHeatmap($event)"
          title="Top 3 Line Amounts"><span [ngClass]="{'text-danger': showPriceHeatmap}"><i
              class="fa-regular fa-fire"></i></span></button>
      </div>
    </div>
  </div>
  <div class="table-responsive">
    <div class="table-responsive"
      *ngIf="gridData.data.length === 0">
      <div class="alert alert-primary ml-3 mr-3 mt-3"
        role="alert">
        <i class="fad fa-check"></i>&nbsp;&nbsp;There are currently no <strong>Sale Items.</strong>
      </div>
    </div>
    <kendo-grid *ngIf="gridData.data.length >  0"
      [data]="gridData"
      filterable="false"
      [sortable]="false"
      [resizable]="true"
      [ngClass]="{'kendo-min-width': !isTablet}"
      [rowClass]="rowCallback"
      (dataStateChange)="dataStateChange($event)">
      <kendo-grid-column field="move"
        title=""
        [width]="40"
        filterable="false"
        *ngIf="enableReorder">
        <ng-template kendoGridCellTemplate
          let-dataItem
          let-rowIndex="rowIndex">
          <span class="pl-1 text-danger"><i class="fas fa-arrows-alt-v"></i></span>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column field="one"
        title=""
        [width]="60"
        filterable="false">
        <ng-template kendoGridCellTemplate
          let-dataItem
          let-rowIndex="rowIndex">
          <button class="btn btn-xs btn-light"
            (click)="openJobSalesItemModal(job.jobId, dataItem.invoiceItemId, dataItem.itemId!==null, (dataItem.worksheetRowItemId !== null || dataItem.worksheetId !== null))"
            [disabled]="!editClosedInvoice && (enableReorder || (jobService.job.value !== null && jobService.job.value.jobStatusIsTerminal === true))"
            *ngIf="dataItem.invoiceItemTypeId === InvoiceItemTypes.InvoiceItem && (dataItem.parentInvoiceItemId === null || dataItem.parentInvoiceItemId === undefined)"><i
              class="fa fa-pencil"></i></button>
          <button class="btn btn-xs btn-light"
            (click)="openJobSalesItemNoteModal(job.jobId, dataItem.invoiceItemId)"
            [disabled]="!editClosedInvoice && (enableReorder || (jobService.job.value !== null && jobService.job.value.jobStatusIsTerminal === true))"
            *ngIf="dataItem.invoiceItemTypeId === InvoiceItemTypes.Note && (dataItem.parentInvoiceItemId === null || dataItem.parentInvoiceItemId === undefined)"><i
              class="fa fa-pencil"></i></button>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column field="partNumber"
        title="Part #"
        [width]="120">
        <ng-template kendoGridCellTemplate
          let-dataItem
          let-rowIndex="rowIndex">
          <span
            *ngIf="dataItem.invoiceItemTypeId === InvoiceItemTypes.InvoiceItem && dataItem.partNumber!==undefined&&dataItem.partNumber!==null">{{dataItem.partNumber}}</span>
          <span
            *ngIf="dataItem.invoiceItemTypeId === InvoiceItemTypes.InvoiceItem && (dataItem.partNumber===undefined||dataItem.partNumber===null)&&(dataItem.parentInvoiceItemId === null || dataItem.parentInvoiceItemId === undefined)"
            class="text-muted">n/a</span>
          <span *ngIf="dataItem.invoiceItemTypeId === InvoiceItemTypes.Note"
            class="text-muted"><i class="fal fa-sticky-note"></i></span>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column field="description"
        title="Description"
        [width]="250">
        <ng-template kendoGridCellTemplate
          let-dataItem
          let-rowIndex="rowIndex">
          <span *ngIf="dataItem.parentInvoiceItemId===null"
            [ngClass]="{'font-italic' : dataItem.invoiceItemTypeId === InvoiceItemTypes.Note}">{{dataItem.description}}</span>
          <span *ngIf="dataItem.parentInvoiceItemId!==null"
            class="text-muted"><span style="margin-left: 5px;"><i
                class="far fa-level-up fa-rotate-90"></i></span>&nbsp;&nbsp;{{dataItem.description}}</span>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column field="quantity"
        title="Quantity"
        [width]="90"
        filterable="false">
        <ng-template kendoGridCellTemplate
          let-dataItem
          let-rowIndex="rowIndex">
          <span
            *ngIf="dataItem.invoiceItemTypeId === InvoiceItemTypes.InvoiceItem && dataItem.parentInvoiceItemId===null">{{dataItem.quantity}}</span>
          <span
            *ngIf="dataItem.invoiceItemTypeId === InvoiceItemTypes.InvoiceItem && dataItem.parentInvoiceItemId!==null"
            class="text-muted">{{dataItem.quantity}}</span>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column field="price"
        title="Unit Price"
        [width]="120"
        *ngIf="job.jobTypeId !== JobTypes.Packer || 
          (job.jobTypeId === JobTypes.Packer && userService.user.value && userService.user.value.activeRoles && userService.user.value.activeRoles.includes('General'))">
        <ng-template kendoGridCellTemplate
          let-dataItem
          let-rowIndex="rowIndex">
          <span
            *ngIf="dataItem.invoiceItemTypeId === InvoiceItemTypes.InvoiceItem && dataItem.parentInvoiceItemId===null">{{dataItem.price
            | currency}}</span>
          <span
            *ngIf="dataItem.invoiceItemTypeId === InvoiceItemTypes.InvoiceItem && dataItem.parentInvoiceItemId!==null"
            class="text-muted">{{dataItem.price | currency}}</span>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column field="total"
        title="Total"
        [width]="120"
        *ngIf="job.jobTypeId !== JobTypes.Packer || 
          (job.jobTypeId === JobTypes.Packer && userService.user.value && userService.user.value.activeRoles && userService.user.value.activeRoles.includes('General'))">
        <ng-template kendoGridCellTemplate
          let-dataItem
          let-rowIndex="rowIndex">
          <span *ngIf="dataItem.invoiceItemTypeId === InvoiceItemTypes.InvoiceItem">{{dataItem.total | currency}}</span>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column [width]="35"
        *ngIf="!isTablet">
        <ng-template kendoGridCellTemplate
          let-dataItem>
          <i class="fal fa-file-invoice-dollar"
            title="Taxable Item"
            *ngIf="dataItem.isTaxable"></i>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column field="one"
        title=""
        [width]="60"
        filterable="false">
        <ng-template kendoGridCellTemplate
          let-dataItem
          let-rowIndex="rowIndex">
          <button class="btn btn-xs btn-light"
            [swal]="{ title: 'Delete Invoice Line?', text: dataItem.description + ': $' + dataItem.total, showCancelButton: true, confirmButtonText: 'Delete', confirmButtonColor: '#DC3545' }"
            (confirm)="deleteJobSalesItem(dataItem)"
            [disabled]="!editClosedInvoice && (enableReorder || dataItem.isPackerWorkLog || (dataItem.worksheetRowItemId !== null||dataItem.worksheetId !== null)||(jobService.job.value !== null && jobService.job.value.jobStatusIsTerminal === true))"
            *ngIf="dataItem.invoiceItemTypeId === InvoiceItemTypes.InvoiceItem && dataItem.parentInvoiceItemId === null || dataItem.parentInvoiceItemId === undefined">
            <i class="fa fa-trash"></i>
          </button>
          <button class="btn btn-xs btn-light"
            [swal]="{ title: 'Delete Note?', text: dataItem.description, showCancelButton: true, confirmButtonText: 'Delete', confirmButtonColor: '#DC3545' }"
            (confirm)="deleteJobSalesItem(dataItem)"
            [disabled]="!editClosedInvoice && (enableReorder || (dataItem.worksheetRowItemId !== null||dataItem.worksheetId !== null)||(jobService.job.value !== null && jobService.job.value.jobStatusIsTerminal === true))"
            *ngIf="dataItem.invoiceItemTypeId === InvoiceItemTypes.Note && dataItem.parentInvoiceItemId === null || dataItem.parentInvoiceItemId === undefined">
            <i class="fa fa-trash"></i>
          </button>
        </ng-template>
      </kendo-grid-column>
    </kendo-grid>
  </div>
</div>
