<div class="card border-top-primary border-top-2 rounded-top-0"
  style="border-top-color: #2196F3 !important;">
  <div class="card-header bg-light header-elements-sm-inline">
    <h6 class="card-title">Billing Codes</h6>
    <div class="header-elements">
      <button class="btn btn-light btn-sm"
        (click)="openBillingCodeModal(0)">
        <i class="fa fa-plus"></i> New Billing Code
      </button>
    </div>
  </div>
  <div class="table-responsive"
    *ngIf="billingCodeService.billingCodeList | async; let model">
    <div class="table-responsive"
      *ngIf="model.length === 0">
      <div class="alert alert-primary ml-3 mr-3 mt-3"
        role="alert">
        <i class="fad fa-check"></i>&nbsp;&nbsp;There are currently no <strong>Billing Codes.</strong>
      </div>
    </div>
    <kendo-grid *ngIf="model.length > 0"
      [kendoGridBinding]="model"
      filterable="false"
      [sortable]="true"
      [resizable]="true"
      [pageSize]="kGridParams.pageSize"
      [skip]="kGridParams.skip"
      (pageChange)="pageChange($event)"
      [pageable]="{
                    buttonCount: kGridParams.buttonCount,
                    info: kGridParams.info,
                    type: kGridParams.type,
                    pageSizes: kGridParams.pageSizes,
                    previousNext: kGridParams.previousNext
                  }">
      <kendo-grid-column field="one"
        title=""
        [width]="60"
        filterable="false">
        <ng-template kendoGridCellTemplate
          let-dataItem
          let-rowIndex="rowIndex">
          <button class="btn btn-xs btn-light"
            (click)="openBillingCodeModal(dataItem.billingCodeId)"
            [disabled]="dataItem.isActive === false"><i class="fa fa-edit"></i></button>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column field="name"
        title="Name"
        [width]="300">
      </kendo-grid-column>
      <kendo-grid-column field="uomName"
        title="UOM"
        [width]="90">
      </kendo-grid-column>
      <kendo-grid-column field="quantity"
        title="Qty"
        [width]="50">
      </kendo-grid-column>
      <kendo-grid-column field="rate"
        title="Rate"
        [width]="90">
        <ng-template kendoGridCellTemplate
          let-dataItem
          let-rowIndex="rowIndex">
          {{dataItem.rate | currency}}
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column field="itemDescription"
        title="Item"
        [width]="200">
      </kendo-grid-column>
      <kendo-grid-column field="customerName"
        title="Customer"
        [width]="200">
      </kendo-grid-column>
      <kendo-grid-column field="additionalDayRental"
        title="Add'l Day"
        [width]="70"
        *ngIf="showAdditionalDayColumn">
        <ng-template kendoGridCellTemplate
          let-dataItem
          let-rowIndex="rowIndex">
          <i class="fas fa-check"
            title="Additional Day Rental"
            *ngIf="dataItem.additionalDayRental"></i>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column field="isTaxable"
        title="Taxable"
        [width]="70">
        <ng-template kendoGridCellTemplate
          let-dataItem
          let-rowIndex="rowIndex">
          <i class="fal fa-file-invoice-dollar"
            title="Taxable Item"
            *ngIf="dataItem.isTaxable"></i>
        </ng-template>
      </kendo-grid-column>
    </kendo-grid>
  </div>
</div>
