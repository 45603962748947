<div class="card border-top-primary border-top-2 rounded-top-0"
  style="border-top-color: #2196F3 !important;">
  <div class="card-header bg-light header-elements-sm-inline">
    <h6 class="card-title"> Maintenance Logs</h6>
    <div class="header-elements">
      <div class="switch-container pt-2 mr-2">
        <span class="switch-label">Show Inactive </span>
        <ui-switch [ngModel]="showInactive"
          (change)="onChangeShowInactive($event)"></ui-switch>
      </div>
      <button class="btn btn-light btn-sm"
        (click)="openMaintenanceEventModal(0)">
        <i class="fa fa-plus"></i> New Maintenance Log
      </button>
    </div>
  </div>
  <app-table-skeleton *ngIf="maintenanceEventService.maintenanceEventsAreLoading"></app-table-skeleton>
  <ng-container *ngIf="!maintenanceEventService.maintenanceEventsAreLoading">
    <div class="table-responsive"
      *ngIf="maintenanceEventService.maintenanceEventByItemInstanceList | async; let model">
      <kendo-grid style="min-width:1200px;"
        [style.maxHeight.px]="640"
        [kendoGridBinding]="model"
        filterable="menu"
        [sortable]="true"
        [resizable]="true"
        [rowClass]="rowCallback"
        [pageSize]="kGridService.kGridParams.pageSize"
        [skip]="kGridService.kGridParams.skip"
        (pageChange)="kGridService.pageChange($event)"
        [pageable]="{
                              buttonCount: kGridService.kGridParams.buttonCount,
                              info: kGridService.kGridParams.info,
                              type: kGridService.kGridParams.type,
                              pageSizes: kGridService.kGridParams.pageSizes,
                              previousNext: kGridService.kGridParams.previousNext
                            }">
        <ng-template kendoGridToolbarTemplate>
          <button type="button"
            kendoGridExcelCommand
            icon="file-excel">Export to Excel</button>
        </ng-template>
        <kendo-grid-column field="one"
          title=""
          [width]="60"
          filterable="false">
          <ng-template kendoGridCellTemplate
            let-dataItem
            let-rowIndex="rowIndex">
            <button class="btn btn-xs btn-light"
              (click)="openMaintenanceEventModal(dataItem.maintenanceEventId)"><i class="fa fa-edit"></i></button>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="maintenanceDate"
          title="Date"
          [width]="80">
          <ng-template kendoGridCellTemplate
            let-dataItem
            let-rowIndex="rowIndex">
            {{dataItem.maintenanceDate | date: 'shortDate'}}
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="vendorName"
          title="Vendor"
          [width]="180">
          <ng-template kendoGridCellTemplate
            let-dataItem
            let-rowIndex="rowIndex">
            <ng-template #popoverTemplatePo>
              <div>
                <p *ngFor="let l of dataItem.purchaseOrderLines"
                  class="mb-1"> <a [routerLink]="['/purchase-orders/details', l.poid]"
                    target="_blank"
                    class="text-primary">{{l.dropDownDisplay}}</a></p>
              </div>
            </ng-template>
            {{dataItem.vendorName}}<span *ngIf="dataItem.purchaseOrderLines?.length > 0"
              class="float-right"><i class="fas fa-dollar-sign text-primary"
                type="button"
                [popover]="popoverTemplatePo"
                popoverTitle="PO Lines"
                placement="auto"
                [outsideClick]="true"
                container="body"></i></span>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="maintenanceTypeName"
          title="Maintenance Type"
          [width]="180">
        </kendo-grid-column>
        <kendo-grid-column field="hrsMileage"
          title="Hr/Mi"
          [width]="70">
        </kendo-grid-column>
        <kendo-grid-column field="notes"
          title="Notes"
          [width]="80">
          <ng-template kendoGridCellTemplate
            let-dataItem
            let-rowIndex="rowIndex">
            <ng-template #popoverTemplateNotes>
              <div>
                {{dataItem.notes}}
              </div>
            </ng-template>
            <span *ngIf="dataItem.notes"
              class="pointer"><i class="far fa-sticky-note text-primary"
                type="button"
                [popover]="popoverTemplateNotes"
                popoverTitle="Notes"
                placement="auto"
                [outsideClick]="true"
                container="body"></i></span>
            <span *ngIf="!dataItem.notes"
              class="text-muted"><i class="far fa-sticky-note"></i></span>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="userName"
          title="User"
          [width]="200">
        </kendo-grid-column>
        <kendo-grid-column field="poTotal"
          title="PO Total"
          [width]="110"
          *ngIf="seePrices">
          <ng-template kendoGridCellTemplate
            let-dataItem
            let-rowIndex="rowIndex">
            {{dataItem.poTotal | currency:'USD':true:'1.2-2'}}
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="one"
          title=""
          [width]="60"
          filterable="false">
          <ng-template kendoGridCellTemplate
            let-dataItem
            let-rowIndex="rowIndex">
            <button class="btn btn-xs btn-light"
              [swal]="{ title: 'Delete Maintenance Event?', text: dataItem.serialNumber + ' - ' + dataItem.vendorName, showCancelButton: true, confirmButtonText: 'Delete', confirmButtonColor: '#DC3545' }"
              (confirm)="deleteMaintenanceEvent($event, dataItem.maintenanceEventId)"
              [disabled]="dataItem.isActive === false"><i class="fa fa-trash"></i></button>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-excel fileName="Grizzly - Maintenance Log.xlsx"
          [fetchData]="allData">
          <kendo-excelexport-column field="maintenanceEventId"
            title="Maintenance Log Id">
          </kendo-excelexport-column>
          <kendo-excelexport-column [width]="140"
            field="maintenanceDate"
            title="Date">
          </kendo-excelexport-column>
          <kendo-excelexport-column field="vendorName"
            title="Vendor">
          </kendo-excelexport-column>
          <kendo-excelexport-column field="serialNumber"
            title="Serial #">
          </kendo-excelexport-column>
          <kendo-excelexport-column field="partNumber"
            title="Part Number">
          </kendo-excelexport-column>
          <kendo-excelexport-column field="itemDescription"
            title="Item">
          </kendo-excelexport-column>
          <kendo-excelexport-column field="maintenanceTypeName"
            title="Maintenance Type">
          </kendo-excelexport-column>
          <kendo-excelexport-column field="hrsMileage"
            title="Hrs/Mi">
          </kendo-excelexport-column>
          <kendo-excelexport-column field="notes"
            title="Notes">
          </kendo-excelexport-column>
          <kendo-excelexport-column field="userName"
            title="User">
          </kendo-excelexport-column>
        </kendo-grid-excel>
      </kendo-grid>
    </div>
  </ng-container>
</div>
