import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { AddressTypes } from 'src/enums/address-types.enum';
import { ContactTypes } from 'src/enums/contact-types.enum';
import { AddressService } from 'src/services/address.service';
import { AppStateService } from 'src/services/app-state.service';
import { ContactService } from 'src/services/contact.service';
import { CustomerService } from 'src/services/customer.service';
import { RigService } from 'src/services/rig.service';
import { UIService } from 'src/services/ui.service';
import { WellService } from 'src/services/well.service';
import { AddEditCustomerModalComponent } from '../add-edit-customer-modal/add-edit-customer-modal.component';
import { CustomerAreaService } from 'src/services/customer-area.service';

@Component({
  selector: 'app-customer-details',
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.css']
})

export class CustomerDetailsComponent implements OnInit, OnDestroy {
  public customerId: number = 1;
  public showDetails = true;
  public AddressTypes = AddressTypes;
  public ContactTypes = ContactTypes;

  constructor(
    private modalService: BsModalService,
    private activatedRoute: ActivatedRoute,
    public uiService: UIService,
    public customerService: CustomerService,
    public addressService: AddressService,
    public contactService: ContactService,
    public wellService: WellService,
    public appStateService: AppStateService,
    public rigService: RigService,
    public customerAreaService: CustomerAreaService,

  ) { };

  public bsModalRef: BsModalRef;

  private routeSub: Subscription;

  private isTabletSub: Subscription;
  public isTablet: boolean = false;

  ngOnDestroy(): void {
    this.isTabletSub?.unsubscribe();

    this.routeSub?.unsubscribe();
  }

  ngOnInit(): void {
    this.subscribeToRouteParams();

    this.addressService.refreshAddressList(
      AddressTypes.Customer,
      this.customerId,
      false);

    this.contactService.refreshContactList(
      ContactTypes.Customer,
      this.customerId,
      false);

    this.wellService.getWellsByCustomerId(
      this.customerId,
      false);

    this.rigService.getRigsByCustomerId(
      this.customerId,
      false);

    this.customerAreaService.refreshCustomerAreaList(
      this.customerId,
      false);

    this.determineIsTablet();
  }

  public determineIsTablet(): void {
    this.isTabletSub = this.appStateService.isTablet$
      .subscribe((isTablet: boolean) => {
        this.isTablet = isTablet;
      });
  }

  public subscribeToRouteParams(): void {
    this.routeSub = this.activatedRoute.params
      .subscribe((x: Params) => {
        this.customerId = parseInt(this.activatedRoute.snapshot.params['id']);
        this.customerService.refreshCustomer(this.customerId, true);
      });
  }

  public openCustomerModal(customerId: number): void {
    const initialState = {
      id: customerId
    };
    this.bsModalRef = this.modalService.show(AddEditCustomerModalComponent, { initialState, backdrop: 'static' });
  }

}
