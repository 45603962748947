import { Component, OnDestroy, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { UserModel } from 'src/models/user.model';
import { WorksheetSnapshotComparisonModel } from 'src/models/worksheet-snapshot-comparison.model';
import { AppStateService } from 'src/services/app-state.service';
import { UIService } from 'src/services/ui.service';
import { UserService } from 'src/services/user.service';
import { WorksheetService } from 'src/services/worksheet.service';

@Component({
  selector: 'app-worksheet-snapshot-comparison-modal',
  templateUrl: './worksheet-snapshot-comparison-modal.component.html',
  styleUrls: ['./worksheet-snapshot-comparison-modal.component.css']
})

export class WorksheetSnapshotComparisonModalComponent implements OnInit, OnDestroy {
  public model: WorksheetSnapshotComparisonModel;
  public now: Date = new Date();
  public jobNumber: string = null;
  public worksheetId: number = null;
  public isLoading: boolean = false;
  public userName: string = null;

  private isTabletSub: Subscription;
  public isTablet: boolean = false;

  private modelSub: Subscription;
  private userSub: Subscription;

  constructor(
    public bsModalRef: BsModalRef,
    public worksheetService: WorksheetService,
    public uiService: UIService,
    public userService: UserService,
    public appStateService: AppStateService

  ) { };

  ngOnDestroy(): void {
    this.modelSub?.unsubscribe();

    this.isTabletSub?.unsubscribe();

    this.userSub?.unsubscribe();
  }

  ngOnInit(): void {
    this.isLoading = true;
    this.getModel();
    this.getUser();
    this.determineIsTablet();
  }

  public determineIsTablet(): void {
    this.isTabletSub = this.appStateService.isTablet$
      .subscribe((isTablet: boolean) => {
        this.isTablet = isTablet;
      });
  }

  public getModel(): void {
    this.modelSub = this.worksheetService.getCurrentWorksheetAndSnapshots(this.worksheetId)
      .subscribe((x: WorksheetSnapshotComparisonModel) => {
        this.model = x;
        this.isLoading = false;
      });
  }

  public getUser(): void {
    this.userSub = this.userService.user
      .subscribe((x: UserModel) => {
        this.userName = x.userName;
      });
  }
}
