<div class="modal-header"
  ngxModalDraggable>
  <h4 class="modal-title pull-left">{{isNew ? 'Add' : 'Edit'}}&nbsp;Address</h4>
  <button type="button"
    class="close pull-right"
    aria-label="Close"
    (click)="bsModalRef.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<form (ngSubmit)="onSave()"
  #heroForm="ngForm">
  <div class="modal-body"
    *ngIf="model">
    <div class="form-group row">
      <label for="title"
        class="col-sm-2 text-right control-label col-form-label">Street Address</label>
      <div class="col-sm-4 form-group mb-0">
        <input type="text"
          class="form-control form-control-sm"
          id="streetAddressLine1"
          [(ngModel)]="model.streetAddressLine1"
          name="streetAddressLine1">
      </div>
      <label for="title"
        class="col-sm-2 text-right control-label col-form-label">Street Address 2</label>
      <div class="col-sm-4 form-group mb-0">
        <input type="text"
          class="form-control form-control-sm"
          id="streetAddressLine2"
          [(ngModel)]="model.streetAddressLine2"
          name="streetAddressLine2">
      </div>
    </div>
    <div class="form-group row">
      <label for="title"
        class="col-sm-2 text-right control-label col-form-label">City</label>
      <div class="col-sm-4 form-group mb-0">
        <input type="text"
          class="form-control form-control-sm"
          id="city"
          [(ngModel)]="model.city"
          name="city"
          required>
      </div>
      <label for="title"
        class="col-sm-2 text-right control-label col-form-label">State/Province</label>
      <div class="col-sm-4 form-group mb-0"
        *ngIf="states">
        <ng-select [items]="states"
          bindLabel="name"
          bindValue="stateId"
          [(ngModel)]="model.stateId"
          (change)="onSateChange()"
          name="states"
          required>
        </ng-select>
      </div>
    </div>
    <div class="form-group row">
      <label for="title"
        class="col-sm-2 text-right control-label col-form-label">County</label>
      <div class="col-sm-4 form-group mb-0"
        *ngIf="counties">
        <ng-select [items]="counties"
          bindLabel="name"
          bindValue="countyId"
          [ngModel]="model.countyId"
          (change)="onChangeOfCounty($event)"
          name="counties"
          [required]="model.stateId===States.NorthDakota"
          [disabled]="model.stateId!==States.NorthDakota">
        </ng-select>
      </div>
      <label for="title"
        class="col-sm-2 text-right control-label col-form-label">Zip/Postal Code</label>
      <div class="col-sm-4 form-group mb-0">
        <input type="text"
          class="form-control form-control-sm"
          id="zip"
          [(ngModel)]="model.zip"
          name="zip">
      </div>
    </div>
    <div class="form-group row">
      <label for="title"
        class="col-sm-2 text-right control-label col-form-label">Override Tax Rate</label>
      <div class="col-sm-4 form-group mb-0">
        <div class="input-group-append input-group-sm">
          <input type="number"
            step=".01"
            class="form-control form-control-sm"
            id="taxRate"
            [(ngModel)]="model.taxRate"
            name="taxRate">
          <div class="input-group-append">
            <span class="input-group-text">
              %
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>
<div class="modal-footer"
  *ngIf="model">
  <button type="button"
    class="btn btn-primary"
    (click)="onSave()"
    [disabled]="disableSave 
      || !(model.stateId 
        && model.city 
        && (model.stateId !== States.NorthDakota 
          || (model.stateId === States.NorthDakota 
            && model.countyId)))">Save</button>
  <button type="button"
    class="btn btn-default"
    (click)="bsModalRef.hide()">Cancel</button>
</div>
