import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Observer } from 'rxjs';
import { tap } from 'rxjs/operators';
import { JobSubStatuses } from 'src/enums/job-sub-statuses';
import { JobSafetyAnalysisCompletionModel } from 'src/models/job-safety-analysis-completion.model';
import { JobSafetyAnalysisCrewSignatureModel } from 'src/models/job-safety-analysis-crew-signature.model';
import { JobSafetyAnalysisHealthModel } from 'src/models/job-safety-analysis-health.model';
import { JobSafetyAnalysisJobStepModel } from 'src/models/job-safety-analysis-job-step.model';
import { JobSafetyAnalysisModel } from 'src/models/job-safety-analysis.model';

@Injectable()
export class JobSafetyAnalysisService {
  constructor(
    private http: HttpClient,
    @Inject('BASE_URL') private baseUrl: string
  ) { }

  public jsaList: BehaviorSubject<JobSafetyAnalysisModel[]> = new BehaviorSubject(null);
  public jsasAreComplete: BehaviorSubject<JobSafetyAnalysisHealthModel> = new BehaviorSubject(null);

  private apiUrl = 'api/JobSafetyAnalysis';  // URL to web api

  public addEditJobSafetyAnalysis(dto: JobSafetyAnalysisModel): Observable<boolean> {
    return new Observable((observer: Observer<boolean>) => {
      this.http.post(this.baseUrl + this.apiUrl + '/AddEditJobSafetyAnalysis', this.cleanModel(dto))
        .subscribe((x: number) => {
          if (x !== -1) {
            dto.jobSafetyAnalysisId = x;
          }

          observer.next(x !== -1);
          observer.complete();
        });
    });
  }

  public addEditJobSafetyAnalysisJobStep(dto: JobSafetyAnalysisJobStepModel): Observable<boolean> {
    return new Observable((observer: Observer<boolean>) => {
      this.http.post(this.baseUrl + this.apiUrl + '/AddEditJobSafetyAnalysisJobStep', this.cleanModel(dto))
        .subscribe((x: number) => {
          if (x !== -1 && !dto.jobSafetyAnalysisJobStepId) {
            dto.jobSafetyAnalysisJobStepId = x;
          }

          observer.next(x !== -1);
          observer.complete();
        });
    });
  }

  public addEditJobSafetyAnalysisCrewSignature(dto: JobSafetyAnalysisCrewSignatureModel): Observable<boolean> {
    return new Observable((observer: Observer<boolean>) => {
      this.http.post(this.baseUrl + this.apiUrl + '/AddEditJobSafetyAnalysisCrewSignature', this.cleanModel(dto))
        .subscribe((x: number) => {
          if (x !== -1 && !dto.jobSafetyAnalysisCrewSignatureId) {
            dto.jobSafetyAnalysisCrewSignatureId = x;
          }

          observer.next(x !== -1);
          observer.complete();
        });
    });
  }

  public createNewJobSafetyAnalysis(
    jobId: number,
    jobDate: Date,
    jobSubStatusId: number,
    lastJobDay: Date): Observable<boolean> {
    return new Observable((observer: Observer<boolean>) => {
      this.http.get(this.baseUrl + this.apiUrl + '/CreateNewJobSafetyAnalysis/' + jobId)
        .subscribe((x: boolean) => {
          if (x) {
            this.getJobSafetyAnalysisByJobId(
              jobId,
              jobDate,
              jobSubStatusId,
              lastJobDay);
          }

          observer.next(x);
          observer.complete();
        });
    });
  }

  public deleteJobSafetyAnalysis(jobSafetyAnalysisId: number): Observable<boolean> {
    return new Observable((observer: Observer<boolean>) => {
      this.http.get(this.baseUrl + this.apiUrl + '/DeleteJobSafetyAnalysis/' + jobSafetyAnalysisId)
        .subscribe((x: boolean) => {
          if (x) {
            let jsa = this.jsaList.value.find((x: JobSafetyAnalysisModel) => x.jobSafetyAnalysisId === jobSafetyAnalysisId);

            if (jsa !== undefined) {
              this.jsaList.value.splice(this.jsaList.value.indexOf(jsa), 1);
            }
          }

          observer.next(x);
          observer.complete();
        });
    });
  }

  public deleteJobSafetyAnalysisCrewSignature(crewSignatureId: number, jobSafetyAnalysisId: number): Observable<boolean> {
    let jsa = this.jsaList.value.find((x: JobSafetyAnalysisModel) => x.jobSafetyAnalysisId === jobSafetyAnalysisId);

    if (!crewSignatureId) {
      return new Observable((observer: Observer<boolean>) => {
        this.spliceCrewSignature(jsa, crewSignatureId);

        observer.next(true);
        observer.complete();
      });
    }

    return new Observable((observer: Observer<boolean>) => {
      this.http.get(this.baseUrl + this.apiUrl + '/DeleteJobSafetyAnalysisCrewSignature/' + crewSignatureId)
        .subscribe((x: boolean) => {
          if (x) {
            this.spliceCrewSignature(jsa, crewSignatureId);
          }

          observer.next(x);
          observer.complete();
        });
    });
  }

  public deleteJobSafetyAnalysisJobStep(
    jobStepId: number,
    jobSafetyAnalysisId: number): Observable<boolean> {
    let jsa = this.jsaList.value.find((x: JobSafetyAnalysisModel) => x.jobSafetyAnalysisId === jobSafetyAnalysisId);

    if (!jobStepId) {
      return new Observable((observer: Observer<boolean>) => {
        this.spliceJobStep(jsa, jobStepId);

        observer.next(true);
        observer.complete();
      });
    }

    return new Observable((observer: Observer<boolean>) => {
      this.http.get(this.baseUrl + this.apiUrl + '/DeleteJobSafetyAnalysisJobStep/' + jobStepId)
        .subscribe((x: boolean) => {
          if (x) {
            this.spliceJobStep(jsa, jobStepId);
          }

          observer.next(x);
          observer.complete();
        });
    });
  }

  public determineIfJsasAreComplete(
    jobId: number,
    jobDate: Date,
    jobSubStatusId: number,
    lastJobDay: Date): void {

    let health: JobSafetyAnalysisHealthModel = new JobSafetyAnalysisHealthModel();

    health.jobId = jobId;

    if (jobSubStatusId === JobSubStatuses.In_Progress) {
      health = this.determineCurrentNumberOfJsas(
        health,
        jobDate,
        lastJobDay);

      health = this.determineIfJsasFilledIn(health);

      health.jsasAreComplete = (health.expectedNumberOfJsas === health.numberOfJsas)
        && (health.individualJsaCompletion.every((x: JobSafetyAnalysisCompletionModel) => x.jsaIsComplete === true));
    } else {
      this.jsaList.value.forEach((jsa: JobSafetyAnalysisModel) => {
        jsa.jsaIsComplete = new JobSafetyAnalysisCompletionModel();
        jsa.jsaIsComplete.jsaIsComplete = true;
      });

      health.jsasAreComplete = true;
    }

    this.jsasAreComplete.next(health);
  }

  public getJobSafetyAnalysisByJobId(
    jobId: number,
    jobDate: Date,
    jobSubStatusId: number,
    lastJobDay: Date,
    clear: boolean = false): void {
    if (clear) {
      this.jsaList.next(null);
    }

    this.http.get<JobSafetyAnalysisModel[]>(this.baseUrl + this.apiUrl + '/GetJobSafetyAnalysisByJobId/' + jobId)
      .pipe(tap((x: JobSafetyAnalysisModel[]) => {
        x.forEach((jsa: JobSafetyAnalysisModel) => {
          jsa.date = new Date(jsa.date);
        });
      }))
      .subscribe((x: JobSafetyAnalysisModel[]) => {
        this.jsaList.next(x);

        this.determineIfJsasAreComplete(
          jobId,
          jobDate,
          jobSubStatusId,
          lastJobDay);
      });
  }

  public generateNewJobSafetyAnalysisJobStep(jobSafetyAnalysisId: number): JobSafetyAnalysisJobStepModel {
    let jobStep = new JobSafetyAnalysisJobStepModel();
    jobStep.jobSafetyAnalysisId = jobSafetyAnalysisId;
    jobStep.isActive = true;

    return jobStep;
  }

  public generateNewJobSafetyAnalysisCrewSignatureModel(jobSafetyAnalysisId: number): JobSafetyAnalysisCrewSignatureModel {
    let crewSignature = new JobSafetyAnalysisCrewSignatureModel();
    crewSignature.jobSafetyAnalysisId = jobSafetyAnalysisId;
    crewSignature.isActive = true;

    return crewSignature;
  }

  private spliceCrewSignature(jsa: JobSafetyAnalysisModel, crewSignatureId: number): void {
    if (jsa !== undefined) {
      let signature = jsa.jobSafetyAnalysisCrewSignatures.filter(
        (x: JobSafetyAnalysisCrewSignatureModel) => x.jobSafetyAnalysisCrewSignatureId === crewSignatureId);

      if (signature.length > 0) {
        jsa.jobSafetyAnalysisCrewSignatures.splice(jsa.jobSafetyAnalysisCrewSignatures.indexOf(signature[0]), 1);
      }
    }
  }

  private spliceJobStep(jsa: JobSafetyAnalysisModel, jobStepId: number): void {
    if (jsa !== undefined) {
      let jobStep = jsa.jobSafetyAnalysisJobSteps.filter((x: JobSafetyAnalysisJobStepModel) => x.jobSafetyAnalysisJobStepId === jobStepId);

      if (jobStep.length > 0) {
        jsa.jobSafetyAnalysisJobSteps.splice(jsa.jobSafetyAnalysisJobSteps.indexOf(jobStep[0]), 1);
      }
    }
  }

  private cleanModel(model: any): any {
    Object.keys(model).forEach((index: string) => {
      if (typeof model[index] === 'string') {
        model[index] = model[index]
          && model[index].trim() !== '' ?
          model[index].trim()
          : null;
      }

      if (Array.isArray(model[index])) {
        model[index].forEach((item: any) => {
          this.cleanModel(item);
        });
      }
    });

    return model;
  }

  private determineIfJsasFilledIn(model: JobSafetyAnalysisHealthModel): JobSafetyAnalysisHealthModel {
    if (this.jsaList.value
      && this.jsaList.value.length > 0) {

      this.jsaList.value.forEach((jsa: JobSafetyAnalysisModel, index: number) => {
        let jsasFilledIn: boolean = false;

        let completeModel: JobSafetyAnalysisCompletionModel = new JobSafetyAnalysisCompletionModel();

        if (!jsa.jobDescription || jsa.jobDescription.trim() === '') {
          completeModel.errors.push('Job Description is required.');
        }

        if (!jsa.userId) {
          completeModel.errors.push('Employee is required.');
        }

        if (!jsa.assessorSignature || jsa.assessorSignature.trim() === '') {
          completeModel.errors.push('Assessor Signature is required.');
        }

        if (!jsa.date) {
          completeModel.errors.push('Date is required.');
        }

        if (!jsa.musterArea || jsa.musterArea.trim() === '') {
          completeModel.errors.push('Muster Area is required.');
        }

        if (this.determineIfJobStepsAreFilledIn(jsa.jobSafetyAnalysisJobSteps) === false) {
          completeModel.errors.push('Job Steps are not complete.');
        }

        if (this.determineIfCrewSignaturesAreFilledIn(jsa.jobSafetyAnalysisCrewSignatures) === false) {
          completeModel.errors.push('Crew Signatures are not complete.');
        }

        if (completeModel.errors.length === 0) {
          jsasFilledIn = true;
        }

        completeModel.jsaDate = new Date(jsa.date);
        completeModel.jsaId = jsa.jobSafetyAnalysisId;
        completeModel.jsaNumber = index + 1;

        completeModel.jsaIsComplete = jsasFilledIn;

        jsa.jsaIsComplete = completeModel;

        model.individualJsaCompletion.push(completeModel);
      });
    } else {
      model.jsasAreComplete = false;
    }

    return model;
  }

  private determineIfCrewSignaturesAreFilledIn(jobSafetyAnalysisCrewSignatures: JobSafetyAnalysisCrewSignatureModel[]): boolean {
    if (jobSafetyAnalysisCrewSignatures
      && jobSafetyAnalysisCrewSignatures.length > 0) {
      let crewSignaturesFilledIn: boolean = true;

      jobSafetyAnalysisCrewSignatures.forEach((crewSignature: JobSafetyAnalysisCrewSignatureModel) => {
        if (
          (!crewSignature.name || crewSignature.name.trim() === '')
        ) {
          crewSignaturesFilledIn = false;
        }
      });

      return crewSignaturesFilledIn;
    }

    return false;
  }

  private determineIfJobStepsAreFilledIn(jobSafetyAnalysisJobSteps: JobSafetyAnalysisJobStepModel[]): boolean {
    if (jobSafetyAnalysisJobSteps
      && jobSafetyAnalysisJobSteps.length > 0) {
      let jobStepsFilledIn: boolean = true;

      jobSafetyAnalysisJobSteps.forEach((jobStep: JobSafetyAnalysisJobStepModel) => {
        if (
          (!jobStep.basicJobSteps || jobStep.basicJobSteps.trim() === '')
          || (!jobStep.potentialRisksHazards || jobStep.potentialRisksHazards.trim() === '')
          || (!jobStep.actionsTakenToReduce || jobStep.actionsTakenToReduce.trim() === '')
        ) {
          jobStepsFilledIn = false;
        }
      });

      return jobStepsFilledIn;
    }

    return false;
  }

  private determineCurrentNumberOfJsas(
    model: JobSafetyAnalysisHealthModel,
    jobDate: Date,
    lastJobDay: Date): JobSafetyAnalysisHealthModel {
    if (this.jsaList.value
      && this.jsaList.value.length > 0) {
      model.expectedNumberOfJsas = this.determineExpectedNumberOfJsas(jobDate, lastJobDay);
      model.numberOfJsas = this.jsaList.value.length;
    } else {
      model.expectedNumberOfJsas = this.determineExpectedNumberOfJsas(jobDate, lastJobDay);
      model.numberOfJsas = 0;
    }

    return model;
  }

  private determineExpectedNumberOfJsas(jobDate: Date, lastWorkDay: Date): number {
    const lastWorkDate: Date = new Date(lastWorkDay.getFullYear(), lastWorkDay.getMonth(), lastWorkDay.getDate());
    const jobDateDate: Date = new Date(jobDate.getFullYear(), jobDate.getMonth(), jobDate.getDate());

    const timestamp1: number = new Date(lastWorkDate).getTime();
    const timestamp2: number = new Date(jobDateDate).getTime();

    const timeDiff: number = Math.abs(timestamp1 - timestamp2);

    const millisecondsInADay: number = 1000 * 60 * 60 * 24;
    const numberOfDays: number = Math.floor(timeDiff / millisecondsInADay);

    const calculatedNumOfJsas: number = Math.floor(numberOfDays / 7) + 1;

    return calculatedNumOfJsas;
  }

}
