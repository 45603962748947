import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Observer } from 'rxjs';
import { Shops } from 'src/enums/shops';
import { WarehouseModel } from 'src/models/warehouse.model';

@Injectable()
export class WarehouseService {
  constructor(
    private http: HttpClient,
    @Inject('BASE_URL') private baseUrl: string
  ) { }

  public whList: BehaviorSubject<Array<WarehouseModel>> = new BehaviorSubject(null);
  public whByShopList: BehaviorSubject<Array<WarehouseModel>> = new BehaviorSubject(null);

  private apiUrl = 'api/Warehouse';  // URL to web api

  public addEditWarehouse(dto: WarehouseModel): Observable<boolean> {
    dto = this.cleanWarehouseModel(dto);

    return new Observable((observer: Observer<boolean>) => {
      this.http.post<boolean>(this.baseUrl + this.apiUrl + '/AddEditWarehouse', dto)
        .subscribe((x: boolean) => {
          if (x === true) {
            this.getWarehouses();
          }
          observer.next(x);
        });
    });
  }

  public deleteWarehouse(id: number): Observable<boolean> {
    return new Observable((observer: Observer<boolean>) => {
      this.http.get<boolean>(this.baseUrl + this.apiUrl + '/DeleteWarehouse/' + id)
        .subscribe((x: boolean) => {
          if (x === true) {
            this.getWarehouses();
          }
          observer.next(x);
        });
    });
  }

  public getWarehouseById(id: number): Observable<WarehouseModel> {
    return this.http.get<WarehouseModel>(this.baseUrl + this.apiUrl + '/GetWarehouseById/' + id);
  }

  public getWarehousesByShopId(shopId: number, showInactive: boolean = false): void {
    this.http.get<WarehouseModel[]>(this.baseUrl + this.apiUrl + '/GetWarehousesByShopId/' + shopId + '/' + showInactive)
      .subscribe((x: WarehouseModel[]) => {
        this.whList.next(x);
      });
  }

  public getWarehouses(showInactive: boolean = false): void {
    this.http.get<WarehouseModel[]>(this.baseUrl + this.apiUrl + '/GetWarehouses/' + showInactive)
      .subscribe((x: WarehouseModel[]) => {
        this.whList.next(x);
      });
  }

  public generateNewWarehouseModel(): WarehouseModel {
    let model: WarehouseModel = new WarehouseModel();

    model.isActive = true;
    model.shopId = Shops.Williston;

    return model;
  }

  private cleanWarehouseModel(model: WarehouseModel): WarehouseModel {
    Object.keys(model).forEach((index: string) => {
      if (typeof model[index] === 'string') {
        model[index] = model[index]
          && model[index].trim() !== '' ?
          model[index].trim()
          : null;
      }
    });

    return model;
  }

}
