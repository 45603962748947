import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { InvoiceModel } from '../models/invoice.model';
import { JobService } from './job.service';
import { tap } from 'rxjs/operators';

@Injectable()
export class InvoiceService {
  constructor(
    private http: HttpClient,
    @Inject('BASE_URL') private baseUrl: string,
    private jobService: JobService
  ) { }

  public invoiceList: BehaviorSubject<InvoiceModel[]> = new BehaviorSubject(null);

  private apiUrl = 'api/Invoice';  // URL to web api

  public getActiveInvoicesByJobId(jobId: number): Observable<InvoiceModel[]> {
    return this.http.get<InvoiceModel[]>(this.baseUrl + this.apiUrl + '/GetActiveInvoicesByJobId/' + jobId)
      .pipe(tap((x: InvoiceModel[]) => {
        x.forEach((inv: InvoiceModel) => {
          inv.dropdownDisplay = `Invoice #${inv.invoiceNumber}`;
        });
      }));
  }

  public refreshInvoiceList(jobId: number) {
    this.getActiveInvoicesByJobId(jobId)
      .subscribe((x: InvoiceModel[]) => {
        this.invoiceList.next(x);
      });
  }
}
