import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-list-skeleton',
  templateUrl: './list-skeleton.component.html',
  styleUrls: ['./list-skeleton.component.scss']
})

export class ListSkeletonComponent implements OnInit {
  iteratorArray = Array;
  @Input() numberToIterate = 10;

  constructor() { }

  ngOnInit(): void {
  }

}
