import { BehaviorSubject, Observable, Observer } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { JobTypeModel } from '../models/job-type.model';
import { tap } from 'rxjs/operators';

@Injectable()
export class JobTypeService {
  constructor(
    private http: HttpClient,
    @Inject('BASE_URL') private baseUrl: string
  ) { }

  private apiUrl = 'api/JobType';  // URL to web api

  private jobTypeListSubject = new BehaviorSubject<JobTypeModel[]>(null);
  public _jobTypeList$ = this.jobTypeListSubject.asObservable();
  private isJobTypeCallInProgress = false;
  public jobTypeList: BehaviorSubject<JobTypeModel[]> = new BehaviorSubject(null);

  public get jobTypeList$(): Observable<JobTypeModel[]> {
    if (!this.jobTypeListSubject.getValue() && !this.isJobTypeCallInProgress) {
      this.isJobTypeCallInProgress = true;
      this.getJobTypes$(false).subscribe(() => this.isJobTypeCallInProgress = false);
    }

    return this._jobTypeList$;
  }

  public addEditJobType(dto: JobTypeModel, showInactive: boolean = false): Observable<boolean> {
    dto = this.cleanJobTypeModel(dto);

    return new Observable((observer: Observer<boolean>) => {
      this.http.post<boolean>(this.baseUrl + this.apiUrl + '/AddEditJobType', dto)
        .subscribe((x: boolean) => {
          if (x === true) {
            this.getJobTypes(showInactive, true);
          }
          observer.next(x);
        });
    });
  }

  public deleteJobType(id: number, showInactive: boolean = false): Observable<boolean> {
    return new Observable((observer: Observer<boolean>) => {
      this.http.get<boolean>(this.baseUrl + this.apiUrl + '/DeleteJobType/' + id)
        .subscribe((x: boolean) => {
          if (x === true) {
            this.getJobTypes(showInactive, true);
          }
          observer.next(x);
        });
    });
  }

  public getJobTypeById(id: number): Observable<JobTypeModel> {
    return this.http.get<JobTypeModel>(this.baseUrl + this.apiUrl + '/GetJobTypeById/' + id);
  }

  public getJobTypes(showInactive: boolean = false, showAll: boolean = false): void {
    this.http.get<JobTypeModel[]>(this.baseUrl + this.apiUrl + '/GetJobTypes/' + showInactive + '/' + showAll)
      .subscribe((x: JobTypeModel[]) => {
        this.jobTypeList.next(x);
      });
  }

  public generateJobTypeModel(): JobTypeModel {
    let model: JobTypeModel = new JobTypeModel();

    model.isActive = true;
    model.displayInDropdown = true;

    return model;
  }

  private cleanJobTypeModel(model: JobTypeModel): JobTypeModel {
    Object.keys(model).forEach((index: string) => {
      if (typeof model[index] === 'string') {
        model[index] = model[index]
          && model[index].trim() !== '' ?
          model[index].trim()
          : null;
      }
    });

    return model;
  }

  private getJobTypes$(showInactive: boolean): Observable<JobTypeModel[]> {
    return this.http.get<Array<JobTypeModel>>(this.baseUrl + this.apiUrl + '/GetJobTypes/' + showInactive + '/false')
      .pipe(
        tap((jobTypes: JobTypeModel[]) => {
          this.jobTypeListSubject.next(jobTypes);
        })
      );
  }

}
