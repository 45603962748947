import { Component, OnDestroy, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { BhaModel } from 'src/models/bha.model';
import { BhaService } from 'src/services/bha.service';
import { EmployeeService } from 'src/services/employee.service';
import { UIService } from 'src/services/ui.service';

@Component({
  selector: 'app-add-edit-bha-modal',
  templateUrl: './add-edit-bha-modal.component.html',
  styleUrls: ['./add-edit-bha-modal.component.css']
})

export class AddEditBhaModalComponent implements OnInit, OnDestroy {
  public isNew: boolean = false;
  public id: number = 0;
  public dayId: number = 0;
  public jobOwnerId: number = 0;
  public jobId: number = 0;

  public disableSave: boolean = false;
  public saveSub: Subscription;
  public newModelSub: Subscription;
  public modelSub: Subscription;
  public model: BhaModel;

  constructor(
    public bsModalRef: BsModalRef,
    public bsModalRefChildModal: BsModalRef,
    public employeeService: EmployeeService,
    public uiService: UIService,
    public bhaService: BhaService
  ) { };

  ngOnDestroy(): void {
    this.modelSub?.unsubscribe();

    this.newModelSub?.unsubscribe();

    this.saveSub?.unsubscribe();
  }

  ngOnInit() {
    this.employeeService.getEmployees(false, true);
    this.isNew = this.id === 0;

    if (this.isNew) {
      this.generateNewBha();
    } else {
      this.getModel();
    }
  }

  public generateNewBha(): void {
    this.newModelSub = this.bhaService.generateNewBhaModel(this.jobId)
      .subscribe((x: BhaModel) => {
        this.model = x;
      });
  }

  public getModel(): void {
    this.modelSub = this.bhaService.getBhaById(this.id)
      .subscribe((x: BhaModel) => {
        this.model = x;
      });
  }

  public onSave(): void {
    this.disableSave = true;

    this.saveSub = this.bhaService.addEditBha(this.model, true)
      .subscribe((x: boolean) => {
        if (x) {
          this.bsModalRef.hide();

          this.uiService.showSuccess('BHA Saved', 'Success');
        } else {
          this.uiService.showError('BHA Not Saved', 'Error');

          this.disableSave = false;
        }
      });
  }

}
