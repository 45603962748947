<div class="card border-top-primary border-top-2 rounded-top-0"
  style="border-top-color: #2196F3 !important; overflow: auto;">
  <div class="card-header bg-light header-elements-sm-inline">
    <h6 class="card-title">Serialized Inventory</h6>
    <div class="header-elements">
      <div class="switch-container pt-2 mr-2">
        <span class="switch-label">Show Scrap </span>
        <ui-switch [ngModel]="showScrap"
          (change)="onChangeScrap($event)"></ui-switch>
      </div>
      <button class="btn btn-light btn-sm"
        (click)="openAddEditItemInstanceModal(0, itemService.item.value.itemId)">
        <i class="fa fa-plus"></i>&nbsp; Add Serialized Inventory
      </button>
    </div>
  </div>
  <div class="table-responsive"
    *ngIf="itemInstanceService.itemInstanceByIdList | async; let model">
    <div class="table-responsive"
      *ngIf="model.length === 0">
      <div class="alert alert-primary ml-3 mr-3 mt-3"
        role="alert">
        <i class="fad fa-check"></i>&nbsp;&nbsp;There is currently no <strong>Serialized Inventory.</strong>
      </div>
    </div>
    <kendo-grid *ngIf="model.length > 0"
      [kendoGridBinding]="model"
      filterable="false"
      [sortable]="true"
      [resizable]="true"
      [rowClass]="rowCallback"
      [pageSize]="kGridParams.pageSize"
      [skip]="kGridParams.skip"
      (pageChange)="pageChange($event)"
      [pageable]="{
                      buttonCount: kGridParams.buttonCount,
                      info: kGridParams.info,
                      type: kGridParams.type,
                      pageSizes: kGridParams.pageSizes,
                      previousNext: kGridParams.previousNext
                    }">
      <kendo-grid-column field="one"
        title=""
        [width]="95"
        filterable="false">
        <ng-template kendoGridCellTemplate
          let-dataItem
          let-rowIndex="rowIndex">
          <a class="btn btn-xs btn-light mr-2"
            [routerLink]="['/item-instances/details', dataItem.itemInstanceId]"><i class="fa fa-search"
              title="Go To Item Instance"></i></a>
          <button class="btn btn-xs btn-light"
            (click)="openAddEditItemInstanceModal(dataItem.itemInstanceId, itemService.item.value.itemId)"><i
              class="fa fa-edit"></i></button>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column field="serialNumber"
        title="Serial Number"
        [width]="105">
      </kendo-grid-column>
      <kendo-grid-column field="itemInstanceLocation"
        title="Status">
        <ng-template kendoGridCellTemplate
          let-dataItem
          let-rowIndex="rowIndex">
          <span *ngIf="dataItem.itemInstanceLocationId === ItemInstanceLocations.OnJob">{{dataItem.itemInstanceLocation
            + ' #' + dataItem.jobNumber}}</span>
          <span
            *ngIf="dataItem.itemInstanceLocationId !== ItemInstanceLocations.OnJob">{{dataItem.itemInstanceLocation}}</span>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column field="shopName"
        title="Shop">
      </kendo-grid-column>
      <kendo-grid-column field="threadTypeName"
        title="Thread Type"
        filterable="false"
        [width]="90">
      </kendo-grid-column>
      <kendo-grid-column field="numberOfBlades"
        title="# of Blades"
        filterable="false"
        [width]="90"
        *ngIf="partGroupId === PartGroups.BladedMills">
      </kendo-grid-column>
      <kendo-grid-column field="innerDiameter"
        title="ID"
        filterable="false"
        [width]="70">
        <ng-template kendoGridCellTemplate
          let-dataItem
          let-rowIndex="rowIndex">
          {{dataItem.innerDiameter}}<span *ngIf="dataItem.innerDiameter!==null">"</span>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column field="outerDiameter"
        title="OD"
        filterable="false"
        [width]="70">
        <ng-template kendoGridCellTemplate
          let-dataItem
          let-rowIndex="rowIndex">
          {{dataItem.outerDiameter}}<span *ngIf="dataItem.outerDiameter!==null">"</span>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column field="length"
        title="Length"
        filterable="false"
        [width]="80">
        <ng-template kendoGridCellTemplate
          let-dataItem
          let-rowIndex="rowIndex">
          {{dataItem.length}}<span *ngIf="dataItem.length!==null">'</span>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column field="length"
        title="Cost"
        filterable="false"
        [width]="80">
        <ng-template kendoGridCellTemplate
          let-dataItem
          let-rowIndex="rowIndex">
          {{dataItem.originalCost | currency}}
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column field="one"
        title=""
        [width]="60"
        filterable="false">
        <ng-template kendoGridCellTemplate
          let-dataItem
          let-rowIndex="rowIndex">
          <button class="btn btn-xs btn-light"
            [swal]="{ title: 'Delete Serialized Item?', text: 'Serial #' + dataItem.serialNumber, showCancelButton: true, confirmButtonText: 'Delete', confirmButtonColor: '#DC3545' }"
            (confirm)="deleteItemInstance(dataItem.itemInstanceId, dataItem.itemId)"
            [disabled]="dataItem.isActive === false || dataItem.allowDelete === false"><i
              class="fa fa-trash"></i></button>
        </ng-template>
      </kendo-grid-column>
    </kendo-grid>
  </div>
</div>
