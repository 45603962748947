<div class="modal-header"
  ngxModalDraggable>
  <h4 class="modal-title pull-left"
    *ngIf="model">{{isNew ? 'Add' : 'Edit'}}&nbsp;Billing Code</h4>
  <button type="button"
    class="close pull-right"
    aria-label="Close"
    (click)="bsModalRef.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body"
  *ngIf="model">
  <div class="form-group row"
    *ngIf="isInventoryItem&&!launchedFromList">
    <label for="title"
      class="col-sm-2 text-right control-label col-form-label">Item</label>
    <div class="col-sm-10 form-group mb-0"
      *ngIf="itemService.itemDropdownList | async; let item">
      <ng-select [items]="item"
        bindLabel="dropdownDisplay"
        bindValue="itemId"
        [(ngModel)]="model.itemId"
        (change)="onChangeOfItem($event)"
        name="items"
        required>
      </ng-select>
    </div>
  </div>
  <div class="form-group row"
    *ngIf="launchedFromList">
    <label for="title"
      class="col-sm-2 text-right control-label col-form-label">Item</label>
    <div class="col-sm-10 form-group mb-0"
      *ngIf="itemService.itemDropdownList | async; let item">
      <ng-select [items]="item"
        bindLabel="dropdownDisplay"
        bindValue="itemId"
        [(ngModel)]="model.itemId"
        (change)="onChangeOfItem($event)"
        name="items">
      </ng-select>
    </div>
  </div>
  <div class="form-group row"
    *ngIf="!model.itemId">
    <label for="title"
      class="col-sm-2 text-right control-label col-form-label">Name</label>
    <div class="col-sm-10 form-group mb-0">
      <input type="text"
        class="form-control form-control-sm"
        id="name"
        [(ngModel)]="model.name"
        name="name"
        required>
    </div>
  </div>
  <div class="form-group row"
    *ngIf="!launchedFromWorksheet">
    <label for="title"
      class="col-sm-2 text-right control-label col-form-label">Customer</label>
    <div class="col-sm-10 form-group mb-0"
      *ngIf="customerService.customerList | async; let cust">
      <ng-select [items]="cust"
        bindLabel="name"
        bindValue="customerId"
        [(ngModel)]="model.customerId"
        name="cust">
      </ng-select>
    </div>
  </div>
  <div class="form-group row">
    <label for="title"
      class="col-sm-2 text-right control-label col-form-label">UOM</label>
    <div class="col-sm-4 form-group mb-0"
      *ngIf="unitsOfMeasure">
      <ng-select [items]="unitsOfMeasure"
        bindLabel="name"
        bindValue="uomId"
        [(ngModel)]="model.uomId"
        (change)="onChangeOfUom($event)"
        name="uom"
        required>
      </ng-select>
    </div>
    <label for="title"
      class="col-sm-2 text-right control-label col-form-label">Quantity</label>
    <div class="col-sm-4 form-group mb-0">
      <input type="number"
        step="1"
        class="form-control form-control-sm"
        id="price"
        [(ngModel)]="model.quantity"
        name="quantity"
        required>
    </div>
  </div>
  <div class="form-group row">
    <label for="title"
      class="col-sm-2 text-right control-label col-form-label">Rate</label>
    <div class="col-sm-4 form-group mb-0">
      <div class="input-group input-group-sm">
        <div class="input-group-prepend">
          <span class="input-group-text">$</span>
        </div>
        <input type="number"
          class="form-control form-control-sm"
          id="rate"
          [(ngModel)]="model.rate"
          name="rate"
          required>
      </div>
    </div>
    <label for="title"
      class="col-sm-2 text-right control-label col-form-label">Is Taxable</label>
    <div class="col-sm-4 form-group mb-0">
      <ui-switch [(ngModel)]="model.isTaxable"
        name="isTaxable"></ui-switch>
    </div>
  </div>
  <div class="form-group row">
    <label for="title"
      class="col-sm-2 text-right control-label col-form-label">Day 1 Rental</label>
    <div class="col-sm-4 form-group mb-0">
      <ui-switch [(ngModel)]="model.dayOneRental"
        name="dayOneRental"
        [disabled]="model.uomId !== UnitsOfMeasure.Ft && model.uomId !== UnitsOfMeasure.Day"></ui-switch>
    </div>
    <label for="title"
      class="col-sm-2 text-right control-label col-form-label">Additional Day Rental</label>
    <div class="col-sm-4 form-group mb-0">
      <ui-switch [(ngModel)]="model.additionalDayRental"
        name="additionalDayRental"
        [disabled]="model.uomId !== UnitsOfMeasure.Ft && model.uomId !== UnitsOfMeasure.Day"></ui-switch>
    </div>
  </div>
</div>
<div class="modal-footer"
  *ngIf="model">
  <button type="button"
    class="btn btn-primary"
    (click)="onSave()"
    [disabled]="disableSave 
      || !(model.rate!==null 
        && model.quantity 
        && model.uomId 
        && ((!model.itemId 
          && model.name) 
          || model.itemId) 
        && (!isInventoryItem 
          || (isInventoryItem 
            && !launchedFromList 
            && model.itemId)))">Save</button>
  <button type="button"
    class="btn btn-default"
    (click)="bsModalRef.hide()">Cancel</button>
</div>
