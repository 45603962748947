import { Component, OnDestroy, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { ItemTypes } from 'src/enums/item-types';
import { UnitsOfMeasure } from 'src/enums/units-of-measure';
import { ItemTypeModel } from 'src/models/item-type.model';
import { ItemModel } from 'src/models/item.model';
import { PartFamilyModel } from 'src/models/part-family.model';
import { UnitOfMeasureModel } from 'src/models/unit-of-measure.model';
import { WorksheetTypeModel } from 'src/models/worksheet-type.model';
import { ItemTypeService } from 'src/services/item-type.service';
import { ItemVendorService } from 'src/services/item-vendor.service';
import { ItemService } from 'src/services/item.service';
import { PartFamilyService } from 'src/services/part-family.service';
import { PartGroupService } from 'src/services/part-group.service';
import { PurchaseOrderService } from 'src/services/purchase-order.service';
import { UIService } from 'src/services/ui.service';
import { UnitOfMeasureService } from 'src/services/unit-of-measure.service';
import { VendorService } from 'src/services/vendor.service';
import { WorksheetTypeService } from 'src/services/worksheet-type.service';

@Component({
  selector: 'app-add-edit-item-modal',
  templateUrl: './add-edit-item-modal.component.html',
  styleUrls: ['add-edit-item-modal.component.css']
})

export class AddEditItemModalComponent implements OnInit, OnDestroy {
  private unitsOfMeasureSub: Subscription;
  public unitsOfMeasure: UnitOfMeasureModel[];

  private itemTypesSub: Subscription;
  public itemTypes: ItemTypeModel[];

  private modelSub: Subscription;
  public model: ItemModel;

  private buildsSub: Subscription;
  public builds: WorksheetTypeModel[] = [];

  private saveSub: Subscription;

  public isNew: boolean = false;
  public id: number = -1;
  public showInactive: boolean = false;
  public launchedFromModal: boolean = false;
  public disableAddRow: boolean = false;
  public showThirdPartRental: boolean = false;
  public refreshDropdown: boolean = false;
  public ItemTypes = ItemTypes;
  public UnitsOfMeasure = UnitsOfMeasure;
  public disableSave: boolean = false;

  constructor(
    public bsModalRef: BsModalRef,
    public itemService: ItemService,
    public itemTypeService: ItemTypeService,
    public vendorService: VendorService,
    public uomService: UnitOfMeasureService,
    public partFamilyService: PartFamilyService,
    public partGroupService: PartGroupService,
    public poService: PurchaseOrderService,
    public uiService: UIService,
    public itemVendorService: ItemVendorService,
    public worksheetTypeService: WorksheetTypeService
  ) { };

  ngOnDestroy(): void {
    this.buildsSub?.unsubscribe();

    this.modelSub?.unsubscribe();

    this.itemTypesSub?.unsubscribe();

    this.saveSub?.unsubscribe();

    this.unitsOfMeasureSub?.unsubscribe();
  }

  ngOnInit(): void {
    this.getItemTypes();

    this.vendorService.getVendors(false, true);

    this.getUnitsOfMeasure();

    this.partFamilyService.refreshPartFamilyList(
      false,
      true,
      false);

    this.showInactive = this.showInactive === true;
    this.isNew = this.id === 0;

    if (this.isNew) {
      this.partGroupService.partGroupList.next(null);
      this.model = this.itemService.generateNewItemModel();

      if (this.launchedFromModal === true) {
        this.model.vendorId = this.poService.purchaseOrder.value !== null
          && this.poService.purchaseOrder.value !== undefined ? this.poService.purchaseOrder.value.vendorId : null;
      }
    } else {
      this.getModel();
    }
  }

  public getUnitsOfMeasure(): void {
    this.unitsOfMeasureSub = this.uomService.uomList$
      .subscribe((unitsOfMeasure: UnitOfMeasureModel[]) => {
        if (unitsOfMeasure) {
          this.unitsOfMeasure = unitsOfMeasure;
        }
      });
  }

  public getItemTypes(): void {
    this.itemTypesSub = this.itemTypeService.itemTypeList$
      .subscribe((itemTypes: ItemTypeModel[]) => {
        if (itemTypes) {
          this.itemTypes = itemTypes;
        }
      });
  }

  public getModel(): void {
    this.modelSub = this.itemService.getItemById(this.id)
      .subscribe((x: ItemModel) => {
        if (x.partFamilyId !== null && x.partFamilyId !== undefined) {
          this.partGroupService.getPartGroupsByFamilyId(
            x.partFamilyId,
            false,
            true);
        }

        if (x.itemTypeId === this.ItemTypes.Build) {
          this.getBuilds();
        }

        this.model = x;
      });
  }

  public getBuilds(): void {
    this.buildsSub = this.worksheetTypeService.getWorksheetTypes(
      false,
      false,
      this.isNew)
      .subscribe((x: WorksheetTypeModel[]) => {
        this.builds = x;
      });
  }

  public onChangePartFamily($event: PartFamilyModel): void {
    this.model.partGroupId = null;

    if ($event !== undefined && $event !== null) {
      this.partGroupService.getPartGroupsByFamilyId($event.partFamilyId, false, true);
    } else {
      this.partGroupService.partGroupList.next(null);
    }
  }

  public onChangeOfItemType($event: ItemTypeModel) {
    if ($event !== undefined) {
      this.model.returnToInvAfterFishing = false;
      this.model.bodyInspection = false;
      this.model.rodInspection = false;
      this.model.chargeForRunsAfterScrap = false;
      this.model.vendorId = $event.itemTypeId === this.ItemTypes.Build ? null : this.model.vendorId;
      this.model.uomId = $event.itemTypeId === this.ItemTypes.Build ? this.UnitsOfMeasure.Each : this.model.uomId;

      if ($event.itemTypeId === this.ItemTypes.Build) {
        if (this.builds.length === 0) {
          this.getBuilds();
        }
        if (this.model.worksheetTypeId) {
          const wt: WorksheetTypeModel[] = this.builds.filter((b: WorksheetTypeModel) => b.worksheetTypeId === this.model.worksheetTypeId);

          this.model.description = wt.length === 1 ? wt[0].name : this.model.description;
        }
      }
    }
  }

  public onChangeReturnAfterFishing($event: boolean): void {
    if ($event !== undefined) {
      this.model.returnToInvAfterFishing = $event;
    }
  }

  public onChangeRodInspection($event: boolean): void {
    if ($event !== undefined) {
      this.model.rodInspection = $event;
    }
  }

  public onChangeChargeForRunsAfterScrap($event: boolean): void {
    if ($event !== undefined) {
      this.model.chargeForRunsAfterScrap = $event;
    }
  }

  public addRow(): void {
    this.model.itemVendor = [...this.model.itemVendor, this.itemVendorService.generateNewItemVendorModel(this.model.itemId)];
    this.checkDisableAddRow();
  }

  public deleteRow(index: number): void {
    this.model.itemVendor.splice(index, 1);
  }

  public checkDisableAddRow(): void {
    if (this.model.itemVendor !== undefined && this.model.itemVendor !== null && this.model.itemVendor.length > 0) {
      for (let x of this.model.itemVendor) {
        let temp: boolean = x.itemId === undefined ||
          x.itemId === null ||
          x.vendorId === undefined ||
          x.vendorId === null;
        this.disableAddRow = temp;
        if (temp === true) {
          break;
        }
      }
    }
  }

  public blurThis($event: any): void {
    if ($event && $event.target) {
      $event.target.blur();
    }
  }

  public onChangeOfBuild($event: WorksheetTypeModel): void {
    if ($event !== undefined) {
      this.model.description = $event.name;
    }
  }

  public onSave(): void {
    this.disableSave = true;

    if (this.model.itemTypeId === this.ItemTypes.Rental || this.model.itemTypeId === this.ItemTypes.ThirdParty) {
      // NEW INVENTORY DO SOMETHING WITH THIS
    } else if (this.model.itemTypeId === this.ItemTypes.Sale) {
      this.model.numberOfConnections = null;
      this.model.bodyInspection = false;
      this.model.minNumDays = null;
    }

    this.saveSub = this.itemService.addEditItem(
      this.model,
      this.showInactive,
      this.launchedFromModal,
      false,
      this.refreshDropdown)
      .subscribe((x: boolean) => {
        if (x) {
          this.bsModalRef.hide();

          this.uiService.showSuccess('Item Saved', 'Success');
        } else {
          this.uiService.showError('Item Not Saved', 'Error');

          this.disableSave = false;
        }
      });
  }

}
