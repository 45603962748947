<button class="btn btn-sm btn-light"
  *ngIf="jobSafetyAnalysisService.jsaList.value && jobSafetyAnalysisService.jsaList.value.length > 0"
  style="float: right;"
  [swal]="{ title: 'Add JSA?', text: 'The system will automatically produce a JSA for every 7-day period.', showCancelButton: true, confirmButtonText: 'Create', confirmButtonColor: '#1B6EC2' }"
  (confirm)="addNewJsa()"
  [disabled]="jobService.job.value.invoiceHasBeenSplit
  || (jobService.job.value !== null 
    && jobService.job.value.jobStatusIsTerminal === true )
  || (jobSubStatusId !== JobSubStatuses.In_Progress
    && !editJsa)"><i class="fas fa-plus"></i> New JSA</button>
<button class="btn btn-light btn-sm mr-1"
  *ngIf="jobSafetyAnalysisService.jsaList.value && jobSafetyAnalysisService.jsaList.value.length > 0"
  style="float: right;"
  (click)="reportService.getJsaPDF(jobId)"
  [disabled]="false"><i class="fal fa-file-pdf"></i> Print JSA's</button>
<div class="d-flex flex-row-reverse mb-3"
  *ngIf="!jobSafetyAnalysisService.jsaList.value || jobSafetyAnalysisService.jsaList.value.length === 0">
  <button class="btn btn-sm btn-light"
    style="float: right;"
    [swal]="{ title: 'Add JSA?', text: 'The system will automatically produce a JSA for every 7-day period.', showCancelButton: true, confirmButtonText: 'Create', confirmButtonColor: '#1B6EC2' }"
    (confirm)="addNewJsa()"
    [disabled]="jobService.job.value.invoiceHasBeenSplit
      || (jobService.job.value !== null 
        && jobService.job.value.jobStatusIsTerminal === true )
      || (jobSubStatusId !== JobSubStatuses.In_Progress
        && !editJsa)"><i class="fas fa-plus"></i> New JSA</button>
</div>
<div class="alert alert-primary mb-0"
  role="alert"
  *ngIf="!jobSafetyAnalysisService.jsaList.value || jobSafetyAnalysisService.jsaList.value.length === 0">
  No <strong>JSAs</strong> added for Job#: {{jobNumber}}.
</div>
<tabset type="pills"
  class="prevent-tabset-default"
  *ngIf="jobSafetyAnalysisService.jsaList.value && jobSafetyAnalysisService.jsaList.value.length > 0">
  <tab id="jsaTab_{{i}}"
    *ngFor="let jsa of jobSafetyAnalysisService.jsaList.value; let i = index;"
    (selectTab)="tabSelect($event)"
    [active]="'jsaTab_' + i === activeTabId"
    [customClass]="pill-incomplete">
    <ng-template tabHeading>
      <span [ngClass]="{'text-danger font-weight-bold' : jsa.jsaIsComplete && !jsa.jsaIsComplete.jsaIsComplete}">JSA -
        {{i + 1}}</span>
    </ng-template>
    <app-job-safety-analysis [jobId]="jobId"
      [jobDate]="jobDate"
      [jsaIndex]="i + 1"
      [jobStatusIsTerminal]="jobStatusIsTerminal"
      [jobSubStatusId]="jobSubStatusId"
      [model]="jsa"
      [lastJobDay]="lastJobDay"
      [contractorTypes]="contractorTypes"
      [weatherTypes]="weatherTypes"
      [windDirections]="windDirections"
      [windSpeeds]="windSpeeds"
      (deleteJsaEvent)="deleteJsa($event)"
      (editJsaEvent)="handleJsaEdit($event)"
      [wellRig]="wellRig"></app-job-safety-analysis>
  </tab>
</tabset>
