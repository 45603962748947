import { Component, OnDestroy, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { BhalineModel } from 'src/models/bha-line.model';
import { BhaLineService } from 'src/services/bha-line.service';
import { FileService } from 'src/services/file.service';
import { JobItemInstanceService } from 'src/services/job-item-instance.service';
import { UIService } from 'src/services/ui.service';
import { BhaModel } from '../../../models/bha.model';
import { JobItemInstanceModel } from '../../../models/job-item-instance.model';
import { JobService } from '../../../services/job.service';

@Component({
  selector: 'app-add-edit-bha-line-modal',
  templateUrl: './add-edit-bha-line-modal.component.html',
  styleUrls: ['./add-edit-bha-line-modal.component.css']
})

export class AddEditBhaLineModalComponent implements OnInit, OnDestroy {
  public isNew: boolean = false;
  public id: number = 0;
  public bhaId: number = 0;
  public jobId: number = 0;
  public fnOdNa: boolean = false;
  public fnLengthNa: boolean = false;
  public disableSave: boolean = false;

  public saveSub: Subscription;

  public newModelSub: Subscription;
  public modelSub: Subscription;
  public model: BhalineModel = null;

  constructor(
    public bsModalRef: BsModalRef,
    public bsModalRefChildModal: BsModalRef,
    public uiService: UIService,
    public jiiService: JobItemInstanceService,
    public bhaLineService: BhaLineService,
    public fileService: FileService,
    public jobService: JobService,
  ) { };

  ngOnDestroy(): void {
    this.modelSub?.unsubscribe();

    this.newModelSub?.unsubscribe();

    this.saveSub?.unsubscribe();
  }

  ngOnInit() {
    this.fileService.refreshBhaDiagram.next(false);

    this.jiiService.getJobItemInstancesByJobId(this.jobId);

    this.isNew = this.id === 0;

    if (!this.isNew) {
      this.getModel();
    } else {
      this.generateNewBhaLine();
    }
  }

  public generateNewBhaLine(): void {
    this.newModelSub = this.bhaLineService.generateNewBhaLineModel(this.bhaId)
      .subscribe((x: BhalineModel) => {
        this.model = x;
      });
  }

  public getModel(): void {
    this.modelSub = this.bhaLineService.getBhaLineById(this.id)
      .subscribe((x: BhalineModel) => {
        this.fnOdNa = x.fnOd == null;
        this.fnLengthNa = x.fnLength == null;
        this.model = x;
      });
  }

  public onChangeOfJobItem($event: JobItemInstanceModel): void {
    if ($event !== undefined) {
      this.getExistingSizeData($event);
      this.model.serialNumber = $event.fullGtNumber !== undefined ? $event.fullGtNumber : null;
      this.model.description = $event.description !== undefined ? $event.description : null;

      this.model.isItemInstance = this.model.serialNumber !== undefined && this.model.serialNumber !== null;
    } else {
      this.model.serialNumber = null;
      this.model.description = null;
      this.model.outerDiameter = null;
      this.model.innerDiameter = null;
      this.model.length = null;
      this.model.fnOd = null;
      this.model.fnLength = null;
      this.fnOdNa = false;
      this.fnLengthNa = false;
    }
  }

  public getExistingSizeData($event: JobItemInstanceModel): void {
    const bhas: BhaModel[] = this.jobService.job.value != null && this.jobService.job.value.jobId === this.jobId ? this.jobService.job.value.bhas : [];
    let lines: BhalineModel[] = [];

    bhas.forEach((bha: BhaModel) => {
      if (bha.isActive && bha.bhalines !== undefined && bha.bhalines !== null) {
        bha.bhalines.forEach((line: BhalineModel) => {
          if (line.isActive) {
            lines.push(line);
          }
        });
      }
    });

    const existing: BhalineModel[] = lines.filter((x: BhalineModel) => x.jobItemInstanceId === $event.jobItemInstanceId);

    if (existing.length > 0) {
      this.model.outerDiameter = existing[0].outerDiameter !== undefined ? existing[0].outerDiameter : null;
      this.model.innerDiameter = existing[0].innerDiameter !== undefined ? existing[0].innerDiameter : null;
      this.model.length = existing[0].length !== undefined ? existing[0].length : null;
      this.model.fnOd = existing[0].fnOd !== undefined ? existing[0].fnOd : null;
      this.model.fnLength = existing[0].fnOd !== undefined ? existing[0].fnLength : null;

      if (this.model.fnOd === null && this.model.fnLength === null) {
        this.fnOdNa = true;
        this.fnLengthNa = true;
      } else {
        this.fnOdNa = false;
        this.fnLengthNa = false;
      }
    } else {
      this.model.outerDiameter = null;
      this.model.innerDiameter = null;
      this.model.length = null;
      this.model.fnOd = null;
      this.model.fnLength = null;
    }
  }

  public toggleFnOdNa($event: any): void {
    if ($event !== undefined) {
      this.model.fnOd = null;
      this.fnOdNa = $event.target.checked;
    }
  }

  public toggleFnLengthNa($event: any): void {
    if ($event !== undefined) {
      this.model.fnLength = null;
      this.fnLengthNa = $event.target.checked;
    }
  }

  public onSave(): void {
    this.disableSave = true;

    this.saveSub = this.bhaLineService.addEditBhaLine(this.model, this.jobId)
      .subscribe((x: boolean) => {
        if (x) {
          this.bsModalRef.hide();

          this.fileService.refreshBhaDiagram.next(true);

          this.uiService.showSuccess('BHA Line Saved', 'Success');
        } else {
          this.uiService.showError('BHA Line Not Saved', 'Error');

          this.disableSave = false;
        }
      });
  }

}
