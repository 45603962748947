import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { JobTypes } from 'src/enums/job-types';
import { AppStateService } from 'src/services/app-state.service';
import { CustomerService } from 'src/services/customer.service';
import { EmployeeService } from 'src/services/employee.service';
import { KGridService } from 'src/services/k-grid.service';
import { UIService } from 'src/services/ui.service';
import { WorksheetCategoryService } from 'src/services/worksheet-category.service';
import { WorksheetTypeService } from 'src/services/worksheet-type.service';
import { WorksheetService } from 'src/services/worksheet.service';

@Component({
  selector: 'app-vendors-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.css']
})

export class PackerListComponent implements OnInit, OnDestroy {

  public showOnJob: boolean = false;
  public showFilter = true;
  public JobTypes = JobTypes;
  public numberOfActiveFilterFields: number = 0;

  private isTabletSub: Subscription;
  public isTablet: boolean = false;

  constructor(
    public kGridService: KGridService,
    public worksheetService: WorksheetService,
    public worksheetTypeService: WorksheetTypeService,
    public worksheetCategoryService: WorksheetCategoryService,
    public employeeService: EmployeeService,
    public customerService: CustomerService,
    public uiService: UIService,
    public appStateService: AppStateService
  ) { };

  ngOnDestroy() {
    this.isTabletSub?.unsubscribe();
  }

  ngOnInit(): void {
    this.worksheetService.refreshPackerList(
      this.showOnJob,
      true);

    this.worksheetTypeService.refreshWorksheetTypes(
      false,
      true);

    this.worksheetCategoryService.getWorksheetCategories(
      false,
      true);

    this.employeeService.getEmployees(false);

    this.customerService.getCustomers(false);

    this.determineIsTablet();
  }

  public determineIsTablet(): void {
    this.isTabletSub = this.appStateService.isTablet$
      .subscribe((isTablet: boolean) => {
        this.isTablet = isTablet;
      });
  }

  public handleOnJobChange($event: boolean) {
    this.showOnJob = $event;
  }

  public getNumberOfActiveFilterFields() {
    if (!this.showFilter) {
      this.numberOfActiveFilterFields = this.worksheetService.getNumberOfActiveFilterFields();
    } else {
      this.numberOfActiveFilterFields = 0;
    }
  }

}
