<div class="page-header">
  <div class="page-header-content header-elements-md-inline">
    <div class="page-title d-flex">
      <a [routerLink]="['/home']"
        class="breadcrumb-link-color">
        <h4><i class="icon-arrow-left52 mr-2"></i> <span class="font-weight-semibold">Warehouse
            Master</span> - Manage</h4>
      </a>
    </div>
    <div class="header-elements d-none py-0 mb-3 mb-md-0">
      <div class="breadcrumb">
        <a href="index.html"
          class="breadcrumb-item"><i class="icon-home2 mr-2"></i> Warehouse Master</a>
        <span class="breadcrumb-item active">List</span>
      </div>
    </div>
  </div>
</div>
<div class="page-content pt-0">
  <div class="content-wrapper">
    <div class="content">
      <div class="row">
        <div class="col-md-12">
          <div class="card border-top-primary border-top-2 rounded-top-0"
            style="border-top-color: #2196F3 !important;">
            <div class="card-header bg-light header-elements-sm-inline">
              <h6 class="card-title">Warehouses</h6>
              <div class="header-elements">
                <div class="switch-container pt-2 mr-2"
                  *ngIf="userService.user.value && userService.user.value.activeRoles && userService.user.value.activeRoles.includes('Super Admin')">
                  <span class="switch-label">Allow Editing </span>
                  <ui-switch (change)="onChangeOfAllowEditing($event)"
                    [ngModel]="allowEditing"></ui-switch>
                </div>
                <div class="switch-container pt-2 mr-2"
                  *ngIf="userService.user.value && userService.user.value.activeRoles && userService.user.value.activeRoles.includes('Super Admin')">
                  <span class="switch-label">Show Inactive </span>
                  <ui-switch (change)="onChangeShowInactive($event)"
                    [disabled]="!allowEditing"></ui-switch>
                </div>
                <button class="btn btn-light btn-sm"
                  (click)="openWarehouseModal(0)"
                  [disabled]="!allowEditing">
                  <i class="fa fa-plus"></i> New Warehouse
                </button>
              </div>
            </div>
            <div class="table-responsive"
              *ngIf="whService.whList | async; let model">
              <kendo-grid style="min-width:1200px;"
                [kendoGridBinding]="model"
                filterable="menu"
                [sortable]="true"
                [resizable]="true"
                [rowClass]="rowCallback"
                [pageSize]="kGridService.kGridParams.pageSize"
                [skip]="kGridService.kGridParams.skip"
                (pageChange)="kGridService.pageChange($event)"
                [pageable]="{
                              buttonCount: kGridService.kGridParams.buttonCount,
                              info: kGridService.kGridParams.info,
                              type: kGridService.kGridParams.type,
                              pageSizes: kGridService.kGridParams.pageSizes,
                              previousNext: kGridService.kGridParams.previousNext
                            }">
                <kendo-grid-column field="one"
                  title=""
                  [width]="60"
                  filterable="false">
                  <ng-template kendoGridCellTemplate
                    let-dataItem
                    let-rowIndex="rowIndex">
                    <button class="btn btn-xs btn-light"
                      (click)="openWarehouseModal(dataItem.warehouseId)"
                      [disabled]="dataItem.isActive === false || !allowEditing"><i class="fa fa-edit"></i></button>
                  </ng-template>
                </kendo-grid-column>
                <kendo-grid-column field="name"
                  title="Name">
                </kendo-grid-column>
                <kendo-grid-column field="shopName"
                  title="Shop"
                  [width]="200">
                </kendo-grid-column>
                <kendo-grid-column field="one"
                  title=""
                  [width]="60"
                  filterable="false">
                  <ng-template kendoGridCellTemplate
                    let-dataItem
                    let-rowIndex="rowIndex">
                    <button class="btn btn-xs btn-light"
                      [swal]="{ title: 'Delete Warehouse?', text: dataItem.name, showCancelButton: true, confirmButtonText: 'Delete', confirmButtonColor: '#DC3545' }"
                      (confirm)="deleteWarehouse(dataItem.warehouseId)"
                      [disabled]="dataItem.isActive === false || !allowEditing"><i class="fa fa-trash"></i></button>
                  </ng-template>
                </kendo-grid-column>
              </kendo-grid>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
