import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AgGridAngular } from 'ag-grid-angular';
import { ColDef, GridReadyEvent } from 'ag-grid-community';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { JobItemInstanceModel } from 'src/models/job-item-instance.model';
import { InvoiceItemReorderModel } from 'src/models/RequestParams/invoice-item-reorder.model';
import { AppStateService } from 'src/services/app-state.service';
import { JobItemInstanceService } from 'src/services/job-item-instance.service';
import { JobService } from 'src/services/job.service';
import { UIService } from 'src/services/ui.service';

@Component({
  selector: 'app-mobile-re-order-tools-modal',
  templateUrl: './mobile-re-order-tools-modal.component.html',
  styleUrls: ['mobile-re-order-tools-modal.component.css']
})

export class MobileReorderToolsModalComponent implements OnInit, OnDestroy {
  @ViewChild(AgGridAngular) agGrid!: AgGridAngular;
  public rows: JobItemInstanceModel[];
  public jobId: number;
  public invoiceId: number = null;
  public isTablet: boolean = false;
  private gridApi;
  private gridColumnApi;

  public defaultColDef: ColDef = {
    sortable: true,
    filter: true,
  };

  public columnDefs: ColDef[] = [
    {
      field: 'fullGtNumber',
      headerName: 'GT #',
      minWidth: 100,
      maxWidth: 150,
      suppressMovable: true,
      rowDrag: true,
      cellRenderer: params => params.value === null ? 'n/a' : params.value,
      cellClass: params => params.value === null ? 'text-muted' : null
    },
    {
      field: 'description',
      suppressMovable: true
    }
  ];

  private isTabletSub: Subscription;
  private saveSub: Subscription;

  constructor(
    public bsModalRef: BsModalRef,
    public jobService: JobService,
    public uiService: UIService,
    public jiiService: JobItemInstanceService,
    public appStateService: AppStateService,
    private modalService: BsModalService
  ) { }

  ngOnDestroy(): void {
    this.isTabletSub?.unsubscribe();

    this.saveSub?.unsubscribe();
  }

  ngOnInit(): void {
    this.determineIsTablet();
  }

  public determineIsTablet(): void {
    this.isTabletSub = this.appStateService.isTablet$
      .subscribe((isTablet: boolean) => {
        this.isTablet = isTablet;
      });
  }

  public onSave(): void {
    const rows = this.gridApi['rowModel']['rowsToDisplay'];

    let batchReorder: number[][] = [];
    let counter: number = 0;

    rows.forEach((row: any) => {
      let temp = [];

      if (row && row['data']
        && row['data'].jobItemInstanceId !== undefined
        && row['data'].jobItemInstanceId !== null
        && row['data'].jobItemInstanceId !== 0) {
        temp.push(row['data'].jobItemInstanceId);
        temp.push(counter);
        batchReorder.push(temp);
        counter++;
      }
    });

    let model = new InvoiceItemReorderModel();
    model.jobId = this.jobId;
    model.batchIds = batchReorder;

    this.saveSub = this.jiiService.batchReorderToolList(model, false)
      .subscribe((x: boolean) => {
        if (x === true) {
          this.uiService.showSuccess('Invoice Reordered', 'Success');
          this.bsModalRef.hide();
        } else {
          this.uiService.showError('Invoice Not Reordered', 'Error');
        }
      });
  }

  public onGridReady(params: GridReadyEvent) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    // The ag-grid is not enlarging based on the page height, 
    // so dynamically adjusting the height of the grid
    this.gridApi.setDomLayout('autoHeight');
    this.gridApi.sizeColumnsToFit(params);

  }

}
