import { Component, OnDestroy, OnInit } from '@angular/core';
import { RowClassArgs } from '@progress/kendo-angular-grid';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { WorksheetTypeModel } from 'src/models/worksheet-type.model';
import { AppStateService } from 'src/services/app-state.service';
import { KGridService } from 'src/services/k-grid.service';
import { UIService } from 'src/services/ui.service';
import { WorksheetTypeService } from 'src/services/worksheet-type.service';
import { AddEditWorksheetTypeModalComponent } from '../add-edit-worksheet-type-modal/add-edit-worksheet-type-modal.component';

@Component({
  selector: 'app-worksheet-types-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.css']
})

export class WorksheetTypeListComponent implements OnInit, OnDestroy {
  public showInactive: boolean = false;

  private isTabletSub: Subscription;
  public isTablet: boolean = false;

  private deleteSub: Subscription;

  constructor(
    private modalService: BsModalService,
    public kGridService: KGridService,
    public uiService: UIService,
    public appStateService: AppStateService,
    public worksheetTypeService: WorksheetTypeService,
  ) { };

  public bsModalRef: BsModalRef;

  ngOnDestroy(): void {
    this.kGridService.resetKGridParams();

    this.isTabletSub?.unsubscribe();

    this.deleteSub?.unsubscribe();
  }

  ngOnInit(): void {
    this.determineIsTablet();

    this.worksheetTypeService.refreshWorksheetTypes(
      this.showInactive,
      false,
      true);
  }

  public determineIsTablet(): void {
    this.isTabletSub = this.appStateService.isTablet$
      .subscribe((isTablet: boolean) => {
        this.isTablet = isTablet;
      });
  }

  public openWorksheetTypeModal(worksheetTypeId: number): void {
    const initialState = {
      id: worksheetTypeId,
      showInactive: this.showInactive
    };
    this.bsModalRef = this.modalService.show(AddEditWorksheetTypeModalComponent, { initialState, backdrop: 'static' });
  }

  public deleteWorksheetType(dto: WorksheetTypeModel): void {
    this.deleteSub = this.worksheetTypeService.deleteWorksheetType(
      dto,
      this.showInactive)
      .subscribe((x: boolean) => {
        if (x) {
          this.uiService.showSuccess('Success', 'Worksheet Type Deleted');
        } else {
          this.uiService.showError('Error', 'Worksheet Type Not Deleted');
        }
      });
  }

  public onChangeShowInactive($event: boolean): void {
    this.showInactive = $event;
    this.worksheetTypeService.refreshWorksheetTypes(this.showInactive, false);
  }

  public rowCallback(context: RowClassArgs): any {
    return {
      inactive: context.dataItem.isActive === false
    };
  }

}
