import { Component, OnDestroy, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { InvoiceItemService } from 'src/services/invoice-item.service';
import { UIService } from 'src/services/ui.service';
import { JobPurchaseOrderLinkModel } from '../../../../models/job-purchase-order-link.model';
import { PurchaseOrderModel } from '../../../../models/purchase-order.model';
import { JobService } from '../../../../services/job.service';
import { PurchaseOrderService } from '../../../../services/purchase-order.service';

@Component({
  selector: 'app-link-po-to-job-modal',
  templateUrl: './link-po-to-job-modal.component.html',
  styleUrls: ['./link-po-to-job-modal.component.css']
})

export class LinkPoToJobModalComponent implements OnInit, OnDestroy {

  public poList: PurchaseOrderModel[] = [];
  public selectedPos: PurchaseOrderModel[] = [];
  public jobId: number = null;
  public disableSave: boolean = false;

  private poListSub: Subscription;
  private saveSub: Subscription;

  constructor(
    public bsModalRef: BsModalRef,
    public uiService: UIService,
    public invoiceItemService: InvoiceItemService,
    public jobService: JobService,
    public poService: PurchaseOrderService,

  ) { };

  ngOnDestroy(): void {
    this.poListSub?.unsubscribe();

    this.saveSub?.unsubscribe();
  }

  ngOnInit(): void {
    this.getPos();
  }

  public getPos(): void {
    this.poListSub = this.poService.getPurchaseOrdersForJobLink(
      false,
      this.jobId)
      .subscribe((x: PurchaseOrderModel[]) => {
        this.poList = x;
      });
  }

  public onSave(): void {
    this.disableSave = true;

    let dto: JobPurchaseOrderLinkModel = new JobPurchaseOrderLinkModel();

    dto.jobId = this.jobId;

    dto.poIds = this.selectedPos.map((x: PurchaseOrderModel) => {
      return x.poid;
    });

    this.saveSub = this.jobService.linkPoToJob(dto)
      .subscribe((x: boolean) => {
        if (x) {
          this.bsModalRef.hide();
          this.uiService.showSuccess('POs Saved', 'Success');
        } else {
          this.disableSave = false;
          this.uiService.showError('POs NOT Saved', 'Error');
        }
      });
  }

}
