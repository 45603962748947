import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { MaintenanceDurationUomModel } from 'src/models/maintenance-duration-uom.model';

@Injectable()
export class MaintenanceDurationUomService {
  constructor(
    private http: HttpClient,
    @Inject('BASE_URL') private baseUrl: string
  ) { }

  private apiUrl = 'api/MaintenanceDurationUom';  // URL to web api

  private mdUomListSubject = new BehaviorSubject<MaintenanceDurationUomModel[]>(null);
  public _mdUomListSubjectList$ = this.mdUomListSubject.asObservable();
  private isMdUomListCallInProgress = false;

  public get maintenanceTypeList$(): Observable<MaintenanceDurationUomModel[]> {
    if (!this.mdUomListSubject.getValue() && !this.isMdUomListCallInProgress) {
      this.isMdUomListCallInProgress = true;
      this.geMaintenanceDurationUoms$(false).subscribe(() => this.isMdUomListCallInProgress = false);
    }

    return this._mdUomListSubjectList$;
  }

  private geMaintenanceDurationUoms$(showInactive: boolean): Observable<MaintenanceDurationUomModel[]> {
    return this.http.get<Array<MaintenanceDurationUomModel>>(this.baseUrl + this.apiUrl + '/GetMaintenanceDurationUoms/' + showInactive)
      .pipe(
        tap((types: MaintenanceDurationUomModel[]) => {
          this.mdUomListSubject.next(types);
        })
      );
  }

}
