<div class="modal-header"
  ngxModalDraggable>
  <h4 class="modal-title pull-left"
    *ngIf="model">{{isNew ? 'Add' : 'Edit'}}&nbsp;BHA&nbsp;{{model.bhaNumber}}</h4>
  <button type="button"
    class="close pull-right"
    aria-label="Close"
    (click)="bsModalRef.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body"
  *ngIf="model">
  <div class="form-group row">
    <label for="title"
      class="col-sm-2 text-right control-label col-form-label">Date</label>
    <div class="col-sm-4 form-group mb-0">
      <input type="text"
        placeholder="mm/dd/yyyy"
        class="form-control"
        [(ngModel)]="model.date"
        required
        bsDatepicker>
    </div>
    <label for="title"
      class="col-sm-2 text-right control-label col-form-label">Operator</label>
    <div class="col-sm-4 form-group mb-0"
      *ngIf="employeeService.employeeList | async; let emp">
      <ng-select [items]="emp"
        bindLabel="fullName"
        bindValue="employeeId"
        [(ngModel)]="model.operatorEmployeeId"
        name="employees">
      </ng-select>
    </div>
  </div>
  <div class="form-group row">
    <label for="title"
      class="col-sm-2 text-right control-label col-form-label">Description</label>
    <div class="col-sm-4 form-group mb-0">
      <textarea class="form-control"
        [(ngModel)]="model.description"></textarea>
    </div>
    <label for="title"
      class="col-sm-2 text-right control-label col-form-label">Supervisor</label>
    <div class="col-sm-4 form-group mb-0"
      *ngIf="employeeService.employeeList | async; let emp">
      <ng-select [items]="emp"
        bindLabel="fullName"
        bindValue="employeeId"
        [(ngModel)]="model.supervisorEmployeeId"
        name="employees">
      </ng-select>
    </div>
  </div>
</div>
<div class="modal-footer"
  *ngIf="model">
  <button type="button"
    class="btn btn-primary"
    (click)="onSave()"
    [disabled]="disableSave 
      || !(model.date)">Save</button>
  <button type="button"
    class="btn btn-default"
    (click)="bsModalRef.hide()">Cancel</button>
</div>
