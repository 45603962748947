import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { JobSafetyAnalysisWeatherModel } from 'src/models/job-safety-analysis-weather.model';
import { WeatherApiModel } from 'src/models/weather-api.model';

@Injectable()
export class JobSafetyAnalysisWeatherService {
  constructor(
    private http: HttpClient,
    @Inject('BASE_URL') private baseUrl: string
  ) { }

  private apiUrl = 'api/JobSafetyAnalysisWeather';  // URL to web api

  private weatherTypeListSubject = new BehaviorSubject<JobSafetyAnalysisWeatherModel[]>(null);
  public _weatherTypeList$ = this.weatherTypeListSubject.asObservable();
  private isWeatherCallInProgress = false;

  public get weatherTypesList$(): Observable<JobSafetyAnalysisWeatherModel[]> {
    if (!this.weatherTypeListSubject.getValue() && !this.isWeatherCallInProgress) {
      this.isWeatherCallInProgress = true;
      this.getWeatherTypes$(false).subscribe(() => this.isWeatherCallInProgress = false);
    }

    return this._weatherTypeList$;
  }

  private getWeatherTypes$(showInactive: boolean): Observable<JobSafetyAnalysisWeatherModel[]> {
    return this.http.get<Array<JobSafetyAnalysisWeatherModel>>(this.baseUrl + this.apiUrl + '/GetWeatherTypes/' + showInactive)
      .pipe(
        tap((weather: JobSafetyAnalysisWeatherModel[]) => {
          this.weatherTypeListSubject.next(weather);
        })
      );
  }

  public getJsaWeather(position: GeolocationPosition): Observable<WeatherApiModel> {
    const lat_lon = `?lat_lon=${position.coords.latitude},${position.coords.longitude}`;

    return this.http.get<WeatherApiModel>(this.baseUrl + this.apiUrl + '/GetJsaWeather' + lat_lon);
  }

}
