import { Component, OnDestroy, OnInit } from '@angular/core';
import { PageChangeEvent } from '@progress/kendo-angular-grid';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { AppStateService } from 'src/services/app-state.service';
import { ItemInstanceLocationHistoryService } from 'src/services/item-instance-location-history.service';
import { ItemService } from 'src/services/item.service';
import { KGridService } from 'src/services/k-grid.service';
import { UIService } from 'src/services/ui.service';

@Component({
  selector: 'app-item-instance-location-history-sm-list',
  templateUrl: './item-instance-location-history-sm-list.component.html',
})

export class ItemInstanceLocationHistorySmallListComponent implements OnInit, OnDestroy {
  constructor(
    public iilhService: ItemInstanceLocationHistoryService,
    public itemService: ItemService,
    public kGridService: KGridService,
    public uiService: UIService,
    public appStateService: AppStateService,

  ) { };

  public bsModalRef: BsModalRef;

  private isTabletSub: Subscription;
  public isTablet: boolean = false;

  ngOnDestroy(): void {
    this.isTabletSub?.unsubscribe();
  }

  ngOnInit(): void {
    this.determineIsTablet();
  }

  public determineIsTablet(): void {
    this.isTabletSub = this.appStateService.isTablet$
      .subscribe((isTablet: boolean) => {
        this.isTablet = isTablet;
      });
  }

  public kGridParams: any =
    {
      buttonCount: 5,
      info: true,
      type: '\'numeric\' | \'input\' = \'numeric\'',
      pageSizes: true,
      previousNext: true,
      pageSize: 10,
      skip: 0
    };

  public pageChange({ skip, take }: PageChangeEvent): void {
    this.kGridParams.skip = skip;
    this.kGridParams.pageSize = take;
  }

}
