import { DayModel } from 'src/models/day.model';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { JobService } from './job.service';
import { map, tap } from 'rxjs/operators';
import { Observable, Observer } from 'rxjs';
import { TabDirective } from 'ngx-bootstrap/tabs';
import { TimezoneService } from './timezone.service';

@Injectable()
export class DayService {
  constructor(
    private http: HttpClient,
    @Inject('BASE_URL') private baseUrl: string,
    private timezoneService: TimezoneService,
    private jobService: JobService
  ) { }

  public selectedDay: TabDirective = null;

  private apiUrl = 'api/Day';  // URL to web api

  public addEditDay(
    dto: DayModel,
    refreshJob: boolean = true): Observable<boolean> {
    return new Observable((observer: Observer<boolean>) => {
      this.http.post<number>(this.baseUrl + this.apiUrl + '/AddEditDay', this.cleanDayModel(dto))
        .subscribe((x: number) => {
          if (x !== -1 && refreshJob) {
            this.jobService.refreshJob(dto.jobId);
          }
          observer.next(x !== -1);
        });
    });
  }

  public deleteDay(
    dto: DayModel,
    refreshJob: boolean = true): Observable<boolean> {
    return new Observable((observer: Observer<boolean>) => {
      this.http.get<boolean>(this.baseUrl + this.apiUrl + '/DeleteDay/' + dto.dayId)
        .subscribe((x: boolean) => {
          if (x === true && refreshJob) {
            this.jobService.refreshJob(dto.jobId);
          }
          observer.next(x);
        });
    });
  }

  public generateDayModel(jobId: number): Observable<DayModel> {
    return this.http.get<DayModel>(this.baseUrl + this.apiUrl + '/GetLastJobDay/' + jobId)
      .pipe(
        tap((x: DayModel) => {
          if (x) {
            x.parsedDate = x && x.date !== undefined && x.date !== null ? new Date(x.date) : null;
          }
        }),
        map((x: DayModel) => {
          return new DayModel(
            0,
            x !== null ? x.dayNumber + 1 : 1,
            x !== null && x.parsedDate !== null ? new Date(x.parsedDate.setDate(x.parsedDate.getDate() + 1)) : new Date(new Date().setHours(0, 0, 0, 0)),
            jobId,
            x !== null ? x.cumulativeWellCost : 0);
        }));
  }

  private cleanDayModel(model: DayModel): DayModel {
    Object.keys(model).forEach((index: string) => {
      if (typeof model[index] === 'string') {
        model[index] = model[index]
          && model[index].trim() !== '' ?
          model[index].trim()
          : null;
      } else if (model[index] instanceof Date
        && !isNaN(model[index])) {
        model[index] = this.timezoneService.correctOffset(model[index]);
      }
    });

    return model;
  }

}
