import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Observer } from 'rxjs';
import { map } from 'rxjs/operators';
import { PartFamilyModel } from '../models/part-family.model';

@Injectable()
export class PartFamilyService {
  constructor(
    private http: HttpClient,
    @Inject('BASE_URL') private baseUrl: string
  ) { }

  public partFamilyList: BehaviorSubject<Array<PartFamilyModel>> = new BehaviorSubject(null);
  public partFamilyListIsLoaded: boolean = false;

  private apiUrl = 'api/PartFamily';  // URL to web api

  public addEditPartFamily(
    dto: PartFamilyModel,
    showInactive: boolean): Observable<boolean> {
    return new Observable((observer: Observer<boolean>) => {
      this.http.post<boolean>(this.baseUrl + this.apiUrl + '/AddEditPartFamily', this.cleanPartFamilyModel(dto))
        .subscribe((x: boolean) => {
          if (x === true) {
            this.refreshPartFamilyList(showInactive, true, true);
          }
          observer.next(x);
        });
    });
  }

  public deletePartFamily(
    id: number,
    showInactive: boolean): Observable<boolean> {
    return new Observable((observer: Observer<boolean>) => {
      this.http.get<boolean>(this.baseUrl + this.apiUrl + '/DeletePartFamily/' + id)
        .subscribe((x: boolean) => {
          if (x === true) {
            this.refreshPartFamilyList(showInactive, true, true);
          }
          observer.next(x);
        });
    });
  }

  public getPartFamilies(
    showInactive: boolean,
    clear: boolean = false,
    includeGroups: boolean = false): Observable<Array<PartFamilyModel>> {
    return this.http.get<Array<PartFamilyModel>>(this.baseUrl + this.apiUrl + '/GetPartFamilies/' + showInactive + '/' + includeGroups);
  }

  public refreshPartFamilyList(
    showInactive: boolean,
    clear: boolean = false,
    includeGroups: boolean = false,
    showSkeleton: boolean = false): void {
    if (!this.partFamilyList.value || clear) {
      if (showSkeleton) {
        this.partFamilyListIsLoaded = false;
      }

      this.getPartFamilies(showInactive, clear, includeGroups)
        .subscribe((x: PartFamilyModel[]) => {
          this.partFamilyList.next(x);
          this.partFamilyListIsLoaded = true;
        });
    }
  }

  public getPartFamilyById(id: number): Observable<PartFamilyModel> {
    return this.http.get<PartFamilyModel>(this.baseUrl + this.apiUrl + '/GetPartFamilyById/' + id);
  }

  public generatePartFamilyModel(): Observable<PartFamilyModel> {
    return this.http.get<number>(this.baseUrl + this.apiUrl + '/GetLastPartFamilyNumberRange')
      .pipe(
        map((x: number) => {
          return new PartFamilyModel(0, (x + 1000).toString(), false, true);
        })
      );
  }

  private cleanPartFamilyModel(model: PartFamilyModel): PartFamilyModel {
    Object.keys(model).forEach((index: string) => {
      if (typeof model[index] === 'string') {
        model[index] = model[index]
          && model[index].trim() !== '' ?
          model[index].trim()
          : null;
      }
    });

    return model;
  }

}
