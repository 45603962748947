import { BehaviorSubject, Observable, Observer } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { WorksheetRowTypeModel } from 'src/models/worksheet-row-type.model';
import { WorksheetTypeService } from './worksheet-type.service';

@Injectable()
export class WorksheetRowTypeService {
  constructor(
    private http: HttpClient,
    @Inject('BASE_URL') private baseUrl: string,
    private worksheetTypeService: WorksheetTypeService
  ) { }

  public worksheetRowTypeList: BehaviorSubject<Array<WorksheetRowTypeModel>> = new BehaviorSubject(null);
  public worksheetRowTypeCache: BehaviorSubject<WorksheetRowTypeModel> = new BehaviorSubject(null);

  private apiUrl = 'api/WorksheetRowType';  // URL to web api

  public getWorksheetRowTypeById(id: number): Observable<WorksheetRowTypeModel> {
    return this.http.get<WorksheetRowTypeModel>(this.baseUrl + this.apiUrl + '/GetWorksheetRowTypeById/' + id);
  }

  public addEditWorksheetRowType(
    dto: WorksheetRowTypeModel,
    refreshWorksheet: boolean = false): Observable<boolean> {
    return new Observable((observer: Observer<boolean>) => {
      this.http.post<number>(this.baseUrl + this.apiUrl + '/AddEditWorksheetRowType', this.cleanWorksheetRowTypModel(dto))
        .subscribe((x: number) => {
          if (x !== -1) {
            if (refreshWorksheet) {
              this.worksheetTypeService.refreshWorksheetType(dto.worksheetTypeId);
            }
          }
          observer.next(x !== -1);
        });
    });
  }

  public deleteWorksheetRowType(
    dto: WorksheetRowTypeModel,
    refreshWorksheet: boolean = false): Observable<boolean> {
    return new Observable((observer: Observer<boolean>) => {
      this.http.get<boolean>(this.baseUrl + this.apiUrl + '/DeleteWorksheetRowType/' + dto.worksheetRowTypeId)
        .subscribe((x: boolean) => {
          if (x === true) {
            if (refreshWorksheet) {
              this.worksheetTypeService.refreshWorksheetType(dto.worksheetTypeId);
            }
          }
          observer.next(x);
        });
    });
  }

  public generateNewWorksheetRowTypeModel(worksheetTypeId: number): WorksheetRowTypeModel {
    let model: WorksheetRowTypeModel = new WorksheetRowTypeModel();
    model.worksheetTypeId = worksheetTypeId;
    model.itemized = false;
    model.isActive = true;

    return model;
  }

  private cleanWorksheetRowTypModel(model: WorksheetRowTypeModel): WorksheetRowTypeModel {
    Object.keys(model).forEach((index: string) => {
      if (typeof model[index] === 'string') {
        model[index] = model[index]
          && model[index].trim() !== '' ?
          model[index].trim()
          : null;
      }
    });

    return model;
  }

}
