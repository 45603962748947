import { ItemVendorModel } from './item-vendor.model';
import { ShopItemModel } from './shop-item.model';

export class ItemDropdownModel {
  itemId: number;
  partNumber: string;
  description: string;
  dropdownDisplay: string;
  isActive: boolean;
  totalInventoryCount: number;
  totalReserveCount: number;
  uomId: number;
  vendorId: number;
  partGroupId: number;
  partGroupName: string;
  partFamilyName: string;
  cost: number | null;
  itemTypeId: number;

  shopItems: ShopItemModel[];
  itemVendor: ItemVendorModel[];
  allVendorIds: number[];
}
