import { BehaviorSubject, Observable } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { SearchDataModel } from 'src/models/search-data.model';

@Injectable()
export class SearchService extends BehaviorSubject<Array<SearchDataModel>> {
  constructor(
    private http: HttpClient,
    @Inject('BASE_URL') private baseUrl: string
  ) {
    super(null);
  }

  private apiUrl = 'api/Search';  // URL to web api

  public getSearchResults(
    searchString: string,
    partGroupId: number): Observable<Array<SearchDataModel>> {
    const queryString = partGroupId ? `?partGroupId=${partGroupId}` : '';

    return this.http.get<Array<SearchDataModel>>(this.baseUrl + this.apiUrl + '/GetSearchResults/' + searchString + queryString);
  }

  public query(
    searchString: string,
    partGroupId: number): void {
    this.getSearchResults(searchString, partGroupId)
      .pipe(debounceTime(500))
      .subscribe((x: SearchDataModel[]) => {
        super.next(x);
      });
  }

}
