import { Component, OnDestroy, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { ShopModel } from 'src/models/shop.model';
import { ItemService } from 'src/services/item.service';
import { ShopService } from 'src/services/shop.service';
import { UIService } from 'src/services/ui.service';

@Component({
  selector: 'app-add-edit-shop-modal',
  templateUrl: './add-edit-shop-modal.component.html',
  styleUrls: ['./add-edit-shop-modal.component.css']
})

export class AddEditShopModalComponent implements OnInit, OnDestroy {
  public isNew: boolean = false;
  public id: number = -1;
  public model: ShopModel;

  private modelSub: Subscription;
  private saveSub: Subscription;

  constructor(
    public bsModalRef: BsModalRef,
    public uiService: UIService,
    public shopService: ShopService,
    public itemService: ItemService

  ) { };

  ngOnDestroy(): void {
    this.modelSub?.unsubscribe();

    this.saveSub?.unsubscribe();
  }

  ngOnInit(): void {
    this.isNew = this.id === 0;

    if (this.isNew) {
      this.model = this.shopService.generateNewShopModel();
    } else {
      this.getModel();
    }
  }

  public getModel(): void {
    this.modelSub = this.shopService.getShopById(this.id)
      .subscribe((x: ShopModel) => {
        this.model = x;
      });
  }

  public onSave(): void {
    this.saveSub = this.shopService.addEditShop(this.model)
      .subscribe((x: boolean) => {
        if (x) {
          this.bsModalRef.hide();
          this.uiService.showSuccess('Shop Saved', 'Success');
        } else {
          this.uiService.showError('Shop Saved', 'Error');
        }
      });
  }

}
