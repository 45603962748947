<div class="modal-header"
  ngxModalDraggable>
  <h4 class="modal-title pull-left"
    *ngIf="job">Copy Job #{{job.jobNumber}}</h4>
  <button type="button"
    class="close pull-right"
    aria-label="Close"
    (click)="bsModalRef.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div class="form-group row"
    *ngIf="model">
    <label for="title"
      class="col-sm-2 text-right control-label col-form-label">Invoice Date</label>
    <div class="col-sm-10 form-group mb-0">
      <input type="text"
        placeholder="mm/dd/yyyy"
        class="form-control"
        [(ngModel)]="model.newJobDate"
        required
        bsDatepicker>
    </div>
  </div>
  <div class="form-group row"
    *ngIf="model">
    <label for="title"
      class="col-sm-2 text-right control-label col-form-label">Include:</label>
    <div class="col-sm-10 form-group mb-0">
      <div class="row pl-2">
        <span class="input-group-text col-md-3 mr-2"
          style="padding: 0px; padding-left: 10px;"><input type="checkbox"
            id="tools"
            [(ngModel)]="model.tools"
            (change)="onChangeOfToolsCheckbox($event)" />&nbsp;<label for="tools"
            style="position: relative; top: 5px; left: 2px;"><strong>Tool List</strong></label></span>
        <span class="input-group-text col-md-3 mr-2"
          style="padding: 0px; padding-left: 10px;"
          *ngIf="job && job.jobTypeId === JobTypes.Fishing"><input type="checkbox"
            id="bhas"
            [(ngModel)]="model.bhas"
            [disabled]="!model.tools" />&nbsp;<label for="bhas"
            style="position: relative; top: 5px; left: 2px;"><strong>BHA's</strong></label></span>
        <span class="input-group-text col-md-3 mr-2"
          style="padding: 0px; padding-left: 10px;"><input type="checkbox"
            id="bhas"
            [(ngModel)]="model.linkedPos" />&nbsp;<label for="linkedPos"
            style="position: relative; top: 5px; left: 2px;"><strong>Linked PO's</strong></label></span>
      </div>
    </div>
  </div>
</div>
<div class="modal-footer">
  <button type="button"
    class="btn btn-primary"
    (click)="onSave()"
    [disabled]="!(model.newJobDate) || disableSave">Copy</button>
  <button type="button"
    class="btn btn-default"
    (click)="bsModalRef.hide()">Cancel</button>
</div>
