<div class="page-header">
  <div class="page-header-content header-elements-md-inline">
    <div class="page-title d-flex">
      <a [routerLink]="[returnUrl]"
        class="breadcrumb-link-color"
        *ngIf="userService.user.value && userService.user.value.activeRoles && userService.user.value.activeRoles.includes('General')">
        <h4><i class="icon-arrow-left52 mr-2"></i> <span class="font-weight-semibold">Fishing Invoice</span> - Details
        </h4>
      </a>
      <a [routerLink]="['/']"
        class="breadcrumb-link-color"
        *ngIf="userService.user.value && userService.user.value.activeRoles && userService.user.value.activeRoles.includes('Fishing Hand')">
        <h4><i class="icon-arrow-left52 mr-2"></i> <span class="font-weight-semibold">Fishing Invoice</span> - Details
        </h4>
      </a>
      <button type="button"
        class="btn btn-outline-dark btn-sm ml-3 pl-1 pr-1 pt-0 pb-0 no-border"
        [title]="!showDetails ? 'Show Details' : 'Hide Details'"
        (click)="showDetails = !showDetails">
        <span *ngIf="!showDetails">
          <i class="fa-regular fa-chevrons-right"></i>&nbsp;Show </span>
        <span *ngIf="showDetails"><i class="fa-regular fa-chevrons-left"></i>&nbsp;Hide
        </span>Details</button>
    </div>
    <div class="header-elements d-none py-0 mb-3 mb-md-0">
      <div class="breadcrumb">
        <a href="index.html"
          class="breadcrumb-item"><i class="icon-home2 mr-2"></i> Invoice</a>
        <span class="breadcrumb-item active">Detail</span>
      </div>
    </div>
  </div>
</div>
<div class="page-content pt-0"
  *ngIf="jobService.job | async; let j">
  <div class="content-wrapper">
    <div class="content">
      <div class="row">
        <div class="col-lg-3"
          *ngIf="showDetails">
          <!-- LEFT HAND JOB SUMMARY CARD -->
          <div class="card mb-0">
            <div class="card-header bg-light header-elements-inline">
              <h6 class="card-title">Invoice#: {{j.jobNumber}}</h6>
              <div class="header-elements">
                <button class="btn btn-success btn-sm mr-1 btn-light"
                  *ngIf="!j.notReadyToInvoice"
                  title="Ready To Invoice"
                  (click)="toggleReadyToInvoice(j.jobId)"
                  [disabled]="enableReorder || enableReorderToolList || (jobService.job.value !== null && jobService.job.value.jobStatusIsTerminal === true) || !(userService.user.value && userService.user.value.activeRoles && userService.user.value.activeRoles.includes('General'))">
                  <span><i class="far fa-check-circle"></i></span><span
                    *ngIf="!isTablet">&nbsp;&nbsp;Ready</span></button>
                <button class="btn btn-success btn-sm mr-1 btn-danger"
                  *ngIf="j.notReadyToInvoice"
                  title="Not Ready To Invoice"
                  (click)="toggleReadyToInvoice(j.jobId)"
                  [disabled]="enableReorder || enableReorderToolList || (jobService.job.value !== null && jobService.job.value.jobStatusIsTerminal === true) || !(userService.user.value && userService.user.value.activeRoles && userService.user.value.activeRoles.includes('General'))"><i
                    class="fa-regular fa-circle-xmark"></i><span *ngIf="!isTablet">&nbsp;&nbsp;Not Ready</span></button>
                <button class="btn btn-light btn-sm"
                  (click)="openJobFishingModal(j.jobId)"
                  [disabled]="enableReorder || enableReorderToolList || jobService.job.value !== null && jobService.job.value.jobStatusIsTerminal === true"><i
                    class="fa fa-edit"></i><span *ngIf="!isTablet"> Edit</span></button>
              </div>
            </div>
            <div class="card-body"
              [ngClass]="{'pr-2 pl-2':isTablet}">
              <div id="collapseBasic"
                [collapse]="isCollapsed">
                <app-job-info [job]="j"></app-job-info>
                <br>
              </div>
              <ng-container
                *ngIf="userService.user.value && userService.user.value.activeRoles && !userService.user.value.activeRoles.includes('General')">
                <div class="row">
                  <div class="col-md-12">
                    <app-sub-status-button-hands [job]="j"
                      [enableReorder]="enableReorder">
                    </app-sub-status-button-hands>
                  </div>
                </div>
              </ng-container>
              <ng-container
                *ngIf="userService.user.value && userService.user.value.activeRoles && userService.user.value.activeRoles.includes('General')">
                <div class="row">
                  <div class="col-md-12">
                    <button class="btn btn-light btn-sm btn-block"
                      (click)="reportService.getAllFishingInvoicePDF(j.jobId)"
                      [disabled]="enableReorder || enableReorderToolList || (j.invoices.length === 0 || (j.invoices.length > 0 && (j.invoices[0].invoiceItems !== undefined && j.invoices[0].invoiceItems.length === 0)))"><i
                        class="fal fa-file-pdf"></i> Print Invoice</button>
                    <app-sub-status-button [job]="j"
                      [enableReorder]="enableReorder"
                      *ngIf="jobService.job.value !== null 
                        && (
                              (jobService.job.value.jobStatusId === JobStatuses.Open_Fishing 
                                && (jobService.job.value.jobSubStatusId === JobSubStatuses.In_Progress))
                              ||
                              (jobService.job.value.jobStatusId === JobStatuses.EditInvoice_Fishing && (jobService.job.value.jobSubStatusId === JobSubStatuses.Pending_Approval || jobService.job.value.jobSubStatusId === JobSubStatuses.Ready_for_Signature))
                            )">
                    </app-sub-status-button>
                    <app-status-button-fishing [job]="j"
                      [enableReorder]="enableReorder"
                      *ngIf="jobService.job.value !== null
                        && (
                              (jobService.job.value.jobStatusId === JobStatuses.Open_Fishing 
                                && jobService.job.value.jobSubStatusId === JobSubStatuses.Pending_Approval)
                              ||
                              (jobService.job.value.jobStatusId === JobStatuses.EditInvoice_Fishing
                                && jobService.job.value.jobSubStatusId === JobSubStatuses.Sent_for_Signature)
                              ||
                              (jobService.job.value.jobStatusId === JobStatuses.Closed_Fishing)
                              ||
                              (jobService.job.value.jobStatusId === JobStatuses.Cancelled_Fishing)
                            )">
                    </app-status-button-fishing>
                    <button class="btn btn-outline-dark btn-sm btn-block"
                      (click)="openCopyJobModal()"><i class="fal fa-clone"></i>&nbsp;Copy Job</button>
                  </div>
                </div>
              </ng-container>
            </div>
          </div>
          <!-- END LEFT HAND JOB SUMMARY CARD -->
          <!-- POSSIBLE BHA LIST LIKE WORKSHEET LIST?? -->
          <ng-container
            *ngIf="userService.user.value && userService.user.value.activeRoles && userService.user.value.activeRoles.includes('General')">
            <app-job-po-display [purchaseOrders]="j.purchaseOrders"
              [disable]="j.jobStatusId !== JobStatuses.Open_Fishing"
              [jobId]="jobId"></app-job-po-display>
          </ng-container>
        </div>
        <div [ngClass]="{'col-lg-9': showDetails, 'col-lg-12': !showDetails}">
          <tabset [justified]="true"
            class="compact-nav">
            <!-- DAILY FISHING TAB -->
            <tab heading="Daily Fishing Report"
              id="tab1"
              [disabled]="enableReorder || enableReorderToolList">
              <div style="background-color: white;">
                <div class="d-flex flex-row-reverse mb-3"
                  *ngIf="j.days.length === 0">
                  <button class="btn btn-light btn-sm"
                    (click)="openJobFishingDayModal(0)"
                    [disabled]="j.invoiceHasBeenSplit || jobService.job.value !== null && jobService.job.value.jobStatusIsTerminal === true"><i
                      class="fas fa-plus"></i> New Day</button>
                </div>
                <div class="alert alert-primary mb-0"
                  role="alert"
                  *ngIf="j.days.length === 0">
                  No <strong>Days</strong> added for Job#: {{j.jobNumber}}. Hit the <strong><i class="fas fa-plus"></i>
                    Add</strong> button to create a new Day.
                </div>
                <button style="float: right;"
                  class="btn btn-light"
                  (click)="openJobFishingDayModal(0)"
                  *ngIf="j.days.length > 0"
                  [disabled]="j.invoiceHasBeenSplit || jobService.job.value !== null && jobService.job.value.jobStatusIsTerminal === true"><i
                    class="fas fa-plus"></i> New Day</button>
                <tabset type="pills"
                  class="prevent-tabset-default"
                  *ngIf="j.days.length > 0">
                  <tab heading="{{day.date | date:'shortDate'}}"
                    id="dayTab_{{i}}"
                    *ngFor="let day of j.days; let i = index;"
                    (selectTab)="tabSelect($event)"
                    [active]="'dayTab_' + i === activeTabId">
                    <div class="row">
                      <div class="col-sm-4"
                        [ngClass]="{'col-md-5':isTablet}">
                        <div class="card">
                          <div class="card-header bg-light header-elements-inline">
                            <h6 class="card-title">Summary Card</h6>
                            <div class="header-elements">
                              <button class="btn btn-danger btn-sm"
                                style="float: right"
                                [swal]="{ title: 'Delete Day ' + day.dayNumber +'?', text:formatDateAsString(day.date), showCancelButton: true, confirmButtonText: 'Delete', confirmButtonColor: '#DC3545' }"
                                (confirm)="deleteDay(day)"
                                [title]="'Delete Day ' + day.dayNumber"
                                [disabled]="j.invoiceHasBeenSplit 
                                  || day.bhasUsed 
                                  || day.workLogs.length > 0
                                  || day.jobItemInstanceIds.length > 0
                                  || (jobService.job.value !== null && jobService.job.value.jobStatusIsTerminal === true)"><i
                                  class="fa fa-trash"></i></button>
                            </div>
                          </div>
                          <div class="card-body"
                            [ngClass]="{'pl-2 pr-2':isTablet}">
                            <dl class="row">
                              <dt class="col-sm-5">Date:</dt>
                              <dd class="col-sm-6">
                                <input type="text"
                                  placeholder="mm/dd/yyyy"
                                  class="form-control pl-0"
                                  style="height: 20px; border: 0;"
                                  [ngModel]="day.date"
                                  [bsConfig]="{ dateInputFormat: 'MMMM Do YYYY' }"
                                  (bsValueChange)="saveJobDateChange($event, day)"
                                  [datesDisabled]="currentJobDates"
                                  bsDatepicker>
                              </dd>
                              <dt class="col-sm-5">Daily Well Cost:</dt>
                              <dd class="col-sm-6">{{day.dailyWellCost | currency}}</dd>
                              <dt class="col-sm-5">Cumulative Well Cost:</dt>
                              <dd class="col-sm-6">{{day.cumulativeWellCost | currency}}<span class="text-primary"
                                  title="This number reflects the Cumulative Well Cost PRIOR to the invoice being edited."
                                  *ngIf="jobService.job.value !== null && jobService.job.value.jobStatusId !== JobStatuses.Open_Fishing">&nbsp;&nbsp;<i
                                    class="fas fa-info-circle"></i></span>
                                <span class="text-primary"
                                  title="This number reflects the Cumulative Well Cost PRIOR to the Tool Supervisor Quantity being edited."
                                  *ngIf="jobService.job.value !== null && jobService.job.value.toolSupervisorQtyHasBeenEdited && jobService.job.value.jobStatusId === JobStatuses.Open_Fishing">&nbsp;&nbsp;<i
                                    class="fas fa-info-circle"></i></span>
                              </dd>
                              <dt class="col-sm-12">Progress of Work</dt>
                              <dd class="col-sm-12">
                                <textarea name="pow"
                                  id="pow"
                                  class="form-control"
                                  [(ngModel)]="day.progressOfWork"
                                  (blur)="saveDay(day)"
                                  *ngIf="jobService.job.value !== null && jobService.job.value.jobStatusIsTerminal === false"></textarea>
                                <textarea name="pow"
                                  id="pow"
                                  class="form-control"
                                  [(ngModel)]="day.progressOfWork"
                                  *ngIf="jobService.job.value !== null && jobService.job.value.jobStatusIsTerminal === true"
                                  readonly></textarea>
                              </dd>
                            </dl>
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-8"
                        [ngClass]="{'col-md-7':isTablet}">
                        <div class="card">
                          <div class="card-header bg-light header-elements-inline">
                            <h6 class="card-title">BHA</h6>
                          </div>
                          <div class="card-body"
                            [ngClass]="{'pl-2 pr-2':isTablet}">
                            <div class="table-responsive table-condensed">
                              <div class="alert alert-primary mb-0"
                                role="alert"
                                *ngIf="j.bhas.length === 0">
                                No <strong>BHAs</strong> added for Job#: {{j.jobNumber}}.
                              </div>
                              <table style="min-width:400px;width: 100%;"
                                class="table table-striped"
                                *ngIf="j.bhas.length > 0">
                                <thead>
                                  <tr>
                                    <th style="width: 24px;"></th>
                                    <th style="width: 100px;">Component</th>
                                    <th style="width: 75px;">OD</th>
                                    <th style="width: 75px;">ID</th>
                                    <th>Length</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr *ngFor="let b of j.bhas">
                                    <td>
                                      <input type="checkbox"
                                        [checked]="b.dayIds.includes(day.dayId)"
                                        (click)="toggleBhaDay(day.dayId, b.bhaId)"
                                        [disabled]="j.invoiceHasBeenSplit || jobService.job.value !== null && jobService.job.value.jobStatusIsTerminal === true">
                                    </td>
                                    <td>BHA #{{b.bhaNumber}}</td>
                                    <td></td>
                                    <td></td>
                                    <td>{{b.length | number : '1.2-2'}}'</td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <app-work-log *ngIf="j"
                      [logs]="day.workLogs"
                      [jobId]="jobId"
                      [dayId]="day.dayId"
                      [jobOwnerId]="j.jobOwnerId"
                      [dayDate]="day.date"
                      [jobTypeId]="j.jobTypeId">
                    </app-work-log>
                  </tab>
                </tabset>
              </div>
            </tab>
            <!-- END DAILY FISHING TAB -->
            <!-- JSA TAB -->
            <tab id="tab5">
              <app-jsa-tab-heading [jsaHealth]="jsaHealth"></app-jsa-tab-heading>
              <app-job-safety-analysis-tabset [jobId]="j.jobId"
                [jobDate]="j.jobDate"
                [jobStatusIsTerminal]="j.jobStatusIsTerminal"
                [jobNumber]="j.jobNumber"
                [jobTypeId]="j.jobTypeId"
                [wellRig]="j.well_Rig"
                [lastJobDay]="j.lastJobDay !== null ? j.lastJobDay : j.jobDate"
                [jobSubStatusId]="j.jobSubStatusId"></app-job-safety-analysis-tabset>
            </tab>
            <!-- END JSA TAB -->
            <!-- FISHING TOOL TAB -->
            <tab heading="Fishing Tool Report"
              id="tab2">
              <div class="d-flex justify-content-between mb-1">
                <button class="btn btn-sm btn-primary"
                  [disabled]="disableReleaseAll || enableReorder || enableReorderToolList"
                  [swal]="{ title: 'Release All Serialized Items?', showCancelButton: true, confirmButtonText: 'Release', confirmButtonColor: '#007bff' }"
                  (confirm)="releaseAllFromJob()">
                  <i class="far fa-check-square"></i>&nbsp;&nbsp;Release All
                </button>
                <div>
                  <button class="btn  btn-sm btn-primary mr-2"
                    *ngIf="isTablet"
                    [disabled]="tools.length <= 1"
                    (click)="openReorderToolsModal()"><i class="fas fa-arrows-v"></i>&nbsp;&nbsp;Re-sort</button>
                  <div class="switch-container mr-2 d-inline-block"
                    *ngIf="!enableReorderToolList && !isTablet">
                    <span class="switch-label">Enable Re-sort </span>
                    <ui-switch [ngModel]="enableReorderToolList"
                      (change)="switchEnableReorder($event)"></ui-switch>
                  </div>
                  <button class="btn btn-sm btn-primary mr-2 d-inline-block"
                    *ngIf="enableReorderToolList"
                    (click)="switchEnableReorder(false)">Save Sort Order</button>
                  <div class="btn-group d-inline-block"
                    dropdown>
                    <button class="btn btn-light btn-sm dropdown-toggle"
                      *ngIf="j.days.length === 0 || j.invoiceHasBeenSplit || enableReorder || enableReorderToolList || jobService.job.value !== null && jobService.job.value.jobStatusIsTerminal === true"
                      disabled>
                      <i class="fa fa-plus"></i> Add
                      <span class="caret"></span>
                    </button>
                    <button class="btn btn-light btn-sm dropdown-toggle"
                      dropdownToggle
                      id="button-basic"
                      aria-controls="dropdown-basic"
                      *ngIf="j.days.length > 0 && !j.invoiceHasBeenSplit && !enableReorder && !enableReorderToolList && !(jobService.job.value !== null && jobService.job.value.jobStatusIsTerminal === true)">
                      <i class="fa fa-plus"></i> Add
                      <span class="caret"></span>
                    </button>
                    <ul id="dropdown-basic"
                      *dropdownMenu
                      class="dropdown-menu dropdown-menu-right"
                      role="menu"
                      aria-labelledby="button-basic">
                      <li role="menuitem"><span class="dropdown-item"
                          (click)="openJobFishingItemInstanceModal(0, j.jobId, JobFishingItemModalTypes.Serialized)"><i
                            class="fal fa-barcode"></i>Serialized Item</span></li>
                      <li role="menuitem"><span class="dropdown-item"
                          (click)="openJobFishingItemInstanceModal(0, j.jobId, JobFishingItemModalTypes.Inventory)"><i
                            class="fal fa-inventory"></i>Inventory Item</span></li>
                      <li role="menuitem"><span class="dropdown-item"
                          (click)="openJobFishingItemInstanceModal(0, j.jobId, JobFishingItemModalTypes.ThirdParty)"><i
                            class="fal fa-hand-holding-box"></i>Third Party Item</span></li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="table-responsive mt-3">
                <div class="alert alert-primary mb-0"
                  role="alert"
                  *ngIf="j.jobItemInstances.length === 0">
                  No Tools added for Job#: {{j.jobNumber}}. Hit the <strong><i class="fas fa-plus"></i> Add</strong>
                  button to select a new Tool.
                </div>
                <ng-container *ngIf="j.jobItemInstances.length > 0">
                  <kendo-grid [data]="toolsGridData"
                    [style.maxHeight.px]="620"
                    [rowClass]="rowCallback_2"
                    [sortable]="false"
                    (dataStateChange)="dataStateChange($event)">
                    <kendo-grid-column field="move"
                      title=""
                      [width]="40"
                      filterable="false"
                      *ngIf="enableReorderToolList"
                      [locked]="true">
                      <ng-template kendoGridCellTemplate
                        let-dataItem
                        let-rowIndex="rowIndex">
                        <span class="pl-1 text-danger"><i class="fas fa-arrows-alt-v"></i></span>
                      </ng-template>
                    </kendo-grid-column>
                    <kendo-grid-column field="one"
                      title=""
                      [width]="55"
                      [locked]="true">
                      <ng-template kendoGridCellTemplate
                        let-dataItem
                        let-rowIndex="rowIndex">
                        <button class="btn btn-xs btn-default"
                          (click)="openJobFishingItemInstanceModal(dataItem.jobItemInstanceId, j.jobId, dataItem.type)"
                          [disabled]="enableReorder || enableReorderToolList || j.invoiceHasBeenSplit || jobService.job.value !== null && jobService.job.value.jobStatusIsTerminal === true"><i
                            class="fa fa-pencil"></i></button>
                      </ng-template>
                    </kendo-grid-column>
                    <kendo-grid-column field="two"
                      title="Release"
                      [width]="65"
                      [style]="{'text-align': 'center'}"
                      [headerStyle]="{'text-align': 'center'}"
                      [locked]="true">
                      <ng-template kendoGridCellTemplate
                        let-dataItem
                        let-rowIndex="rowIndex">
                        <input type="checkbox"
                          [checked]="dataItem.releasedFromJob === true"
                          (click)="toggleReleaseFromJob(dataItem.jobItemInstanceId)"
                          [disabled]="enableReorder || enableReorderToolList || dataItem.sold===true||j.invoiceHasBeenSplit || jobService.job.value !== null && jobService.job.value.jobStatusIsTerminal === true"
                          *ngIf="dataItem.itemInstanceId !== null">
                      </ng-template>
                    </kendo-grid-column>
                    <kendo-grid-column field="Invoice"
                      title="Invoice"
                      [width]="60"
                      [style]="{'text-align': 'center'}"
                      [headerStyle]="{'text-align': 'center'}"
                      [locked]="true">
                      <ng-template kendoGridCellTemplate
                        let-dataItem
                        let-rowIndex="rowIndex">
                        <span *ngIf="!dataItem.doNotCharge"
                          title="Invoice"><i class="fas fa-dollar-sign"></i></span>
                      </ng-template>
                    </kendo-grid-column>
                    <kendo-grid-column field="two"
                      title="Scrap"
                      [width]="65"
                      [style]="{'text-align': 'center'}"
                      [headerStyle]="{'text-align': 'center'}"
                      [locked]="true">
                      <ng-template kendoGridCellTemplate
                        let-dataItem
                        let-rowIndex="rowIndex">
                        <span *ngIf="dataItem.sold===true || dataItem.removeFromInventory===true"
                          class="text-danger"><i class="fas fa-times"></i><span
                            *ngIf="dataItem.soldQty !== undefined && dataItem.soldQty !== null && dataItem.quantityUom !== undefined && dataItem.quantityUom !== null">&nbsp;&nbsp;({{dataItem.soldQty}}<span
                              *ngIf="dataItem.quantityUom!=='Each'&&dataItem.quantityUom!=='Run'&&dataItem.quantityUom!=='Day'">{{dataItem.quantityUom}}</span>)</span></span>
                      </ng-template>
                    </kendo-grid-column>
                    <kendo-grid-column field="three"
                      title="Type"
                      [width]="60"
                      [style]="{'text-align': 'center'}"
                      [headerStyle]="{'text-align': 'center'}"
                      [locked]="true">
                      <ng-template kendoGridCellTemplate
                        let-dataItem
                        let-rowIndex="rowIndex">
                        <span *ngIf="dataItem.type===1"
                          title="Serialized Item"><i class="far fa-barcode"></i></span>
                        <span *ngIf="dataItem.type===2"
                          title="Inventory Item"><i class="far fa-inventory"></i></span>
                        <span *ngIf="dataItem.type===3"
                          title="Third-Party Item"><i class="far fa-hand-holding-box"></i></span>
                      </ng-template>
                    </kendo-grid-column>
                    <kendo-grid-column field="four"
                      title="Serial #"
                      [width]="80"
                      [style]="{'text-align': 'center'}"
                      [headerStyle]="{'text-align': 'center'}"
                      [locked]="true">
                      <ng-template kendoGridCellTemplate
                        let-dataItem
                        let-rowIndex="rowIndex">
                        <span
                          *ngIf="dataItem.fullGtNumber !== undefined && dataItem.fullGtNumber !== null">{{dataItem.fullGtNumber}}</span><span
                          class="text-muted"
                          *ngIf="dataItem.fullGtNumber === undefined || dataItem.fullGtNumber === null">n/a</span>
                      </ng-template>
                    </kendo-grid-column>
                    <kendo-grid-column field="description"
                      title="Tools Used"
                      [width]="200"
                      [style]="{'text-align': 'center'}"
                      [headerStyle]="{'text-align': 'center'}"
                      [locked]="true">
                    </kendo-grid-column>
                    <kendo-grid-column *ngFor="let day of j.days; let i = index"
                      [field]="one"
                      [title]="day.date | date:'shortDate'"
                      [width]="70"
                      [style]="{'text-align': 'center'}"
                      [headerStyle]="{'text-align': 'center'}">
                      <ng-template kendoGridCellTemplate
                        let-dataItem
                        let-rowIndex="rowIndex">
                        <ng-container *ngIf="!dataItem.variableQty && !dataItem.perRun">
                          <input type="checkbox"
                            [checked]="dataItem.dayIds.includes(day.dayId)"
                            (click)="toggleJobItemInstanceDay(day, dataItem.jobItemInstanceId)"
                            [disabled]="enableReorder || enableReorderToolList || j.invoiceHasBeenSplit || jobService.job.value !== null && jobService.job.value.jobStatusIsTerminal === true">
                        </ng-container>
                        <ng-container *ngIf="dataItem.variableQty && !dataItem.perRun">
                          <input type="number"
                            name="jiiQty"
                            value=""
                            class="form-control form-control-sm"
                            [ngModel]="dataItem.jobIteminstanceDays[i].qty"
                            (blur)="changeJiiDayQty($event, dataItem.jobIteminstanceDays[i], day)"
                            style="padding:1px;height: 20px;"
                            [readonly]="enableReorder || enableReorderToolList || j.invoiceHasBeenSplit || jobService.job.value !== null && jobService.job.value.jobStatusIsTerminal === true"
                            (keydown.enter)="$event.target.blur();" />
                        </ng-container>
                        <ng-container *ngIf="dataItem.perRun && i === 0">
                          <input type="number"
                            name="name"
                            [(ngModel)]="dataItem.qty"
                            style="padding:1px;height: 20px;"
                            class="form-control form-control-sm"
                            (blur)="saveJobItemInstance(dataItem)"
                            (keydown.enter)="$event.target.blur();"
                            [readonly]="enableReorder || enableReorderToolList" />
                        </ng-container>
                      </ng-template>
                    </kendo-grid-column>
                    <kendo-grid-column field="one"
                      title=""
                      [width]="50">
                      <ng-template kendoGridCellTemplate
                        let-dataItem
                        let-rowIndex="rowIndex">
                        <button class="btn btn-xs"
                          [swal]="{ title: 'Delete ' + dataItem.description + '?', text:dataItem.serialNumber, showCancelButton: true, confirmButtonText: 'Delete', confirmButtonColor: '#DC3545' }"
                          (confirm)="deleteJobItemInstance(dataItem)"
                          [disabled]="enableReorder || enableReorderToolList || j.invoiceHasBeenSplit || dataItem.dayIds.length > 0 || dataItem.disableTrash || dataItem.onBha || jobService.job.value !== null && jobService.job.value.jobStatusIsTerminal === true">
                          <i class="fa fa-trash"></i>
                        </button>
                      </ng-template>
                    </kendo-grid-column>
                  </kendo-grid>
                </ng-container>
              </div>
            </tab>
            <!-- END FISHING TOOL TAB -->
            <!-- BHA -->
            <tab heading="BHA"
              id="tab4"
              [disabled]="enableReorder || enableReorderToolList">
              <div style="background-color: white;">
                <div class="d-flex flex-row-reverse mb-3"
                  *ngIf="j.bhas.length === 0">
                  <button class="btn btn-light btn-sm"
                    (click)="openBhaModal(0, j.jobId)"
                    [disabled]="j.invoiceHasBeenSplit || jobService.job.value !== null && jobService.job.value.jobStatusIsTerminal === true"><i
                      class="fas fa-plus"
                      *ngIf="j.bhas.length === 0"></i> New BHA</button>
                </div>
                <div class="alert alert-primary mb-0"
                  role="alert"
                  *ngIf="j.bhas.length === 0">
                  No <strong>BHAs</strong> added for Job#: {{j.jobNumber}}. Hit the <strong><i class="fas fa-plus"></i>
                    Add</strong> button to create a new BHA.
                </div>
                <button style="float: right"
                  class="btn btn-light"
                  (click)="openBhaModal(0, j.jobId)"
                  [disabled]="j.invoiceHasBeenSplit || jobService.job.value !== null && jobService.job.value.jobStatusIsTerminal === true"
                  *ngIf="j.bhas.length > 0"><i class="fas fa-plus"></i> New BHA</button>
                <tabset type="pills"
                  class="prevent-tabset-default">
                  <tab heading="BHA #{{bha.bhaNumber}}"
                    id="bhaTab_{{i}}"
                    *ngFor="let bha of j.bhas; let i = index;"
                    (selectTab)="tabBhaSelect($event)"
                    [active]="'bhaTab_' + i === activeBhaTabId">
                    <!-- <div>
                      there will be an image here
                      <img [src]="timeStampBhaImageLink(bha.bhaId)" alt="">
                    </div> -->
                    <div class="card">
                      <div class="card-header bg-light header-elements-sm-inline">
                        <h6 class="card-title">BHA #{{bha.bhaNumber}}</h6>
                        <div>
                          <button class="btn btn-light btn-sm mr-2"
                            (click)="reportService.getBhaPDF(bha.bhaId, j.jobId)"
                            [disabled]="!(bha.bhalines && bha.bhalines.length > 0)"><i class="fal fa-file-pdf"></i>
                            Print BHA</button>
                          <button class="btn btn-light btn-sm mr-2"
                            (click)="openBhaModal(bha.bhaId, j.jobId)"
                            [disabled]="j.invoiceHasBeenSplit || jobService.job.value !== null && jobService.job.value.jobStatusIsTerminal === true"><i
                              class="fa fa-edit"></i> Edit</button>
                          <button class="btn btn-danger btn-sm"
                            [swal]="{ title: 'Delete BHA #' + bha.bhaNumber +'?', showCancelButton: true, confirmButtonText: 'Delete', confirmButtonColor: '#DC3545' }"
                            (confirm)="deleteBha(bha)"
                            [title]="'Delete BHA #' + bha.bhaNumber"
                            [disabled]="j.invoiceHasBeenSplit || bha.dayIds && bha.dayIds.length > 0 || jobService.job.value !== null && jobService.job.value.jobStatusIsTerminal === true"><i
                              class="fa fa-trash"></i></button>
                        </div>
                      </div>
                      <div class="card-body"
                        [ngClass]="{'pl-2 pr-2': isTablet}">
                        <div class="row">
                          <div class="col-sm-4">
                            <dl class="row">
                              <dt class="col-sm-4">Date</dt>
                              <dd class="col-sm-8">{{bha.date | date:'shortDate'}}</dd>
                              <dt class="col-sm-4">BHA Desc</dt>
                              <dd class="col-sm-8">{{bha.description}}</dd>
                            </dl>
                          </div>
                          <div class="col-sm-4">
                            <dl class="row">
                              <dt class="col-sm-4">Rig</dt>
                              <dd class="col-sm-8">{{j.rigName}}</dd>
                              <dt class="col-sm-4">Well</dt>
                              <dd class="col-sm-8">{{j.wellName}}</dd>
                            </dl>
                          </div>
                          <div class="col-sm-4">
                            <dl class="row">
                              <dt class="col-sm-4">Operator</dt>
                              <dd class="col-sm-8">{{bha.operator}}</dd>
                              <dt class="col-sm-4">Supervisor</dt>
                              <dd class="col-sm-8">{{bha.supervisor}}</dd>
                            </dl>
                          </div>
                        </div>
                        <hr>
                        <div class="d-flex flex-row-reverse mb-2">
                          <button class="btn btn-light btn-sm"
                            (click)="openBhaLineModal(0, bha.bhaId, j.jobId)"
                            [disabled]="j.invoiceHasBeenSplit || jobService.job.value !== null && jobService.job.value.jobStatusIsTerminal === true"><i
                              class="fas fa-plus"></i> Add</button>
                        </div>
                        <div class="row">
                          <div class="col-sm-12"
                            *ngIf="bha.bhalines.length === 0">
                            <div class="alert alert-primary mb-0"
                              role="alert">
                              <i class="fad fa-check"></i>&nbsp;&nbsp;There are currently no <strong>BHA
                                Lines.</strong>&nbsp;Hit the <strong><i class="fas fa-plus"></i> Add</strong> button to
                              create a new line.
                            </div>
                          </div>
                          <div class="col-sm-1 text-center"
                            *ngIf="bha.hasImage">
                            <img [src]="timeStampBhaImageLink(bha.bhaId)"
                              alt=""
                              style="color: white;max-height: 400px;max-width: 75px;">
                          </div>
                          <div class="col-sm-11"
                            [ngClass]="{'col-sm-12':!bha.hasImage}">
                            <div class="table-responsive">
                              <!-- KENDO GRID -->
                              <kendo-grid [data]="bha.bhalines"
                                filterable="false"
                                [sortable]="false"
                                [resizable]="true"
                                style="min-width:700px;width: 100%;"
                                *ngIf="bha.bhalines.length > 0">
                                <kendo-grid-column field="one"
                                  title=""
                                  [width]="55"
                                  filterable="false">
                                  <ng-template kendoGridCellTemplate
                                    let-dataItem
                                    let-rowIndex="rowIndex">
                                    <button class="btn btn-xs btn-default"
                                      (click)="openBhaLineModal(dataItem.bhaLineId, bha.bhaId, j.jobId)"
                                      [disabled]="j.invoiceHasBeenSplit || jobService.job.value !== null && jobService.job.value.jobStatusIsTerminal === true"><i
                                        class="fa fa-pencil"></i></button>
                                  </ng-template>
                                </kendo-grid-column>
                                <kendo-grid-column field="outerDiameter"
                                  title="OD"
                                  filterable="false"
                                  [width]="70">
                                  <ng-template kendoGridCellTemplate
                                    let-dataItem
                                    let-rowIndex="rowIndex">
                                    {{dataItem.outerDiameter}}<span *ngIf="dataItem.outerDiameter!==null">"</span>
                                  </ng-template>
                                </kendo-grid-column>
                                <kendo-grid-column field="description"
                                  title="Description"
                                  filterable="description"
                                  [width]="150">
                                </kendo-grid-column>
                                <kendo-grid-column field="serialNumber"
                                  title="Serial#"
                                  filterable="false"
                                  [width]="90">
                                  <ng-template kendoGridCellTemplate
                                    let-dataItem
                                    let-rowIndex="rowIndex">
                                    <span *ngIf="dataItem.serialNumber">
                                      {{dataItem.serialNumber}}
                                    </span>
                                    <span *ngIf="!dataItem.serialNumber"
                                      class="text-muted">
                                      n/a
                                    </span>
                                  </ng-template>
                                </kendo-grid-column>
                                <kendo-grid-column field="innerDiameter"
                                  title="ID"
                                  filterable="false"
                                  [width]="70">
                                  <ng-template kendoGridCellTemplate
                                    let-dataItem
                                    let-rowIndex="rowIndex">
                                    {{dataItem.innerDiameter}}<span *ngIf="dataItem.innerDiameter!==null">"</span>
                                  </ng-template>
                                </kendo-grid-column>
                                <kendo-grid-column field="fnOd"
                                  title="FNOD"
                                  filterable="false"
                                  [width]="70">
                                  <ng-template kendoGridCellTemplate
                                    let-dataItem
                                    let-rowIndex="rowIndex">
                                    {{dataItem.fnOd}}<span *ngIf="dataItem.fnOd!==null">"</span>
                                  </ng-template>
                                </kendo-grid-column>
                                <kendo-grid-column field="fnLength"
                                  title="FN Length"
                                  filterable="false"
                                  [width]="90">
                                  <ng-template kendoGridCellTemplate
                                    let-dataItem
                                    let-rowIndex="rowIndex">
                                    {{dataItem.fnLength}}<span *ngIf="dataItem.fnLength!==null">'</span>
                                  </ng-template>
                                </kendo-grid-column>
                                <kendo-grid-column field="length"
                                  title="Length"
                                  filterable="false"
                                  [width]="75">
                                  <ng-template kendoGridCellTemplate
                                    let-dataItem
                                    let-rowIndex="rowIndex">
                                    {{dataItem.length}}<span *ngIf="dataItem.length!==null">'</span>
                                  </ng-template>
                                </kendo-grid-column>
                                <kendo-grid-column field="accumLength"
                                  title="Accum Length"
                                  filterable="false"
                                  [width]="105">
                                  <ng-template kendoGridCellTemplate
                                    let-dataItem
                                    let-rowIndex="rowIndex">
                                    {{dataItem.accumLength}}<span *ngIf="dataItem.accumLength!==null">'</span>
                                  </ng-template>
                                </kendo-grid-column>
                                <kendo-grid-column field="notes"
                                  title="Remarks"
                                  filterable="false"
                                  *ngIf="!showDetails && isTablet">
                                </kendo-grid-column>
                                <kendo-grid-column field="one"
                                  title=""
                                  [width]="60"
                                  filterable="false">
                                  <ng-template kendoGridCellTemplate
                                    let-dataItem
                                    let-rowIndex="rowIndex">
                                    <button class="btn btn-xs"
                                      [swal]="{ title: 'Delete BHA Line?', text:'Delete Item #' + dataItem.rowNumber + ' from BHA #' + bha.bhaNumber +'?', showCancelButton: true, confirmButtonText: 'Delete', confirmButtonColor: '#DC3545' }"
                                      (confirm)="deleteBhaLine(dataItem.bhaLineId, j.jobId)"
                                      [title]="'Delete BHA Item #' + dataItem.rowNumber"
                                      [disabled]="j.invoiceHasBeenSplit || jobService.job.value !== null && jobService.job.value.jobStatusIsTerminal === true"><i
                                        class="fa fa-trash"></i></button>
                                  </ng-template>
                                </kendo-grid-column>
                              </kendo-grid>
                              <!--END KENDO GRID -->
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </tab>
                </tabset>
              </div>
            </tab>
            <!-- END BHA -->
            <!--INVOICE -->
            <tab heading="Invoice"
              id="tab5"
              *ngIf="userService.user.value && userService.user.value.activeRoles && userService.user.value.activeRoles.includes('General')"
              [disabled]="enableReorder || enableReorderToolList">
              <div class="alert alert-primary mb-0"
                role="alert"
                *ngIf="j.invoices.length === 0 || (j.invoices.length > 0 && (j.invoices[0].invoiceItems !== undefined && j.invoices[0].invoiceItems.length === 0))">
                No <strong>Invoice Items</strong> added for Job#: {{j.jobNumber}}.
              </div>
              <ng-container
                *ngIf="j.invoices.length > 0 && (j.invoices[0].invoiceItems === undefined || j.invoices[0].invoiceItems.length > 0)">
                <ng-container *ngFor="let inv of j.invoices;let i = index;">
                  <app-fishing-invoice-table [invoice]="inv"
                    [job]="j"
                    (enableReorderEvent)="handleEnableReorderEvent($event)"></app-fishing-invoice-table>
                </ng-container>
              </ng-container>
              <div class="d-flex justify-content-end"
                *ngIf="j.totalStamp">
                <div class="card border-top-primary border-top-2 rounded-top-0 col-lg-3 col-md-5 col-sm-6"
                  style="border-top-color: #2196F3 !important;">
                  <div class="card-body">
                    <table style="float:right;">
                      <tr>
                        <td style="font-weight: 500;">Sub Total:</td>
                        <td width="20px">&nbsp;</td>
                        <td>{{j.subTotalStamp | currency}}</td>
                      </tr>
                      <tr>
                        <td style="font-weight: 500;">Tax&nbsp;<small>({{j.salesTaxRateStamp | percent:'0.2'}})</small>:
                        </td>
                        <td width="20px">&nbsp;</td>
                        <td>{{j.salesTaxTotalStamp | currency}}</td>
                      </tr>
                      <tr>
                        <td style="font-weight: 500;">TOTAL:</td>
                        <td width="20px">&nbsp;</td>
                        <td><strong>{{j.totalStamp | currency}}</strong></td>
                      </tr>
                    </table>
                  </div>
                </div>
              </div>
            </tab>
            <!--END INVOICE -->
          </tabset>
        </div>
      </div>
    </div>
  </div>
</div>
<div *ngIf="!jobService.job.value"
  class="page-content pt-0">
  <div class="content-wrapper">
    <div class="content">
      <div class="row">
        <div class="col-lg-3">
          <div class="card">
            <div class="card-header bg-light header-elements-inline">
              <h6 class="card-title">Invoice</h6>
              <div class="header-elements">
                <button class="btn btn-light btn-sm"
                  [disabled]="true"><i class="fa fa-edit"></i> Edit</button>
              </div>
            </div>
            <div class="card-body">
              <app-list-skeleton [numberToIterate]="11"></app-list-skeleton>
            </div>
          </div>
        </div>
        <div class="col-lg-9">
          <tabset [justified]="true"
            class="compact-nav">
            <tab heading="Daily Fishing Report"
              id="tab1"
              [disabled]="true">
              <div class="d-flex flex-row-reverse mb-3">
                <button class="btn btn-light"
                  [disabled]="true"><i class="fas fa-plus"></i> New Day</button>
              </div>
              <div class="row">
                <div class="col-sm-4"
                  [ngClass]="{'col-md-5':isTablet}">
                  <div class="card">
                    <div class="card-header bg-light header-elements-inline">
                      <h6 class="card-title">Summary Card</h6>
                      <div class="header-elements">
                        <button class="btn btn-danger btn-sm"
                          style="float: right"
                          [disabled]="true"><i class="fa fa-trash"></i></button>
                      </div>
                    </div>
                    <div class="card-body"
                      [ngClass]="{'pl-2 pr-2':isTablet}">
                      <app-list-skeleton [numberToIterate]="4"></app-list-skeleton>
                    </div>
                  </div>
                </div>
                <div class="col-sm-8"
                  [ngClass]="{'col-md-7':isTablet}">
                  <div class="card">
                    <div class="card-header bg-light header-elements-inline">
                      <h6 class="card-title">BHA</h6>
                    </div>
                    <div class="card-body"
                      [ngClass]="{'pl-2 pr-2':isTablet}">
                      <app-table-skeleton [numberToIterate]="2"></app-table-skeleton>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card">
                <div class="card-header bg-light header-elements-inline">
                  <h6 class="card-title">Operations Breakdown</h6>
                  <div class="header-elements">
                    <button class="btn btn-light btn-sm"
                      [disabled]="true"><i class="fas fa-plus"></i> Add</button>
                  </div>
                </div>
                <div class="card-body"
                  [ngClass]="{'pl-2 pr-2':isTablet}">
                  <div class="table-responsive">
                    <app-table-skeleton [numberToIterate]="2"></app-table-skeleton>
                  </div>
                </div>
              </div>
            </tab>
            <tab heading="JSA"
              id="tab5"
              [disabled]="true">
            </tab>
            <tab heading="Fishing Tool Report"
              id="tab2"
              [disabled]="true">
            </tab>
            <tab heading="BHA"
              id="tab3"
              [disabled]="true">
            </tab>
            <tab heading="Invoice"
              id="tab4"
              [disabled]="true">
            </tab>
          </tabset>
        </div>
      </div>
    </div>
  </div>
</div>
