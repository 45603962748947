import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Observer } from 'rxjs';
import { WellFilterParamsModel } from 'src/models/RequestParams/well-filter-params.model';
import { WellModel } from '../models/well.model';

@Injectable()
export class WellService {
  constructor(
    private http: HttpClient,
    @Inject('BASE_URL') private baseUrl: string
  ) { }

  public wellList: BehaviorSubject<Array<WellModel>> = new BehaviorSubject(null);
  public wellFilterParams: WellFilterParamsModel = new WellFilterParamsModel();
  public wellCache: BehaviorSubject<WellModel> = new BehaviorSubject(null);

  private apiUrl = 'api/Well';  // URL to web api

  public getWells(showInactive: boolean): void {
    this.http.post<Array<WellModel>>(this.baseUrl + this.apiUrl + '/GetWells/' + showInactive, this.wellFilterParams)
      .subscribe((x: WellModel[]) => {
        this.wellList.next(x);
      });
  }

  public getWellsByCustomerId(
    customerId: number,
    showInactive: boolean): void {
    this.getWellsByCustomerIdApiCall(customerId, showInactive)
      .subscribe((x: WellModel[]) => {
        this.wellList.next(x);
      });
  }

  public getWellsByCustomerIdApiCall(
    customerId: number,
    showInactive: boolean): Observable<Array<WellModel>> {
    return this.http.get<Array<WellModel>>(this.baseUrl + this.apiUrl + '/GetWellsByCustomerId/' + customerId + '/' + showInactive);
  }

  public getWellById(id: number): Observable<WellModel> {
    return this.http.get<WellModel>(this.baseUrl + this.apiUrl + '/GetWellById/' + id);
  }

  public addEditWell(
    dto: WellModel,
    showInactive: boolean,
    getByCustomer: boolean = false,
    cacheWell: boolean = false): Observable<boolean> {
    dto = this.cleanWellModel(dto);
    return new Observable((observer: Observer<boolean>) => {
      this.http.post<number>(this.baseUrl + this.apiUrl + '/AddEditWell', dto)
        .subscribe((x: number) => {
          if (x !== -1) {
            if (cacheWell) {
              dto.wellId = x;
              this.wellCache.next(dto);
            }
            if (!getByCustomer) {
              this.getWells(showInactive);
            } else {
              this.getWellsByCustomerId(dto.customerId, showInactive);
            }
          }
          observer.next(x !== -1);
        });
    });
  }

  public deleteWell(
    dto: WellModel,
    showInactive: boolean,
    getByCustomer: boolean = false): Observable<boolean> {
    return new Observable((observer: Observer<boolean>) => {
      this.http.get<boolean>(this.baseUrl + this.apiUrl + '/DeleteWell/' + dto.wellId)
        .subscribe((x: boolean) => {
          if (x === true) {
            if (!getByCustomer) {
              this.getWells(showInactive);
            } else {
              this.getWellsByCustomerId(dto.customerId, showInactive);
            }
          }
          observer.next(x);
        });
    });
  }

  public clearFilterParams(showInactive: boolean) {
    this.wellFilterParams.name = null;
    this.wellFilterParams.countyId = null;
    this.wellFilterParams.customerId = null;

    this.getWells(showInactive);
  }

  public generateNewWellModel(): WellModel {
    let model: WellModel = new WellModel();
    model.isActive = true;

    return model;
  }

  private cleanWellModel(model: WellModel): WellModel {
    model.name = model.name
      && model.name.trim() !== '' ?
      model.name.trim()
      : null;

    return model;
  }

}
