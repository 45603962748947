import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Observer } from 'rxjs';
import { ContactModel } from '../models/contact.model';
import { ContactTypes } from 'src/enums/contact-types.enum';

@Injectable()
export class ContactService {
  constructor(
    private http: HttpClient,
    @Inject('BASE_URL') private baseUrl: string
  ) { }

  public contactList: BehaviorSubject<Array<ContactModel>> = new BehaviorSubject(null);
  public contactCache: BehaviorSubject<ContactModel> = new BehaviorSubject(null);

  private apiUrl = 'api/Contact';  // URL to web api

  public refreshContactList(
    type: number,
    id: number,
    showInactive: boolean): void {
    if (type === ContactTypes.Customer) {
      this.getCustomerContacts(id, showInactive)
        .subscribe((x: ContactModel[]) => {
          this.contactList.next(x);
        });
    }
  }

  public getCustomerContacts(
    id: number,
    showInactive: boolean): Observable<Array<ContactModel>> {
    return this.http.get<Array<ContactModel>>(this.baseUrl + this.apiUrl + '/GetCustomerContacts/' + id + '/' + showInactive);
  }

  public getContactById(id: number): Observable<ContactModel> {
    return this.http.get<ContactModel>(this.baseUrl + this.apiUrl + '/GetContactById/' + id);
  }

  public addEditContact(
    dto: ContactModel,
    showInactive: boolean,
    cacheContact: boolean): Observable<boolean> {
    return new Observable((observer: Observer<boolean>) => {
      const url = this.baseUrl + this.apiUrl + '/AddEditContact';

      this.http.post<number>(url, this.cleanContactModel(dto))
        .subscribe((x: number) => {
          if (x !== -1) {
            if (cacheContact) {
              dto.contactId = x;
              this.contactCache.next(dto);
            }

            if (dto.customerId !== null) {
              this.refreshContactList(ContactTypes.Customer, dto.customerId, showInactive);
            } else if (dto.vendorId !== null) {
              this.refreshContactList(ContactTypes.Vendor, dto.vendorId, showInactive);
            }
          }
          observer.next(x !== -1);
        });
    });
  }

  public deleteContact(
    dto: ContactModel,
    showInactive: boolean): Observable<boolean> {
    return new Observable((observer: Observer<boolean>) => {
      this.http.get<boolean>(this.baseUrl + this.apiUrl + '/DeleteContact/' + dto.contactId)
        .subscribe((x: boolean) => {
          if (x === true) {
            if (dto.customerId !== null) {
              this.refreshContactList(ContactTypes.Customer, dto.customerId, showInactive);
            } else if (dto.vendorId !== null) {
              this.refreshContactList(ContactTypes.Vendor, dto.vendorId, showInactive);
            }
          }
          observer.next(x);
        });
    });
  }

  public generateNewContactModel(
    vendorId: number = null,
    customerId: number = null): ContactModel {
    let model: ContactModel = new ContactModel();
    model.vendorId = vendorId;
    model.customerId = customerId;
    model.isActive = true;

    return model;
  }

  public cleanContactModel(model: ContactModel): ContactModel {
    Object.keys(model).forEach((index: string) => {
      if (typeof model[index] === 'string') {
        model[index] = model[index]
          && model[index].trim() !== '' ?
          model[index].trim()
          : null;
      }
    });

    return model;
  }
}
