<div class="modal-header"
  ngxModalDraggable>
  <h4 class="modal-title pull-left"
    *ngIf="model">{{isNew ? 'Add ' : 'Edit '}}Job Status</h4>
  <button type="button"
    class="close pull-right"
    aria-label="Close"
    (click)="bsModalRef.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body"
  *ngIf="model">
  <div class="form-group row">
    <label for="title"
      class="col-sm-2 text-right control-label col-form-label">Name</label>
    <div class="col-sm-10 form-group mb-0">
      <input type="text"
        class="form-control form-control-sm"
        id="name"
        [(ngModel)]="model.name"
        name="name"
        required>
    </div>
  </div>
  <div class="form-group row">
    <label for="title"
      class="col-sm-2 text-right control-label col-form-label">Job Type</label>
    <div class="col-sm-10 form-group mb-0">
      <ng-select [items]="jobTypes"
        bindLabel="name"
        bindValue="jobTypeId"
        (change)="onChangeJobType($event)"
        [clearable]="false"
        [(ngModel)]="model.jobTypeId"
        name="jobTypes"
        required></ng-select>
    </div>
  </div>
  <div class="form-group row">
    <label for="title"
      class="col-sm-2 text-right control-label col-form-label">Order</label>
    <div class="col-sm-4 form-group mb-0">
      <input type="number"
        class="form-control form-control-sm"
        id="statusOrder"
        [(ngModel)]="model.statusOrder"
        name="statusOrder"
        required>
    </div>
    <label for="title"
      class="col-sm-2 text-right control-label col-form-label">Is Terminal</label>
    <div class="col-sm-4 form-group mb-0">
      <ui-switch [(ngModel)]="model.isTerminal"
        name="isTerminal"></ui-switch>
    </div>
  </div>
</div>
<div class="modal-footer"
  *ngIf="model">
  <button type="button"
    class="btn btn-primary"
    (click)="onSave()"
    [disabled]="!(model.name && model.statusOrder)">Save</button>
  <button type="button"
    class="btn btn-default"
    (click)="bsModalRef.hide()">Cancel</button>
</div>
