import { MaintenanceEventPoLineLinkModel } from './maintenance-po-line-link.model';
import { PurchaseOrderLineModel } from './purchase-order-line.model';

export class MaintenanceEventModel {
  maintenanceEventId: number;
  itemInstanceId: number;
  serialNumber: string;
  itemDescription: string;
  partNumber: string;
  maintenanceTypeId: number;
  maintenanceTypeName: string;
  maintenanceDate: Date | string;
  inHouseRepair: boolean | null;
  vendorId: number | null;
  vendorName: string;
  hrsMileage: number | null;
  maintenanceDurationUom: string;
  notes: string;
  isActive: boolean | null;
  createUser: number | null;
  userName: string;

  maintenanceEventDropDisplay: string;
  poTotal: number | null;
  ponumberDisplay: string;
  purchaseOrderLines: PurchaseOrderLineModel[];
  maintenanceEventPoLineLinks: MaintenanceEventPoLineLinkModel;
}
