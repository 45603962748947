export class UserModel {
  id: number;
  userName: string;
  password: string = '';
  email: string;
  phoneNumber: string;
  twoFactorEnabled: boolean;
  activeRoles: Array<string> = [];
  jobSubStatusIds: number[];


  defaultShopId: number;
  defaultShopName: string;
  firstName: string;
  lastName: string;
  displayName: string;
  roleMapDisplayName: string;
  poLimit: number;
  invoiceApprovalLimit: number | null;
  canApproveInvoices: boolean;
  isActive: boolean;
}
