<div class="modal-header"
  ngxModalDraggable>
  <h4 class="modal-title pull-left"
    *ngIf="model && !isLoading">Worksheet #{{worksheetId}} - Snapshot History</h4>
  <h4 class="modal-title pull-left"
    *ngIf="isLoading"><i class="fad fa-spinner fa-spin"></i>&nbsp;Generating Snapshots... </h4>
  <button type="button"
    class="close pull-right"
    aria-label="Close"
    (click)="bsModalRef.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body fixed-height-compare">
  <div class="row"
    *ngIf="isLoading">
    <div [ngClass]="{'col-md-5' : isTablet, 'col-md-6' : !isTablet}">
      <app-table-skeleton [numberToIterate]="8"></app-table-skeleton>
    </div>
    <div [ngClass]="{'col-md-5' : isTablet, 'col-md-6' : !isTablet}">
      <app-table-skeleton [numberToIterate]="8"></app-table-skeleton>
    </div>
  </div>
  <div class="row"
    *ngIf="!isLoading">
    <div class="col-md-6 h-100">
      <h3 class="mb-0">Current</h3>
      <hr class="mt-0">
      <div class="gray-background p-2 h-100">
        <table width="100%">
          <thead>
            <tr>
              <th></th>
              <th>Item</th>
              <th>Quantity</th>
            </tr>
          </thead>
          <tbody>
            <ng-container *ngFor="let row of model.currentWorksheet.worksheetRowsCombined">
              <tr *ngIf="row.quantity > 0">
                <td><i class="icon-gear"
                    style="color: gray;"></i></td>
                <td>{{row.selectedItemName}}</td>
                <td>{{row.quantity}}</td>
              </tr>
            </ng-container>
          </tbody>
        </table>
        <hr class="mb-2 mt-2" />
        <dl class="row w-100">
          <dt class="col-sm-2 mb-0 pr-0  =text-left">Notes:</dt>
          <dd class="col-sm-10 mb-1 pr-0">
            <span *ngIf="model.currentWorksheet.notes">
              {{model.currentWorksheet.notes}}
            </span>
            <span *ngIf="!model.currentWorksheet.notes"
              class="text-muted">
              n/a
            </span>
          </dd>
          <dt class="col-sm-2 mb-0 pr-0 text-left"
            *ngIf="userService.user.value && userService.user.value.activeRoles && userService.user.value.activeRoles.includes('General')">
            Price:</dt>
          <dd class="col-sm-10 mb-1 pr-0"
            *ngIf="userService.user.value && userService.user.value.activeRoles && userService.user.value.activeRoles.includes('General')">
            <span *ngIf="model.currentWorksheet.priceStamp">
              {{model.currentWorksheet.priceStamp | currency}}
            </span>
            <span *ngIf="!model.currentWorksheet.priceStamp"
              class="text-muted">
              n/a
            </span>
          </dd>
          <dt class="col-sm-2 mb-0 pr-0 text-left"
            *ngIf="userService.user.value && userService.user.value.activeRoles && userService.user.value.activeRoles.includes('General')">
            Cost:</dt>
          <dd class="col-sm-10 mb-1 pr-0"
            *ngIf="userService.user.value && userService.user.value.activeRoles && userService.user.value.activeRoles.includes('General')">
            <span *ngIf="model.currentWorksheet.costStamp">
              {{model.currentWorksheet.costStamp | currency}}
            </span>
            <span *ngIf="!model.currentWorksheet.costStamp"
              class="text-muted">
              n/a
            </span>
          </dd>
          <dt class="col-sm-2 mb-0 pr-0 text-left">
            Builder:</dt>
          <dd class="col-sm-10 mb-1 pr-0">
            <span *ngIf="model.currentWorksheet.employeeName">
              {{model.currentWorksheet.employeeName}}
            </span>
            <span *ngIf="!model.currentWorksheet.employeeName"
              class="text-muted">
              n/a
            </span>
          </dd>
        </dl>
      </div>
      <div class="mt-1">
        <dl class="row w-100">
          <dt class="col-sm-3 mb-0 pr-0">Current Date</dt>
          <dd class="col-sm-9 mb-1 pr-0">{{now | date:'short'}}</dd>
          <dt class="col-sm-3 mb-0 pr-0">Snapshot Type</dt>
          <dd class="col-sm-9 mb-1 pr-0 text-muted">n/a</dd>
          <dt class="col-sm-3 mb-0 pr-0">Current Job#</dt>
          <dd class="col-sm-9 mb-1 pr-0">
            <span *ngIf="jobNumber">{{jobNumber}}</span>
            <span *ngIf="!jobNumber"
              class="text-muted">n/a</span>
          </dd>
          <dt class="col-sm-3 mb-0 pr-0"
            *ngIf="userName">User</dt>
          <dd class="col-sm-9 mb-1 pr-0"
            *ngIf="userName">{{userName}}</dd>
        </dl>
      </div>
    </div>
    <div class="col-md-6 h-100">
      <h3 class="mb-0">Snapshots</h3>
      <hr class="mt-0">
      <div class="list-container"
        *ngIf="model.snapshots.length > 0">
        <div *ngFor="let snap of model.snapshots">
          <div class="gray-background p-2 h-100 d-inline-block w-100">
            <table width="100%">
              <thead>
                <tr>
                  <th></th>
                  <th>Item</th>
                  <th>Quantity</th>
                </tr>
              </thead>
              <tbody>
                <ng-container *ngFor="let row of snap.worksheetRowsCombined">
                  <tr *ngIf="row.quantity > 0">
                    <td><i class="icon-gear"
                        style="color: gray;"></i></td>
                    <td>{{row.selectedItemName}}</td>
                    <td>{{row.quantity}}</td>
                  </tr>
                </ng-container>
              </tbody>
            </table>
            <hr class="mb-2 mt-2" />
            <dl class="row w-100">
              <dt class="col-sm-2 mb-0 pr-0 text-left">Notes:</dt>
              <dd class="col-sm-10 mb-1 pr-0">{{snap.notes}}</dd>
              <dt class="col-sm-2 mb-0 pr-0 text-left"
                *ngIf="userService.user.value && userService.user.value.activeRoles && userService.user.value.activeRoles.includes('General')">
                Price:</dt>
              <dd class="col-sm-10 mb-1 pr-0"
                *ngIf="userService.user.value && userService.user.value.activeRoles && userService.user.value.activeRoles.includes('General')">
                {{snap.priceStamp | currency}}</dd>
              <dt class="col-sm-2 mb-0 pr-0 text-left"
                *ngIf="userService.user.value && userService.user.value.activeRoles && userService.user.value.activeRoles.includes('General')">
                Cost:</dt>
              <dd class="col-sm-10 mb-1 pr-0"
                *ngIf="userService.user.value && userService.user.value.activeRoles && userService.user.value.activeRoles.includes('General')">
                {{snap.costStamp | currency}}</dd>
              <dt class="col-sm-2 mb-0 pr-0 text-left">
                Builder:</dt>
              <dd class="col-sm-10 mb-1 pr-0">
                <span *ngIf="snap.employeeName">
                  {{snap.employeeName}}
                </span>
                <span *ngIf="!snap.employeeName"
                  class="text-muted">
                  n/a
                </span>
              </dd>
            </dl>
          </div>
          <div>
            <dl class="row w-100">
              <dt class="col-sm-3 mb-0 pr-0">Snapshot Date</dt>
              <dd class="col-sm-9 mb-1 pr-0">{{snap.worksheetSnapshotDate | date:'short'}}</dd>
              <dt class="col-sm-3 mb-0 pr-0">Snapshot Type</dt>
              <dd class="col-sm-9 mb-1 pr-0">{{snap.worksheetSnapshotType}}</dd>
              <dt class="col-sm-3 mb-0 pr-0">Job#</dt>
              <dd class="col-sm-9 mb-1 pr-0">
                <span *ngIf="snap.jobNumber">{{snap.jobNumber}}</span>
                <span *ngIf="!snap.jobNumber"
                  class="text-muted">n/a</span>
              </dd>
              <dt class="col-sm-3 mb-0 pr-0">User</dt>
              <dd class="col-sm-9 mb-1 pr-0">{{snap.worksheetSnapshotUser}}</dd>
            </dl>
          </div>
          <hr>
        </div>
      </div>
      <div class="list-container"
        *ngIf="model.snapshots.length === 0">
        <div class="alert alert-primary"
          role="alert">
          <i class="fad fa-check"></i>&nbsp;&nbsp;There are currently no <strong>Snapshots.</strong>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="modal-footer"
  *ngIf="model">
  <button type="button"
    class="btn btn-default"
    (click)="bsModalRef.hide()">Close</button>
</div>
