import {
  Component,
  ElementRef,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BsDropdownConfig } from 'ngx-bootstrap/dropdown';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Observable, Subscription } from 'rxjs';
import { JobTypes } from 'src/enums/job-types';
import { SearchDataModel } from 'src/models/search-data.model';
import { AppStateService } from 'src/services/app-state.service';
import { PartGroupService } from 'src/services/part-group.service';
import { SearchService } from 'src/services/search.service';
import { UserService } from 'src/services/user.service';
import { AddEditJobSalesModalComponent } from '../jobs/shared/add-edit-job-sales-modal/add-edit-job-sales-modal.component';
import { AddEditPoModalComponent } from '../purchase-orders/add-edit-po-modal/add-edit-po-modal.component';
import { ChangePasswordModalComponent } from '../users/change-password-modal/change-password-modal.component';
import { AddEditVendorModalComponent } from '../vendors/add-edit-vendor-modal/add-edit-vendor-modal.component';
import { ChooseWorksheetModalComponent } from '../worksheets/choose-worksheet-modal/choose-worksheet-modal.component';
import { PartFamilyModel } from 'src/models/part-family.model';

@Component({
  selector: 'app-top-nav',
  templateUrl: './top-nav.component.html',
  styleUrls: ['./top-nav.component.css'],
  providers: [{ provide: BsDropdownConfig, useValue: { isAnimated: true, autoClose: true } }]
})

export class TopNavComponent implements OnInit, OnDestroy {
  public isCollapsed: boolean = true;
  public topIsCollapsed: boolean = true;
  public bsModalRef: BsModalRef;
  public bsModalRef2: BsModalRef;
  public searchCtrl: UntypedFormControl = new UntypedFormControl();
  public searchResults: Observable<Array<SearchDataModel>>;
  public searchTerm: string = '';
  public partGroupId: number = null;
  public isTest = false;
  public advancedSearch = false;
  public searchPlaceHolder = 'Search';
  public JobTypes = JobTypes;

  private isTabletSub: Subscription;
  public isTablet: boolean = false;

  @ViewChild('searchInput') el: ElementRef<HTMLElement>;

  constructor(
    private modalService: BsModalService,
    public userService: UserService,
    public searchService: SearchService,
    public partGroupService: PartGroupService,
    public router: Router,
    public activatedRoute: ActivatedRoute,
    public appStateService: AppStateService,
    private deviceService: DeviceDetectorService,
    @Inject('BASE_URL') private baseUrl: string
  ) {
    this.searchResults = searchService;
  };

  ngOnDestroy(): void {
    this.isTabletSub?.unsubscribe();
  }

  ngOnInit(): void {
    this.updateIsTablet();
    this.userService.refreshUser(true);
    this.partGroupService.getItemInstancePartGroups();
    this.determineIfTest(this.baseUrl);
    this.determineIsTablet();
  }

  public updateIsTablet(): void {
    // const deviceInfo: DeviceInfo = this.deviceService.getDeviceInfo();
    // const isMobile: boolean = this.deviceService.isMobile();
    // const isDesktopDevice: boolean = this.deviceService.isDesktop();
    this.isTablet = this.deviceService.isTablet();

    this.appStateService.updateIsTablet(this.isTablet);
  }

  public determineIsTablet(): void {
    this.isTabletSub = this.appStateService.isTablet$
      .subscribe((isTablet: boolean) => {
        this.isTablet = isTablet;
      });
  }

  public createPoModal(): void {
    const initialState = {
      id: 0,
    };

    this.bsModalRef = this.modalService.show(AddEditPoModalComponent, { initialState, backdrop: 'static' });
  }

  public openPasswordModal(): void {
    const initialState = {
    };

    this.bsModalRef2 = this.modalService.show(ChangePasswordModalComponent, { initialState, backdrop: 'static' });
  }

  public openJobModal(
    jobId: number,
    jobTypeId: number,
    isQuote: boolean = false): void {
    const initialState = {
      id: jobId,
      jobTypeId: jobTypeId,
      isQuote: isQuote
    };

    this.bsModalRef = this.modalService.show(AddEditJobSalesModalComponent, { initialState, class: 'modal-lg modal-xl', backdrop: 'static' });
  }

  public getSearchResults($event: any): void {
    if (this.searchCtrl.value.length >= 2) {
      this.searchService.query(this.searchCtrl.value, this.partGroupId);
    }
    if (this.searchCtrl.value.length < 2) {
      this.loadRecentlySearched();
    }
  }

  public openChooseWorksheetModal(): void {
    const modalSize = this.isTablet ? 'modal-xl' : 'modal-lg';

    const initialState = {
      jobId: null,
      packersOnly: true
    };
    this.bsModalRef = this.modalService.show(ChooseWorksheetModalComponent, { initialState, class: modalSize, backdrop: 'static' });
  }

  public goToSelection(selection: SearchDataModel): void {
    let navigate: boolean = true;

    this.el.nativeElement.blur();

    let link: string = '';

    switch (selection.type) {
      case 'jobs':
        link = `./${selection.type}/${selection.jobType}/details/${selection.id}`;
        break;
      case 'purchase-orders':
        link = `./${selection.type}/details/${selection.id}`;
        break;
      case 'items':
        link = `./${selection.type}/details/${selection.id}`;
        break;
      case 'customers':
        link = `./${selection.type}/details/${selection.id}`;
        break;
      case 'vendors':
        navigate = false;
        this.openVendorModal(selection.id);
        break;
      case 'item-instances':
        link = `./${selection.type}/details/${selection.id}`;
        break;
      default:
        link = './';
    }

    if (navigate) {
      this.router.navigate([link], { relativeTo: this.activatedRoute.parent });
    }

    if (this.userService.user.value) {
      this.userService.addSearchResult(selection, this.userService.user.value.id);
    }
  }

  public displayFn(selection: SearchDataModel): string {
    return '';
  }

  public onFocus($event: any): void {
    if ($event
      && ($event.target.value === '' || $event.target.value === null)
      && this.userService.user.value) {
      this.searchTerm = '';
      this.loadRecentlySearched();
    }
  }

  public loadRecentlySearched(): void {
    this.userService.getRecentSearchesByUserId(this.userService.user.value.id);
  }

  public clearSearchResults($event: any): void {
    if ($event && ($event.target.value === '' || $event.target.value === null)) {
      this.searchTerm = '';
      this.searchService.query(null, this.partGroupId);
    }
  }

  public openVendorModal(vendorId: number): void {
    const initialState = {
      id: vendorId,
      showInactive: false
    };
    this.bsModalRef = this.modalService.show(AddEditVendorModalComponent, { initialState, class: 'modal-lg', backdrop: 'static' });
  }

  public onChangeOfPartGroup($event: PartFamilyModel) {
    this.getSearchResults($event);
  }

  public toggleAdvancedSearch($event: any) {
    this.advancedSearch = !this.advancedSearch;

    this.searchTerm = '';

    this.searchPlaceHolder = this.advancedSearch ? 'Search by Serial #' : 'Search';

    if (this.advancedSearch
      && this.partGroupId === null
      && this.partGroupService.itemInstancePartGroupList.value !== null
      && this.partGroupService.itemInstancePartGroupList.value.length > 0) {
      this.partGroupId = this.partGroupService.itemInstancePartGroupList.value[0].partGroupId;
    } else {
      this.partGroupId = null;
    }
  }

  private determineIfTest(url: string): void {
    this.isTest = url.includes('test.griztools');
  }

}
