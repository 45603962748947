import { Component, OnDestroy, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { EmployeeModel } from 'src/models/employee.model';
import { EmployeeService } from 'src/services/employee.service';
import { UIService } from 'src/services/ui.service';

@Component({
  selector: 'app-add-edit-employee-modal',
  templateUrl: './add-edit-employee-modal.component.html',
  styleUrls: ['add-edit-employee-modal.component.css']
})

export class AddEditEmployeeModalComponent implements OnInit, OnDestroy {
  public isNew: boolean = false;
  public id: number = -1;
  public showInactive: boolean = false;

  private modelSub: Subscription;
  public model: EmployeeModel;

  private saveSub: Subscription;

  constructor(
    public bsModalRef: BsModalRef,
    public uiService: UIService,

    public employeeService: EmployeeService,
  ) { };

  ngOnDestroy(): void {
    this.modelSub?.unsubscribe();

    this.saveSub?.unsubscribe();
  }

  ngOnInit(): void {
    this.showInactive = this.showInactive === true;

    this.isNew = this.id === 0;

    if (this.isNew) {
      this.model = this.employeeService.generateNewEmployeeModel();
    } else {
      this.getModel();
    }
  }

  public getModel(): void {
    this.modelSub = this.employeeService.getEmployeeById(this.id)
      .subscribe((x: EmployeeModel) => {
        this.model = x;
      });
  }

  public onSave(): void {
    this.saveSub = this.employeeService.addEditEmployee(
      this.model,
      this.showInactive)
      .subscribe((x: boolean) => {
        if (x) {
          this.bsModalRef.hide();
          this.uiService.showSuccess('Employee Saved', 'Success');
        } else {
          this.uiService.showError('Employee Not Saved', 'Error');
        }
      });
  }

}
