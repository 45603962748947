import { BehaviorSubject, Observable, Observer } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ItemTypeModel } from 'src/models/item-type.model';
import { tap } from 'rxjs/operators';

@Injectable()
export class ItemTypeService {
  constructor(
    private http: HttpClient,
    @Inject('BASE_URL') private baseUrl: string
  ) { }

  private apiUrl = 'api/ItemType';  // URL to web api

  private itemTypeListSubject = new BehaviorSubject<ItemTypeModel[]>(null);
  public _itemTypeList$ = this.itemTypeListSubject.asObservable();
  private isItemTypeCallInProgress = false;
  public itemTypeList: BehaviorSubject<ItemTypeModel[]> = new BehaviorSubject(null);

  public get itemTypeList$(): Observable<ItemTypeModel[]> {
    if (!this.itemTypeListSubject.getValue() && !this.isItemTypeCallInProgress) {
      this.isItemTypeCallInProgress = true;
      this.getItemTypes$(false).subscribe(() => this.isItemTypeCallInProgress = false);
    }

    return this._itemTypeList$;
  }

  public addEditItemType(dto: ItemTypeModel, showInactive: boolean = false): Observable<boolean> {
    dto = this.cleanItemTypeModel(dto);

    return new Observable((observer: Observer<boolean>) => {
      this.http.post<boolean>(this.baseUrl + this.apiUrl + '/AddEditItemType', dto)
        .subscribe((x: boolean) => {
          if (x === true) {
            this.getItemTypes(showInactive);
          }
          observer.next(x);
        });
    });
  }

  public deleteItemType(id: number, showInactive: boolean = false): Observable<boolean> {
    return new Observable((observer: Observer<boolean>) => {
      this.http.get<boolean>(this.baseUrl + this.apiUrl + '/DeleteItemType/' + id)
        .subscribe((x: boolean) => {
          if (x === true) {
            this.getItemTypes(showInactive);
          }
          observer.next(x);
        });
    });
  }

  public getItemTypeById(id: number): Observable<ItemTypeModel> {
    return this.http.get<ItemTypeModel>(this.baseUrl + this.apiUrl + '/GetItemTypeById/' + id);
  }

  public getItemTypes(showInactive: boolean = false): void {
    this.http.get<ItemTypeModel[]>(this.baseUrl + this.apiUrl + '/GetItemTypes/' + showInactive)
      .subscribe((x: ItemTypeModel[]) => {
        this.itemTypeList.next(x);
      });
  }

  public generateItemTypeModel(): ItemTypeModel {
    let model: ItemTypeModel = new ItemTypeModel();

    model.isActive = true;
    model.sortOrder = this.getLastOrderNumber();

    return model;
  }

  private getLastOrderNumber(): number {
    const last: number = this.itemTypeList.value !== null ? Math.max.apply(Math, this.itemTypeList.value.map((x: ItemTypeModel) => {
      return x.sortOrder;
    })) : null;

    return last !== null ? last + 1 : 0;
  }

  private getItemTypes$(showInactive: boolean): Observable<ItemTypeModel[]> {
    return this.http.get<Array<ItemTypeModel>>(this.baseUrl + this.apiUrl + '/GetItemTypes/' + showInactive)
      .pipe(
        tap((itemTypes: ItemTypeModel[]) => {
          this.itemTypeListSubject.next(itemTypes);
        })
      );
  }

  private cleanItemTypeModel(model: ItemTypeModel): ItemTypeModel {
    Object.keys(model).forEach((index: string) => {
      if (typeof model[index] === 'string') {
        model[index] = model[index]
          && model[index].trim() !== '' ?
          model[index].trim()
          : null;
      }
    });

    return model;
  }
}
