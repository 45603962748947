<div class="modal-header"
  ngxModalDraggable>
  <h4 class="modal-title pull-left"
    *ngIf="model">{{isNew ? 'Add' : 'Edit'}}&nbsp;Job Item</h4>
  <button type="button"
    class="close pull-right"
    aria-label="Close"
    (click)="bsModalRef.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body"
  *ngIf="model">
  <div class="form-group row"
    *ngIf="isNew || (!isNew && existingItemHasBillingCode)">
    <label for="title"
      class="col-sm-2 text-right control-label col-form-label">Billing Code</label>
    <div class="col-sm-10 form-group mb-0">
      <div class="input-group">
        <ng-select [items]="billingCodes"
          bindLabel="dropDownDisplay"
          bindValue="billingCodeId"
          [ngModel]="model.billingCodeId"
          name="billingCodes"
          class="col-md-9"
          style="padding:0px;"
          (change)="onChangeOfBillingCode($event)"
          [disabled]="billingCodes.length === 0||isFishingRental"
          required>
        </ng-select>
        <div class="input-group-append col-md-3"
          style="padding: 0;width:100%">
          <span class="input-group-text"
            style="padding: 0px;"><button (click)="openBillingCodeModal(0)"
              style="display: inline-flex;padding: 4px 10px;width:100%"
              class="btn btn-sm btn-default"
              [disabled]="isFishingRental||(isInventoryItem&&!this.model.itemId)"><i class="fa fa-plus"
                style="position: relative; top: 3px; margin-right: 5px;"></i>&nbsp;New</button></span>
        </div>
      </div>
    </div>
  </div>
  <div class="form-group row">
    <label for="title"
      class="col-sm-2 text-right control-label col-form-label">Description</label>
    <div class="col-sm-10 form-group mb-0">
      <input type="text"
        class="form-control form-control-sm"
        id="description"
        [(ngModel)]="model.description"
        name="description"
        required>
    </div>
  </div>
  <div class="form-group row">
    <label for="title"
      class="col-sm-2 text-right control-label col-form-label">Quantity</label>
    <div class="col-sm-4 form-group mb-0"
      *ngIf="!isInspection">
      <input type="number"
        step="1"
        class="form-control form-control-sm"
        id="quantity"
        [(ngModel)]="model.quantity"
        name="quantity"
        (input)="calculateTotal()"
        required
        [readonly]="!inEditMode && !isMisc">
      <!--||isFishingRental||(!isFishingRental&&!model.billingCodeId)-->
    </div>
    <div class="col-sm-4 form-group mb-0"
      *ngIf="isInspection">
      <div class="input-group">
        <input type="number"
          class="form-control form-control-sm"
          id="quantity"
          [(ngModel)]="model.quantity"
          name="quantity"
          required
          [readonly]="!editInspection&&!inEditMode"
          (input)="calculateTotal()">
        <div class="input-group-append"
          style="height: 32px;">
          <span class="input-group-text">
            <input type="checkbox"
              (change)="enableQtyEdit()"
              [checked]="editInspection"
              [disabled]="editInspection||inEditMode||!isFishingRental&&!model.billingCodeId">&nbsp;&nbsp;<span
              [ngClass]="{'text-muted': !editInspection}">Edit</span>
          </span>
        </div>
      </div>
      <div class="alert alert-warning"
        role="alert"
        *ngIf="showWarning"
        style="padding: 3px;margin-top: 5px;margin-bottom: 0px;">
        <i class="fas fa-exclamation-triangle"></i>&nbsp;&nbsp;Please only edit <strong>Quantity</strong> AFTER the
        Fishing Tool Report is finished.
      </div>
      <div class="alert alert-warning"
        role="alert"
        *ngIf="editInspection && !showWarning"
        style="padding: 3px;margin-top: 5px;margin-bottom: 0px;">
        <i class="fas fa-exclamation-triangle"></i>&nbsp;&nbsp;Qty has been edited.
      </div>
    </div>
    <label for="title"
      class="col-sm-2 text-right control-label col-form-label">Price</label>
    <div class="col-sm-4 form-group mb-0">
      <input type="number"
        step=".01"
        class="form-control form-control-sm"
        id="price"
        [(ngModel)]="model.price"
        name="price"
        (input)="calculateTotal()"
        [readonly]="disableInpCheckbox||!inEditMode||!isFishingRental&&!model.billingCodeId||(model && model.lockPrice === true)"
        required>
    </div>
  </div>
  <div class="form-group row">
    <label for="title"
      class="col-sm-2 text-right control-label col-form-label">Total</label>
    <div class="col-sm-4 form-group mb-0">
      <div class="input-group input-group-sm">
        <div class="input-group-prepend">
          <span class="input-group-text">$</span>
        </div>
        <input type="text"
          class="form-control form-control-sm"
          id="total"
          [ngModel]="total"
          name="total"
          readonly>
      </div>
    </div>
    <label for="title"
      class="col-sm-2 text-right control-label col-form-label">For Resale</label>
    <div class="col-sm-4 form-group mb-0">
      <ui-switch [(ngModel)]="model.isForResale"
        name="isForResale"
        [disabled]="isReadOnly"></ui-switch>
    </div>
  </div>
  <div class="form-group row"
    *ngIf="isInventoryItem && inventoryCount!==null">
    <label for="title"
      class="col-sm-2 text-right control-label col-form-label"></label>
    <div class="col-sm-4 form-group mb-0">
      <div class="alert alert-success p-1 mb-1"
        role="alert"
        *ngIf="inventoryCount > 0">
        <i class="fas fa-check-circle"></i>&nbsp;&nbsp;Current Inventory:
        <strong>{{inventoryCount!==null ? inventoryCount : 0}}</strong>
      </div>
      <div class="alert alert-danger p-1 mb-1"
        role="alert"
        *ngIf="inventoryCount <= 0">
        <i class="fas fa-exclamation-circle"></i>&nbsp;&nbsp;Current Inventory:
        <strong>{{inventoryCount!==null ? inventoryCount : 0}}</strong>
      </div>
    </div>
  </div>
  <div class="form-group row"
    *ngIf="invoiceHasBeenSplit && inEditMode">
    <label for="title"
      class="col-sm-2 text-right control-label col-form-label">Invoice</label>
    <div class="col-sm-10 form-group mb-0"
      *ngIf="invoiceService.invoiceList | async; let inv">
      <ng-select [items]="inv"
        bindLabel="dropdownDisplay"
        bindValue="invoiceId"
        [(ngModel)]="model.invoiceId"
        style="padding:0px;"
        name="items"
        [clearable]="false"
        required>
      </ng-select>
    </div>
  </div>
</div>
<div class="modal-footer"
  *ngIf="model">
  <button type="button"
    class="btn btn-primary"
    (click)="onSave()"
    [disabled]="disableSave 
      || !(model.quantity !== null 
        && model.quantity !== undefined 
        && model.price !==undefined 
        && model.price!==null 
        && (!isFishingRental 
          || (isFishingRental 
            && model.billingCodeId)))">Save</button>
  <button type="button"
    class="btn btn-default"
    (click)="bsModalRef.hide()">Cancel</button>
</div>
