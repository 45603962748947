<div class="modal-header"
  ngxModalDraggable>
  <h4 class="modal-title pull-left"
    *ngIf="model">{{isNew ? 'Add ' : 'Edit '}}Maintenance Log</h4>
  <button type="button"
    class="close pull-right"
    aria-label="Close"
    (click)="bsModalRef.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body"
  *ngIf="model">
  <div class="form-group row">
    <label for="title"
      class="col-sm-3 text-right control-label col-form-label">Date</label>
    <div class="col-sm-9 form-group mb-0">
      <input type="text"
        placeholder="mm/dd/yyyy"
        class="form-control"
        [(ngModel)]="model.maintenanceDate"
        required
        bsDatepicker>
    </div>
  </div>
  <div class="form-group row">
    <label for="title"
      class="col-sm-3 text-right control-label col-form-label">Shop Repair</label>
    <div class="col-sm-9 form-group mb-0">
      <ui-switch [ngModel]="model.inHouseRepair"
        name="inHouseRepair"
        (change)="onChangeInHouseRepair($event)"></ui-switch>
    </div>
  </div>
  <div class="form-group row"
    *ngIf="!model.inHouseRepair">
    <label for="title"
      class="col-sm-3 text-right control-label col-form-label">Vendor</label>
    <div class="col-sm-9 form-group mb-0"
      *ngIf="vendorService.vendorList | async; let vend">
      <div class="input-group">
        <ng-select [items]="vend"
          bindLabel="name"
          bindValue="vendorId"
          [(ngModel)]="model.vendorId"
          name="vendors"
          class="col-md-9"
          style="padding:0px;"
          (change)="onChangeVendor($event)"
          required>
        </ng-select>
        <div class="input-group-append col-md-3"
          style="padding:0;width:100%;">
          <span class="input-group-text"
            style="padding: 0px;"><button (click)="openVendorModal(0)"
              style="display: inline-flex;padding: 4px 10px;width:100%"
              class="btn btn-sm btn-default"><i class="fa fa-plus"
                style="position: relative; top: 3px; margin-right: 5px;"></i>&nbsp;New Vendor</button></span>
        </div>
      </div>
    </div>
  </div>
  <div class="form-group row"
    *ngIf="!model.inHouseRepair && seePrices">
    <label for="title"
      class="col-sm-3 text-right control-label col-form-label">Po Lines</label>
    <div class="col-sm-9 form-group mb-0">
      <ng-select [items]="lineList"
        [multiple]="true"
        [closeOnSelect]="false"
        [searchable]="false"
        placeholder="Select PO Lines"
        bindLabel="dropDownDisplay"
        [(ngModel)]="selectedLines"
        [readonly]="lineList.length === 0"
        name="selectedLines">
      </ng-select>
    </div>
  </div>
  <div class="form-group row">
    <label for="title"
      class="col-sm-3 text-right control-label col-form-label">Serial #</label>
    <div class="col-sm-9 form-group mb-0"
      *ngIf="itemInstanceService.itemInstanceRepairDropdownList | async; let itemInstances">
      <ng-select [items]="itemInstances"
        bindLabel="dropdownDisplay"
        bindValue="itemInstanceId"
        [(ngModel)]="model.itemInstanceId"
        [clearable]="false"
        (change)="onChangeOfSerialNumber($event)"
        name="itemInstances"
        required>
      </ng-select>
    </div>
  </div>
  <div class="form-group row">
    <label for="title"
      class="col-sm-3 text-right control-label col-form-label">Maintenance Type</label>
    <div class="col-sm-9 form-group mb-0"
      *ngIf="maintenanceTypes">
      <ng-select [items]="maintenanceTypes"
        bindLabel="name"
        bindValue="maintenanceTypeId"
        [(ngModel)]="model.maintenanceTypeId"
        [clearable]="false"
        name="maintenanceTypes"
        required>
      </ng-select>
    </div>
  </div>
  <div class="form-group row">
    <label for="title"
      class="col-sm-3 text-right control-label col-form-label">Hours/Mileage</label>
    <div class="col-sm-9 form-group mb-0">
      <div class="input-group">
        <input type="number"
          class="form-control form-control-sm"
          id="sortOrder"
          [(ngModel)]="model.hrsMileage"
          name="sortOrder"
          required>
        <div class="input-group-append"
          style="height: 32px;"
          *ngIf="model.maintenanceDurationUom">
          <span class="input-group-text">
            {{model.maintenanceDurationUom}}
          </span>
        </div>
      </div>
    </div>
  </div>
  <div class="form-group row">
    <label for="title"
      class="col-sm-3 text-right control-label col-form-label">Notes</label>
    <div class="col-sm-9 form-group mb-0">
      <textarea class="form-control form-control-sm"
        id="notes"
        [(ngModel)]="model.notes"
        name="notes"
        style="width: 100%;height: 80px;"
        [disabled]="false"></textarea>
    </div>
  </div>
</div>
<div class="modal-footer"
  *ngIf="model">
  <button type="button"
    class="btn btn-primary"
    (click)="onSave()"
    [disabled]="!(model.maintenanceDate
      && model.itemInstanceId
      && model.maintenanceTypeId
      &&model.hrsMileage !== null
      && ((model.inHouseRepair && !model.vendorId) || (!model.inHouseRepair && model.vendorId)))">Save</button>
  <button type="button"
    class="btn btn-default"
    (click)="bsModalRef.hide()">Cancel</button>
</div>
