import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filter'
})
export class FilterPipe implements PipeTransform {

  transform(items: any[], idField: string, searchId: number): any[] {
    // return empty array if array is falsy
    if (!items) {
      return [];
    }

    // return the original array if search text is empty
    if (!searchId) {
      return items;
    }

    // return the filtered array
    return items.filter(item => {
      if (item && item[idField]) {
        if (Array.isArray(item[idField])) {
          return item[idField].includes(searchId);
        } else {
          return item[idField] === searchId;
        }
      }
      return false;
    });
  }
}
