import { BehaviorSubject, Observable, Observer } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { ThreadTypeModel } from 'src/models/thread-type.model';

@Injectable()
export class ThreadTypeService {
  constructor(
    private http: HttpClient,
    @Inject('BASE_URL') private baseUrl: string
  ) { }

  private apiUrl = 'api/ThreadType';  // URL to web api

  private threadTypeListSubject = new BehaviorSubject<ThreadTypeModel[]>(null);
  public _threadTypeList$ = this.threadTypeListSubject.asObservable();
  private isThreadTypeCallInProgress = false;
  public threadTypeList: BehaviorSubject<ThreadTypeModel[]> = new BehaviorSubject(null);
  public threadTypeListIsLoading: boolean = false;

  public get threadTypeList$(): Observable<ThreadTypeModel[]> {
    if (!this.threadTypeListSubject.getValue() && !this.isThreadTypeCallInProgress) {
      this.isThreadTypeCallInProgress = true;
      this.getThreadTypes$(false).subscribe(() => this.isThreadTypeCallInProgress = false);
    }

    return this._threadTypeList$;
  }

  public addEditThreadType(dto: ThreadTypeModel, showInactive: boolean = false): Observable<boolean> {
    dto = this.cleanThreadTypeModel(dto);

    return new Observable((observer: Observer<boolean>) => {
      this.http.post<boolean>(this.baseUrl + this.apiUrl + '/AddEditThreadType', dto)
        .subscribe((x: boolean) => {
          if (x === true) {
            this.getThreadTypes(showInactive);
          }
          observer.next(x);
        });
    });
  }

  public deleteThreadType(id: number, showInactive: boolean = false): Observable<boolean> {
    return new Observable((observer: Observer<boolean>) => {
      this.http.get<boolean>(this.baseUrl + this.apiUrl + '/DeleteThreadType/' + id)
        .subscribe((x: boolean) => {
          if (x === true) {
            this.getThreadTypes(showInactive);
          }
          observer.next(x);
        });
    });
  }

  public getThreadTypeById(id: number): Observable<ThreadTypeModel> {
    return this.http.get<ThreadTypeModel>(this.baseUrl + this.apiUrl + '/GetThreadTypeById/' + id);
  }

  public getThreadTypes(
    showInactive: boolean = false,
    showSkeleton: boolean = false): void {
    if (showSkeleton) {
      this.threadTypeListIsLoading = true;
    }

    this.http.get<ThreadTypeModel[]>(this.baseUrl + this.apiUrl + '/GetThreadTypes/' + showInactive)
      .subscribe((x: ThreadTypeModel[]) => {
        this.threadTypeList.next(x);

        this.threadTypeListIsLoading = false;
      });
  }

  public generateThreadTypeModel(): ThreadTypeModel {
    let model: ThreadTypeModel = new ThreadTypeModel();

    model.isActive = true;

    return model;
  }

  private cleanThreadTypeModel(model: ThreadTypeModel): ThreadTypeModel {
    Object.keys(model).forEach((index: string) => {
      if (typeof model[index] === 'string') {
        model[index] = model[index]
          && model[index].trim() !== '' ?
          model[index].trim()
          : null;
      }
    });

    return model;
  }

  private getThreadTypes$(showInactive: boolean): Observable<ThreadTypeModel[]> {
    return this.http.get<Array<ThreadTypeModel>>(this.baseUrl + this.apiUrl + '/GetThreadTypes/' + showInactive)
      .pipe(
        tap((threadTypes: ThreadTypeModel[]) => {
          this.threadTypeListSubject.next(threadTypes);
        })
      );
  }
}
