import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { JobSafetyAnalysisWindDirectionModel } from 'src/models/job-safety-analysis-wind-direction.model';

@Injectable()
export class JobSafetyAnalysisWindDirectionService {
  constructor(
    private http: HttpClient,
    @Inject('BASE_URL') private baseUrl: string
  ) { }

  private apiUrl = 'api/JobSafetyAnalysisWindDirection';  // URL to web api

  private windDirectionListSubject = new BehaviorSubject<JobSafetyAnalysisWindDirectionModel[]>(null);
  public _windDirectionList$ = this.windDirectionListSubject.asObservable();
  private isWindDirectionCallInProgress = false;

  public get windDirectionList$(): Observable<JobSafetyAnalysisWindDirectionModel[]> {
    if (!this.windDirectionListSubject.getValue() && !this.isWindDirectionCallInProgress) {
      this.isWindDirectionCallInProgress = true;
      this.getWindDirections$(false).subscribe(() => this.isWindDirectionCallInProgress = false);
    }

    return this._windDirectionList$;
  }

  private getWindDirections$(showInactive: boolean): Observable<JobSafetyAnalysisWindDirectionModel[]> {
    return this.http.get<Array<JobSafetyAnalysisWindDirectionModel>>(this.baseUrl + this.apiUrl + '/GetWindDirections/' + showInactive)
      .pipe(
        tap((directions: JobSafetyAnalysisWindDirectionModel[]) => {
          this.windDirectionListSubject.next(directions);
        })
      );
  }
}
