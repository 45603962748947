import { ContactModel } from './contact.model';
import { CountyModel } from './county.model';

export class CustomerAreaModel {
  customerAreaId: number;
  customerId: number;
  contactId: number | null;
  customerName: string;
  name: string;
  notes: string;
  isActive: boolean | null;

  contact: ContactModel;
  counties: CountyModel[];
}
