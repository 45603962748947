<div class="modal-header"
  ngxModalDraggable>
  <h4 class="modal-title pull-left"
    *ngIf="model">{{isNew ? 'Add ' : 'Edit '}}Serialized Inventory</h4>
  <button type="button"
    class="close pull-right"
    aria-label="Close"
    (click)="bsModalRef.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body"
  *ngIf="model">
  <div class="form-group row">
    <label for="title"
      class="col-sm-2 text-right control-label col-form-label">Item</label>
    <div class="col-sm-10 form-group mb-0"
      *ngIf="itemService.itemDropdownList | async; let item">
      <ng-select [items]="item"
        bindLabel="dropdownDisplay"
        bindValue="itemId"
        [(ngModel)]="model.itemId"
        [disabled]="true"
        name="items"
        required>
      </ng-select>
    </div>
  </div>
  <div class="form-group row">
    <label for="title"
      class="col-sm-2 text-right control-label col-form-label">Status</label>
    <div class="col-sm-10 form-group mb-0"
      *ngIf="iilService.iilList | async; let iil">
      <ng-select [items]="iil"
        bindLabel="name"
        bindValue="itemInstanceLocationId"
        [(ngModel)]="model.itemInstanceLocationId"
        (change)="onChangeLocation($event)"
        name="itemInstanceLocation"
        required>
      </ng-select>
    </div>
  </div>
  <div class="form-group row">
    <label for="title"
      class="col-sm-2 text-right control-label col-form-label">Shop</label>
    <div class="col-sm-10 form-group mb-0"
      *ngIf="shops">
      <ng-select [items]="shops"
        bindLabel="name"
        bindValue="shopId"
        [(ngModel)]="model.shopId"
        [clearable]="false"
        (change)="onChangeShop($event)"
        name="shops"
        required>
      </ng-select>
    </div>
  </div>
  <div class="form-group row">
    <label for="title"
      class="col-sm-2 text-right control-label col-form-label">Serial#</label>
    <div class="col-sm-10 form-group mb-0">
      <input type="text"
        class="form-control form-control-sm"
        id="serial"
        [(ngModel)]="model.serialNumber"
        name="serialNumber"
        required>
    </div>
  </div>
  <div class="form-group row">
    <label for="title"
      class="col-sm-2 text-right control-label col-form-label">Thread Type</label>
    <div class="col-sm-10 form-group mb-0"
      *ngIf="threadTypes">
      <ng-select [items]="threadTypes"
        bindLabel="name"
        bindValue="threadTypeId"
        [(ngModel)]="model.threadTypeId"
        name="threadType">
      </ng-select>
    </div>
  </div>
  <div class="form-group row"
    *ngIf="model.partGroupId === PartGroups.BladedMills">
    <label for="title"
      class="col-sm-2 text-right control-label col-form-label"># Of Blades</label>
    <div class="col-sm-10 form-group mb-0">
      <input type="text"
        class="form-control form-control-sm"
        id="numberOfBlades"
        [(ngModel)]="model.numberOfBlades"
        name="numberOfBlades">
    </div>
  </div>
  <div class="
        form-group
        row">
    <label for="title"
      class="col-sm-2 text-right control-label col-form-label">ID</label>
    <div class="col-sm-4 form-group mb-0">
      <div class="input-group">
        <input type="number"
          class="form-control form-control-sm"
          id="innerDiameter"
          [(ngModel)]="model.innerDiameter"
          name="innerDiameter"
          [required]="!fnIdNa"
          [readonly]="fnIdNa">
        <div class="input-group-append"
          style="height: 32px;">
          <span class="input-group-text">
            inch&nbsp;&nbsp;|&nbsp;&nbsp;<input type="checkbox"
              (change)="toggleFnIdNa($event)"
              [checked]="fnIdNa">&nbsp;&nbsp;<span class="text-muted">n/a</span>
          </span>
        </div>
      </div>
    </div>
    <label for="title"
      class="col-sm-2 text-right control-label col-form-label">OD</label>
    <div class="col-sm-4 form-group mb-0">
      <div class="input-group">
        <input type="number"
          class="form-control form-control-sm"
          id="outerDiameter"
          [(ngModel)]="model.outerDiameter"
          name="outerDiameter"
          [required]="!fnOdNa"
          [readonly]="fnOdNa">
        <div class="input-group-append"
          style="height: 32px;">
          <span class="input-group-text">
            inch&nbsp;&nbsp;|&nbsp;&nbsp;<input type="checkbox"
              (change)="toggleFnOdNa($event)"
              [checked]="fnOdNa">&nbsp;&nbsp;<span class="text-muted">n/a</span>
          </span>
        </div>
      </div>
    </div>
  </div>
  <div class="form-group row">
    <label for="title"
      class="col-sm-2 text-right control-label col-form-label">Length</label>
    <div class="col-sm-4 form-group mb-0">
      <div class="input-group">
        <input type="number"
          class="form-control form-control-sm"
          id="length"
          [(ngModel)]="model.length"
          name="length"
          [required]="!fnLengthNa"
          [readonly]="fnLengthNa">
        <div class="input-group-append"
          style="height: 32px;">
          <span class="input-group-text">
            ft&nbsp;&nbsp;|&nbsp;&nbsp;<input type="checkbox"
              (change)="toggleLengthOdNa($event)"
              [checked]="fnLengthNa">&nbsp;&nbsp;<span class="text-muted">n/a</span>
          </span>
        </div>
      </div>
    </div>
    <label for="title"
      class="col-sm-2 text-right control-label col-form-label">Cost</label>
    <div class="col-sm-4 form-group mb-0">
      <div class="input-group">
        <div class="input-group-prepend"
          style="height: 32px;">
          <span class="input-group-text">$</span>
        </div>
        <input type="number"
          class="form-control form-control-sm"
          id="originalCost"
          [(ngModel)]="model.originalCost"
          name="originalCost"
          required>
      </div>
    </div>
  </div>
  <div class="form-group row">
    <label for="title"
      class="col-sm-2 text-right control-label col-form-label">Has Maintenance Log</label>
    <div class="col-sm-4 form-group mb-0">
      <ui-switch [ngModel]="model.hasMaintenanceLog"
        name="bodyInspection"
        (change)="onChangeMaintenanceLog($event)"></ui-switch>
    </div>
    <label for="title"
      class="col-sm-2 text-right control-label col-form-label"
      *ngIf="model.hasMaintenanceLog">Maintenance Duration UOM</label>
    <div class="col-sm-4 form-group mb-0"
      *ngIf="model.hasMaintenanceLog">
      <ng-select [items]="maintenanceUoms"
        bindLabel="name"
        bindValue="maintenanceDurationUomId"
        [ngModel]="model.maintenanceDurationUomId"
        [clearable]="false"
        (change)="onChangeMaintenanceUom($event)"
        name="maintenanceUoms">
      </ng-select>
    </div>
  </div>
</div>
<div class="modal-footer"
  *ngIf="model">
  <button type="button"
    class="btn btn-primary"
    (click)="onSave()"
    [disabled]="disableSave 
      || !(model.serialNumber 
        && model.itemInstanceLocationId 
        && model.itemId 
        && model.originalCost!==null 
        && model.shopId
        && (fnIdNa === true 
          ||(fnIdNa === false 
            && model.innerDiameter!==null 
            && model.innerDiameter!==undefined))
        && (fnOdNa === true 
          || (fnOdNa === false 
            && model.outerDiameter!==null 
            && model.outerDiameter!==undefined))
        && (fnLengthNa === true 
          || (fnLengthNa === false 
            && model.length !== null 
            && model.length !== undefined)))">Save</button>
  <button type="button"
    class="btn btn-default"
    (click)="bsModalRef.hide()">Cancel</button>
</div>
