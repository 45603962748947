<div class="modal-header"
  ngxModalDraggable>
  <h4 class="modal-title pull-left">{{isNew ? 'Add' : 'Edit'}}&nbsp;Item<span
      *ngIf="model&&!isNew">:&nbsp;{{model.partNumber}}</span></h4>
  <button type="button"
    class="close pull-right"
    aria-label="Close"
    (click)="bsModalRef.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body"
  *ngIf="model">
  <div class="form-group row">
    <label for="title"
      class="col-sm-2 text-right control-label col-form-label">Description</label>
    <div class="col-sm-4 form-group mb-0">
      <input type="text"
        class="form-control form-control-sm"
        id="description"
        [(ngModel)]="model.description"
        name="description"
        required>
    </div>
    <label for="title"
      class="col-sm-2 text-right control-label col-form-label"
      *ngIf="model.itemTypeId !== ItemTypes.Build">Vendor</label>
    <ng-container *ngIf="model.itemTypeId !== ItemTypes.Build">
      <div class="col-sm-4 form-group mb-0"
        *ngIf="vendorService.vendorList | async; let vend">
        <ng-select [items]="vend"
          bindLabel="name"
          bindValue="vendorId"
          [(ngModel)]="model.vendorId"
          name="vendors"
          required>
        </ng-select>
      </div>
    </ng-container>
  </div>
  <div class="form-group row">
    <label for="title"
      class="col-sm-2 text-right control-label col-form-label">Cost</label>
    <div class="col-sm-4 form-group mb-0">
      <div class="input-group input-group-sm">
        <div class="input-group-prepend">
          <span class="input-group-text">$</span>
        </div>
        <input type="number"
          class="form-control form-control-sm"
          id="quantity"
          [(ngModel)]="model.cost"
          name="cost">
      </div>
    </div>
    <label for="title"
      class="col-sm-2 text-right control-label col-form-label">UOM</label>
    <div class="col-sm-4 form-group mb-0"
      *ngIf="unitsOfMeasure">
      <ng-select [items]="unitsOfMeasure"
        bindLabel="name"
        bindValue="uomId"
        [(ngModel)]="model.uomId"
        [disabled]="model.itemTypeId === ItemTypes.Build"
        name="uom"
        required>
      </ng-select>
    </div>
  </div>
  <div class="form-group row">
    <label for="title"
      class="col-sm-2 text-right control-label col-form-label">Part Family</label>
    <div class="col-sm-4 form-group mb-0"
      *ngIf="partFamilyService.partFamilyList | async; let pf">
      <ng-select [items]="pf"
        bindLabel="name"
        bindValue="partFamilyId"
        [(ngModel)]="model.partFamilyId"
        (change)="onChangePartFamily($event)"
        name="partFamily"
        appendTo='body'
        required>
      </ng-select>
    </div>
    <label for="title"
      class="col-sm-2 text-right control-label col-form-label">Part Group</label>
    <div class="col-sm-4 form-group mb-0"
      *ngIf="partGroupService.partGroupList | async; let pg">
      <ng-select [items]="pg"
        bindLabel="name"
        bindValue="partGroupId"
        [(ngModel)]="model.partGroupId"
        name="partGroup"
        appendTo='body'
        required>
      </ng-select>
    </div>
    <div class="col-sm-4 form-group mb-0"
      *ngIf="(partGroupService.partGroupList | async)===null; let pg">
      <input type="text"
        class="form-control"
        readonly>
    </div>
  </div>
  <div class="form-group row">
    <label class="col-sm-2 text-right control-label col-form-label">Item Type</label>
    <div class="col-sm-4 form-group mb-0"
      *ngIf="itemTypes">
      <ng-select [items]="itemTypes"
        bindLabel="name"
        bindValue="itemTypeId"
        [(ngModel)]="model.itemTypeId"
        [clearable]="false"
        name="types"
        [disabled]="!isNew"
        (change)="onChangeOfItemType($event)"
        required>
      </ng-select>
    </div>
    <label class="col-sm-2 text-right control-label col-form-label"
      *ngIf="model.itemTypeId === ItemTypes.Build">Build</label>
    <div class="col-sm-4 form-group mb-0"
      *ngIf="model.itemTypeId === ItemTypes.Build">
      <ng-select [items]="builds"
        bindLabel="name"
        bindValue="worksheetTypeId"
        [(ngModel)]="model.worksheetTypeId"
        name="builds"
        (change)="onChangeOfBuild($event)"
        [disabled]="!isNew"
        required>
      </ng-select>
    </div>
    <label for="title"
      class="col-sm-2 text-right control-label col-form-label"
      *ngIf="model.itemTypeId === ItemTypes.Sale">Return After Fishing</label>
    <div class="col-sm-4 form-group mb-0"
      *ngIf="model.itemTypeId === ItemTypes.Sale">
      <ui-switch [ngModel]="model.returnToInvAfterFishing"
        name="returnToInvAfterFishing"
        (change)="onChangeReturnAfterFishing($event)"
        [disabled]="false"></ui-switch>
    </div>
    <label for="title"
      class="col-sm-2 text-right control-label col-form-label"
      *ngIf="model.itemTypeId === ItemTypes.Rental || model.itemTypeId === ItemTypes.ThirdParty">Body Inspection</label>
    <div class="col-sm-4 form-group mb-0"
      *ngIf="model.itemTypeId === ItemTypes.Rental || model.itemTypeId === ItemTypes.ThirdParty">
      <ui-switch [(ngModel)]="model.bodyInspection"
        name="bodyInspection"></ui-switch>
    </div>
  </div>
  <div class="form-group row mb-1"
    *ngIf="!isNew && model && model.itemTypeId === ItemTypes.Sale">
    <label for="title"
      class="col-sm-2 text-right control-label col-form-label">Inventory Count</label>
    <div class="col-sm-10 form-group mb-0">
      <div class="row">
        <div class="alert alert-danger p-1 mb-1"
          role="alert"
          *ngIf="model.shopItems === null || model.shopItems.length === 0">
          <i class="fas fa-check-circle"></i>&nbsp;&nbsp;Current Inventory:
          <strong>{{currentInventory!==null ? currentInventory : 0}}</strong>
        </div>
        <ng-container *ngIf="model.shopItems.length !== null && model.shopItems.length">
          <div class="alert p-1 mb-1 mr-2"
            [ngClass]="{'alert-success':ci.inventoryCount !==null && ci.inventoryCount > 0, 'alert-danger': ci.inventoryCount === null || ci.inventoryCount <= 0 }"
            role="alert"
            *ngFor="let ci of model.shopItems">
            <i class="fas fa-check-circle"></i>&nbsp;&nbsp;{{ci.name}}:
            <strong>{{ci.inventoryCount !==null ? ci.inventoryCount : 0}}</strong>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
  <div class="form-group row"
    *ngIf="model.itemTypeId === ItemTypes.Rental || model.itemTypeId === ItemTypes.ThirdParty">
    <label for="title"
      class="col-sm-2 text-right control-label col-form-label"># Connections</label>
    <div class="col-sm-1 form-group mb-0">
      <input type="number"
        class="form-control"
        [(ngModel)]="model.numberOfConnections"
        required>
    </div>
    <label for="title"
      class="col-sm-2 text-right control-label col-form-label">Minimum Days</label>
    <div class="col-sm-1 form-group mb-0">
      <input type="number"
        class="form-control"
        [(ngModel)]="model.minNumDays">
    </div>
    <label for="title"
      class="col-sm-2 text-right control-label col-form-label">Rod Inspection</label>
    <div class="col-sm-1 form-group mb-0">
      <ui-switch [ngModel]="model.rodInspection"
        name="rodInspection"
        (change)="onChangeRodInspection($event)"
        [disabled]="false"></ui-switch>
    </div>
    <label for="title"
      class="col-sm-2 text-right control-label col-form-label">Charge For Runs After Scrap</label>
    <div class="col-sm-1 form-group mb-0">
      <ui-switch [ngModel]="model.chargeForRunsAfterScrap"
        name="chargeForRunsAfterScrap"
        (change)="onChangeChargeForRunsAfterScrap($event)"
        [disabled]="false"></ui-switch>
    </div>
  </div>
  <div class="table-responsive"
    *ngIf="model.itemTypeId !== ItemTypes.Build">
    <div class="alert alert-primary ml-3 mr-4 mt-2"
      role="alert"
      *ngIf="model.itemVendor.length === 0">
      <i class="fad fa-check"></i>&nbsp;&nbsp;There are currently no <strong>Vendor Items.</strong>
    </div>
    <table class="table table-striped"
      *ngIf="model.itemVendor.length > 0">
      <tr>
        <th style="width:25%">Vendor</th>
        <th style="width:25%">Part Number</th>
        <th style="width:25%">Description</th>
        <th style="width:18%">Cost</th>
        <th style="width: 60px;"></th>
      </tr>
      <tr *ngFor="let iv of model.itemVendor;index as i">
        <td *ngIf="vendorService.vendorList | async; let vend">
          <ng-select [items]="vend"
            bindLabel="name"
            bindValue="vendorId"
            [(ngModel)]="model.itemVendor[i].vendorId"
            name="vendors{{i}}"
            (change)="checkDisableAddRow()"
            appendTo='body'
            required>
          </ng-select>
        </td>
        <td>
          <input type="text"
            class="form-control form-control-sm"
            id="{{i}}partNumber"
            (keyup.enter)="blurThis($event)"
            (blur)="checkDisableAddRow()"
            [(ngModel)]="model.itemVendor[i].partNumber"
            name="{{i}}partNumber">
        </td>
        <td>
          <input type="text"
            class="form-control form-control-sm"
            id="{{i}}description"
            (keyup.enter)="blurThis($event)"
            (blur)="checkDisableAddRow()"
            [(ngModel)]="model.itemVendor[i].description"
            name="{{i}}description">
        </td>
        <td>
          <div class="input-group input-group-sm">
            <div class="input-group-prepend">
              <span class="input-group-text">$</span>
            </div>
            <input type="number"
              class="form-control form-control-sm"
              id="{{i}}cost"
              (keyup.enter)="blurThis($event)"
              (blur)="checkDisableAddRow()"
              [(ngModel)]="model.itemVendor[i].cost"
              name="{{i}}cost">
          </div>
        </td>
        <td>
          <button class="btn btn-xs btn-light"
            [swal]="{ title: 'Delete Vendor Item?', showCancelButton: true, confirmButtonText: 'Delete', confirmButtonColor: '#DC3545' }"
            (confirm)="deleteRow(i)">
            <i class="fa fa-trash"></i>
          </button>
        </td>
      </tr>
    </table>
    <button class="btn btn-primary btn-sm float-right mr-4"
      (click)="addRow();"
      [disabled]="disableAddRow">
      <i class="fas fa-plus"></i>
    </button>
  </div>
</div>
<div class="modal-footer"
  *ngIf="model">
  <button type="button"
    class="btn btn-primary"
    (click)="onSave()"
    [disabled]="disableSave
      || !(model.partGroupId
        && model.uomId
        && model.description
        && disableAddRow===false
        && ((model.itemTypeId !== ItemTypes.Build && model.vendorId) || (model.itemTypeId === ItemTypes.Build && !model.vendorId))
        &&  (
              (model.itemTypeId === ItemTypes.Sale || model.itemTypeId === ItemTypes.Build)
              ||
              ((model.itemTypeId === ItemTypes.Rental || model.itemTypeId === ItemTypes.ThirdParty)
              && (model.numberOfConnections !== null && model.numberOfConnections !== undefined))
            )
        )">Save</button>
  <button type="button"
    class="btn btn-default"
    (click)="bsModalRef.hide()">Cancel</button>
</div>
