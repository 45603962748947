import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { State, process } from '@progress/kendo-data-query';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { AddBuiltPackerModalComponent } from 'src/app/packers/add-built-packer-modal/add-built-packer-modal.component';
import { ChooseWorksheetModalComponent } from 'src/app/worksheets/choose-worksheet-modal/choose-worksheet-modal.component';
import { JobSubStatuses } from 'src/enums/job-sub-statuses';
import { DayModel } from 'src/models/day.model';
import { JobSafetyAnalysisHealthModel } from 'src/models/job-safety-analysis-health.model';
import { JobModel } from 'src/models/job.model';
import { WorksheetCombinedModel } from 'src/models/worksheet-combined.model';
import { AppStateService } from 'src/services/app-state.service';
import { BhaLineService } from 'src/services/bha-line.service';
import { BhaService } from 'src/services/bha.service';
import { DayService } from 'src/services/day.service';
import { FileService } from 'src/services/file.service';
import { InvoiceItemService } from 'src/services/invoice-item.service';
import { JobItemInstanceService } from 'src/services/job-item-instance.service';
import { JobSafetyAnalysisService } from 'src/services/job-safety-analysis.service';
import { JobService } from 'src/services/job.service';
import { ReportService } from 'src/services/report.service';
import { RouterEventService } from 'src/services/router-event.service';
import { TimezoneService } from 'src/services/timezone.service';
import { UIService } from 'src/services/ui.service';
import { WorkLogService } from 'src/services/work-log.service';
import { WorksheetService } from 'src/services/worksheet.service';
import { JobStatuses } from '../../../../enums/job-statuses';
import { InvoiceItemModel } from '../../../../models/invoice-item.model';
import { ItemInstanceService } from '../../../../services/item-instance.service';
import { UserService } from '../../../../services/user.service';
import { AddEditJobFishingDayModalComponent } from '../../fishing/add-edit-job-day-fishing-modal/add-edit-job-day-fishing-modal.component';
import { AddEditJobSalesModalComponent } from '../../shared/add-edit-job-sales-modal/add-edit-job-sales-modal.component';
import { CancelJobConfirmComponent } from '../../shared/cancel-job-confirm-modal/cancel-job-confirm-modal.component';
import { Subscription } from 'rxjs';
import { TabDirective } from 'ngx-bootstrap/tabs';

@Component({
  selector: 'app-packer-details',
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.css']
})

export class PackerDetailsComponent implements OnInit, OnDestroy {
  constructor(
    private modalService: BsModalService,
    private activatedRoute: ActivatedRoute,
    public jobService: JobService,
    public dayService: DayService,
    public uiService: UIService,
    public invoiceItemService: InvoiceItemService,
    public worksheetService: WorksheetService,
    public workLogService: WorkLogService,
    public jiiService: JobItemInstanceService,
    public iiService: ItemInstanceService,
    public bhaService: BhaService,
    public bhaLineService: BhaLineService,
    public fileService: FileService,
    public userService: UserService,
    public reportService: ReportService,
    public appStateService: AppStateService,
    public timeZoneService: TimezoneService,
    private routerEventService: RouterEventService,
    public jobSafetyAnalysisService: JobSafetyAnalysisService
  ) { };

  public model: JobModel;
  public bsModalRef: BsModalRef;
  public jobId: number = -1;
  public timeStamp: string = new Date().toISOString();
  public invoiceItems: InvoiceItemModel[] = [];
  public state: State = {};
  public gridData: any = process(this.invoiceItems, this.state);
  public infoIsCollapsed = false;
  public showDetails = true;
  public JobStatuses = JobStatuses;
  public JobSubStatuses = JobSubStatuses;
  public currentJobDates: (string | Date)[] = [];
  public activeTabId: string = 'dayTab_0';
  public enableReorder: boolean = false;
  public returnUrl: string = '/';
  public jsaHealth: JobSafetyAnalysisHealthModel = new JobSafetyAnalysisHealthModel();

  private isTabletSub: Subscription;
  public isTablet: boolean = false;

  private routeParamsSub: Subscription;
  private jsaAreCompleteSub: Subscription;
  private jobSub: Subscription;
  private daySaveSub: Subscription;
  private deleteDaySub: Subscription;
  private worksheetDaySaveSub: Subscription;

  ngOnDestroy(): void {
    this.isTabletSub?.unsubscribe();

    this.routeParamsSub?.unsubscribe();

    this.jsaAreCompleteSub?.unsubscribe();

    this.jobSub?.unsubscribe();

    this.daySaveSub?.unsubscribe();

    this.deleteDaySub?.unsubscribe();

    this.worksheetDaySaveSub?.unsubscribe();
  }

  ngOnInit(): void {
    this.returnUrl = this.routerEventService.getPreviousUrl();

    this.subscribeToRouteParams();

    this.subscribeToJsasAreComplete();

    this.determineIsTablet();
  }

  public determineIsTablet(): void {
    this.isTabletSub = this.appStateService.isTablet$
      .subscribe((isTablet: boolean) => {
        this.isTablet = isTablet;
      });
  }

  public subscribeToRouteParams(): void {
    this.jobService.job.next(null);

    this.routeParamsSub = this.activatedRoute.params
      .subscribe((x: Params) => {
        this.jobId = parseInt(this.activatedRoute.snapshot.params['id']);
        this.jobService.refreshJob(this.jobId);
        this.worksheetService.getWorksheetsCombinedByJobId(this.jobId, false);
        this.subscribeToJob();
      });
  }

  public subscribeToJsasAreComplete(): void {
    this.jsaAreCompleteSub = this.jobSafetyAnalysisService.jsasAreComplete
      .subscribe((health: JobSafetyAnalysisHealthModel) => {
        if (health) {
          this.jsaHealth = health;
        }
      });
  }

  public openChooseWorksheetModal(): void {
    const modalSize = this.isTablet ? 'modal-xl' : 'modal-lg';

    const initialState = {
      customerId: this.model.customerId,
      jobId: this.jobId,
      packersOnly: true
    };
    this.bsModalRef = this.modalService.show(ChooseWorksheetModalComponent, { initialState, class: modalSize, backdrop: 'static' });
  }

  public openJobFishingDayModal(dayId: number): void {
    const currentJobDates: (string | Date)[] = this.returnCurrentJobDates();
    const initialState = {
      id: dayId,
      jobId: this.jobId,
      currentJobDates: currentJobDates
    };
    this.bsModalRef = this.modalService.show(AddEditJobFishingDayModalComponent, { initialState, backdrop: 'static' });
  }

  public openJobModal(jobId: number): void {
    const initialState = {
      id: jobId,
      jobTypeId: 5
    };
    this.bsModalRef = this.modalService.show(AddEditJobSalesModalComponent, { initialState, class: 'modal-lg modal-xl', backdrop: 'static' });
  }

  public openBuiltPackerModal(jobId: number): void {
    const initialState = {
      customerId: this.model.customerId,
      jobId: jobId
    };
    this.bsModalRef = this.modalService.show(AddBuiltPackerModalComponent, { initialState, class: 'modal-lg', backdrop: 'static' });
  }

  public openCancelJobConfirmModal(
    job: JobModel,
    cancellationStatusId: number): void {
    const initialState = {
      statusId: cancellationStatusId,
      model: job
    };
    this.bsModalRef = this.modalService.show(CancelJobConfirmComponent, { initialState, backdrop: 'static' });
  }

  public subscribeToJob(): void {
    this.jobSub = this.jobService.job
      .subscribe((x: JobModel) => {
        if (x !== null) {
          this.invoiceItems = x.invoiceItems;
          this.gridData = process(this.invoiceItems, this.state);
          this.model = x;
        }
      });
  }

  private returnCurrentJobDates(): (string | Date)[] {
    const currentJobDays: DayModel[] = this.jobService.job.value !== null ? this.jobService.job.value.days : [];
    return currentJobDays.map((d: DayModel) => {
      return this.timeZoneService.removeTimeFromDate(d.date);
    });
  }

  public saveJobDateChange(
    $event: Date,
    day: DayModel): void {
    if ($event !== undefined && $event !== null) {
      const dateIsInUse: boolean = this.currentJobDates.findIndex((x: string | Date) => {
        return x.valueOf() === $event.valueOf();
      }) !== -1;

      if (!dateIsInUse) {
        if ($event !== undefined && $event !== null && day.date !== $event) {
          day.date = $event;
          this.saveDay(day);
        }
      }
    }
  }

  public saveDay(day: DayModel): void {
    this.daySaveSub = this.dayService.addEditDay(day)
      .subscribe((x: boolean) => {
        if (x) {
          this.uiService.showSuccess('Day Saved', 'Success');
        } else {
          this.uiService.showSuccess('Day Not Saved', 'Error');
        }
      });
  }

  public toggleWorksheetDay(
    dayId: number,
    worksheetId: number): void {
    let worksheet: WorksheetCombinedModel[] =
      this.worksheetService.worksheetList.value != null ?
        this.worksheetService.worksheetList.value.filter((x: WorksheetCombinedModel) => {
          return x.worksheetId == worksheetId;
        })
        : null;

    let dayExists: boolean = worksheet.length > 0 && worksheet[0].dayIds.includes(dayId);

    if (!dayExists) {
      worksheet[0].dayIds.push(dayId);
    } else {
      let index: number = worksheet[0].dayIds.indexOf(dayId);
      worksheet[0].dayIds.splice(index, 1);
    }

    this.worksheetDaySaveSub = this.worksheetService.toggleWorksheetDay(dayId, worksheetId, this.jobId)
      .subscribe((x: boolean) => {
        if (x === true) {
          this.uiService.showSuccess('Worksheet Day Changed', 'Success');
        } else {
          this.uiService.showError('Worksheet Day Not Changed', 'Error');
        }
      });
  }

  public deleteDay(day: DayModel): void {
    if (day !== undefined && day !== null) {
      this.deleteDaySub = this.dayService.deleteDay(day)
        .subscribe((x: boolean) => {
          if (x) {
            this.activeTabId = 'dayTab_0';
            this.uiService.showSuccess('Day Deleted', 'Success');
          } else {
            this.uiService.showSuccess('Day Not Deleted', 'Error');
          }
        });
    }
  }

  public tabSelect($event: TabDirective): void {
    if ($event !== undefined) {
      this.activeTabId = $event.id;
    }
  }

  public formatDateAsString(date: Date): string {
    var m_names = new Array('January', 'February', 'March',
      'April', 'May', 'June', 'July', 'August', 'September',
      'October', 'November', 'December');

    var d = new Date(date);
    var curr_date = d.getDate();
    var curr_month = d.getMonth();
    var curr_year = d.getFullYear();

    return m_names[curr_month] + ' ' + curr_date + ', ' + curr_year;
  }

  public handleEnableReorderEvent($event: boolean): void {
    this.enableReorder = $event;
  }

}
