import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { PageChangeEvent } from '@progress/kendo-angular-grid';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { CustomerAreaModel } from 'src/models/customer-area.model';
import { CustomerModel } from 'src/models/customer.model';
import { CountyService } from 'src/services/county.service';
import { CustomerAreaService } from 'src/services/customer-area.service';
import { CustomerService } from 'src/services/customer.service';
import { KGridService } from 'src/services/k-grid.service';
import { UIService } from 'src/services/ui.service';
import { AddEditCustomerAreaModalComponent } from '../add-edit-customer-area-modal/add-edit-customer-area-modal.component';

@Component({
  selector: 'app-customer-area-list',
  templateUrl: './customer-area-list.component.html',
})

export class CustomerAreaListComponent implements OnInit, OnDestroy {
  @Input() customerId: number;

  constructor(
    private modalService: BsModalService,
    public customerAreaService: CustomerAreaService,
    public customerService: CustomerService,
    public countyService: CountyService,
    public kGridService: KGridService,
    public uiService: UIService,
  ) { };

  public showInactive: boolean = false;
  public entityId: number = -1;
  public bsModalRef: BsModalRef;

  private customerSub: Subscription;
  private deleteSub: Subscription;

  ngOnDestroy(): void {
    this.customerSub?.unsubscribe();

    this.deleteSub?.unsubscribe();
  }

  ngOnInit(): void {

  }

  public subscribeToCustomer(): void {
    this.customerSub = this.customerService.customer
      .subscribe((x: CustomerModel) => {
        this.entityId = x.customerId;
      });
  }

  public openCustomerAreaModal(id: number): void {
    const initialState = {
      id: id,
      customerId: this.customerId,
      showInactive: this.showInactive
    };

    this.bsModalRef = this.modalService.show(AddEditCustomerAreaModalComponent, { initialState, backdrop: 'static' });
  }

  public onChangeShowInactive($event: boolean): void {
    this.showInactive = $event;

    this.customerAreaService.refreshCustomerAreaList(this.customerId, this.showInactive);
  }

  public deleteCustomerArea(model: CustomerAreaModel): void {
    this.deleteSub = this.customerAreaService.deleteCustomerArea(model, this.showInactive)
      .subscribe((x: boolean) => {
        if (x) {
          this.uiService.showSuccess('Success', 'Customer Area Deleted');
        } else {
          this.uiService.showError('Error', 'Customer Area Not Deleted');
        }
      });
  }

  public kGridParams: any =
    {
      buttonCount: 5,
      info: true,
      type: '\'numeric\' | \'input\' = \'numeric\'',
      pageSizes: true,
      previousNext: true,
      pageSize: 100,
      skip: 0
    };

  public pageChange({ skip, take }: PageChangeEvent): void {
    this.kGridParams.skip = skip;
    this.kGridParams.pageSize = take;
  }

}
