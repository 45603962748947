<div class="modal-header"
  ngxModalDraggable>
  <h4 class="modal-title pull-left"
    *ngIf="model">{{isNew ? 'Add' : 'Edit'}}&nbsp;Invoice</h4>
  <button type="button"
    class="close pull-right"
    aria-label="Close"
    (click)="bsModalRef.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body"
  *ngIf="model">
  <div class="form-group row">
    <label for="title"
      class="col-sm-2 text-right control-label col-form-label">Invoice Number</label>
    <div class="col-sm-4 form-group mb-0"
      *ngIf="model.jobId !== 0">
      <input type="number"
        class="form-control form-control-sm"
        id="jobNumber"
        [ngModel]="model.jobNumber"
        name="jobNumber"
        readonly>
    </div>
    <div class="col-sm-4 form-group mb-0"
      *ngIf="model.jobId === 0">
      <input type="text"
        class="form-control form-control-sm"
        id="jobNumber"
        name="jobNumber"
        value="New Invoice"
        readonly>
    </div>
    <label for="title"
      class="col-sm-2 text-right control-label col-form-label">Open Date</label>
    <div class="col-sm-4 form-group mb-0">
      <input type="text"
        placeholder="mm/dd/yyyy"
        class="form-control"
        [(ngModel)]="model.jobDate"
        required
        bsDatepicker>
    </div>
  </div>
  <div class="form-group row">
    <label for="title"
      class="col-sm-2 text-right control-label col-form-label">Invoice Type</label>
    <div class="col-sm-4 form-group mb-0"
      *ngIf="jobTypes">
      <ng-select [items]="jobTypes"
        bindLabel="name"
        bindValue="jobTypeId"
        [(ngModel)]="model.jobTypeId"
        name="jobTypes"
        [disabled]="true"
        required>
      </ng-select>
    </div>
    <label for="title"
      class="col-sm-2 text-right control-label col-form-label">Invoice Status</label>
    <div class="col-sm-4 form-group mb-0"
      *ngIf="statusService.jobStatusList | async; let status">
      <ng-select [items]="status"
        bindLabel="name"
        bindValue="jobStatusId"
        [(ngModel)]="model.jobStatusId"
        name="jobstatuses"
        [clearable]="false"
        [disabled]="isNew || !(userService.user.value && userService.user.value.activeRoles && userService.user.value.activeRoles.includes('General'))"
        required>
      </ng-select>
    </div>
  </div>
  <div class="form-group row">
    <label for="title"
      class="col-sm-2 text-right control-label col-form-label">AFE#</label>
    <div class="col-sm-4 form-group mb-0">
      <input type="text"
        class="form-control form-control-sm"
        id="afeNumber"
        [(ngModel)]="model.afeNumber"
        name="afeNumber">
    </div>
    <label for="title"
      class="col-sm-2 text-right control-label col-form-label">CC#</label>
    <div class="col-sm-4 form-group mb-0">
      <input type="text"
        class="form-control form-control-sm"
        id="ccNumber"
        [(ngModel)]="model.ccNumber"
        name="ccNumber">
    </div>
  </div>
  <div class="form-group row">
    <label for="title"
      class="col-sm-2 text-right control-label col-form-label">Customer</label>
    <div class="col-sm-4 form-group mb-0"
      *ngIf="customerService.customerList | async; let cust">
      <div class="input-group">
        <ng-select [items]="cust"
          bindLabel="name"
          bindValue="customerId"
          (change)="onCustomerChange($event)"
          [(ngModel)]="model.customerId"
          class="col-md-9"
          style="padding:0px;"
          name="customers"
          required>
        </ng-select>
        <div class="input-group-append col-md-3"
          style="padding: 0;width:100%">
          <span class="input-group-text"
            style="padding: 0px;"><button (click)="openCustomerModal(0)"
              style="display: inline-flex;padding: 4px 10px;width:100%"
              class="btn btn-sm btn-default"
              [disabled]="disableTopFields || !(userService.user.value && userService.user.value.activeRoles && userService.user.value.activeRoles.includes('General'))"><i
                class="fa fa-plus"
                style="position: relative; top: 3px; margin-right: 5px;"></i>&nbsp;New</button></span>
        </div>
      </div>
    </div>
    <label for="title"
      class="col-sm-2 text-right control-label col-form-label">Ordered By</label>
    <div class="col-sm-4 form-group mb-0"
      *ngIf="contactService.contactList | async; let con">
      <div class="input-group">
        <ng-select [items]="con"
          bindLabel="fullName"
          bindValue="contactId"
          [(ngModel)]="model.orderedById"
          class="col-md-9"
          style="padding:0px;"
          name="contacts">
        </ng-select>
        <div class="input-group-append col-md-3"
          style="padding: 0;width:100%">
          <span class="input-group-text"
            style="padding: 0px;"><button (click)="openContactModal(0)"
              style="display: inline-flex;padding: 4px 10px;width:100%"
              class="btn btn-sm btn-default"
              [disabled]="disableTopFields"><i class="fa fa-plus"
                style="position: relative; top: 3px; margin-right: 5px;"></i>&nbsp;New</button></span>
        </div>
      </div>
    </div>
    <div class="col-sm-4"
      *ngIf="(contactService.contactList | async)===null; let con">
      <div class="input-group">
        <input type="text"
          class="form-control"
          readonly>
        <div class="input-group-append col-md-3"
          style="padding: 0;width:100%">
          <span class="input-group-text"
            style="padding: 0px;"><button style="display: inline-flex;padding: 4px 10px;width:100%"
              class="btn btn-sm btn-default"
              [disabled]="true"><i class="fa fa-plus"
                style="position: relative; top: 3px; margin-right: 5px;"></i>&nbsp;New</button></span>
        </div>
      </div>
    </div>
  </div>
  <div class="form-group row">
    <label for="title"
      class="col-sm-2 text-right control-label col-form-label">Deliver To</label>
    <div class="col-sm-4 form-group mb-0">
      <ng-select [items]="[{display: 'Well', value: true}, {display: 'Address', value: false}]"
        bindLabel="display"
        bindValue="value"
        (change)="onChangeDeliverTo($event)"
        [ngModel]="isWell"
        [clearable]="false"
        name="deliverTo"
        required>
      </ng-select>
    </div>
    <label for="title"
      class="col-sm-2 text-right control-label col-form-label"
      *ngIf="isWell===false">Address</label>
    <div class="col-sm-4 form-group mb-0"
      *ngIf="isWell===false&&addressService.addressList | async; let addr">
      <div class="input-group">
        <ng-select [items]="addr"
          bindLabel="addressString"
          bindValue="addressId"
          [(ngModel)]="model.addressId"
          (change)="updateCountyInfo($event)"
          class="col-md-9"
          style="padding:0px;"
          name="addresses"
          required>
        </ng-select>
        <div class="input-group-append col-md-3"
          style="padding: 0;width:100%">
          <span class="input-group-text"
            style="padding: 0px;"><button (click)="openAddressModal(0)"
              style="display: inline-flex;padding: 4px 10px;width:100%"
              class="btn btn-sm btn-default"
              [disabled]="disableTopFields"><i class="fa fa-plus"
                style="position: relative; top: 3px; margin-right: 5px;"></i>&nbsp;New</button></span>
        </div>
      </div>
    </div>
    <div class="col-sm-4"
      *ngIf="isWell===false&&(addressService.addressList | async)===null; let addr">
      <div class="input-group">
        <input type="text"
          class="form-control"
          readonly>
        <div class="input-group-append col-md-3"
          style="padding: 0;width:100%">
          <span class="input-group-text"
            style="padding: 0px;">
            <button style="display: inline-flex;padding: 4px 10px;width:100%"
              class="btn btn-sm btn-default"
              [disabled]="true"><i class="fa fa-plus"
                style="position: relative; top: 3px; margin-right: 5px;"></i>&nbsp;New</button>
          </span>
        </div>
      </div>
    </div>
    <label for="title"
      class="col-sm-2 text-right control-label col-form-label"
      *ngIf="isWell===true">Well</label>
    <div class="col-sm-4 form-group mb-0"
      *ngIf="isWell===true&&wellService.wellList | async; let well">
      <div class="input-group">
        <ng-select [items]="well"
          bindLabel="name"
          bindValue="wellId"
          [(ngModel)]="model.wellId"
          (change)="updateCountyInfo($event)"
          class="col-md-9"
          style="padding:0px;"
          name="wells"
          required>
        </ng-select>
        <div class="input-group-append col-md-3"
          style="padding: 0;width:100%">
          <span class="input-group-text"
            style="padding: 0px;"><button (click)="openWellModal(0)"
              style="display: inline-flex;padding: 4px 10px;width:100%"
              class="btn btn-sm btn-default"
              [disabled]="disableTopFields"><i class="fa fa-plus"
                style="position: relative; top: 3px; margin-right: 5px;"></i>&nbsp;New</button></span>
        </div>
      </div>
    </div>
    <div class="col-sm-4"
      *ngIf="isWell===true&&(wellService.wellList | async)===null; let well">
      <div class="input-group">
        <input type="text"
          class="form-control"
          readonly>
        <div class="input-group-append col-md-3"
          style="padding: 0;width:100%">
          <span class="input-group-text"
            style="padding: 0px;">
            <button style="display: inline-flex;padding: 4px 10px;width:100%"
              class="btn btn-sm btn-default"
              [disabled]="true"><i class="fa fa-plus"
                style="position: relative; top: 3px; margin-right: 5px;"></i>&nbsp;New</button>
          </span>
        </div>
      </div>
    </div>
  </div>
  <div class="form-group row">
    <label for="title"
      class="col-sm-2 text-right control-label col-form-label">Rig</label>
    <div class="col-sm-4 form-group mb-0"
      *ngIf="rigService.rigList | async; let rig">
      <div class="input-group">
        <ng-select [items]="rig"
          bindLabel="name"
          bindValue="rigId"
          [(ngModel)]="model.rigId"
          class="col-md-9"
          style="padding:0px;"
          name="rigs">
        </ng-select>
        <div class="input-group-append col-md-3"
          style="padding: 0;width:100%">
          <span class="input-group-text"
            style="padding: 0px;"><button (click)="openRigModal(0)"
              style="display: inline-flex;padding: 4px 10px;width:100%"
              class="btn btn-sm btn-default"
              [disabled]="disableTopFields"><i class="fa fa-plus"
                style="position: relative; top: 3px; margin-right: 5px;"></i>&nbsp;New</button></span>
        </div>
      </div>
    </div>
    <div class="col-sm-4"
      *ngIf="(rigService.rigList | async)===null; let rig">
      <div class="input-group">
        <input type="text"
          class="form-control"
          readonly>
        <div class="input-group-append col-md-3"
          style="padding: 0;width:100%">
          <span class="input-group-text"
            style="padding: 0px;"><button style="display: inline-flex;padding: 4px 10px;width:100%"
              class="btn btn-sm btn-default"
              [disabled]="true"><i class="fa fa-plus"
                style="position: relative; top: 3px; margin-right: 5px;"></i>&nbsp;New</button></span>
        </div>
      </div>
    </div>
    <label for="title"
      class="col-sm-2 text-right control-label col-form-label">County</label>
    <div class="col-sm-4"
      *ngIf="addrOrWellHasCounty">
      <input type="text"
        class="form-control"
        [ngModel]="model.countyName"
        readonly>
    </div>
    <ng-container *ngIf="!addrOrWellHasCounty">
      <div class="col-sm-4 form-group mb-0"
        *ngIf="counties">
        <ng-select [items]="counties"
          bindLabel="name"
          bindValue="countyId"
          [(ngModel)]="model.countyId"
          name="county"
          required>
        </ng-select>
      </div>
    </ng-container>
  </div>
  <div class="form-group row"
    *ngIf="!handSelect">
    <label for="title"
      class="col-sm-2 text-right control-label col-form-label">Delivered By</label>
    <div class="col-sm-4 form-group mb-0">
      <ng-select [items]="[{display: 'Employee', value: true}, {display: 'Customer/Third Party', value: false}]"
        bindLabel="display"
        bindValue="value"
        (change)="onChangeDeliveredBy($event)"
        [ngModel]="deliveredByEmployee"
        [clearable]="false"
        name="deliveredBy">
      </ng-select>
    </div>
    <label for="title"
      class="col-sm-2 text-right control-label col-form-label"
      *ngIf="deliveredByEmployee===true">Employee</label>
    <div class="col-sm-4 form-group mb-0"
      *ngIf="deliveredByEmployee===true&&employeeService.employeeList | async; let emp">
      <ng-select [items]="emp"
        bindLabel="fullName"
        bindValue="employeeId"
        [(ngModel)]="model.deliveredById"
        name="customers">
      </ng-select>
    </div>
    <label for="title"
      class="col-sm-2 text-right control-label col-form-label"
      *ngIf="deliveredByEmployee===false">Customer/Third Party</label>
    <div class="col-sm-4 form-group mb-0"
      *ngIf="deliveredByEmployee===false">
      <input type="text"
        class="form-control form-control-sm"
        id="deliveredByThirdParty"
        [(ngModel)]="model.deliveredByThirdParty"
        name="deliveredByThirdParty">
    </div>
  </div>
  <div class="form-group row">
    <label for="title"
      class="col-sm-2 text-right control-label col-form-label">Job Owner</label>
    <div class="col-sm-4 form-group mb-0"
      *ngIf="employeeService.employeeList | async; let emp">
      <ng-select [items]="emp"
        bindLabel="fullName"
        bindValue="employeeId"
        [(ngModel)]="model.jobOwnerId"
        name="contacts"
        (change)="onSelectOfJobOwner($event)"
        required>
      </ng-select>
    </div>
    <label for="title"
      class="col-sm-2 text-right control-label col-form-label">Shop</label>
    <div class="col-sm-4 form-group mb-0"
      *ngIf="shops">
      <ng-select [items]="shops"
        bindLabel="name"
        bindValue="shopId"
        [(ngModel)]="model.shopId"
        [clearable]="false"
        name="shops">
      </ng-select>
    </div>
  </div>
  <div class="form-group row"
    *ngIf="handSelect">
    <label for="title"
      class="col-sm-2 text-right control-label col-form-label">Hands</label>
    <div class="col-sm-4 form-group mb-0"
      *ngIf="userService.handList | async; let hands">
      <ng-select [items]="hands"
        bindLabel="displayName"
        bindValue="id"
        [multiple]="true"
        [closeOnSelect]="false"
        [searchable]="true"
        placeholder="Select Hands"
        [(ngModel)]="model.jobHandUserIds"
        name="hands">
      </ng-select>
    </div>
  </div>
  <div class="form-group row">
    <label for="title"
      class="col-sm-2 text-right control-label col-form-label">Work Description</label>
    <div class="col-sm-10 form-group mb-0">
      <textarea type="text"
        class="form-control form-control-sm"
        id="workDescription"
        [(ngModel)]="model.workDescription"
        name="workDescription"></textarea>
    </div>
  </div>
  <div class="modal-footer"
    *ngIf="model">
    <button type="button"
      class="btn btn-primary"
      (click)="onSave()"
      [disabled]="disableSave || 
        !(model.jobOwnerId
          &&model.customerId
          &&model.countyId
          &&model.jobDate
          &&model.jobTypeId
          &&model.jobStatusId
          &&(isWell&&model.wellId
            ||!isWell&&model.addressId))">Save</button>
    <button type="button"
      class="btn btn-default"
      (click)="bsModalRef.hide()">Cancel</button>
  </div>
