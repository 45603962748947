import { Component, OnDestroy, OnInit } from '@angular/core';
import { RowClassArgs } from '@progress/kendo-angular-grid';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { KGridService } from 'src/services/k-grid.service';
import { ThreadTypeService } from 'src/services/thread-type.service';
import { UIService } from 'src/services/ui.service';
import { AddEditThreadTypeModalComponent } from '../add-edit-thread-type-modal/add-edit-thread-type-modal.component';

@Component({
  selector: 'app-thread-types-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.css']
})

export class ThreadTypeListComponent implements OnInit, OnDestroy {

  public showInactive: boolean = false;

  constructor(
    private modalService: BsModalService,
    public kGridService: KGridService,
    public threadTypeService: ThreadTypeService,
    public uiService: UIService,
  ) { };

  public bsModalRef: BsModalRef;

  private deleteSub: Subscription;

  ngOnDestroy() {
    this.kGridService.resetKGridParams();

    this.deleteSub?.unsubscribe();
  }

  ngOnInit(): void {
    this.threadTypeService.getThreadTypes(this.showInactive, true);
  }

  public openThreadTypeModal(vendorId: number): void {
    const initialState = {
      id: vendorId,
      showInactive: this.showInactive
    };

    this.bsModalRef = this.modalService.show(AddEditThreadTypeModalComponent, { initialState, backdrop: 'static' });
  }

  public deleteThreadType(id: number): void {
    this.deleteSub = this.threadTypeService.deleteThreadType(id, this.showInactive)
      .subscribe((x: boolean) => {
        if (x) {
          this.uiService.showSuccess('Success', 'Thread Type Deleted');
        } else {
          this.uiService.showError('Error', 'Thread Type Not Deleted');
        }
      });
  }

  public onChangeShowInactive($event: boolean): void {
    this.showInactive = $event;

    this.threadTypeService.getThreadTypes(this.showInactive);
  }

  public rowCallback(context: RowClassArgs): any {
    return {
      inactive: context.dataItem.isActive === false
    };
  }

}
