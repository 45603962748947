import { Component, OnDestroy, OnInit } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { UIService } from 'src/services/ui.service';
import { WorksheetTypeService } from 'src/services/worksheet-type.service';
import { WorksheetTypeModel } from '../../../models/worksheet-type.model';
import { WorksheetModalComponent } from '../worksheet-modal/worksheet-modal.component';

@Component({
  selector: 'app-choose-worksheet-modal',
  templateUrl: './choose-worksheet-modal.component.html',
  styleUrls: ['choose-worksheet-modal.component.css']
})

export class ChooseWorksheetModalComponent implements OnInit, OnDestroy {
  public showInactive: boolean = false;
  public jobId: number = 0;
  public packersOnly = false;
  public customerId = null;
  public showOnJob: boolean = false;
  public worksheetTypes: WorksheetTypeModel[];
  public worksheetTypesFiltered: WorksheetTypeModel[];
  public searchTerm: string = '';

  private wsTypeSub: Subscription;

  constructor(
    public bsModalRef: BsModalRef,
    public bsModalRefChildModal: BsModalRef,
    public worksheetTypeService: WorksheetTypeService,
    public uiService: UIService,
    private modalService: BsModalService
  ) { }

  ngOnDestroy(): void {
    this.wsTypeSub?.unsubscribe();
  }

  ngOnInit(): void {
    this.getWorksheetTypes();
  }

  public getWorksheetTypes(): void {
    this.wsTypeSub = this.worksheetTypeService.getWorksheetTypes(
      false,
      this.packersOnly)
      .subscribe((x: WorksheetTypeModel[]) => {
        this.worksheetTypes = x;
        this.filterWorksheetTypes(null);
      });
  }

  public filterWorksheetTypes(str: string): void {
    if (str !== undefined && str !== null && str.trim() !== '') {
      this.worksheetTypesFiltered = this.worksheetTypes.filter((ws: WorksheetTypeModel) => ws.name.trim().toLowerCase().includes(str.trim().toLowerCase()));
    } else {
      this.worksheetTypesFiltered = this.worksheetTypes;
    }
  }

  public selectWorksheetModal(
    id: number,
    worksheetTypeId: number): void {
    this.openWorksheetModal(id, worksheetTypeId);
    this.bsModalRef.hide();
  }

  public openWorksheetModal(
    id: number,
    worksheetTypeId: number): void {
    const initialState = {
      id: id,
      jobId: this.jobId,
      customerId: this.customerId,
      worksheetTypeId: worksheetTypeId,
      showOnJob: this.showOnJob
    };

    this.bsModalRefChildModal = this.modalService.show(WorksheetModalComponent, { initialState, class: 'modal-xl', backdrop: 'static' });
  }

}
