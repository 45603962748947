import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserModel } from '../../../models/user.model';
import { AuthenticationService } from '../../../services/authentication.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
})

export class AuthLoginComponent implements OnInit, OnDestroy {
  public userModel: UserModel = new UserModel();
  public errorMessage: string;
  private authSub: Subscription;

  constructor(public authenticationService: AuthenticationService,
    private activatedRoute: ActivatedRoute,
    private router: Router) { };

  ngOnDestroy() {
    this.authSub?.unsubscribe();
  }

  ngOnInit() {

  }

  login() {
    this.authSub = this.authenticationService.login(this.userModel.userName, this.userModel.password)
      .subscribe(async x => {
        if (x) {
          // login success redirect
          await this.router.navigateByUrl('/home', {
            replaceUrl: true
          });
        } else {
          // display error
          this.errorMessage = 'Invalid Username or Password';
        }
      });
  }
}
