import { Component, OnDestroy, OnInit } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { AddEditVendorModalComponent } from 'src/app/vendors/add-edit-vendor-modal/add-edit-vendor-modal.component';
import { AccountModel } from 'src/models/account.model';
import { PurchaseOrderModel } from 'src/models/purchase-order.model';
import { ShopModel } from 'src/models/shop.model';
import { PurchaseOrderService } from 'src/services/purchase-order.service';
import { UIService } from 'src/services/ui.service';
import { VendorService } from 'src/services/vendor.service';
import { JobModel } from '../../../models/job.model';
import { AccountService } from '../../../services/account.service';
import { JobService } from '../../../services/job.service';
import { ShopService } from '../../../services/shop.service';
import { VendorModel } from 'src/models/vendor.model';

@Component({
  selector: 'app-add-edit-po-modal',
  templateUrl: './add-edit-po-modal.component.html',
  styleUrls: ['./add-edit-po-modal.component.css']
})

export class AddEditPoModalComponent implements OnInit, OnDestroy {
  private accountsSub: Subscription;
  public accounts: AccountModel[];

  private shopsSub: Subscription;
  public shops: ShopModel[];

  private modelSub: Subscription;
  private jobsSub: Subscription;
  private saveSub: Subscription;
  private cacheSub: Subscription;

  public isNew: boolean = false;
  public id: number = 0;
  public openInventoryLine: boolean = false;
  public openMiscLine: boolean = false;
  public model: PurchaseOrderModel;
  public jobs: JobModel[] = [];
  public disableSave: boolean = false;

  constructor(
    public bsModalRef: BsModalRef,
    public bsModalRefChildModal: BsModalRef,
    public poService: PurchaseOrderService,
    public vendorService: VendorService,
    public jobService: JobService,
    public shopService: ShopService,
    public accountService: AccountService,
    private modalService: BsModalService,
    public uiService: UIService
  ) { };

  ngOnDestroy(): void {
    this.accountsSub?.unsubscribe();

    this.shopsSub?.unsubscribe();

    this.modelSub?.unsubscribe();

    this.jobsSub?.unsubscribe();

    this.saveSub?.unsubscribe();

    this.cacheSub?.unsubscribe();
  }

  ngOnInit(): void {
    this.isNew = this.id === 0;

    this.getJobs();

    this.getShops();

    this.vendorService.getVendors(false, true);

    this.getAccounts();

    this.subscribeToVendorCache();

    if (this.isNew) {
      this.model = this.poService.generateNewPoModel();
    } else {
      this.getModel();
    }
  }

  public getModel(): void {
    this.modelSub = this.poService.getPurchaseOrderById(this.id)
      .subscribe((x: PurchaseOrderModel) => {
        this.model = x;
      });
  }

  public getShops(): void {
    this.shopsSub = this.shopService.shopList$
      .subscribe((shops: ShopModel[]) => {
        if (shops) {
          this.shops = shops;
        }
      });
  }

  public getAccounts(): void {
    this.accountsSub = this.accountService.accountList$
      .subscribe((accounts: AccountModel[]) => {
        if (accounts) {
          this.accounts = accounts;
        }
      });
  }

  public getJobs(): void {
    this.jobsSub = this.jobService.getJobsForDropdown(false)
      .subscribe((x: JobModel[]) => {
        this.jobs = x;
      });
  }

  public onJobChange($event: JobModel): void {
    if ($event !== undefined) {
      this.model.jobId = $event.jobId;
      this.model.jobDisplay = $event.dropdownDisplay;
      this.model.jobTypeId = $event.jobTypeId;
    } else {
      this.model.jobId = null;
      this.model.jobDisplay = null;
      this.model.jobTypeId = null;
    }
  }

  public onShopChange($event: ShopModel): void {
    if ($event !== undefined) {
      this.model.shopId = $event.shopId;
      this.model.shopName = $event.name;
    }
  }

  public onAccountChange($event: AccountModel): void {
    if ($event !== undefined) {
      this.model.accountId = $event.accountId;
      this.model.accountName = $event.name;
    }
  }

  public updateVendorName($event: VendorModel): void {
    if ($event !== undefined) {
      this.model.vendorId = $event.vendorId;
      this.model.vendorName = $event.name;
    } else {
      this.model.vendorId = null;
      this.model.vendorName = null;
    }
  }

  public onSave(): void {
    this.disableSave = true;

    this.saveSub = this.poService.addEditPurchaseOrder(
      this.model,
      this.openInventoryLine,
      this.openMiscLine)
      .subscribe((x: boolean) => {
        if (x) {
          this.bsModalRef.hide();
          this.uiService.showSuccess('PO Saved', 'Success');
        } else {
          this.disableSave = false;
          this.uiService.showError('PO Not Saved', 'Error');
        }
      });
  }

  public openVendorModal(vendorId: number): void {
    const initialState = {
      id: vendorId,
      showInactive: false,
      launchedFromPoModal: true
    };

    this.bsModalRefChildModal = this.modalService.show(AddEditVendorModalComponent, { initialState, class: 'modal-lg', backdrop: 'static' });
  }

  public subscribeToVendorCache(): void {
    this.vendorService.vendorCache.next(null);

    this.cacheSub = this.vendorService.vendorCache
      .subscribe((x: VendorModel) => {
        if (x !== null && x !== undefined) {
          this.model.vendorId = x.vendorId;
        }
      });
  }

}
