import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { PageChangeEvent, RowClassArgs } from '@progress/kendo-angular-grid';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { PartGroups } from 'src/enums/part-groups';
import { ItemInstanceService } from 'src/services/item-instance.service';
import { ItemService } from 'src/services/item.service';
import { KGridService } from 'src/services/k-grid.service';
import { UIService } from 'src/services/ui.service';
import { ItemInstanceLocations } from '../../../enums/item-instance-locations';
import { AddEditItemInstanceModalComponent } from '../add-edit-item-instance-modal/add-edit-item-instance-modal.component';

@Component({
  selector: 'app-item-instance-sm-list',
  templateUrl: './item-instance-sm-list.component.html',
  styleUrls: ['./item-instance-sm-list.component.css']
})

export class ItemInstanceSmallListComponent implements OnInit, OnDestroy {
  @Input() itemId: number = -1;
  @Input() partGroupId: number = -1;

  constructor(
    private modalService: BsModalService,
    public itemInstanceService: ItemInstanceService,
    public itemService: ItemService,
    public kGridService: KGridService,
    public uiService: UIService,
  ) { };

  public bsModalRef: BsModalRef;
  public showScrap: boolean = false;
  public ItemInstanceLocations = ItemInstanceLocations;
  public PartGroups = PartGroups;

  public deleteSub: Subscription;

  ngOnDestroy(): void {
    this.deleteSub?.unsubscribe();
  }

  ngOnInit(): void {

  }

  public openAddEditItemInstanceModal(
    iiId: number,
    itemId: number): void {
    const initialState = {
      id: iiId,
      itemId: itemId,
      launchedFromItemDetail: true,
      partGroupId: this.partGroupId
    };

    this.bsModalRef = this.modalService.show(AddEditItemInstanceModalComponent, { initialState, class: 'modal-lg', backdrop: 'static' });
  }

  public kGridParams: any =
    {
      buttonCount: 5,
      info: true,
      type: '\'numeric\' | \'input\' = \'numeric\'',
      pageSizes: true,
      previousNext: true,
      pageSize: 10,
      skip: 0
    };

  public pageChange({ skip, take }: PageChangeEvent): void {
    this.kGridParams.skip = skip;
    this.kGridParams.pageSize = take;
  }

  public onChangeScrap($event: boolean): void {
    this.showScrap = $event;
    this.itemInstanceService.getItemInstancesByItemId(this.itemId, this.showScrap, false);
  }

  public deleteItemInstance(
    itemInstanceId: number,
    itemId: number): void {
    this.deleteSub = this.itemInstanceService.deleteItemInstance(itemInstanceId, itemId, this.showScrap, false)
      .subscribe((x: boolean) => {
        if (x) {
          this.uiService.showSuccess('Serialized Item Deleted', 'Success');
        } else {
          this.uiService.showError('Serialized Item NOT Deleted', 'Error');
        }
      });
  }

  public rowCallback(context: RowClassArgs): any {
    return {
      inactive: context.dataItem.itemInstanceLocationId === 2
    };
  }

}
