import { Component, OnDestroy, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { PartGroupModel } from 'src/models/part-group.model';
import { PartFamilyService } from 'src/services/part-family.service';
import { PartGroupService } from 'src/services/part-group.service';
import { UIService } from 'src/services/ui.service';
import { PartFamilyModel } from '../../../models/part-family.model';

@Component({
  selector: 'app-add-edit-part-group-modal',
  templateUrl: './add-edit-part-group-modal.component.html',
  styleUrls: ['add-edit-part-group-modal.component.css']
})

export class AddEditPartGroupModalComponent implements OnInit, OnDestroy {
  public isNew: boolean = false;
  public id: number = -1;
  public partFamilyId: number = -1;
  public fishingTools: boolean = false;
  public showInactive: boolean = false;
  public partFamilyName: string = '';
  public currentGtNumbers: number[];
  public gtIsInUse: boolean = false;
  public model: PartGroupModel;
  public partFamilies: PartFamilyModel[] = [];
  public partGroupNumberCache: number = -1;
  public disableSave: boolean = false;

  private modelSub: Subscription;
  private saveSub: Subscription;
  private gtNumSub: Subscription;
  private partFamilySub: Subscription;

  constructor(
    public bsModalRef: BsModalRef,
    public partGroupService: PartGroupService,
    public partFamilyService: PartFamilyService,
    public uiService: UIService,

  ) { };

  ngOnDestroy(): void {
    this.modelSub?.unsubscribe();

    this.saveSub?.unsubscribe();

    this.gtNumSub?.unsubscribe();

    this.partFamilySub?.unsubscribe();
  }

  ngOnInit() {
    this.showInactive = this.showInactive === true;
    this.isNew = this.id === 0;
    this.getPartFamilies();
    this.getCurrentGtNumbers();

    if (this.isNew) {
      this.model = this.partGroupService.generatePartGroupModel(this.partFamilyId);
    } else {
      this.getModel();
    }
  }

  public getModel(): void {
    this.modelSub = this.partGroupService.getPartGroupById(this.id)
      .subscribe((x: PartGroupModel) => {
        this.model = x;
        this.partGroupNumberCache = !isNaN(parseInt(this.model.gtPrefix)) ? parseInt(this.model.gtPrefix) : -1;
      });
  }

  public getCurrentGtNumbers(): void {
    this.gtNumSub = this.partGroupService.getCurrentGtPrefixes()
      .subscribe((x: number[]) => {
        this.currentGtNumbers = x;

        if (this.isNew) {
          const max = Math.max(...x);
          this.model.gtPrefix = (max + 1).toString();
        }
      });
  }

  public getPartFamilies(): void {
    this.partFamilySub = this.partFamilyService.getPartFamilies(
      false,
      true,
      false)
      .subscribe(pf => {
        this.partFamilies = pf;
      });
  }

  public checkIfGtIsInUse($event: any): void {
    if ($event !== undefined) {
      let parsed = parseInt(this.model.gtPrefix);

      if (!isNaN(parsed)) {
        this.gtIsInUse = this.currentGtNumbers.includes(parsed) && this.partGroupNumberCache !== parsed;
        if (this.model.gtPrefix.length === 1) {
          this.model.gtPrefix = '0' + this.model.gtPrefix;
        }
      } else {
        this.gtIsInUse = true;
      }
    }
  }

  public onSave(): void {
    this.disableSave = true;

    this.saveSub = this.partGroupService.addEditPartGroup(this.model)
      .subscribe((x: boolean) => {
        if (x) {
          this.bsModalRef.hide();
          this.uiService.showSuccess('Part Group Saved', 'Success');
        } else {
          this.disableSave = false;
          this.uiService.showError('Part Group Not Saved', 'Error');
        }
      });
  }

}
