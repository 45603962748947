import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthorizeService } from './authorize.service';
import { tap } from 'rxjs/operators';
import { ApplicationPaths, QueryParameterNames } from './api-authorization.constants';
import { UserService } from 'src/services/user.service';
import { UserModel } from 'src/models/user.model';

@Injectable({
  providedIn: 'root'
})
export class AuthorizeGuard implements CanActivate {
  constructor(private authorize: AuthorizeService, private router: Router) {
  }
  canActivate(
    _next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return this.authorize.isAuthenticated()
      .pipe(tap(isAuthenticated => this.handleAuthorization(isAuthenticated, state)));
  }

  private handleAuthorization(isAuthenticated: boolean, state: RouterStateSnapshot) {
    if (!isAuthenticated) {
      this.router.navigate(ApplicationPaths.LoginPathComponents, {
        queryParams: {
          [QueryParameterNames.ReturnUrl]: state.url
        }
      });
    }
  }
}

@Injectable({
  providedIn: 'root'
})
export class RoleGuard implements CanActivate {
  constructor(private router: Router, private userService: UserService) { }

  private verified: boolean = false;

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    const requiredRoles: string[] = route.data.requiredRoles || '';

    if (!this.userService.user.value) {
      return new Observable<boolean>((observer) => {
        this.userService.getUserInfo().subscribe((x: UserModel) => {
          this.userService.user.next(x);
          if (this.roleCheck(x, requiredRoles)) {
            observer.next(true);
          } else if (this.builderRedirect(this.userService.user.value)) {
            this.router.navigate(['/packers/list']);
            return false;
          } else {
            this.router.navigate(['/login']); // there is a bug here, this should just go to root (/) but it causes an infinite loop if we do that
            observer.next(false);
          }
          observer.complete();
        });
      });
    } else {
      if (this.roleCheck(this.userService.user.value, requiredRoles)) {
        return true;
      } else if (this.builderRedirect(this.userService.user.value)) {
        this.router.navigate(['/packers/list']);
        return false;
      } else {
        this.router.navigate(['/login']);
        return false;
      }
    }
  }

  roleCheck(user: UserModel, requiredRoles: string[]): boolean {
    return user.activeRoles.some(activeRole => requiredRoles.includes(activeRole));
  }

  builderRedirect(user: UserModel): boolean {
    return user.activeRoles.length === 1 && user.activeRoles.includes('Builder');
  }

}
