import { Component, OnDestroy, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { BillingCodeModel } from 'src/models/billing-code.model';
import { BillingCodeService } from 'src/services/billing-code.service';
import { CustomerService } from 'src/services/customer.service';
import { ItemService } from 'src/services/item.service';
import { UIService } from 'src/services/ui.service';
import { UnitOfMeasureService } from 'src/services/unit-of-measure.service';
import { UnitsOfMeasure } from '../../../enums/units-of-measure';
import { ItemDropdownModel } from '../../../models/item-dropdown.model';
import { UnitOfMeasureModel } from '../../../models/unit-of-measure.model';
import { ItemModel } from 'src/models/item.model';

@Component({
  selector: 'app-add-edit-billing-code-modal',
  templateUrl: './add-edit-billing-code-modal.component.html',
  styleUrls: ['./add-edit-billing-code-modal.component.css']
})

export class AddEditBillingCodeModalComponent implements OnInit, OnDestroy {
  private unitsOfMeasureSub: Subscription;
  public unitsOfMeasure: UnitOfMeasureModel[];

  private modelSub: Subscription;
  public model: BillingCodeModel = new BillingCodeModel();

  private itemsSub: Subscription;
  public items: ItemDropdownModel[] = [];

  private saveSub: Subscription;

  public isNew: boolean = false;
  public keepFilter: boolean = false;
  public launchedFromList: boolean = false;
  public launchedFromWorksheet = false;
  public isInventoryItem: boolean = false;
  public isSelectable: boolean = true;
  public getByItem: boolean = false;
  public billingCodeId = 0;
  public itemId = 0;
  public isInitialLoad: boolean = false;
  public showInactive: boolean = false;
  public cacheBillingCode: boolean = false;
  public UnitsOfMeasure = UnitsOfMeasure;
  public disableSave: boolean = false;

  constructor(
    public bsModalRef: BsModalRef,
    public bsModalRefChildModal: BsModalRef,
    public uiService: UIService,
    public itemService: ItemService,
    public billingCodeService: BillingCodeService,
    public customerService: CustomerService,

    public uomService: UnitOfMeasureService
  ) { };

  ngOnDestroy(): void {
    this.itemsSub?.unsubscribe();

    this.modelSub?.unsubscribe();

    this.saveSub?.unsubscribe();

    this.unitsOfMeasureSub?.unsubscribe();
  }

  ngOnInit() {
    this.isNew = this.billingCodeId === 0;

    this.getUnitsOfMeasure();

    this.customerService.getCustomers(false);

    this.itemService.getItemsDropdown(false, true, true, false);

    if (this.isInventoryItem) {
      this.subscribeToItems();
    }

    if (!this.isNew) {
      this.isInitialLoad = true;

      this.getModel();
    } else {
      this.model = this.billingCodeService.generateNewBillingCodeModel();

      this.model.isSelectable = this.isSelectable;

      if (this.isInventoryItem) {
        this.model.itemId = this.itemId;
      }
    }
  }

  public getUnitsOfMeasure(): void {
    this.unitsOfMeasureSub = this.uomService.uomList$
      .subscribe((unitsOfMeasure: UnitOfMeasureModel[]) => {
        if (unitsOfMeasure) {
          this.unitsOfMeasure = unitsOfMeasure;
        }
      });
  }

  public getModel(): void {
    this.modelSub = this.billingCodeService.getBillingCodeById(this.billingCodeId)
      .subscribe((x: BillingCodeModel) => {
        this.model = x;
      });
  }

  // this subscribe exists to remove a race condition where there would not yet be values in itemList when the modal renders
  public subscribeToItems(): void {
    this.itemsSub = this.itemService.itemDropdownList
      .subscribe((x: ItemDropdownModel[]) => {
        this.filterItems(this.model.itemId);
      });
  }

  // only called on change of Item select menu
  public onChangeOfItem($event: ItemModel): void {
    this.items.length = 0;

    if ($event !== undefined) {
      this.filterItems($event.itemId);
    }
  }

  public filterItems(itemId: number): void {
    this.items = this.itemService.itemDropdownList.value !== null ? this.itemService.itemDropdownList.value.filter((x: ItemDropdownModel) => {
      return x.itemId == itemId;
    }) : [];

    if (this.items.length > 0) {
      if (!this.isInitialLoad) {
        this.model.uomId = this.items[0].uomId;

        this.isInitialLoad = false;
      }
    }
  }

  public onChangeOfUom($event: UnitOfMeasureModel): void {
    if ($event !== undefined) {
      if ($event.uomId !== 1 && $event.uomId !== this.UnitsOfMeasure.Day) {
        this.model.dayOneRental = false;
        this.model.additionalDayRental = false;
      }
    }
  }

  public onSave(): void {
    this.disableSave = true;

    if (this.model.itemId) {
      this.model.name = null;
    }

    this.saveSub = this.billingCodeService.addEditBillingCode(
      this.model,
      this.showInactive,
      this.cacheBillingCode,
      !this.isSelectable,
      this.getByItem,
      this.keepFilter)
      .subscribe((x: boolean) => {
        if (x) {
          this.bsModalRef.hide();

          this.uiService.showSuccess('Billing Code Saved', 'Success');
        } else {
          this.uiService.showError('Billing Code Saved', 'Error');

          this.disableSave = false;
        }
      });
  }

}
