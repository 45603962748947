export class JobFilterParamsModel {
  jobNumber: number = null;
  jobTypeId: number = null;
  customerId: number = null;
  jobStatusId: number = null;
  jobSubStatusId: number = null;
  orderedById: number = null;
  jobOwnerId: number = null;
  deliveredById: number = null;
  wellId: number = null;
  shopId: number = null;
}
