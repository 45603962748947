<div class="btn-group btn-block mt-1 mb-1"
  dropdown
  placement="bottom right"
  id="two"
  *ngIf="jobService.job.value && jobService.job.value.jobStatusId === editId">
  <button type="button"
    class="btn btn-primary btn-block"
    [swal]="{ title: 'Finalize Job?', text: 'Job#:' + job.jobNumber + ' - ' + job.customerName, showCancelButton: true, confirmButtonText: 'Finalize', confirmButtonColor: '#1B6EC2' }"
    (confirm)="changeJobStatus(closeId);"
    [disabled]="enableReorder || enableReorderToolList || !canApproveInvoices">
    <i class="fad fa-check"></i>&nbsp;&nbsp;Finalize Invoice
  </button>
  <button *ngIf="canApproveInvoices"
    id="button-split"
    type="button"
    dropdownToggle
    class="btn btn-xs dropdown-toggle dropdown-toggle-split"
    [class.btn-light]="false"
    [class.btn-primary]="true"
    aria-controls="dropdown-split"
    [disabled]="enableReorder || enableReorderToolList">
    <span class="caret"></span>
    <span class="sr-only">Other Options</span>
  </button>
  <ul *dropdownMenu
    class="dropdown-menu btn-block"
    role="menu"
    aria-labelledby="button-split">
    <li role="menuitem">
      <a class="dropdown-item"
        *ngIf="jobService.job.value 
          && jobService.job.value.jobSubStatusId === JobSubStatuses.Sent_for_Signature"
        [swal]="{ title: 'BACK TO In Progress?', text: 'Job#:' + job.jobNumber + ' - ' + job.customerName, showCancelButton: true, confirmButtonText: 'Back', confirmButtonColor: '#1B6EC2' }"
        (confirm)="changeJobSubStatus(JobSubStatuses.Ready_for_Signature);"
        [disabled]="enableReorder">
        <i class="far fa-undo"></i> BACK (Ready for Signature)
      </a>
    </li>
  </ul>
</div>
<div class="btn-group btn-block mt-1 mb-1"
  dropdown
  placement="bottom right"
  *ngIf="jobService.job.value && jobService.job.value.jobStatusId === openId && job.showSplitInvoiceButton === false"
  id="one">
  <button type="button"
    class="btn btn-primary btn-block"
    [swal]="{ title: 'Edit Invoice?', text: 'Selecting this status will allow full editing of the invoice.  Changes to the Tool List or Work Logs will NO LONGER BE REFLECTED on the Invoice.', showCancelButton: true, confirmButtonText: 'Edit Invoice', confirmButtonColor: '#1B6EC2' }"
    (confirm)="changeJobStatus(editId);"
    [disabled]="enableReorder || enableReorderToolList || !canApproveInvoices">
    <i class="fad fa-edit"></i>&nbsp;&nbsp;Edit Invoice
  </button>
  <button *ngIf="canApproveInvoices"
    id="button-split"
    type="button"
    dropdownToggle
    class="btn btn-xs dropdown-toggle dropdown-toggle-split"
    [class.btn-light]="false"
    [class.btn-primary]="true"
    aria-controls="dropdown-split"
    [disabled]="enableReorder || enableReorderToolList">
    <span class="caret"></span>
    <span class="sr-only">Other Options</span>
  </button>
  <ul *dropdownMenu
    class="dropdown-menu btn-block"
    role="menu"
    aria-labelledby="button-split">
    <li role="menuitem">
      <a class="dropdown-item"
        *ngIf="jobService.job.value 
          && jobService.job.value.jobSubStatusId === JobSubStatuses.Pending_Approval"
        [swal]="{ title: 'BACK TO In Progress?', text: 'Job#:' + job.jobNumber + ' - ' + job.customerName, showCancelButton: true, confirmButtonText: 'Back', confirmButtonColor: '#1B6EC2' }"
        (confirm)="changeJobSubStatus(JobSubStatuses.In_Progress);"
        [disabled]="enableReorder">
        <i class="far fa-undo"></i> BACK (In Progress)
      </a>
    </li>
    <li role="menuitem">
      <a class="dropdown-item"
        [swal]="{ title: 'Finalize Job?', showCancelButton: true, confirmButtonText: 'Finalize Job?', confirmButtonColor: '#1B6EC2' }"
        (confirm)="changeJobStatus(closeId);"><i class="fad fa-check"></i>
        Finalize Invoice</a>
    </li>
    <li role="menuitem"><a class="dropdown-item"
        (click)="openCancelJobConfirmModal(job, cancellationId)"><i class="fad fa-times-circle"></i>
        Cancel Invoice</a></li>
  </ul>
</div>
<div class="btn-group btn-block mt-1 mb-1"
  dropdown
  placement="bottom right"
  *ngIf="jobService.job.value && jobService.job.value.jobStatusId === openId && job.showSplitInvoiceButton === true"
  id="two">
  <button type="button"
    class="btn btn-primary btn-block"
    [swal]="{ title: 'Split & Edit Invoice?', text: 'Job#:' + job.jobNumber + ' - ' + job.customerName, showCancelButton: true, confirmButtonText: 'Split & Edit Invoice', confirmButtonColor: '#1B6EC2' }"
    (confirm)="splitInvoice();"
    [disabled]="enableReorder || enableReorderToolList || !canApproveInvoices">
    <i class="fad fa-copy"></i>&nbsp;&nbsp;Split & Edit Invoice
  </button>
  <button *ngIf="canApproveInvoices"
    id="button-split"
    type="button"
    dropdownToggle
    class="btn btn-xs dropdown-toggle dropdown-toggle-split"
    [class.btn-light]="false"
    [class.btn-primary]="true"
    aria-controls="dropdown-split"
    [disabled]="enableReorder || enableReorderToolList">
    <span class="caret"></span>
    <span class="sr-only">Other Options</span>
  </button>
  <ul *dropdownMenu
    class="dropdown-menu btn-block"
    role="menu"
    aria-labelledby="button-split">
    <li role="menuitem">
      <a class="dropdown-item"
        *ngIf="jobService.job.value 
          && jobService.job.value.jobSubStatusId === JobSubStatuses.Pending_Approval"
        [swal]="{ title: 'BACK TO In Progress?', text: 'Job#:' + job.jobNumber + ' - ' + job.customerName, showCancelButton: true, confirmButtonText: 'Back', confirmButtonColor: '#1B6EC2' }"
        (confirm)="changeJobSubStatus(JobSubStatuses.In_Progress);"
        [disabled]="enableReorder">
        <i class="far fa-undo"></i> BACK (In Progress)
      </a>
    </li>
    <li role="menuitem">
      <a class="dropdown-item"
        [swal]="{ title: 'Finalize Job?', text: 'Are you SURE you want to close Job#' + job.jobNumber + '? The current Total of $' + job.totalStamp + ' is above ' + job.customerName + '\'s individual invoice limit of $' + job.customerMax + '!', showCancelButton: true, confirmButtonText: 'Finalize Job?', confirmButtonColor: '#1B6EC2' }"
        (confirm)="changeJobStatus(closeId);"><i class="fad fa-check"></i>
        Finalize Invoice Without Split</a>
    </li>
    <li role="menuitem"><a class="dropdown-item"
        (click)="openCancelJobConfirmModal(job, cancellationId)"><i class="fad fa-times-circle"></i>
        Cancel Invoice</a></li>
  </ul>
</div>
<button type="button"
  class="btn btn-primary btn-block mt-1 mb-1"
  *ngIf="jobService.job.value && jobService.job.value.jobStatusId === closeId"
  disabled>
  <i class="fad fa-check"></i>&nbsp;&nbsp;Invoice Finalized
</button>
<button type="button"
  class="btn btn-danger btn-block mt-1 mb-1"
  *ngIf="jobService.job.value && jobService.job.value.jobStatusId === cancellationId"
  disabled>
  <i class="fad fa-times-circle"></i>&nbsp;&nbsp;Invoice Canceled
</button>
