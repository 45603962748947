import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { JobStatusHistoryCombinedModel } from 'src/models/job-status-history-combined.model';
import { JobStatusHistoryModel } from 'src/models/job-status-history.model';
import { JobService } from 'src/services/job.service';

@Component({
  selector: 'app-job-status-history-popover',
  templateUrl: './job-status-history-popover.component.html',
  styleUrls: ['./job-status-history-popover.component.css']
})

export class JobStatusHistoryPopoverComponent implements OnInit, OnDestroy {
  @Input() jobId: number;
  public statusHistory: JobStatusHistoryModel[] = null;
  public combinedStatusHistory: JobStatusHistoryCombinedModel[] = null;

  private statusHistorySub: Subscription;

  constructor(
    public jobService: JobService,
  ) { };

  ngOnDestroy(): void {
    this.statusHistorySub?.unsubscribe();
  }

  ngOnInit(): void {
    this.getStatusHistory();
  }

  public getStatusHistory(): void {
    this.statusHistorySub = this.jobService.getCombinedJobStatusHistoryByJobId(this.jobId)
      .subscribe((x: JobStatusHistoryCombinedModel[]) => {
        this.combinedStatusHistory = x;
      });
  }

}
