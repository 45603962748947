import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { PurchaseOrderModel } from '../models/purchase-order.model';
import { Router } from '@angular/router';

@Injectable()
export class AuthenticationService {
  constructor(
    private http: HttpClient, 
    @Inject('BASE_URL') private baseUrl: string, 
    private router: Router
    ) { }

  public purchaseOrder: BehaviorSubject<PurchaseOrderModel> = new BehaviorSubject(null);
  public isLoading: boolean = false;

  private apiUrl = 'api/Authentication';  // URL to web api

  public login(username: string, password: string): Observable<Boolean> {
    return this.http.post<Boolean>(this.baseUrl + this.apiUrl + '/Login', { username: username, password: password });
  }

}
