<div class="page-header">
  <div class="page-header-content header-elements-md-inline">
    <div class="page-title d-flex">
      <a [routerLink]="['/home']"
        class="breadcrumb-link-color">
        <h4><i class="icon-arrow-left52 mr-2"></i> <span class="font-weight-semibold">Worksheet Type Master</span> -
          Manage</h4>
      </a>
    </div>
    <div class="header-elements d-none py-0 mb-3 mb-md-0">
      <div class="breadcrumb">
        <a href="index.html"
          class="breadcrumb-item"><i class="icon-home2 mr-2"></i> Worksheet Type Master</a>
        <span class="breadcrumb-item active">List</span>
      </div>
    </div>
  </div>
</div>
<div class="page-content pt-0">
  <div class="content-wrapper">
    <div class="content">
      <div class="row">
        <div class="col-md-12">
          <div class="card border-top-primary border-top-2 rounded-top-0"
            style="border-top-color: #2196F3 !important;">
            <div class="card-header bg-light header-elements-sm-inline">
              <h6 class="card-title">Worksheet Types</h6>
              <div class="header-elements">
                <div class="switch-container pt-2 mr-2">
                  <span class="switch-label">Show Inactive </span>
                  <ui-switch [ngModel]="showInactive"
                    (change)="onChangeShowInactive($event)"></ui-switch>
                </div>
                <button class="btn btn-light btn-sm"
                  (click)="openWorksheetTypeModal(0)">
                  <i class="fa fa-plus"></i> New Worksheet Type
                </button>
              </div>
            </div>
            <app-table-skeleton *ngIf="worksheetTypeService.typeListIsLoading"></app-table-skeleton>
            <ng-container *ngIf="!worksheetTypeService.typeListIsLoading">
              <div class="table-responsive"
                *ngIf="worksheetTypeService.worksheetTypeList | async; let model">
                <kendo-grid [ngClass]="{'kendo-min-width': !isTablet}"
                  [style.maxHeight.px]="650"
                  [kendoGridBinding]="model"
                  filterable="menu"
                  [sortable]="true"
                  [resizable]="true"
                  [rowClass]="rowCallback"
                  [pageSize]="kGridService.kGridParams.pageSize"
                  [skip]="kGridService.kGridParams.skip"
                  (pageChange)="kGridService.pageChange($event)"
                  [pageable]="{
                              buttonCount: kGridService.kGridParams.buttonCount,
                              info: kGridService.kGridParams.info,
                              type: kGridService.kGridParams.type,
                              pageSizes: kGridService.kGridParams.pageSizes,
                              previousNext: kGridService.kGridParams.previousNext
                            }">
                  <kendo-grid-column field="one"
                    title=""
                    [width]="60"
                    filterable="false">
                    <ng-template kendoGridCellTemplate
                      let-dataItem
                      let-rowIndex="rowIndex">
                      <a class="btn btn-xs btn-light"
                        [routerLink]="['/worksheet-types/details', dataItem.worksheetTypeId]"><i
                          class="fa fa-search"></i></a>
                    </ng-template>
                  </kendo-grid-column>
                  <kendo-grid-column field="name"
                    [width]="300"
                    title="Worksheet">
                    <ng-template kendoGridCellTemplate
                      let-dataItem
                      let-rowIndex="rowIndex">
                      <ng-template #popoverTemplateBc>
                        <div class="pl-2 pr-2">
                          <div class="row">
                            <div class="col-xs-12">
                              <a [routerLink]="['/items/details', dataItem.itemId]"
                                target="_blank">{{dataItem.itemDescription}}</a>
                            </div>
                          </div>
                        </div>
                      </ng-template>
                      <div class="d-flex justify-content-between">
                        <span>{{dataItem.name}}</span>
                        <span *ngIf="dataItem.itemId"
                          class="text-primary pointer">
                          &nbsp;&nbsp;
                          <i class="fal fa-cog"
                            type="button"
                            [popover]="popoverTemplateBc"
                            popoverTitle="Associated Item"
                            placement="auto"
                            [outsideClick]="true"
                            container="body">
                          </i>
                        </span>
                      </div>
                    </ng-template>
                  </kendo-grid-column>
                  <kendo-grid-column field="billingCodeName"
                    title="Billing Code"
                    [width]="300">
                  </kendo-grid-column>
                  <kendo-grid-column field="worksheetCategoryName"
                    title="Worksheet Category"
                    [width]="140">
                  </kendo-grid-column>
                  <kendo-grid-column field="sortOrder"
                    title="Order"
                    filterable="false"
                    [width]="80">
                  </kendo-grid-column>
                  <kendo-grid-column field="showIsCustomerProperty"
                    title="Show IsCustomerProperty"
                    filterable="false"
                    [width]="170">
                    <ng-template kendoGridCellTemplate
                      let-dataItem
                      let-rowIndex="rowIndex">
                      <span *ngIf="dataItem.showIsCustomerProperty"><i class="fas fa-check"></i></span>
                    </ng-template>
                  </kendo-grid-column>
                  <kendo-grid-column field="isItemized"
                    title="Is Itemized"
                    filterable="false"
                    [width]="85">
                    <ng-template kendoGridCellTemplate
                      let-dataItem
                      let-rowIndex="rowIndex">
                      <span *ngIf="dataItem.isItemized"><i class="fas fa-check"></i></span>
                    </ng-template>
                  </kendo-grid-column>
                  <kendo-grid-column field="isRebuild"
                    title="Is Rebuild"
                    filterable="false"
                    [width]="85">
                    <ng-template kendoGridCellTemplate
                      let-dataItem
                      let-rowIndex="rowIndex">
                      <span *ngIf="dataItem.isRebuild"><i class="fas fa-check"></i></span>
                    </ng-template>
                  </kendo-grid-column>
                  <kendo-grid-column field="isPacker"
                    title="Is Packer"
                    filterable="false"
                    [width]="85">
                    <ng-template kendoGridCellTemplate
                      let-dataItem
                      let-rowIndex="rowIndex">
                      <span *ngIf="dataItem.isPacker"><i class="fas fa-check"></i></span>
                    </ng-template>
                  </kendo-grid-column>
                  <kendo-grid-column field="isActive"
                    title="Active"
                    [width]="70"
                    filterable="false">
                    <ng-template kendoGridCellTemplate
                      let-dataItem
                      let-rowIndex="rowIndex">
                      <span [ngClass]="{ 'text-danger' : !dataItem.isActive }">{{dataItem.isActive ? 'Yes' :
                        'No'}}</span>
                    </ng-template>
                  </kendo-grid-column>
                  <kendo-grid-column field="one"
                    title=""
                    [width]="60"
                    filterable="false">
                    <ng-template kendoGridCellTemplate
                      let-dataItem
                      let-rowIndex="rowIndex">
                      <button class="btn btn-xs btn-light"
                        [swal]="{ title: 'Delete Worksheet Type?', text: dataItem.name, showCancelButton: true, confirmButtonText: 'Delete', confirmButtonColor: '#DC3545' }"
                        (confirm)="deleteWorksheetType(dataItem)"
                        [disabled]="dataItem.isActive === false">
                        <i class="fa fa-trash"></i>
                      </button>
                    </ng-template>
                  </kendo-grid-column>
                </kendo-grid>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
