import { BehaviorSubject, Observable, Observer } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { CustomerAreaModel } from 'src/models/customer-area.model';

@Injectable()
export class CustomerAreaService {
  constructor(
    private http: HttpClient,
    @Inject('BASE_URL') private baseUrl: string
  ) { }

  public customerAreaList: BehaviorSubject<Array<CustomerAreaModel>> = new BehaviorSubject(null);
  public customerAreaCache: BehaviorSubject<CustomerAreaModel> = new BehaviorSubject(null);

  private apiUrl = 'api/CustomerArea';  // URL to web api

  public addEditCustomerArea(
    dto: CustomerAreaModel,
    showInactive: boolean,
    cacheArea: boolean = false): Observable<boolean> {
    const url = this.baseUrl + this.apiUrl + '/AddEditCustomerArea';

    return new Observable((observer: Observer<boolean>) => {
      this.http.post<number>(url, this.cleanCustomerAreaModel(dto))
        .subscribe((x: number) => {
          if (x !== -1) {
            if (cacheArea) {
              dto.customerAreaId = x;
              this.customerAreaCache.next(dto);
            }
            this.refreshCustomerAreaList(dto.customerId, showInactive);
          }
          observer.next(x !== -1);
        });
    });
  }

  public deleteCustomerArea(
    dto: CustomerAreaModel,
    showInactive: boolean): Observable<boolean> {
    return new Observable((observer: Observer<boolean>) => {
      this.http.get<boolean>(this.baseUrl + this.apiUrl + '/DeleteCustomerArea/' + dto.customerAreaId)
        .subscribe((x: boolean) => {
          if (x === true) {
            if (dto.customerId !== null) {
              this.refreshCustomerAreaList(dto.customerId, showInactive);
            }
          }
          observer.next(x);
        });
    });
  }

  public refreshCustomerAreaList(
    id: number,
    showInactive: boolean): void {
    this.getCustomerAreas(id, showInactive)
      .subscribe((x: CustomerAreaModel[]) => {
        this.customerAreaList.next(x);
      });
  }

  public getCustomerAreas(
    id: number,
    showInactive: boolean): Observable<CustomerAreaModel[]> {
    return this.http.get<CustomerAreaModel[]>(this.baseUrl + this.apiUrl + '/GetCustomerAreas/' + id + '/' + showInactive);
  }

  public getCustomerAreaById(id: number): Observable<CustomerAreaModel> {
    return this.http.get<CustomerAreaModel>(this.baseUrl + this.apiUrl + '/GetCustomerAreaById/' + id);
  }

  public generateNewCustomerAreaModel(customerId: number): CustomerAreaModel {
    let model: CustomerAreaModel = new CustomerAreaModel();
    model.customerId = customerId;
    model.isActive = true;
    model.counties = [];

    return model;
  }

  public cleanCustomerAreaModel(model: CustomerAreaModel): CustomerAreaModel {
    Object.keys(model).forEach((index: string) => {
      if (typeof model[index] === 'string') {
        model[index] = model[index]
          && model[index].trim() !== '' ?
          model[index].trim()
          : null;
      }
    });

    return model;
  }

}
