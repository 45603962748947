import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { JobSubStatuses } from 'src/enums/job-sub-statuses';
import { JobTypes } from 'src/enums/job-types';
import { AppStateService } from 'src/services/app-state.service';
import { JobService } from 'src/services/job.service';
import { TimezoneService } from 'src/services/timezone.service';
import { UIService } from 'src/services/ui.service';
import { UserService } from 'src/services/user.service';
import { JobStatuses } from '../../../../enums/job-statuses';
import { JobModel } from '../../../../models/job.model';

@Component({
  selector: 'app-sub-status-button-hands',
  templateUrl: './sub-status-button-hands.component.html',
  styleUrls: ['./sub-status-button-hands.component.css']
})

export class SubStatusButtonHandsComponent implements OnInit, OnDestroy {
  @Input() job: JobModel;
  @Input() enableReorder: boolean = false;
  public JobStatuses = JobStatuses;
  public JobSubStatuses = JobSubStatuses;
  public JobTypes = JobTypes;
  public bsModalRef: BsModalRef;
  public cancellationId: number = null;
  public closeId: number = null;
  public openId: number = null;
  public editId: number = null;
  public isFishing: boolean = false;

  private isTabletSub: Subscription;
  public isTablet: boolean = false;

  private changeJobSubStatusSub: Subscription;

  constructor(
    public uiService: UIService,
    public userService: UserService,
    public jobService: JobService,
    public appStateService: AppStateService,
    public timeZoneService: TimezoneService,
  ) { };

  ngOnDestroy(): void {
    this.isTabletSub?.unsubscribe();
  }

  ngOnInit(): void {
    this.determineIsTablet();
    this.setCancellationId();
    this.setOpenId();
  }

  public determineIsTablet(): void {
    this.isTabletSub = this.appStateService.isTablet$
      .subscribe((isTablet: boolean) => {
        this.isTablet = isTablet;
      });
  }

  public setCancellationId(): void {
    this.cancellationId = this.jobService.setCancellationId(this.job.jobTypeId);
  }

  public setOpenId(): void {
    this.openId = this.jobService.setOpenId(this.job.jobTypeId);
  }

  public changeJobSubStatus(subStatusId: number): void {
    if (this.jobService.job.value !== null) {
      this.jobService.job.value.jobSubStatusId = subStatusId;

      this.jobService.addEditJob(
        this.jobService.job.value,
        false,
        true)
        .subscribe((x: boolean) => {
          // This is here because the jobSubStatusSub can not be unsubscribed from ngOnDestroy.
          this.changeJobSubStatusSub?.unsubscribe();

          if (x === true) {
            this.uiService.showSuccess('Job Status Changed', 'Success');
          } else {
            this.uiService.showError('Job Status Not Changed', 'Error');
          }
        });
    } else {
      this.uiService.showError('Job Status Not Changed', 'Error');
    }
  }

}
