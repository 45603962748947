import { AddressModel } from './address.model';
import { ContactModel } from './contact.model';

export class VendorModel {
  vendorId: number;
  name: string;
  isActive: boolean;
  paymentTermId: number;
  paymentTermName: string;

  vendorAddresses: AddressModel[];
  vendorContacts: ContactModel[];
  cityName: string;
  stateName: string;
  contactFullName: string;
  phone: string;
  email: string;
}
