<div class="modal-header"
  ngxModalDraggable>
  <h4 class="modal-title pull-left"
    *ngIf="model">{{isNew ? 'Add ' : 'Edit '}}Inventory Count</h4>
  <button type="button"
    class="close pull-right"
    aria-label="Close"
    (click)="bsModalRef.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body"
  *ngIf="model">
  <div class="form-group row"
    *ngIf="!isNew">
    <label for="title"
      class="col-sm-2 text-right control-label col-form-label">Date</label>
    <div class="col-sm-10 form-group mb-0">
      <input type="text"
        placeholder="mm/dd/yyyy"
        class="form-control"
        [(ngModel)]="model.date"
        required
        bsDatepicker>
    </div>
  </div>
  <div class="form-group row">
    <label class="col-sm-2 text-right control-label col-form-label">Employee</label>
    <div class="col-sm-10 form-group mb-0"
      *ngIf="employeeService.employeeList | async; let emp">
      <ng-select [items]="emp"
        bindLabel="fullName"
        bindValue="employeeId"
        [(ngModel)]="model.employeeId"
        name="emp"
        required>
      </ng-select>
    </div>
  </div>
  <div class="form-group row">
    <label for="title"
      class="col-sm-2 text-right control-label col-form-label">Status</label>
    <div class="col-sm-10 form-group mb-0">
      <ng-select [items]="statuses"
        bindLabel="name"
        bindValue="inventoryCountStatusId"
        [(ngModel)]="model.inventoryCountStatusId"
        name="statuses"
        required
        disabled>
      </ng-select>
    </div>
  </div>
  <div class="form-group row"
    *ngIf="!isNew">
    <label for="title"
      class="col-sm-2 text-right control-label col-form-label">Notes</label>
    <div class="col-sm-10 form-group mb-0">
      <textarea class="form-control form-control-sm"
        id="notes"
        [(ngModel)]="model.notes"
        name="notes"
        style="width: 100%;height: 80px;"
        [disabled]="false"></textarea>
    </div>
  </div>
</div>
<div class="modal-footer"
  *ngIf="model">
  <button type="button"
    class="btn btn-primary"
    (click)="onSave()"
    [disabled]="!(model.inventoryCountStatusId&&model.date&&model.employeeId)">Save</button>
  <button type="button"
    class="btn btn-default"
    (click)="bsModalRef.hide()">Cancel</button>
</div>
