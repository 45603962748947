import { Component, OnDestroy, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { JobStatusModel } from 'src/models/job-status.model';
import { JobTypeModel } from 'src/models/job-type.model';
import { JobStatusService } from 'src/services/job-status.service';
import { JobTypeService } from 'src/services/job-type.service';
import { UIService } from 'src/services/ui.service';

@Component({
  selector: 'app-add-edit-job-status-modal',
  templateUrl: './add-edit-job-status-modal.component.html',
  styleUrls: ['./add-edit-job-status-modal.component.css']
})

export class AddEditJobStatusModalComponent implements OnInit, OnDestroy {
  public isNew: boolean = false;
  public id: number = -1;
  public model: JobStatusModel;
  public showInactive: boolean = false;

  private jobTypesSub: Subscription;
  public jobTypes: JobTypeModel[];

  private modelSub: Subscription;
  private saveSub: Subscription;

  constructor(
    public bsModalRef: BsModalRef,
    public uiService: UIService,
    public jobStatusService: JobStatusService,
    public jobTypeService: JobTypeService
  ) { };

  ngOnDestroy(): void {
    this.jobTypesSub?.unsubscribe();

    this.modelSub?.unsubscribe();

    this.saveSub?.unsubscribe();
  }

  ngOnInit(): void {
    this.isNew = this.id === 0;

    this.getJobTypes();

    if (this.isNew) {
      this.model = this.jobStatusService.generateJobStatusModel();
    } else {
      this.getModel();
    }
  }

  public getModel(): void {
    this.modelSub = this.jobStatusService.getJobStatusById(this.id)
      .subscribe((x: JobStatusModel) => {
        this.model = x;
      });
  }

  public getJobTypes(): void {
    this.jobTypesSub = this.jobTypeService.jobTypeList$
      .subscribe((jobTypes: JobTypeModel[]) => {
        if (jobTypes) {
          this.jobTypes = jobTypes;
        }
      });
  }

  public onChangeJobType($event): void {
    if ($event) {
      this.model.statusOrder = this.jobStatusService.getLastOrderNumber($event.jobTypeId);
    }
  }

  public onSave(): void {
    this.saveSub = this.jobStatusService.addEditJobStatus(this.model, this.showInactive)
      .subscribe((x: boolean) => {
        if (x) {
          this.bsModalRef.hide();
          this.uiService.showSuccess('Job Status Saved', 'Success');
        } else {
          this.uiService.showError('Job Status Saved', 'Error');
        }
      });
  }

}
