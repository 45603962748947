import { Component, OnDestroy, OnInit } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { WorksheetRowItemTypes } from 'src/enums/worksheet-row-item-types';
import { WorksheetRowTypes } from 'src/enums/worksheet-row-types';
import { WorksheetCombinedModel } from 'src/models/worksheet-combined.model';
import { WorksheetRowCombinedModel } from 'src/models/worksheet-row-combined.model';
import { AppStateService } from 'src/services/app-state.service';
import { CustomerService } from 'src/services/customer.service';
import { EmployeeService } from 'src/services/employee.service';
import { UIService } from 'src/services/ui.service';
import { WorksheetSnapshotService } from 'src/services/worksheet-snapshot.service';
import { WorksheetTypeService } from 'src/services/worksheet-type.service';
import { WorksheetService } from 'src/services/worksheet.service';
import { ConfirmWorksheetIdModalComponent } from '../confirm-worksheet-id-modal/confirm-worksheet-id-modal.component';
import { WorksheetRowItemCombinedModel } from 'src/models/worksheet-row-item-combined.model';

@Component({
  selector: 'app-worksheet-modal',
  templateUrl: './worksheet-modal.component.html',
  styleUrls: ['worksheet-modal.component.css']
})

export class WorksheetModalComponent implements OnInit, OnDestroy {
  public id: number = 0;
  public isNew: boolean = false;
  public worksheetTypeId: number = 0;
  public jobId: number | null = null;
  public jobStatusIsTerminal: boolean = false;
  public showOnJob: boolean = false;
  public model: WorksheetCombinedModel;
  public WorksheetRowTypes = WorksheetRowTypes;
  public WorksheetRowItemTypes = WorksheetRowItemTypes;
  public disableSave: boolean = false;
  public customerId = null;

  private isTabletSub: Subscription;
  public isTablet: boolean = false;

  private modelSub: Subscription;
  private newWorksheetSub: Subscription;
  private saveSub: Subscription;

  constructor(
    public bsModalRef: BsModalRef,
    public bsModalRefChildModal: BsModalRef,
    public bsModalRefConfirmIdModal: BsModalRef,
    public snapshotService: WorksheetSnapshotService,
    public worksheetService: WorksheetService,
    public worksheetTypeService: WorksheetTypeService,
    public uiService: UIService,
    public appStateService: AppStateService,
    public employeeService: EmployeeService,
    public customerService: CustomerService,
    private modalService: BsModalService
  ) { }

  ngOnDestroy(): void {
    this.isTabletSub?.unsubscribe();

    this.modelSub?.unsubscribe();

    this.newWorksheetSub?.unsubscribe();

    this.saveSub?.unsubscribe();
  }

  ngOnInit(): void {
    this.determineIsTablet();

    this.employeeService.getEmployees(false);
    this.customerService.getCustomers(false);

    this.isNew = this.id === 0;
    if (this.isNew) {
      this.generateNewWorksheet();
    } else {
      this.getModel();
    }
  }

  public determineIsTablet(): void {
    this.isTabletSub = this.appStateService.isTablet$
      .subscribe((isTablet: boolean) => {
        this.isTablet = isTablet;
      });
  }

  public generateNewWorksheet(): void {
    this.newWorksheetSub = this.worksheetService.generateNewWorksheetCombined(
      this.worksheetTypeId,
      this.jobId,
      this.customerId)
      .subscribe((x: WorksheetCombinedModel) => {
        this.model = x;
        this.checkForToggle(this.model);
      });
  }

  public getModel(): void {
    this.modelSub = this.worksheetService.getWorksheetCombinedById(this.id)
      .subscribe((x: WorksheetCombinedModel) => {
        x.worksheetRowsCombined.forEach((row: WorksheetRowCombinedModel) => {
          let selected = row.worksheetRowItemsCombined.filter((i: WorksheetRowItemCombinedModel) => {
            return i.isSelected === true;
          });
          if (selected.length > 0) {
            row.isNote = selected[0].isNote;
          }
        });
        this.model = x;
        this.checkForToggle(this.model);
      });
  }

  public handleIncrement(row: WorksheetRowCombinedModel): void {
    if (row
      && row.quantity !== null
      && row.quantity !== undefined) {
      row.quantity = row.quantity + 1;
    }
  }

  public handleDecrement(row: WorksheetRowCombinedModel): void {
    if (row
      && row.quantity !== null
      && row.quantity !== undefined) {
      row.quantity = row.quantity - 1;
    }
  }

  public changeSelection(
    $event: number,
    worksheetRowTypeId: number): void {
    let row = this.model.worksheetRowsCombined.filter((x: WorksheetRowCombinedModel) => {
      return x.worksheetRowTypeId == worksheetRowTypeId;
    });

    if (row !== null && row.length > 0) {

      if (row[0].worksheetRowTypeId === this.WorksheetRowTypes.NewMhOrMbSelect) {
        this.toggleOption($event);
      }

      if ($event !== undefined) {
        row[0].worksheetRowItemsCombined.forEach((item: WorksheetRowItemCombinedModel) => {
          item.isSelected = item.worksheetRowItemTypeId == $event;
        });
      } else {
        row[0].worksheetRowItemsCombined.forEach((item: WorksheetRowItemCombinedModel) => {
          item.isSelected = false;
        });
      }
    }
  }

  public onSave(): void {
    this.disableSave = true;

    this.uiService.showToastSaving();

    if (this.isNew) {
      this.saveSub = this.worksheetService.addWorksheetCombined(
        this.model,
        false,
        this.showOnJob)
        .subscribe((x: number) => {
          if (x !== -1) {
            this.uiService.killAllToastMessages();
            this.uiService.showSuccess('Worksheet Saved', 'Success');
            this.openConfirmIdModal(x);
            this.bsModalRefChildModal.hide();
          } else {
            this.disableSave = false;
            this.uiService.killAllToastMessages();
            this.uiService.showError('Worksheet Not Saved', 'Error');
          }
        });
    } else {
      this.saveSub = this.worksheetService.editWorksheetCombined(
        this.model,
        false,
        this.showOnJob)
        .subscribe((x: boolean) => {
          if (x) {
            this.uiService.killAllToastMessages();
            this.uiService.showSuccess('Worksheet Saved', 'Success');
            this.bsModalRefChildModal.hide();
          } else {
            this.disableSave = false;
            this.uiService.killAllToastMessages();
            this.uiService.showError('Worksheet Not Saved', 'Error');
          }
        });
    }
  }

  public openConfirmIdModal(worksheetId: number): void {
    const initialState = {
      worksheetId
    };

    this.bsModalRefConfirmIdModal = this.modalService.show(ConfirmWorksheetIdModalComponent, { initialState, backdrop: 'static' });
  }

  public checkForToggle(ws: WorksheetCombinedModel): void {
    let mhOrMbRow = ws.worksheetRowsCombined.filter((row: WorksheetRowCombinedModel) => row.worksheetRowTypeId === this.WorksheetRowTypes.NewMhOrMbSelect);

    if (mhOrMbRow.length > 0) {
      let selected = mhOrMbRow[0].worksheetRowItemsCombined.filter((i: WorksheetRowItemCombinedModel) => {
        return i.isSelected === true;
      });
      if (selected.length > 0) {
        this.toggleOption(selected[0].worksheetRowItemTypeId);
      }
    }
  }

  public toggleOption(id: number): void {
    // Hard coded to Upper Spiral.  I know, I know. -dc 4/12/2022
    let usRow = this.model.worksheetRowsCombined.filter((x: WorksheetRowCombinedModel) => {
      return x.worksheetRowTypeId == this.WorksheetRowTypes.UpperSpiral;
    });

    if (usRow !== null && usRow.length > 0) {
      // MH Desander
      if (id === this.WorksheetRowItemTypes.NewMhDesander) {
        usRow[0].quantity = 0;
        usRow[0].hide = true;
      } else {
        // MB Desander
        usRow[0].quantity = 1;
        usRow[0].hide = false;
      }
    }

    let bodyRow = this.model.worksheetRowsCombined.filter((x: WorksheetRowCombinedModel) => {
      return x.worksheetRowTypeId == this.WorksheetRowTypes.SlottedBody;
    }); // Hard coded to Slotted Body.  Sigh. -dc 4/12/2022

    if (bodyRow !== null && bodyRow.length > 0) {
      // MH Desander
      if (id === this.WorksheetRowItemTypes.NewMhDesander) {
        this.changeSelection(this.WorksheetRowItemTypes.FourAndAHalfMhBody, this.WorksheetRowTypes.SlottedBody);
      } else {
        // MB Desander
        this.changeSelection(this.WorksheetRowItemTypes.FourAndAHalfMbBody, this.WorksheetRowTypes.SlottedBody);
      }
      bodyRow[0].worksheetRowItemsCombined = [...bodyRow[0].worksheetRowItemsCombined]; // Forces change detection
    }
  }

}
