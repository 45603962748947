<div class="page-header">
  <div class="page-header-content header-elements-md-inline">
    <div class="page-title d-flex">
      <a [routerLink]="['/home']"
        class="breadcrumb-link-color">
        <h4><i class="icon-arrow-left52 mr-2"></i> <span class="font-weight-semibold">Reports</span></h4>
      </a>
      <a href="#"
        class="header-elements-toggle text-default d-md-none"><i class="icon-more"></i></a>
    </div>
    <div class="header-elements d-none py-0 mb-3 mb-md-0">
      <div class="breadcrumb">
        <a href="index.html"
          class="breadcrumb-item"><i class="icon-home2 mr-2"></i> Reports</a>
        <span class="breadcrumb-item active">List</span>
      </div>
    </div>
  </div>
</div>
<div class="page-content pt-0 pl-3 pr-3">
  <div class="content-wrapper">
    <div class="content">
      <div class="row flex-row"
        *ngIf="generalReports">
        <div class="col-lg-3 col-md-6 pl-1 pr-1"
          *ngFor="let r of generalReports;">
          <div class="card border-top-primary border-top-2 rounded-top-0 fixed-report-card-height"
            style="border-top-color: #2196F3 !important;">
            <div class="panel flex-item">
              <div class="panel-body"
                style="text-align: center;">
                <h1 class="mb-0 text-muted"
                  style="margin-top: 10px;"><i class="{{r.icon}}"></i></h1>
                <h3 class="mb-0">{{r.name}}</h3>
                <p>{{r.description}}</p>
              </div>
              <div class="mb-2 pl-1 pr-1">
                <button class="btn btn-primary btn-sm btn-block button-bottom"
                  (click)="openReportModal(r.reportId)"
                  *ngIf="r.reportParams&&(r.reportParams.hasStartDate||r.reportParams.hasEndDate || r.reportParams.hasDateDropDown||r.reportParams.hasJobId)"><i
                    class="fa fa-file-excel"></i>&nbsp;&nbsp;Generate Report</button>
                <button class="btn btn-primary btn-sm btn-block button-bottom"
                  (click)="generateNoParamReport(r)"
                  *ngIf="r.reportParams&&(!r.reportParams.hasStartDate&&!r.reportParams.hasEndDate&&!r.reportParams.hasDateDropDown&&!r.reportParams.hasJobId)"><i
                    class="fa fa-file-excel"></i>&nbsp;&nbsp;Generate Report</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="!generalReports || generalReports.length === 0"
        class="row flex-row">
        <div class="col-lg-3 col-md-6 pl-1 pr-1"
          *ngFor="let r of numberOfSkeletons;">
          <div class="card border-top-primary border-top-2 rounded-top-0 fixed-report-card-height"
            style="border-top-color: #2196F3 !important;">
            <div class="panel flex-item">
              <div class="panel-body"
                style="text-align: center;">
                <app-list-skeleton [numberToIterate]="2"></app-list-skeleton>
              </div>
              <div class="mb-2 pl-1 pr-1">
                <button class="btn btn-primary btn-sm btn-block"
                  style="width:100%;"><i class="fa fa-file-excel"
                    [disabled]="true"></i>&nbsp;&nbsp;Generate Report</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--END ROW-->
      <ng-container
        *ngIf="userService.user.value && userService.user.value.activeRoles && userService.user.value.activeRoles.includes('Finance')">
        <hr />
        <h6>Finance Reports</h6>
        <div *ngIf="financeReports && financeReports.length > 0"
          class="row flex-row">
          <div class="col-lg-3 col-md-6 pl-1 pr-1"
            *ngFor="let r of financeReports;">
            <div class="card border-top-primary border-top-2 rounded-top-0 fixed-report-card-height"
              style="border-top-color: #2196F3 !important;">
              <div class="panel flex-item">
                <div class="panel-body"
                  style="text-align: center;">
                  <h1 class="mb-0 text-muted"
                    style="margin-top: 10px;"><i class="{{r.icon}}"></i></h1>
                  <h3 class="mb-0">{{r.name}}</h3>
                  <p>{{r.description}}</p>
                </div>
                <div class="mb-2 pl-1 pr-1">
                  <button class="btn btn-primary btn-sm btn-block button-bottom"
                    (click)="openReportModal(r.reportId)"
                    *ngIf="r.reportParams&&(r.reportParams.hasStartDate||r.reportParams.hasEndDate || r.reportParams.hasDateDropDown||r.reportParams.hasJobId)"><i
                      class="fa fa-file-excel"></i>&nbsp;&nbsp;Generate Report</button>
                  <button class="btn btn-primary btn-sm btn-block button-bottom"
                    (click)="generateNoParamReport(r)"
                    *ngIf="r.reportParams&&(!r.reportParams.hasStartDate&&!r.reportParams.hasEndDate&&!r.reportParams.hasDateDropDown&&!r.reportParams.hasJobId)"><i
                      class="fa fa-file-excel"></i>&nbsp;&nbsp;Generate Report</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>
