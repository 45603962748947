<div class="modal-header"
  ngxModalDraggable>
  <h4 class="modal-title pull-left"
    *ngIf="model">Confirm Cancellation of {{model.jobTypeName}} Invoice #{{model.jobNumber}}</h4>
  <button type="button"
    class="close pull-right"
    aria-label="Close"
    (click)="bsModalRef.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body"
  *ngIf="model">
  <div class="form-group row">
    <label for="title"
      class="col-sm-3 text-right control-label col-form-label">Cancellation Reason</label>
    <div class="col-sm-9 form-group mb-0"
      s>
      <div class="input-group">
        <ng-select [items]="reasons"
          bindLabel="reason"
          bindValue="cancellationReasonId"
          [ngModel]="model.cancellationReasonId"
          (change)="onChangeOfReason($event)"
          [clearable]="false"
          name="reasons"
          style="width: 100%"
          required>
        </ng-select>
      </div>
    </div>
  </div>
  <div class="form-group row"
    *ngIf="model && showOtherBox">
    <label for="title"
      class="col-sm-3 text-right control-label col-form-label">Other Reason</label>
    <div class="col-sm-9 form-group mb-0">
      <textarea type="text"
        class="form-control form-control-sm"
        id="otherReason"
        [(ngModel)]="model.otherReason"
        name="otherReason"
        required></textarea>
      <p class="text-danger mb-0 mt-2"
        *ngIf="showError && model && !model.otherReason">
        <i class="fas fa-exclamation-triangle"></i>&nbsp;&nbsp;Please enter a Cancellation Reason
      </p>
    </div>
  </div>
  <dl class="row">
    <dt class="col-sm-3 text-right"
      *ngIf="model.customerName">Customer</dt>
    <dd class="col-sm-9"
      *ngIf="model.customerName">{{model.customerName}}</dd>
    <dt class="col-sm-3 text-right"
      *ngIf="model.wellId !== null">Well / RIG</dt>
    <dd class="col-sm-9"
      *ngIf="model.wellId !== null">{{model.well_Rig}}</dd>
    <dt class="col-sm-3 text-right"
      *ngIf="model.jobOwnerName">Job Owner</dt>
    <dd class="col-sm-9"
      *ngIf="model.jobOwnerName">{{model.jobOwnerName}}</dd>
    <dt class="col-sm-3 text-right"
      *ngIf="model.workDescription">Work Description</dt>
    <dd class="col-sm-9"
      *ngIf="model.workDescription">{{model.workDescription}}</dd>
  </dl>
  <div class="modal-footer"
    *ngIf="model">
    <button type="button"
      class="btn btn-danger"
      (click)="onSave();"
      [disabled]="deactivateSave || !(model && ((showOtherBox && model.cancellationReasonId && model.otherReason) || (!showOtherBox && model.cancellationReasonId && !model.otherReason)))">Confirm</button>
    <button type="button"
      class="btn btn-default"
      (click)="onCancel();">Cancel</button>
  </div>
