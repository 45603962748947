<div class="modal-header"
  ngxModalDraggable>
  <h4 class="modal-title pull-left"
    *ngIf="model">{{isNew ? 'Add' : 'Edit'}}&nbsp;Customer Area</h4>
  <button type="button"
    class="close pull-right"
    aria-label="Close"
    (click)="bsModalRef.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body"
  *ngIf="model">
  <div class="form-group row">
    <label for="title"
      class="col-sm-3 text-right control-label col-form-label">Name</label>
    <div class="col-sm-9 form-group mb-0">
      <input type="text"
        class="form-control form-control-sm"
        id="name"
        [(ngModel)]="model.name"
        name="name"
        required>
    </div>
  </div>
  <div class="form-group row">
    <label for="title"
      class="col-sm-3 text-right control-label col-form-label">Counties</label>
    <div class="col-sm-9 form-group mb-0">
      <ng-select [items]="counties"
        [multiple]="true"
        [closeOnSelect]="false"
        [searchable]="false"
        bindLabel="name"
        placeholder="Select Counties"
        [(ngModel)]="model.counties"
        name="counties">
      </ng-select>
    </div>
  </div>
  <div class="form-group row">
    <label for="title"
      class="col-sm-3 text-right control-label col-form-label">Contact</label>
    <div class="col-sm-9 form-group mb-0"
      *ngIf="contactService.contactList | async; let con">
      <div class="input-group">
        <ng-select [items]="con"
          bindLabel="fullName"
          bindValue="contactId"
          [(ngModel)]="model.contactId"
          class="col-md-9"
          style="padding:0px;"
          name="contacts">
        </ng-select>
        <div class="input-group-append col-md-3"
          style="padding: 0;width:100%">
          <span class="input-group-text"
            style="padding: 0px;"><button (click)="openContactModal(0)"
              style="display: inline-flex;padding: 4px 10px;width:100%"
              class="btn btn-sm btn-default"
              [disabled]="disableTopFields"><i class="fa fa-plus"
                style="position: relative; top: 3px; margin-right: 5px;"></i>&nbsp;New</button></span>
        </div>
      </div>
    </div>
  </div>
  <div class="form-group row">
    <label for="title"
      class="col-sm-3 text-right control-label col-form-label">Notes</label>
    <div class="col-sm-9 form-group mb-0">
      <textarea class="form-control"
        [(ngModel)]="model.notes"></textarea>
    </div>
  </div>
</div>
<div class="modal-footer"
  *ngIf="model">
  <button type="button"
    class="btn btn-primary"
    (click)="onSave()"
    [disabled]="!(model.name) 
      || disableSave">Save</button>
  <button type="button"
    class="btn btn-default"
    (click)="bsModalRef.hide()">Cancel</button>
</div>
