<div class="modal-header"
  ngxModalDraggable>
  <h4 class="modal-title pull-left">Link Purchase Orders</h4>
  <button type="button"
    class="close pull-right"
    aria-label="Close"
    (click)="bsModalRef.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div class="form-group row">
    <label for="title"
      class="col-sm-2 text-right control-label col-form-label">POs</label>
    <div class="col-sm-10 form-group mb-0">
      <ng-select [items]="poList"
        [multiple]="true"
        [closeOnSelect]="false"
        [searchable]="false"
        placeholder="Select PO's"
        bindLabel="dropDownDisplay"
        [(ngModel)]="selectedPos"
        name="selectedPos">
      </ng-select>
    </div>
  </div>
</div>
<div class="modal-footer">
  <button type="button"
    class="btn btn-primary"
    (click)="onSave()"
    [disabled]="disableSave">Save</button>
  <button type="button"
    class="btn btn-default"
    (click)="bsModalRef.hide()">Cancel</button>
</div>
