import { Component, OnDestroy, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { JobSubStatusModel } from 'src/models/job-sub-status.model';
import { ShopModel } from 'src/models/shop.model';
import { UserModel } from 'src/models/user.model';
import { JobSubStatusService } from 'src/services/job-sub-status.service';
import { UIService } from 'src/services/ui.service';
import { UserService } from 'src/services/user.service';
import { ShopService } from '../../../services/shop.service';

@Component({
  selector: 'app-add-edit-user-modal',
  templateUrl: './add-edit-user-modal.component.html',
  styleUrls: ['add-edit-user-modal.component.css']
})

export class AddEditUserModalComponent implements OnInit, OnDestroy {
  private shopsSub: Subscription;
  public shops: ShopModel[];

  private jobSubStatusSub: Subscription;
  public jobSubStatuses: JobSubStatusModel[];

  private modelSub: Subscription;
  private saveSub: Subscription;

  public isNew: boolean = false;
  public id: number = -1;
  public changePassword: boolean = false;
  public showChangePassSwitch: boolean = false;
  public confirmPassword: string = '';
  public passwordIsValid: boolean = false;
  public disableSave: boolean = false;
  public model: UserModel;

  constructor(
    public bsModalRef: BsModalRef,
    public userService: UserService,
    public shopService: ShopService,
    public uiService: UIService,
    public subStatusService: JobSubStatusService
  ) { };

  ngOnDestroy(): void {
    this.shopsSub?.unsubscribe();

    this.jobSubStatusSub?.unsubscribe();

    this.modelSub?.unsubscribe();

    this.saveSub?.unsubscribe();
  }

  ngOnInit(): void {
    this.userService.getRolesForDropdown();

    this.getShops();

    this.getJobSubStatuses();

    this.isNew = this.id === 0;

    if (this.isNew) {
      this.model = this.userService.generateNewUserModel();
      this.changePassword = true;
    } else {
      this.getModel();
      this.showChangePassSwitch = true;
    }
  }

  public getModel(): void {
    this.modelSub = this.userService.getUserInfoById(this.id)
      .subscribe((x: UserModel) => {
        this.model = x;
        this.model.password = '';
      });
  }

  public getShops(): void {
    this.shopsSub = this.shopService.shopList$
      .subscribe((shops: ShopModel[]) => {
        if (shops) {
          this.shops = shops;
        }
      });
  }

  public getJobSubStatuses(): void {
    this.jobSubStatusSub = this.subStatusService.jobSubStatusList$
      .subscribe((jobSubStatuses: JobSubStatusModel[]) => {
        if (jobSubStatuses) {
          this.jobSubStatuses = jobSubStatuses;
        }
      });
  }

  public onChangeOfPasswordSwitch($event: boolean): void {
    if ($event !== undefined) {
      this.changePassword = $event;
      this.confirmPassword = '';
      this.model.password = '';
    }
  }

  public onSave(): void {
    this.disableSave = true;

    if (!this.model.activeRoles.includes('Approve Invoices')) {
      this.model.invoiceApprovalLimit = null;
    }

    this.saveSub = this.userService.addEditUser(this.model)
      .subscribe((x: boolean) => {
        if (x) {
          this.bsModalRef.hide();
          this.uiService.showSuccess('User Saved', 'Success');
        } else {
          this.disableSave = false;
          this.uiService.showError('User Not Saved', 'Error');
        }
      });
  }

}
