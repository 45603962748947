export class ContactModel {
  contactId: number;
  firstName: string;
  lastName: string;
  fullName: string;
  phone: string;
  email: string;
  notes: string;
  isActive: boolean;

  customerId: number;
  vendorId: number;
}
