<div class="page-header">
  <div class="page-header-content header-elements-md-inline">
    <div class="page-title d-flex">
      <a [routerLink]="['/items/list']"
        class="breadcrumb-link-color">
        <h4><i class="icon-arrow-left52 mr-2"></i> <span class="font-weight-semibold">Item</span> - Details</h4>
      </a>
      <button type="button"
        class="btn btn-outline-dark btn-sm ml-3 pl-1 pr-1 pt-0 pb-0 no-border"
        [title]="!showDetails ? 'Show Details' : 'Hide Details'"
        (click)="showDetails = !showDetails">
        <span *ngIf="!showDetails">
          <i class="fa-regular fa-chevrons-right"></i>&nbsp;Show </span>
        <span *ngIf="showDetails"><i class="fa-regular fa-chevrons-left"></i>&nbsp;Hide
        </span>Details</button>
    </div>
    <div class="header-elements d-none py-0 mb-3 mb-md-0">
      <div class="breadcrumb">
        <a href="index.html"
          class="breadcrumb-item"><i class="icon-home2 mr-2"></i> Item</a>
        <span class="breadcrumb-item active">Detail</span>
      </div>
    </div>
  </div>
</div>
<div class="page-content pt-0"
  *ngIf="itemService.item | async; let i">
  <div class="content-wrapper">
    <div class="content">
      <div class="row">
        <div class="col-lg-3"
          *ngIf="showDetails">
          <div class="card mb-0">
            <div class="card-header bg-light header-elements-inline"
              [ngClass]="{'pr-2 pl-2':isTablet}">
              <h6 class="card-title">Item: {{i.partNumber}}</h6>
              <div class="header-elements">
                <label class="btn btn-light btn-sm mb-0 mr-1"
                  style="cursor: pointer;">
                  <i class="fa fa-camera"></i><input type="file"
                    hidden
                    accept="image/x-png,image/gif,image/jpeg"
                    (change)="uploadFile($event)">
                </label>
                <button class="btn btn-light btn-sm"
                  (click)="openItemModal(i.itemId)"><i class="fa fa-edit"></i><span *ngIf="!isTablet">
                    Edit</span></button>
              </div>
            </div>
            <div id="collapseBasic"
              [collapse]="isCollapsed">
              <div class="card-body"
                [ngClass]="{'pr-2 pl-2':isTablet}">
                <dl class="row">
                  <dt class="col-sm-4 text-right"
                    [ngClass]="{'pr-0':isTablet}">Part #</dt>
                  <dd class="col-sm-8">{{i.partNumber}}</dd>
                  <dt class="col-sm-4 text-right"
                    [ngClass]="{'pr-0':isTablet}">Description</dt>
                  <dd class="col-sm-8">{{i.description}}</dd>
                  <dt class="col-sm-4 text-right"
                    [ngClass]="{'pr-0':isTablet}"
                    *ngIf="i.vendorName">Primary Vendor</dt>
                  <dd class="col-sm-8"
                    *ngIf="i.vendorName">{{i.vendorName}}</dd>
                  <dt class="col-sm-4 text-right"
                    [ngClass]="{'pr-0':isTablet}">Cost</dt>
                  <dd class="col-sm-8">{{i.cost | currency}}</dd>
                  <dt class="col-sm-4 text-right"
                    [ngClass]="{'pr-0':isTablet}">UOM</dt>
                  <dd class="col-sm-8">{{i.uomName}}</dd>
                  <dt class="col-sm-4 text-right"
                    [ngClass]="{'pr-0':isTablet}">Part Family</dt>
                  <dd class="col-sm-8">{{i.partFamilyName}}</dd>
                  <dt class="col-sm-4 text-right"
                    [ngClass]="{'pr-0':isTablet}">Part Group</dt>
                  <dd class="col-sm-8">{{i.partGroupName}}</dd>
                  <dt class="col-sm-4 text-right"
                    [ngClass]="{'pr-0':isTablet}">Item Type</dt>
                  <dd class="col-sm-8">
                    <span *ngIf="i.itemTypeId !== ItemTypes.ThirdParty">{{i.itemTypeName}}</span>
                    <span *ngIf="i.itemTypeId === ItemTypes.ThirdParty"
                      class="badge badge-primary">{{i.itemTypeName}}</span>
                  </dd>
                  <dt class="col-sm-4 text-right"
                    [ngClass]="{'pr-0':isTablet}"
                    *ngIf="i.itemTypeId === ItemTypes.Build">Worksheet</dt>
                  <dd class="col-sm-8"
                    *ngIf="i.itemTypeId === ItemTypes.Build"><a
                      [routerLink]="['/worksheet-types/details', i.worksheetTypeId]"
                      target="_blank">{{i.worksheetTypeName}}</a></dd>
                  <dt class="col-sm-4 text-right"
                    [ngClass]="{'pr-0':isTablet}"
                    *ngIf="i.itemTypeId === ItemTypes.Rental && i.minNumDays">Minimum Days</dt>
                  <dd class="col-sm-8"
                    *ngIf="i.itemTypeId === ItemTypes.Rental && i.minNumDays">{{i.minNumDays}}</dd>
                  <dt class="col-sm-4 text-right"
                    [ngClass]="{'pr-0':isTablet}"
                    *ngIf="i.itemTypeId === ItemTypes.Rental">Number of Connections</dt>
                  <dd class="col-sm-8"
                    *ngIf="i.itemTypeId === ItemTypes.Rental">{{i.numberOfConnections}}</dd>
                  <dt class="col-sm-4 text-right"
                    [ngClass]="{'pr-0':isTablet}"
                    *ngIf="i.itemTypeId === ItemTypes.Rental">Body Inspection</dt>
                  <dd class="col-sm-8"
                    *ngIf="i.itemTypeId === ItemTypes.Rental">{{i.bodyInspection ? 'Yes' : 'No'}}</dd>
                  <dt class="col-sm-4 text-right"
                    [ngClass]="{'pr-0':isTablet}"
                    *ngIf="i.itemTypeId === ItemTypes.Rental">Rod Inspection</dt>
                  <dd class="col-sm-8"
                    *ngIf="i.itemTypeId === ItemTypes.Rental">{{i.rodInspection ? 'Yes' : 'No'}}</dd>
                  <dt class="col-sm-4 text-right"
                    [ngClass]="{'pr-0':isTablet}"
                    *ngIf="i.itemTypeId === ItemTypes.Rental">Charge For Runs After Scrap</dt>
                  <dd class="col-sm-8"
                    *ngIf="i.itemTypeId === ItemTypes.Rental">{{i.chargeForRunsAfterScrap ? 'Yes' : 'No'}}</dd>
                  <dt class="col-sm-4 text-right"
                    [ngClass]="{'pr-0':isTablet}"
                    *ngIf="i.itemTypeId === ItemTypes.Sale">On Hand Inventory</dt>
                  <dd class="col-sm-8"
                    *ngIf="i.itemTypeId === ItemTypes.Sale">
                    <ng-template #popoverTemplateInv>
                      <div class="pl-2 pr-2">
                        <div *ngFor="let si of i.shopItems;let ind = index"
                          class="row">
                          <div class="col-xs-9">
                            {{si.name}}:
                          </div>
                          <div class="col-xs-3">
                            <span class="float-right">&nbsp;<strong>{{si.inventoryCount}}</strong></span>
                          </div>
                        </div>
                      </div>
                    </ng-template>
                    {{i.totalInventoryCount}}&nbsp;
                    <span class="text-primary pointer">
                      <i class="fas fa-info-circle"
                        type="button"
                        [popover]="popoverTemplateInv"
                        popoverTitle="Inventory"
                        placement="auto"
                        [outsideClick]="true"
                        container="body"></i>
                    </span>
                  </dd>
                  <dt class="col-sm-4 text-right"
                    [ngClass]="{'pr-0':isTablet}"
                    *ngIf="i.itemTypeId === ItemTypes.Sale">Reserve Inventory</dt>
                  <dd class="col-sm-8"
                    *ngIf="i.itemTypeId === ItemTypes.Sale">
                    <ng-template #popoverTemplateRes>
                      <div class="pl-2 pr-2">
                        <div *ngFor="let si of i.shopItems;let ind = index"
                          class="row">
                          <div class="col-xs-9">
                            {{si.name}}:
                          </div>
                          <div class="col-xs-3">
                            <span class="float-right">&nbsp;<strong>{{si.reserveCount}}</strong></span>
                          </div>
                        </div>
                      </div>
                    </ng-template>
                    {{i.totalReserveCount}}&nbsp;
                    <span class="text-primary pointer"
                      [title]="i.reservePopover">
                      <i class="fas fa-info-circle"
                        type="button"
                        [popover]="popoverTemplateRes"
                        popoverTitle="Reserve"
                        placement="auto"
                        [outsideClick]="true"
                        container="body"></i>
                    </span>
                  </dd>
                  <dt class="col-sm-4 text-right"
                    [ngClass]="{'pr-0':isTablet}"
                    *ngIf="i.itemTypeId === ItemTypes.Sale">Open PO Inventory</dt>
                  <dd class="col-sm-8"
                    *ngIf="i.itemTypeId === ItemTypes.Sale">{{i.inventoryInOpenPos}}</dd>
                  <dt class="col-sm-4 text-right"
                    [ngClass]="{'pr-0':isTablet}"
                    *ngIf="i.itemTypeId === ItemTypes.Sale">Is Active</dt>
                  <dd class="col-sm-8"
                    *ngIf="i.itemTypeId === ItemTypes.Sale">{{i.isActive ? 'Yes' : 'No'}}</dd>
                  <dt class="col-sm-4 text-right"
                    [ngClass]="{'pr-0':isTablet}"
                    *ngIf="i.itemTypeId === ItemTypes.Sale">Return To Inv. After Fishing</dt>
                  <dd class="col-sm-8"
                    *ngIf="i.itemTypeId === ItemTypes.Sale">
                    <span *ngIf="!i.returnToInvAfterFishing">No</span>
                    <span class="badge badge-primary"
                      *ngIf="i.returnToInvAfterFishing">Yes</span>
                  </dd>
                </dl>
              </div>
            </div>
          </div>
          <app-vendor-item-sm-list *ngIf="i.itemTypeId !== ItemTypes.Build"></app-vendor-item-sm-list>
          <div class="card rounded-top-0"
            *ngIf="i.hasImage">
            <div class="card-header bg-light header-elements-sm-inline pb-1 pt-1 pl-2">
              <h6 class="card-title">Image</h6>
              <div class="header-elements">
                <button class="btn btn-danger pl-1 pr-1 pt-0 pb-0"
                  [swal]="{ title: 'Remove Image?', showCancelButton: true, confirmButtonText: 'Remove', confirmButtonColor: '#DC3545' }"
                  (confirm)="removeBhaImage()">
                  <i class="far fa-times-circle"></i>
                </button>
              </div>
            </div>
            <div class="card-body p-0 text-center">
              <img [src]="timeStampImageLink()"
                id="bha_diabram"
                class="pt-1 pb-1">
            </div>
          </div>
        </div>
        <div [ngClass]="{'col-lg-9': showDetails, 'col-lg-12': !showDetails}">
          <tabset class="compact-nav">
            <tab heading="Inventory History"
              *ngIf="i.itemTypeId === ItemTypes.Sale"
              id="IH"
              (selectTab)="tabSelect($event)"
              [active]="'IH' === activeTabId">
              <button class="btn btn-primary btn-sm float-right mr-3"
                *ngIf="noHistory && !isLoadingHistory"
                (click)="openInventoryAdjustModal()"><i class="fa fa-plus"></i> Add Inventory</button>
              <app-inventory-history-sm-list *ngIf="!isLoadingHistory"></app-inventory-history-sm-list>
              <app-table-skeleton *ngIf="isLoadingHistory"
                [numberToIterate]="10"></app-table-skeleton>
            </tab>
            <tab heading="Serialized"
              *ngIf="i.itemTypeId === ItemTypes.Rental"
              id="SER"
              (selectTab)="tabSelect($event)"
              [active]="'SER' === activeTabId">
              <app-item-instance-sm-list [itemId]="i.itemId"
                [partGroupId]="i.partGroupId"></app-item-instance-sm-list>
            </tab>
            <tab heading="Builds"
              *ngIf="i.itemTypeId === ItemTypes.Build"
              id="BUI"
              (selectTab)="tabSelect($event)"
              [active]="'BUI' === activeTabId">
              <app-packer-table [showExtendedControls]="false"></app-packer-table>
            </tab>
            <tab heading="Billing Codes"
              *ngIf="i.itemTypeId === ItemTypes.Sale || i.itemTypeId === ItemTypes.Rental || i.itemTypeId === ItemTypes.ThirdParty"
              id="BC"
              (selectTab)="tabSelect($event)"
              [active]="'BC' === activeTabId">
              <app-billing-code-sm-list [showAdditionalDayColumn]="showAdditionalDayColumn"></app-billing-code-sm-list>
            </tab>
          </tabset>
        </div>
      </div>
    </div>
  </div>
</div>
<div *ngIf="!itemService.item.value"
  class="page-content pt-0">
  <div class="content-wrapper">
    <div class="content">
      <div class="row">
        <div class="col-lg-3">
          <div class="card">
            <div class="card-header bg-light header-elements-inline">
              <h6 class="card-title">Item:</h6>
              <div class="header-elements">
                <button class="btn btn-light btn-sm"
                  [disabled]="true"><i class="fa fa-edit"></i> Edit</button>
              </div>
            </div>
            <div class="card-body">
              <app-list-skeleton [numberToIterate]="10"></app-list-skeleton>
            </div>
          </div>
        </div>
        <div class="col-lg-9">
          <tabset class="compact-nav">
            <tab heading="-----------------"
              [disabled]="true">
              <app-table-skeleton [numberToIterate]="10"></app-table-skeleton>
            </tab>
            <tab heading="-----------------"
              [disabled]="true">
            </tab>
          </tabset>
        </div>
      </div>
    </div>
  </div>
</div>
