import { Component, OnDestroy, OnInit } from '@angular/core';
import { RowClassArgs } from '@progress/kendo-angular-grid';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { KGridService } from 'src/services/k-grid.service';
import { UIService } from 'src/services/ui.service';
import { UserService } from 'src/services/user.service';
import { AddEditUserModalComponent } from '../add-edit-user-modal/add-edit-user-modal.component';
import { RoleMapModalComponent } from '../role-map-modal/role-map-modal.component';

@Component({
  selector: 'app-users-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.css']
})

export class UserListComponent implements OnInit, OnDestroy {

  public showInactive: boolean = false;

  constructor(
    private modalService: BsModalService,
    public kGridService: KGridService,
    public uiService: UIService,
    public userService: UserService,
  ) { };

  public bsModalRef: BsModalRef;
  public isAuthorized: boolean = false;

  private activeSub: Subscription;

  ngOnDestroy(): void {
    this.kGridService.resetKGridParams();
    this.userService.clearFilterParams();

    this.activeSub?.unsubscribe();
  }

  ngOnInit(): void {
    this.userService.getUsers(
      true,
      true);
  }

  public openUserModal(userId: number): void {
    const initialState = {
      id: userId
    };

    this.bsModalRef = this.modalService.show(AddEditUserModalComponent, { initialState, backdrop: 'static' });
  }

  public openRoleMapModel(): void {
    this.bsModalRef = this.modalService.show(RoleMapModalComponent, { class: 'modal-xl', backdrop: 'static' });
  }

  public toggleUserActive(id: number): void {
    this.activeSub = this.userService.toggleUserIsActive(id)
      .subscribe((x: boolean) => {
        if (x === false) {
          this.uiService.showSuccess('Success', 'User Deactivated');
        } else if (x === true) {
          this.uiService.showSuccess('Success', 'User Reactivated');
        } else {
          this.uiService.showError('Error', 'User Status Not Changed');
        }
      });
  }

  public onChangeShowInactive($event: boolean): void {
    this.userService.userFilterParams.showInactive = $event;
    this.userService.getUsers(true);
  }

  public rowCallback(context: RowClassArgs): any {
    return {
      inactive: context.dataItem.isActive === false
    };
  }

}
