<button type="button"
  *ngIf="jobService.job.value !== null && (jobService.job.value.jobStatusId !== cancellationId && jobService.job.value.jobStatusId === openId && jobService.job.value.jobSubStatusId === JobSubStatuses.In_Progress)"
  class="btn btn-primary btn-block mt-1 mb-1"
  [swal]="{ title: 'Submit for Approval?', text: 'Job#:' + job.jobNumber + ' - ' + job.customerName, showCancelButton: true, confirmButtonText: 'Submit', confirmButtonColor: '#1B6EC2' }"
  (confirm)="changeJobSubStatus(JobSubStatuses.Pending_Approval);"
  [disabled]="enableReorder"><i class="fas fa-share"></i>&nbsp;&nbsp;Submit for
  Approval</button>
<button type="button"
  *ngIf="jobService.job.value !== null && (jobService.job.value.jobStatusId !== cancellationId && jobService.job.value.jobSubStatusId !== JobSubStatuses.In_Progress)"
  class="btn btn-primary btn-block mt-1 mb-1"
  [disabled]="true"><i class="fas fa-share"></i>&nbsp;&nbsp;Submitted</button>
<button type="button"
  class="btn btn-danger btn-block mt-1 mb-1"
  *ngIf="jobService.job.value !== null && jobService.job.value.jobStatusId === cancellationId"
  disabled>
  <i class="fad fa-times-circle"></i>&nbsp;&nbsp;Invoice Canceled
</button>
