import { Component, OnDestroy, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { InvoiceItemService } from 'src/services/invoice-item.service';
import { UIService } from 'src/services/ui.service';
import { JobTypes } from '../../../../enums/job-types';
import { JobCopyParamsModel } from '../../../../models/RequestParams/job-copy-params.model';
import { JobModel } from '../../../../models/job.model';
import { InvoiceService } from '../../../../services/invoice.service';
import { JobService } from '../../../../services/job.service';
import { TimezoneService } from '../../../../services/timezone.service';

@Component({
  selector: 'app-copy-job-modal',
  templateUrl: './copy-job-modal.component.html',
  styleUrls: ['./copy-job-modal.component.css']
})

export class CopyJobModalComponent implements OnInit, OnDestroy {
  public job: JobModel;
  public model: JobCopyParamsModel = new JobCopyParamsModel();
  public disableSave: boolean = false;
  public JobTypes = JobTypes;

  private saveSub: Subscription;

  constructor(
    public bsModalRef: BsModalRef,
    public bsModalRefChildModal: BsModalRef,
    public uiService: UIService,
    public invoiceItemService: InvoiceItemService,
    public invoiceService: InvoiceService,
    public timeZoneService: TimezoneService,
    public jobService: JobService
  ) { };

  ngOnDestroy(): void {
    this.saveSub?.unsubscribe();
  }

  ngOnInit(): void {
    this.initializeModel();
  }

  public initializeModel(): void {
    if (this.job !== null) {
      this.model.jobId = this.job.jobId;
      this.model.newJobDate = this.timeZoneService.removeTimeFromDate(new Date());
      this.model.bhas = this.job.jobTypeId === this.JobTypes.Fishing;
    }
  }

  public onChangeOfToolsCheckbox($event: any): void {
    if ($event !== undefined && !$event.srcElement.checked) {
      this.model.bhas = false;
    }
  }

  public onSave(): void {
    this.disableSave = true;

    this.saveSub = this.jobService.copyJob(this.model, this.job.jobTypeId)
      .subscribe((x: boolean) => {
        if (x) {
          this.bsModalRef.hide();
          this.uiService.showSuccess('Job Copied', 'Success');
        } else {
          this.disableSave = false;
          this.uiService.showError('Job Not Copied', 'Error');
        }
      });
  }

}
