import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-confirm-worksheet-id-modal',
  templateUrl: './confirm-worksheet-id-modal.component.html',
  styleUrls: ['./confirm-worksheet-id-modal.component.css']
})

export class ConfirmWorksheetIdModalComponent implements OnInit {
  public worksheetId: number = -1;

  constructor(
    public bsModalRef: BsModalRef
  ) { };

  ngOnInit(): void {

  }
}
