import { Component, OnDestroy, OnInit } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { AddEditContactModalComponent } from 'src/app/contacts/add-edit-contact-modal/add-edit-contact-modal.component';
import { ContactTypes } from 'src/enums/contact-types.enum';
import { ContactModel } from 'src/models/contact.model';
import { CountyModel } from 'src/models/county.model';
import { CustomerAreaModel } from 'src/models/customer-area.model';
import { ContactService } from 'src/services/contact.service';
import { CountyService } from 'src/services/county.service';
import { CustomerAreaService } from 'src/services/customer-area.service';
import { PaymentTermService } from 'src/services/payment-term.service';
import { UIService } from 'src/services/ui.service';

@Component({
  selector: 'app-add-edit-customer-area-modal',
  templateUrl: './add-edit-customer-area-modal.component.html',
  styleUrls: ['./add-edit-customer-area-modal.component.css']
})

export class AddEditCustomerAreaModalComponent implements OnInit, OnDestroy {
  public modelSub: Subscription;
  public model: CustomerAreaModel;
  public showInactive: boolean = false;

  private saveSub: Subscription;
  private countiesSub: Subscription;

  public isNew: boolean = false;
  public id: number = 0;
  public customerId: number = 0;
  public cacheCustomerArea: boolean = false;

  public counties: CountyModel[];

  public customerContactSub: Subscription;
  public customerContacts: ContactModel[];

  public disableSave = false;

  constructor(
    public bsModalRef: BsModalRef,
    public customerAreaService: CustomerAreaService,
    public uiService: UIService,
    public termService: PaymentTermService,
    public countyService: CountyService,
    public contactService: ContactService,
    public bsModalRefChildModal: BsModalRef,
    private modalService: BsModalService,
  ) { };

  ngOnDestroy(): void {
    this.countiesSub?.unsubscribe();

    this.customerContactSub?.unsubscribe();

    this.modelSub?.unsubscribe();

    this.saveSub?.unsubscribe();
  }

  ngOnInit(): void {
    this.isNew = this.id === 0;

    this.getCounties();

    this.subscribeToContacts();

    this.contactService.refreshContactList(ContactTypes.Customer, this.customerId, false);

    if (this.isNew) {
      this.model = this.customerAreaService.generateNewCustomerAreaModel(this.customerId);
    } else {
      this.getModel();
    }
  }

  public getModel(): void {
    this.modelSub = this.customerAreaService.getCustomerAreaById(this.id)
      .subscribe((x: CustomerAreaModel) => {
        this.model = x;
      });
  }

  public getCounties(): void {
    this.countiesSub = this.countyService.getCountiesForAreaDropdown(this.showInactive, this.customerId, this.id === 0 ? null : this.id)
      .subscribe((counties: CountyModel[]) => {
        if (counties) {
          this.counties = counties;
        }
      });
  }

  public openContactModal(id: number): void {
    const initialState = {
      id: id,
      type: ContactTypes.Customer,
      entityId: this.model.customerId,
      showInactive: false,
      cacheContact: true
    };
    this.bsModalRefChildModal = this.modalService.show(AddEditContactModalComponent, { initialState, backdrop: 'static' });
  }

  public subscribeToContacts(): void {
    this.contactService.contactCache.next(null);

    this.customerContactSub = this.contactService.contactCache
      .subscribe((x: ContactModel) => {
        if (x !== null && x !== undefined) {
          this.model.contactId = x.contactId;
        }
      });
  }

  public onSave(): void {
    this.disableSave = true;

    this.saveSub = this.customerAreaService.addEditCustomerArea(
      this.model,
      this.cacheCustomerArea)
      .subscribe((x: boolean) => {
        if (x) {
          this.bsModalRef.hide();
          this.uiService.showSuccess('Customer Area Saved', 'Success');
        } else {
          this.disableSave = false;
          this.uiService.showError('Customer Area Not Saved', 'Error');
        }
      });
  }

}
