import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { PageChangeEvent } from '@progress/kendo-angular-grid';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { AddEditRigModalComponent } from 'src/app/rigs/add-edit-rig-modal/add-edit-rig-modal.component';
import { AddressTypes } from 'src/enums/address-types.enum';
import { CustomerModel } from 'src/models/customer.model';
import { RigModel } from 'src/models/rig.model';
import { CustomerService } from 'src/services/customer.service';
import { KGridService } from 'src/services/k-grid.service';
import { RigService } from 'src/services/rig.service';
import { UIService } from 'src/services/ui.service';

@Component({
  selector: 'app-rigs-sm-list',
  templateUrl: './rigs-sm-list.component.html',
})

export class RigsSmallListComponent implements OnInit, OnDestroy {
  @Input() type: number;

  constructor(
    private modalService: BsModalService,
    public rigService: RigService,
    public customerService: CustomerService,
    public kGridService: KGridService,
    public uiService: UIService,
  ) { };

  public showInactive: boolean = false;
  public entityId: number = -1;
  public bsModalRef: BsModalRef;

  private customerSub: Subscription;
  private deleteSub: Subscription;

  ngOnDestroy(): void {
    this.customerSub?.unsubscribe();

    this.deleteSub?.unsubscribe();
  }

  ngOnInit(): void {
    if (this.type === AddressTypes.Customer) {
      this.subscribeToCustomer();
    }
  }

  public subscribeToCustomer(): void {
    this.customerSub = this.customerService.customer
      .subscribe((x: CustomerModel) => {
        this.entityId = x.customerId;
      });
  }

  public openRigModal(id: number): void {
    const initialState = {
      id: id,
      type: this.type,
      entityId: this.entityId,
      showInactive: this.showInactive,
      getByCustomer: true
    };

    this.bsModalRef = this.modalService.show(AddEditRigModalComponent, { initialState, backdrop: 'static' });
  }

  public onChangeShowInactive($event: boolean): void {
    this.showInactive = $event;
    this.rigService.getRigsByCustomerId(this.entityId, this.showInactive);
  }

  public deleteRig(dto: RigModel): void {
    this.deleteSub = this.rigService.deleteRig(
      dto,
      this.showInactive, true)
      .subscribe((x: boolean) => {
        if (x) {
          this.uiService.showSuccess('Success', 'Rig Deleted');
        } else {
          this.uiService.showError('Error', 'Rig Not Deleted');
        }
      });
  }

  public kGridParams: any =
    {
      buttonCount: 5,
      info: true,
      type: '\'numeric\' | \'input\' = \'numeric\'',
      pageSizes: true,
      previousNext: true,
      pageSize: 100,
      skip: 0
    };

  public pageChange({ skip, take }: PageChangeEvent): void {
    this.kGridParams.skip = skip;
    this.kGridParams.pageSize = take;
  }

}
