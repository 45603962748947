import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { WorksheetModel } from 'src/models/worksheet.model';
import { AppStateService } from 'src/services/app-state.service';
import { JobService } from 'src/services/job.service';
import { KGridService } from 'src/services/k-grid.service';
import { UIService } from 'src/services/ui.service';
import { WorksheetService } from 'src/services/worksheet.service';
import { JobTypes } from '../../../enums/job-types';
import { WorksheetModalComponent } from '../worksheet-modal/worksheet-modal.component';
import { WorksheetSnapshotComparisonModalComponent } from '../worksheet-snapshot-comparison-modal/worksheet-snapshot-comparison-modal.component';
import { JobModel } from 'src/models/job.model';

@Component({
  selector: 'app-worksheets-sm-list',
  templateUrl: './worksheets-sm-list.component.html',
  styleUrls: ['worksheets-sm-list.component.css']
})

export class WorksheetsSmallListComponent implements OnInit, OnDestroy {
  @Input() type: string;

  constructor(
    private modalService: BsModalService,
    public kGridService: KGridService,
    public uiService: UIService,
    public worksheetService: WorksheetService,
    public appStateService: AppStateService,
    public jobService: JobService
  ) { };

  public bsModalRef: BsModalRef;
  public isPackerInvoice = false;
  public jobId: number = -1;
  public jobNumber: string = null;
  public jobStatusIsTerminal: boolean = false;
  public JobTypes = JobTypes;
  public showInactive: boolean = false;

  private isTabletSub: Subscription;
  public isTablet: boolean = false;

  private jobSub: Subscription;
  private deleteSub: Subscription;
  private removeSub: Subscription;

  ngOnDestroy(): void {
    this.isTabletSub?.unsubscribe();

    this.jobSub?.unsubscribe();

    this.deleteSub?.unsubscribe();

    this.removeSub?.unsubscribe();
  }

  ngOnInit(): void {
    this.subscribeToJob();
    this.determineIsTablet();
  }

  public determineIsTablet(): void {
    this.isTabletSub = this.appStateService.isTablet$
      .subscribe((isTablet: boolean) => {
        this.isTablet = isTablet;
      });
  }

  public subscribeToJob(): void {
    this.jobSub = this.jobService.job
      .subscribe((x: JobModel) => {
        if (x !== null) {
          this.jobId = x.jobId;
          this.jobNumber = x.jobNumber;
          this.jobStatusIsTerminal = x.jobStatusIsTerminal;
          this.isPackerInvoice = x.jobTypeId === this.JobTypes.Packer;
        }
      });
  }

  public openWorksheetModal(worksheetId: number): void {
    const initialState = {
      id: worksheetId,
      jobStatusIsTerminal: this.jobStatusIsTerminal
    };

    this.bsModalRef = this.modalService.show(WorksheetModalComponent, { initialState, class: 'modal-xl', backdrop: 'static' });
  }

  public removeWorksheetFromJob(dto: WorksheetModel): void {
    if (confirm('Are you sure to remove Rebuild #' + dto.worksheetId + ' from Job #' + this.jobNumber + '?')) {
      this.uiService.showToastSaving();

      this.removeSub = this.worksheetService.removePackerFromJob(
        dto.worksheetId,
        this.jobId)
        .subscribe((x: boolean) => {
          if (x) {
            this.uiService.killAllToastMessages();
            this.uiService.showSuccess('Success', 'Rebuild Worksheet Removed');
          } else {
            this.uiService.killAllToastMessages();
            this.uiService.showError('Error', 'Rebuild Worksheet NOT Removed');
          }
        });
    }
  }

  public openSnapshotModal(worksheetId: number): void {
    const initialState = {
      worksheetId: worksheetId,
      jobNumber: this.jobNumber
    };

    this.bsModalRef = this.modalService.show(WorksheetSnapshotComparisonModalComponent, { initialState, class: 'modal-xl', backdrop: 'static' });
  }

  public deleteWorksheet(dto: WorksheetModel): void {
    if (confirm('Are you sure to delete Rebuild #' + dto.worksheetId)) {
      this.deleteSub = this.worksheetService.deleteWorksheet(dto, this.showInactive)
        .subscribe((x: boolean) => {
          if (x) {
            this.uiService.showSuccess('Success', 'Rebuild Worksheet Deleted');
          } else {
            this.uiService.showError('Error', 'Rebuild Worksheet NOT Deleted');
          }
        });
    }
  }

}
