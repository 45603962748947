<div class="card border-top-primary border-top-2 rounded-top-0"
  style="border-top-color: #2196F3 !important;">
  <div class="card-header bg-light header-elements-sm-inline">
    <h6 class="card-title">Fishing Invoice #{{invoice.invoiceNumber}}</h6>
    <div class="header-elements">
      <div class="switch-container pt-2 mr-2"
        *ngIf="(job.jobStatusId === JobStatuses.Closed_Fishing || job.jobStatusId === JobStatuses.Closed_FishingToolRental) && userService.user.value && userService.user.value.activeRoles && userService.user.value.activeRoles.includes('Manage Closed Invoices')">
        <span class="switch-label">Edit Override </span>
        <ui-switch [(ngModel)]="editClosedInvoice"></ui-switch>
      </div>
      <button class="btn  btn-sm btn-primary mr-2"
        *ngIf="isTablet"
        [disabled]="lines.length <= 1"
        (click)="openReorderInvoiceModal()"><i class="fas fa-arrows-v"></i>&nbsp;&nbsp;Re-sort</button>
      <div class="switch-container pt-2 mr-2"
        *ngIf="!enableReorder  && !isTablet">
        <span class="switch-label">Enable Re-sort </span>
        <ui-switch [ngModel]="enableReorder"
          (change)="switchEnableReorder($event)"></ui-switch>
      </div>
      <button class="btn btn-sm btn-primary mr-2"
        *ngIf="enableReorder"
        (click)="switchEnableReorder(false)">Save Sort Order</button>
      <div class="btn-group"
        dropdown>
        <button class="btn btn-light btn-sm dropdown-toggle"
          *ngIf="!editClosedInvoice && (enableReorder || (jobService.job.value !== null && jobService.job.value.jobStatusIsTerminal === true))"
          disabled>
          <i class="fa fa-plus"></i> Add Line
          <span class="caret"></span>
        </button>
        <button class="btn btn-light btn-sm dropdown-toggle"
          dropdownToggle
          id="button-basic"
          aria-controls="dropdown-basic"
          *ngIf="editClosedInvoice || (!enableReorder && !(jobService.job.value !== null && jobService.job.value.jobStatusIsTerminal === true))">
          <i class="fa fa-plus"></i> Add Line
          <span class="caret"></span>
        </button>
        <ul id="dropdown-basic"
          *dropdownMenu
          class="dropdown-menu dropdown-menu-right"
          role="menu"
          aria-labelledby="button-basic">
          <li role="menuitem"
            *ngIf="editClosedInvoice || ((job.jobStatusId === JobStatuses.Open_Fishing || job.jobStatusId === JobStatuses.Open_FishingToolRental || job.jobStatusId === JobStatuses.EditInvoice_Fishing || job.jobStatusId === JobStatuses.EditInvoice_FishingToolRental))">
            <span class="dropdown-item"
              (click)="openJobFishingItemModal(job.jobId, 0, false, true)"><i class="fal fa-box"></i>
              Miscellaneous</span>
          </li>
          <li role="menuitem"><span class="dropdown-item"
              (click)="openJobFishingItemNoteModal(job.jobId, 0, invoice.invoiceId)"><i class="fal fa-sticky-note"></i>
              Note</span></li>
        </ul>
      </div>
      <button class="btn btn-sm btn-light ml-2"
        *ngIf="true"
        (click)="reportService.getFishingInvoicePDF(job.jobId,invoice.invoiceId)"><i class="fal fa-file-pdf"></i>
        Print</button>
      <button class="btn btn-sm btn-light ml-2"
        [disabled]="lines && lines.length === 0"
        (click)="togglePriceHeatmap($event)"
        title="Top 3 Line Amounts"><span [ngClass]="{'text-danger': showPriceHeatmap}"><i
            class="fa-regular fa-fire"></i></span></button>
    </div>
  </div>
  <div class="table-responsive">
    <div class="table-responsive"
      *ngIf="lines && lines.length === 0">
      <div class="alert alert-primary ml-3 mr-3 mt-3"
        role="alert">
        <i class="fad fa-check"></i>&nbsp;&nbsp;There are currently no <strong>Fishing Items.</strong>
      </div>
    </div>
    <kendo-grid *ngIf="gridData.data.length >  0"
      [data]="gridData"
      filterable="false"
      [resizable]="true"
      [sortable]="false"
      [ngClass]="{'kendo-min-width': !isTablet}"
      [rowClass]="rowCallback"
      (dataStateChange)="dataStateChange($event)">
      <kendo-grid-column field="move"
        title=""
        [width]="40"
        filterable="false"
        *ngIf="enableReorder">
        <ng-template kendoGridCellTemplate
          let-dataItem
          let-rowIndex="rowIndex">
          <span class="pl-1 text-danger"><i class="fas fa-arrows-alt-v"></i></span>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column field="one"
        title=""
        [width]="60"
        filterable="false">
        <ng-template kendoGridCellTemplate
          let-dataItem
          let-rowIndex="rowIndex">
          <button class="btn btn-xs btn-light"
            (click)="openJobFishingItemModal(job.jobId, dataItem.invoiceItemId, (dataItem.worksheetRowItemId === null && dataItem.worksheetId === null && dataItem.jobItemId === null && dataItem.jobItemInstanceId === null && dataItem.autoGenerated === true), (dataItem.worksheetRowItemId === null && dataItem.worksheetId === null && dataItem.jobItemId === null && dataItem.jobItemInstanceId === null && dataItem.autoGenerated === false))"
            [disabled]="!editClosedInvoice && (enableReorder || (jobService.job.value !== null && jobService.job.value.jobStatusIsTerminal === true))"
            *ngIf="dataItem.invoiceItemTypeId === InvoiceItemTypes.InvoiceItem && (dataItem.parentInvoiceItemId === null || dataItem.parentInvoiceItemId === undefined)"><i
              class="fa fa-pencil"></i></button>
          <button class="btn btn-xs btn-light"
            (click)="openJobFishingItemNoteModal(job.jobId, dataItem.invoiceItemId, invoice.invoiceId)"
            [disabled]="!editClosedInvoice && (enableReorder || (jobService.job.value !== null && jobService.job.value.jobStatusIsTerminal === true))"
            *ngIf="dataItem.invoiceItemTypeId === InvoiceItemTypes.Note && (dataItem.parentInvoiceItemId === null || dataItem.parentInvoiceItemId === undefined)"><i
              class="fa fa-pencil"></i></button>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column field="partNumber"
        title="Part #"
        [width]="120">
        <ng-template kendoGridCellTemplate
          let-dataItem
          let-rowIndex="rowIndex">
          <span
            *ngIf="dataItem.invoiceItemTypeId === InvoiceItemTypes.InvoiceItem&&dataItem.partNumber!==undefined&&dataItem.partNumber!==null">{{dataItem.partNumber}}</span>
          <span
            *ngIf="dataItem.invoiceItemTypeId === InvoiceItemTypes.InvoiceItem&&(dataItem.partNumber===undefined||dataItem.partNumber===null)&&(dataItem.parentInvoiceItemId === null || dataItem.parentInvoiceItemId === undefined)"
            class="text-muted">n/a</span>
          <span *ngIf="dataItem.invoiceItemTypeId === InvoiceItemTypes.Note"
            class="text-muted"><i class="fal fa-sticky-note"></i></span>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column field="description"
        title="Description"
        [width]="250">
        <ng-template kendoGridCellTemplate
          let-dataItem
          let-rowIndex="rowIndex">
          <span *ngIf="dataItem.parentInvoiceItemId===null"
            [ngClass]="{'font-italic' : dataItem.invoiceItemTypeId===InvoiceItemTypes.Note}">{{dataItem.description}}</span>
          <span *ngIf="dataItem.parentInvoiceItemId!==null"
            class="text-muted"><span style="margin-left: 5px;"><i
                class="far fa-level-up fa-rotate-90"></i></span>&nbsp;&nbsp;{{dataItem.description}}</span>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column field="quantity"
        title="Quantity"
        [width]="90"
        filterable="false">
        <ng-template kendoGridCellTemplate
          let-dataItem
          let-rowIndex="rowIndex">
          <span
            *ngIf="dataItem.invoiceItemTypeId === InvoiceItemTypes.InvoiceItem && dataItem.parentInvoiceItemId===null">{{dataItem.quantity}}</span>
          <span
            *ngIf="dataItem.invoiceItemTypeId === InvoiceItemTypes.InvoiceItem && dataItem.parentInvoiceItemId!==null"
            class="text-muted">{{dataItem.quantity}}</span>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column field="price"
        title="Unit Price"
        [width]="120">
        <ng-template kendoGridCellTemplate
          let-dataItem
          let-rowIndex="rowIndex">
          <span
            *ngIf="dataItem.invoiceItemTypeId === InvoiceItemTypes.InvoiceItem && dataItem.parentInvoiceItemId===null">{{dataItem.price
            | currency}}</span>
          <span
            *ngIf="dataItem.invoiceItemTypeId === InvoiceItemTypes.InvoiceItem && dataItem.parentInvoiceItemId!==null"
            class="text-muted">{{dataItem.price | currency}}</span>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column field="total"
        title="Total"
        [width]="120">
        <ng-template kendoGridCellTemplate
          let-dataItem
          let-rowIndex="rowIndex">
          <span *ngIf="dataItem.invoiceItemTypeId === InvoiceItemTypes.InvoiceItem">{{dataItem.total | currency}}</span>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column [width]="35"
        *ngIf="!isTablet">
        <ng-template kendoGridCellTemplate
          let-dataItem>
          <i class="fal fa-file-invoice-dollar"
            title="Taxable Item"
            *ngIf="dataItem.isTaxable"></i>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column field="one"
        title=""
        [width]="60"
        filterable="false">
        <ng-template kendoGridCellTemplate
          let-dataItem
          let-rowIndex="rowIndex">
          <button class="btn btn-xs btn-light"
            [swal]="{ title: 'Delete Invoice Line?', text: dataItem.description + ': $' + dataItem.total, showCancelButton: true, confirmButtonText: 'Delete', confirmButtonColor: '#DC3545' }"
            (confirm)="deleteFishingInvoiceItem(dataItem)"
            [disabled]="!editClosedInvoice
                              && (jobService.job.value !== null && ((jobService.job.value.jobTypeId === JobTypes.Fishing && jobService.job.value.jobStatusId !== JobStatuses.EditInvoice_Fishing) || (jobService.job.value.jobTypeId === JobTypes.FishingToolRental && jobService.job.value.jobStatusId !== JobStatuses.EditInvoice_FishingToolRental)))
                              && (dataItem.allowDelete===false || (job.invoiceHasBeenSplit || enableReorder || (jobService.job.value !== null && jobService.job.value.jobStatusIsTerminal === true)))"
            *ngIf="dataItem.invoiceItemTypeId === InvoiceItemTypes.InvoiceItem && dataItem.parentInvoiceItemId === null || dataItem.parentInvoiceItemId === undefined">
            <i class="fa fa-trash"></i>
            <!--|| (job.invoiceHasBeenSplit || enableReorder || (jobService.job.value !== null && jobService.job.value.jobStatusIsTerminal === true))-->
          </button>
          <button class="btn btn-xs btn-light"
            [swal]="{ title: 'Delete Note?', text: dataItem.description, showCancelButton: true, confirmButtonText: 'Delete', confirmButtonColor: '#DC3545' }"
            (confirm)="deleteFishingInvoiceItem(dataItem)"
            [disabled]="!editClosedInvoice && (enableReorder || (dataItem.worksheetRowItemId !== null||dataItem.worksheetId !== null)||(jobService.job.value !== null && jobService.job.value.jobStatusIsTerminal === true))"
            *ngIf="dataItem.invoiceItemTypeId === InvoiceItemTypes.Note && dataItem.parentInvoiceItemId === null || dataItem.parentInvoiceItemId === undefined">
            <i class="fa fa-trash"></i>
          </button>
        </ng-template>
      </kendo-grid-column>
    </kendo-grid>
  </div>
  <div class="d-flex mt-3"
    [ngClass]="{ 'justify-content-between': invoice.invDay.length > 0, 'flex-row-reverse' : invoice.invDay.length === 0 }">
    <div class="card border-top-primary border-top-2 rounded-top-0 col-lg-3 col-md-5 col-sm-6"
      style="border-top-color: #2196F3 !important;"
      *ngIf="invoice.invDay.length > 0">
      <div class="card-body">
        <h6>Work Dates:</h6>
        <p *ngFor="let d of invoice.invDay">{{d | date:'shortDate'}}</p>
      </div>
    </div>
    <div class="card border-top-primary border-top-2 rounded-top-0 col-lg-3 col-md-5 col-sm-6"
      style="border-top-color: #2196F3 !important;"
      *ngIf="invoice.totalStamp && job.invoices.length > 1">
      <div class="card-body">
        <table style="float:right;">
          <tr>
            <td style="font-weight: 500;">Sub Total:</td>
            <td width="20px">&nbsp;</td>
            <td>{{invoice.subTotalStamp | currency}}</td>
          </tr>
          <tr>
            <td style="font-weight: 500;">Tax&nbsp;<small>({{invoice.salesTaxRateStamp | percent:'0.2'}})</small>:</td>
            <td width="20px">&nbsp;</td>
            <td>{{invoice.salesTaxTotalStamp | currency}}</td>
          </tr>
          <tr>
            <td style="font-weight: 500;">TOTAL:</td>
            <td width="20px">&nbsp;</td>
            <td><strong>{{invoice.totalStamp | currency}}</strong></td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</div>
