<div class="modal-header"
  ngxModalDraggable>
  <h4 class="modal-title pull-left">Confirm Enable Edit</h4>
  <button type="button"
    class="close pull-right"
    aria-label="Close"
    (click)="onCancel()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div class="center">
    <h1><span class="text-danger"><i class="fas fa-exclamation-triangle"></i></span> Hold up there,
      cowboy! <span class="text-danger"><i class="fas fa-exclamation-triangle"></i></span>
    </h1>
  </div>
  <p class="p-font-size">If you're here, that means you're a <strong>SUPER ADMIN.</strong> Congratulations, you've made
    it! Great job!</p>
  <p class="p-font-size">
    Here's the deal, champ:</p>
  <div class="bg-light p-1">
    <p class="p-font-size text-danger mb-0">Please be very, <strong>VERY</strong> sure you want to change this list.
      TackleBox has logic written around this list and <strong>changing it should only be done with extreme
        caution</strong>. If you're not sure, please contact the TackleBox team for assistance.
    </p>
  </div>
</div>
<div class="modal-footer">
  <button type="button"
    class="btn btn-danger"
    (click)="onCancel()">Cancel</button>
  <button type="button"
    class="btn btn-success"
    (click)="onConfirm()">Continue</button>
</div>
