import { Component, OnDestroy, OnInit } from '@angular/core';
import { RowClassArgs } from '@progress/kendo-angular-grid';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { SuperAdminEditConfirmModalComponent } from 'src/app/shared/super-admin-edit-confirm-modal/super-admin-edit-confirm-modal.component';
import { ItemInstanceLocationService } from 'src/services/item-instance-location.service';
import { KGridService } from 'src/services/k-grid.service';
import { UIService } from 'src/services/ui.service';
import { UserService } from 'src/services/user.service';
import { AddEditItemInstanceLocationModalComponent } from '../add-edit-item-instance-location-modal/add-edit-item-instance-location-modal.component';

@Component({
  selector: 'app-item-instance-locations-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.css']
})

export class ItemInstanceLocationListComponent implements OnInit, OnDestroy {
  public showInactive: boolean = false;
  public allowEditing: boolean = false;
  public bsModalRef: BsModalRef;
  private deleteSub: Subscription;
  private saveSubscriptions = new Subscription();

  constructor(
    private modalService: BsModalService,
    public kGridService: KGridService,
    public iilService: ItemInstanceLocationService,
    public uiService: UIService,
    public userService: UserService
  ) { };

  public ngOnDestroy(): void {
    this.kGridService.resetKGridParams();

    this.deleteSub?.unsubscribe();

    this.saveSubscriptions?.unsubscribe();
  }

  ngOnInit(): void {
    this.iilService.getItemInstanceLocations(
      this.showInactive,
      true);
  }

  public rowCallback(context: RowClassArgs): any {
    return {
      inactive: context.dataItem.isActive === false
    };
  }

  public openItemInstanceLocationModal(itemInstanceLocationId: number): void {
    const initialState = {
      id: itemInstanceLocationId,
      showInactive: this.showInactive
    };
    this.bsModalRef = this.modalService.show(AddEditItemInstanceLocationModalComponent, { initialState, backdrop: 'static' });
  }

  public onChangeOfAllowEditing($event: boolean): void {
    this.allowEditing = $event;

    if ($event !== undefined) {
      if ($event === true) {
        this.bsModalRef = this.modalService.show(SuperAdminEditConfirmModalComponent, { backdrop: 'static' });

        this.saveSubscriptions.add(this.bsModalRef.content.onClose
          .subscribe((confirm: boolean) => {
            if (confirm !== undefined) {
              this.allowEditing = confirm;
            }
          }));
      }
    }
  }

  public deleteItemInstanceLocation(
    $event: any,
    id: number): void {
    this.deleteSub = this.iilService.deleteItemInstanceLocation(id, this.showInactive)
      .subscribe((x: boolean) => {
        if (x) {
          this.uiService.showSuccess('Success', 'Location Deleted');
        } else {
          this.uiService.showError('Error', 'Location Not Deleted');
        }
      });
  }

  public onChangeShowInactive($event): void {
    this.showInactive = $event;

    this.iilService.getItemInstanceLocations(
      this.showInactive,
      true);
  }

}
