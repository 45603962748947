import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AuthorizeService } from 'src/api-authorization/authorize.service';


@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

  constructor(private authorize: AuthorizeService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(tap(event => { }, err => {
      debugger;
      // if (err instanceof HttpErrorResponse && err.url != null && err.url.toLowerCase().indexOf('/account/login') > 0) {  
      if ((err instanceof HttpErrorResponse
        && err.url != null
        && err.url.toLowerCase().indexOf('/account/login') > 0)
        || (err instanceof HttpErrorResponse && err.status == 401)) {
        // handle redirect to login page
        window.history.state.local = true;
        window.location.href = '/authentication/logout';
      }
    }));
  }
}
