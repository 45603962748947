<div class="page-header">
  <div class="page-header-content header-elements-md-inline">
    <div class="page-title d-flex">
      <a [routerLink]="['/home']"
        class="breadcrumb-link-color">
        <h4><i class="icon-arrow-left52 mr-2"></i> <span class="font-weight-semibold">Serialized Item Quick View</span>
          - Manage</h4>
      </a>
      <button type="button"
        class="btn btn-outline-dark btn-sm ml-3 pl-1 pr-1 pt-0 pb-0 no-border"
        [title]="!showFilter ? 'Show Filter' : 'Hide Filter'"
        (click)="showFilter = !showFilter;getNumberOfActiveFilterFields();">
        <span *ngIf="!showFilter">
          <i class="fa-regular fa-chevrons-down"></i>&nbsp;Show </span>
        <span *ngIf="showFilter"><i class="fa-regular fa-chevrons-up"></i>&nbsp;Hide
        </span>Filter<span class="text-warning"
          *ngIf="!showFilter && numberOfActiveFilterFields > 0"><strong>&nbsp;&nbsp;({{numberOfActiveFilterFields}}
            active
            criteria)</strong></span></button>
    </div>
    <div class="header-elements d-none py-0 mb-3 mb-md-0">
      <div class="breadcrumb">
        <a href="index.html"
          class="breadcrumb-item"><i class="icon-home2 mr-2"></i> Serialized Item Quick View</a>
        <span class="breadcrumb-item active">List</span>
      </div>
    </div>
  </div>
</div>
<div class="page-content pt-0">
  <div class="content-wrapper">
    <div class="content">
      <div class="row">
        <div class="col-md-12"
          *ngIf="showFilter">
          <div class="card">
            <div class="card-body pb-2 pt-2">
              <div class="row">
                <div class="col-lg-3 col-md-6">
                  <div class="form-group mb-0">
                    <label class="col-form-label">Part #</label>
                    <div>
                      <input type="text"
                        class="form-control"
                        [(ngModel)]="itemInstanceService.serializedLocationFilterParams.partNumber"
                        name="ponumberFilter"
                        (keyup.enter)="itemInstanceService.getItems(showInactive, true, false, true, true);kGridService.pageChange({ skip: 0, take: kGridService.kGridParams.pageSize })">
                    </div>
                  </div>
                </div>
                <div class="col-lg-3 col-md-6">
                  <div class="form-group mb-0">
                    <label class="col-form-label">Description</label>
                    <div>
                      <input type="text"
                        class="form-control"
                        [(ngModel)]="itemInstanceService.serializedLocationFilterParams.description"
                        name="ponumberFilter"
                        (keyup.enter)="itemInstanceService.getItems(showInactive, true, false, true, true);kGridService.pageChange({ skip: 0, take: kGridService.kGridParams.pageSize })">
                    </div>
                  </div>
                </div>
                <div class="col-lg-6 col-md-8">
                  <div class="form-group mb-0">
                    <label class="col-form-label">Items</label>
                    <div *ngIf="itemService.serializedItemList | async; let items">
                      <ng-select [items]="items"
                        bindLabel="dropdownDisplay"
                        bindValue="itemId"
                        [multiple]="true"
                        [closeOnSelect]="false"
                        [searchable]="true"
                        placeholder="Select Items"
                        [(ngModel)]="itemInstanceService.serializedLocationFilterParams.itemIds"
                        name="itemIds">
                      </ng-select>
                    </div>
                    <div *ngIf="!itemService.serializedItemList.value">
                      <ng-select name="itemIds"
                        placeholder="Select Items"
                        [multiple]="true"
                        [readonly]="true">
                      </ng-select>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row d-flex flex-row-reverse pr-2 mt-lg-2">
                <button type="submit"
                  class="btn btn-sm btn-primary"
                  (click)="itemInstanceService.getSerializedLocationList(onJobOnly);kGridService.pageChange({ skip: 0, take: kGridService.kGridParams.pageSize })"><i
                    class="fa fa-search mr-2"></i>Search</button>
                <button type="submit"
                  class="btn btn-sm btn-light mr-1"
                  (click)="itemInstanceService.clearFilterParams(onJobOnly);kGridService.pageChange({ skip: 0, take: kGridService.kGridParams.pageSize })"><i
                    class="far fa-times-circle"></i>&nbsp;Clear</button>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-12">
          <div class="card border-top-primary border-top-2 rounded-top-0"
            style="border-top-color: #2196F3 !important;">
            <div class="card-header bg-light header-elements-sm-inline">
              <h6 class="card-title">Serialized Items</h6>
              <div class="switch-container pt-2 mr-2">
                <span class="switch-label">Show On Job Only </span>
                <ui-switch [ngModel]="onJobOnly"
                  (change)="onChangeOnJobOnly($event)"></ui-switch>
              </div>
            </div>
            <app-table-skeleton *ngIf="itemInstanceService.locationsAreLoading"></app-table-skeleton>
            <ng-container *ngIf="!itemInstanceService.locationsAreLoading">
              <div class="table-responsive"
                *ngIf="itemInstanceService.serializedLocationList | async; let model">
                <kendo-grid [ngClass]="{'kendo-min-width': !isTablet}"
                  [kendoGridBinding]="model"
                  filterable="false"
                  [sortable]="true"
                  [resizable]="true"
                  [rowClass]="rowCallback"
                  [style.maxHeight.px]="650"
                  [pageSize]="kGridService.kGridParams.pageSize"
                  [skip]="kGridService.kGridParams.skip"
                  (pageChange)="kGridService.pageChange($event)"
                  [pageable]="{
                              buttonCount: kGridService.kGridParams.buttonCount,
                              info: kGridService.kGridParams.info,
                              type: kGridService.kGridParams.type,
                              pageSizes: kGridService.kGridParams.pageSizes,
                              previousNext: kGridService.kGridParams.previousNext
                            }">
                  <ng-template kendoGridToolbarTemplate>
                    <button type="button"
                      kendoGridExcelCommand
                      icon="file-excel">Export to Excel</button>
                  </ng-template>
                  <kendo-grid-column field="partNumber"
                    title="Part #"
                    [width]="80">
                    <ng-template kendoGridCellTemplate
                      let-dataItem
                      let-rowIndex="rowIndex">
                      <span *ngIf="dataItem.partNumber"
                        class="link"><a
                          [routerLink]="['/items/details', dataItem.itemId]"><u>{{dataItem.partNumber}}</u></a></span>
                    </ng-template>
                  </kendo-grid-column>
                  <kendo-grid-column field="description"
                    title="Description"
                    [width]="350">
                  </kendo-grid-column>
                  <kendo-grid-column field="serialNumber"
                    title="Serial #"
                    [width]="80">
                    <ng-template kendoGridCellTemplate
                      let-dataItem
                      let-rowIndex="rowIndex">
                      <span *ngIf="dataItem.serialNumber"
                        class="link"><a
                          [routerLink]="['/item-instances/details', dataItem.itemInstanceId]"><u>{{dataItem.serialNumber}}</u></a></span>
                    </ng-template>
                  </kendo-grid-column>
                  <kendo-grid-column field="jobNumber"
                    title="Job #"
                    [width]="80">
                    <ng-template kendoGridCellTemplate
                      let-dataItem
                      let-rowIndex="rowIndex">
                      <span *ngIf="dataItem.jobNumber"
                        class="link"
                        (click)="goToJob(dataItem)"><u>{{dataItem.jobNumber}}</u></span>
                      <span *ngIf="!dataItem.jobNumber"
                        class="text-muted">n/a</span>
                    </ng-template>
                  </kendo-grid-column>
                  <kendo-grid-column field="openDate"
                    title="Open Date"
                    [width]="150">
                    <ng-template kendoGridCellTemplate
                      let-dataItem
                      let-rowIndex="rowIndex">
                      <span *ngIf="dataItem.openDate">{{dataItem.openDate | date:'longDate'}}</span>
                      <span *ngIf="!dataItem.openDate"
                        class="text-muted">n/a</span>
                    </ng-template>
                  </kendo-grid-column>
                  <kendo-grid-column field="jobOwner"
                    title="Job Owner"
                    [width]="125">
                    <ng-template kendoGridCellTemplate
                      let-dataItem
                      let-rowIndex="rowIndex">
                      <span *ngIf="dataItem.jobOwner">{{dataItem.jobOwner}}</span>
                      <span *ngIf="!dataItem.jobOwner"
                        class="text-muted">n/a</span>
                    </ng-template>
                  </kendo-grid-column>
                  <kendo-grid-column field="location"
                    title="Location"
                    [class]="{ codeColumn: true }"
                    [width]="275">
                  </kendo-grid-column>
                  <kendo-grid-excel fileName="Grizzly - Serialized Item Locations.xlsx"
                    [fetchData]="allData">
                    <kendo-excelexport-column field="partNumber"
                      title="Part #">
                    </kendo-excelexport-column>
                    <kendo-excelexport-column field="description"
                      title="Description">
                    </kendo-excelexport-column>
                    <kendo-excelexport-column field="serialNumber"
                      title="Serial #">
                    </kendo-excelexport-column>
                    <kendo-excelexport-column field="jobNumber"
                      title="Job #">
                    </kendo-excelexport-column>
                    <kendo-excelexport-column field="openDate"
                      title="Open Date">
                    </kendo-excelexport-column>
                    <kendo-excelexport-column field="jobOwner"
                      title="Job Owner">
                    </kendo-excelexport-column>
                    <kendo-excelexport-column field="location"
                      title="Location">
                    </kendo-excelexport-column>
                  </kendo-grid-excel>
                </kendo-grid>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
