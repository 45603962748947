import { Component, OnDestroy, OnInit } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { AddEditBillingCodeModalComponent } from 'src/app/billing-codes/add-edit-billing-code-modal/add-edit-billing-code-modal.component';
import { AddEditItemModalComponent } from 'src/app/items/add-edit-item-modal/add-edit-item-modal.component';
import { BillingCodeModel } from 'src/models/billing-code.model';
import { JobSalesItemModel } from 'src/models/job-sales-item.model';
import { BillingCodeService } from 'src/services/billing-code.service';
import { InvoiceItemService } from 'src/services/invoice-item.service';
import { ItemService } from 'src/services/item.service';
import { JobService } from 'src/services/job.service';
import { UIService } from 'src/services/ui.service';
import { UserService } from 'src/services/user.service';
import { InvoiceItemTypes } from '../../../../enums/invoice-item-types';
import { ItemDropdownModel } from '../../../../models/item-dropdown.model';
import { ShopItemModel } from '../../../../models/shop-item.model';
import { ItemModel } from 'src/models/item.model';

@Component({
  selector: 'app-add-edit-job-sales-item-modal',
  templateUrl: './add-edit-job-sales-item-modal.component.html',
  styleUrls: ['./add-edit-job-sales-item-modal.component.css']
})

export class AddEditJobSalesItemModalComponent implements OnInit, OnDestroy {
  public billingCodes: Array<BillingCodeModel> = [];
  public userCanSeePrices: boolean = true;
  public isNew: boolean = false;
  public isInventoryItem: boolean = false;
  public isRebuildItem: boolean = false;
  public itemIsLoaded: boolean = false;
  public jobId: number = 0;
  public invoiceItemId: number = 0;
  public missingBillingCodeChecked: boolean = false;
  public missingItemChecked: boolean = false;
  public total: string = '';

  public currentInventory: ShopItemModel[] = [];
  public InvoiceItemTypes = InvoiceItemTypes;

  public isEditable: boolean = false;
  public editItem: boolean = false;
  public disableInpCheckbox: boolean = false;
  public showWarning: boolean = false;
  public qtyCache: number = 0;
  public disableSave: boolean = false;

  private modelSub: Subscription;
  public model: JobSalesItemModel = new JobSalesItemModel();

  private bcSub_1: Subscription;
  private bcSub_2: Subscription;
  private bcSub: Subscription;

  private itemSub_1: Subscription;
  private itemSub_2: Subscription;
  private itemSub: Subscription;

  private saveSub: Subscription;

  constructor(
    public bsModalRef: BsModalRef,
    public bsModalRefChildModal: BsModalRef,
    private modalService: BsModalService,
    public uiService: UIService,
    public itemService: ItemService,
    public billingCodeService: BillingCodeService,
    public invoiceItemService: InvoiceItemService,
    public jobService: JobService,
    public userService: UserService

  ) { };

  ngOnDestroy(): void {
    this.modelSub?.unsubscribe();

    this.bcSub_1?.unsubscribe();

    this.bcSub_2?.unsubscribe();

    this.bcSub?.unsubscribe();

    this.itemSub_1?.unsubscribe();

    this.itemSub_2?.unsubscribe();

    this.itemSub?.unsubscribe();

    this.saveSub?.unsubscribe();
  }

  ngOnInit(): void {
    this.userCanSeePrices = this.canSeePrices();

    this.subscribeToItemList();

    this.itemService.getItemsDropdown(false);

    this.billingCodeService.getBillingCodes(
      false,
      true,
      this.isRebuildItem,
      true);

    this.subscribeToItemCache();

    this.subscribeToBillingCodeCache();

    this.isNew = this.invoiceItemId === 0;

    if (!this.isNew) {
      this.getModel();
    } else {
      this.model.invoiceItemTypeId = this.InvoiceItemTypes.InvoiceItem;
      this.model.isForResale = false;
      this.model.isActive = true;
      this.model.jobId = this.jobId;
      this.subscribeToBillingCodes();
    }
  }

  public getModel(): void {
    this.modelSub = this.invoiceItemService.getJobSalesItemByInvoiceItemId(this.invoiceItemId)
      .subscribe((x: JobSalesItemModel) => {
        this.isEditable = x.allowEditFishingItemQty;
        this.qtyCache = x.quantity;
        this.editItem = x.lockQty;
        this.disableInpCheckbox = x.lockQty;

        this.model = x;
        this.subscribeToBillingCodes();
      });
  }

  // this subscribe exists to remove a race condition where there would not yet be values in billingCodeList when the modal renders
  public subscribeToBillingCodes(): void {
    this.billingCodeService.billingCodeList.next(null);

    this.bcSub_1 = this.billingCodeService.billingCodeList
      .subscribe((x: BillingCodeModel[]) => {
        if (x !== null
          && x !== undefined) {
          let filter = x.filter((x: BillingCodeModel) => {
            return x.billingCodeId === this.model.billingCodeId;
          });

          if (filter.length === 0
            && this.model
            && this.model.billingCodeId) {
            this.bcSub_2 = this.billingCodeService.getBillingCodeById(this.model.billingCodeId)
              .subscribe((x: BillingCodeModel) => {
                this.missingBillingCodeChecked = true;
                if (x !== undefined && x !== null) {
                  this.billingCodeService.billingCodeList.next([...this.billingCodeService.billingCodeList.value, x]);
                }
              });
          } else {
            this.filterBillingCodes(this.model.itemId);
          }

          this.calculateTotal();
        }
      });
  }

  public subscribeToItemList(): void {
    this.itemService.itemDropdownList.next(null);

    this.itemSub_1 = this.itemService.itemDropdownList
      .subscribe((x: ItemDropdownModel[]) => {
        if (x !== null
          && x !== undefined) {
          let filter: ItemDropdownModel[] = x.filter((x: ItemDropdownModel) => {
            return x.itemId == this.model.itemId;
          });

          if (filter.length === 0
            && this.model
            && this.model.itemId) {
            this.itemSub_2 = this.itemService.getItemById(this.model.itemId)
              .subscribe((x: ItemModel) => {
                this.missingItemChecked = true;
                if (x !== null && x !== undefined) {
                  let newDropdown = this.itemService.convertItemToDropdown(x);
                  this.itemService.itemDropdownList.next([...this.itemService.itemDropdownList.value, newDropdown]);
                }
              });
          }
        }
      });
  }

  public subscribeToItemCache(): void {
    this.itemService.itemCache.next(null);

    this.itemSub = this.itemService.itemCache
      .subscribe((x: ItemModel) => {
        if (x !== null && x !== undefined) {
          this.model.itemId = x.itemId;
        }
      });
  }

  public subscribeToBillingCodeCache(): void {
    this.billingCodeService.billingCodeCache.next(null);

    this.bcSub = this.billingCodeService.billingCodeCache
      .subscribe((x: BillingCodeModel) => {
        if (x !== null && x !== undefined) {
          this.model.billingCodeId = x.billingCodeId;
          this.model.price = x.rate;
        }
      });
  }

  public openItemModal(itemId: number): void {
    const initialState = {
      id: itemId,
      showInactive: false,
      launchedFromModal: true,
      refreshDropdown: true
    };
    this.bsModalRefChildModal = this.modalService.show(AddEditItemModalComponent, { initialState, class: 'modal-lg', backdrop: 'static' });
  }

  public openBillingCodeModal(billingCodeId: number): void {
    const initialState = {
      id: billingCodeId,
      itemId: this.model.itemId,
      showInactive: false,
      cacheBillingCode: true,
      isInventoryItem: this.isInventoryItem
    };
    this.bsModalRefChildModal = this.modalService.show(AddEditBillingCodeModalComponent, { initialState, backdrop: 'static' });
  }

  // only called on change of Item select menu
  public onChangeOfItem($event: ItemDropdownModel): void {
    if ($event !== undefined) {
      this.itemIsLoaded = true;
      this.currentInventory = $event.shopItems !== undefined && $event.shopItems !== null ? $event.shopItems : [];
    } else {
      this.itemIsLoaded = false;
      this.currentInventory = [];
    }

    this.model.billingCodeId = null;
    this.billingCodes.length = 0;

    if ($event !== undefined) {
      this.filterBillingCodes($event.itemId);
      this.model.price = this.billingCodes.length > 0 ? this.billingCodes[0].rate : null;
      this.model.description = this.billingCodes.length > 0 ? this.billingCodes[0].name : null;
    } else {
      this.model.description = null;
    }

    this.calculateTotal();
  }

  // only called on change of Item select menu
  public onChangeOfBillingCode($event: BillingCodeModel): void {
    if ($event !== undefined) {
      this.model.billingCodeId = $event.billingCodeId;
      this.model.price = this.billingCodes.filter((x: BillingCodeModel) => {
        return x.billingCodeId === this.model.billingCodeId;
      })[0].rate;
      this.model.description = this.billingCodes.filter((x: BillingCodeModel) => {
        return x.billingCodeId === this.model.billingCodeId;
      })[0].name;
    } else {
      this.model.billingCodeId = null;
      this.model.description = null;
    }
    this.calculateTotal();
  }
  // THIS CODE REPEATS IN MANY PLACES NEED TO CENTRALIZE
  public filterBillingCodes(itemId: number): void {

    let job = this.jobService.job.value;

    if (this.isInventoryItem) {
      this.billingCodes = this.billingCodeService.billingCodeList.value !== null ?
        (this.billingCodeService.billingCodeList.value.filter((x: BillingCodeModel) => {
          return x.itemId == itemId && x.itemId !== null && (x.customerId === null || (job !== null && x.customerId === job.customerId));
        }))
        : [];

      let currentBillingCode: BillingCodeModel[] = this.billingCodes.filter((x: BillingCodeModel) => {
        return x.billingCodeId === this.model.billingCodeId;
      });
      // if the current billing code id exists in the list of filtered billing codes, the billing code is not changed.
      if (currentBillingCode.length === 0) {
        let customerSpecificCode = job !== null ? this.billingCodes.filter((x: BillingCodeModel) => {
          return x.customerId === job.customerId;
        }) : [];
        // The initially selected billing code will look first for a customer specific code, then go to the first in the array if one doesn't exist
        this.model.billingCodeId = this.billingCodes.length > 0 ?
          customerSpecificCode.length > 0 ? customerSpecificCode[0].billingCodeId : this.billingCodes[0].billingCodeId : null;
      }
    } else {
      // retrieve only billing codes without itemIds for misc items
      this.billingCodes = this.billingCodeService.billingCodeList.value !== null ?
        (this.billingCodeService.billingCodeList.value.filter((x: BillingCodeModel) => {
          return x.itemId === null;
        })) : [];
    }
  }

  public calculateTotal(): void {
    if (this.model.quantity !== null
      && this.model.quantity !== undefined
      && this.model.billingCodeId !== null
      && this.model.billingCodeId !== undefined
      && this.billingCodes.length > 0) {

      let rate: number = this.model.price;
      this.total = (this.model.quantity * rate).toFixed(2);
    } else {
      this.total = '';
    }
  }

  public enableQtyEdit(): void {
    this.editItem = true;
    this.disableInpCheckbox = true;
    this.showWarning = true;
  }

  public onSave(): void {
    this.disableSave = true;

    if (this.editItem && this.qtyCache !== this.model.quantity) {
      this.model.lockQty = true;
    }

    this.saveSub = this.invoiceItemService.addEditJobSalesItem(this.model)
      .subscribe((x: boolean) => {
        if (x) {
          this.bsModalRef.hide();
          this.uiService.showSuccess('Item Saved', 'Success');
        } else {
          this.disableSave = false;
          this.uiService.showError('Item Not Saved', 'Error');
        }
      });
  }

  private canSeePrices(): boolean {
    return this.userService.user.value != null
      && this.userService.user.value.activeRoles != null
      ? this.userService.user.value.activeRoles.includes('General')
      : false;
  }

}
