import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, Observer } from 'rxjs';
import { WorksheetCategories } from 'src/enums/worksheet-categories';
import { WorksheetTypeModel } from 'src/models/worksheet-type.model';

@Injectable()
export class WorksheetTypeService {
  constructor(
    private http: HttpClient,
    @Inject('BASE_URL') private baseUrl: string,
    private router: Router
  ) { }

  public worksheetTypeList: BehaviorSubject<Array<WorksheetTypeModel>> = new BehaviorSubject(null);
  public worksheetType: BehaviorSubject<WorksheetTypeModel> = new BehaviorSubject(null);
  public WorksheetCategories = WorksheetCategories;
  public isLoading: boolean = false;
  public typeListIsLoading: boolean = false;

  private apiUrl = 'api/WorksheetType';  // URL to web api

  public addEditWorksheetType(
    dto: WorksheetTypeModel,
    showInactive: boolean,
    cacheWorksheetType: boolean = false): Observable<boolean> {
    dto = this.cleanWorksheetTypeModel(dto);
    return new Observable((observer: Observer<boolean>) => {
      this.http.post<number>(this.baseUrl + this.apiUrl + '/AddEditWorksheetType', dto)
        .subscribe((x: number) => {
          if (x !== -1 && dto.worksheetTypeId !== 0) {
            // Worksheet type is not new
            this.worksheetType.next(dto);
          } else {
            this.router.navigateByUrl(`/worksheet-types/details/${x}`);
          }
          observer.next(x !== -1);
        });
    });
  }

  public createCopyOfWorksheetType(id: number): Observable<boolean> {
    return new Observable((observer: Observer<boolean>) => {
      this.http.get<number>(this.baseUrl + this.apiUrl + '/CreateCopyOfWorksheetType/' + id)
        .subscribe((x: number) => {
          if (x !== -1) {
            this.router.navigateByUrl(`/worksheet-types/details/${x}`);
          }
          observer.next(x !== -1);
        });
    });
  }

  public deleteWorksheetType(
    dto: WorksheetTypeModel,
    showInactive: boolean): Observable<boolean> {
    return new Observable((observer: Observer<boolean>) => {
      this.http.get<boolean>(this.baseUrl + this.apiUrl + '/DeleteWorksheetType/' + dto.worksheetTypeId)
        .subscribe((x: boolean) => {
          this.refreshWorksheetTypes(showInactive, false);
          observer.next(x);
        });
    });
  }

  public getWorksheetTypeById(id: number): Observable<WorksheetTypeModel> {
    return this.http.get<WorksheetTypeModel>(this.baseUrl + this.apiUrl + '/GetWorksheetTypeById/' + id);
  }

  public refreshWorksheetType(id: number): void {
    this.isLoading = true;
    if (this.worksheetType.value && id != this.worksheetType.value.worksheetTypeId) {
      this.worksheetType.next(null);
    }

    this.getWorksheetTypeById(id)
      .subscribe((x: WorksheetTypeModel) => {
        this.worksheetType.next(x);
        this.isLoading = false;
      });
  }

  public getWorksheetTypes(
    showInactive: boolean,
    packersOnly: boolean,
    hasNoItemId: boolean = false): Observable<Array<WorksheetTypeModel>> {
    return this.http.get<Array<WorksheetTypeModel>>(this.baseUrl + this.apiUrl + '/GetWorksheetTypes/' + showInactive + '/' + packersOnly + '/' + hasNoItemId);
  }

  public refreshWorksheetTypes(
    showInactive: boolean,
    packersOnly: boolean,
    showSkeleton: boolean = false): void {
    if (showSkeleton) {
      this.typeListIsLoading = true;
    }

    this.getWorksheetTypes(showInactive, packersOnly)
      .subscribe((x: WorksheetTypeModel[]) => {
        this.worksheetTypeList.next(x);
        this.typeListIsLoading = false;
      });
  }

  public generateNewWorksheetTypeModel(): WorksheetTypeModel {
    let model: WorksheetTypeModel = new WorksheetTypeModel();
    model.worksheetTypeId = 0;
    model.worksheetCategoryId = this.WorksheetCategories.Anchor;
    model.isItemized = false;
    model.showIsCustomerProperty = false;
    model.billingCodeId = null;
    model.sortOrder = this.getNewSortNumber();
    model.isRebuild = true;
    model.isActive = true;

    return model;
  }

  private cleanWorksheetTypeModel(model: WorksheetTypeModel): WorksheetTypeModel {
    Object.keys(model).forEach((index: string) => {
      if (typeof model[index] === 'string') {
        model[index] = model[index]
          && model[index].trim() !== '' ?
          model[index].trim()
          : null;
      }
    });

    return model;
  }

  private getNewSortNumber(): number {
    let num =
      this.worksheetTypeList.value !== null &&
        this.worksheetTypeList.value.length > 0 ?
        Math.max.apply(Math, this.worksheetTypeList.value.map((x: WorksheetTypeModel) => {
          return x.sortOrder;
        })) : 0;

    return num + 1;
  }

}
