import { Component, OnDestroy, OnInit } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { JobTypes } from 'src/enums/job-types';
import { JobItemInstanceModel } from 'src/models/job-item-instance.model';
import { ItemInstanceService } from 'src/services/item-instance.service';
import { JobItemInstanceService } from 'src/services/job-item-instance.service';
import { UIService } from 'src/services/ui.service';
import { JobFishingItemModalTypes } from '../../../../enums/job-fishing-item-modal-types';
import { BillingCodeModel } from '../../../../models/billing-code.model';
import { ItemDropdownModel } from '../../../../models/item-dropdown.model';
import { ItemInstanceModel } from '../../../../models/item-instance.model';
import { ItemModel } from '../../../../models/item.model';
import { PurchaseOrderLineModel } from '../../../../models/purchase-order-line.model';
import { ShopItemModel } from '../../../../models/shop-item.model';
import { BillingCodeService } from '../../../../services/billing-code.service';
import { ItemService } from '../../../../services/item.service';
import { JobService } from '../../../../services/job.service';
import { PurchaseOrderLineService } from '../../../../services/purchase-order-line.service';
import { AddEditBillingCodeModalComponent } from '../../../billing-codes/add-edit-billing-code-modal/add-edit-billing-code-modal.component';

@Component({
  selector: 'app-add-edit-job-fishing-item-instance-modal',
  templateUrl: './add-edit-job-fishing-item-instance-modal.component.html',
  styleUrls: ['./add-edit-job-fishing-item-instance-modal.component.css']
})

export class AddEditJobFishingItemInstanceModalComponent implements OnInit, OnDestroy {
  public isNew: boolean = false;
  public id: number = 0;
  public jobId: number = 0;
  public jobTypeId: number = 0;
  public total: string = '';
  public type: number = null;
  public typeString: string = '';
  public billingCodes: Array<BillingCodeModel> = [];
  public poLines: Array<PurchaseOrderLineModel> = [];
  public disableSave: boolean = false;
  public itemIsLoaded: boolean = false;
  public currentInventory: ShopItemModel[] = [];
  public showQuantity: boolean = false;
  public showAdditionalDaysOnly: boolean = false;
  public showChargeAllMinimumDays: boolean = false;
  public missingItemChecked: boolean = false;
  public missingBillingCodeChecked: boolean = false;
  public missingItemInstanceChecked: boolean = false;
  public missingRentalItemChecked: boolean = false;
  public JobTypes = JobTypes;
  public JobFishingItemModalTypes = JobFishingItemModalTypes;

  private modelSub: Subscription;
  public model: JobItemInstanceModel = null;

  private iiListSub: Subscription;
  private tpListSub: Subscription;
  private poSub: Subscription;
  private bc_1_Sub: Subscription;
  private bc_2_Sub: Subscription;
  private itemList_1_Sub: Subscription;
  private itemList_2_Sub: Subscription;
  private iiListSub_1: Subscription;
  private iiListSub_2: Subscription;
  private tpListSub_1: Subscription;
  private tpListSub_2: Subscription;
  private itemCacheSub: Subscription;
  private saveSub: Subscription;

  constructor(
    public bsModalRef: BsModalRef,
    public bsModalRefChildModal: BsModalRef,
    private modalService: BsModalService,
    public uiService: UIService,
    public jiiService: JobItemInstanceService,
    public itemService: ItemService,
    public iiService: ItemInstanceService,
    public billingCodeService: BillingCodeService,
    public poLineService: PurchaseOrderLineService,
    public jobService: JobService

  ) { };

  ngOnDestroy(): void {
    this.bc_1_Sub?.unsubscribe();

    this.bc_2_Sub?.unsubscribe();

    this.iiListSub?.unsubscribe();

    this.itemList_1_Sub?.unsubscribe();

    this.itemList_2_Sub?.unsubscribe();

    this.iiListSub_1?.unsubscribe();

    this.iiListSub_2?.unsubscribe();

    this.itemCacheSub?.unsubscribe();

    this.tpListSub_1?.unsubscribe();

    this.tpListSub_2?.unsubscribe();

    this.modelSub?.unsubscribe();

    this.saveSub?.unsubscribe();

    this.poSub?.unsubscribe();

    this.tpListSub?.unsubscribe();
  }

  ngOnInit(): void {
    this.isNew = this.id === 0;

    this.getLists(this.type);
    this.setTypeString(this.type);

    if (!this.isNew) {
      this.getModel();
    } else {
      this.model = this.jiiService.generateNewJobItemModel(
        this.jobId,
        this.jobTypeId,
        this.type);
    }

    if (this.type === this.JobFishingItemModalTypes.Serialized) {
      this.subscribeToItemInstances();
    }

    if (this.type === this.JobFishingItemModalTypes.Inventory
      || this.type === this.JobFishingItemModalTypes.Miscellaneous) {
      this.subscribeToBillingCodes();
      this.subscribeToItemList();
    }

    if (this.type === this.JobFishingItemModalTypes.ThirdParty) {
      this.subscribeToThirdPartyItemList();
    }
  }

  public getModel(): void {
    this.modelSub = this.jiiService.getJobItemInstanceById(this.id)
      .subscribe((x: JobItemInstanceModel) => {
        this.model = x;
        this.showQuantity = this.model.qty !== undefined && this.model.qty !== null;
        this.subscribeToBillingCodes();
        if (this.type === this.JobFishingItemModalTypes.Serialized) {
          this.determineItemInstanceShowAdditionalDayChoices();
        }
        if (this.type === this.JobFishingItemModalTypes.ThirdParty) {
          this.determineThirdPartyShowAdditionalDayChoices();
        }
      });
  }

  public determineItemInstanceShowAdditionalDayChoices(): void {
    this.iiListSub = this.iiService.itemInstanceList
      .subscribe((x: ItemInstanceModel[]) => {
        if (x !== null) {
          let ii: ItemInstanceModel[] = x.filter((x: ItemInstanceModel) => x.itemInstanceId === this.model.itemInstanceId);

          if (ii && ii.length === 1) {
            this.showAdditionalDaysOnly = ii[0].showAdditionalDaysOnly;
            this.showChargeAllMinimumDays = ii[0].showChargeAllMinimumDays;
          }
        }
      });
  }

  public determineThirdPartyShowAdditionalDayChoices(): void {
    this.tpListSub = this.itemService.rentalItemList
      .subscribe((x: ItemModel[]) => {
        if (x !== null) {
          let item: ItemModel[] = x.filter((x: ItemModel) => x.itemId === this.model.itemId);

          if (item && item.length === 1) {
            this.showAdditionalDaysOnly = item[0].showAdditionalDaysOnly;
            this.showChargeAllMinimumDays = item[0].showChargeAllMinimumDays;
          }
        }
      });
  }

  public onChangeOfSold($event: boolean): void {
    if ($event !== undefined) {
      this.poLines.length = 0;
      $event === true ? this.getPoLines() : null;
      this.model.sold = $event;
      this.model.soldQty = $event === true ? 1 : null;
      this.model.price = null;
    }
  }

  public onChangeOfChargeMinimumDays($event: boolean): void {
    if ($event !== undefined) {
      this.model.chargeMinimumDays = $event;
    }
  }

  public onChangeOfDoNotCharge($event: boolean): void {
    if ($event !== undefined) {
      this.model.doNotCharge = $event;
    }
  }

  public onChangeOfAdditionalRateOnly($event: boolean): void {
    if ($event !== undefined) {
      this.model.additionalRateOnly = $event;
      this.model.chargeMinimumDays = $event === true ? false : this.model.chargeMinimumDays;
    }
  }

  public getPoLines(): void {
    this.poSub = this.poLineService.getLastReceivedPoLinesByItem(null, this.model.itemInstanceId)
      .subscribe((x: PurchaseOrderLineModel[]) => {
        this.poLines = x;
      });
  }

  public onChangeOfItem($event: ItemDropdownModel): void {
    if ($event !== undefined) {
      this.itemIsLoaded = true;
      this.currentInventory = $event.shopItems !== undefined && $event.shopItems !== null ? $event.shopItems : [];
    } else {
      this.itemIsLoaded = false;
      this.currentInventory = [];
    }

    this.model.billingCodeId = null;
    this.billingCodes.length = 0;
    if ($event !== undefined) {
      this.filterBillingCodes($event.itemId);
    }
  }

  public onChangeOfThirdParty($event: ItemModel): void {
    if ($event !== undefined) {
      this.showAdditionalDaysOnly = $event.showAdditionalDaysOnly;
      this.showChargeAllMinimumDays = $event.showChargeAllMinimumDays;
    } else {
      this.showAdditionalDaysOnly = false;
      this.showChargeAllMinimumDays = false;
    }
  }

  // this subscribe exists to remove a race condition where there would not yet be values in billingCodeList when the modal renders
  public subscribeToBillingCodes(): void {
    this.billingCodeService.billingCodeList.next(null);

    this.bc_1_Sub = this.billingCodeService.billingCodeList
      .subscribe((x: BillingCodeModel[]) => {
        if (x !== null
          && x !== undefined) {
          if (this.type === this.JobFishingItemModalTypes.Inventory) {
            let filter = x.filter((x: BillingCodeModel) => {
              return x.itemId === this.model.itemId;
            });
            if (filter.length === 0
              && this.model
              && this.model.itemId
              && !this.missingBillingCodeChecked) {
              this.bc_2_Sub = this.billingCodeService.getBillingCodesByItemId(
                this.model.itemId,
                true)
                .subscribe((y: BillingCodeModel[]) => {
                  this.missingBillingCodeChecked = true;

                  if (y !== undefined
                    && y !== null) {
                    this.billingCodeService.billingCodeList.next([...this.billingCodeService.billingCodeList.value, ...y]);
                  }
                });
            } else {
              this.filterBillingCodes(this.model.itemId);
            }
            if (this.jobTypeId === this.JobTypes.FishingToolRental) {
              this.calculateInventoryTotal();
            }
          }
        }
      });
  }

  public subscribeToItemList(): void {
    this.itemList_1_Sub = this.itemService.itemDropdownList
      .subscribe((x: ItemDropdownModel[]) => {
        if (x !== null
          && x !== undefined) {
          let filter: ItemDropdownModel[] = x.filter((x: ItemDropdownModel) => {
            return x.itemId == this.model.itemId;
          });

          if (filter.length === 0
            && this.model
            && this.model.itemId
            && !this.missingItemChecked) {
            this.itemList_2_Sub = this.itemService.getItemById(this.model.itemId)
              .subscribe((y: ItemModel) => {
                this.missingItemChecked = true;
                if (y !== null && y !== undefined) {
                  let newDropdown = this.itemService.convertItemToDropdown(y);
                  this.itemService.itemDropdownList.next([...this.itemService.itemDropdownList.value, newDropdown]);
                }
              });
          }
        }
      });
  }

  public subscribeToItemInstances(): void {
    this.iiService.itemInstanceList.next(null);

    this.iiListSub_1 = this.iiService.itemInstanceList
      .subscribe((x: ItemInstanceModel[]) => {
        if (x !== null
          && x !== undefined) {
          let filter: ItemInstanceModel[] = x.filter((x: ItemInstanceModel) => {
            return x.itemInstanceId == this.model.itemInstanceId;
          });

          if (filter.length === 0
            && this.model
            && this.model.itemInstanceId
            && !this.missingItemInstanceChecked) {
            this.iiListSub_2 = this.iiService.getItemInstanceById(this.model.itemInstanceId)
              .subscribe((y: ItemInstanceModel) => {
                this.missingItemInstanceChecked = true;
                if (y !== null && y !== undefined) {
                  this.iiService.itemInstanceList.next([...this.iiService.itemInstanceList.value, y]);
                }
              });
          }
        }
      });
  }

  public subscribeToThirdPartyItemList(): void {
    this.itemService.rentalItemList.next(null);

    this.tpListSub_1 = this.itemService.rentalItemList
      .subscribe((x: ItemModel[]) => {
        if (x !== null
          && x !== undefined) {
          let filter: ItemModel[] = x.filter((x: ItemModel) => {
            return x.itemId == this.model.itemId;
          });

          if (filter.length === 0
            && this.model
            && this.model.itemId
            && !this.missingRentalItemChecked) {
            this.tpListSub_2 = this.itemService.getItemById(this.model.itemId)
              .subscribe((y: ItemModel) => {
                this.missingItemChecked = true;
                if (y !== null && y !== undefined) {
                  this.itemService.rentalItemList.next([...this.itemService.rentalItemList.value, y]);
                }
              });
          }
        }
      });
  }

  public openBillingCodeModal(billingCodeId: number): void {
    const initialState = {
      id: billingCodeId,
      itemId: this.model.itemId,
      showInactive: false,
      cacheBillingCode: true,
      isInventoryItem: true
    };
    this.bsModalRefChildModal = this.modalService.show(AddEditBillingCodeModalComponent, { initialState, backdrop: 'static' });
  }

  // THIS CODE REPEATS IN MANY PLACES NEED TO CENTRALIZE
  public filterBillingCodes(itemId: number): void {
    let job = this.jobService.job.value;

    if (this.type != this.JobFishingItemModalTypes.Miscellaneous) {
      this.billingCodes = this.billingCodeService.billingCodeList.value !== null ?
        (this.billingCodeService.billingCodeList.value.filter((x: BillingCodeModel) => {
          return x.itemId == itemId
            && x.itemId !== null
            && (x.customerId === null
              || (job !== null
                && x.customerId === job.customerId));
        }))
        : [];

      let currentBillingCode: BillingCodeModel[] = this.billingCodes.filter((x: BillingCodeModel) => {
        return x.billingCodeId === this.model.billingCodeId;
      });
      // if the current billing code id exists in the list of filtered billing codes, the billing code is not changed.
      if (currentBillingCode.length === 0) {
        let customerSpecificCode = job !== null ? this.billingCodes.filter((x: BillingCodeModel) => {
          return x.customerId === job.customerId;
        })
          : [];
        // The initially selected billing code will look first for a customer specific code, then go to the first in the array if one doesn't exist
        this.model.billingCodeId = this.billingCodes.length > 0 ?
          customerSpecificCode.length > 0 ?
            customerSpecificCode[0].billingCodeId
            : this.billingCodes[0].billingCodeId
          : null;
        this.calculateTotal();
      }
    } else {
      // retrieve only billing codes without itemIds for misc items
      this.billingCodes = this.billingCodeService.billingCodeList.value !== null ?
        (this.billingCodeService.billingCodeList.value.filter((x: BillingCodeModel) => {
          return x.itemId === null;
        }))
        : [];
    }
  }

  public calculateTotal(): void {
    if (this.model.billingCodeId !== null && this.model.billingCodeId !== undefined && this.billingCodes.length > 0) {
      let rate: number = this.billingCodes.filter((x: BillingCodeModel) => {
        return x.billingCodeId === this.model.billingCodeId;
      })[0].rate;
      this.total = rate != undefined ? rate.toFixed(2) : '';
    } else {
      this.total = '';
    }
  }

  public calculateInventoryTotal(): void {
    if (this.model.billingCodeId !== null && this.model.billingCodeId !== undefined && this.billingCodes.length > 0) {
      let rate: number = this.billingCodes.filter((x: BillingCodeModel) => {
        return x.billingCodeId === this.model.billingCodeId;
      })[0].rate;
      this.total = rate != undefined ? (rate * this.model.qty).toFixed(2) : '';
    } else {
      this.total = '';
    }
  }

  public onChangeOfBillingCode($event: BillingCodeModel): void {
    if ($event !== undefined) {
      this.model.billingCodeId = $event.billingCodeId;
    } else {
      this.model.billingCodeId = null;
    }
    this.calculateTotal();
  }

  public onChangeItemInstance($event: ItemInstanceModel): void {
    if ($event !== undefined) {
      this.model.itemInstanceId = $event.itemInstanceId;
      this.model.quantityUom = $event.quantityUom !== undefined ? $event.quantityUom : null;
      this.model.qty = $event.requiresQuantity === true
        && this.model.qty !== null
        && this.model.qty !== undefined ? this.model.qty : null;

      this.showQuantity = $event.requiresQuantity
        && $event.quantityUom !== undefined
        && $event.quantityUom !== null
        && (($event.quantityUom.toLowerCase() !== 'each'
          && $event.quantityUom.toLowerCase() !== 'ft') ||
          (($event.quantityUom.toLowerCase() === 'each'
            || $event.quantityUom.toLowerCase() === 'ft')
            && this.jobTypeId === this.JobTypes.FishingToolRental));

      this.showAdditionalDaysOnly = $event.showAdditionalDaysOnly;
      this.showChargeAllMinimumDays = $event.showChargeAllMinimumDays;
    } else {
      this.model.itemInstanceId = null;
      this.model.quantityUom = null;
      this.model.qty = null;
      this.showQuantity = false;
      this.showAdditionalDaysOnly = false;
      this.showChargeAllMinimumDays = false;

      this.model.chargeMinimumDays = true;
      this.model.additionalRateOnly = false;
    }
  }

  public setTypeString(type: number): void {
    switch (type) {
      case this.JobFishingItemModalTypes.Serialized:
        this.typeString = 'Serialized';
        break;
      case this.JobFishingItemModalTypes.Inventory:
        this.typeString = 'Inventory';
        break;
      case this.JobFishingItemModalTypes.ThirdParty:
        this.typeString = 'Third Party';
        break;
      case this.JobFishingItemModalTypes.Miscellaneous:
        this.typeString = 'Miscellaneous';
        break;
    }
  }

  public getLists(type: number): void {
    switch (type) {
      case this.JobFishingItemModalTypes.Serialized:
        this.iiService.getItemInstances(!this.isNew);
        break;
      case this.JobFishingItemModalTypes.Inventory:
        this.itemService.getItemsDropdown(false);
        this.billingCodeService.getBillingCodes(false, true, false, true);
        break;
      case this.JobFishingItemModalTypes.ThirdParty:
        this.itemService.refreshThirdPartyRentalList();
        break;
      case this.JobFishingItemModalTypes.Miscellaneous:
        this.billingCodeService.getBillingCodes(false, true, false, true);
      default:
        this.typeString = '';
    }
  }

  public onSave(): void {
    this.disableSave = true;

    if (this.type === this.JobFishingItemModalTypes.Inventory) {
      this.model.numberOfDays = null;
    }

    if (this.model.sold !== true) {
      this.model.price = null;
    }

    this.saveSub = this.jiiService.addEditJobItemInstance(this.model)
      .subscribe((x: boolean) => {
        if (x) {
          this.bsModalRef.hide();
          this.uiService.showSuccess('Item Saved', 'Success');
        } else {
          this.disableSave = false;
          this.uiService.showError('Item Not Saved', 'Error');
        }
      });
  }

}
