import { Component, OnDestroy, OnInit } from '@angular/core';
import { ExcelExportData } from '@progress/kendo-angular-excel-export';
import { RowClassArgs } from '@progress/kendo-angular-grid';
import { process } from '@progress/kendo-data-query';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { ItemTypes } from 'src/enums/item-types';
import { ItemTypeModel } from 'src/models/item-type.model';
import { UnitOfMeasureModel } from 'src/models/unit-of-measure.model';
import { AppStateService } from 'src/services/app-state.service';
import { ItemTypeService } from 'src/services/item-type.service';
import { ItemService } from 'src/services/item.service';
import { KGridService } from 'src/services/k-grid.service';
import { PartFamilyService } from 'src/services/part-family.service';
import { PartGroupService } from 'src/services/part-group.service';
import { UIService } from 'src/services/ui.service';
import { UnitOfMeasureService } from 'src/services/unit-of-measure.service';
import { VendorService } from 'src/services/vendor.service';
import { AddEditItemModalComponent } from '../add-edit-item-modal/add-edit-item-modal.component';
import { PartFamilyModel } from 'src/models/part-family.model';

@Component({
  selector: 'app-items-list',
  templateUrl: './list.component.html',
  styleUrls: ['list.component.css']
})

export class ItemListComponent implements OnInit, OnDestroy {
  private unitsOfMeasureSub: Subscription;
  public unitsOfMeasure: UnitOfMeasureModel[];

  private itemTypesSub: Subscription;
  public itemTypes: ItemTypeModel[];

  public showInactive: boolean = false;
  public showFilter = true;
  public numberOfActiveFilterFields: number = 0;
  public ItemTypes = ItemTypes;
  public lookbackDate: Date;

  private isTabletSub: Subscription;
  public isTablet: boolean = false;

  private deleteSub: Subscription;

  constructor(
    private modalService: BsModalService,
    public itemService: ItemService,
    public itemTypeService: ItemTypeService,
    public kGridService: KGridService,
    public vendorService: VendorService,
    public uomService: UnitOfMeasureService,
    public partFamilyService: PartFamilyService,
    public partGroupService: PartGroupService,
    public appStateService: AppStateService,
    public uiService: UIService,
  ) {
    this.allData = this.allData.bind(this);
  };

  public bsModalRef: BsModalRef;

  ngOnDestroy(): void {
    this.kGridService.resetKGridParams();

    this.deleteSub?.unsubscribe();

    this.isTabletSub?.unsubscribe();

    this.itemTypesSub?.unsubscribe();

    this.unitsOfMeasureSub?.unsubscribe();
  }

  ngOnInit(): void {
    this.itemService.getItems(
      this.showInactive,
      true,
      false,
      true,
      true,
      true);

    this.vendorService.getVendors(false, true);

    this.getUnitsOfMeasure();

    this.partFamilyService.refreshPartFamilyList(
      false,
      true,
      false);

    this.partGroupService.partGroupList.next(null);

    this.getItemTypes();

    this.determineIsTablet();

    if (this.itemService.itemFilterParams.partFamilyId !== null
      && this.itemService.itemFilterParams.partFamilyId !== undefined) {
      this.partGroupService.getPartGroupsByFamilyId(
        this.itemService.itemFilterParams.partFamilyId,
        false,
        true);
    }

    if (this.itemService.itemFilterParams.lookbackInDays !== null
      && this.itemService.itemFilterParams.lookbackInDays !== undefined) {
      this.calculateLookbackDate(this.itemService.itemFilterParams.lookbackInDays);
    }
  }

  public getUnitsOfMeasure(): void {
    this.unitsOfMeasureSub = this.uomService.uomList$
      .subscribe((unitsOfMeasure: UnitOfMeasureModel[]) => {
        if (unitsOfMeasure) {
          this.unitsOfMeasure = unitsOfMeasure;
        }
      });
  }

  public getItemTypes(): void {
    this.itemTypesSub = this.itemTypeService.itemTypeList$
      .subscribe((itemTypes: ItemTypeModel[]) => {
        if (itemTypes) {
          this.itemTypes = itemTypes;
        }
      });
  }

  public determineIsTablet(): void {
    this.isTabletSub = this.appStateService.isTablet$
      .subscribe((isTablet: boolean) => {
        this.isTablet = isTablet;
      });
  }

  public openItemModal(itemId: number): void {
    const initialState = {
      id: itemId,
      showInactive: this.showInactive,
      showThirdPartRental: true
    };

    this.bsModalRef = this.modalService.show(AddEditItemModalComponent, { initialState, class: 'modal-xl', backdrop: 'static' });
  }

  public onChangeOfLookback() {
    if (this.itemService.itemFilterParams.lookbackInDays !== null
      && this.itemService.itemFilterParams.lookbackInDays !== undefined) {
      this.calculateLookbackDate(this.itemService.itemFilterParams.lookbackInDays);
    }
  }

  public calculateLookbackDate(lookbackInDays: number): void {
    let newDate = new Date();

    newDate.setDate(newDate.getDate() - lookbackInDays);

    this.lookbackDate = newDate;
  }

  public deleteItem(id: number): void {
    this.deleteSub = this.itemService.deleteItem(id, this.showInactive)
      .subscribe((x: boolean) => {
        if (x) {
          this.uiService.showSuccess('Success', 'Item Deleted');
        } else {
          this.uiService.showError('Error', 'Item Not Deleted');
        }
      });
  }

  public getNumberOfActiveFilterFields(): void {
    if (!this.showFilter) {
      this.numberOfActiveFilterFields = this.itemService.getNumberOfActiveFilterFields();
    } else {
      this.numberOfActiveFilterFields = 0;
    }
  }

  public onChangeShowInactive($event: boolean): void {
    this.showInactive = $event;

    this.itemService.getItems(
      this.showInactive,
      true,
      false,
      true,
      true);
  }

  public onChangePartFamily($event: PartFamilyModel): void {
    this.itemService.itemFilterParams.partGroupId = null;

    if ($event !== undefined && $event !== null) {
      this.partGroupService.getPartGroupsByFamilyId(
        $event.partFamilyId,
        false,
        true);
    } else {
      this.partGroupService.partGroupList.next(null);
    }
  }

  public allData(): ExcelExportData {
    let sev = this.itemService.itemList.value;

    const result: ExcelExportData = {
      data: process(sev, { group: null, sort: null }).data,
      group: null
    };

    return result;
  }

  public rowCallback(context: RowClassArgs): any {
    return {
      blue: context.dataItem.itemTypeId === ItemTypes.ThirdParty,
      peach: context.dataItem.itemTypeId === ItemTypes.Build,
      inactive: !context.dataItem.isActive
    };
  }

}
