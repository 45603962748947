import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Observer } from 'rxjs';
import { EmployeeModel } from '../models/employee.model';

@Injectable()
export class EmployeeService {
  constructor(
    private http: HttpClient,
    @Inject('BASE_URL') private baseUrl: string
  ) { }

  public employeeList: BehaviorSubject<Array<EmployeeModel>> = new BehaviorSubject(null);
  public employeeListIsLoaded: boolean = false;

  private apiUrl = 'api/Employee';  // URL to web api

  public addEditEmployee(
    dto: EmployeeModel,
    showInactive: boolean): Observable<boolean> {
    dto = this.cleanEmployeeModel(dto);
    return new Observable((observer: Observer<boolean>) => {
      this.http.post<boolean>(this.baseUrl + this.apiUrl + '/AddEditEmployee', dto)
        .subscribe((x: boolean) => {
          if (x === true) {
            this.getEmployees(showInactive, true);
          }
          observer.next(x);
        });
    });
  }

  public deleteEmployee(
    id: number,
    showInactive: boolean): Observable<boolean> {
    return new Observable((observer: Observer<boolean>) => {
      this.http.get<boolean>(this.baseUrl + this.apiUrl + '/DeleteEmployee/' + id)
        .subscribe((x: boolean) => {
          if (x === true) {
            this.getEmployees(showInactive, true);
          }
          observer.next(x);
        });
    });
  }

  public getEmployees(
    showInactive: boolean,
    clear: boolean = false,
    showSkeleton: boolean = false): void {
    if (!this.employeeList.value || clear) {
      if (showSkeleton) {
        this.employeeListIsLoaded = false;
      }

      this.http.get<Array<EmployeeModel>>(this.baseUrl + this.apiUrl + '/GetEmployees/' + showInactive)
        .subscribe((x: EmployeeModel[]) => {
          this.employeeList.next(x);
          this.employeeListIsLoaded = true;
        });
    }
  }

  public getEmployeeById(id: number): Observable<EmployeeModel> {
    return this.http.get<EmployeeModel>(this.baseUrl + this.apiUrl + '/GetEmployeeById/' + id);
  }

  public generateNewEmployeeModel(): EmployeeModel {
    let model: EmployeeModel = new EmployeeModel();
    model.isActive = true;

    return model;
  }

  private cleanEmployeeModel(model: EmployeeModel): EmployeeModel {
    Object.keys(model).forEach((index: string) => {
      if (typeof model[index] === 'string') {
        model[index] = model[index]
          && model[index].trim() !== '' ?
          model[index].trim()
          : null;
      }
    });

    return model;
  }

}
