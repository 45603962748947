<div class="card border-top-primary border-top-2 rounded-top-0"
  style="border-top-color: #2196F3 !important;">
  <div class="card-header bg-light header-elements-sm-inline">
    <h6 class="card-title">Builds</h6>
    <div class="header-elements"
      *ngIf="showExtendedControls">
      <div class="switch-container pt-2 mr-2">
        <span class="switch-label">Show On Job </span>
        <ui-switch [ngModel]="showOnJob"
          (change)="onChangeOnJob($event)"></ui-switch>
      </div>
      <button class="btn btn-light btn-sm"
        (click)="openChooseWorksheetModal()"><i class="fas fa-plus"></i> Add Build</button>
    </div>
  </div>
  <app-table-skeleton *ngIf="worksheetService.packerListIsLoading"></app-table-skeleton>
  <div class="alert alert-primary ml-3 mr-3 mt-3"
    role="alert"
    *ngIf="!worksheetService.packerListIsLoading 
            &&  (!worksheetService.packerList 
                || !worksheetService.packerList.value 
                || worksheetService.packerList.value.length === 0)">
    <i class="fad fa-check"></i>&nbsp;&nbsp;There are currently no <strong>Builds</strong> that have not been
    associated with a Job.
  </div>
  <ng-container *ngIf="worksheetService.packerList 
              && worksheetService.packerList.value 
              && worksheetService.packerList.value.length > 0
              && !worksheetService.packerListIsLoading">
    <div class="table-responsive"
      *ngIf="worksheetService.packerList | async; let model">
      <kendo-grid [style.maxHeight.px]="640"
        [kendoGridBinding]="model"
        [filterable]="'menu'"
        [sortable]="true"
        [resizable]="true"
        [rowClass]="rowCallback"
        [selectable]="selectableSettings"
        (selectedKeysChange)="selectedKeysChange($event)"
        [kendoGridSelectBy]="'worksheetId'"
        [selectedKeys]="selectedIds"
        [pageSize]="kGridService.kGridParams.pageSize"
        [skip]="kGridService.kGridParams.skip"
        (pageChange)="kGridService.pageChange($event)"
        [pageable]="{
                              buttonCount: kGridService.kGridParams.buttonCount,
                              info: kGridService.kGridParams.info,
                              type: kGridService.kGridParams.type,
                              pageSizes: kGridService.kGridParams.pageSizes,
                              previousNext: kGridService.kGridParams.previousNext
                            }">
        <ng-template kendoGridToolbarTemplate>
          <button type="button"
            kendoGridExcelCommand
            icon="file-excel">Export to Excel</button>
          <button class="btn btn-light label-button"
            (click)="printLabelData($event)"><i class="fal fa-tags"></i>&nbsp;&nbsp;Print&nbsp;<span
              *ngIf="selectedIds && selectedIds.length === 0">Unprinted</span><span
              *ngIf="selectedIds && selectedIds.length > 0">Selected</span>&nbsp;Label Data</button>
        </ng-template>
        <kendo-grid-checkbox-column [width]="40"
          [class]="{ 'text-center': true }"
          [headerClass]="{ 'text-center': true }"
          [showSelectAll]="true"></kendo-grid-checkbox-column>
        <kendo-grid-column field="one"
          title=""
          [width]="45"
          [filterable]="false">
          <ng-template kendoGridCellTemplate
            let-dataItem
            let-rowIndex="rowIndex">
            <button class="btn btn-xs btn-light"
              (click)="openWorksheetModal(dataItem.worksheetId)"
              *ngIf="!dataItem.jobId"><i class="fa fa-edit"></i></button>
            <button class="btn btn-xs btn-light"
              *ngIf="dataItem.jobId"
              disabled><i class="fa fa-edit"></i></button>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="two"
          title=""
          [width]="35"
          [filterable]="false">
          <ng-template kendoGridCellTemplate
            let-dataItem
            let-rowIndex="rowIndex">
            <span *ngIf="dataItem.hasBeenPrinted"
              class="print-icon text-success"
              title="Has Been Printed"><i class="fa-duotone fa-print"></i></span>
            <span *ngIf="!dataItem.hasBeenPrinted"
              class="print-icon text-danger"
              title="Has Not Been Printed"><i class="fa-duotone fa-print-slash"></i></span>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="worksheetId"
          [width]="60"
          title="ID">
        </kendo-grid-column>
        <kendo-grid-column field="createDate"
          [width]="70"
          title="Date">
          <ng-template kendoGridCellTemplate
            let-dataItem
            let-rowIndex="rowIndex">
            {{dataItem.createDate | date:'shortDate'}}
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="worksheetTypeName"
          [width]="showExtendedControl ? 300 : 200"
          title="Type">
        </kendo-grid-column>
        <kendo-grid-column field="notes"
          title="Notes"
          [width]="showExtendedControl ? 250 : 175">
        </kendo-grid-column>
        <kendo-grid-column field="customerName"
          [width]="105"
          title="Customer">
        </kendo-grid-column>
        <kendo-grid-column field="employeeName"
          [width]="80"
          title="Builder"
          *ngIf="showExtendedControls">
        </kendo-grid-column>
        <kendo-grid-column field="costStamp"
          title="Cost"
          [width]="70"
          *ngIf="userService.user.value && userService.user.value.activeRoles && userService.user.value.activeRoles.includes('General')">
          <ng-template kendoGridCellTemplate
            let-dataItem
            let-rowIndex="rowIndex">
            <span *ngIf="dataItem.costStamp">{{dataItem.costStamp | currency}}</span>
            <span *ngIf="!dataItem.costStamp"
              class="text-muted">n/a</span>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="priceStamp"
          title="Price"
          [width]="showExtendedControls ? 70 : 80"
          *ngIf="userService.user.value && userService.user.value.activeRoles && userService.user.value.activeRoles.includes('General')">
          <ng-template kendoGridCellTemplate
            let-dataItem
            let-rowIndex="rowIndex">
            <span *ngIf="dataItem.priceStamp">{{dataItem.priceStamp | currency}}</span>
            <span *ngIf="!dataItem.priceStamp"
              class="text-muted">n/a</span>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="jobNumber"
          [width]="70"
          title="Job #"
          *ngIf="showExtendedControls">
          <ng-template kendoGridCellTemplate
            let-dataItem
            let-rowIndex="rowIndex">
            <span class="text-muted"
              *ngIf="!dataItem.jobId">n/a</span>
            <span *ngIf="!userIsBuilderOnly && dataItem.jobId && dataItem.jobTypeId === JobTypes.Packer"><a class="link"
                [routerLink]="['/jobs/packer/details', dataItem.jobId]">Job #{{dataItem.jobNumber}}</a></span>
            <span *ngIf="!userIsBuilderOnly && dataItem.jobId && dataItem.jobTypeId === JobTypes.Sales"><a class="link"
                [routerLink]="['/jobs/sales/details', dataItem.jobId]">Job #{{dataItem.jobNumber}}</a></span>
            <span *ngIf="userIsBuilderOnly && dataItem.jobId">Job #{{dataItem.jobNumber}}</span>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="one"
          title=""
          [width]="45"
          [filterable]="false">
          <ng-template kendoGridCellTemplate
            let-dataItem
            let-rowIndex="rowIndex">
            <button class="btn btn-xs btn-light"
              (click)="openSnapshotModal(dataItem.worksheetId)"
              *ngIf="dataItem.hasSnapshot"
              title="Snapshots"><i class="fad fa-camera"></i></button>
            <button class="btn btn-xs btn-light"
              *ngIf="!dataItem.hasSnapshot"
              disabled><i class="fad fa-camera"></i></button>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="one"
          title=""
          [width]="45"
          [filterable]="false">
          <ng-template kendoGridCellTemplate
            let-dataItem
            let-rowIndex="rowIndex">
            <button class="btn btn-xs btn-light"
              [swal]="{ title: 'Delete Worksheet ' + dataItem.worksheetId + '?', text: 'This cannot be undone!', showCancelButton: true, confirmButtonText: 'Delete', confirmButtonColor: '#DC3545' }"
              (confirm)="deleteWorksheet(dataItem)"
              [disabled]="dataItem.jobId"><i class="
                        fa
                        fa-trash"></i></button>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-excel fileName="Grizzly - Packers.xlsx"
          [fetchData]="allData">
          <kendo-excelexport-column field="worksheetId"
            title="Worksheet Id">
          </kendo-excelexport-column>
          <kendo-excelexport-column field="description"
            title="Type">
          </kendo-excelexport-column>
          <kendo-excelexport-column field="notes"
            title="Notes">
          </kendo-excelexport-column>
          <kendo-excelexport-column field="customerName"
            title="Customer">
          </kendo-excelexport-column>
          <kendo-excelexport-column field="employeeName"
            title="Builder">
          </kendo-excelexport-column>
          <kendo-excelexport-column field="costStamp"
            title="Cost"
            *ngIf="userService.user.value && userService.user.value.activeRoles && userService.user.value.activeRoles.includes('General')">
          </kendo-excelexport-column>
          <kendo-excelexport-column field="priceStamp"
            title="Price"
            *ngIf="userService.user.value && userService.user.value.activeRoles && userService.user.value.activeRoles.includes('General')">
          </kendo-excelexport-column>
          <kendo-excelexport-column field="jobNumber"
            title="Job #">
          </kendo-excelexport-column>
        </kendo-grid-excel>
      </kendo-grid>
    </div>
  </ng-container>
</div>
