import { BehaviorSubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ItemInstanceLocationHistoryModel } from 'src/models/item-instance-location-history.model';
import { tap } from 'rxjs/operators';

@Injectable()
export class ItemInstanceLocationHistoryService {
  constructor(private http: HttpClient, @Inject('BASE_URL') private baseUrl: string) { }
  public iilhList: BehaviorSubject<Array<ItemInstanceLocationHistoryModel>> = new BehaviorSubject(null);

  private apiUrl = 'api/ItemInstanceLocationHistory';  // URL to web api

  public getLocationHistoryByItemInstanceId(id: number): void {
    this.http.get<Array<ItemInstanceLocationHistoryModel>>(this.baseUrl + this.apiUrl + '/GetLocationHistoryByItemInstanceId/' + id)
      .pipe(tap((x: ItemInstanceLocationHistoryModel[]) => {
        x.forEach((h: ItemInstanceLocationHistoryModel) => {
          h.changedOn = h.changedOn !== undefined && h.changedOn !== null ? new Date(h.changedOn) : null;
        });
      }))
      .subscribe((x: ItemInstanceLocationHistoryModel[]) => {
        this.iilhList.next(x);
      });
  }

}
