import { Component, OnDestroy, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { PartGroups } from 'src/enums/part-groups';
import { ItemInstanceLocationModel } from 'src/models/item-instance-location.model';
import { ItemInstanceModel } from 'src/models/item-instance.model';
import { ShopModel } from 'src/models/shop.model';
import { ThreadTypeModel } from 'src/models/thread-type.model';
import { ItemInstanceLocationService } from 'src/services/item-instance-location.service';
import { ItemInstanceService } from 'src/services/item-instance.service';
import { ItemService } from 'src/services/item.service';
import { ThreadTypeService } from 'src/services/thread-type.service';
import { UIService } from 'src/services/ui.service';
import { ShopService } from '../../../services/shop.service';
import { MaintenanceDurationUoms } from 'src/enums/maintenance-duration-uoms';
import { MaintenanceDurationUomModel } from 'src/models/maintenance-duration-uom.model';
import { MaintenanceDurationUomService } from 'src/services/maintenance-duration-uom.service';

@Component({
  selector: 'app-add-edit-item-instance-modal',
  templateUrl: './add-edit-item-instance-modal.component.html',
  styleUrls: ['./add-edit-item-instance-modal.component.css']
})

export class AddEditItemInstanceModalComponent implements OnInit, OnDestroy {
  private shopsSub: Subscription;
  public shops: ShopModel[];

  private maintenanceUomsSub: Subscription;
  public maintenanceUoms: MaintenanceDurationUomModel[];

  private threadTypesSub: Subscription;
  public threadTypes: ThreadTypeModel[];

  private modelSub: Subscription;
  public model: ItemInstanceModel;

  private saveSub: Subscription;
  private snSub: Subscription;

  public isNew: boolean = false;
  public id: number = -1;
  public itemId: number = -1;
  public partGroupId: number = null;
  public launchedFromItemDetail: boolean = false;
  public PartGroups = PartGroups;
  public disableSave: boolean = false;

  public fnIdNa: boolean = false;
  public fnOdNa: boolean = false;
  public fnLengthNa: boolean = false;

  constructor(
    public bsModalRef: BsModalRef,
    public itemInstanceService: ItemInstanceService,
    public iilService: ItemInstanceLocationService,
    public uiService: UIService,
    public itemService: ItemService,
    public shopService: ShopService,
    public threadTypeService: ThreadTypeService,
    public maintenanceDurationUomService: MaintenanceDurationUomService

  ) { };

  ngOnDestroy(): void {
    this.shopsSub?.unsubscribe();

    this.threadTypesSub?.unsubscribe();

    this.maintenanceUomsSub?.unsubscribe();

    this.modelSub?.unsubscribe();

    this.saveSub?.unsubscribe();

    this.snSub?.unsubscribe();
  }

  ngOnInit(): void {
    this.isNew = this.id === 0;

    this.getShops();

    this.getThreadTypes();

    this.getMaintenanceDurationUoms();

    this.iilService.getItemInstanceLocations(false);

    this.itemService.getItemsDropdown(
      false,
      false,
      true,
      false);

    if (this.isNew) {
      this.getNewSerialNumber();

      this.model = this.itemInstanceService.generateNewItemInstanceModel(this.itemId, null, this.partGroupId);
    } else {
      this.getModel();
    }
  }

  public getNewSerialNumber(): void {
    this.snSub = this.itemInstanceService.getSingleNewSerialNumber(this.partGroupId, this.itemId)
      .subscribe((x: string) => {
        this.model.serialNumber = x;
      });
  }

  public getThreadTypes(): void {
    this.threadTypesSub = this.threadTypeService.threadTypeList$
      .subscribe((threadTypes: ThreadTypeModel[]) => {
        if (threadTypes) {
          this.threadTypes = threadTypes;
        }
      });
  }

  public getShops(): void {
    this.shopsSub = this.shopService.shopList$
      .subscribe((shops: ShopModel[]) => {
        if (shops) {
          this.shops = shops;
        }
      });
  }

  public getMaintenanceDurationUoms(): void {
    this.maintenanceUomsSub = this.maintenanceDurationUomService.maintenanceTypeList$
      .subscribe((maintenanceUoms: MaintenanceDurationUomModel[]) => {
        if (maintenanceUoms) {
          this.maintenanceUoms = maintenanceUoms;
        }
      });
  }

  public getModel(): void {
    this.modelSub = this.itemInstanceService.getItemInstanceById(this.id)
      .subscribe((x: ItemInstanceModel) => {
        this.fnOdNa = x.outerDiameter == null;
        this.fnIdNa = x.innerDiameter == null;
        this.fnLengthNa = x.length == null;
        this.model = x;
      });
  }

  public onChangeLocation($event: ItemInstanceLocationModel): void {
    if ($event !== undefined) {
      this.model.itemInstanceLocation = $event.name;
    } else {
      this.model.itemInstanceLocation = null;
    }
  }

  public onChangeShop($event: ShopModel): void {
    if ($event !== undefined) {
      this.model.shopName = $event.name;
    } else {
      this.model.shopName = null;
    }
  }

  public onChangeMaintenanceLog($event: boolean): void {
    if ($event !== undefined) {
      this.model.hasMaintenanceLog = $event;

      if (!$event) {
        this.model.maintenanceDurationUomId = null;
      } else {
        this.model.maintenanceDurationUomId = MaintenanceDurationUoms.Hours;
      }
    }
  }

  public onChangeMaintenanceUom($event: MaintenanceDurationUomModel): void {
    if ($event !== undefined) {
      this.model.maintenanceDurationUomId = $event.maintenanceDurationUomId;
      this.model.maintenanceDurationUomName = $event.name;
    } else {
      this.model.maintenanceDurationUomName = null;
    }
  }

  public onSave(): void {
    this.disableSave = true;

    if (this.partGroupId !== this.PartGroups.BladedMills) {
      this.model.numberOfBlades = null;
    }

    this.saveSub = this.itemInstanceService.addEditItemInstance(this.model, false, this.launchedFromItemDetail)
      .subscribe((x: boolean) => {
        if (x) {
          this.bsModalRef.hide();

          this.uiService.showSuccess('Serialized Inventory Saved', 'Success');
        } else {
          this.uiService.showError('Serialized Inventory Saved', 'Error');

          this.disableSave = false;
        }
      });
  }

  public toggleFnIdNa($event: any): void {
    if ($event !== undefined) {
      this.model.innerDiameter = null;
      this.fnIdNa = $event.target.checked;
    }
  }

  public toggleFnOdNa($event: any): void {
    if ($event !== undefined) {
      this.model.outerDiameter = null;
      this.fnOdNa = $event.target.checked;
    }
  }

  public toggleLengthOdNa($event: any): void {
    if ($event !== undefined) {
      this.model.length = null;
      this.fnLengthNa = $event.target.checked;
    }
  }

}
