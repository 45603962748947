import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, Observer } from 'rxjs';
import { AddressModel } from 'src/models/address.model';
import { ContactModel } from 'src/models/contact.model';
import { CustomerModel } from '../models/customer.model';
import { AddressService } from './address.service';
import { ContactService } from './contact.service';

@Injectable()
export class CustomerService {

  constructor(
    private http: HttpClient,
    @Inject('BASE_URL') private baseUrl: string,
    private addressService: AddressService,
    private contactService: ContactService,
    private router: Router
  ) { }

  public isLoading: boolean = false;
  public customerList: BehaviorSubject<Array<CustomerModel>> = new BehaviorSubject(null);
  public customer: BehaviorSubject<CustomerModel> = new BehaviorSubject(null);
  public customerCache: BehaviorSubject<CustomerModel> = new BehaviorSubject(null);
  public customerListIsLoading: boolean = false;

  private apiUrl = 'api/Customer';  // URL to web api

  public addEditCustomer(
    dto: CustomerModel,
    cacheItem: boolean = false): Observable<boolean> {
    dto.customerAddresses.forEach((a: AddressModel) => {
      a = this.addressService.cleanAddressModel(a);
    });

    dto.customerContacts.forEach((c: ContactModel) => {
      c = this.contactService.cleanContactModel(c);
    });

    return new Observable((observer: Observer<boolean>) => {
      this.http.post<number>(this.baseUrl + this.apiUrl + '/AddEditCustomer', this.cleanCustomerModel(dto))
        .subscribe((x: number) => {
          this.getCustomers(false, true);
          // Edits of existing Customers && New Customers added from other modals.
          if (x !== -1 && (cacheItem || dto.customerId !== undefined)) {
            if (cacheItem) {
              dto.customerId = x;
              this.customerCache.next(dto);
            } else {
              this.refreshCustomer(x);
            }
          } else {
            this.router.navigateByUrl(`/customers/details/${x}`);
          }
          observer.next(x !== -1);
        });
    });
  }

  public deleteCustomer(
    id: number,
    showInactive: boolean): Observable<boolean> {
    return new Observable((observer: Observer<boolean>) => {
      this.http.get<boolean>(this.baseUrl + this.apiUrl + '/DeleteCustomer/' + id)
        .subscribe((x: boolean) => {
          if (x === true) {
            this.getCustomers(showInactive, true);
          }
          observer.next(x);
        });
    });
  }

  public getCustomers(
    showInactive: boolean,
    clear: boolean = false,
    showSkeleton: boolean = false): void {
    if (!this.customerList.value || clear) {
      if (showSkeleton) {
        this.customerListIsLoading = true;
      }

      this.http.get<Array<CustomerModel>>(this.baseUrl + this.apiUrl + '/GetCustomers/' + showInactive)

        .subscribe((x: CustomerModel[]) => {
          this.customerList.next(x);
          this.customerListIsLoading = false;
        });
    }
  }

  public refreshCustomer(
    id: number,
    clear: boolean = false) {
    if (clear) {
      this.customer.next(null);
    }

    this.isLoading = true;
    this.getCustomerById(id)
      .subscribe((x: CustomerModel) => {
        this.customer.next(x);
        this.isLoading = false;
      });
  }

  public getCustomerById(id: number): Observable<CustomerModel> {
    return this.http.get<CustomerModel>(this.baseUrl + this.apiUrl + '/GetCustomerById/' + id);
  }

  public generateNewCustomerModel(): CustomerModel {
    let model: CustomerModel = new CustomerModel();
    model.customerAddresses = [];
    model.customerContacts = [];
    model.isActive = true;

    return model;
  }

  private cleanCustomerModel(model: CustomerModel): CustomerModel {
    Object.keys(model).forEach((index: string) => {
      if (typeof model[index] === 'string') {
        model[index] = model[index]
          && model[index].trim() !== '' ?
          model[index].trim()
          : null;
      }
    });

    return model;
  }

}
