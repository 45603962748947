<div class="card border-top-primary border-top-2 rounded-top-0"
  style="border-top-color: #2196F3 !important;">
  <div class="card-header bg-light header-elements-sm-inline">
    <h6 class="card-title">Wells</h6>
    <div class="header-elements">
      <div class="switch-container pt-2 mr-2">
        <span class="switch-label">Show Inactive </span>
        <ui-switch [ngModel]="showInactive"
          (change)="onChangeShowInactive($event)"></ui-switch>
      </div>
      <button class="btn btn-light btn-sm"
        (click)="openWellModal(0)">
        <i class="fa fa-plus"></i> New Well
      </button>
    </div>
  </div>
  <div class="table-responsive"
    *ngIf="wellService.wellList | async; let model">
    <div class="table-responsive"
      *ngIf="model.length === 0">
      <div class="alert alert-primary ml-3 mr-3 mt-3"
        role="alert">
        <i class="fad fa-check"></i>&nbsp;&nbsp;There are currently no <strong>Customer Wells.</strong>
      </div>
    </div>
    <kendo-grid *ngIf="model.length > 0"
      [kendoGridBinding]="model"
      filterable="menu"
      [sortable]="true"
      [resizable]="true"
      [pageSize]="kGridParams.pageSize"
      [skip]="kGridParams.skip"
      (pageChange)="pageChange($event)"
      [pageable]="{
                    buttonCount: kGridParams.buttonCount,
                    info: kGridParams.info,
                    type: kGridParams.type,
                    pageSizes: kGridParams.pageSizes,
                    previousNext: kGridParams.previousNext
                  }">
      <kendo-grid-column field="one"
        title=""
        [width]="60"
        filterable="false">
        <ng-template kendoGridCellTemplate
          let-dataItem
          let-rowIndex="rowIndex">
          <button class="btn btn-xs btn-light"
            (click)="openWellModal(dataItem.wellId)"
            [disabled]="dataItem.isActive === false"><i class="fa fa-edit"></i></button>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column field="name"
        title="Name">
      </kendo-grid-column>
      <kendo-grid-column field="countyName"
        title="County">
      </kendo-grid-column>
      <kendo-grid-column field="isActive"
        title="Active"
        [width]="80"
        filterable="false">
        <ng-template kendoGridCellTemplate
          let-dataItem
          let-rowIndex="rowIndex">
          <span [ngClass]="{ 'text-danger' : !dataItem.isActive }">{{dataItem.isActive ? 'Yes' : 'No'}}</span>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column field="one"
        title=""
        [width]="60"
        filterable="false">
        <ng-template kendoGridCellTemplate
          let-dataItem
          let-rowIndex="rowIndex">
          <button class="btn btn-xs btn-light"
            [swal]="{ title: 'Delete Well?', text: dataItem.name + ' - ' + dataItem.customerName, showCancelButton: true, confirmButtonText: 'Delete', confirmButtonColor: '#DC3545' }"
            (confirm)="deleteWell(dataItem)"
            [disabled]="dataItem.isActive === false"><i class="fa fa-trash"></i></button>
        </ng-template>
      </kendo-grid-column>
    </kendo-grid>
  </div>
</div>
