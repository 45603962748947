import { Injectable } from '@angular/core';
import { PageChangeEvent } from '@progress/kendo-angular-grid';

@Injectable()
export class KGridService {
  constructor() { }

  public kGridParams: any =
    {
      buttonCount: 5,
      info: true,
      type: '\'numeric\' | \'input\' = \'numeric\'',
      pageSizes: true,
      previousNext: true,
      pageSize: 100,
      skip: 0
    };

  public pageChange({ skip, take }: PageChangeEvent): void {
    this.kGridParams.skip = skip;
    this.kGridParams.pageSize = take;
  }

  public resetKGridParams(): void {
    this.kGridParams.buttonCount = 5;
    this.kGridParams.info = true;
    this.kGridParams.type = '\'numeric\' | \'input\' = \'numeric\'';
    this.kGridParams.pageSize = true;
    this.kGridParams.previousNext = true;
    this.kGridParams.pageSize = 100;
    this.kGridParams.buttonCount = 0;
    this.kGridParams.skip = 0;
  }

}
