import { Component, OnDestroy, OnInit } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { AddEditAddressModalComponent } from 'src/app/addresses/add-edit-address-modal/add-edit-address-modal.component';
import { AddEditContactModalComponent } from 'src/app/contacts/add-edit-contact-modal/add-edit-contact-modal.component';
import { AddEditCustomerModalComponent } from 'src/app/customers/add-edit-customer-modal/add-edit-customer-modal.component';
import { AddEditRigModalComponent } from 'src/app/rigs/add-edit-rig-modal/add-edit-rig-modal.component';
import { AddEditWellModalComponent } from 'src/app/wells/add-edit-well-modal/add-edit-well-modal.component';
import { JobTypes } from 'src/enums/job-types';
import { CountyModel } from 'src/models/county.model';
import { JobTypeModel } from 'src/models/job-type.model';
import { JobModel } from 'src/models/job.model';
import { ShopModel } from 'src/models/shop.model';
import { AddressService } from 'src/services/address.service';
import { ContactService } from 'src/services/contact.service';
import { CountyService } from 'src/services/county.service';
import { CustomerService } from 'src/services/customer.service';
import { EmployeeService } from 'src/services/employee.service';
import { JobStatusService } from 'src/services/job-status.service';
import { JobTypeService } from 'src/services/job-type.service';
import { JobService } from 'src/services/job.service';
import { PaymentTermService } from 'src/services/payment-term.service';
import { RigService } from 'src/services/rig.service';
import { UIService } from 'src/services/ui.service';
import { WellService } from 'src/services/well.service';
import { ShopService } from '../../../../services/shop.service';
import { UserService } from '../../../../services/user.service';
import { UserModel } from 'src/models/user.model';
import { CustomerModel } from 'src/models/customer.model';
import { RigModel } from 'src/models/rig.model';
import { WellModel } from 'src/models/well.model';
import { AddressModel } from 'src/models/address.model';
import { ContactModel } from 'src/models/contact.model';
import { AddressTypes } from 'src/enums/address-types.enum';
import { ContactTypes } from 'src/enums/contact-types.enum';
import { EmployeeModel } from 'src/models/employee.model';

@Component({
  selector: 'app-add-edit-job-sales-modal',
  templateUrl: './add-edit-job-sales-modal.component.html',
  styleUrls: ['./add-edit-job-sales-modal.component.css']
})

export class AddEditJobSalesModalComponent implements OnInit, OnDestroy {
  private countiesSub: Subscription;
  public counties: CountyModel[];

  private shopsSub: Subscription;
  public shops: ShopModel[];

  private jobTypesSub: Subscription;
  public jobTypes: JobTypeModel[];

  private modelSub: Subscription;
  public model: JobModel;

  private userSub: Subscription;
  private saveSub: Subscription;
  private customerSub: Subscription;
  private rigSub: Subscription;
  private wellSub: Subscription;
  private addressSub: Subscription;
  private contactsSub: Subscription;

  public isNew: boolean = false;
  public id: number = 0;
  public jobTypeId: number = 0;
  public isQuote: boolean = false;
  public isWell: boolean = true;
  public deliveredByEmployee = true;
  public disableSave: boolean = false;
  public addrOrWellHasCounty: boolean = false;
  public handSelect: boolean = false;
  public userIsHand: boolean = false;
  public JobTypes = JobTypes;

  constructor(
    public bsModalRef: BsModalRef,
    public bsModalRefChildModal: BsModalRef,
    private modalService: BsModalService,
    public addressService: AddressService,
    public customerService: CustomerService,
    public countyService: CountyService,
    public contactService: ContactService,
    public employeeService: EmployeeService,
    public wellService: WellService,
    public rigService: RigService,
    public shopService: ShopService,
    public jobTypeService: JobTypeService,
    public statusService: JobStatusService,
    public jobService: JobService,
    public uiService: UIService,
    public termService: PaymentTermService,
    public userService: UserService,
  ) { };

  ngOnDestroy(): void {
    this.customerSub?.unsubscribe();

    this.countiesSub?.unsubscribe();

    this.shopsSub?.unsubscribe();

    this.jobTypesSub?.unsubscribe();

    this.modelSub?.unsubscribe();

    this.userSub?.unsubscribe();

    this.saveSub?.unsubscribe();

    this.rigSub?.unsubscribe();

    this.wellSub?.unsubscribe();

    this.addressSub?.unsubscribe();

    this.contactsSub?.unsubscribe();
  }

  ngOnInit(): void {
    this.isNew = this.id === 0;

    this.handSelect = this.jobTypeId === this.JobTypes.Fishing || this.jobTypeId === this.JobTypes.Packer;

    this.userService.getHands(this.jobTypeId);

    this.getCounties();

    this.getJobTypes();

    this.statusService.getJobStatusesByJobTypeId(
      this.jobTypeId,
      false);

    this.customerService.getCustomers(false);

    this.employeeService.getEmployees(false);

    this.getShops();

    this.subscribeToCaches();

    if (this.isNew) {
      this.clearLists();

      this.model = this.jobService.generateNewJobModel(
        this.jobTypeId,
        this.isQuote);
    } else {
      this.getModel();
    }

    this.subscribeToUser();
  }

  public getCounties(): void {
    this.countiesSub = this.countyService.countyList$
      .subscribe((counties: CountyModel[]) => {
        if (counties) {
          this.counties = counties;
        }
      });
  }

  public getShops(): void {
    this.shopsSub = this.shopService.shopList$
      .subscribe((shops: ShopModel[]) => {
        if (shops) {
          this.shops = shops;
        }
      });
  }

  public getJobTypes(): void {
    this.jobTypesSub = this.jobTypeService.jobTypeList$
      .subscribe((jobTypes: JobTypeModel[]) => {
        if (jobTypes) {
          this.jobTypes = jobTypes;
        }
      });
  }

  public getModel(): void {
    this.modelSub = this.jobService.getJobById(this.id)
      .subscribe((x: JobModel) => {
        if (x.customerId !== undefined && x.customerId !== null) {
          this.refreshCustomerBasedLists(x.customerId);
        }

        this.isWell = x.wellId !== undefined && x.wellId !== null;
        this.deliveredByEmployee = x.deliveredById !== undefined && x.deliveredById !== null;
        this.addrOrWellHasCounty = x.addrOrWellHasCounty;

        this.model = x;
      });
  }

  public subscribeToUser(): void {
    this.userSub = this.userService.user
      .subscribe((x: UserModel) => {
        if (x) {
          this.userIsHand = x.activeRoles.includes('Fishing Hand') || x.activeRoles.includes('Packer Hand');

          if (this.isNew && this.userIsHand) {
            this.model.jobHandUserIds.push(x.id);
          }
        }
      });
  }

  public onCustomerChange($event: any): void {
    this.model.addressId = null;
    this.model.orderedById = null;
    this.model.wellId = null;
    this.model.rigId = null;

    if ($event !== undefined) {
      this.refreshCustomerBasedLists($event.customerId);
    } else {
      this.contactService.contactList.next(null);
      this.addressService.addressList.next(null);
      this.wellService.wellList.next(null);
      this.rigService.rigList.next(null);
    }
  }

  public onChangeDeliverTo($event: any): void {
    if ($event !== undefined) {
      this.isWell = $event.value;
      this.model.addressId = null;
      this.model.wellId = null;
      this.model.countyId = null;
      this.model.countyName = null;
    }
  }

  public onChangeDeliveredBy($event: any): void {
    if ($event !== undefined) {
      this.deliveredByEmployee = $event.value;
      this.model.deliveredById = null;
      this.model.deliveredByThirdParty = null;
    }
  }

  public updateCountyInfo($event: any): void {
    if ($event !== undefined) {
      this.addrOrWellHasCounty = $event.countyId !== undefined && $event.countyId !== null;
      this.model.countyId = $event.countyId !== undefined && $event.countyId !== null ? $event.countyId : null;
      this.model.countyName = $event.countyName !== undefined && $event.countyName !== null ? $event.countyName : null;
    } else {
      this.addrOrWellHasCounty = false;
      this.model.countyId = null;
      this.model.countyName = null;
    }
  }

  public toggleIsWell(isWell: boolean): void {
    this.isWell = isWell;
  }

  public clearLists(): void {
    this.isWell = null;
    this.deliveredByEmployee = null;
    this.contactService.contactList.next(null);
    this.addressService.addressList.next(null);
    this.wellService.wellList.next(null);
    this.rigService.rigList.next(null);
  }

  public refreshCustomerBasedLists(customerId: number): void {
    this.contactService.refreshContactList(
      ContactTypes.Customer,
      customerId,
      false);

    this.addressService.refreshAddressList(
      AddressTypes.Customer,
      customerId,
      false);

    this.wellService.getWellsByCustomerId(
      customerId,
      false);

    this.rigService.getRigsByCustomerId(
      customerId,
      false);
  }

  public onSave(): void {
    this.disableSave = true;

    if (this.isWell) {
      this.model.addressId = null;
    } else if (!this.isWell) {
      this.model.wellId = null;
    }

    this.saveSub = this.jobService.addEditJob(
      this.model,
      false,
      true)
      .subscribe((x: boolean) => {
        if (x) {
          this.bsModalRef.hide();
          this.uiService.showSuccess('Job Saved', 'Success');
        } else {
          this.uiService.showError('Job Not Saved', 'Error');
          this.disableSave = false;
        }
      });
  }

  public onSelectOfJobOwner($event: EmployeeModel): void {
    if ($event !== undefined) {
      if (this.jobTypeId === this.JobTypes.Fishing
        || this.jobTypeId === this.JobTypes.Packer) {
        const users = this.userService.handList.value;
        const employeeFirstName = $event.firstName ? $event.firstName.toLowerCase() : null;
        const employeeLastName = $event.lastName ? $event.lastName.toLowerCase() : null;

        let user = users.find(x =>
          x.firstName
          && x.lastName
          && x.firstName.toLowerCase() === employeeFirstName
          && x.lastName.toLowerCase() === employeeLastName);

        if (!user) {
          user = users.find(x => x.lastName && x.lastName.toLowerCase() === employeeLastName);
        }

        if (user && this.model.jobHandUserIds.length === 0) {
          this.model.jobHandUserIds = [user.id];
        }
      }
    } else {
      this.model.jobHandUserIds = [];
    }
  }

  public openCustomerModal(custId: number): void {
    const initialState = {
      poid: custId,
      cacheCustomer: true
    };

    this.bsModalRefChildModal = this.modalService.show(AddEditCustomerModalComponent, { initialState, backdrop: 'static' });
  }

  public openRigModal(rigId: number): void {
    const initialState = {
      id: rigId,
      entityId: this.model.customerId,
      showInactive: false,
      getByCustomer: true,
      cacheRig: true
    };

    this.bsModalRefChildModal = this.modalService.show(AddEditRigModalComponent, { initialState, backdrop: 'static' });
  }

  public openWellModal(id: number): void {
    const initialState = {
      id: id,
      entityId: this.model.customerId,
      showInactive: false,
      getByCustomer: true,
      cacheWell: true
    };
    this.bsModalRefChildModal = this.modalService.show(AddEditWellModalComponent, { initialState, backdrop: 'static' });
  }

  public openAddressModal(id: number): void {
    const initialState = {
      id: id,
      type: AddressTypes.Customer,
      entityId: this.model.customerId,
      showInactive: false,
      cacheAddress: true
    };
    this.bsModalRefChildModal = this.modalService.show(AddEditAddressModalComponent, { initialState, class: 'modal-lg', backdrop: 'static' });
  }

  public openContactModal(id: number): void {
    const initialState = {
      id: id,
      type: ContactTypes.Customer,
      entityId: this.model.customerId,
      showInactive: false,
      cacheContact: true
    };
    this.bsModalRefChildModal = this.modalService.show(AddEditContactModalComponent, { initialState, backdrop: 'static' });
  }

  public subscribeToCaches(): void {
    this.subscribeToCustomer();
    this.subscribeToRigs();
    this.subscribeToWells();
    this.subscribeToAddresses();
    this.subscribeToContacts();
  }

  public subscribeToCustomer(): void {
    this.customerService.customerCache.next(null);

    this.customerSub = this.customerService.customerCache
      .subscribe((x: CustomerModel) => {
        if (x !== null && x !== undefined) {
          this.model.customerId = x.customerId;
          this.onCustomerChange({ customerId: x.customerId });
        }
      });
  }

  public subscribeToRigs(): void {
    this.rigService.rigCache.next(null);

    this.rigSub = this.rigService.rigCache
      .subscribe((x: RigModel) => {
        if (x !== null && x !== undefined) {
          this.model.rigId = x.rigId;
        }
      });
  }

  public subscribeToWells(): void {
    this.wellService.wellCache.next(null);

    this.wellSub = this.wellService.wellCache
      .subscribe((x: WellModel) => {
        if (x !== null && x !== undefined) {
          this.model.wellId = x.wellId;
          this.model.countyId = x.countyId;
          this.model.countyName = x.countyName;
        }
      });
  }

  public subscribeToAddresses(): void {
    this.addressService.addressCache.next(null);

    this.addressSub = this.addressService.addressCache
      .subscribe((x: AddressModel) => {
        if (x !== null && x !== undefined) {
          this.model.addressId = x.addressId;
          this.model.countyId = x.countyId;
          this.model.countyName = x.countyName;
        }
      });
  }

  public subscribeToContacts(): void {
    this.contactService.contactCache.next(null);

    this.contactsSub = this.contactService.contactCache
      .subscribe((x: ContactModel) => {
        if (x !== null && x !== undefined) {
          this.model.orderedById = x.contactId;
        }
      });
  }

}
