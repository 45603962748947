import { Component, OnDestroy, OnInit } from '@angular/core';
import { PageChangeEvent } from '@progress/kendo-angular-grid';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { AppStateService } from 'src/services/app-state.service';
import { InventoryHistoryService } from 'src/services/inventory-history.service';
import { ItemService } from 'src/services/item.service';
import { KGridService } from 'src/services/k-grid.service';
import { UIService } from 'src/services/ui.service';
import { ManualInventoryAdjustmentModalComponent } from '../manual-inventory-adjustment-modal/manual-inventory-adjustment-modal.component';
// import { ManualInventoryTransferModalComponent } from '../manual-inventory-transfer-modal/manual-inventory-transfer-modal.component';

@Component({
  selector: 'app-inventory-history-sm-list',
  templateUrl: './inventory-history-sm-list.component.html',
})

export class InventoryHistorySmallListComponent implements OnInit, OnDestroy {
  constructor(
    private modalService: BsModalService,
    public inventoryHistoryService: InventoryHistoryService,
    public itemService: ItemService,
    public kGridService: KGridService,
    public uiService: UIService,
    public appStateService: AppStateService,
  ) { };

  private isTabletSub: Subscription;
  public isTablet: boolean = false;

  public bsModalRef: BsModalRef;

  ngOnDestroy(): void {
    this.isTabletSub?.unsubscribe();
  }

  ngOnInit(): void {
    this.determineIsTablet();
  }

  public determineIsTablet(): void {
    this.isTabletSub = this.appStateService.isTablet$
      .subscribe((isTablet: boolean) => {
        this.isTablet = isTablet;
      });
  }

  public openInventoryAdjustModal(
    itemId: number,
    shopId: number): void {
    const initialState = {
      itemId: itemId,
      shopId: shopId
    };

    this.bsModalRef = this.modalService.show(ManualInventoryAdjustmentModalComponent, { initialState, backdrop: 'static' });
  }

  // public openInventoryTransferModal(itemId: number, shopId: number): void {
  //   const initialState = {
  //     itemId: itemId,
  //     shopId: shopId
  //   };
  //   this.bsModalRef = this.modalService.show(ManualInventoryTransferModalComponent, { initialState, backdrop: 'static' });
  // }

  public kGridParams: any =
    {
      buttonCount: 5,
      info: true,
      type: '\'numeric\' | \'input\' = \'numeric\'',
      pageSizes: true,
      previousNext: true,
      pageSize: 10,
      skip: 0
    };

  public pageChange({ skip, take }: PageChangeEvent): void {
    this.kGridParams.skip = skip;
    this.kGridParams.pageSize = take;
  }

}
