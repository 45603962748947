import { Component, OnDestroy, OnInit } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { KGridService } from 'src/services/k-grid.service';
import { StorageLocationService } from 'src/services/storage-location.service';
import { UIService } from 'src/services/ui.service';
import { AddEditStorageLocationModalComponent } from '../add-edit-storage-location-modal/add-edit-storage-location-modal.component';

@Component({
  selector: 'app-storage-locations-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.css']
})

export class StorageLocationListComponent implements OnInit, OnDestroy {

  public showInactive: boolean = false;

  constructor(
    private modalService: BsModalService,
    public kGridService: KGridService,
    public locService: StorageLocationService,
    public uiService: UIService,
  ) { };

  public bsModalRef: BsModalRef;
  private deleteSub: Subscription;

  ngOnDestroy() {
    this.kGridService.resetKGridParams();

    this.deleteSub?.unsubscribe();
  }

  ngOnInit(): void {
    this.locService.getStorageLocations(this.showInactive, true);
  }

  public openStorageLocationModal(locId: number): void {
    const initialState = {
      id: locId,
      showInactive: this.showInactive
    };

    this.bsModalRef = this.modalService.show(AddEditStorageLocationModalComponent, { initialState, backdrop: 'static' });
  }

  public deleteStorageLocation(
    $event: any,
    id: number): void {
    this.deleteSub = this.locService.deleteStorageLocation(
      id,
      this.showInactive)
      .subscribe((x: boolean) => {
        if (x) {
          this.uiService.showSuccess('Success', 'Item Deleted');
        } else {
          this.uiService.showError('Error', 'Item Not Deleted');
        }
      });
  }

  public onChangeShowInactive($event): void {
    this.showInactive = $event;
    this.locService.getStorageLocations(this.showInactive, true);
  }

}
