import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { ManualInventoryAdjustmentModalComponent }
  from 'src/app/inventory-history/manual-inventory-adjustment-modal/manual-inventory-adjustment-modal.component';
import { FileTypes } from 'src/enums/file-types';
import { ItemTypes } from 'src/enums/item-types';
import { Shops } from 'src/enums/shops';
import { InventoryHistoryModel } from 'src/models/inventory-history.model';
import { ItemModel } from 'src/models/item.model';
import { AppStateService } from 'src/services/app-state.service';
import { BillingCodeService } from 'src/services/billing-code.service';
import { FileService } from 'src/services/file.service';
import { InventoryHistoryService } from 'src/services/inventory-history.service';
import { ItemInstanceService } from 'src/services/item-instance.service';
import { ItemService } from 'src/services/item.service';
import { UIService } from 'src/services/ui.service';
import { WorksheetService } from 'src/services/worksheet.service';
import { AddEditItemModalComponent } from '../add-edit-item-modal/add-edit-item-modal.component';
import { TabDirective } from 'ngx-bootstrap/tabs';

@Component({
  selector: 'app-item-details',
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.css']
})

export class ItemDetailsComponent implements OnInit, OnDestroy {
  constructor(
    private modalService: BsModalService,
    private activatedRoute: ActivatedRoute,
    public itemService: ItemService,
    public uiService: UIService,
    public inventoryHistoryService: InventoryHistoryService,
    public billingCodeService: BillingCodeService,
    public itemInstanceService: ItemInstanceService,
    public fileService: FileService,
    public appStateService: AppStateService,
    public worksheetService: WorksheetService
  ) { };

  public bsModalRef: BsModalRef;
  public itemId: number = -1;
  public activeTabId: string = null;
  public timeStamp: string = new Date().toISOString();
  public infoIsCollapsed = false;
  public showDetails = true;
  public ItemTypes = ItemTypes;
  public isLoadingHistory: boolean = false;
  public showAdditionalDayColumn: boolean = null;
  public noHistory = false;
  public Shops = Shops;
  public FileTypes = FileTypes;

  private isTabletSub: Subscription;
  public isTablet: boolean = false;

  private routeParamsSub: Subscription;
  private invHistSub: Subscription;
  private itemSub: Subscription;
  private fileSub: Subscription;
  private removeImageSub: Subscription;
  private shopHistSub: Subscription;

  ngOnDestroy(): void {
    this.isTabletSub?.unsubscribe();

    this.invHistSub?.unsubscribe();

    this.itemSub?.unsubscribe();

    this.fileSub?.unsubscribe();

    this.removeImageSub?.unsubscribe();

    this.routeParamsSub?.unsubscribe();

    this.shopHistSub?.unsubscribe();
  }

  ngOnInit(): void {
    this.determineIsTablet();

    this.subscribeToRouteParams();

    this.subscribeToItem();

    this.subscribeToInventoryHistory();
  }

  public determineIsTablet(): void {
    this.isTabletSub = this.appStateService.isTablet$
      .subscribe((isTablet: boolean) => {
        this.isTablet = isTablet;
      });
  }

  public subscribeToRouteParams(): void {
    this.itemService.item.next(null);

    this.routeParamsSub = this.activatedRoute.params
      .subscribe((x: Params) => {
        this.itemId = parseInt(this.activatedRoute.snapshot.params['id']);
        this.itemService.refreshItem(this.itemId);
      });
  }

  public subscribeToItem(): void {
    this.itemSub = this.itemService.item
      .subscribe((item: ItemModel) => {
        if (item !== undefined && item !== null) {
          this.getLists(item.itemId);

          if (item.itemTypeId === this.ItemTypes.Build) {
            this.worksheetService.packerList.next(item.inStockBuilds);
          }

          this.activeTabId = item.itemTypeId === this.ItemTypes.Sale ?
            'IH'
            : item.itemTypeId === this.ItemTypes.Rental ?
              'SER'
              : item.itemTypeId === this.ItemTypes.Build ?
                'BUI'
                : 'BC';

          this.showAdditionalDayColumn = item.itemTypeId === this.ItemTypes.Rental || item.itemTypeId === this.ItemTypes.ThirdParty;
        }
      });
  }

  public subscribeToInventoryHistory(): void {
    this.invHistSub = this.inventoryHistoryService.inventoryHistoryShopList
      .subscribe((x: InventoryHistoryModel[][]) => {
        if (x) {
          this.noHistory = x.length === 0;
        }
      });
  }

  public setTimeStamp(): void {
    this.timeStamp = new Date().toISOString();
  }

  public uploadFile($event: any): void {
    let fileList: FileList = $event.target.files;
    let typeId: number = this.FileTypes.ItemDiagram;
    this.upload(fileList, typeId, this.itemId);
  }

  private upload(
    fileList: FileList,
    typeId: number,
    itemId: number): void {
    this.fileSub = this.fileService.uploadFile(
      fileList,
      typeId,
      itemId)
      .subscribe((x: boolean) => {
        if (x) {
          this.setTimeStamp();
          this.uiService.showSuccess('Image Added', 'Success');
        } else {
          this.uiService.showError('Image NOT Added', 'Success');
        }
      });
  }

  public removeBhaImage(): void {
    this.removeImageSub = this.fileService.removeBhaItemImage(
      this.FileTypes.ItemDiagram,
      this.itemId)
      .subscribe((x: boolean) => {
        if (x) {
          this.setTimeStamp();
          this.uiService.showSuccess('Image Removed', 'Success');
        } else {
          this.uiService.showError('Image NOT Removed', 'Success');
        }
      });
  }

  public timeStampImageLink(): string {
    return `/api/File/GetBhaItemImage/3/${this.itemId}?${this.timeStamp}`;
  }

  public tabSelect($event: TabDirective): void {
    if ($event !== undefined) {
      this.activeTabId = $event.id;
    }
  }

  public openInventoryAdjustModal(): void {
    const initialState = {
      itemId: this.itemId,
      shopId: this.Shops.Williston
    };
    this.bsModalRef = this.modalService.show(ManualInventoryAdjustmentModalComponent, { initialState, backdrop: 'static' });
  }

  public openItemModal(itemId: number): void {
    const initialState = {
      id: itemId,
      showInactive: false
    };
    this.bsModalRef = this.modalService.show(AddEditItemModalComponent, { initialState, class: 'modal-xl', backdrop: 'static' });
  }

  public loadShopHistory(itemId: number) {
    this.isLoadingHistory = true;

    this.shopHistSub = this.inventoryHistoryService.getInventoryHistoryPerShopByItemId(itemId)
      .subscribe((x: boolean) => {
        if (x) {
          this.isLoadingHistory = false;
        }
      });
  }

  public getLists(itemId: number): void {
    this.inventoryHistoryService.getInventoryHistoryByItemId(itemId);
    // this.inventoryHistoryService.getInventoryHistoryPerShopByItemId(itemId); // for testing
    this.loadShopHistory(itemId);
    this.billingCodeService.refreshBillingCodesByItemId(itemId);
    this.itemInstanceService.getItemInstancesByItemId(itemId);
  }

}
