<div class="page-header">
  <div class="page-header-content header-elements-md-inline">
    <div class="page-title d-flex">
      <a [routerLink]="['/items/details', itemId]"
        class="breadcrumb-link-color">
        <h4><i class="icon-arrow-left52 mr-2"></i> <span class="font-weight-semibold">Serialized Item</span> - Details
        </h4>
      </a>
      <button type="button"
        class="btn btn-outline-dark btn-sm ml-3 pl-1 pr-1 pt-0 pb-0 no-border"
        [title]="!showDetails ? 'Show Details' : 'Hide Details'"
        (click)="showDetails = !showDetails">
        <span *ngIf="!showDetails">
          <i class="fa-regular fa-chevrons-right"></i>&nbsp;Show </span>
        <span *ngIf="showDetails"><i class="fa-regular fa-chevrons-left"></i>&nbsp;Hide
        </span>Details</button>
    </div>
    <div class="header-elements d-none py-0 mb-3 mb-md-0">
      <div class="breadcrumb">
        <a href="index.html"
          class="breadcrumb-item"><i class="icon-home2 mr-2"></i>Serialized Item</a>
        <span class="breadcrumb-item active">Detail</span>
      </div>
    </div>
  </div>
</div>
<div class="page-content pt-0"
  *ngIf="itemInstanceService.itemInstance | async; let ii">
  <div class="content-wrapper">
    <div class="content">
      <div class="row">
        <div class="col-md-3"
          *ngIf="showDetails">
          <div class="card mb-0">
            <div class="card-header bg-light header-elements-inline"
              [ngClass]="{'pr-2 pl-2':isTablet}">
              <h6 class="card-title">Serialized Item: {{ii.serialNumber}}</h6>
              <div class="header-elements">
                <button class="btn btn-light btn-sm"
                  (click)="openAddEditItemInstanceModal(ii.itemInstanceId, ii.itemId)"><i class="fa fa-edit"></i>
                  Edit</button>
              </div>
            </div>
            <div id="collapseBasic"
              [collapse]="isCollapsed">
              <div class="card-body"
                [ngClass]="{'pr-2 pl-2':isTablet}">
                <dl class="row">
                  <dt class="col-sm-4 text-right"
                    [ngClass]="{'pr-0':isTablet}">Part #</dt>
                  <dd class="col-sm-8">{{ii.item.partNumber}}</dd>
                  <dt class="col-sm-4 text-right"
                    [ngClass]="{'pr-0':isTablet}">Vendor</dt>
                  <dd class="col-sm-8">{{ii.item.vendorName}}</dd>
                  <dt class="col-sm-4 text-right"
                    [ngClass]="{'pr-0':isTablet}">Serial#</dt>
                  <dd class="col-sm-8">{{ii.serialNumber}}</dd>
                  <dt class="col-sm-4 text-right"
                    [ngClass]="{'pr-0':isTablet}">Status</dt>
                  <dd class="col-sm-8">{{ii.itemInstanceLocation}}</dd>
                  <dt class="col-sm-4 text-right"
                    [ngClass]="{'pr-0':isTablet}">Shop</dt>
                  <dd class="col-sm-8">{{ii.shopName}}</dd>
                  <dt class="col-sm-4 text-right"
                    [ngClass]="{'pr-0':isTablet}"
                    *ngIf="ii.threadTypeName">Thread Type</dt>
                  <dd class="col-sm-8"
                    *ngIf="ii.threadTypeName">{{ii.threadTypeName}}</dd>
                  <dt class="col-sm-4 text-right"
                    [ngClass]="{'pr-0':isTablet}"
                    *ngIf="ii.numberOfBlades"># of Blades</dt>
                  <dd class="col-sm-8"
                    *ngIf="ii.numberOfBlades">{{ii.numberOfBlades}}</dd>
                  <dt class="col-sm-4 text-right"
                    [ngClass]="{'pr-0':isTablet}">ID</dt>
                  <dd class="col-sm-8">{{ii.innerDiameter}}<span *ngIf="ii.innerDiameter!==null">"</span></dd>
                  <dt class="col-sm-4 text-right"
                    [ngClass]="{'pr-0':isTablet}">OD</dt>
                  <dd class="col-sm-8">{{ii.outerDiameter}}<span *ngIf="ii.outerDiameter!==null">"</span></dd>
                  <dt class="col-sm-4 text-right"
                    [ngClass]="{'pr-0':isTablet}">Length</dt>
                  <dd class="col-sm-8">{{ii.length}}<span *ngIf="ii.length!==null">'</span></dd>
                  <dt class="col-sm-4 text-right"
                    [ngClass]="{'pr-0':isTablet}">Cost</dt>
                  <dd class="col-sm-8">{{ii.originalCost | currency}}</dd>
                  <dt class="col-sm-4 text-right"
                    [ngClass]="{'pr-0':isTablet}"
                    *ngIf="ii.purchasePoId">Purchase PO</dt>
                  <dd class="col-sm-8"
                    *ngIf="ii.purchasePoId"><span><a
                        [routerLink]="['/purchase-orders/details', ii.purchasePoId]"><u>{{ii.purchasePoNumber}}</u></a></span>
                  </dd>
                  <dt class="col-sm-4 text-right"
                    [ngClass]="{'pr-0':isTablet}"
                    *ngIf="ii.hasMaintenanceLog">Maintenance Duration UOM</dt>
                  <dd class="col-sm-8"
                    *ngIf="ii.hasMaintenanceLog">{{ii.maintenanceDurationUomName}}
                  </dd>
                </dl>
              </div>
            </div>
          </div>
        </div>
        <div [ngClass]="{'col-md-9': showDetails, 'col-md-12': !showDetails}">
          <tabset class="compact-nav">
            <tab heading="Maintenance Logs"
              *ngIf="hasMaintenanceLog"
              id="MAI"
              (selectTab)="tabSelect($event)"
              [active]="'MAI' === activeTabId">
              <app-maintenance-log-sm-list [itemInstanceId]="itemInstanceId"></app-maintenance-log-sm-list>
            </tab>
            <tab heading="Status History"
              id="STA"
              *ngIf="showStatusHistory"
              (selectTab)="tabSelect($event)"
              [active]="'STA' === activeTabId">
              <app-item-instance-location-history-sm-list></app-item-instance-location-history-sm-list>
            </tab>
          </tabset>
        </div>
      </div>
    </div>
  </div>
</div>
<div *ngIf="!itemInstanceService.itemInstance.value"
  class="page-content pt-0">
  <div class="content-wrapper">
    <div class="content">
      <div class="row">
        <div class="col-lg-3">
          <div class="card">
            <div class="card-header bg-light header-elements-inline">
              <h6 class="card-title">Serialized Item:</h6>
              <div class="header-elements">
                <button class="btn btn-light btn-sm"
                  [disabled]="true"><i class="fa fa-edit"></i> Edit</button>
              </div>
            </div>
            <div class="card-body">
              <app-list-skeleton [numberToIterate]="10"></app-list-skeleton>
            </div>
          </div>
        </div>
        <div class="col-lg-9">
          <tabset class="compact-nav">
            <tab heading="-----------------"
              [disabled]="true">
              <app-table-skeleton [numberToIterate]="10"></app-table-skeleton>
            </tab>
            <tab heading="-----------------"
              [disabled]="true">
            </tab>
          </tabset>
        </div>
      </div>
    </div>
  </div>
</div>
