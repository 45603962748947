export class RigModel {
  rigId: number;
  name: string;
  customerId: number;
  customerName: string;
  countyId: number;
  countyName: string;
  customerNotes: string;
  isActive: boolean;
}
