import { Component, OnDestroy, OnInit } from '@angular/core';
import { RowClassArgs } from '@progress/kendo-angular-grid';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { AppStateService } from 'src/services/app-state.service';
import { CustomerService } from 'src/services/customer.service';
import { KGridService } from 'src/services/k-grid.service';
import { UIService } from 'src/services/ui.service';
import { AddEditCustomerModalComponent } from '../add-edit-customer-modal/add-edit-customer-modal.component';

@Component({
  selector: 'app-customers-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.css']
})

export class CustomerListComponent implements OnInit, OnDestroy {

  constructor(
    private modalService: BsModalService,
    public customerService: CustomerService,
    public kGridService: KGridService,
    public appStateService: AppStateService,
    public uiService: UIService,
  ) { };
  public showInactive: boolean = false;
  public bsModalRef: BsModalRef;

  private isTabletSub: Subscription;
  public isTablet: boolean = false;

  private deleteSub: Subscription;

  ngOnDestroy(): void {
    this.kGridService.resetKGridParams();

    this.isTabletSub?.unsubscribe();

    this.deleteSub?.unsubscribe();
  }

  ngOnInit() {
    this.customerService.getCustomers(
      this.showInactive,
      true,
      true);

    this.determineIsTablet();
  }

  public determineIsTablet(): void {
    this.isTabletSub = this.appStateService.isTablet$
      .subscribe((isTablet: boolean) => {
        this.isTablet = isTablet;
      });
  }

  public onChangeShowInactive($event: boolean): void {
    this.showInactive = $event;
    this.customerService.getCustomers(this.showInactive, true);
  }

  public openCustomerModal(custId: number): void {
    const initialState = {
      id: custId,
    };
    this.bsModalRef = this.modalService.show(AddEditCustomerModalComponent, { initialState, backdrop: 'static' });
  }

  public deleteCustomer(id: number): void {
    this.deleteSub = this.customerService.deleteCustomer(id, this.showInactive)
      .subscribe((x: boolean) => {
        if (x) {
          this.uiService.showSuccess('Success', 'Customer Deleted');
        } else {
          this.uiService.showError('Error', 'Customer Not Deleted');
        }
      });
  }

  public rowCallback(context: RowClassArgs): any {
    return {
      inactive: context.dataItem.isActive === false
    };
  }

}
