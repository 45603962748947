import { Component, OnDestroy, OnInit } from '@angular/core';
import { SelectableSettings } from '@progress/kendo-angular-grid';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { PartFamilyModel } from 'src/models/part-family.model';
import { PartGroupModel } from 'src/models/part-group.model';
import { KGridService } from 'src/services/k-grid.service';
import { PartFamilyService } from 'src/services/part-family.service';
import { PartGroupService } from 'src/services/part-group.service';
import { UIService } from 'src/services/ui.service';
import { AddEditPartFamilyModalComponent } from '../add-edit-part-family/add-edit-part-family-modal.component';
import { AddEditPartGroupModalComponent } from '../add-edit-part-group/add-edit-part-group-modal.component';

@Component({
  selector: 'app-part-families-list',
  templateUrl: './list.component.html'
})

export class PartFamilyListComponent implements OnInit, OnDestroy {

  public showInactive: boolean = false;
  public selectedIds: number[] = [];
  public selectedIsFishingTool: boolean = false;
  public selectableSettings: SelectableSettings;
  public partGroups: PartGroupModel[] = [];
  public selectedRowName: string = '';

  private partFamilyChangesSub: Subscription;
  private deletePartFamilySub: Subscription;
  private deletePartGroupSub: Subscription;

  constructor(
    private modalService: BsModalService,
    public kGridService: KGridService,
    public partFamilyService: PartFamilyService,
    public partGroupService: PartGroupService,
    public uiService: UIService,
  ) { };

  public bsModalRef: BsModalRef;

  ngOnDestroy(): void {
    this.partFamilyChangesSub?.unsubscribe();

    this.deletePartFamilySub?.unsubscribe();

    this.deletePartGroupSub?.unsubscribe();
  }

  ngOnInit(): void {
    this.setSelectableSettings();

    this.subscribeToPartFamilyChanges();

    this.partFamilyService.refreshPartFamilyList(
      this.showInactive,
      true,
      true,
      true);
  }

  public subscribeToPartFamilyChanges(): void {
    this.partFamilyChangesSub = this.partFamilyService.partFamilyList
      .subscribe((x: PartFamilyModel[]) => {
        if (x !== null) {
          this.populatePartGroupKendo();
        }
      });
  }

  public setSelectableSettings(): void {
    this.selectableSettings = {
      checkboxOnly: false,
      mode: 'single'
    };
  }

  public selectedKeysChange($event: number[]): void {
    if ($event !== undefined) {
      this.selectedIds = $event;
      let partFamily: PartFamilyModel[] = this.partFamilyService.partFamilyList.value ?
        this.partFamilyService.partFamilyList.value.filter((pf: PartFamilyModel) => {
          return pf.partFamilyId === $event[0];
        })
        : [];
      if (partFamily.length > 0) {
        this.selectedIsFishingTool = partFamily[0].fishingTools;
      }
      this.populatePartGroupKendo();
    }
  }

  public populatePartGroupKendo(): void {
    if (this.selectedIds.length == 0) {
      this.partGroups = [];
    } else {
      let row: PartFamilyModel[] = this.partFamilyService.partFamilyList.value !== null ?
        this.partFamilyService.partFamilyList.value.filter((x: PartFamilyModel) => {
          return x.partFamilyId === this.selectedIds[0];
        })
        : null;
      this.partGroups = row !== null && row.length > 0 && row[0].partGroups !== null && row[0].partGroups.length > 0 ? row[0].partGroups : [];
      this.selectedRowName = row !== null && row.length > 0 ? row[0].name : '';
    }
  }

  public openPartFamilyModal(pfId: number): void {
    const initialState = {
      id: pfId,
      showInactive: this.showInactive
    };

    this.bsModalRef = this.modalService.show(AddEditPartFamilyModalComponent, { initialState, backdrop: 'static' });
  }

  public openPartGroupModal(
    pgId: number,
    partFamilyId: number,
    fishingTools: boolean): void {
    const initialState = {
      id: pgId,
      fishingTools: fishingTools,
      partFamilyId: partFamilyId,
      partFamilyName: this.selectedRowName
    };

    this.bsModalRef = this.modalService.show(AddEditPartGroupModalComponent, { initialState, backdrop: 'static' });
  }

  public deletePartFamily(id: number): void {
    this.deletePartFamilySub = this.partFamilyService.deletePartFamily(id, this.showInactive)
      .subscribe((x: boolean) => {
        if (x) {
          this.uiService.showSuccess('Success', 'Part Family Deleted');
        } else {
          this.uiService.showError('Error', 'Part Family Not Deleted');
        }
      });
  }

  public deletePartGroup(id: number): void {
    this.deletePartGroupSub = this.partGroupService.deletePartFamily(id)
      .subscribe((x: boolean) => {
        if (x) {
          this.uiService.showSuccess('Success', 'Part Group Deleted');
        } else {
          this.uiService.showError('Error', 'Part Group Not Deleted');
        }
      });
  }

}
