import { Component, OnDestroy, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { CountyModel } from 'src/models/county.model';
import { PaymentTermModel } from 'src/models/payment-term.model';
import { StateModel } from 'src/models/state.model';
import { VendorModel } from 'src/models/vendor.model';
import { AddressService } from 'src/services/address.service';
import { CountyService } from 'src/services/county.service';
import { PaymentTermService } from 'src/services/payment-term.service';
import { StateService } from 'src/services/state.service';
import { UIService } from 'src/services/ui.service';
import { VendorService } from 'src/services/vendor.service';
import { States } from '../../../enums/states';
import { ContactService } from 'src/services/contact.service';

@Component({
  selector: 'app-add-edit-vendor-modal',
  templateUrl: './add-edit-vendor-modal.component.html',
  styleUrls: ['add-edit-vendor-modal.component.css']
})

export class AddEditVendorModalComponent implements OnInit, OnDestroy {
  private countiesSub: Subscription;
  public counties: CountyModel[];

  private statesSub: Subscription;
  public states: StateModel[];

  private termsSub: Subscription;
  public terms: PaymentTermModel[];

  private modelSub: Subscription;
  public model: VendorModel;

  private saveSub: Subscription;

  public isNew: boolean = false;
  public id: number = -1;
  public showInactive: boolean = false;
  public launchedFromPoModal: boolean = false;
  public States = States;
  public disableSave: boolean = false;

  constructor(
    public bsModalRef: BsModalRef,
    public vendorService: VendorService,
    public addressService: AddressService,
    public contactService: ContactService,
    public countyService: CountyService,
    public stateService: StateService,
    public termService: PaymentTermService,
    public uiService: UIService
  ) { };

  ngOnDestroy(): void {
    this.countiesSub?.unsubscribe();

    this.modelSub?.unsubscribe();

    this.statesSub?.unsubscribe();

    this.saveSub?.unsubscribe();

    this.termsSub?.unsubscribe();
  }

  ngOnInit(): void {
    this.getCounties();

    this.getStates();

    this.getTerms();

    this.showInactive = this.showInactive === true;
    this.isNew = this.id === 0;

    if (this.isNew) {
      this.model = this.vendorService.generateNewVendorModel();
      this.model.vendorAddresses = [this.addressService.generateNewAddressModel(this.id)];
      this.model.vendorContacts = [this.contactService.generateNewContactModel(this.id)];
    } else {
      this.getModel();
    }
  }

  public getStates(): void {
    this.statesSub = this.stateService.stateList$
      .subscribe((states: StateModel[]) => {
        if (states) {
          this.states = states;
        }
      });
  }

  public getCounties(): void {
    this.countiesSub = this.countyService.countyList$
      .subscribe((counties: CountyModel[]) => {
        if (counties) {
          this.counties = counties;
        }
      });
  }

  public getTerms(): void {
    this.termsSub = this.termService.paymentTermList$
      .subscribe((terms: PaymentTermModel[]) => {
        if (terms) {
          this.terms = terms;
        }
      });
  }

  public getModel(): void {
    this.modelSub = this.vendorService.getVendorById(this.id)
      .subscribe((x: VendorModel) => {
        if (x.vendorAddresses === null || x.vendorAddresses === undefined || x.vendorAddresses.length === 0) {
          x.vendorAddresses = [this.addressService.generateNewAddressModel(this.id)];
        }

        if (x.vendorContacts === null || x.vendorContacts === undefined || x.vendorContacts.length === 0) {
          x.vendorContacts = [this.contactService.generateNewContactModel(this.id)];
        }

        this.model = x;
      });
  }

  public onSateChange($event: StateModel): void {
    this.model.vendorAddresses[0].countyId = null;
  }

  public onSave(): void {
    this.disableSave = true;

    this.saveSub = this.vendorService.addEditVendor(
      this.model,
      this.showInactive,
      this.launchedFromPoModal)
      .subscribe((x: boolean) => {
        if (x) {
          this.bsModalRef.hide();
          this.uiService.showSuccess('Vendor Saved', 'Success');
        } else {
          this.disableSave = false;
          this.uiService.showError('Vendor Not Saved', 'Error');
        }
      });
  }

}
