<div class="modal-header"
  ngxModalDraggable>
  <h4 class="modal-title pull-left">{{isNew ? 'Add' : 'Edit'}}&nbsp;User</h4>
  <button type="button"
    class="close pull-right"
    aria-label="Close"
    (click)="bsModalRef.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<form (ngSubmit)="onSave()"
  #heroForm="ngForm">
  <div class="modal-body"
    *ngIf="model">
    <div class="form-group row">
      <label for="title"
        class="col-sm-3 text-right control-label col-form-label">Username</label>
      <div class="col-sm-9 form-group mb-0">
        <input type="text"
          class="form-control form-control-sm"
          id="userName"
          [(ngModel)]="model.userName"
          name="userName"
          required>
      </div>
    </div>
    <div class="form-group row">
      <label for="title"
        class="col-sm-3 text-right control-label col-form-label">First Name</label>
      <div class="col-sm-9 form-group mb-0">
        <input type="text"
          class="form-control form-control-sm"
          id="firstName"
          [(ngModel)]="model.firstName"
          name="firstName">
      </div>
    </div>
    <div class="form-group row">
      <label for="title"
        class="col-sm-3 text-right control-label col-form-label">Last Name</label>
      <div class="col-sm-9 form-group mb-0">
        <input type="text"
          class="form-control form-control-sm"
          id="lastName"
          [(ngModel)]="model.lastName"
          name="lastName">
      </div>
    </div>
    <div class="form-group row">
      <label for="title"
        class="col-sm-3 text-right control-label col-form-label">Email</label>
      <div class="col-sm-9 form-group mb-0">
        <input type="text"
          class="form-control form-control-sm"
          id="email"
          [(ngModel)]="model.email"
          name="email"
          required>
      </div>
    </div>
    <div class="form-group row">
      <label for="title"
        class="col-sm-3 text-right control-label col-form-label">Phone</label>
      <div class="col-sm-9 form-group mb-0">
        <input type="text"
          class="form-control form-control-sm"
          id="phoneNumber"
          [(ngModel)]="model.phoneNumber"
          name="phoneNumber">
      </div>
    </div>
    <div class="form-group row">
      <label for="title"
        class="col-sm-3 text-right control-label col-form-label">PO Limit</label>
      <div class="col-sm-9 form-group mb-0">
        <div class="input-group input-group-sm">
          <div class="input-group-prepend">
            <span class="input-group-text">$</span>
          </div>
          <input type="number"
            step=".01"
            class="form-control form-control-sm"
            id="poLimit"
            [(ngModel)]="model.poLimit"
            name="poLimit"
            required>
        </div>
      </div>
    </div>
    <div class="form-group row">
      <label for="title"
        class="col-sm-3 text-right control-label col-form-label">Roles</label>
      <div class="col-sm-9 form-group mb-0">
        <ng-select [items]="userService.roleList | async"
          [multiple]="true"
          [closeOnSelect]="false"
          [searchable]="false"
          placeholder="Select Roles"
          [(ngModel)]="model.activeRoles"
          name="activeRoles">
        </ng-select>
      </div>
    </div>
    <div class="form-group row"
      *ngIf="model.activeRoles && model.activeRoles.includes('Approve Invoices')">
      <label for="title"
        class="col-sm-3 text-right control-label col-form-label">Invoice Approval Limit&nbsp;
        <span class="text-primary pointer">
          <i class="fas fa-info-circle"
            type="button"
            [popover]="popoverTemplate"
            popoverTitle="Invoice Approval Limit"
            placement="auto"
            [outsideClick]="true"
            container="body"></i>
        </span>
        <ng-template #popoverTemplate>
          <p>If this field is left blank, a user with the "Approve Invoices" role will be able to approve invoices of
            any
            amount.</p>
          <p>If a value is entered, the user will only be able to approve invoices up to the specified amount.</p>
        </ng-template>
      </label>
      <div class="col-sm-9 form-group mb-0">
        <div class="input-group input-group-sm">
          <div class="input-group-prepend">
            <span class="input-group-text">$</span>
          </div>
          <input type="number"
            step=".01"
            class="form-control form-control-sm"
            id="invoiceApprovalLimit"
            [(ngModel)]="model.invoiceApprovalLimit"
            name="invoiceApprovalLimit">
        </div>
      </div>
    </div>
    <div class="form-group row">
      <label for="title"
        class="col-sm-3 text-right control-label col-form-label">Default Open Invoice View</label>
      <div class="col-sm-9 form-group mb-0">
        <ng-select [items]="jobSubStatuses"
          bindLabel="name"
          bindValue="jobSubStatusId"
          [multiple]="true"
          [closeOnSelect]="false"
          [searchable]="false"
          placeholder="Select Sub Status"
          [(ngModel)]="model.jobSubStatusIds"
          name="subStatuses">
        </ng-select>
      </div>
    </div>
    <div class="form-group row">
      <label for="title"
        class="col-sm-3 text-right control-label col-form-label">Default Shop</label>
      <div class="col-sm-9 form-group mb-0"
        *ngIf="shops">
        <ng-select [items]="shops"
          bindLabel="name"
          bindValue="shopId"
          [(ngModel)]="model.defaultShopId"
          [clearable]="false"
          name="shops">
        </ng-select>
      </div>
    </div>
    <div class="form-group row"
      *ngIf="showChangePassSwitch">
      <span class="col-sm-3 text-right control-label col-form-label">Change Password</span>
      <div class="col-sm-9 form-group mb-0">
        <ui-switch [(ngModel)]="changePassword"
          name="changePassword"
          (change)="onChangeOfPasswordSwitch($event)"></ui-switch>
      </div>
    </div>
    <div class="form-group row mb-1"
      *ngIf="changePassword">
      <label for="title"
        class="col-sm-3 text-right control-label col-form-label">Password</label>
      <div class="col-sm-9 form-group mb-0">
        <input type="password"
          class="form-control form-control-sm"
          id="password"
          [(ngModel)]="model.password"
          name="password"
          [ngClass]="{ 'border border-danger' : changePassword && !userService.verifyPassword(model.password) , 'border border-success' : changePassword && userService.verifyPassword(model.password) }">
      </div>
    </div>
    <div class="form-group row mb-2"
      *ngIf="changePassword && !userService.verifyPassword(model.password)">
      <label for="title"
        class="col-sm-3 text-right control-label col-form-label"></label>
      <div class="col-sm-9 form-group mb-0">
        <div class="alert alert-danger"
          role="alert"
          style="margin-bottom: 0;padding: 4px;">
          <i class="fas fa-exclamation-triangle"></i>&nbsp;Password must contain:<br>
          <ul class="mb-0">
            <li>1 lower case character</li>
            <li>1 upper case character</li>
            <li>1 numerical character</li>
            <li>1 special character</li>
            <li>a minimum of <strong>8</strong> characters</li>
          </ul>
        </div>
      </div>
    </div>
    <div class="form-group row mb-2"
      *ngIf="changePassword && userService.verifyPassword(model.password)">
      <label for="title"
        class="col-sm-3 text-right control-label col-form-label"></label>
      <div class="col-sm-9 form-group mb-0">
        <div class="alert alert-success"
          role="alert"
          style="margin-bottom: 0;padding: 4px;">
          <i class="fas fa-check-circle"></i>&nbsp;Password valid!
        </div>
      </div>
    </div>
    <div class="form-group row mb-1"
      *ngIf="changePassword">
      <label for="title"
        class="col-sm-3 text-right control-label col-form-label">Confirm</label>
      <div class="col-sm-9 form-group mb-0">
        <input type="password"
          class="form-control form-control-sm"
          id="confirmPassword"
          [(ngModel)]="confirmPassword"
          name="confirmPassword"
          [ngClass]="{ 'border border-danger' : changePassword && (confirmPassword.length === 0 || (confirmPassword.length > 0 && model.password!==confirmPassword))  , 'border border-success' : changePassword && confirmPassword.length > 0 && model.password===confirmPassword }">
      </div>
    </div>
    <div class="form-group row mb-2"
      *ngIf="changePassword && confirmPassword.length > 0 && model.password!==confirmPassword">
      <label for="title"
        class="col-sm-3 text-right control-label col-form-label"></label>
      <div class="col-sm-9 form-group mb-0">
        <div class="alert alert-danger"
          role="alert"
          style="margin-bottom: 0;padding: 4px;">
          <i class="fas fa-exclamation-triangle"></i>&nbsp;Passwords must match!
        </div>
      </div>
    </div>
    <div class="form-group row mb-2"
      *ngIf="changePassword && confirmPassword.length > 0 && model.password===confirmPassword">
      <label for="title"
        class="col-sm-3 text-right control-label col-form-label"></label>
      <div class="col-sm-9 form-group mb-0">
        <div class="alert alert-success"
          role="alert"
          style="margin-bottom: 0;padding: 4px;">
          <i class="fas fa-check-circle"></i>&nbsp;Passwords match!
        </div>
      </div>
    </div>
  </div>
</form>
<div class="modal-footer"
  *ngIf="model">
  <button type="button"
    class="btn btn-primary"
    (click)="onSave()"
    [disabled]="disableSave || !(model.poLimit!==null&&model.userName&&model.email&&(!changePassword||changePassword&&userService.verifyPassword(model.password)&&model.password===confirmPassword))">Save</button>
  <button type="button"
    class="btn btn-default"
    (click)="bsModalRef.hide()">Cancel</button>
</div>
