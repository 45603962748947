import { Component, OnDestroy, OnInit } from '@angular/core';
import { RowClassArgs } from '@progress/kendo-angular-grid';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { SuperAdminEditConfirmModalComponent } from 'src/app/shared/super-admin-edit-confirm-modal/super-admin-edit-confirm-modal.component';
import { KGridService } from 'src/services/k-grid.service';
import { UIService } from 'src/services/ui.service';
import { UserService } from 'src/services/user.service';
import { AddEditJobStatusModalComponent } from '../add-edit-job-status-modal/add-edit-job-status-modal.component';
import { JobStatusService } from 'src/services/job-status.service';
import { JobTypes } from 'src/enums/job-types';

@Component({
  selector: 'app-job-status-list',
  templateUrl: './list.component.html',
  styleUrls: ['list.component.css']
})

export class JobStatusListComponent implements OnInit, OnDestroy {
  public showInactive: boolean = false;
  public allowEditing: boolean = false;
  private deleteSub: Subscription;
  public bsModalRef: BsModalRef;
  private saveSubscriptions = new Subscription();
  public JobTypes = JobTypes;

  constructor(
    private modalService: BsModalService,
    public kGridService: KGridService,
    public jobStatusService: JobStatusService,
    public uiService: UIService,
    public userService: UserService
  ) { };

  public ngOnDestroy(): void {
    this.kGridService.resetKGridParams();

    this.deleteSub?.unsubscribe();

    this.saveSubscriptions?.unsubscribe();
  }

  ngOnInit(): void {
    this.jobStatusService.getJobStatuses(this.showInactive);
  }

  public onChangeShowInactive($event): void {
    this.showInactive = $event;

    this.jobStatusService.getJobStatuses(this.showInactive);
  }

  public openJobStatusModal(itemTypeId: number): void {
    const initialState = {
      id: itemTypeId,
      showInactive: this.showInactive
    };
    this.bsModalRef = this.modalService.show(AddEditJobStatusModalComponent, { initialState, backdrop: 'static' });
  }

  public onChangeOfAllowEditing($event: boolean): void {
    this.allowEditing = $event;

    if ($event !== undefined) {
      if ($event === true) {
        this.bsModalRef = this.modalService.show(SuperAdminEditConfirmModalComponent, { backdrop: 'static' });

        this.saveSubscriptions.add(this.bsModalRef.content.onClose
          .subscribe((confirm: boolean) => {
            if (confirm !== undefined) {
              this.allowEditing = confirm;
            }
          }));
      }
    }
  }

  public deleteJobStatus(id: number): void {
    this.deleteSub = this.jobStatusService.deleteJobStatus(id)
      .subscribe((x: boolean) => {
        if (x) {
          this.uiService.showSuccess('Success', 'Job Status Deleted');
        } else {
          this.uiService.showError('Error', 'Job Status Not Deleted');
        }
      });
  }

  public rowCallback(context: RowClassArgs): any {
    if (context.dataItem.isActive === false) {
      return {
        inactive: true
      };
    } else {
      return {
        sales: context.dataItem.jobTypeId === JobTypes.Sales,
        packer: context.dataItem.jobTypeId === JobTypes.Packer,
        fishing: context.dataItem.jobTypeId === JobTypes.Fishing,
        rental: context.dataItem.jobTypeId === JobTypes.FishingToolRental,
      };
    }

  }

}
