import { Component, OnDestroy, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { UnitOfMeasureModel } from 'src/models/unit-of-measure.model';
import { ItemService } from 'src/services/item.service';
import { UIService } from 'src/services/ui.service';
import { UnitOfMeasureService } from 'src/services/unit-of-measure.service';

@Component({
  selector: 'app-add-edit-uom-modal',
  templateUrl: './add-edit-uom-modal.component.html',
  styleUrls: ['./add-edit-uom-modal.component.css']
})

export class AddEditUomModalComponent implements OnInit, OnDestroy {
  public isNew: boolean = false;
  public id: number = -1;
  public model: UnitOfMeasureModel;

  private modelSub: Subscription;
  private saveSub: Subscription;

  constructor(
    public bsModalRef: BsModalRef,
    public uiService: UIService,
    public uomService: UnitOfMeasureService,
    public itemService: ItemService

  ) { };

  ngOnDestroy(): void {
    this.modelSub?.unsubscribe();

    this.saveSub?.unsubscribe();
  }

  ngOnInit(): void {
    this.isNew = this.id === 0;

    if (this.isNew) {
      this.model = this.uomService.generateNewUomModel();
    } else {
      this.getModel();
    }
  }

  public getModel(): void {
    this.modelSub = this.uomService.getUnitOfMeasureById(this.id)
      .subscribe((x: UnitOfMeasureModel) => {
        this.model = x;
      });
  }

  public onSave(): void {
    this.saveSub = this.uomService.addEditUnitOfMeasure(this.model)
      .subscribe((x: boolean) => {
        if (x) {
          this.bsModalRef.hide();
          this.uiService.showSuccess('UOM Saved', 'Success');
        } else {
          this.uiService.showError('UOM Saved', 'Error');
        }
      });
  }

}
