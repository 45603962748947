import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Observer } from 'rxjs';
import { PartGroupModel } from '../models/part-group.model';
import { PartFamilyService } from './part-family.service';

@Injectable()
export class PartGroupService {
  constructor(
    private http: HttpClient,
    @Inject('BASE_URL') private baseUrl: string,
    private partFamilyService: PartFamilyService
  ) { }

  public partGroupList: BehaviorSubject<Array<PartGroupModel>> = new BehaviorSubject(null);
  public itemInstancePartGroupList: BehaviorSubject<Array<PartGroupModel>> = new BehaviorSubject(null);

  private apiUrl = 'api/PartGroup';  // URL to web api

  public addEditPartGroup(dto: PartGroupModel): Observable<boolean> {
    return new Observable((observer: Observer<boolean>) => {
      this.http.post<boolean>(this.baseUrl + this.apiUrl + '/AddEditPartGroup', this.cleanPartGroupModel(dto))
        .subscribe((x: boolean) => {
          if (x === true) {
            this.partFamilyService.refreshPartFamilyList(false, true, true);
          }
          observer.next(x);
        });
    });
  }

  public deletePartFamily(id: number): Observable<boolean> {
    return new Observable((observer: Observer<boolean>) => {
      this.http.get<boolean>(this.baseUrl + this.apiUrl + '/DeletePartGroup/' + id)
        .subscribe((x: boolean) => {
          if (x === true) {
            this.partFamilyService.refreshPartFamilyList(false, true, true);
          };
          observer.next(x);
        });
    });
  }

  public getItemInstancePartGroups(): void {
    if (!this.partGroupList.value) {
      this.partGroupList.next(null);
      this.http.get<Array<PartGroupModel>>(this.baseUrl + this.apiUrl + '/GetItemInstancePartGroups')
        .subscribe((x: PartGroupModel[]) => {
          this.itemInstancePartGroupList.next(x);
        });
    }
  }

  public getPartGroupsByFamilyId(
    id: number,
    showInactive: boolean,
    clear: boolean = false): void {
    if (!this.partGroupList.value || clear) {
      this.partGroupList.next(null);
      this.http.get<Array<PartGroupModel>>(this.baseUrl + this.apiUrl + '/GetPartGroupsByFamilyId/' + id + '/' + showInactive)
        .subscribe((x: PartGroupModel[]) => {
          this.partGroupList.next(x);
        });
    }
  }

  public getPartGroupById(id: number): Observable<PartGroupModel> {
    return this.http.get<PartGroupModel>(this.baseUrl + this.apiUrl + '/GetPartGroupById/' + id);
  }

  public getCurrentGtPrefixes(): Observable<number[]> {
    return this.http.get<number[]>(this.baseUrl + this.apiUrl + '/GetCurrentGtPrefixes');
  }

  public generatePartGroupModel(partFamilyId: number): PartGroupModel {
    let model = new PartGroupModel();
    model.isActive = true;
    model.partFamilyId = partFamilyId;

    return model;
  }

  private cleanPartGroupModel(model: PartGroupModel): PartGroupModel {
    Object.keys(model).forEach((index: string) => {
      if (typeof model[index] === 'string') {
        model[index] = model[index]
          && model[index].trim() !== '' ?
          model[index].trim()
          : null;
      }
    });

    return model;
  }

}
