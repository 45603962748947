export enum States {
  NorthDakota = 1,
  Utah = 2,
  Alabama = 3,
  Alaska = 4,
  Arizona = 5,
  Arkansas = 6,
  California = 7,
  Colorado = 8,
  Connecticut = 9,
  Delaware = 10,
  DistrictOfColumbia = 11,
  Florida = 12,
  Georgia = 13,
  Hawaii = 14,
  Idaho = 15,
  Illinois = 16,
  Indiana = 17,
  Iowa = 18,
  Kansas = 19,
  Kentucky = 20,
  Louisiana = 21,
  Maine = 22,
  Maryland = 23,
  Massachusetts = 24,
  Michigan = 25,
  Minnesota = 26,
  Mississippi = 27,
  Missouri = 28,
  Montana = 29,
  Nebraska = 30,
  Nevada = 31,
  NewHampshire = 32,
  NewJersey = 33,
  NewMexico = 34,
  NewYork = 35,
  NorthCarolina = 36,
  Ohio = 37,
  Oklahoma = 38,
  Oregon = 39,
  Pennsylvania = 40,
  RhodeIsland = 41,
  SouthCarolina = 42,
  SouthDakota = 43,
  Tennessee = 44,
  Texas = 45,
  Vermont = 46,
  Virginia = 47,
  Washington = 48,
  WestVirginia = 49,
  Wisconsin = 50,
  Wyoming = 51,
  Alberta = 52,
  BritishColumbia = 53,
  Manitoba = 54,
  NewBrunswick = 55,
  NewfoundlandAndLabrador = 56,
  NorthwestTerritories = 57,
  NovaScotia = 58,
  Nunavut = 59,
  Ontario = 60,
  PrinceEdwardIsland = 61,
  Quebec = 62,
  Saskatchewan = 63,
  Yukon = 64
}
