<dl class="row">
  <dt class="col-sm-4 text-right"
    [ngClass]="{'pr-0': isTablet}"
    *ngIf="job.jobDate">Open Date</dt>
  <dd class="col-sm-8"
    *ngIf="job.jobDate">{{job.jobDate | date}}</dd>
  <dt class="col-sm-4 text-right"
    [ngClass]="{'pr-0': isTablet}"
    *ngIf="job.customerName">Customer</dt>
  <dd class="col-sm-8"
    *ngIf="job.customerName">{{job.customerName}}</dd>
  <dt class="col-sm-4 text-right"
    [ngClass]="{'pr-0': isTablet}"
    *ngIf="job.afeNumber">AFE#</dt>
  <dd class="col-sm-8"
    *ngIf="job.afeNumber">{{job.afeNumber}}</dd>
  <dt class="col-sm-4 text-right"
    [ngClass]="{'pr-0': isTablet}"
    *ngIf="job.ccNumber">CC#</dt>
  <dd class="col-sm-8"
    *ngIf="job.ccNumber">{{job.ccNumber}}</dd>
  <dt class="col-sm-4 text-right"
    [ngClass]="{'pr-0': isTablet}"
    *ngIf="job.addressId !== null">Address</dt>
  <dd class="col-sm-8"
    *ngIf="job.addressId !== null">
    <ul *ngIf="job.address"
      class="address-ul">
      <li *ngIf="job.address.streetAddressLine1">
        {{job.address.streetAddressLine1}}
      </li>
      <li *ngIf="job.address.streetAddressLine2">
        {{job.address.streetAddressLine2}}
      </li>
      <li>
        {{job.address.city}}<span *ngIf="job.address.stateCode !== null">,&nbsp;</span>{{job.address.stateCode}}<span
          *ngIf="job.address.zip !== null">&nbsp;</span>{{job.address.zip}}
      </li>
    </ul>
  </dd>
  <dt class="col-sm-4 text-right"
    [ngClass]="{'pr-0': isTablet}"
    *ngIf="job.wellId !== null">Well / RIG</dt>
  <dd class="col-sm-8"
    *ngIf="job.wellId !== null">{{job.well_Rig}}</dd>
  <dt class="col-sm-4 text-right"
    [ngClass]="{'pr-0': isTablet}">County</dt>
  <dd class="col-sm-8">{{job.countyName}}</dd>
  <dt class="col-sm-4 text-right"
    [ngClass]="{'pr-0': isTablet}"
    *ngIf="job.orderedByName">Ordered By</dt>
  <dd class="col-sm-8"
    *ngIf="job.orderedByName">{{job.orderedByName}}</dd>
  <dt class="col-sm-4 text-right"
    [ngClass]="{'pr-0': isTablet}"
    *ngIf="!handDisplay && (job.deliveredByName||job.deliveredByThirdParty)">Delivered By</dt>
  <dd class="col-sm-8"
    *ngIf="!handDisplay && job.deliveredByName">{{job.deliveredByName}}</dd>
  <dd class="col-sm-8"
    *ngIf="!handDisplay && job.deliveredByThirdParty">{{job.deliveredByThirdParty}}</dd>
  <dt class="col-sm-4 text-right"
    [ngClass]="{'pr-0': isTablet}"
    *ngIf="job.jobOwnerName">Job Owner</dt>
  <dd class="col-sm-8"
    *ngIf="job.jobOwnerName">{{job.jobOwnerName}}</dd>
  <dt class="col-sm-4 text-right"
    [ngClass]="{'pr-0': isTablet}"
    *ngIf="handDisplay && job.jobHandNames && job.jobHandNames.length > 0">Hands</dt>
  <dd class="col-sm-8"
    *ngIf="handDisplay && job.jobHandNames && job.jobHandNames.length > 0"><span
      *ngFor="let h of job.jobHandNames">{{h}}<br /></span>
  </dd>
  <dt class="col-sm-4 text-right"
    [ngClass]="{'pr-0': isTablet}"
    *ngIf="job.shopName">Shop</dt>
  <dd class="col-sm-8"
    *ngIf="job.shopName">{{job.shopName}}</dd>
  <dt class="col-sm-4 text-right"
    [ngClass]="{'pr-0': isTablet}"
    *ngIf="job.jobStatusName">Invoice Status</dt>
  <dd class="col-sm-8"
    *ngIf="job.jobStatusName">
    <!--PACKER-->
    <span class="badge badge-success"
      *ngIf="job.jobStatusId === JobStatuses.Open_Packer"><i class="fas fa-tasks"></i>&nbsp;&nbsp;{{job.jobStatusName|
      uppercase}}</span>
    <span class="badge badge-warning"
      *ngIf="job.jobStatusId === JobStatuses.EditInvoice_Packer"><i
        class="fas fa-edit"></i>&nbsp;&nbsp;{{job.jobStatusName| uppercase}}</span>
    <span class="badge badge-dark"
      *ngIf="job.jobStatusId === JobStatuses.Closed_Packer"><i class="fad fa-check"></i>&nbsp;&nbsp;{{job.jobStatusName|
      uppercase}}</span>
    <span class="badge badge-danger"
      *ngIf="job.jobStatusId === JobStatuses.Cancelled_Packer"><i
        class="fad fa-times-circle"></i>&nbsp;&nbsp;{{job.jobStatusName| uppercase}}</span>
    <!--SALES-->
    <span class="badge badge-light"
      *ngIf="job.jobStatusId=== JobStatuses.Quote_Sales"><i
        class="fal fa-quote-right"></i>&nbsp;&nbsp;{{job.jobStatusName| uppercase}}</span>
    <span class="badge badge-success"
      *ngIf="job.jobStatusId === JobStatuses.Open_Sales"><i class="fas fa-tasks"></i>&nbsp;&nbsp;{{job.jobStatusName|
      uppercase}}</span>
    <span class="badge badge-dark"
      *ngIf="job.jobStatusId === JobStatuses.Closed_Sales"><i class="fad fa-check"></i>&nbsp;&nbsp;{{job.jobStatusName|
      uppercase}}</span>
    <span class="badge badge-danger"
      *ngIf="job.jobStatusId === JobStatuses.Cancelled_Sales"><i
        class="fad fa-times-circle"></i>&nbsp;&nbsp;{{job.jobStatusName| uppercase}}</span>
    <!--TOOL RENTAL-->
    <span class="badge badge-success"
      *ngIf="job.jobStatusId === JobStatuses.Open_FishingToolRental"><i
        class="fas fa-tasks"></i>&nbsp;&nbsp;{{job.jobStatusName| uppercase}}</span>
    <span class="badge badge-warning"
      *ngIf="job.jobStatusId === JobStatuses.EditInvoice_FishingToolRental"><i
        class="fas fa-edit"></i>&nbsp;&nbsp;{{job.jobStatusName| uppercase}}</span>
    <span class="badge badge-dark"
      *ngIf="job.jobStatusId === JobStatuses.Closed_FishingToolRental"><i
        class="fad fa-check"></i>&nbsp;&nbsp;{{job.jobStatusName| uppercase}}</span>
    <span class="badge badge-danger"
      *ngIf="job.jobStatusId === JobStatuses.Cancelled_FishingToolRental"><i
        class="fad fa-times-circle"></i>&nbsp;&nbsp;{{job.jobStatusName| uppercase}}</span>
    <!--FISHING-->
    <span class="badge badge-success"
      *ngIf="job.jobStatusId === JobStatuses.Open_Fishing"><i class="fas fa-tasks"></i>&nbsp;&nbsp;{{job.jobStatusName|
      uppercase}}</span>
    <span class="badge badge-warning"
      *ngIf="job.jobStatusId === JobStatuses.EditInvoice_Fishing"><i
        class="fas fa-edit"></i>&nbsp;&nbsp;{{job.jobStatusName| uppercase}}</span>
    <span class="badge badge-dark"
      *ngIf="job.jobStatusId === JobStatuses.Closed_Fishing"><i
        class="fad fa-check"></i>&nbsp;&nbsp;{{job.jobStatusName| uppercase}}</span>
    <span class="badge badge-danger"
      *ngIf="job.jobStatusId === JobStatuses.Cancelled_Fishing"><i
        class="fad fa-times-circle"></i>&nbsp;&nbsp;{{job.jobStatusName| uppercase}}</span>
    <span class="text-primary pointer"
      *ngIf="userService.user.value && userService.user.value.activeRoles && userService.user.value.activeRoles.includes('General')">
      &nbsp;
      <i class="fas fa-info-circle"
        type="button"
        [popover]="popoverTemplateHistory"
        popoverTitle="Job Status History"
        placement="auto"
        [outsideClick]="true"
        container="body"></i>
    </span>
    <ng-template #popoverTemplateHistory>
      <div class="width-container">
        <app-job-status-history-popover [jobId]="job.jobId"></app-job-status-history-popover>
      </div>
    </ng-template>
  </dd>
  <dt class="col-sm-4 text-right"
    [ngClass]="{'pr-0': isTablet}"
    *ngIf="job.jobSubStatusName && job.jobStatusId !== JobStatuses.Quote_Sales">Sub Status</dt>
  <dd class="col-sm-8"
    *ngIf="job.jobSubStatusName && job.jobStatusId !== JobStatuses.Quote_Sales">
    <span class="badge badge-secondary"
      *ngIf="job.jobSubStatusName">{{job.jobSubStatusName| uppercase}}</span>
  </dd>
  <dt class="col-sm-4 text-right"
    *ngIf="job.otherReason"
    [ngClass]="{'pr-0': isTablet}">Cancellation Reason</dt>
  <dd class="col-sm-8"
    *ngIf="job.otherReason">{{job.otherReason}}</dd>
  <dt class="col-sm-4 text-right"
    [ngClass]="{'pr-0': isTablet}"
    *ngIf="job.workDescription">Work Description</dt>
  <dd class="col-sm-8"
    *ngIf="job.workDescription">{{job.workDescription}}</dd>
  <dt class="col-sm-4 text-right"
    [ngClass]="{'pr-0': isTablet}"
    *ngIf="job.closeDate">Close Date</dt>
  <dd class="col-sm-8"
    *ngIf="job.closeDate">{{job.closeDate | date}}</dd>
  <dt class="col-sm-4 text-right"
    [ngClass]="{'pr-0': isTablet}">Invoice Date</dt>
  <dd class="col-sm-8">
    <input type="text"
      placeholder="mm/dd/yyyy"
      class="form-control pl-0"
      style="height: 20px; border: 0;"
      [ngModel]="invoiceDate"
      [bsConfig]="{ dateInputFormat: 'MMM DD, YYYY' }"
      (bsValueChange)="saveInvoiceDateChange($event, job)"
      [disabled]="jobService.job.value !== null && jobService.job.value.jobStatusIsTerminal === true"
      bsDatepicker>
  </dd>
</dl>
