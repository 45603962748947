import { CurrencyPipe } from '@angular/common';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AgGridAngular } from 'ag-grid-angular';
import { ColDef, GridReadyEvent } from 'ag-grid-community';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { InvoiceItemModel } from 'src/models/invoice-item.model';
import { InvoiceItemReorderModel } from 'src/models/RequestParams/invoice-item-reorder.model';
import { AppStateService } from 'src/services/app-state.service';
import { JobService } from 'src/services/job.service';
import { UIService } from 'src/services/ui.service';

@Component({
  selector: 'app-mobile-re-order-invoice-modal',
  templateUrl: './mobile-re-order-invoice-modal.component.html',
  styleUrls: ['mobile-re-order-invoice-modal.component.css']
})

export class MobileReorderInvoiceModalComponent implements OnInit, OnDestroy {
  @ViewChild(AgGridAngular) agGrid!: AgGridAngular;
  public rows: InvoiceItemModel[];
  public jobId: number;
  public invoiceId: number = null;
  public isTablet: boolean = false;
  private gridApi;
  private gridColumnApi;

  public defaultColDef: ColDef = {
    sortable: true,
    filter: true,
  };

  public columnDefs: ColDef[] = [
    {
      field: 'partNumber',
      headerName: 'Part #',
      minWidth: 100,
      maxWidth: 150,
      suppressMovable: true,
      rowDrag: true,
      cellRenderer: params => params.value === null ? 'n/a' : params.value,
      cellClass: params => params.value === null ? 'text-muted' : null
    },
    {
      field: 'description',
      suppressMovable: true
    },
    {
      field: 'quantity',
      headerName: 'Qty',
      minWidth: 75,
      maxWidth: 125,
      suppressMovable: true,
      type: 'rightAligned'
    },
    {
      field: 'price',
      headerName: 'Unit Price',
      minWidth: 75,
      maxWidth: 125,
      suppressMovable: true,
      valueFormatter: (data) => this.currencyFormatter.transform(data.value),
      type: 'rightAligned'

    },
    {
      field: 'total',
      minWidth: 75,
      maxWidth: 125,
      suppressMovable: true,
      valueFormatter: (data) => this.currencyFormatter.transform(data.value),
      type: 'rightAligned'
    }
  ];

  private isTabletSub: Subscription;
  private saveSub: Subscription;

  constructor(
    public bsModalRef: BsModalRef,
    public jobService: JobService,
    public uiService: UIService,
    public appStateService: AppStateService,
    private modalService: BsModalService,
    private currencyFormatter: CurrencyPipe
  ) { }

  ngOnDestroy(): void {
    this.isTabletSub?.unsubscribe();

    this.saveSub?.unsubscribe();
  }

  ngOnInit(): void {
    this.determineIsTablet();
  }

  public determineIsTablet(): void {
    this.isTabletSub = this.appStateService.isTablet$
      .subscribe((isTablet: boolean) => {
        this.isTablet = isTablet;
      });
  }

  public onSave(): void {
    const rows = this.gridApi['rowModel']['rowsToDisplay'];

    let batchReorder: number[][] = [];
    let counter: number = 0;

    rows.forEach((row: any) => {
      let temp = [];

      if (row && row['data']) {
        temp.push(row['data'].invoiceItemId);
        temp.push(counter);
        batchReorder.push(temp);
        counter++;
      }
    });

    let model = new InvoiceItemReorderModel();
    model.jobId = this.jobId;
    model.batchIds = batchReorder;
    model.invoiceId = this.invoiceId;

    if (!this.invoiceId) {
      this.saveSub = this.jobService.batchReorderJobInvoiceItems(model)
        .subscribe((x: boolean) => {
          if (x === true) {
            this.bsModalRef.hide();
            this.uiService.showSuccess('Invoice Reordered', 'Success');
          } else {
            this.uiService.showError('Invoice Not Reordered', 'Error');
          }
        });
    } else if (this.invoiceId) {
      this.saveSub = this.jobService.batchReorderFishingJobInvoiceItems(model)
        .subscribe((x: boolean) => {
          if (x === true) {
            this.bsModalRef.hide();
            this.uiService.showSuccess('Invoice Reordered', 'Success');
          } else {
            this.uiService.showError('Invoice Not Reordered', 'Error');
          }
        });
    }
  }

  public onGridReady(params: GridReadyEvent) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    // The ag-grid is not enlarging based on the page height, 
    // so dynamically adjusting the height of the grid
    this.gridApi.setDomLayout('autoHeight');
    this.gridApi.sizeColumnsToFit(params);

  }

}
