import { Component, OnDestroy, OnInit } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { AddEditBillingCodeModalComponent } from 'src/app/billing-codes/add-edit-billing-code-modal/add-edit-billing-code-modal.component';
import { BillingCodeModel } from 'src/models/billing-code.model';
import { JobSalesItemModel } from 'src/models/job-sales-item.model';
import { BillingCodeService } from 'src/services/billing-code.service';
import { InvoiceItemService } from 'src/services/invoice-item.service';
import { ItemService } from 'src/services/item.service';
import { JobService } from 'src/services/job.service';
import { UIService } from 'src/services/ui.service';
import { InvoiceItemTypes } from '../../../../enums/invoice-item-types';
import { InvoiceService } from '../../../../services/invoice.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-add-edit-job-fishing-item-modal',
  templateUrl: './add-edit-job-fishing-item-modal.component.html',
  styleUrls: ['./add-edit-job-fishing-item-modal.component.css']
})

export class AddEditJobFishingItemModalComponent implements OnInit, OnDestroy {
  public isNew: boolean = false;
  public isInventoryItem: boolean = false;
  public isFishingRental: boolean = false;
  public isMisc: boolean = false;
  public isRebuildItem: boolean = false;
  public jobId: number = 0;
  public invoiceId: number;
  public invoiceItemId: number = 0;
  public qtyCache: number = 0;
  public billingCodes: Array<BillingCodeModel> = [];
  public total: string = '';

  public isInspection: boolean = false;
  public editInspection: boolean = false;
  public disableInpCheckbox: boolean = false;
  public showWarning: boolean = false;

  public isReadOnly: boolean = false;
  public inEditMode: boolean = false;
  public invoiceHasBeenSplit: boolean = false;
  public inventoryCount: number = null;

  public InvoiceItemTypes = InvoiceItemTypes;
  public missingBillingCodeChecked: boolean = false;
  public existingItemHasBillingCode: boolean = false;
  public disableSave: boolean = false;

  private modelSub: Subscription;
  public model: JobSalesItemModel = new JobSalesItemModel();

  private bc_1_Sub: Subscription;
  private bc_2_Sub: Subscription;
  private bcSub: Subscription;
  private saveSub: Subscription;

  constructor(
    public bsModalRef: BsModalRef,
    public bsModalRefChildModal: BsModalRef,
    private modalService: BsModalService,
    public uiService: UIService,
    public itemService: ItemService,
    public billingCodeService: BillingCodeService,
    public invoiceItemService: InvoiceItemService,
    public invoiceService: InvoiceService,
    public jobService: JobService,

  ) { };

  ngOnDestroy(): void {
    this.modelSub?.unsubscribe();

    this.bc_1_Sub?.unsubscribe();

    this.bc_2_Sub?.unsubscribe();

    this.bcSub?.unsubscribe();

    this.saveSub?.unsubscribe();
  }

  ngOnInit(): void {
    this.billingCodeService.getBillingCodes(
      false,
      true,
      this.isRebuildItem,
      true);

    this.isNew = this.invoiceItemId === 0;

    if (!this.isNew) {
      this.getModel();
    } else {
      this.model.invoiceItemTypeId = this.InvoiceItemTypes.InvoiceItem;
      this.model.invoiceId = this.invoiceId;
      this.model.isForResale = false;
      this.model.isActive = true;
      this.model.jobId = this.jobId;
      if (!this.isFishingRental) {
        this.subscribeToBillingCodeCache();
      }
    }

    if (this.invoiceHasBeenSplit
      && this.inEditMode) {
      this.invoiceService.refreshInvoiceList(this.jobId);
    }

    this.subscribeToBillingCodes();
  }

  public getModel(): void {
    this.modelSub = this.invoiceItemService.getJobSalesItemByInvoiceItemId(this.invoiceItemId)
      .subscribe((x: JobSalesItemModel) => {
        this.isInspection = x.allowEditFishingItemQty;

        this.qtyCache = x.quantity;

        this.editInspection = x.lockQty;

        this.disableInpCheckbox = x.lockQty;

        this.existingItemHasBillingCode = x.billingCodeId !== null
          && x.billingCodeId !== undefined;

        this.model = x;

        this.subscribeToBillingCodes();
      });
  }

  // this subscribe exists to remove a race condition where there would not yet be values in billingCodeList when the modal renders
  public subscribeToBillingCodes(): void {
    this.billingCodeService.billingCodeList.next(null);

    this.bc_1_Sub = this.billingCodeService.billingCodeList
      .subscribe((x: BillingCodeModel[]) => {
        if (x !== null
          && x !== undefined) {
          let filter = x.filter((x: BillingCodeModel) => {
            return x.billingCodeId === this.model.billingCodeId;
          });

          if (filter.length === 0
            && this.model
            && this.model.billingCodeId
            && !this.missingBillingCodeChecked) {
            this.bc_2_Sub = this.billingCodeService.getBillingCodeById(this.model.billingCodeId)
              .subscribe((x: BillingCodeModel) => {
                this.missingBillingCodeChecked = true;
                if (x !== undefined && x !== null) {
                  this.billingCodeService.billingCodeList.next([...this.billingCodeService.billingCodeList.value, x]);
                }
              });
          } else {
            this.filterBillingCodes();
          }

          this.calculateTotal();
        }
      });
  }

  public subscribeToBillingCodeCache(): void {
    this.billingCodeService.billingCodeCache.next(null);

    this.bcSub = this.billingCodeService.billingCodeCache
      .subscribe((x: BillingCodeModel) => {
        if (x !== null && x !== undefined) {
          this.model.billingCodeId = x.billingCodeId;
          this.model.description = x.name;
          this.model.price = x.rate;
        }
      });
  }

  public openBillingCodeModal(billingCodeId: number): void {
    const initialState = {
      id: billingCodeId,
      itemId: this.model.itemId,
      showInactive: false,
      cacheBillingCode: true,
      isInventoryItem: this.isInventoryItem
    };

    this.bsModalRefChildModal = this.modalService.show(AddEditBillingCodeModalComponent, { initialState, backdrop: 'static' });
  }

  public onChangeOfBillingCode($event: BillingCodeModel): void {
    if ($event !== undefined) {
      this.model.billingCodeId = $event.billingCodeId;
      this.model.price = this.billingCodes.filter((x: BillingCodeModel) => {
        return x.billingCodeId === this.model.billingCodeId;
      })[0].rate;
      this.model.description = this.billingCodes.filter((x: BillingCodeModel) => {
        return x.billingCodeId === this.model.billingCodeId;
      })[0].name;
    } else {
      this.model.billingCodeId = null;
      this.model.description = null;
    }
    this.calculateTotal();
  }

  public enableQtyEdit(): void {
    this.editInspection = true;
    this.disableInpCheckbox = true;
    this.showWarning = true;
  }

  public filterBillingCodes(): void {
    if (this.isFishingRental) {
      this.billingCodes = this.billingCodeService.billingCodeList.value !== null ? this.billingCodeService.billingCodeList.value : null;
    } else {
      // retrieve only billing codes without itemIds for misc items
      this.billingCodes = this.billingCodeService.billingCodeList.value !== null ?
        (this.billingCodeService.billingCodeList.value.filter((x: BillingCodeModel) => {
          return x.itemId === null;
        }))
        : [];
    }
  }

  public calculateTotal(): void {
    if (this.model.quantity !== null
      && this.model.quantity !== undefined
      && this.model.billingCodeId !== null
      && this.model.billingCodeId !== undefined
      && this.billingCodes.length > 0) {
      let rate: number = this.model.price;
      this.total = (this.model.quantity * rate).toFixed(2);
    } else if ((this.model.billingCodeId === null
      || this.model.billingCodeId === undefined
      || this.billingCodes.length > 0)
      && this.model.quantity !== null
      && this.model.quantity !== undefined
      && this.model.price !== null
      && this.model.price !== undefined) {
      let rate: number = this.model.price;
      this.total = (this.model.quantity * rate).toFixed(2);
    } else {
      this.total = '';
    }
  }

  public onSave(): void {
    this.disableSave = true;

    if (this.editInspection
      && this.qtyCache !== this.model.quantity) {
      this.model.lockQty = true;
    }

    this.saveSub = this.invoiceItemService.addEditFishingInvoiceLine(this.model)
      .subscribe((x: boolean) => {
        if (x) {
          this.bsModalRef.hide();

          this.uiService.showSuccess('Item Saved', 'Success');
        } else {
          this.uiService.showError('Item Not Saved', 'Error');

          this.disableSave = false;
        }
      });
  }

}
