import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Observer } from 'rxjs';
import { RigFilterParamsModel } from 'src/models/RequestParams/rig-filter-params.model';
import { RigModel } from '../models/rig.model';

@Injectable()
export class RigService {
  constructor(
    private http: HttpClient,
    @Inject('BASE_URL') private baseUrl: string
  ) { }

  public rigList: BehaviorSubject<Array<RigModel>> = new BehaviorSubject(null);
  public rigFilterParams: RigFilterParamsModel = new RigFilterParamsModel();
  public rigCache: BehaviorSubject<RigModel> = new BehaviorSubject(null);

  private apiUrl = 'api/Rig';  // URL to web api

  public addEditRig(
    dto: RigModel,
    showInactive: boolean,
    getByCustomer: boolean = false,
    cacheRig: boolean = false): Observable<boolean> {
    dto = this.cleanRigModel(dto);
    return new Observable((observer: Observer<boolean>) => {
      this.http.post<number>(this.baseUrl + this.apiUrl + '/AddEditRig', dto)
        .subscribe((x: number) => {
          if (x !== -1) {
            if (cacheRig) {
              dto.rigId = x;
              this.rigCache.next(dto);
            }
            if (!getByCustomer) {
              this.getRigs(showInactive);
            } else {
              this.getRigsByCustomerId(dto.customerId, showInactive);
            }
          }
          observer.next(x !== -1);
        });
    });
  }

  public deleteRig(
    dto: RigModel,
    showInactive: boolean,
    getByCustomer: boolean = false): Observable<boolean> {
    return new Observable((observer: Observer<boolean>) => {
      this.http.get<boolean>(this.baseUrl + this.apiUrl + '/DeleteRig/' + dto.rigId)
        .subscribe((x: boolean) => {
          if (x === true) {
            if (!getByCustomer) {
              this.getRigs(showInactive);
            } else {
              this.getRigsByCustomerId(dto.customerId, showInactive);
            }
          }
          observer.next(x);
        });
    });
  }

  public getRigs(showInactive: boolean): void {
    this.http.post<Array<RigModel>>(this.baseUrl + this.apiUrl + '/GetRigs/' + showInactive, this.rigFilterParams)
      .subscribe((x: RigModel[]) => {
        this.rigList.next(x);
      });
  }

  public getRigsByCustomerId(
    customerId: number,
    showInactive: boolean): void {
    this.http.get<Array<RigModel>>(this.baseUrl + this.apiUrl + '/GetRigsByCustomerId/' + customerId + '/' + showInactive)
      .subscribe((x: RigModel[]) => {
        this.rigList.next(x);
      });
  }

  public getRigById(id: number): Observable<RigModel> {
    return this.http.get<RigModel>(this.baseUrl + this.apiUrl + '/GetRigById/' + id);
  }

  public clearFilterParams(showInactive: boolean) {
    this.rigFilterParams.name = null;
    this.rigFilterParams.countyId = null;
    this.rigFilterParams.customerId = null;

    this.getRigs(showInactive);
  }

  public generateNewRigModel(): RigModel {
    let model: RigModel = new RigModel();
    model.isActive = true;

    return model;
  }

  private cleanRigModel(model: RigModel): RigModel {
    Object.keys(model).forEach((index: string) => {
      if (typeof model[index] === 'string') {
        model[index] = model[index]
          && model[index].trim() !== '' ?
          model[index].trim()
          : null;
      }
    });

    return model;
  }

}
