<div class="modal-header"
  ngxModalDraggable>
  <h4 class="modal-title pull-left"
    *ngIf="model">{{isNew ? 'Add' : 'Edit'}}&nbsp;BHA Line {{model.rowNumber}}</h4>
  <button type="button"
    class="close pull-right"
    aria-label="Close"
    (click)="bsModalRef.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body"
  *ngIf="model">
  <div class="form-group row">
    <label for="title"
      class="col-sm-2 text-right control-label col-form-label">Job Item</label>
    <div class="col-sm-4 form-group mb-0"
      *ngIf="jiiService.jiiByJobIdList | async; let item">
      <ng-select [items]="item"
        bindLabel="dropdownDisplay"
        bindValue="jobItemInstanceId"
        [(ngModel)]="model.jobItemInstanceId"
        (change)="onChangeOfJobItem($event)"
        style="padding:0px;"
        name="items"
        required>
      </ng-select>
    </div>
  </div>
  <div class="form-group row">
    <label for="title"
      class="col-sm-2 text-right control-label col-form-label">Description</label>
    <div class="col-sm-4 form-group mb-0">
      <input type="text"
        class="form-control form-control-sm"
        id="description"
        [(ngModel)]="model.description"
        name="description"
        [readonly]="true">
    </div>
    <label for="title"
      class="col-sm-2 text-right control-label col-form-label">Serial Number</label>
    <div class="col-sm-4 form-group mb-0"
      *ngIf="model">
      <input type="text"
        class="form-control form-control-sm"
        id="serialNumber"
        [(ngModel)]="model.serialNumber"
        name="serialNumber"
        [readonly]="model.isItemInstance===true||!model.jobItemInstanceId">
    </div>
  </div>
  <div class="form-group row">
    <label for="title"
      class="col-sm-2 text-right control-label col-form-label">OD</label>
    <div class="col-sm-4 form-group mb-0">
      <div class="input-group">
        <input type="number"
          class="form-control form-control-sm"
          id="outerDiameter"
          [(ngModel)]="model.outerDiameter"
          name="outerDiameter"
          required>
        <div class="input-group-append"
          style="height: 32px;">
          <span class="input-group-text">inches</span>
        </div>
      </div>
    </div>
    <label for="title"
      class="col-sm-2 text-right control-label col-form-label">ID</label>
    <div class="col-sm-4 form-group mb-0">
      <div class="input-group">
        <input type="number"
          class="form-control form-control-sm"
          id="innerDiameter"
          [(ngModel)]="model.innerDiameter"
          name="innerDiameter"
          required>
        <div class="input-group-append"
          style="height: 32px;">
          <span class="input-group-text">inch</span>
        </div>
      </div>
    </div>
  </div>
  <div class="form-group row">
    <label for="title"
      class="col-sm-2 text-right control-label col-form-label">FNOD</label>
    <div class="col-sm-4 form-group mb-0">
      <div class="input-group">
        <input type="number"
          class="form-control form-control-sm"
          id="fnOd"
          [(ngModel)]="model.fnOd"
          name="fnOd"
          [required]="!fnOdNa"
          [readonly]="fnOdNa">
        <div class="input-group-append"
          style="height: 32px;">
          <span class="input-group-text">
            ft&nbsp;&nbsp;|&nbsp;&nbsp;<input type="checkbox"
              (change)="toggleFnOdNa($event)"
              [checked]="fnOdNa">&nbsp;&nbsp;<span class="text-muted">n/a</span>
          </span>
        </div>
      </div>
    </div>
    <label for="title"
      class="col-sm-2 text-right control-label col-form-label">FN Length</label>
    <div class="col-sm-4 form-group mb-0">
      <div class="input-group">
        <input type="number"
          class="form-control form-control-sm"
          id="fnLength"
          [(ngModel)]="model.fnLength"
          name="fnLength"
          [required]="!fnLengthNa"
          [readonly]="fnLengthNa">
        <div class="input-group-append"
          style="height: 32px;">
          <span class="input-group-text">
            ft&nbsp;&nbsp;|&nbsp;&nbsp;<input type="checkbox"
              (change)="toggleFnLengthNa($event)"
              [checked]="fnLengthNa">&nbsp;&nbsp;<span class="text-muted">n/a</span>
          </span>
        </div>
      </div>
    </div>
  </div>
  <div class="form-group row">
    <label for="title"
      class="col-sm-2 text-right control-label col-form-label">Length</label>
    <div class="col-sm-4 form-group mb-0">
      <div class="input-group">
        <input type="number"
          class="form-control form-control-sm"
          id="length"
          [(ngModel)]="model.length"
          name="length"
          required>
        <div class="input-group-append"
          style="height: 32px;">
          <span class="input-group-text">ft</span>
        </div>
      </div>
    </div>
    <label for="title"
      class="col-sm-2 text-right control-label col-form-label">Remarks</label>
    <div class="col-sm-4 form-group mb-0">
      <textarea name="notes"
        id="notes"
        class="form-control form-control-sm"
        [(ngModel)]="model.notes"></textarea>
    </div>
  </div>
</div>
<div class="modal-footer"
  *ngIf="model">
  <button type="button"
    class="btn btn-primary"
    (click)="onSave()"
    [disabled]="disableSave 
      || !(model.jobItemInstanceId
        && model.length!==null
        && model.outerDiameter!==null
        && model.innerDiameter!==null
        && (fnOdNa===true 
          ||(fnOdNa===false 
            && model.fnOd!==null 
            && model.fnOd!==undefined))
        && (fnLengthNa===true 
          || (fnLengthNa===false 
            && model.fnLength!==null 
            && model.fnLength!==undefined)))">Save</button>
  <button type="button"
    class="btn btn-default"
    (click)="bsModalRef.hide()">Cancel</button>
</div>
