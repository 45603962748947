import { Component, OnDestroy, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { WorksheetRowTypeModel } from 'src/models/worksheet-row-type.model';
import { UIService } from 'src/services/ui.service';
import { WorksheetRowTypeService } from 'src/services/worksheet-row-type.service';

@Component({
  selector: 'app-add-edit-worksheet-row-type-modal',
  templateUrl: './add-edit-worksheet-row-type-modal.component.html',
  styleUrls: ['add-edit-worksheet-row-type-modal.component.css']
})

export class AddEditWorksheetRowTypeModalComponent implements OnInit, OnDestroy {
  public isNew: boolean = false;
  public id: number = -1;
  public isItemized = true;
  public isRebuild = true;
  public worksheetTypeId: number = -1;
  public newRowSortValue: number = -1;
  public worksheetTypeName: string = null;
  public showInactive: boolean = false;
  public disableSave: boolean = false;

  private modelSub: Subscription;
  public model: WorksheetRowTypeModel;

  private saveSub: Subscription;

  constructor(
    public bsModalRef: BsModalRef,
    public bsModalRefChildModal: BsModalRef,
    public uiService: UIService,
    public worksheetRowTypeService: WorksheetRowTypeService,
  ) { };

  ngOnDestroy(): void {
    this.modelSub?.unsubscribe();

    this.saveSub?.unsubscribe();
  }

  ngOnInit(): void {
    this.isNew = this.id === 0;

    if (this.isNew) {
      this.model = this.worksheetRowTypeService.generateNewWorksheetRowTypeModel(this.worksheetTypeId);
      this.model.worksheetTypeName = this.worksheetTypeName;
      this.model.sortOrder = this.newRowSortValue;

    } else {
      this.getModel();
    }
  }

  public getModel(): void {
    this.modelSub = this.worksheetRowTypeService.getWorksheetRowTypeById(this.id)
      .subscribe((x: WorksheetRowTypeModel) => {
        this.model = x;
      });
  }

  public onChangeOfIsNewBillingCode($event: boolean): void {
    if ($event !== undefined) {
      this.model.isNewBuildBillingCode = $event;
      if ($event === true && this.model.itemized === true) {
        this.model.itemized = false;
      }
    }
  }

  public onSave(): void {
    this.disableSave = true;

    this.saveSub = this.worksheetRowTypeService.addEditWorksheetRowType(
      this.model,
      true)
      .subscribe((x: boolean) => {
        if (x) {
          this.bsModalRef.hide();
          this.uiService.showSuccess('Worksheet Row Type Saved', 'Success');
        } else {
          this.disableSave = false;
          this.uiService.showError('Worksheet Row Type Not Saved', 'Error');
        }
      });
  }

}
